import React from 'react';
import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material";
import savePng from "../../assets/bottomToolBar/stage/save_stage.png";
import noSavePng from "../../assets/bottomToolBar/stage/no_save_stage.png";
import { Font } from '@react-pdf/renderer';
import { click } from '@testing-library/user-event/dist/cjs/convenience/click.js';

const NormalButton = (props) => {
  
  const [status, setStatus] = useState(0)
  let mouseEnterFlag = false
  let timer: any = null

  const timeOutHandle = () => {
    clearTimeout(timer)
    if(mouseEnterFlag) {
      setStatus(1)
    }
    else {
      setStatus(0)
    }
    props.onClickHandle()
  }

  return <div style={{display: "flex", 
       flexDirection: "row",
       alignItems: "center",
       justifyContent: "center",
       userSelect: 'none',
       width: props.width,
       height: props.height,
       background: status === 0 ? props.backgroundColor : (status === 1 ? props.mouseInBackgroundColor : props.clickedBackgroundColor),
       color: status === 0 ? props.color : (status === 1 ? props.mouseInColor : props.clickedColor),
       fontSize: props.fontSize,
       borderRadius: props.radius,
  }}
  onClick={()=>{
    setStatus(2)
    timer = setTimeout(timeOutHandle, 100)
  }}
  onMouseEnter={() => {mouseEnterFlag = true; setStatus(1)}}
  onMouseLeave={() => {mouseEnterFlag = false; setStatus(0)}}>
    {props.text}
  </div>
};

export default NormalButton;