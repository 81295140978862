import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { UAlert, UButton, UText } from "../../../components"
import { Box, Link } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { RootState } from "../../../core/app/store"
import { getContactInfo } from "../../../modules/contact.config"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"

const Footer: FC = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { alertMsg, isError, btnText, btnAction } = useAppSelector(
    (state: RootState) => state.alert,
  )
  const { t } = useTranslation("common")
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const contactInfo = useMemo(() => getContactInfo(countryCode), [countryCode])

  useEffect(() => {
    if (alertMsg) {
      setShowAlert(true)
    }
  }, [alertMsg])

  const closeAlert = () => {
    setShowAlert(false)
    dispatch(setAlert({ message: "" }))
  }

  return (
    <Box
      component={"div"}
      sx={{
        ...CENETER_COLUMN_ALIGN,
        backgroundColor: "#F1F2F4",
        py: "22px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {alertMsg && showAlert && (
        <UAlert
          alertContent={
            <>
              <UText
                sxProp={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "143%",
                }}
              >
                {alertMsg}
              </UText>
              {!!btnText && !!btnAction && (
                <UButton
                  sxProp={{
                    width: "120px",
                    height: "30px",
                    color: "#ffffff",
                    border: "1px solid #ffffff",
                    borderRadius: "4px",
                    fontSize: "13px",
                    marginLeft: 2,
                    padding: "4px 10px",
                  }}
                  onClickHandler={btnAction}
                  btnText={btnText}
                />
              )}
            </>
          }
          variant={"filled"}
          severity={isError ? "error" : "success"}
          alertProps={{ paddingBottom: "10px" }}
          showAlert={showAlert}
          onClickHandler={closeAlert}
        ></UAlert>
      )}
      <UText
        sxProp={{ fontSize: "11px", fontWeight: "300", opacity: "60%" }}
        color={"#000000"}
        component={"p"}
      >
        {t("footer.description")}{" "}
        <Link
          href={contactInfo?.phoneHref}
          sx={{ textDecoration: "none", color: "#000000", fontWeight: "500" }}
        >
          {t(contactInfo?.phone)}{" "}
        </Link>
        {t("footer.emailTitle")}{" "}
        <Link
          href={contactInfo?.emailHref}
          sx={{ textDecoration: "none", color: "#000000", fontWeight: "500" }}
        >
          {t(contactInfo?.email)}
        </Link>
      </UText>
      <Box component={"div"} sx={{ pt: "4px" }}>
        <UText
          color={"#000000"}
          component={"div"}
          sxProp={{
            display: "flex",
            alignItems: "center",
            fontSize: "9px",
            fontWeight: "300",
            lineHeight: "16px",
            opacity: "60%",
          }}
        >
          {t("footer.rights")}
        </UText>
      </Box>
      <Box component={"div"} sx={{ pt: "4px" }}>
        <UText
          color={"#000000"}
          component={"div"}
          sxProp={{
            display: "flex",
            alignItems: "center",
            fontSize: "9px",
            fontWeight: "300",
            opacity: "60%",
          }}
        >
          <Link
            href={"https://www.ulabsystems.com/privacy-policy/"}
            target={"_blank"}
            sx={{
              textDecoration: "none",
              color: "#000000",
              fontWeight: "500",
              lineHeight: "16px",
            }}
          >
            {t("footer.privacypolicy")}
          </Link>
          <Box
            component="div"
            sx={{
              borderLeft: "1px solid #000000",
              opacity: "0.28",
              height: "12px",
              mx: 1,
            }}
          ></Box>
          <Link
            href={"https://www.ulabsystems.com/terms-of-use/"}
            target={"_blank"}
            sx={{
              textDecoration: "none",
              color: "#000000",
              fontWeight: "500",
              lineHeight: "16px",
            }}
          >
            {t("footer.termsandconditions")}
          </Link>
        </UText>
      </Box>
    </Box>
  )
}

export default Footer
