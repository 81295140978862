import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

import { UModalPopup, UText } from "../../../ui-component"
import { Box, IconButton } from "../../../ui-component/mui.components"

const Shortcuts: FC = () => {
  const { t } = useTranslation("common")
  const [showShortcutPopup, setShowShortcutPopup] = useState(true)

  return (
    <UModalPopup
      data-testid={"shortcuts"}
      title={
        <Box>
          <UText
            variant={"h6"}
            sxProp={{
              fontSize: "40px",
              fontWeight: 600,
              lineHeight: "116.7%",
              letterSpacing: "normal",
              color: "background.default",
            }}
          >
            {t("shortcuts.title")}
          </UText>
          <IconButton
            sx={{
              color: "#fff",
              position: "absolute",
              top: "8px",
              right: "8px",
              padding: "12px",
            }}
            onClick={() => {
              setShowShortcutPopup(false)
              window.close()
            }}
          >
            <CloseIcon
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      }
      isAlert={showShortcutPopup}
      content={
        <>
          {
            //TODO once final to be displayed is provided
          }
        </>
      }
      sxModalProps={{
        "& .MuiDialog-paper": {
          minWidth: "640px",
          padding: 2,
        },
        "#titleCntr": {
          backgroundColor: "primary.light",
          padding: "16px 24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "stretch",
        },
      }}
      btnList={[]}
    />
  )
}

export default Shortcuts
