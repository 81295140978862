import { useEffect, useRef, useState } from "react"
import { caseManagement } from "@/gluelayer"
import { Box } from "@mui/material"
function useResizeObserver(): [
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  sizes: { height?: number; width?: number },
] {
  const [ref, setRef] = useState<HTMLElement | null>(null)
  const [sizes, setSizes] = useState<{ height?: number; width?: number }>({})
  useEffect(() => {
    if (!ref) return
    const observer = new ResizeObserver(() => {
      setSizes({ height: ref.offsetHeight, width: ref.offsetWidth })
    })
    observer.observe(ref)
    return () => {
      observer.disconnect()
      setSizes({})
    }
  }, [ref])
  return [setRef, sizes]
}
const WasmCanvas = (props) => {
  const [ref, sizes] = useResizeObserver()
  const canvasRef = useRef<any>()
  const isLoading = useRef<boolean>(false)

  const styles: any = {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflowY: "hidden",
  }
  useEffect(() => {
    document.oncontextmenu = () => {
      return false
    }
    // if (canvasRef.current && isLoading.current === false) {
    //   isLoading.current = true
    //   // caseManagement.closeCase()
    //   // caseManagement.openCaseFromLocalZipFileUrl(
    //   //   canvasRef.current,
    //   //   "/testdata/shaoguangyu.zip",
    //   // )
    // }
    // caseManagement.setCallbackAfterOpencase(() => {
    //   isLoading.current = false
    // })
  }, [])
  useEffect(() => {
    // console.log("size:", sizes)
    window.dispatchEvent(new Event("resize"))
  }, [sizes])
  return (
    <canvas
      id="canvas"
      style={styles}
      ref={ref}
      tabIndex={-1}
      // onMouseOver={function () {
      //   canvasRef.current.focus()
      // }}
    />
  )
}
export default WasmCanvas
