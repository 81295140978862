import { createMachine, assign } from "xstate";
import { wasmModule } from "../wasm/wasmModule";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";
import { toothCorrectionModule } from "./toothcorrectiondispatcher";
import { bindCallbackToWindow } from "./bindcallback";

export enum ERemovalType {
  Magicwand = "magicwandtool",
  Pen = "pentool",
  TrimAndFill = "tftool",
  Tooth = "toothtool",
  Reset = "reset",
  Back = "back",
}

/**
 * interface :
 *
 * type ActionType = 'paint' | 'brush' | 'trimfill' | 'smooth' | 'reset' | 'undo'
 * clickJSButtonByIndex(type:ActionType)
 */

class ToothCorrenctionRemoval {
  state;
  constructor() {
    this.state = createMachine({
      id: "removalmodule",
      initial: "normal",
      context: {
        removal: false,
        gangbi: false,
        tooth: false, // tfttool 名称不对
      },
      states: {
        magicwand: {
          entry: [
            assign({ removal: (ctx: any) => true }),
            () => {
              const moduleEditor = this.moduleManager.GetModuleMeshEdit();
              console.log("??????????>>>>>>>>>0000", moduleEditor);
              moduleEditor.clickJSButtonByIndex("paint");
            },
          ],
          exit: [assign({ removal: (ctx: any) => false })],
        },
        gangbi: {
          entry: [
            assign({ gangbi: (ctx: any) => true }),
            () => {
              const moduleEditor = this.moduleManager.GetModuleMeshEdit();
              console.log("??????????>>>>>>>>>1111", moduleEditor);
              moduleEditor.clickJSButtonByIndex("brush");
            },
          ],
          exit: [assign({ gangbi: (ctx: any) => false })],
        },
        tooth: {
          entry: [
            assign({ tooth: (ctx: any) => true }),
            () => {
              const moduleEditor = this.moduleManager.GetModuleMeshEdit();
              console.log("??????????>>>>>>>>>2222", moduleEditor);
              moduleEditor.clickJSButtonByIndex("smooth");
            },
          ],
          exit: [assign({ tooth: (ctx: any) => false })],
        },
        normal: {},
      },
      entry: [
        () => {
          console.log("entry.... removal");
          this.switchModuleMeshEdit(true);
        },
      ],
      exit: [
        () => {
          console.log("out.... removal");
          this.switchModuleMeshEdit(false);
          this.moduleMeshShowBtnsCallBack(false);
        },
      ],
      on: {
        TOGGLE_MAGICWAND: [
          {
            target: ".normal",
            cond: (context, event, guardMeta) => {
              return context.removal === true;
            },
          },
          {
            target: ".magicwand",
            cond: (context, event, guardMeta) => {
              console.log("change to magicwand");
              return context.removal === false;
            },
          },
        ],
        TOGGLE_PEN: [
          {
            target: ".normal",
            cond: (context, event, guardMeta) => {
              return context.gangbi === true;
            },
          },
          {
            target: ".gangbi",
            cond: (context, event, guardMeta) => {
              return context.gangbi === false;
            },
          },
        ],
        TOGGLE_TOOTH: [
          {
            target: ".normal",
            cond: (context, event, guardMeta) => {
              return context.tooth === true;
            },
          },
          {
            target: ".tooth",
            cond: (context, event, guardMeta) => {
              return context.tooth === false;
            },
          },
        ],
        CLOSEALL: ".normal",
      },
    });

    bindCallbackToWindow(
      "toothCorrection.removel.clicktooth",
      this.moduleMeshShowBtnsCallBack.bind(this)
    );
  }

  switchModuleMeshEdit(isEnabled: boolean) {
    if (!wasmModule.isInit) return;
    console.log("switchModuleMeshEdit...", isEnabled);
    this.moduleManager.SwitchModuleMeshEdit(isEnabled);
  }
  clickJSButtonByIndex(actionType: ERemovalType) {
    const moduleEditor = this.moduleManager.GetModuleMeshEdit();
    const removalStateMachine =
      toothCorrectionModule.service.state.context.removalRef;
    console.log("action::::", actionType);
    switch (actionType) {
      case ERemovalType.Magicwand:
        removalStateMachine.send("TOGGLE_MAGICWAND");
        console.log("....TOGGLE_MAGICWAND");
        break;
      case ERemovalType.Pen:
        // moduleEditor.clickJSButtonByIndex('brush');
        removalStateMachine.send("TOGGLE_PEN");
        break;
      case ERemovalType.TrimAndFill:
        removalStateMachine.send("CLOSEALL");
        moduleEditor.clickJSButtonByIndex("trimfill");
        console.log("....tooth trimfill");
        break;
      case ERemovalType.Tooth:
        // moduleEditor.clickJSButtonByIndex('smooth');
        console.log("....tooth smooth");
        removalStateMachine.send("TOGGLE_TOOTH");
        break;
      case ERemovalType.Reset:
        moduleEditor.clickJSButtonByIndex("reset");
        console.log("....reset");
        removalStateMachine.send("CLOSEALL");
        break;
      case ERemovalType.Back:
        moduleEditor.clickJSButtonByIndex("undo");
        console.log("....undo");
        removalStateMachine.send("CLOSEALL");
        break;
      default:
        break;
    }
  }

  async moduleMeshShowBtnsCallBack(isEnabled: boolean) {
    // await getDvaApp()._store.dispatch({
    //   type: 'toothCorrection/setState',
    //   payload: {
    //     isRemovalShow: isEnabled,
    //   },
    // });
  }

  get moduleManager() {
    const ulabwinIns = wasmModule.getWasmObj()._ulabwinIns;
    const moduleManager = ulabwinIns.getModuleManager();
    return moduleManager;
  }
}

export const toothCorrenctionRemoval = new ToothCorrenctionRemoval();

/**
 * bind smooth model event
 */
globalEvents.on(
  GlobalEvents.TOOTH_CORRECTION_REMOVAL_CLICKED,
  (type: ERemovalType) => {
    toothCorrenctionRemoval.clickJSButtonByIndex(type);
  }
);
