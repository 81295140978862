export const title = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: 32,
}

export const previewBox = {
  display: "flex",
  height: 380,
  width: "100%",
  marginTop: 1,
}
export const fileBox = {
  width: 416,
  height: "100%",
  padding: "32px 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}

export const zoneBox = {
  width: 610,
  height: "100%",
  padding: "10px",
  gap: "10px",
}

export const stltitle = {
  width: "100%",
  marginBottom: "16px",
  display: "block",
}

export const file = {
  width: "100%",
  height: 88,
  padding: "24px 16px",
}

export const fileBoxContent = {
  flexDirection: "row",
  gap: 2,
}

export const zone = {
  textAlign: "center",
  width: "100%",
  padding: "10px",
}
