import { wasmModule } from "../wasm/wasmModule";

class ConfigModule {
  setAllSpecialColor() {
    this.setSpecialToothColor();
    this.setGridColor();
    this.setWidgetColor();
  }
  /**
   * 设置特殊牙齿的颜色
   */
  setSpecialToothColor() {
    const CrownColor: [number, number, number] = [
      255 / 255,
      132 / 255,
      65 / 255,
    ];

    const PonticColor: [number, number, number] = [
      124 / 255,
      77 / 255,
      160 / 255,
    ];

    const PrimaryColor: [number, number, number] = [
      146 / 255,
      182 / 255,
      96 / 255,
    ];

    const Bridge_Implant_Fix_Color: [number, number, number] = [
      85 / 255,
      93 / 255,
      104 / 255,
    ];

    wasmModule.statusController.SetToothTypeColor(
      wasmModule.module.ToothColorType.Crown,
      ...CrownColor
    );
    wasmModule.statusController.SetToothTypeColor(
      wasmModule.module.ToothColorType.Pontic,
      ...PonticColor
    );

    wasmModule.statusController.SetToothTypeColor(
      wasmModule.module.ToothColorType.Primary,
      ...PrimaryColor
    );

    wasmModule.statusController.SetToothTypeColor(
      wasmModule.module.ToothColorType.Bridge,
      ...Bridge_Implant_Fix_Color
    );

    wasmModule.statusController.SetToothTypeColor(
      wasmModule.module.ToothColorType.Implant,
      ...Bridge_Implant_Fix_Color
    );

    wasmModule.statusController.SetToothTypeColor(
      wasmModule.module.ToothColorType.Fix,
      ...Bridge_Implant_Fix_Color
    );
  }

  setGridColor() {
    wasmModule.moduleManager.SetGridActorsColor(
      120 / 255,
      144 / 255,
      156 / 255,
      0.45,
      91 / 255,
      160 / 255,
      194 / 255,
      0.6,
      249 / 255,
      252 / 255,
      255 / 255,
      1
    );
  }

  /**
   * 设置各种widget的颜色
   */
  setWidgetColor() {
    const pinkColor: [number, number, number, number] = [
      227 / 255,
      55 / 255,
      230 / 255,
      1,
    ];
    const blueColor: [number, number, number, number] = [
      3 / 255,
      155 / 255,
      229 / 255,
      1,
    ];
    const blackColor: [number, number, number, number] = [0, 0, 0, 1];

    const configModule = wasmModule.moduleManager.GetModulesConfigurator();
    console.log("ashuaizzz🚀 ~ configModule:", configModule);
    //circle widget
    configModule.SetFastSmartButtonColor(...pinkColor);
    configModule.SetFastSmartCircleColor(0, 63 / 255, 186 / 255, 1);

    //dome widget
    configModule.SetToothEruptMoveButtonColor(...blackColor);
    configModule.SetToothEruptRotateButtonColor(...pinkColor);
    configModule.SetToothEruptRotateLineColor(...blueColor);
    configModule.SetToothEruptScaleButtonColor(...pinkColor);

    //tooth widget
    configModule.SetMoveToothWidgetMoveButtonColor(...blackColor);
    configModule.SetMoveToothWidgetRotateButtonColor(...pinkColor);
    configModule.SetMoveToothWidgetMoveSphereColor(...pinkColor);
    configModule.SetMoveToothWidgetDiskColor(213 / 255, 0, 249 / 255, 0.28);
    configModule.SetMoveToothMoveLineColor(...pinkColor);
    configModule.SetMoveToothMoveAuxLineColor(...pinkColor);

    //attachment widget
    configModule.SetAttachmentMoveButtonColor(...blackColor);
    configModule.SetAttachmentRotateButtonColor(...pinkColor);
    configModule.SetAttachmentRotateLineColor(...blueColor);
    configModule.SetAttachmentScaleLineColor(...pinkColor);

    //attachment color
    configModule.SetAttachmentColor(105 / 255, 105 / 255, 105 / 255, 1);

    //overlay teeth color
    configModule.SetOverlayTeethColor(212 / 255, 214 / 255, 85 / 255, 1);
  }
}

export const configModule = new ConfigModule();
