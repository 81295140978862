import { matchPath } from "react-router-dom"
import { find } from "lodash"

import { CASE_STATUS } from "../modules/Patient/config/status.config"

export const DEFAULT_ROUTES = "records/patient/:patientId/case/:caseId/scans"

export const ROUTE_BY_MODULE = {
  clinicalPath: "clinical/patient/:patientId/case/:caseId/*",
  uAssistPrescription: "uassist/patient/:patientId/case/:caseId/prescription",
  orderBundle: "order/patient/:patientId/case/:caseId/bundle",
  order: "order/patient/:patientId/case/:caseId/*",
  reviewOrder: "order/patient/:patientId/case/:caseId/revieworder",
}

export const ROUTE_ATTENTION_BANNER = {
  attentionBanner: "/attentionBanner",
  smartRxFormBanner: "/SmartRxFormBanner",
}

const STATUS_CONFIG_BY_STATUS = [
  {
    matchPath: [
      "records/patient/:patientId/case/:caseId/*",
      "uassist/patient/:patientId/case/:caseId/dentalchart",
      "uassist/patient/:patientId/case/:caseId/retainer",
    ],
    allowedStatus: [
      CASE_STATUS.COMPLETE_PATIENT_RECORDS,
      CASE_STATUS.FILL_OUT_RX_FORM,
      CASE_STATUS.SUBMIT_TO_UASSIST,
      CASE_STATUS.TREATMENT_IN_PROGRESS,
      CASE_STATUS.TREATMENT_COMPLETE,
      CASE_STATUS.SHIPPED,
      CASE_STATUS.DELIVERED,
      CASE_STATUS.FROM_UDESIGN,
    ],
  },
  {
    matchPath: [
      "uassist/patient/:patientId/case/:caseId/ordertype",
      "uassist/patient/:patientId/case/:caseId/prescription",
      "uassist/patient/:patientId/case/:caseId/retainer",
      "uassist/patient/:patientId/case/:caseId/crowding/:formId",
      "uassist/patient/:patientId/case/:caseId/limitedtreatment/:formId",
      "uassist/patient/:patientId/case/:caseId/deepbite/:formId",
      "uassist/patient/:patientId/case/:caseId/spacing/:formId",
      "uassist/patient/:patientId/case/:caseId/openbite/:formId",
    ],
    allowedStatus: [
      CASE_STATUS.FILL_OUT_RX_FORM,
      CASE_STATUS.SUBMIT_TO_UASSIST,
      CASE_STATUS.SHIPPED,
      CASE_STATUS.DELIVERED,
      CASE_STATUS.TREATMENT_IN_PROGRESS,
      CASE_STATUS.TREATMENT_COMPLETE,
    ],
  },
  {
    matchPath: ["uassist/patient/:patientId/case/:caseId/summary"],
    allowedStatus: [CASE_STATUS.SUBMIT_TO_UASSIST],
  },
  {
    matchPath: ["uassist/patient/:patientId/case/:caseId/summary/confirmation"],
    allowedStatus: [CASE_STATUS.SUBMITTED_TO_UASSIST],
  },
  {
    matchPath: [
      "overview/patient/:patientId/case/:caseId/refinement/confirmation",
    ],
    allowedStatus: [
      CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
      CASE_STATUS.FROM_UDESIGN,
    ],
  },
  {
    matchPath: ["overview/patient/:patientId/case/:caseId/*"],
    allowedStatus: [
      CASE_STATUS.TREATMENT_IN_PROGRESS,
      CASE_STATUS.FROM_UDESIGN,
      CASE_STATUS.SHIPPED,
      CASE_STATUS.DELIVERED,
    ],
  },
  {
    matchPath: [
      "order/patient/:patientId/case/:caseId/order/:orderId/confirmation",
    ],
    allowedStatus: [CASE_STATUS.ORDER_SUBMITTED],
  },
  {
    matchPath: ["order/patient/:patientId/case/:caseId/*"],
    allowedStatus: [
      CASE_STATUS.REVIEW_PLAN,
      CASE_STATUS.REVIEW_MODIFICATION,
      CASE_STATUS.PLACE_ORDER,
      CASE_STATUS.SHIPPED,
      CASE_STATUS.DELIVERED,
      CASE_STATUS.TREATMENT_IN_PROGRESS,
      CASE_STATUS.TREATMENT_COMPLETE,
      CASE_STATUS.FROM_UDESIGN,
    ],
  },
]

export const verifyRoutAllowedByStatus = (
  status: string,
  location: string,
  isAllowToViewAllOrg: boolean,
) => {
  const isRestricted = STATUS_CONFIG_BY_STATUS.find(
    (config) =>
      config.matchPath.find((path) =>
        matchPath(isAllowToViewAllOrg ? "org/:orgId/" + path : path, location),
      ) && config.allowedStatus.includes(status),
  )
  return !!isRestricted
}

// export const getRouteConfigByModule = (module: string) => {
//   const obj = find(STATUS_ROUTE_CONFIG_BY_STATUS, function (o) {
//     return o.module === module
//   })
//   return obj
// }

export const shouldNavigateToPatients = (
  location: string,
  status: string,
): boolean => {
  if (
    (status === CASE_STATUS.SUBMITTED_TO_UASSIST &&
      (matchPath("uassist/patient/:patientId/case/:caseId/summary", location) ||
        matchPath(
          "uassist/patient/:patientId/case/:caseId/retainer",
          location,
        ))) ||
    (status === CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT &&
      matchPath(
        "overview/patient/:patientId/case/:caseId/form/refinement",
        location,
      )) ||
    ((status === CASE_STATUS.ORDER_SUBMITTED ||
      status === CASE_STATUS.IN_PRODUCTION) &&
      matchPath("order/patient/:patientId/case/:caseId/*", location))
  )
    return true
  return false
}
