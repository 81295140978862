import { FC, useEffect, useState } from "react"
import { LinearProgress } from "@mui/material"

import { Box } from "../../../../components/mui.components"
import { UText } from "../../../../ui-component"

const ProgressBar: FC<{
  colorCode: string
  total: number
  completed: number
}> = ({ colorCode, total, completed }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress((completed / total) * 100)
  }, [completed, total])
  return (
    <Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          transform: "scaleX(-1)",
          height: 16,
          width: 160,
          backgroundColor: "#E0E0E0",
          span: {
            backgroundColor: colorCode,
          },
        }}
      />
    </Box>
  )
}

export default ProgressBar
