import { FC } from "react"
import { Outlet } from "react-router-dom"

import { Grid } from "../components/mui.components"

const Billing: FC = () => {
  return (
    <Grid container direction={"row"} justifyContent={"center"} minWidth={"lg"}>
      <Grid container maxWidth={"lg"} sx={{ alignContent: "flex-start" }}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default Billing
