import { and, RankedTester, rankWith, schemaMatches } from "@jsonforms/core"
export const UTextareaTester: RankedTester = rankWith(
  3,
  and(
    schemaMatches((schema) => {
      // eslint-disable-next-line no-prototype-builtins
      return schema.hasOwnProperty("isTextarea")
    }),
  ),
)
