import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IConfigState {
  spinner: boolean
  routeFrom: string
}

const initialState: IConfigState = {
  spinner: true,
  routeFrom: localStorage.getItem("routeFrom") || "",
}

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLoadingSpinner: (state, action: PayloadAction<boolean>) => {
      state.spinner = action.payload
    },
    setRouteFrom: (state, action: PayloadAction<string>) => {
      state.routeFrom = action.payload
      localStorage.setItem("routeFrom", action.payload)
    },
    clearRouteFrom: (state) => {
      state.routeFrom = ""
    },
  },
})

export const { setLoadingSpinner, setRouteFrom, clearRouteFrom } =
  configSlice.actions

export default configSlice.reducer
