import { useState } from "react"
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  Switch,
  IconButton,
  TextField,
} from "@mui/material"
import { UDTabs } from "../custom/UDTabs"
import PostMsgIcon from "@mui/icons-material/SendOutlined"
import {
  UDSelectOptions,
  UDTitleSelect,
  UDTitlePlusMinus,
} from "../custom/UDSelectOptions"
import { RightPage } from "../right/RightPage"

export const UDStaging = (props) => {
  const StagingContent = () => {
    return (
      <Grid container alignItems={"center"} spacing={0.1}>
        <Grid display="flex" item xs={3} justifyContent="center">
          <Typography variant="body1">Upper Jaw</Typography>
        </Grid>
        <Grid display="flex" item xs={3} justifyContent="center">
          <Switch />
        </Grid>
        <Grid display="flex" item xs={3} justifyContent="center">
          <Typography variant="body1">Lower Jaw</Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" item xs={3}>
          <Button variant="contained">Run ...</Button>
        </Grid>
        <UDTitleSelect
          title="Over Correction"
          options={["None", "Before Final", "After Fianl"]}
        />
        <UDTitleSelect
          title="Seq. Movement"
          options={[
            "Auto",
            "Distlization",
            "Extraction",
            "Post Expansion",
            "None",
          ]}
        />
        <UDTitleSelect
          title="IPR Staging"
          options={["First Stage", "Auto", "Early", "Late"]}
        />
        <UDTitleSelect
          title="C-Chain"
          options={["None", "Ant 3x3", "Ant 4x4"]}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <UDTitleSelect title="Wearing Time" options={["2 Weeks", "1 Week"]} />
        <UDTitleSelect
          title="Passive Aligner"
          options={["None", "Final", "Linear"]}
        />
        <Grid display="flex" justifyContent="center" item xs={12}>
          <Button variant="contained">Run ...</Button>
        </Grid>
      </Grid>
    )
  }
  return (
    <RightPage
      title="uDesign Staging"
      onClose={props.onClose}
      content={<StagingContent />}
    />
  )
}
