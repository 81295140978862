import axios, { AxiosProgressEvent } from "axios"

import httpIT from "@/core/global/httpIT"
import HttpS3 from "@/core/global/https3"

import { getResizedFile } from "../../../../../modules/Records/Photograph/photographUtil"
import http from "../../../../global/http"


interface IUploadFileOptions{
  orgId: string,
  patientId: string,
  caseId: string,
  files:File[],
  callback:(progress: AxiosProgressEvent) => void,
}

export async function uploadFiles({
  patientId,
  caseId,
  files,
  orgId,
  callback,
}:IUploadFileOptions) {
  return Promise.all(
    files.map( file  => {
      const formData = new FormData()
      formData.append("attachment", file)
      return http.post(
        `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/cases/${file.name}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: callback,
        },
      )
    }),
  )
}


export const uploadThumbnails = ({
  orgId,
  patientId,
  caseId,
  files,
  callback,
}:IUploadFileOptions
)=>{
  return Promise.all(files.map(file=>{
    const formData =  new FormData();
    formData.append("attachment", file)
    return http.post(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${file.name}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: callback,
      },
    )
  }))
}


export const uploadOnePhotoFile = (
  orgId:string,
  patientId:string,
  caseId:string,
  fileName:string,
  formData:FormData,
  callback:(progress:AxiosProgressEvent)=>void
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try{
    return getResizedFile(formData.get("attachment")).then(
      (resizedFile:File)=>{
        formData.set("attachment",resizedFile)
        const res = http.post(url,formData,{
          headers:{
            "Content-Type":"multipart/form-data"
          },
          onUploadProgress:callback
        })
        return res
      }
    )
  }catch(err){
    return err
  }
}



export const uploadPhotographFiles = (
  // orgId: string,
  // patientId: string,
  // caseId: string,
  // fileName: string,
  // formData: FormData,
  // callback: (progress: AxiosProgressEvent) => void,
  {
    orgId,
    patientId,
    caseId,
    files,
    callback,
  }:IUploadFileOptions
) => {
  return Promise.all(files.map(async file=>{
    const newFile = await getResizedFile(file)
    const formData =  new FormData();
    formData.append("attachment", newFile)
    return http.post(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${newFile.name}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: callback,
      },
    )
  }))


  // const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  // try {
    
  //   const file = formData.get("attachment");
  //   console.log('3333>>',formData,file)
  //   if (file.type.includes('image')) {
  //     return getResizedFile(file).then(
  //       async (resizedFile: File) => {
  //         console.log('444>>',formData)
  //         formData.set("attachment", resizedFile)
  //         const res = await http.post(url, formData, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //           onUploadProgress: callback,
  //         })
  //         console.log('555>>',formData)
  //         return res
  //       },
  //     )
  //   }else{
  //     console.log('444>>',formData)
  //     return http.post(url, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       onUploadProgress: callback,
  //     })
  //   }

  // } catch (err) {
  //   return err
  // }
}

export const getS3FileUrl = ({ caseId, casePhoto }) => {
  return httpIT.post(`/ulab/case/api/v2/case/s3url`, {
    caseId,
    urlType: "upload",
    casePhoto,
  })
}

export const uploadPhoto = ({ url, file }) => {
  const res = HttpS3({ url }).put("", file)
  return res
}

export const downloadPhotographFiles = async(
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  fileName: string,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try {
    const res = await http.get(url, {
      responseType: "blob",
    })
    const retFile = new File([res.data],fileName)
    return retFile
  } catch (err) {
    return err
  }
}

export const fetchFileList = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/files`
  try {
    const res = http.get(url)
    return res
  } catch (err) {
    return err
  }
}

export const updatePhotoPositionAPI = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  payload: {
    photo_position: string
  },
) => {
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs`
  try {
    const res = http.patch(url, payload)
    return res
  } catch (err) {
    return err
  }
}

export const uploadPhotoJSONAPI = (
  orgId: string,
  patientId: string ,
  caseId: string,
  jsonFile:File,
  callback?:(progressEvent: AxiosProgressEvent)=>void
)=>{
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photopositiontype/type.json`;

  try {
    const formData = new FormData;
    formData.append('attachment',jsonFile);
    const res = http.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: callback,
      },)
    return res
  } catch (err) {
    return err
  }
}



export const downloadPhotoJSONAPI = (
  orgId: string,
  patientId: string ,
  caseId: string,
  callback?:(progressEvent: AxiosProgressEvent)=>void
)=>{
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photopositiontype/type.json`;
  try {
    const res = http.get(
      url,
      {
        onDownloadProgress: callback,
      },)
    return res
  } catch (err) {
    return err
  }

}

export const deletePhotographFile = async(
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  fileName: string,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try {
    const res = await http.delete(url)
    const retFile = new File([res.data],fileName)
    return retFile
  } catch (err) {
    return err
  }
}