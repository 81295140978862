import { FC, useEffect } from "react"
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"

import { useAppDispatch } from "../../core/app/hooks"
import {
  clearLoggedInUser,
  setErrorMessage,
  updateTokenExpired,
} from "../../core/app/slices/user/userSlice"

import ActivationUser from "./Activation/ActivationUser"
import ForgotPassword from "./ForgotPassword/ForgotPassword"
import ForgotUsername from "./ForgotUsername/ForgotUsername"
import Login from "./Login/Login"
import ResetPassword from "./ResetPassword/ResetPassword"

const AuthModule: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(clearLoggedInUser())
  }, [dispatch])

  useEffect(() => {
    if (!matchPath("/auth/login", location.pathname)) {
      dispatch(updateTokenExpired(""))
      dispatch(setErrorMessage(""))
    }
  }, [location])

  return (
    <Routes>
      <Route path={"login"} element={<Login />} />
      <Route path={"forgotUsername"} element={<ForgotUsername />} />
      <Route path={"forgotPassword"} element={<ForgotPassword />} />
      <Route path={"recoverPassword"} element={<ResetPassword />} />
      <Route path={"activation"} element={<ActivationUser />} />

      <Route path={"*"} element={<Navigate to={"login"} replace />} />
    </Routes>
  )
}

export default AuthModule
