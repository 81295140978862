import http from "../../../global/http"

export const fetchFormList = async (orgId: string) => {
  const url = `/shared/v1/org/${orgId}/forms`
  try {
    const res = await http.get(url)
    return res
  } catch (err) {
    return err
  }
}
export const saveDefaultFormDataService = async (
  orgId: string,
  formId: string,
  payload: string,
): Promise<any> => {
  try {
    const { data } = await http.put(
      `shared/v1/org/${orgId}/forms/${formId}/default-data/`,
      { form_default_data: payload, org_id: orgId },
    )
    return data
  } catch (e) {
    console.log(e)
  }
}

export const getSmartRxFormService = async (orgId: string, formID: string) => {
  const url = `/shared/v1/org/${orgId}/forms/${formID}`
  try {
    const res = await http.get(url)
    return res
  } catch (err) {
    return err
  }
}

export const getFormSavedDataService = async (
  orgId: string,
  formId: string,
  caseId: string,
) => {
  const url = `/shared/v1/org/${orgId}/forms/${formId}/txplans/${caseId}/saved-data`
  try {
    const res = await http.get(url)
    return res
  } catch (err) {
    return err
  }
}

export const updateFormSavedDataService = async (
  orgId: string,
  formId: string,
  caseId: string,
  payload: any,
  userId: string,
  savedId: string,
) => {
  const url = `/shared/v1/org/${orgId}/forms/${formId}/txplans/${caseId}/saved-data${
    savedId ? "/" + savedId : ""
  }`
  const body = {
    user_filled_data: payload,
    user_id: userId,
  }
  try {
    const res = savedId ? await http.put(url, body) : await http.post(url, body)
    return res
  } catch (err) {
    return err
  }
}

/**
 * In this method fetch from schema, uiSchema and default Data   and get data by case id
 * the merge svaedata with default data
 * @param orgId
 * @param formId
 * @param caseId
 * @returns
 */
export const getFormByIdWithMergeData = async (
  orgId: string,
  formId: string,
  caseId: string,
  getSavedData: boolean,
) => {
  try {
    const res = await Promise.all([
      getFormById(orgId, formId),
      getFormDataByCaseId(orgId, formId, caseId, getSavedData),
    ])
    if (res[1] && res[1].saved_data) {
      res[0].default_data = res[1].saved_data
      res[0].save_id = res[1].id
    }
    return res[0]
  } catch (err) {
    return err
  }
}

export const getFormById = async (orgId: string, formID: string) => {
  const url = `/shared/v1/org/${orgId}/forms/${formID}`
  try {
    const res = await http.get(url)
    if (res.status === 200) {
      const { data } = res
      return data.Result
    }
    return res
  } catch (err) {
    return err
  }
}

export const getFormDataByCaseId = async (
  orgId: string,
  formId: string,
  caseId: string,
  getSavedData: boolean,
) => {
  const url = `/shared/v1/org/${orgId}/forms/${formId}/txplans/${caseId}/saved-data`
  try {
    if (!getSavedData) return []
    const res = await http.get(url)
    if (res.status === 200) {
      const { data } = res
      return data.Result[0] || []
    }
    return []
  } catch (err) {
    return err
  }
}

export const uploadSummarypdf = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
  isRefinement: boolean,
  isRetainer?: boolean,
) => {
  try {
    const { data } = await http.post(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/uassist-summary/${
        isRefinement
          ? "refinement"
          : isRetainer
          ? "retainer-summary"
          : "summary"
      }.pdf`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    return data
  } catch (err) {
    return err
  }
}

export const deleteFormSavedDataService = async (
  orgId: string,
  formId: string,
) => {
  const url = `/shared/v1/org/${orgId}/forms/${formId}/default-data/`
  try {
    const res = await http.delete(url)
    return res
  } catch (err) {
    return err
  }
}
