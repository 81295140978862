import { FC, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Actions, ControlProps } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { RootState } from "../../../core/app/store"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"

const UTemplateSelect: FC<ControlProps> = (props) => {
  const { label, path, schema, data, handleChange } = props
  const [value, setValue] = useState(schema.default || "")
  const navigate = useMyNavigation()
  const location = useLocation()
  const { patientId, caseId } = useParams()
  const dispatchEvent = useAppDispatch()
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")

  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { routeFrom } = useAppSelector((state: RootState) => state.config)

  const { VITE_PRESCRIPTION_ID, VITE_RETAINER, VITE_REFINEMENT } = import.meta
    .env

  useEffect(() => {
    setValue(data)
  }, [data])

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  useEffect(() => {
    if (core?.schema.required?.indexOf(path) === -1) return
    updateErrors(!value ? "This is a required field" : "")
  }, [data, value, core?.validationMode])

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const selectedForm = Object.values(formTemplateList).find(
      (formobject) => formobject.name === event.target.value,
    )
    setValue(event.target.value as string)
    handleChange(path, event.target.value)
    selectedForm &&
      handleChange(path + "_id", {
        value: selectedForm.name,
        id: selectedForm.id,
      })
  }

  const routeToTemplate = (templateVal: string) => {
    const templateRoutes: { [key: string]: string } = {
      Crowding: "crowding",
      "Open Bite": "openbite",
      "Deep Bite": "deepbite",
      "Limited Treatment": "limitedtreatment",
      Spacing: "spacing",
    }

    const selectedForm = Object.values(formTemplateList).find(
      (formobject) => formobject.name === templateVal,
    )

    // eslint-disable-next-line no-prototype-builtins
    if (selectedForm && templateRoutes.hasOwnProperty(selectedForm.name)) {
      const route = `/uassist/patient/${patientId}/case/${caseId}/${
        templateRoutes[selectedForm.name]
      }/${selectedForm.id}`
      if (location.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner)) {
        const bannerRoute = `/overview/patient/${patientId}/case/${caseId}/attentionBanner/SmartRxFormBanner/${
          templateRoutes[selectedForm.name]
        }/${selectedForm.id}`
        navigate(bannerRoute, { state: { isBanner: true } })
      }
      //  else if (routeFrom === "PATIENT_LIST_MODULE") {
      //   navigate(route, { state: { isPatientList: true } })
      // } else if (routeFrom === "PATIENT_OVERVIEW_MODULE") {
      //   navigate(route, { state: { isOverview: true } })
      // }
      else {
        navigate(route)
      }
    }
  }
  return (
    <>
      <Box sx={{ my: 1 }}>
        {label && (
          <InputLabel
            sx={{
              mb: 1,
              color: "rgba(0, 0, 0, 0.60)",
              // v2:
              // whiteSpace: "unset",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.60)",
              },
            }}
            error={!!(errors && core?.validationMode === "ValidateAndShow")}
            id={`${path}-label}`}
          >
            {label}
          </InputLabel>
        )}
        <FormControl
          fullWidth
          error={!!(errors && core?.validationMode === "ValidateAndShow")}
        >
          <Select
            labelId={`${path}-label}`}
            id={`${path}-select}`}
            value={value}
            onChange={handleChangeSelect}
            renderValue={
              value
                ? undefined
                : () => (
                    <Box component={"div"} sx={{ color: "#aaa" }}>
                      {"Select"}
                    </Box>
                  )
            }
            displayEmpty
          >
            {formTemplateList &&
              Object.values(formTemplateList).map((item, index) => {
                if (
                  item.id !== VITE_PRESCRIPTION_ID &&
                  item.id !== VITE_REFINEMENT &&
                  item.id !== VITE_RETAINER
                ) {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  )
                }
              })}
          </Select>
        </FormControl>
        <Button
          fullWidth
          onClick={() => {
            dispatchEvent(
              setIsRouteChangeBlocked({ isRouteChangeBlocked: false }),
            )
            setTimeout(() => {
              routeToTemplate(value)
            }, 100)
          }}
          sx={{
            display: !value ? "none" : "block",
            my: 2,
            borderRadius: "4px",
            background: "#E0E0E0",
            color: "#000000",
            boxShadow:
              "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
        >
          {schema.options.buttonText}
        </Button>
        {errors && core?.validationMode === "ValidateAndShow" && (
          <FormHelperText sx={{ marginLeft: 0, color: "error.main" }}>
            {errors}
          </FormHelperText>
        )}
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(UTemplateSelect)
