import { useEffect, useState } from "react"
import ReactGA4 from "react-ga4"

const { VITE_GA4 } = import.meta.env

export const useAnalytics = () => {
  const [initialized, setInitialized] = useState<boolean>(false)
  useEffect(() => {
    if (VITE_GA4) {
      ReactGA4.initialize(VITE_GA4)
      setInitialized(true)
    }
  }, [])
  return initialized
}

export default useAnalytics
