import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { UButton, UText } from "../../../../components"
import { Grid } from "../../../../components/mui.components"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"

const EditHeader: FC = () => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const { patientId } = useParams()

  return (
    <Grid
      container
      direction={"row"}
      spacing={0}
      justifyContent={"space-between"}
      sx={{ pt: 2 }}
    >
      <UText variant={"h5"}>{t("userProfile.edit")}</UText>
      <UButton
        variant={"shade"}
        btnType={"button"}
        btnText={t("button.cancel")}
        sxProp={{ fontSize: "13px", p: "4px 10px", height: "30px" }}
        onClickHandler={() => navigate(`overview/patient/${patientId}`)}
      />
    </Grid>
  )
}

export default EditHeader
