import React, { FC, ReactNode } from "react"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material"
interface DialogBoxProps {
  title: string
  content: ReactNode
  actions: ReactNode
  open: boolean
}
const CustomDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "444px",
  },
  "& .MuiDialogTitle-root": {
    color: "#fff",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "0.15px",
  },
  //   "& .MuiDialogContent-root": {
  //     padding: 0,
  //     marginTop: "30px",
  //     letterSpacing: "-0.5px",
  //     position: "relative",
  //     fontWeight: "normal",
  //   },
}))

const DialogBox: FC<DialogBoxProps> = ({ open, title, content, actions }) => {
  return (
    <>
      <CustomDialogBox
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </CustomDialogBox>
    </>
  )
}

export default DialogBox
