import { useEffect, useState } from "react"
import { Box, ThemeProvider, Stack } from "@mui/material"
import { lightTheme, darkTheme } from "./theme/UDThemes"

import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"

import WasmCanvas from "@/wasm3d/WasmCanvas"
import { TopToolBar } from "./top/TopToolBar"
import { LeftToolBar } from "./left/LeftToolBar"
import { LeftPages } from "./left/LeftPages"
import { RightToolBar } from "./right/RightToolBar"
import { RightPages } from "./right/RightPages"
import { UDOpenCase } from "./UDOpenCase"
import { UDLockCaseForEdit } from "./UDLockCaseForEdit"

import { setCurRightPage } from "./udTreatSlice"
import { BottomToolBar } from "./bottom/BottomToolBar"
import { BottomStatusBar } from "./bottom/BottomStatusBar"
import { UDSmartRxWorkflow } from "./workflow/smartrx/UDSmartRxWorkflow"
import { UDRetainerRxWorkflow } from "./workflow/retainer/UDRetainerWorkflow"
import { UploadSTL } from "./components/UploadSTL"
import { UDChairsideWorkflow } from "./workflow/chairside/UDChairsideWorkflow"
import UDToothInfo from "./UDToothInfo/UDToothInfo"
import ExtractedToothList from "./UDToothInfo/ExtractedToothList"
import SmartRx from "./components/SmartRx"
import { ThemeSwitch } from "./top/ThemeSwitch"
import { useParams } from "react-router-dom"
import { WeStageBar } from "./WeDesign/WeStageBar"
import { KFEditor } from "./WeDesign/KFEditor"
import BiteRampButtons from "./UDesign/Attachments/BiteRampButtons"
import { UDBanner } from "./Banner/UDBanner"
import { ReopenCaseDlg } from "./components/ReopenCaseDlg"
import { useRef } from "react"
import {
  getCaseById,
  updateCaseById,
} from "@/core/app/slices/case/caseThunkApi"
import ToothHover from "@/modules/Clinical/components/ToothHover/toothhover"
import CaseLockPopup from "@/modules/Clinical/components/CaseLockPopup/caseLockPopup"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import {
  forcelockCaseApi,
  lockCaseApi,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import OrderPopup from "@/modules/Clinical/components/CloseCase/orderPopup"
import { ScreeshotReport } from "@/modules/Clinical/components/ReportPDF/screenshotReport"
import {
  caseLockByCRB,
  caseLockByOtherUser,
  caseLockByShipped,
  caseLockByUassist,
} from "./bottom/Toggle/column"
import { caseManagement } from "@/gluelayer"
export const UDTreat = () => {
  const dispatch = useAppDispatch()
  const {
    curCaseMode,
    workflowMode,
    isCasePreview,
    showBottomStatusBar,
    planList,
    reportData,
  } = useAppSelector((state: RootState) => state.udTreatService)

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { txplanSubname, txplanName } = caseDetail
  const { caseId, patientId } = useParams()
  const [openCase, setOpenCase] = useState(true)
  const [darkMode, setDarkMode] = useState(false)
  const [openWarning, setOpenWarning] = useState(true)
  const pageRef = useRef(null)
  useEffect(() => {
    dispatch(getCaseById({ patientId, caseId }))
  }, [])
  // order states
  const [isClickOrder, setisClickOrder] = useState(false)
  // case lock popup
  const [openLockPopup, setOpenLockPopup] = useState<boolean>(false)
  // case lock type
  //-1--- CRB  ,0---lock by uassist,   1---order been place,  2----edit by other user  ,
  const [lockType, setLockType] = useState<number>(2)
  const [ispopupAfterOrder, setispopupAfterOrder] = useState(false)
  // lock by username and machine
  const [lockInfo, setLockInfo] = useState<{
    username: string
    device_info: string
    locked_date: string
  }>({ username: "", device_info: "", locked_date: "" })
  const [isEdit, setIsEdit] = useState(false)

  // click lock control or override button
  const clickControlOverride = () => {
    dispatch(forcelockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        setIsEdit(true)
        setOpenLockPopup(false)
      }
    })
  }
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isEdit)
  // current tx
  const [currentPlan, setCurrentPlan] = useState<{
    txName: string
    index: 2 | 1
  }>({})
  //max refine
  const [maxRefine, setMaxRefine] = useState<number>(0)
  // pdf shoot
  const reportPdfRef = useRef(null)
  const openOrUploadReportPdf = (status: "open" | "upload") => {
    reportPdfRef?.current.uploadReportPDF(status)
  }
  // lock case function
  // lock post request
  const lockFunc = (callBack?: () => void) => {
    dispatch(lockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        callBack && callBack()
      } else {
        const { errors, username, device_info, locked_date } = payload.data
        const { error_message } = errors[0]
        const info = error_message.split("::")[0]
        if (caseLockByUassist.includes(info)) {
          setLockType(0)
          setOpenLockPopup(true)
        } else if (caseLockByShipped.includes(info)) {
          setLockType(1)
          setOpenLockPopup(true)
        } else if (caseLockByOtherUser.includes(info)) {
          setLockInfo({ username, device_info, locked_date })
          setLockType(isEdit ? 3 : 2)
          setOpenLockPopup(true)
        } else if (caseLockByCRB.includes(info)) {
          setLockType(-1)
          setOpenLockPopup(true)
        }
      }
    })
  }
  /**
   * update the case detail by the case id
   * @param txname:the active case
   * @param other: the other payload for the case detail api
   */
  const updateCaseFun = ({ txname = planList[0].txName, ...other }) => {
    const extralInfo = caseManagement.getCaseExtraInfomation()
    extralInfo.UpperRetainer = 1
    extralInfo.LowerRetainer = 1
    let newUdesignJsonObj = {}
    if (caseDetail && caseDetail.udesign_json) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      //Merge with existing JSON.
      newUdesignJsonObj = {
        ...udesign_json,
        ...extralInfo,
      }
    } else {
      newUdesignJsonObj = { ...extralInfo }
    }
    const txsubname = maxRefine
      ? `Course Refinement ${maxRefine}`
      : planList[0].txName !== txname
      ? planList[0].txName
      : planList[1]?.txName || ""
    dispatch(
      updateCaseById({
        patientId,
        caseId,
        payload: {
          case_extra_attrs: JSON.stringify(newUdesignJsonObj),
          txplan_name: txname,
          txplan_subname: txsubname,
          ...other,
        },
      }),
    )
  }
  /**
   * upload all of the datas when close the case
   * @param txname:active plan in the case(modifi plan or assign plan or order plan)
   * @param txsubname:inactive plan in the case
   * @param callback:the function after upload zips
   * @param other: the payload for upload case detail
   */
  const uploadAllData = ({
    txname = txplanName,
    txsubname = txplanSubname,
    callback = () => {
      return
    },
    ...other
  }: {
    txname?: string
    txsubname?: string
    callback: () => void
    other
  }) => {
    if (!txname) {
      // if there is no txname in the DB
      txname = planList[0]?.txName
    }
    if (!txsubname && planList[1]) {
      // if there is no txsubname in the DB
      txsubname = planList[1]?.txName
    }
    // if there is no plan
    if (!txname) {
      callback && callback()
      return
    }
    lockFunc(() => {
      updateCaseFun({ txname, txsubname, ...other })
      // update pdf
      openOrUploadReportPdf("upload")
      // update zips
      caseManagement.getCaseFiles(["bulk2"]).then((r) => {
        const zips = []
        if (r) {
          for (const k in r) {
            zips.push({
              fileName: k,
              file: r[k],
            })
          }
          dispatch(
            uploadCaseZips({
              zips,
              patientId,
              caseId,
            }),
          ).then(({ payload }) => {
            if (zips.length === payload.length) {
              for (let i = 0; i < payload.length; i++) {
                if (payload[i].status !== 200) {
                  return
                }
              }
              callback && callback()
            }
          })
        }
      })
    })
  }
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box
        ref={pageRef}
        sx={{
          display: "flex",
          top: 60,
          left: 0,
          bottom: 0,
          width: "100%",
          flexDirection: "column",
          border: "none",
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: "hidden" }} position="relative">
          <WasmCanvas />
          <TopToolBar
            setLockType={setLockType}
            setOpenLockPopup={setOpenLockPopup}
            setisClickOrder={setisClickOrder}
            setispopupAfterOrder={setispopupAfterOrder}
            setIsEdit={setIsEdit}
          />
          <ExtractedToothList />
          <ThemeSwitch
            sx={{
              position: "absolute",
              right: "180px",
              top: "-45px",
            }}
            value={darkMode}
            onChange={(e) => {
              setDarkMode(e.target.checked)
            }}
          />
          {workflowMode === "aidesign" && <LeftToolBar />}
          <LeftPages />
          <UDToothInfo isOpenCase={openCase} isEdit={isEdit} />
          {openCase && <ToothHover />}

          {workflowMode === "aidesign" && <RightToolBar />}
          <RightPages />
          <BiteRampButtons />
          <SmartRx />
          <UDOpenCase
            open={openCase}
            onClose={() => {
              setOpenCase(false)
            }}
          />
          <UDLockCaseForEdit />
          <UDBanner
            open={openWarning}
            onOK={() => {
              setOpenWarning(false)
            }}
          />
          {workflowMode === "smartrx" && <UDSmartRxWorkflow />}
          {workflowMode === "retainerrx" && <UDRetainerRxWorkflow />}
          {/* {showBottomStatusBar && <BottomStatusBar />} */}
          <BottomStatusBar />
          {/* curCaseMode === "WeDesign" && <WeStageBar /> */}
          {curCaseMode === "WeDesign" && <KFEditor />}
          <ReopenCaseDlg />
          <BottomToolBar parentRef={pageRef} />
        </Box>
        {/* {!isCasePreview && <BottomToolBar />} */}
        {/* case lock popup */}
        <CaseLockPopup
          lockType={lockType}
          openLockPopup={openLockPopup}
          setOpenLockPopup={setOpenLockPopup}
          lockInfo={lockInfo}
          setIsEdit={setIsEdit}
          clickControlOverride={clickControlOverride}
        />
        {/* if 2 plan popup*/}
        <OrderPopup
          currentPlan={currentPlan}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
          isClickOrder={
            isClickOrder && ispopupAfterOrder
            //&& isSubmitModification
          }
          setisClickOrder={() => {
            setisClickOrder(false)
            setispopupAfterOrder(false)
          }}
          planList={planList}
          uploadReportPdf={() => openOrUploadReportPdf("upload")}
          deletePlanFunction={() => {
            return
          }}
          lockFunc={lockFunc}
          uploadAllData={uploadAllData}
        ></OrderPopup>
        {/** Fill in the full report pdf */}
        <ScreeshotReport
          // isReady={preparation}
          isReady={true}
          attachment={reportData.attachment}
          toothlist={reportData.toothlist}
          iprData={reportData.iprData}
          ref={reportPdfRef}
        />
      </Box>
    </ThemeProvider>
  )
}
