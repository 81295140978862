import { FC } from "react"
import { useTranslation } from "react-i18next"

import { UText } from "../../../components"

import UploadPano from "./Pano"

const Xrays: FC<{
  showError: boolean
  scanErrors: boolean
  photographErrors: boolean
  xrayErrors: boolean
  setXrayErrors: (value: boolean) => void
  title: string
  description?: string
  isRefinement?: boolean
  isShowRecords?: boolean
}> = ({
  scanErrors,
  photographErrors,
  xrayErrors,
  setXrayErrors,
  showError,
  title,
  description,
  isRefinement,
  isShowRecords,
}) => {
  const { t } = useTranslation("common")
  return (
    <>
      <UText
        sxProp={{ display: "flex", justifyContent: "center" }}
        color={"text.primary"}
        variant={"h5"}
      >
        {title}
      </UText>
      {description && (
        <UText
          variant="caption"
          sxProp={{
            display: "flex",
            justifyContent: "center",
            color: "rgba(0,0,0,0.60)",
            mb: 1,
          }}
        >
          {description}
        </UText>
      )}
      {scanErrors && showError && (
        <UText
          sxProp={{ display: "flex", justifyContent: "center", mb: 1 }}
          variant={"caption"}
          color={"error"}
        >
          {t("records.errors.scanError")}
        </UText>
      )}
      {photographErrors && showError && (
        <UText
          sxProp={{ display: "flex", justifyContent: "center", mb: 1 }}
          variant={"caption"}
          color={"error"}
        >
          {t("records.errors.photographError")}
        </UText>
      )}
      {xrayErrors && showError && (
        <UText
          sxProp={{ display: "flex", justifyContent: "center" }}
          variant={"caption"}
          color={"error"}
        >
          {t("records.errors.xrayError")}
        </UText>
      )}
      {
        <UploadPano
          setXrayErrors={setXrayErrors}
          isRefinement={isRefinement}
          isShowRecords={isShowRecords}
        />
      }
    </>
  )
}
export default Xrays
