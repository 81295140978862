import React from 'react';
import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material";
import savePng from "../../assets/bottomToolBar/stage/save_stage.png";
import noSavePng from "../../assets/bottomToolBar/stage/no_save_stage.png";
import { Font } from '@react-pdf/renderer';

const ImageButton = (props) => {
  
  const [mouseEnterImg, setMouseEnterImg] = useState(false);

  return <div style={{display: "flex", 
       flexDirection: "row",
       width: props.width,
       height: props.height,
       background: "transparent",
  }}
  onClick={
    (event) => {
      props.onClick()
      event.stopPropagation()
    }
  }
  onMouseEnter={() => {if(props.enableHover) {setMouseEnterImg(true)}}}
  onMouseLeave={() => {if(props.enableHover) {setMouseEnterImg(false)}}}>
    <img 
      src={!mouseEnterImg ? (props.clicked ? props.backgroundSrcClicked : props.backgroundSrc) : props.backgroundSrcHovered}
      width={props.width}
      height={props.height}>
      
    </img>
  </div>

};

export default ImageButton;