import { FC, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { UButton, UText } from "../../components"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "../../components/mui.components"
import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"

export const PricingTnC: FC = () => {
  const { t } = useTranslation("common")
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const [showTnc, setShowTnc] = useState<boolean>(false)

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
      <Grid item>
        <Accordion sx={{ "&.MuiAccordion-root": { boxShadow: "none" } }}>
          <AccordionSummary
            id="pricingTnc"
            sx={{
              backgroundColor: "#EEEEEE",
              ">.MuiAccordionSummary-content": { display: "contents" },
            }}
          >
            <UButton
              sxProp={{
                color: "#0080AA",
                fontSize: "12px",
                fontWeight: "500",
                padding: "16px 24px",
                textDecoration: "underline",
              }}
              disableRipple
              onClickHandler={() => setShowTnc(!showTnc)}
              endIcon={showTnc ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              variant={"text"}
              btnText={
                showTnc
                  ? t("Pricing.tnc.button.hidedetails")
                  : t("Pricing.tnc.button.showdetails")
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={{ background: "#EEEE" }}>
            <Box component={"div"} marginBottom={2}>
              <UText variant="subtitle1" sxProp={{ fontWeight: "600" }}>
                {t("Pricing.tnc.title")}
              </UText>
            </Box>
            <UText variant="body2">
              <Trans components={{ newLine: <br /> }}>
                {countryCode !== "AU" && countryCode !== "NZ"
                  ? "Pricing.tnc.line1"
                  : "Pricing.tnc.anz.tnc"}
              </Trans>
            </UText>
            <br />
            <br />
            <Box component={"div"} marginBottom={2}>
              <UText variant="body1" sxProp={{ fontWeight: "600" }}>
                {t("Pricing.tnc.title2")}
              </UText>
            </Box>
            <UText variant="body2">
              <Trans components={{ newLine: <br /> }}>
                {countryCode !== "AU" && countryCode !== "NZ"
                  ? "Pricing.tnc.line2"
                  : "Pricing.tnc.anz.case"}
              </Trans>
            </UText>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}
