import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { Alert, Box } from "@mui/material"

export const UDSetupStatus = (props) => {
  const { disabled } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  return (
    disabled && (
      <Alert severity="warning">
        Autosetup is not activated yet. Please select and run one setup first.
      </Alert>
    )
  )
}
