import { FC } from "react"
import { Outlet } from "react-router-dom"

import { Grid } from "../components/mui.components"

const Organization: FC = () => {
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      minWidth={"lg"}
      sx={{ minHeight: "85vh" }}
    >
      <Grid container maxWidth={"lg"} flexDirection={"column"}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default Organization
