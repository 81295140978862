import { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Grid,
  IconButton,
  Stack,
  Button,
} from "@mui/material"
import PlusIcon from "@mui/icons-material/Add"
import PlusPlusIcon from "@mui/icons-material/AddCircleOutline"
import MinusIcon from "@mui/icons-material/Remove"
import MinusMinusIcon from "@mui/icons-material/RemoveCircleOutline"
import { string } from "prop-types"
export const UDTitleSelect = (props) => {
  return (
    <>
      <Grid display="flex" justifyItems="center" item xs={5}>
        <Typography variant="body1">{props.title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={7}>
        <UDSelectOptions {...props} />
      </Grid>
    </>
  )
}

export const UDTitleButtons = (props) => {
  return (
    <>
      <Grid display="flex" justifyItems="center" item xs={12}>
        <Typography variant="body1">{props.title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={12}>
        <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
          {props.options.map((option, index) => (
            <Button
              size="small"
              variant="outlined"
              key={index}
              value={index}
              onClick={() => {
                props.onClick(index)
              }}
            >
              {option}
            </Button>
          ))}
        </Stack>
      </Grid>
    </>
  )
}
export const UDTitleSelectOptions = (props) => {
  const [value, setValue] = useState(0)
  useEffect(() => {
    setValue(0)
  }, [])
  const handleChange = (event) => {
    setValue(event.target.value)
    props.onChangeSel?.(event.target.value)
  }
  return (
    <>
      <Grid display="flex" justifyItems="center" item xs={5}>
        <Typography variant="body1">{props.title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={7}>
        <Select
          sx={{ height: 35 }}
          value={value}
          onChange={handleChange}
          label={props.label}
          displayEmpty
        >
          {props.options.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  )
}
export const UDSelectOptions = (props) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    props.onChangeSel?.(value)
  }, [value])
  useEffect(() => {
    setValue(props.options[0])
  }, [])
  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 100 }}>
      <Select
        sx={{ height: 35 }}
        value={value}
        onChange={handleChange}
        label={props.label}
        displayEmpty
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const UDTitlePlusMinus = ({
  title,
  value,
  deltaSmall,
  deltaLarge,
  onChange,
  size = "medium",
}) => {
  const small = size === "small"
  return (
    <>
      <Grid
        display="flex"
        justifyItems="center"
        item
        xs={size === "small" ? 4 : 5}
      >
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value - deltaLarge)}>
          <MinusMinusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value - deltaSmall)}>
          <MinusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 2 : 3}>
        <Typography variant="body1">{value.toPrecision(2)}</Typography>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value + deltaSmall)}>
          <PlusIcon />
        </IconButton>
      </Grid>
      <Grid display="flex" justifyItems="center" item xs={small ? 1.5 : 1}>
        <IconButton onClick={() => onChange(value + deltaLarge)}>
          <PlusPlusIcon />
        </IconButton>
      </Grid>
    </>
  )
}
