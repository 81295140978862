import http from "../../../global/http"

export const doctorList = async (orgId: string) => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/assignees/`,
    )
    return data
  } catch (err) {
    return err
  }
}
