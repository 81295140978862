import Deserialize from "./interface/Deserialize"
import { ICustomPackaging } from "./interface/ICustomPackaging"

export class CustomPackaging implements ICustomPackaging, Deserialize {
  attachmentType: string
  createdDate: string
  createdOrg: number
  createdUser: number
  createdBy: string
  description: null
  fileName: string
  orgName: string
  status: number
  customPackagingName: string
  packagingStatus: string
  updateDate: string
  updateOrg: number
  updateUser: number
  boxLink: string
  topLink: string
  sideLink: string
  accountId: string

  Deserialize(input: any): this {
    if (input) {
      this.accountId = input.id
      this.attachmentType = input.attachment_type
      this.createdDate = input?.create_date || ""
      this.createdOrg = input.create_org
      this.createdUser = input.create_user
      this.createdBy = input.created_by
      this.description = input.description
      this.fileName = input.file_name
      this.orgName = input.org_name
      this.status = input.status
      this.packagingStatus = input?.status_obj?.name
      this.updateDate = input.update_date
      this.updateOrg = input.update_org
      this.updateUser = input.update_user
      this.boxLink = input.box_link
      this.topLink = input.top_link
      this.sideLink = input.side_link
      this.customPackagingName = input.custom_packaging_name
    }
    return this
  }
}
