import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { store } from "./core/app/store"
import App from "./App"

import "./index.scss"

// filter the ignorable errors
const originalConsoleError = console.error;
const errFilterList = [
  "Support for defaultProps will be removed",
]
console.error = (...args) => {

  const index = errFilterList.findIndex((item)=>{
    return args[0].includes(item)
  })
  if (index===-1) {
    originalConsoleError(...args);
  }
};

const contentElement = document.getElementById("root") as HTMLElement

ReactDOM.createRoot(contentElement).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)
