import { FC } from "react"
import { FieldErrors } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, SxProps } from "@mui/material"

import { FormAutoComplete, FormInput } from "../../../components"
import { validations } from "../../../core/utils/validations"
import { UText } from "../../../ui-component"

interface IQuestionAnswerInputProps {
  errors: FieldErrors
  control: any
  sxProps?: SxProps
  options: any
}

const QuestionAnswerInput: FC<IQuestionAnswerInputProps> = ({
  errors,
  control,
  sxProps,
  options,
}) => {
  const { t } = useTranslation("common")

  return (
    <>
      <Box sx={sxProps}>
        <UText variant={"body1"} color="#000">
          {t("auth.activation.secretQuestionTitle")}
        </UText>
        <FormAutoComplete
          inputLabel={"formfields.secretQuestion"}
          isInputLabel={true}
          isNotPlaceholder={true}
          fieldName={"secretQuestion"}
          rules={{
            required: "formfieldErrors.secretQuestionRequired",
          }}
          errors={errors}
          control={control}
          formSxprops={{
            mt: 1,
            mb: 2,
            ".MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink": {
              display: "none",
            },
            ".MuiOutlinedInput-notchedOutline > legend": {
              display: "none",
            },
            ".MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
              color: "text.secondary",
            },
            ".MuiFormLabel-root.MuiInputLabel-root.Mui-error.MuiInputLabel-shrink":
              {
                color: "error.main",
              },
          }}
          options={options}
        />
        <FormInput
          inputLabel={"formfields.answer"}
          fieldName={"answer"}
          control={control}
          errors={errors}
          rules={{
            required: "formfieldErrors.answerRequired",
            pattern: {
              value: validations.answer.pattern.value,
              message: "formfieldErrors.answerRequiredLenght",
            },
          }}
          formSxprops={{
            m: 0,
            ".MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
              color: "text.secondary",
            },
            ".MuiFormLabel-root.MuiInputLabel-root.Mui-error.MuiInputLabel-shrink":
              {
                color: "error.main",
              },
          }}
        />
      </Box>
    </>
  )
}

export default QuestionAnswerInput
