import { Event } from "./event";

export const globalEvents = new Event();

export enum GlobalEvents {
  // 浏览器加载wasm模块完成
  WASM_MODULE_LOADOK = "WasmModuleLoadOK",
  // case init 完成（在setup或者presetup wasm初始化case后调用）
  CASE_INIT_FINISHED = "CaseInitFinished",

  // 切换wasm canvas后的回调
  WASM_CANVAS_CHANGED = "wasmCanvasChanged",

  /**
   * args: CaseInfo
   */
  CLOSE_CASE = "CloseCase",

  /**
   * invoke during case modify,
   * args: ZipFileName[] 
   * type ZipFileName =
      | "bulk0"
      | `bulk1`
      | `bulk2`
      | `bulk10`
      | `raw`
      | `photo`;
   */
  ON_CASE_MODIFY = "OnCaseModify",

  ON_ATTACHMENT_BUTTON_CLICKED = "OnAttachMentBtnClicked",

  TOOTH_CORRECTION_TOGGLE_CLICKED = "OnToothCorrectionToggleClicked",

  /**
   * params =
   * 'trimline' | 'scissors'
   */
  TOOTH_CORRECTION_TRIMMING_CLICKED = "OnToothCorrectionTrimmingClicked",

  /**
   * params = 
   *  export enum EInoutType {
        Select = 'select',
        Deform = 'deform',
        Smooth = 'smooth',
        Reset = 'reset',
        BackOneStep = 'backonestep',
      }
   */
  TOOTH_CORRECTION_INOUT_CLICKED = "OnToothCorrectionInoutClicked",

  /**
   * 0-1
   */
  TOOTH_CORRECTION_INOUT_SMOOTHMESH = "ToothCorrectionInoutSmoothMesh",

  /**
   * export enum ERemovalType {
      Magicwand = 'magicwandtool',
      Pen = 'pentool',
      TrimAndFill = 'tftool',
      Tooth = 'toothtool',
      Reset = 'reset',
      Back = 'back',
    }
   */
  TOOTH_CORRECTION_REMOVAL_CLICKED = "ToothCorrectionInoutRemoval",

  // - trimming
  ON_TRIMING_CLICKED = "OnTrimmingClicked",
  ON_TRIMING_MODE_CHANGED = "OnTrimingModeChanged",
  ON_TRIMMING_DRAW = "OnTrimmingDraw",
  ON_TRIMMING_CUT = "OnTrimmingCut",
  ON_TRIMMING_UNDO = "OnTrimmingUndo",
  ON_TRIMMING_CLEAR = "OnTrimmingClear",

  // staging controller
  /**
   * params = { upStep: up, downStep: down }
   */
  ON_STAGESTEP_CHANGED = "OnStageStepChanged",
  /**
   * ViewType
   */
  VIEW_SWITCH_TYPE = "ViewSwitchType",

  /**
   * on report update event
   */
  ON_REPORT_UPDATE = "OnReportUpdate",
}
