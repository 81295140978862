import { FC, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react"

import { ULoading } from "../components"
import { useMyNavigation } from "../hooks/useMyNavigation"

const SecureRoute: FC = () => {
  const navigate = useMyNavigation()
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      navigate("auth/login")
    }
  }, [oktaAuth, authState?.isAuthenticated, authState, navigate])

  if (!authState || !authState?.isAuthenticated) {
    return <ULoading isLoading={true} />
  }

  return <Outlet />
}

export default SecureRoute
