import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Rnd } from "react-rnd"
import { update } from "@jsonforms/core"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  Stack,
  Typography,
} from "@mui/material"
import { Radio, RadioGroup, Switch } from "@mui/material"
import type { IToothPercentInfo } from "@/gluelayer"
import { stagingManager, viewControlInTreatment } from "@/gluelayer"
import { wasmModule } from "@/gluelayer"
import { GlobalEvents, globalEvents } from "@/gluelayer"
import { stagingPercent } from "@/gluelayer"

import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { FlowButton2 } from "../custom/FlowCustom"

import {
  setCurArch,
  setCurrentStep,
  setShowKFEditor,
  setUpdateStaging,
} from "../udTreatSlice"

import { useTheme } from "@mui/material/styles"

export const KFEditor = (props) => {
  const theme = useTheme()
  const { curArch, currentStep, updateStaging, showKFEditor } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [isForwardOnly, setIsForwardOnly] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [isKeyFrame, setIsKeyFrame] = useState(false)
  const [isInitFinalKF, setIsInitFinalKF] = useState(false)
  const [updateFlag, setUpdateFlag] = useState<boolean>(false)
  const [smoothPercent, setSmoothPercent] = useState(false)
  const [dragxy, setDragxy] = useState({ x: 100, y: 200 })

  const upKFs = useRef([])
  const lowKFs = useRef([])
  const weDesignMode = useRef(false)

  const updateKFs = () => {
    stagingManager.updateStagesFromWasm()
    let KFs = []
    const upNodes = stagingManager.wasmStageData.jointUpKeypoints
    for (let i = 0; i < upNodes.length; ++i) {
      if (upNodes[i].nodeType.length !== 0) {
        const idx = upNodes[i].stepindex
        KFs.push(idx)
      }
    }
    upKFs.current = KFs
    KFs = []
    const lowNodes = stagingManager.wasmStageData.jointLowerKeypoints
    for (let i = 0; i < lowNodes.length; ++i) {
      if (lowNodes[i].nodeType.length !== 0) {
        const idx = lowNodes[i].stepindex
        KFs.push(idx)
      }
    }
    lowKFs.current = KFs
    checkKeyFrame()
  }

  const getCurKFIdx = () => {
    const isUp = curArch === "up"
    let curKFIdx = -1
    if (isUp) {
      const curArchStage = stagingManager.stageupIndex
      curKFIdx = upKFs.current.findIndex((elem) => {
        return curArchStage === elem
      })
    } else {
      const curArchStage = stagingManager.stagelowIndex
      curKFIdx = lowKFs.current.findIndex((elem) => {
        return curArchStage === elem
      })
    }
    return curKFIdx
  }

  const setCurKFIdx = (KFIdx) => {
    if (KFIdx < 0) return
    const isUp = curArch === "up"
    const newStage = isUp ? upKFs.current[KFIdx] : lowKFs.current[KFIdx]
    dispatch(setCurrentStep(newStage))
  }

  const checkKeyFrame = () => {
    const isUp = curArch === "up"
    if (isUp) {
      const curArchStage = stagingManager.stageupIndex
      setIsInitFinalKF(
        curArchStage === 0 || curArchStage === stagingManager.stageUpnNumber,
      )
      const idx = upKFs.current.findIndex((elem) => {
        return curArchStage === elem
      })
      setIsKeyFrame(idx >= 0)
    } else {
      const curArchStage = stagingManager.stagelowIndex
      setIsInitFinalKF(
        curArchStage === 0 || curArchStage === stagingManager.stageLowNumber,
      )
      const idx = lowKFs.current.findIndex((elem) => {
        return curArchStage === elem
      })
      setIsKeyFrame(idx >= 0)
    }
  }

  const updateAll = () => {
    if (!wasmModule.isInit) return
    if (!weDesignMode.current) {
      wasmModule.module.getWrapperIns().SetWeDesignMode(true)
      weDesignMode.current = true
    }
    if (!showKFEditor) {
      stagingPercent.close()
    } else {
      if (curArch === "all") {
        dispatch(setShowKFEditor(false))
      } else {
        const curKFIdx = getCurKFIdx() // Get current key frame idx
        viewControlInTreatment.showArchMode(curArch === "up" ? "up" : "low")
        stagingPercent.update()
        stagingManager.updateStagesFromWasm()
        const percentData = stagingPercent.getTeethPercentInfo(
          curArch === "up" ? true : false,
        )
        setStagePercentData(percentData)
        updateKFs()
        setCurKFIdx(curKFIdx)
      }
    }
  }
  const idx = Array(16)
    .fill(0)
    .map((_, i) => i + 1)

  const initPercentData = () => {
    const initdata: Record<number, IToothPercentInfo> = {}
    idx.map((id) => {
      initdata[id] = {
        enable: false,
        angle: 0,
        curStepIndex: 0,
        dist: 0,
        newStepNum: 0,
        percent: 0,
        tID: id,
        moveVec: [],
      }
    })
    return initdata
  }

  const [stagePercentData, setStagePercentData] = useState<
    Record<number, IToothPercentInfo>
  >(initPercentData())

  const onToothChangePercent = (tid, newPercent) => {
    const tdata = stagePercentData[tid]
    if (!tdata) return
    if (smoothPercent) {
      // total 3 tooth
      const weights = [0.25, 0.5, 1.0, 0.5, 0.25]
      const arch = tid <= 16 ? 0 : 1
      for (let i = 0; i < 5; i++) {
        const tid1 = tid + i - 2
        const arch1 = tid1 <= 16 ? 0 : 1
        if (arch1 != arch) continue
        const tdata1 = stagePercentData[tid1]
        if (!tdata1 || tdata1.curStepIndex < 0 || !tdata1.enable)
          // there is a bug, enable is also true for missing teeth. but curStepIndex will be -1
          continue
        const w = weights[i]
        let percent = w * newPercent + (1 - w) * tdata1.percent
        percent = Math.max(0.0, Math.min(1.0, percent))
        stagingPercent.setToothMove(tid1, percent)
        if (isForwardOnly) {
          // stagePercentData.UpdateNote
        }
      }
    } else {
      // only one tooth
      stagingPercent.setToothMove(tid, newPercent)
    }
    ApplyChange(curArch)
  }

  const ApplyChange = (curArch) => {
    const isUp = curArch === "up"
    const curArchStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingPercent.addOrUpdateKeyFrame(
      isUp ? 0 : 1,
      curArchStage,
      isForwardOnly,
    )
    dispatch(setUpdateStaging())
  }

  const AddKeyFrame = (curArch) => {
    const isUp = curArch === "up"
    const curArchStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingManager.addNormalNode(isUp, curArchStage, false)
    dispatch(setUpdateStaging())
  }

  const DelKeyFrame = (curArch) => {
    const isUp = curArch === "up"
    const curArchStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingManager.deleteKeyNode(isUp, curArchStage)
    dispatch(setUpdateStaging())
  }

  const setShowManuStaging = (show: boolean) => {
    dispatch(setShowKFEditor(show))
  }

  const Top = () => {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        columns={12}
        alignItems="center"
      >
        <Grid item xs={4}>
          <FormControl size="small">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={curArch}
              value={curArch}
              onChange={(_, value) => {
                dispatch(setCurArch(value))
              }}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="up"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Upper</Typography>}
              />
              <FormControlLabel
                value="low"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Lower</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FlowButton2
            disabled={isInitFinalKF || !isKeyFrame}
            onClick={() => {
              ApplyChange(curArch)
            }}
          >
            Apply
          </FlowButton2>
        </Grid>
        <Grid item xs={2}>
          <FlowButton2
            disabled={isKeyFrame}
            onClick={() => {
              AddKeyFrame(curArch)
            }}
          >
            Add KF
          </FlowButton2>
        </Grid>
        <Grid item xs={2}>
          <FlowButton2
            disabled={isInitFinalKF || !isKeyFrame}
            onClick={() => {
              DelKeyFrame(curArch)
            }}
          >
            Del KF
          </FlowButton2>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={<Switch checked={isForwardOnly} />}
            onChange={(_, value) => {
              setIsForwardOnly(value)
            }}
            label="Forward Only"
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Switch
                checked={smoothPercent}
                onChange={(e) => {
                  setSmoothPercent(e.target.checked)
                }}
              />
            }
            label="Smooth Percent"
          />
        </Grid>
      </Grid>
    )
  }
  const Left = () => {
    return (
      <Grid item xs={1.5}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2">Tooth</Typography>
          <Stack
            sx={{ height: 200 }}
            direction="column"
            justifyContent="space-between"
          >
            <Typography variant="body2" align="right">
              100%
            </Typography>
            <Typography variant="body2" align="right">
              50%
            </Typography>
            <Typography variant="body2" align="right">
              0%
            </Typography>
          </Stack>
          <Typography variant="body2">Dist</Typography>
          <Typography variant="body2">Angle</Typography>
        </Stack>
      </Grid>
    )
  }
  const OneTooth = (toothProps: IToothPercentInfo) => {
    return (
      <Grid item xs={1}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" align="center">
            {toothProps.tID}
          </Typography>
          <Slider
            size="small"
            sx={{ height: 200 }}
            orientation="vertical"
            min={0}
            max={100}
            valueLabelDisplay="auto"
            defaultValue={Number.parseInt(
              (toothProps.percent * 100).toFixed(0),
            )}
            disabled={
              !toothProps.enable ||
              toothProps.curStepIndex < 0 ||
              !isKeyFrame ||
              isInitFinalKF
            }
            onChangeCommitted={(_, val) => {
              onToothChangePercent(toothProps.tID, (val as number) / 100)
            }}
          />
          <Typography variant="body2" align="center">
            {toothProps.dist === 0 ? 0 : toothProps.dist.toFixed(1)}
          </Typography>
          <Typography variant="body2" align="center">
            {toothProps.angle === 0 ? 0 : toothProps.angle.toFixed(1)}
          </Typography>
        </Stack>
      </Grid>
    )
  }
  const allTeeth = idx.map((id) => {
    const tID = id + (curArch === "up" ? 0 : 16)
    let percentinfo = stagePercentData[tID]
    if (!percentinfo)
      percentinfo = {
        enable: false,
        angle: 0,
        curStepIndex: 0,
        dist: 0,
        newStepNum: 0,
        percent: 0,
        tID: tID,
        moveVec: [],
      }
    return <OneTooth key={id} {...percentinfo} />
  })
  const PercentAllTeeth = () => {
    return (
      <Grid container spacing={0.1} alignItems="center" columns={17.5}>
        <Left />
        {allTeeth}
      </Grid>
    )
  }
  useEffect(() => {
    updateAll()
  }, [showKFEditor, curArch, updateStaging, updateFlag])

  useEffect(() => {
    globalEvents.on(GlobalEvents.ON_STAGESTEP_CHANGED, () => {
      setUpdateFlag((pre) => !pre)
    })
  }, [])

  return showKFEditor ? (
    <Rnd
      position={{ x: dragxy.x, y: dragxy.y }}
      onDragStop={(e, d) => {
        setDragxy({ x: d.x, y: d.y })
      }}
      // onDrag={dragEventHandler}
      default={{
        x: 15,
        y: 50,
        width: 600,
        height: 300,
      }}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      bounds="window"
      // enableUserSelectHack={false}
      dragHandleClassName="my-drag-handle"
    >
      <Card sx={{}}>
        <CardHeader
          className="my-drag-handle"
          sx={{ height: "48px", backgroundColor: theme.transBkColor.light }}
          action={
            <IconButton
              aria-label="settings"
              sx={{ width: 30, height: 30 }}
              onClick={() => {
                setShowManuStaging(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: "body1" }}
          title="Key Frame Editor"
        />
        <CardContent>
          <Top />
          <PercentAllTeeth />
        </CardContent>
      </Card>
    </Rnd>
  ) : (
    <></>
  )
}
