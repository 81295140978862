export const getResizedFile = async (file):Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      const originalWidth = img?.width
      const originalHeight = img?.height

      if (originalWidth && originalHeight) {
        const resolution = originalWidth * originalHeight
        if (resolution > 8000000) {
          const ratio = originalWidth / originalHeight
          const newHeight = Math.sqrt(8000000 / ratio)
          const newWidth = 8000000 / newHeight
          const canvas: HTMLCanvasElement = document.createElement("canvas")
          canvas.width = newWidth
          canvas.height = newHeight
          const ctx: any = canvas.getContext("2d") as CanvasRenderingContext2D
          ctx.drawImage(img, 0, 0, newWidth, newHeight)
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file?.name, {
              type: file?.type,
            })
            resolve(resizedFile)
          }, file?.type)
        } else {
          resolve(file)
        }
      }
    }
  })
}
