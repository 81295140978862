import { treatmentPlan } from "../treatmentplan";
import { wasmModule } from "../wasm/wasmModule";

class SuperimposeModule {
  /**
   * Show/hide superimpose
   * @param isShow
   */
  ShowSuperimpose(isShow: boolean) {
    wasmModule.wrapInstance.ShowSuperimpose(isShow);
  }

  /**
   * SetSuperimposeOpacity
   * @param opacity 0-1
   */
  SetSuperimposeOpacity(opacity: number) {
    wasmModule.wrapInstance.SetSuperimposeOpacity(opacity);
  }
  /**
   * SetSuperimposeStage
   * @param isUpper 0:upper 1:lower
   * @param stepIndex stage number
   */
  SetSuperimposeStage(isUpper: number, stepIndex: number) {
    const txNum = treatmentPlan.currentTxIndex;
    wasmModule.wrapInstance.SetSuperimposeStage(isUpper, stepIndex, txNum);
  }
}

export const superimposeModule = new SuperimposeModule();
