import http from "../../../global/http"
import { CustomPackaging } from "../../../model/customPackaging"
import { ICustomPackaging } from "../../../model/interface/ICustomPackaging"

export const getCustomPackagingListService = async (orgId: string) => {
  try {
    let customData: ICustomPackaging[] = []
    const rs: any = await http.get(
      `/user-mgmt/v2/org/${orgId}/account/custom-box`,
    )
    if (rs && rs.status === 200) {
      const { result } = rs.data
      if (result && result.length > 0) {
        result.forEach((element) => {
          customData.push(new CustomPackaging().Deserialize(element))
        })
        //only 12 records are allowed
        customData = customData.splice(0, 12)
      }
    }
    return {
      records: customData,
    }
  } catch (err) {
    return err
  }
}

export const uploadNewCustomBoxService = async (
  orgId: string,
  formData: FormData,
) => {
  const url = `/user-mgmt/v2/org/${orgId}/account/custom-box/`
  try {
    const res = http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res
  } catch (err) {
    return err
  }
}

export const downloadCustomBoxFiles = async (
  orgId: string,
  accountId: string | undefined,
  imageType: "top" | "side" | "box",
) => {
  const url = `/user-mgmt/v2/org/${orgId}/account/custom-box/${accountId}/${imageType}`
  try {
    const res = await http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}
