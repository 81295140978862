import * as React from "react"
import { Fragment, useEffect } from "react"
import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import { Checkbox, FormControlLabel, Slider } from "@mui/material"
import { gdsPlusSetup, IPRRange } from "@/gluelayer"
import { stagingManager } from "@/gluelayer"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup } from "@/UDTreat/udSetupSlice"
import { FlowBtnGroup, FlowNameSliderGrid } from "../../custom/FlowCustom"
import { setUpdateSetup, setUpdateStaging } from "../../udTreatSlice"
import { UDSetupTooth } from "@/UDTreat/UDesign/UDSetupTooth"
import { UDSetupStatus } from "@/UDTreat/UDesign/UDSetupStatus"

export const WeSetupIprSpace = (props) => {
  const { disabled, Params, selectedTooth } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )

  const upperArchIPRBtns = [
    { label: "Ant. Only", name: "upperAnt" },
    { label: "Full", name: "upperFull" },
    { label: "Inc. Only", name: "upperInc" },
    { label: "Canine Only", name: "upperCanine" },
  ]

  const lowerArchIPRBtns = [
    { label: "Ant. Only", name: "lowerAnt" },
    { label: "Full", name: "lowerFull" },
    { label: "Inc. Only", name: "lowerInc" },
    { label: "Canine Only", name: "lowerCanine" },
  ]

  const dispatch = useAppDispatch()

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "upperAnt":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.AntOnly)
        break
      case "upperFull":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.Full)
        break
      case "upperInc":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.IncisorOnly)
        break
      case "upperCanine":
        gdsPlusSetup.SetIPRRangeByID(true, IPRRange.CanineOnly)
        break
      case "lowerAnt":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.AntOnly)
        break
      case "lowerFull":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.Full)
        break
      case "lowerInc":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.IncisorOnly)
        break
      case "lowerCanine":
        gdsPlusSetup.SetIPRRangeByID(false, IPRRange.CanineOnly)
        break
    }
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  useEffect(() => {
    console.log("Start DrawIPRSpace")
    return () => {
      console.log("End DrawIPRSpace")
    }
  }, [])

  return (
    <Stack direction="column" spacing={1}>
      <UDSetupStatus />
      <Typography variant="body1">Arch IPR/Space</Typography>
      <FlowBtnGroup
        lineLabel={"Upper"}
        disabled={disabled}
        btnW={2.25}
        btnGroup={upperArchIPRBtns}
        onButtonClick={onButtonClick}
      />
      <FlowBtnGroup
        lineLabel={"Lower"}
        disabled={disabled}
        btnW={2.25}
        btnGroup={lowerArchIPRBtns}
        onButtonClick={onButtonClick}
      />
      <Divider />
      <UDSetupTooth />
    </Stack>
  )
}
