import { interpret, StateMachine, assign, spawn, send } from "xstate";
import { createMachine } from "xstate";
import { stop } from "xstate/lib/actions";
import { toothSpaceCorrection } from "./toothspacecorrection";
import { toothExtractions } from "./toothextractions";
import { trimmingModule } from "./trimming";
import { toothCorrenctionInout } from "./inout";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";
import { toothCorrenctionRemoval } from "./removal";
import { faccAndEdgeEditor } from "./faccedge";

export type ToothCorrectionToggleType =
  | "axis"
  | "model"
  | "groove"
  | "spacecorrection"
  | "extraction"
  | "inout"
  | "trimming"
  | "trimline"
  | "scissors"
  | "removal";

class ToothCorrections {
  stateMachine;
  currentState;
  service;
  constructor() {
    this.stateMachine = createMachine({
      id: "toothcorrecion",
      initial: "off",
      context: {
        axis: false,
        groove: false,
        model: false,
        space_correction: false,
        extraction: false,
        inout: false,
        removal: false,
        trim: false,
        scissors: false,
        removalRef: undefined as any,
        faccedgeRef2: undefined as any,
        faccedgeRef: undefined as any,
        spacecorrectionRef: undefined as any,
        extractionRef: undefined as any,
        inoutRef: undefined as any,
        trimmingRef: undefined as any,
      },
      states: {
        off: {},
        ready: {},
        axis: {
          entry: [
            assign({ axis: () => true }),
            assign({
              faccedgeRef: () =>
                spawn(faccAndEdgeEditor.state, "faccedgemodule"),
            }),
            send("TOGGLE_FACC", { to: (context: any) => context.faccedgeRef }),
          ],
          exit: [assign({ axis: () => false }), stop("faccedgemodule")],
        },
        groove: {
          entry: [
            assign({ groove: () => true }),
            assign({
              faccedgeRef2: () =>
                spawn(faccAndEdgeEditor.state, "faccedgemodule2"),
            }),
            send("TOGGLE_EDGE", { to: (context: any) => context.faccedgeRef2 }),
          ],
          exit: [assign({ groove: () => false }), stop("faccedgemodule2")],
        },
        model: {
          entry: [assign({ model: () => true })],
          exit: [assign({ model: () => false })],
        },
        spacecorrection: {
          entry: [
            assign({ space_correction: () => true }),
            assign({
              spacecorrectionRef: () =>
                spawn(toothSpaceCorrection.state, "spacecorrectionmodule"),
            }),
          ],
          exit: [
            assign({ space_correction: () => false }),
            stop("spacecorrectionmodule"),
          ],
        },
        extraction: {
          entry: [
            assign({ extraction: () => true }),
            assign({
              extractionRef: () =>
                spawn(toothExtractions.state, "extractionmodule"),
            }),
          ],
          exit: [assign({ extraction: () => false }), stop("extractionmodule")],
        },
        inout: {
          entry: [
            assign({ inout: () => true }),
            assign({
              inoutRef: () => spawn(toothCorrenctionInout.state, "inoutmodule"),
            }),
            context => {
              context.inoutRef.onTransition(state => {
                this._updateInoutUIState(state.context);
              });
            },
            () => {
              toothCorrenctionInout.toggleInout(true);
            },
          ],
          exit: [
            assign({ inout: () => false }),
            stop("inoutmodule"),
            () => {
              toothCorrenctionInout.toggleInout(false);
            },
          ],
        },
        removal: {
          entry: [
            assign({ removal: () => true }),
            assign({
              removalRef: () =>
                spawn(toothCorrenctionRemoval.state, "removalmodule"),
            }),
            context => {
              context.removalRef.onTransition(state => {
                console.log("on removalRef transition..........", state);
                this._updateRemovalUIState(state.context);
              });
            },
          ],
          exit: [assign({ removal: () => false }), stop("removalmodule")],
          on: {},
        },
        trimming: {
          entry: [
            assign({ trim: () => true }),
            assign({
              trimmingRef: () => spawn(trimmingModule.state, "trimmingmodule"),
            }),
            context => {
              context.trimmingRef.onTransition(state => {
                this._updateTrimmingUIState(state.context);
              });
            },
          ],
          exit: [assign({ trim: () => false }), stop("trimmingmodule")],
        },
      },
      on: {
        TOGGLE_SPACECORRECTION: [
          {
            target: "spacecorrection",
            cond: context => context.space_correction !== true,
          },
          {
            target: "off",
            cond: context => context.space_correction === true,
          },
        ],
        TOGGLE_AXIS: [
          {
            target: "axis",
            cond: context => context.axis !== true,
          },
          {
            target: "off",
            cond: context => context.axis === true,
          },
        ],
        TOGGLE_GROOVE: [
          {
            target: "groove",
            cond: context => context.groove !== true,
          },
          {
            target: "off",
            cond: context => context.groove === true,
          },
        ],
        TOGGLE_MODEL: [
          {
            target: "model",
            cond: context => context.model !== true,
          },
          {
            target: "off",
            cond: context => context.model === true,
          },
        ],
        TOGGLE_INOUT: [
          {
            target: "inout",
            cond: context => context.inout !== true,
          },
          {
            target: "off",
            cond: context => context.inout === true,
          },
        ],
        TOGGLR_REMOVAL: [
          {
            target: "removal",
            cond: context => context.removal !== true,
          },
          {
            target: "off",
            cond: context => context.removal === true,
          },
        ],
        TOGGLE_TRIM: [
          {
            target: "trimming",
            cond: context => context.trim !== true,
          },
          {
            target: "off",
            cond: context => context.trim === true,
          },
        ],
        TOGGLE_EXTRACTION: [
          {
            target: "extraction",
            cond: context => context.extraction !== true,
          },
          {
            target: "off",
            cond: context => context.extraction === true,
          },
        ],
      },
    });
  }

  /**
   * the interface of tooth correction clicked event.
   * @param actionType
   * @param setUplower
   * @returns
   */
  doAction(
    actionType: ToothCorrectionToggleType,
    setUplower: (item: "up" | "low") => void
  ) {
    if (!this.currentState) {
      const { initialState } = this.stateMachine;
      this.currentState = initialState;
      this.service = interpret(this.stateMachine);
      this.service.onTransition(state => {
        console.log("onTransition::::", state);
        // const { inoutRef } = state.context;
        // console.log('ref::', inoutRef, inoutRef?.getSnapshot());
        this._updateUIState();
      });
      this.service.start();
      console.log(">>>>USM start", this.service, actionType);
    }
    console.log("doaction11::", this.service, actionType);

    switch (actionType) {
      case "axis":
        this.service.send("TOGGLE_AXIS");
        break;
      case "groove":
        this.service.send("TOGGLE_GROOVE");
        break;
      case "model":
        this.service.send("TOGGLE_MODEL");
        break;
      case "spacecorrection":
        if (!this.service.state.context.space_correction) {
          toothSpaceCorrection.doAction(setUplower);
        } else {
          this.service.send("TOGGLE_SPACECORRECTION");
        }
        break;
      case "extraction":
        if (!this.service.state.context.extraction) {
          toothExtractions.doAction();
        } else {
          this.service.send("TOGGLE_EXTRACTION");
        }
        break;
      case "trimming":
        this.service.send("TOGGLE_TRIM");
        break;
      case "inout":
        this.service.send("TOGGLE_INOUT");
        break;
      case "removal":
        this.service.send("TOGGLR_REMOVAL");
        break;
      default:
        break;
    }

    switch (actionType) {
      // case 'spacecorrection':
      //   toothSpaceCorrection.doAction(setUplower);
      //   break;
      case "trimming":
        trimmingModule.doAction();
        break;
      // case 'inout':
      //   console.log('>>>>>>>>>>>>>>>>>>>>inout', actionType);
      //   toothCorrenctionInout.doAction();
      default:
        break;
    }
    // this._updateUIState();
    console.log("service.state", this.service);
  }

  /**
   * notify the UI update.
   */
  private _updateUIState() {
    const newstate = this.service.state.context;
    // getDvaApp()._store.dispatch({
    //   type: 'toothCorrection/setShowState',
    //   payload: { ...newstate },
    // });
  }

  /**
   * notify the removal sub UI update.
   */
  private _updateRemovalUIState(payload) {
    // getDvaApp()._store.dispatch({
    //   type: 'toothCorrection/setremovalState',
    //   payload: { ...payload },
    // });
  }

  /**
   * notify the UI update.
   */
  private _updateInoutUIState(payload) {
    // getDvaApp()._store.dispatch({
    //   type: 'toothCorrection/setinoutState',
    //   payload: { ...payload },
    // });
  }

  /**
   * notify the UI update.
   */
  private _updateTrimmingUIState(payload) {
    // getDvaApp()._store.dispatch({
    //   type: 'toothCorrection/setTrimState',
    //   payload: { ...payload },
    // });
  }
}

export const toothCorrectionModule = new ToothCorrections();

globalEvents.on(
  GlobalEvents.TOOTH_CORRECTION_TOGGLE_CLICKED,
  (actionType, setuplowcallback) => {
    toothCorrectionModule.doAction(actionType, setuplowcallback);
  }
);
