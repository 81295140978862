import { FC } from "react"
import { Box, Grid } from "@mui/material"
import { isEmpty } from "lodash"

import low from "../../assets/svgs/lower.svg"
import up from "../../assets/svgs/upper.svg"
import SvgBtn from "../../components/DarkMode/SvgButton/svgbtn"
import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"

import { tool, view, viewbox, zoom, zoomin, zoomout } from "./zoom.style"

interface zoomType {
  setisUpper: (arg: boolean) => void
  setzoomValue: (arg: number) => void
  zoomValue: number
  isUpper: boolean
  isLower: boolean
  setisLower: (arg: boolean) => void
}
const Zoom: FC<zoomType> = ({
  setisUpper,
  setzoomValue,
  zoomValue,
  isUpper,
  isLower,
  setisLower,
}) => {
  const { zipList, caseArch } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  return (
    <Grid
      container
      sx={{
        ...tool,
      }}
    >
      <Grid sx={view}>
        <SvgBtn
          svg={up}
          clickFun={() => setisUpper(!isUpper)}
          isdisable={caseArch !== "both"}
          boxSx={{
            ...viewbox,
            "&:hover": {
              backgroundColor: "#4E6670",
            },
            backgroundColor:
              isUpper || caseArch !== "both" ? "#455A64" : "#546E7A",
            opacity: 1,
            borderRight: "1px solid #455A64",
            borderEndStartRadius: "4px",
            borderStartStartRadius: "4px",
            borderRadius: "none",
          }}
          svgSx={{
            width: 24,
            height: 24,
          }}
        ></SvgBtn>
        <SvgBtn
          svg={low}
          clickFun={() => setisLower(!isLower)}
          isdisable={caseArch !== "both"}
          boxSx={{
            ...viewbox,
            "&:hover": {
              backgroundColor: "#4E6670",
            },
            borderEndEndRadius: "4px",
            borderStartEndRadius: "4px",
            backgroundColor:
              isLower || caseArch !== "both" ? "#455A64" : "#546E7A",
            opacity: 1,
            borderRadius: "none",
          }}
          svgSx={{
            width: 24,
            height: 24,
          }}
        ></SvgBtn>
      </Grid>
      <Grid container sx={zoom}>
        <Box
          component={"span"}
          sx={{
            ...zoomin,
            "&:hover": {
              backgroundColor: zoomValue > 0.25 ? "#4E6670" : "#546E7A",
            },
            borderEndStartRadius: 4,
            borderStartStartRadius: 4,
            "&:active": {
              backgroundColor: zoomValue > 0.25 ? "#455A64" : "#546E7A",
            },
            opacity: zoomValue > 0.25 ? 1 : 0.6,
          }}
          onClick={() => {
            if (zoomValue === 0.25) return
            setzoomValue(zoomValue - 0.25 < 0.25 ? 0.25 : zoomValue - 0.25)
          }}
        >
          -
        </Box>
        <Box
          component={"span"}
          sx={{
            ...zoomout,
            "&:hover": {
              backgroundColor: zoomValue < 2 ? "#4E6670" : "#546E7A",
            },
            borderEndEndRadius: 4,
            borderStartEndRadius: 4,
            "&:active": {
              backgroundColor: zoomValue < 2 ? "#455A64" : "#546E7A",
            },
            opacity: zoomValue < 2 ? 1 : 0.6,
          }}
          onClick={() => {
            if (isEmpty(zipList)) {
              return
            }
            if (zoomValue === 2) return
            setzoomValue(zoomValue + 0.25 > 2 ? 2 : zoomValue + 0.25)
          }}
        >
          +
        </Box>
      </Grid>
    </Grid>
  )
}
export default Zoom
