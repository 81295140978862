import { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { fetchBundlePricing } from "../../core/app/slices/pricing/pricingThunkApi"
import { RootState } from "../../core/app/store"
import { useError } from "../../Error.context"

import { OtherPricing } from "./otherPricing"
import { PricingHeader } from "./PricingHeader"
import PricingTable from "./PricingTable"
import { PricingTnC } from "./PricingTnc"
import { Volumes } from "./Volumes"

export interface PricingTableHeader {
  text: string
  value: string
  showBorder?: boolean
  subText?: string
}

const Pricing: FC = () => {
  const { bundleItems, pricingDate, volume, loading } = useAppSelector(
    (state: RootState) => state.PricingService,
  )
  const dispatch = useAppDispatch()
  const { setError } = useError()
  const navigate = useNavigate()
  const bundleHeaders: PricingTableHeader[] = [
    { text: "", value: "" },
    {
      text: "My pricing",
      value: "myprice",
      showBorder: true,
      subText: "Your current pricing",
    },
    { text: "Allstar", value: "all", showBorder: true },
    { text: "List ", value: "list" },
  ]
  useEffect(() => {
    !bundleItems &&
      dispatch(fetchBundlePricing()).then((res) => {
        const pricingRes = res?.payload
        if (pricingRes.status !== "Success") {
          const err = {
            code: pricingRes.status,
            message: "An error occurred",
          }
          setError(err)
          navigate("/error")
        }
      })
  }, [])
  return (
    loading === "succeeded" && (
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        minWidth={"lg"}
        sx={{ minHeight: "85vh", mb: 5 }}
      >
        <Grid
          container
          maxWidth={"lg"}
          flexDirection={"column"}
          sx={{
            display: "flex",
            alignItems: "center",
            mt: "100px",
            border: "1px solid #EEEE",
          }}
        >
          {pricingDate && <PricingHeader pricingDate={pricingDate} />}
          {volume && <Volumes volume={volume} />}
          {bundleItems && (
            <PricingTable
              bundleHeaders={bundleHeaders}
              bundleItems={bundleItems}
            />
          )}
          <OtherPricing />
          <PricingTnC />
        </Grid>
      </Grid>
    )
  )
}

export default Pricing
