import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
export const UploadCaseDlg = ({ open, onCancel, onOK }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle> Would you like to save this case?</DialogTitle>
      <DialogContent>
        <p> Would you like to save this case before closing?</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onOK}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
