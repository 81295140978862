import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { fetchFileList } from "../../../core/app/slices/clinical/clinicalThunkApi"
import { fetchFilesList } from "../../../core/app/slices/records/photograph/photographThunkApi"
import {
  fetchFormByCaseIdWithMergeData,
  fetchFormLists,
} from "../../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../../core/app/store"
import PhotoList from "../../Uassist/PhotoList"
import Prescription from "../../Uassist/USmartForms/Prescription"
import Retainer from "../../Uassist/USmartForms/Retainer"

const RxForm: FC<{
  postSettingTozips: () => void
  isPhotoAndRxForm?: string
  handleBack?: () => void
}> = ({ postSettingTozips, isPhotoAndRxForm, handleBack }) => {
  const { VITE_PRESCRIPTION_ID } = import.meta.env
  const dispatch = useAppDispatch()
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)
  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const { rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { patientId, caseId } = useParams()
  const [isRetainerForm, setIsRetainerForm] = useState(false)

  useEffect(() => {
    !formTemplateList.length && dispatch(fetchFormLists())
    dispatch(fetchFileList({ patientId, caseId }))
    if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])

  useEffect(() => {
    if (rxPrescription.id) return
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: VITE_PRESCRIPTION_ID,
        caseId: caseId,
        getSavedData: true,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (caseDetail) {
      const udesignCategory = caseDetail?.udesign_category
      if (udesignCategory) {
        const arches = udesignCategory.split("/")
        setIsRetainerForm(arches?.includes("R") && !arches?.includes("A"))
      }
    }
  }, [caseDetail])

  return (
    <Grid container>
      <Grid item md={5}>
        <PhotoList
          upperArchType={upperArchType}
          lowerArchType={lowerArchType}
        />
      </Grid>
      <Grid item md={7}>
        {!isRetainerForm && (
          <Prescription
            setUpperArchType={setUpperArchType}
            setLowerArchType={setLowerArchType}
            postSettingTozips={postSettingTozips}
            isPhotoAndRxForm={isPhotoAndRxForm}
            handleBack={handleBack}
          />
        )}
        {isRetainerForm && (
          <Retainer
            setUpperArchType={setUpperArchType}
            setLowerArchType={setLowerArchType}
            postSettingTozips={postSettingTozips}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default RxForm
