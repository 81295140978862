export const CENETER_COLUMN_ALIGN = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}
export const CENETER_ROW_ALIGN = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}
