import { FC, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import AlertBanner from "../../components/AlertBanner/AlertBanner"
import { CssBaseline, Grid } from "../../components/mui.components"
import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { isTokenValid } from "../../core/config/okta.config"
import { useMyNavigation } from "../../hooks/useMyNavigation"

import Footer from "./Footer/Footer"
import Header from "./Header/Header"

const MainLayout: FC = () => {
  const location = useLocation()
  const navigate = useMyNavigation()
  const isLogin = isTokenValid()
  const { user, systemBannerMessage, marketBannerMessage } = useAppSelector(
    (state: RootState) => state.userService,
  )
  useEffect(() => {
    const destinationPath = location.pathname
    if (isLogin) {
      if (location.pathname.includes("/auth") || location.pathname === "/") {
        navigate(user.isAllowToViewAllOrg ? "/organization" : "/patients")
      }
    } else {
      if (
        !location.pathname.includes("/auth") &&
        destinationPath != "/" &&
        destinationPath != "/patients" &&
        destinationPath != "/organization"
      ) {
        navigate(
          `/auth/login?redirectTo=${encodeURIComponent(destinationPath)}`,
          {
            replace: true,
          },
        )
      } else {
        navigate(`/auth/login`, {
          replace: true,
        })
      }
    }
  }, [location, user])
  return (
    <>
      {isLogin && (
        <>
          <CssBaseline />
          {systemBannerMessage?.id && (
            <AlertBanner
              bannerInfo={systemBannerMessage}
              isMarketingBanner={false}
            />
          )}
          {!systemBannerMessage?.id && marketBannerMessage?.id && (
            <AlertBanner
              bannerInfo={marketBannerMessage}
              isMarketingBanner={true}
            />
          )}

          <Header />
          <Grid
            sx={{
              background: "#F7F8F9",
              flexGrow: 1,
            }}
            container
            component={"main"}
          >
            <Outlet />
          </Grid>
          {!location.pathname.includes("/clinical/") && (
            <>
              <Footer />
              {systemBannerMessage?.id && marketBannerMessage?.id && (
                <AlertBanner
                  bannerInfo={marketBannerMessage}
                  isMarketingBanner={true}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default MainLayout
