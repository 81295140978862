import { CPlusArray } from "./types";

/**
 * Get JS Array From a CPlus Array
 * @param cpArray
 * @returns
 */
export function getArrayFromCPlusArray<T>(cpArray: CPlusArray<T>): T[] {
  const size = cpArray.size();
  const ret = [];
  for (let index = 0; index < size; index++) {
    const toothID = cpArray.get(index);
    ret.push(toothID);
  }
  return ret;
}
