import { FC, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { caseManagement } from "@/gluelayer"

import CloseIcon from "@/assets/svgs/close.svg"
import { FormAutoComplete, UButton, UText } from "@/components"
import { Backdrop, Box, Grid, IconButton } from "@/components/mui.components"
import PatientBasicInfo from "@/containers/LandingLayout/PatientInfoHeader/PatientBasicInfo"
import PatientTooltipContent from "@/containers/LandingLayout/PatientInfoHeader/PatientTooltipContent"
import UTooltip from "@/containers/LandingLayout/PatientInfoHeader/UTootip"
import { useAppSelector } from "@/core/app/hooks"
import { useAppDispatch } from "@/core/app/hooks"
import { updateCaseById } from "@/core/app/slices/case"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import {
  fetchFileList,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { fetchFilesList } from "@/core/app/slices/records/photograph"
import { fetchFormLists } from "@/core/app/slices/uassist/formThunkApi"
import { fetchClinicalSettings } from "@/core/app/slices/user/userApis"
import { RootState } from "@/core/app/store"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { CENETER_COLUMN_ALIGN } from "@/theme/theme.util"

import DentalPortrait from "@/modules/Uassist/DentalPortrait/DentalPortrait"
import Prescription from "./USmartForms/Prescription"
import Retainer from "./USmartForms/Retainer"
import SmartRxForm from "./USmartForms/SmartRxForm"
import Summary from "./USmartForms/Summary"
import OrderType from "./OrderType"
import PhotoList from "@/modules/Uassist/PhotoList"

// const UassistRoutes: FC<{
//   setUpperArchType: (val: number) => void
//   setLowerArchType: (val: number) => void
//   postSettingTozips: () => void
// }> = ({ setUpperArchType, setLowerArchType, postSettingTozips }) => {
//   return (
//     <Routes>
//       <Route
//         path={"prescription"}
//         element={
//           <Prescription
//             setUpperArchType={setUpperArchType}
//             setLowerArchType={setLowerArchType}
//             postSettingTozips={postSettingTozips}
//           />
//         }
//       />
//       <Route path={":formName/:formid"} element={<SmartRxForm />} />
//       <Route
//         path={"retainer"}
//         element={
//           <Retainer
//             setUpperArchType={setUpperArchType}
//             setLowerArchType={setLowerArchType}
//             postSettingTozips={postSettingTozips}
//           />
//         }
//       />
//       <Route path={"dentalchart"} element={<DentalPortrait />} />
//       <Route path={"ordertype"} element={<OrderType />} />
//       <Route path={"summary"} element={<Summary />} />
//       <Route path={"*"} element={<Navigate to={"dentalchart"} replace />} />
//     </Routes>
//   )
// }

const DentalMoudle: FC = ({ dentalGoNext }) => {
  const { t } = useTranslation()
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const preview = useRef<HTMLInputElement>(null)
  const [selectedPreview, setSelectedPreview] = useState("")
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)

  // const { zipList, zipNames } = useAppSelector(
  //   (state: RootState) => state.clinicalService,
  // )

  // const { user } = useAppSelector((state: RootState) => state.userService)

  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const { fileList, compositePhoto, individualPhotos, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService)

  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )

  const { routeFrom } = useAppSelector((state: RootState) => state.config)

  const { clinicalSettings, clinicalSettingsFetchStatus } = useAppSelector(
    (state: RootState) => state.userService,
  )

  useEffect(() => {
    dispatch(fetchClinicalSettings())
  }, [dispatch])

  const postSettingTozips = () => {
    if (!clinicalSettingsFetchStatus) return
    caseManagement
      .saveJsonDataToZip(
        JSON.stringify(clinicalSettings),
        "uassist_team_settings.json",
        "Setting Data2",
      )
      .then((res) => {
        if (res) {
          caseManagement.getCaseFiles(["bulk2"]).then((r) => {
            const zips = []
            if (r) {
              for (const k in r) {
                zips.push({
                  fileName: k,
                  file: r[k],
                })
              }
              dispatch(
                uploadCaseZips({
                  zips,
                  patientId,
                  caseId,
                }),
              )
            }
          })
        }
      })
  }

  // useEffect(() => {
  //   zipNames &&
  //     zipNames.length &&
  //     dispatch(
  //       fetchFileZips({
  //         patientId,
  //         caseId,
  //         zipNames,
  //       }),
  //     )
  // }, [zipNames])

  useEffect(() => {
    !formTemplateList.length && dispatch(fetchFormLists())
    // if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
    dispatch(
      fetchFileList({
        patientId,
        caseId,
        fileNames: ["raw", "bulk2"],
      }),
    )
    if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])

  useEffect(() => {
    const handleScroll = () => {
      if (preview && preview.current) {
        const { top, height } = preview.current.getBoundingClientRect()
        //To determine if the columns have wrapped
        const parent = preview.current.parentElement?.offsetWidth
        const form = preview.current.nextSibling?.offsetWidth
        if (parent && form) {
          const isSticky =
            top <= 0 &&
            top + height > 0 &&
            parent >= form + preview.current.offsetWidth + 45
          preview.current.classList.toggle("preview-sticky", isSticky)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [preview])

  const handleCloseButton = () => {
    if (routeFrom === "PATIENT_OVERVIEW_MODULE") {
      navigate(`/overview/patient/${patientId}/`)
    } else {
      navigate("/")
    }
  }
  //Clean up clincal zipNames, zipList, and other data to avoid entering clincal with errors
  useEffect(() => {
    return () => {
      dispatch(resetCase())
    }
  }, [])

  return (
    <>
      <Grid
        container
        gap={"45px"}
        marginBottom={"48px"}
        paddingTop={2}
        justifyContent={"center"}
      >
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          minWidth={"466px"}
          ref={preview}
          height={"720px"}
        >
          <Grid sx={{ my: 2 }} display={"flex"}>
            <PatientBasicInfo
              patientData={patientData}
              caseDetail={caseDetail}
            />
          </Grid>
          <PhotoList
            setSelectedPreview={setSelectedPreview}
            upperArchType={upperArchType}
            lowerArchType={lowerArchType}
          />
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          width={"640px"}
        >
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"flex-end"}
            height={82}
            padding={"16px 0"}
          >
            <UButton
              sxProp={{ height: 30, width: 63 }}
              variant={"shade"}
              btnType={"button"}
              btnText={t("button.close")}
              size={"small"}
              onClickHandler={() => handleCloseButton()}
            />
          </Box>
          {/* <UassistRoutes
            setUpperArchType={setUpperArchType}
            setLowerArchType={setLowerArchType}
            postSettingTozips={postSettingTozips}
          /> */}
          <DentalPortrait dentalGoNext={dentalGoNext} />
        </Grid>
      </Grid>
      {selectedPreview && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Box
              display="flex"
              justifyContent="center"
              position="fixed"
              top="120px"
              bottom="120px"
              left="120px"
              right="120px"
              overflow="hidden"
            >
              <Box
                id="backdrop"
                position="relative"
                width="inherit"
                height="inherit"
              >
                <img
                  src={selectedPreview}
                  alt={"fullscreenimg"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
                <Box
                  component={"div"}
                  id="closeicon"
                  position="absolute"
                  top="0px"
                  right="0px"
                  sx={{ padding: "8px" }}
                >
                  <IconButton
                    size="medium"
                    sx={{
                      fontSize: "large",
                      backgroundColor: "divider",
                      padding: "0px",
                      ":hover": { backgroundColor: "divider" },
                    }}
                    onClick={() => setSelectedPreview("")}
                  >
                    <Box
                      component={"img"}
                      src={CloseIcon}
                      alt={"Logo"}
                      loading="lazy"
                      sx={{ cursor: "pointer", padding: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      )}
    </>
  )
}
export default DentalMoudle
