import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { UText } from "../../../ui-component"

const ULabContactInfo = () => {
  const { t } = useTranslation("common")
  const { companyProfile } = useAppSelector(
    (state: RootState) => state.userService,
  )

  return (
    <>
      {companyProfile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 4,
            backgroundColor: "background.default",
            boxShadow: 1,
            borderRadius: 2,
            gap: 3,
            width: "100%",
          }}
        >
          <UText variant={"h6"}>{t("uLabAccount.contactInfo.title")}</UText>
          <Box
            sx={{
              display: "flex",
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
              }}
            >
              <UText variant={"subtitle2"}>
                {t("uLabAccount.contactInfo.info.subtitle1")}
              </UText>
              <UText variant={"body2"} color="text.secondary">
                {companyProfile?.accountOwner || "N/A"}
              </UText>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
              }}
            >
              <UText variant={"subtitle2"}>
                {t("uLabAccount.contactInfo.info.subtitle2")}
              </UText>
              <UText variant={"body2"} color="text.secondary">
                {companyProfile?.clinicalEdSpec || "N/A"}
              </UText>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ULabContactInfo
