import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { Box } from "@mui/material"
import { useOktaAuth } from "@okta/okta-react"

import { FormInput, UButton, UText } from "../../../components"
import { useAppDispatch } from "../../../core/app/hooks"
import { activateUser } from "../../../core/app/slices/user/userApis"
import {
  setErrorMessage,
  updateTokenExpired,
} from "../../../core/app/slices/user/userSlice"
import { validations } from "../../../core/utils/validations"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import ErrorAlert from "../components/ErrorAlert"
import PasswordInput from "../components/PasswordInput"

import QuestionAnswerInput from "./QuestionAnswerInput"
import ReadAndAgree from "./ReadAndAgree"

export interface IActivationUserForm {
  username: string
  password: string
  confirmPassword: string
  secretQuestion: string
  answer: string
  hasSixChar: boolean
  hasSpecialChar: boolean
  termsAndConditions: boolean
  privacyPolicy: boolean
}

const ActivationUser: FC = () => {
  const { t } = useTranslation("common")
  const { oktaAuth } = useOktaAuth()
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const [searchParams] = useSearchParams()
  const [activationInfo, setActivationInfo] = useState({
    userId: "",
    locale: "",
    username: "",
  })
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    setValue,
  } = useForm<IActivationUserForm>({ mode: "onSubmit" })

  const [errorMsg, setErrorMsg] = useState<string>("")
  const [options, setOptions] = useState<{ id: string; label: string }[]>([])

  const onSubmit: SubmitHandler<IActivationUserForm> = async (data) => {
    setErrorMsg("")
    dispatch(
      activateUser({
        tou_accepted_flag: "true",
        lang_code: activationInfo?.locale.split("_")[0],
        country_code: activationInfo?.locale.split("_")[1],
        user_password: data.confirmPassword,
        okta_user_id: activationInfo?.userId,
        security_question: {
          question: options.find((option) => option.id === data.secretQuestion)
            ?.label,
          answer: data.answer,
        },
        roles: ["Reviewer"],
      }),
    ).then((response) => {
      if (response?.payload?.status === "Success") {
        navigate("/auth/login")
      } else {
        setErrorMsg(response?.payload?.data?.errors[0]?.error_message)
      }
    })
  }

  useEffect(() => {
    if (searchParams.get("token")) {
      oktaAuth &&
        oktaAuth
          .verifyRecoveryToken({ recoveryToken: searchParams.get("token") })
          .then((response) => {
            if (response) {
              const { user } = response
              setValue("username", user?.profile?.login)
              setActivationInfo({
                userId: user?.id,
                locale: user?.profile?.locale,
                username: user?.profile?.login,
              })
              generateSecretQuestions()
            }
          })
          .catch((error) => {
            if (error?.errorCode === "E0000105") {
              dispatch(updateTokenExpired("activation"))
            } else {
              dispatch(setErrorMessage(error?.errorSummary))
            }
            navigate("/auth/login")
          })
    } else {
      navigate("/auth/login")
    }
  }, [searchParams])

  const generateSecretQuestions = () => {
    const questions: { id: string; label: string }[] = []
    for (const index of Array(6).keys()) {
      questions.push({
        id: index.toString(),
        label: t("auth.activation.secretQuestions.question" + index),
      })
    }
    setOptions(questions)
  }

  return (
    <>
      {errorMsg && <ErrorAlert message={errorMsg} />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          padding: "16px 0px",
          mb: 2,
        }}
      >
        <UText
          variant={"h4"}
          color={"text.primary"}
          sxProp={{
            textAlign: "center",
          }}
        >
          {t("auth.activation.title")}
        </UText>
        <UText
          variant={"body2"}
          color={"text.secondary"}
          sxProp={{
            textAlign: "center",
          }}
        >
          {t("auth.activation.subtitle")}
        </UText>
      </Box>
      <Box
        component={"form"}
        data-testid={"activation-form"}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          inputLabel={"formfields.email"}
          fieldName={"username"}
          rules={{
            pattern: {
              value: validations.email.pattern.value,
              message: "formfieldErrors.userEmailinvalid",
            },
          }}
          formSxprops={{
            mb: 3,
          }}
          disabled={true}
          errors={errors}
          control={control}
        />
        <PasswordInput
          sxProps={{
            mb: 4,
          }}
          setError={setError}
          clearErrors={clearErrors}
          setValue={setValue}
          formKey={"activation-form"}
          control={control}
          errors={errors}
          watch={watch}
        />

        <QuestionAnswerInput
          sxProps={{
            mb: 3,
          }}
          options={options}
          control={control}
          errors={errors}
        />
        <ReadAndAgree
          control={control}
          sxProps={{
            mb: 3,
          }}
        />
        <UButton
          size={"large"}
          sxProp={{
            width: "100%",
            fontSize: "15px",
            letterSpacing: "0.46px",
            lineHeight: "173.333%",
            color: "background.default",
          }}
          variant={"contained"}
          btnType={"submit"}
          btnText={t("auth.activation.activateBtn")}
        />
      </Box>
    </>
  )
}

export default ActivationUser
