import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { UDTabs } from "../custom/UDTabs"
import { UDSetupType } from "./UDSetupType"
import { UDSetupTooth } from "./UDSetupTooth"
import { UDSetupArchform } from "./UDSetupArchform"
import { gdsPlusSetup, wasmModule } from "@/gluelayer"
import { setLockCaseForEdit } from "../udTreatSlice"
import { RightPage } from "../right/RightPage"

export const UDSetup = (props) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!wasmModule.isInit) return
    dispatch(setLockCaseForEdit("Start"))
    gdsPlusSetup.SwitchAutoSetupModule(true)
    return () => {
      console.log("End chairside setup")
      gdsPlusSetup.SwitchAutoSetupModule(false)
    }
  }, [])
  return (
    <RightPage
      title="uDesign Setup"
      onClose={props.onClose}
      content={
        <UDTabs labels={["Setup Type", "Tooth", "Arch"]}>
          <UDSetupType />
          <UDSetupTooth />
          <UDSetupArchform />
        </UDTabs>
      }
    />
  )
}
