import { FC, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Close from "../../../../assets/images/clinical/closeBolton.svg"
import placeHolder from "../../../../assets/images/placeholder.png"
import zoomin from "../../../../assets/svgs/AddFilled.svg"
import zoomout from "../../../../assets/svgs/RemoveFilled.svg"
import subtract from "../../../../assets/svgs/Subtract.svg"
import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
  UText,
} from "../../../../components"
import {
  Box,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import {
  deleteProfilePhoto,
  uploadProfilePhoto,
} from "../../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../../core/app/store"
import { convertMBtoKB } from "../../../../core/utils/formatters"

const UploadPatientPhoto: FC = () => {
  const { t } = useTranslation("common")
  const containerRef = useRef(null)
  const { profile } = useAppSelector((state: RootState) => state.xrayService)
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const { patientId } = useParams()
  const dispatch = useAppDispatch()
  const [uploadPhoto, setUploadPhoto] = useState<boolean>(false)
  const [progressValue, setProgressValues] = useState<number>(0)
  const [files, setFiles] = useState<File[]>([])
  const [zoom, setZoom] = useState<string | null>("zoomout")
  const [zoomFactor, setZoomFactor] = useState<number>(1.0)

  const selectedProfilePic = (files: FileList): void => {
    setFiles([...files])
  }

  useEffect(() => {
    if (files.length > 0) {
      setTimeout(() => {
        setProgressValues(100)
      }, 3000)
    }
  }, [files])

  useEffect(() => {
    if (profile[0]?.src) {
      setProgressValues(100)
    } else if (!profile[0]?.src) {
      setProgressValues(0)
      setFiles([])
    }
  }, [profile])

  const cropProfilePic = () => {
    const canvas = document.getElementById("myCanvas")
    canvas.height = "240"
    canvas.width = "240"
    const ctx = canvas.getContext("2d")
    const img = document.getElementById("myimg")
    const newWidth = img.width * zoomFactor
    const newHeight = img.height * zoomFactor
    const newX = (canvas.width - newWidth) / 2
    const newY = (canvas.height - newHeight) / 2
    ctx.drawImage(img, newX, newY, newWidth, newHeight)
  }

  const saveProfilePic = () => {
    cropProfilePic()
    const canvas = document.getElementById("myCanvas")
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      const newFile = new File(
        [blob],
        `${patientId}.${files[0].type.split("/")[1]}`,
        {
          type: files[0].type,
        },
      )
      const formData = new FormData()
      formData.append(
        "attachment",
        new File([blob], newFile.name, {
          type: files[0].type,
        }),
      )
      formData.append("original_file_name", files[0].name)
      dispatch(
        uploadProfilePhoto({
          file: newFile,
          patientId: patientId,
          formData: formData,
          fileName: newFile.name,
        }),
      )
    })
  }

  const handleZoom = () => {
    setZoom("zoomin")
    setZoomFactor(zoomFactor + 0.1)
    cropProfilePic()
  }

  const handleZoomOut = () => {
    setZoom("zoomout")
    setZoomFactor(zoomFactor - 0.1)
    cropProfilePic()
  }

  const deletePhoto = () => {
    if (profile[0]?.src && fileList) {
      const profileFileName = fileList.find(
        (file) => file.attachment_type === "avatar",
      )
      dispatch(
        deleteProfilePhoto({
          patientId: patientId,
          fileName: profileFileName?.file_name || profile[0]?.fileName,
        }),
      )
      setUploadPhoto(false)
    } else {
      setProgressValues(profile[0]?.src ? 100 : 0)
      setFiles([])
    }
  }
  return (
    <>
      <Box
        component={"img"}
        src={profile[0]?.src || placeHolder}
        alt={"Logo"}
        loading="lazy"
        sx={{ mr: 2, height: "96px", width: "96px" }}
      />
      <Box component={"div"} sx={{ display: "flex", flexDirection: "column" }}>
        <UButton
          sxProp={{
            height: "30px",
            padding: "4px 10px",
            border: "1px solid rgba(33, 150, 243, 0.5)",
          }}
          variant={"outlined"}
          btnType={"button"}
          btnText={profile[0]?.src ? "EDIT Photo" : t("button.uploadPhoto")}
          onClickHandler={() => {
            setUploadPhoto(true)
          }}
        />
      </Box>
      <UModalPopup
        title={
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <UText variant="h6" component={"div"}>
              {t("overview.profile.patientPhoto")}
            </UText>
            <IconButton
              size="medium"
              onClick={() => {
                setUploadPhoto(false)
                setFiles([])
                setProgressValues(profile[0]?.src ? 100 : 0)
                setZoomFactor(1)
              }}
            >
              <Box component={"img"} src={Close}></Box>
            </IconButton>
          </Grid>
        }
        sxModalProps={{
          ".MuiDialog-paper": {
            paddingTop: 3,
            paddingBottom: 4,
            px: progressValue === 100 && files.length > 0 ? 4 : 5,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            minWidth: "400px",
            width: "fit-content",
            maxHeight: "fit-content",
            margin: 0,
          },
          "#titleCntr": {
            justifyContent:
              progressValue === 100 && files.length > 0 ? "left" : "center",
            pb: progressValue === 100 && files.length > 0 ? "2px" : 1,
          },
          "#contentCntr": {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          },
        }}
        content={
          <>
            {files.length === 0 && progressValue === 0 && (
              <UFileBox
                boxSize={{
                  height: "216px",
                  width: "463px",
                  margin: "10px",
                }}
                allowedFileExtensions={["png", "jpg", "jpeg", "bmp"]}
                allowedfileSize={convertMBtoKB(10)}
                fileAcceptType={"image/*"}
                id="userProfile"
                selectedFile={selectedProfilePic}
                fileLimit={1}
                isRequired={false}
                messages={{
                  fileNote: t("records.file.filenote", {
                    fileTypes: "JPG, PNG, BMP",
                    fileSize: 10,
                  }),
                  uploadButton: t("records.file.btnclicktoupload"),
                  uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                  invalidfileFormat: (
                    <Trans components={{ newLine: <br /> }}>
                      {"records.photograph.invalidfileformat"}
                    </Trans>
                  ),
                  invalidFileSize: t("records.file.invalidfilesize", {
                    fileSize: 10,
                  }),
                  invalidFileLimit: t("userProfile.uploadLimitError", {
                    noOfFiles: 1,
                  }),
                }}
              />
            )}
            {files.length > 0 && progressValue !== 100 && (
              <Box
                sx={{
                  py: "6px",
                  width: "483px",
                  px: "1px",
                }}
              >
                <UFileProcessingCard
                  fileName={files[0].name}
                  fileSize={files[0].size}
                  progressValue={100}
                  progressstatus={"loading"}
                  onRemove={() => setFiles([])}
                />
              </Box>
            )}
            {(profile[0]?.src || (files[0] && progressValue === 100)) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "240px",
                    height: "240px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    ref={containerRef}
                    sx={{
                      border: "1px solid rgba(0,0,0,0.4)",
                      borderRadius: "4px",
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      id="myimg"
                      src={
                        profile[0]?.src
                          ? profile[0].src
                          : URL.createObjectURL(files[0])
                      }
                      alt="profilePic"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        transform: `scale(${zoomFactor})`,
                      }}
                    />
                  </Box>
                  <img
                    src={subtract}
                    alt="profilePic"
                    style={{ position: "absolute" }}
                  />
                </Box>
                <ToggleButtonGroup value={zoom} exclusive>
                  <ToggleButton
                    value="zoomout"
                    onClick={handleZoomOut}
                    sx={{ width: "120px", height: "40px" }}
                  >
                    <Box component={"img"} src={zoomout}></Box>
                  </ToggleButton>
                  <ToggleButton
                    value="zoomin"
                    onClick={handleZoom}
                    sx={{ width: "120px", height: "40px" }}
                  >
                    <Box component={"img"} src={zoomin}></Box>
                  </ToggleButton>
                </ToggleButtonGroup>
                <UButton
                  variant={"outlined"}
                  sxProp={{
                    border: "1px solid rgba(211, 47, 47, 0.5)",
                    color: "error.main",
                    height: "36px",
                    width: "240px",
                    mt: 1,
                    "&:hover": {
                      border: "1px solid rgba(211, 47, 47, 0.5)",
                    },
                  }}
                  btnText={t("button.deletePhoto")}
                  onClickHandler={deletePhoto}
                ></UButton>
                <canvas id="myCanvas" style={{ display: "none" }}></canvas>
              </Box>
            )}
          </>
        }
        isAlert={uploadPhoto}
        btnList={[
          <>
            <Box sx={{ display: "flex", padding: 1 }}>
              <UButton
                variant={"text"}
                btnText={t("button.cancel")}
                key={"yes"}
                sxProp={{ width: "74px", height: "36px" }}
                onClickHandler={() => {
                  setUploadPhoto(false)
                  setFiles([])
                  setProgressValues(profile[0]?.src ? 100 : 0)
                  setZoomFactor(1)
                }}
              />
              <UButton
                variant={"contained"}
                btnText={t("button.save")}
                btnType={"submit"}
                onClickHandler={() => {
                  setUploadPhoto(false)
                  saveProfilePic()
                  setZoomFactor(1)
                }}
                sxProp={{ width: "69px", height: "36px", ml: 1 }}
              />
            </Box>
          </>,
        ]}
      />
    </>
  )
}

export default UploadPatientPhoto
