import { useState, useEffect } from "react"
import { Grid, Box, Typography, Menu, MenuItem } from "@mui/material"
import { FormControl, Divider, FormGroup } from "@mui/material"
import ToothMissing from "@mui/icons-material/Clear"
import ToothFix from "@mui/icons-material/LockOutlined"
import ToothImplant from "@mui/icons-material/RectangleOutlined"
import ToothPullout from "@mui/icons-material/Remove"
import ToothIconSvg from "../../assets/ToothIcon.svg"
import { gdsPlusSetup, wasmModule } from "@/gluelayer"
import { SvgIcon, SvgIconProps } from "@mui/material"
import { Tooltip, IconButton } from "@mui/material"

const ToothIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        stroke="black"
        strokeWidth={1.0}
        d="m9.09 3.69c-0.707-0.258-1.38-0.503-2.41-0.503-2.19 0-3.29 2.19-3.29 4.94 0 1.75 0.449 2.84 0.916 3.96 0.263 0.635 0.533 1.28 0.73 2.07 0.163 0.654 0.23 1.6 0.3 2.61 0.165 2.37 0.354 5.07 1.89 5.07 0.986 0 1.42-1.55 1.89-3.26 0.583-2.09 1.23-4.42 3.04-4.42 1.81 0 2.46 2.33 3.04 4.42 0.476 1.71 0.908 3.26 1.89 3.26 1.54 0 1.73-2.7 1.89-5.07 0.0702-1.01 0.136-1.95 0.3-2.61 0.198-0.791 0.467-1.44 0.73-2.07 0.467-1.13 0.916-2.21 0.916-3.96 0-2.74-1.1-4.94-3.29-4.94-1.04 0-1.71 0.245-2.41 0.503-0.79 0.289-1.63 0.594-3.07 0.594-1.45 0-2.28-0.306-3.07-0.594z"
      />
    </SvgIcon>
  )
}
export const AllToothType = ({ teethType, onSetToothType }) => {
  const toothTypes = [
    "Normal",
    "PullOut",
    "Implant",
    "Missing",
    "Fix",
    "Retainer",
    "AP",
  ]
  const TypeIcon = (props) => {
    return props.type == 0 ? ( // normal
      <></>
    ) : props.type == 1 ? ( // pullout
      <ToothPullout />
    ) : props.type == 2 ? ( // implant
      <ToothImplant />
    ) : props.type == 3 ? ( // Missing
      <ToothMissing />
    ) : props.type == 4 ? ( // no move (fix)
      <ToothFix />
    ) : props.type == 5 ? ( // Retainer,
      <></>
    ) : (
      // AP or other
      <></>
    )
  }
  const typeColorNames = [
    ["white", "normal"],
    ["red", "pullout"],
    ["grey", "implant"],
    ["black", "missing"],
    ["blue", " no move"],
    ["darkGreen", " retainer"],
    ["green", "AP"],
  ]
  const ToothTypeIconButton = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
    ) => {
      props.setType(props.tid, index)
      setAnchorEl(null)
    }
    const [typeName, setTypeName] = useState<string>("Normal")
    const [toothColor, setToothColor] = useState<string>("white")

    useEffect(() => {
      if (!props.type) return
      const type = props.type as number
      setToothColor(typeColorNames[type][0])
      setTypeName(typeColorNames[type][1])
    }, [props.type])
    return (
      <Grid display="flex" item xs={0.75} justifyContent={"center"}>
        <Tooltip placement="bottom" title={typeName} arrow>
          <IconButton onClick={handleClick}>
            <ToothIcon htmlColor={toothColor} />
          </IconButton>
        </Tooltip>
        {/* 
        <Box
          sx={{
            width: "100%",
            height: 30,
            border: 1,
            backgroundImage: `url(${ToothIconSvg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
          onClick={handleClick}
        >
          <TypeIcon type={props.type} />
        </Box>*/}
        <Menu
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {toothTypes.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === props.type}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    )
  }

  const OneToothType = (props) => {
    return (
      <Grid display="flex" item xs={0.75} justifyContent={"center"}>
        <Box width={"100%"} border={1}>
          {props.tid}
        </Box>
      </Grid>
    )
  }

  const toothIdsU = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
  const toothIdsL = [
    32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17,
  ]

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">Tooth Pullout / Do not Move</Typography>
      </Grid>
      {toothIdsU.map((id, index) => (
        <Grid
          key={index}
          display="flex"
          item
          xs={0.75}
          justifyContent={"center"}
        >
          <Box
            sx={{
              width: "100%",
              height: 30,
            }}
          >
            {id}
          </Box>
        </Grid>
      ))}
      <FormControl fullWidth>
        <FormGroup row>
          {toothIdsU.map((id, index) => (
            <ToothTypeIconButton
              key={id}
              tid={id}
              type={teethType[id - 1]}
              label={"T" + id}
              setType={onSetToothType}
            />
          ))}
        </FormGroup>
        <FormGroup row>
          {toothIdsL.map((id, index) => (
            <ToothTypeIconButton
              key={id}
              tid={id}
              type={teethType[id - 1]}
              setType={onSetToothType}
              label={"T" + id}
            />
          ))}
        </FormGroup>
      </FormControl>
      {toothIdsL.map((id, index) => (
        <Grid
          key={index}
          display="flex"
          item
          xs={0.75}
          justifyContent={"center"}
        >
          <Box
            sx={{
              width: "100%",
              height: 30,
            }}
          >
            {id}
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} />
      {/* typeColorNames.map((colorName, index) => (
        <Grid xs={1.714} flexDirection={"column"}>
          <ToothIcon htmlColor={colorName[0]} />
          <Typography>{colorName[1]} </Typography>
        </Grid>
      )) */}
    </>
  )
}
