import React from "react"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AccountCircle, Clear, Search } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material"

import { UButton } from "../../components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { resetCase } from "../../core/app/slices/clinical/clinicalSlice"
import { fetchPatientList } from "../../core/app/slices/patients"
import { addNewPatient } from "../../core/app/slices/patients"
import { AddNewPatientParams } from "../../core/app/slices/patients/patient.types"
import { setData } from "../../core/app/slices/patients/patientSlice"
import { RootState } from "../../core/app/store"
import { useMyNavigation } from "../../hooks/useMyNavigation"

import { UFlowNewPatient } from "./UFlowNewPatient"

const UFlowSearchCase = ({ onClickSearch }) => {
  const [searchText, setSerchText] = useState("")

  return (
    <>
      <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
        <InputLabel htmlFor="search-case">Search Case</InputLabel>
        <Input
          id="search-case"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  onClickSearch(searchText)
                }}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setSerchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(searchText)
            }
          }}
        />
      </FormControl>
    </>
  )
}

export const UFlowPatientList = () => {
  const navigate = useMyNavigation()
  const { patients, loading, paging } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const [openNewPatient, setOpenNewPatient] = useState(false)
  const dispatch = useAppDispatch()
  const { newPatientAPI, patientData, caseId } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const onChangePage = (newPage: number) => {
    dispatch(
      fetchPatientList({
        patientQueryparams: {
          page: newPage,
          page_size: 20,
        },
      }),
    )
  }
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  function toOpenList() {
    const { VITE_APP_TYPE } = import.meta.env
    return weSmileUI && VITE_APP_TYPE === "wesmile"
    // return true
  }
  function onClickSearch(searchText: string) {
    console.log("Search text ", searchText)
    dispatch(
      fetchPatientList({
        patientQueryparams: {
          page: 1,
          page_size: 20,
          search: searchText,
        },
      }),
    )
  }
  useEffect(() => {
    if (newPatientAPI === "succeeded" && weSmileUI) {
      dispatch(resetCase())
      navigate(`/clinical/patient/${patientData.id}/case/${caseId}/treat`)
    }
  }, [newPatientAPI])

  return toOpenList() ? (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <UButton
            variant={"contained"}
            btnType={"button"}
            btnText={"WeSmile New Patient"}
            onClickHandler={() => {
              setOpenNewPatient(true)
            }}
          />
        </Grid>
      </Grid>
      <UFlowNewPatient
        open={openNewPatient}
        onCancel={() => {
          setOpenNewPatient(false)
        }}
        onCreate={(firstname, lastname) => {
          setOpenNewPatient(false)
          const payload: AddNewPatientParams = {
            firstname,
            lastname,
            dob: "1991-01-01",
            assigned_to: 1,
          }
          // dispatch(addNewPatient(payload))
          dispatch(addNewPatient(payload)).then((res) => {
            if (res.payload?.data?.status === "Success") {
              dispatch(setData({ newPatientAPI: "idle" }))
              navigate("/patients")
            }
          })
        }}
      />
    </>
  ) : (
    <></>
  )
}
