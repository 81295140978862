import { FC } from "react"
import { useTranslation } from "react-i18next"

import accountHelp from "../../../assets/images/account-help.jpg"
import gettingStartedHelp from "../../../assets/images/getting-started-help.jpg"
import orderHelp from "../../../assets/images/order-help.jpg"
import patientListHelp from "../../../assets/images/patient-list-help.jpg"
import treatmentHelp from "../../../assets/images/treatment-help.jpg"
import uassistHelp from "../../../assets/images/uassist-help.jpg"
import ulabUniversity from "../../../assets/images/ulabUniversity.jpg"
import { useAppDispatch } from "../../../core/app/hooks"
import { fetchHelpDocuments } from "../../../core/app/slices/user/userApis"
import { UButton, UText } from "../../../ui-component"
import { Box, Divider, Grid, Link } from "../../../ui-component/mui.components"
import { convertBlobToPdfUrl } from "../../Patient/PatientList/util/commonUtil"

import HelpCard from "./HelpCard"

const HelpContent: FC = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const cardItems = [
    {
      title: "help.cards.card1.title",
      subtitle: "help.cards.card1.subtitle",
      image: gettingStartedHelp,
      fileName: "getting_started.pdf",
    },
    {
      title: "help.cards.card2.title",
      subtitle: "help.cards.card2.subtitle",
      image: accountHelp,
      fileName: "account_menu.pdf",
    },
    {
      title: "help.cards.card3.title",
      subtitle: "help.cards.card3.subtitle",
      image: patientListHelp,
      fileName: "patient_list.pdf",
    },
    {
      title: "help.cards.card4.title",
      subtitle: "help.cards.card4.subtitle",
      image: uassistHelp,
      fileName: "uassist_workflow.pdf",
    },
    {
      title: "help.cards.card5.title",
      subtitle: "help.cards.card5.subtitle",
      image: treatmentHelp,
      fileName: "treatment_plan_modification.pdf",
    },
    {
      title: "help.cards.card6.title",
      subtitle: "help.cards.card6.subtitle",
      image: orderHelp,
      fileName: "ordering_usmile_aligners.pdf",
    },
  ]

  const downloadHelpDoc = (fileName) => {
    dispatch(fetchHelpDocuments(fileName)).then((res: any) => {
      const link = document.createElement("a")
      link.href = convertBlobToPdfUrl(res.payload)
      // Open the downloaded PDF in a new tab
      window.open(link.href, "_blank")
    })
  }

  return (
    <Box
      data-testid={"helpContent"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          paddingY: 2,
        }}
      >
        <UText variant={"h4"}>{t("help.title")}</UText>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          gap: 2,
          borderRadius: 2,
          padding: "32px 40px 48px 40px",
          boxShadow: 1,
          width: "79.6%",
          backgroundColor: "background.default",
          marginBottom: 6,
        }}
      >
        <UText
          variant={"h5"}
          sxProp={{
            py: 2,
          }}
        >
          {t("help.helpDocuments")}
        </UText>
        <Grid
          sx={{
            paddingBottom: "24px",
          }}
          justifyContent={"center"}
          container
          rowGap={2}
          columnGap={3}
        >
          {cardItems.map((item, index) => {
            return (
              <Grid
                item
                lg={3}
                md={4}
                sm={10}
                xs={12}
                sx={{ "&.MuiGrid-item": { maxWidth: "100%", flexGrow: 1 } }}
                key={"card-" + index}
                onClick={() => downloadHelpDoc(item.fileName)}
              >
                <Link
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <HelpCard
                    title={item.title}
                    subtitle={item.subtitle}
                    imageUrl={item.image}
                  />
                </Link>
              </Grid>
            )
          })}
        </Grid>
        <Divider />
        <UText
          variant={"h5"}
          sxProp={{
            pt: "20px",
          }}
        >
          {t("help.uLabUniversity.title")}
        </UText>
        <Grid justifyContent={"center"} container rowGap={2} columnGap={3}>
          <Grid item lg={4} md={4}>
            <Box
              component={"img"}
              src={ulabUniversity}
              alt={"Ulab University"}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={7} md={7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UText
                sxProp={{
                  color: "#000",
                  paddingTop: 2,
                  paddingBottom: 3,
                }}
                variant={"body1"}
              >
                {t("help.uLabUniversity.content")}
              </UText>
              <UButton
                sxProp={{
                  fontSize: "15px",
                  letterSpacing: "0.46px",
                  lineHeight: "173.333%",
                }}
                onClickHandler={() => {
                  window.open(
                    "https://ulab.smiledesignuniversity.com/#/login",
                    "_blank",
                  )
                }}
                variant={"contained"}
                btnText={t("help.uLabUniversity.gotoBtn")}
                size={"large"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default HelpContent
