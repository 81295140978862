import { ElementType, FC, ReactNode } from "react"
import { SxProps } from "@mui/system"

import { Box, styled } from "../../ui-component/mui.components"

interface BlockProps {
  component: ElementType
  children: ReactNode
  sxProp?: SxProps
}

const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  boxShadow:
    "0px 0px 1px rgba(0, 0, 0, 0.31), 0px 8px 12px rgba(0, 0, 0, 0.15)",
  border: `1px solid #FFFFFF`,
  borderRadius: "32px",
  width: "500px",
  height: "auto",
  backgroundColor: "#FFFFFF",
}))

const Block: FC<BlockProps> = ({ component, sxProp, children }) => {
  return (
    <BoxWrapper component={component} sx={sxProp}>
      {children}
    </BoxWrapper>
  )
}

Block.defaultProps = {
  sxProp: undefined,
}

export default Block
