import Deserialize from "./interface/Deserialize"

interface DateTime {
  name: string
  datetime: string
}

interface Currency {
  name: string
  code: string
  display_symbol: string
  symbol_placement: string
}

interface Line {
  order_number: string
  date: string
  desc: string
  price: number
  qty: number
  total: number | null
}

interface CreditsLine {
  desc: string
  rate: string
  qty: string
  total: number
}

interface ShippingLine {
  order_number: string
  date: DateTime
  desc: string
  total: number
}

interface Orders {
  qty: number
  tax: number
  total: number | null
  lines: Line[]
}

interface Credits {
  total: number
  lines: CreditsLine[]
}

interface Shipping {
  tax: number
  total: number
  lines: ShippingLine[]
}

interface STL {
  date: string
  desc: string
  qty: string
  total: number
}

export interface DetailsByAddress {
  address: string
  usmile_orders: Orders
  uassist_orders: Orders
  currency: Currency
  credits: Credits
  shipping: Shipping
}

export interface Details {
  in_office_outputs: {
    lines: STL[]
  }
  details_by_address: DetailsByAddress[]
}

export interface DateObject {
  year: number
  month: number
}

export interface DateRange {
  start: DateObject
  end: DateObject
}

interface UInvoice {
  start_date: DateTime
  end_date: DateTime
  due_date: DateTime
  total_amount: number
  paid_amount: number
  remaining_amount: number
  currency: Currency
  discount: number
  invoice_number: string
  org_id: string
  credit_provided: number
  total_aligners: number
  total_retainers: number
  member_tiers: string
  invoice_url: string | null
  status: string
  details: Details
}
function deserializeDate(details: Details) {
  for (const addressDetails of details.details_by_address) {
    for (const orderType of ["usmile_orders", "uassist_orders", "shipping"]) {
      if (orderType in addressDetails) {
        const orders = addressDetails[orderType]!.lines
        for (const order of orders) {
          order.date = order.date.name
        }
      }
    }
  }
  return details
}

export class Invoice implements UInvoice, Deserialize {
  start_date = null
  end_date = null
  due_date = null
  total_amount = null
  paid_amount = null
  remaining_amount = null
  currency = null
  discount = null
  invoice_number = null
  org_id = null
  credit_provided = null
  total_aligners = null
  total_retainers = null
  member_tiers = null
  invoice_url = null
  status = null
  details = null

  Deserialize(input: any): this {
    if (input) {
      this.start_date = input.start_date
      this.end_date = input.end_date
      this.due_date = input.due_date
      this.total_amount = input.total_amount
      this.paid_amount = input.paid_amount
      this.remaining_amount = input.remaining_amount
      this.currency = input.currency
      this.discount = input.discount
      this.invoice_number = input.invoice_number
      this.org_id = input.org_id
      this.credit_provided = input.credit_provided
      this.total_aligners = input.total_aligners
      this.total_retainers = input.total_retainers
      this.member_tiers = input.member_tiers
      this.invoice_url = input.invoice_url
      this.status = input.status
      this.details = deserializeDate(input?.details)
    }
    return this
  }
}
