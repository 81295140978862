import { Button, Grid, Switch, Divider } from "@mui/material"
import { Stack } from "@mui/material"
import { UDTitlePlusMinus } from "../custom/UDSelectOptions"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { gdsPlusSetup } from "@/gluelayer"
import { updateAutoSetup } from "../udSetupSlice"
import { setUpdateSetup, setUpdateStaging } from "../udTreatSlice"

export const UDToothAutoSetup = () => {
  const dispatch = useAppDispatch()
  const { disabled, selectedTooth, Params } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )

  const onToothFixedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fixed = event.target.checked
    gdsPlusSetup.SetToothFixed(selectedTooth.ID, fixed)
    dispatch(updateAutoSetup())
  }
  const onSelIPRSpaceFixedChange = (fixed: boolean, toNext: boolean) => {
    gdsPlusSetup.SetToothIPREnable(fixed, toNext)
    dispatch(updateAutoSetup())
  }

  const getIPRSpaceValue = (idx: number) => {
    let value = 0
    if (idx === 0) value = Params.IPRToPrev.value
    else if (idx === 1) value = Params.SpaceToPrev.value
    else if (idx === 2) value = Params.IPRToNext.value
    else if (idx === 3) value = Params.SpaceToNext.value
    value = Math.round(value * 10) / 10
    return value
  }
  const onChangeIPRSpace = (idx: number, value: number) => {
    value = Math.max(0.0, Math.round(value * 10) / 10)
    const paraName = ["IPRToPrev", "SpaceToPrev", "IPRToNext", "SpaceToNext"]
    gdsPlusSetup.SetParamDoubleValue(paraName[idx], value)
    dispatch(updateAutoSetup())
  }
  const extractRestoreTooth = (extract) => {
    if (extract) gdsPlusSetup.ExtractTooth()
    else gdsPlusSetup.RestoreAllTeeth()
    dispatch({ type: "autosetup/setDisable", payload: false })
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  const DoNotMove = () => {
    return (
      <>
        <Grid item xs={6}>
          Movement
        </Grid>
        <Grid item xs={3}>
          Do not Move
        </Grid>
        <Grid item xs={3}>
          <Switch
            checked={selectedTooth.isFixed}
            onChange={onToothFixedChange}
          />
        </Grid>
      </>
    )
  }
  const IPRSpace = ({ toNext }) => {
    return (
      <>
        <Grid item xs={6}>
          {toNext ? "Contact Next." : "Contat Prev."}
        </Grid>
        <Grid item xs={3}>
          Fixed
        </Grid>
        <Grid item xs={3}>
          <Switch
            checked={
              toNext
                ? selectedTooth.isFixedIprSpaceNext
                : selectedTooth.isFixedIprSpacePrev
            }
            onChange={(e) => {
              const fixed = e.target.checked
              onSelIPRSpaceFixedChange(fixed, toNext)
            }}
          />
        </Grid>
        <UDTitlePlusMinus
          size="small"
          title="IPR"
          value={getIPRSpaceValue(toNext ? 2 : 0)}
          deltaSmall={0.1}
          deltaLarge={0.5}
          onChange={(newValue) => {
            onChangeIPRSpace(toNext ? 2 : 0, newValue)
          }}
        />
        <UDTitlePlusMinus
          size="small"
          title="Space"
          value={getIPRSpaceValue(toNext ? 3 : 1)}
          deltaSmall={0.1}
          deltaLarge={0.5}
          onChange={(newValue) => {
            onChangeIPRSpace(toNext ? 3 : 1, newValue)
          }}
        />
      </>
    )
  }
  
  const RemoveOccContact = () => {
    return (
      <Button
        onClick={() => {
          gdsPlusSetup.ResolveOccColl(true, true, () => ({}))
        }}
      >
        Remove Occ Contact
      </Button>
    )
  }

  return (
    <Grid
      sx={{ padding: 1 }}
      container
      spacing={1}
      columnSpacing={{ xs: 0.5 }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item xs={3}>
        Extraction
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              extractRestoreTooth(true)
            }}
          >
            Pull Out
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              extractRestoreTooth(false)
            }}
          >
            Restore All
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <DoNotMove />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <IPRSpace toNext={false} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <IPRSpace toNext={true} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <RemoveOccContact />
    </Grid>
  )
}
