import { FC } from "react"
import { Box, Chip, Link } from "@mui/material"

import { getStatusObjByKey } from "../../../config/status.config"
import { ORDERED_STATUS } from "../../patientTable/table.config"

export interface IStatusButtonProps {
  id: number
  statusId: number
  statusName: string
  trackingNumber: number
  caseStatus: string
}

const StatusButton: FC<IStatusButtonProps> = ({
  id,
  statusId,
  statusName,
  trackingNumber,
  caseStatus,
}) => {
  const orderStatus: any = getStatusObjByKey(caseStatus)
  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {orderStatus && (
        <Chip
          label={orderStatus.displayText}
          sx={{
            fontSize: "13px",
            lineHeight: "138.462%",
            letterSpacing: "0.16px",
            backgroundColor:
              orderStatus?.style?.variant === "filled"
                ? orderStatus?.style?.backgroundColor
                : "",
            color: orderStatus?.style?.color,
            border:
              orderStatus?.style?.variant === "outlined"
                ? orderStatus?.style?.border
                : "none",
            borderColor:
              orderStatus?.style?.variant === "outlined"
                ? orderStatus?.style?.borderColor
                : "none",
          }}
          variant={"outlined"}
        />
      )}
      {(caseStatus === ORDERED_STATUS.DELIVERED ||
        caseStatus === ORDERED_STATUS.SHIPPED) && (
        <Link
          target={"_blank"}
          onClick={(event) => event.stopPropagation()}
          href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
        >
          {trackingNumber}
        </Link>
      )}
    </Box>
  )
}

export default StatusButton
