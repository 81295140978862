import {
  Grid,
  FormControl,
  Box,
  Select,
  MenuItem,
  Typography,
  Button,
  Tabs,
  Tab,
} from "@mui/material"
import { useState } from "react"
import { UDTabs } from "../custom/UDTabs"
import { UDSelectOptions, UDTitleSelect } from "../custom/UDSelectOptions"
import { RightPage } from "./RightPage"

export function UDQuickRxMain(props: any) {
  return (
    <Grid container alignItems={"center"}>
      <UDTitleSelect
        title="Treat Arch"
        options={["Both", "Upper Only", "Lower Only"]}
      />
      <UDTitleSelect
        title="Estimated Treat Time"
        options={["Half Year", "One Year", "Two+ Year"]}
      />
      <UDTitleSelect
        title="Treat Teeth"
        options={["Anterior 3x3", "Anterior 5x5", "Full 7x7"]}
      />
      <UDTitleSelect
        title="Midline"
        options={["Maintain", "Lower to Upper", "Upper to Lower"]}
      />
      <UDTitleSelect
        title="Crossbite"
        options={["N/A", "Maintain", "Correctr"]}
      />
      <Grid item xs={6}>
        <Button variant="contained">Cancel</Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="contained">Submit to uAssist</Button>
      </Grid>
    </Grid>
  )
}

export function UDQuickRxOld(props: any) {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <UDTabs labels={["Main", "Occlusion", "Treatment"]}>
      <UDQuickRxMain />
      <Box>Occlusion </Box>
      <Box>Treatment </Box>
    </UDTabs>
  )
}

export function UDQuickRx(props: any) {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <RightPage
      title="Quick Rx"
      onClose={props.onClose}
      content={
        <UDTabs labels={["Main", "Occlusion", "Treatment"]}>
          <UDQuickRxMain />
          <p>Occlusion </p>
          <p>Treatment </p>
        </UDTabs>
      }
      actions={<Button variant="contained">Hello, QuickRx!</Button>}
    />
  )
}
