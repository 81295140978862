import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import DownloadIcon from "@mui/icons-material/Download"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { Invoice } from "../../../core/model/billing"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import { formatCurrencyWithDecimals } from "../../../core/utils/formatters"
import usePermissions from "../../../hooks/userPermission"
import { UButton, UText } from "../../../ui-component"
import {
  Box,
  Chip,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from "../../../ui-component/mui.components"
import { STLTable } from "../../Patient/PatientList/patientTable/table.config"
import { formatDate } from "../../Patient/PatientList/util/commonUtil"

import { BillingTable } from "./billingTable"
import { InvoiceDetails } from "./invoiceDetails"

const Invoices: FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { hasAny } = usePermissions()
  const [showDetail, setShowDetail] = useState(false)
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const { t } = useTranslation("common")
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down("sm"))

  const renderInvoice = (
    label: string,
    value: string | number,
    fontweight?: number,
  ) => {
    return (
      <Box>
        <UText
          variant={"body1"}
          component={"span"}
          sxProp={{
            color: "text.primary",
            fontWeight: 700,
          }}
        >
          {label}
        </UText>
        <UText variant={"body1"} color="text.primary" component={"span"}>
          {value}
        </UText>
      </Box>
    )
  }
  const renderTotal = (key: string, amount: string, weight?: number) => {
    return (
      <Grid container>
        <Grid
          item
          xs={6}
          justifyContent={isXs ? "start" : "end"}
          display={"flex"}
        >
          <UText
            variant={"body1"}
            component={"span"}
            sxProp={{
              color: "text.primary",
              fontWeight: 700,
              fontSize: weight ? "20px" : "16px",
            }}
          >
            {key}
          </UText>
        </Grid>
        <Grid item xs={6} justifyContent={"end"} display={"flex"}>
          <UText
            variant={"body1"}
            color="text.primary"
            component={"span"}
            sxProp={{
              fontWeight: weight ? 700 : 400,
              fontSize: weight ? "20px" : "16px",
            }}
          >
            {amount}
          </UText>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {invoice.invoice_number && countryCode && (
        <Paper
          elevation={0}
          sx={{ borderRadius: "8px", mx: 4, minHeight: "100px", p: 4 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pb: 2,
            }}
          >
            {invoice.status && (
              <Chip
                label={invoice.status}
                sx={{
                  height: "36px",
                  color:
                    invoice.status === "Paid" ? "success.main" : "warning.main",
                  border: "1px solid",
                  fontWeight: 400,
                  borderColor:
                    invoice.status === "Paid" ? "success.main" : "warning.main",
                  borderRadius: "4px",
                  backgroundColor: "background.default",
                }}
                variant={"outlined"}
              />
            )}
            {hasAny(UI_PERMISSION.UI_BILLING_DOWNLOAD) &&
              invoice.invoice_url && (
                <UButton
                  sxProp={{
                    height: "36px",
                  }}
                  btnType={"button"}
                  startIcon={<DownloadIcon />}
                  variant={"contained"}
                  btnText={t("billingHistory.downloadInvoice")}
                  onClickHandler={() => {
                    window.open(invoice.invoice_url, "_blank")
                  }}
                />
              )}
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5} sx={{ paddingTop: 0 }}>
              {renderInvoice(
                t("billingHistory.invoices.invoice"),
                invoice.invoice_number,
                700,
              )}
              {renderInvoice(
                t("billingHistory.invoices.dateRange"),
                t("billingHistory.invoices.dateRangefrom", {
                  from: formatDate(invoice.start_date.datetime),
                  to: formatDate(invoice.end_date.datetime),
                }),
                700,
              )}
              {invoice.status !== "Paid" &&
                renderInvoice(
                  t("billingHistory.invoices.dueDate"),
                  formatDate(invoice.due_date.datetime),
                  700,
                )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {renderInvoice(
                t("billingHistory.invoices.promoLevel"),
                invoice.member_tiers,
              )}
              {renderInvoice(
                t("billingHistory.invoices.aligners"),
                invoice.total_aligners,
              )}
              {renderInvoice(
                t("billingHistory.invoices.retainers"),
                invoice.total_retainers,
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isXs ? "flex-start" : "flex-end",
              }}
            >
              {renderTotal(
                t("billingHistory.invoices.totalAmt"),
                formatCurrencyWithDecimals(invoice.total_amount),
              )}
              {renderTotal(
                t("billingHistory.invoices.amtPaid"),
                formatCurrencyWithDecimals(invoice.paid_amount),
              )}
              {renderTotal(
                t("billingHistory.invoices.creditProvided"),
                formatCurrencyWithDecimals(invoice.credit_provided),
              )}
              {renderTotal(
                t("billingHistory.invoices.balanceDue"),
                formatCurrencyWithDecimals(invoice.remaining_amount),
                700,
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <UButton
              sxProp={{
                fontSize: "14px",
                lineHeight: "169.231%",
                letterSpacing: "0.46px",
                height: "30px",
                color: "primary.main",
              }}
              size={"small"}
              variant={"text"}
              btnText={
                !showDetail
                  ? t("billingHistory.invoices.showInvoice")
                  : t("billingHistory.invoices.hideInvoice")
              }
              onClickHandler={() => {
                setShowDetail(!showDetail)
              }}
              endIcon={showDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            />
            {showDetail && (
              <Box>
                {invoice?.details?.in_office_outputs?.lines?.length ? (
                  <>
                    <UText
                      variant="body1"
                      sxProp={{ mb: 2, fontWeight: "600" }}
                    >
                      {t("billingHistory.invoices.stlExports")}
                    </UText>
                    <BillingTable
                      tableHeader={STLTable.columnDef}
                      rows={invoice.details.in_office_outputs.lines}
                      showPagination={false}
                      currencyCode={invoice.currency.code}
                    ></BillingTable>
                  </>
                ) : (
                  ""
                )}
                <InvoiceDetails invoice={invoice} />
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </>
  )
}

export default Invoices
