import { ChangeEvent, FC, useEffect } from "react"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"

import { Box, Container, Paper } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { updateIsAlignerCase } from "../../../core/app/slices/order/orderSlice"
import { getShippingAddressByCase } from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import Banner from "../../Patient/Overview/components/banner"

import DeliveryService from "./components/DeliveryService"
import PackageType from "./components/PackageType"
import ShippingAddress from "./components/ShippingAddress"

const Shipping: FC = () => {
  const dispatch = useAppDispatch()
  const { quotation, isAlignerCase } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  useEffect(() => {
    dispatch(getShippingAddressByCase())
  }, [dispatch])

  const handleIsAlignerChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateIsAlignerCase(isAlignerCase === 0 ? 1 : 0))
  }

  return (
    <Container component="main" maxWidth={"lg"} sx={{ mb: 4 }}>
      <Box>
        <ShippingAddress isReviewOrder={false} />
      </Box>

      <Box sx={{ mt: 2 }}>
        <DeliveryService isReviewOrder={false} />
      </Box>

      <Box sx={{ mt: 2 }}>
        <PackageType isReviewOrder={false} />
      </Box>
      {quotation.isAlignerCase && (
        <Box sx={{ mt: 2 }}>
          <Paper elevation={0} sx={{ padding: "16px", borderRadius: 4 }}>
            <Banner
              severity="info"
              bannerContent="An aligner case is not included with this order. Check the box to add an aligner case to this order."
            />
            <Box sx={{ mt: 1, pb: 2 }}>
              <Box sx={{ mx: 2 }}>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item md={10}>
                    <Box>
                      <FormControlLabel
                        control={<Checkbox checked={!!isAlignerCase} />}
                        label={"Additional aligner storage case"}
                        name={"aligner_case"}
                        value={"true"}
                        onChange={handleIsAlignerChange}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    {/* <UText variant={"subtitle2"}>$10</UText> */}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </Container>
  )
}

export default Shipping
