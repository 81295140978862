import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Grid } from "@mui/material"
// checkbox_arr=['a','b']
// checkbox_value = {value:['a']}
// checkbox_value_obj = {value:['a'],a:'val'}
// checkbox_for_obj = {a:true,b:false}
// checkbox_value_str = {value:['a'],str:"b"}
// str = {key:'str'}
// upper_lower = {upper:{},lower:{}}
// value_stage = {value:'Yes',stage:''} or {value:'No'}
const retainerConfig = [
  {
    isHead: true,
    label: "ARCHES",
    key: "ARCHES",
    type: "head",
  },
  {
    isHead: false,
    label: "Select Arches",
    key: "arches_to_treat",
    type: "checkbox_arr",
  },
  {
    isHead: true,
    label: "DETAILS (AS APPROPRIATE)",
    key: "DETAILS (AS APPROPRIATE)",
    type: "head",
  },
  {
    isHead: false,
    label: "Bracket Removal",
    key: "bracket_removal",
    type: "checkbox_value",
  },
  {
    isHead: false,
    label: "Remove Scanned Attachments / Buttons",
    key: "remove_scanned_attachement",
    type: "checkbox_value_obj",
  },
  {
    isHead: false,
    label: "Cover Lingual Bar",
    key: "cover_lingual_bar",
    type: "checkbox_value_obj",
  },
  {
    isHead: false,
    label: "Remove Lingual Bar",
    key: "remove_lingual_bar",
    type: "checkbox_value_obj",
  },
  {
    isHead: false,
    label: "Add Bite Ramp",
    key: "add_bite_ramps",
    type: "checkbox_for_obj",
  },
  {
    isHead: false,
    label: "Pontics",
    key: "pontics",
    type: "checkbox_value_str",
  },
  {
    isHead: false,
    label: "Additional Instructions",
    key: "additional_instructions",
    type: "str",
  },
]
const alingerConfig = [
  {
    isHead: false,
    label: "Estimated Treatment Time",
    key: "estimated_treatment_time",
    type: "str",
  },
  {
    isHead: false,
    label: "Which Arches would you like to treat",
    key: "arches_to_treat",
    type: "upper_lower",
  },
  {
    isHead: false,
    label: "Selected Teeth To Treat",
    key: "selected_teeth_to_treat",
    type: "str",
  },
  {
    isHead: false,
    label: "Class Correction",
    key: "class_correction",
    type: "str",
  },
  {
    isHead: false,
    label: "Midline Correction",
    key: "midline_correction",
    type: "str",
  },
  {
    isHead: false,
    label: "Crossbite Correction",
    key: "crossbite_correction",
    type: "str",
  },
  {
    isHead: false,
    label: "Planning for Restoration, Pontic or Eruption Dome?",
    key: "restorations",
    type: "value_stage",
  },
  {
    isHead: false,
    label: "Which SmartRx Template would you like to use for this case?",
    key: "smart_rx_template",
    type: "str",
  },
  {
    isHead: false,
    label: "Case Specific Instructions",
    key: "case_instructions",
    type: "str",
  },
]
const ReviewForm: FC<{ type: string; data: any }> = ({ type, data }) => {
  const { t } = useTranslation("common")
  // first to uppercase
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const getContent = (type: string) => {
    const tmp = type === "retainer" ? retainerConfig : alingerConfig
    return (
      <>
        {tmp.map((i) => {
          if (i.isHead) {
            return (
              <Grid
                key={i.key}
                sx={{
                  width: "100%",
                  padding: "8px",
                  height: "40px",
                  backgroundColor: "#eee",
                  fontSize: "12px",
                  color: "rgba(0,0,0,0.6)",
                  lineHeight: "24px",
                }}
                container
              >
                {i.label}
              </Grid>
            )
          } else {
            let content = ""
            switch (i.type) {
              case "checkbox_arr":
                data[i.key] &&
                  data[i.key].map((val) => {
                    content = `${content} ${capitalizeFirstLetter(val)}`
                  })
                break
              case "checkbox_value":
                data[i.key] &&
                  data[i.key].value.map((val) => {
                    content = `${content} ${capitalizeFirstLetter(val)}`
                  })
                break
              case "checkbox_value_obj":
                data[i.key] &&
                  data[i.key].value.map((val) => {
                    content = `${content} ${capitalizeFirstLetter(
                      data[i.key][val],
                    )}`
                  })
                break
              case "checkbox_for_obj":
                data[i.key] &&
                  Object.keys(data[i.key]).map((key) => {
                    if (data[i.key][key]) {
                      content = `${content} ${capitalizeFirstLetter(key)}`
                    }
                  })
                break
              case "checkbox_value_str":
                if (i.key === "pontics" && data[i.key]) {
                  if (data[i.key].value.length) {
                    content = `${content} ${capitalizeFirstLetter(
                      data[i.key].value[0],
                    )}`
                  }
                  if (
                    data[i.key].instructions &&
                    data[i.key].instructions !== ""
                  ) {
                    content = `${content}: ${data[i.key].instructions}`
                  }
                }
                break
              case "str":
                data[i.key] ? (content = `${data[i.key]}`) : (content = "")
                break
              case "upper_lower":
                if (data[i.key] && data[i.key].upper) {
                  const up = data[i.key].upper
                  content = `${up.isChecked}:${up.aligner_type}\n`
                  const strArr = []
                  if (up.bracket_removal && up.bracket_removal !== "") {
                    strArr.push(up.bracket_removal)
                  }
                  if (up.cover_lingual_bar && up.cover_lingual_bar !== "") {
                    strArr.push(up.cover_lingual_bar)
                  }
                  if (up.remove_lingual_bar && up.remove_lingual_bar !== "") {
                    strArr.push(up.remove_lingual_bar)
                  }
                  if (strArr.length) {
                    content = `${content}${strArr.join(",")}`
                  } else {
                    content = `${content} \n`
                  }
                }
                if (data[i.key] && data[i.key].lower) {
                  const lower = data[i.key].lower
                  content = `${content}${lower.isChecked}:${lower.aligner_type}\n`
                  const strArr = []
                  if (lower.bracket_removal && lower.bracket_removal !== "") {
                    strArr.push(lower.bracket_removal)
                  }
                  if (
                    lower.cover_lingual_bar &&
                    lower.cover_lingual_bar !== ""
                  ) {
                    strArr.push(lower.cover_lingual_bar)
                  }
                  if (
                    lower.remove_lingual_bar &&
                    lower.remove_lingual_bar !== ""
                  ) {
                    strArr.push(lower.remove_lingual_bar)
                  }
                  if (strArr.length) {
                    content = `${content} ${strArr.join(",")}`
                  }
                }
                break
              case "value_stage":
                if (data[i.key]) {
                  if (data[i.key].value === "Yes") {
                    content = `Yes:${data[i.key].stage}`
                  } else if (data[i.key].value === "No") {
                    content = "No"
                  }
                }
                break
            }
            return (
              <Grid sx={{ margin: "8px" }} key={i.key} container>
                <Grid
                  sx={{
                    width: "200px",
                    color: "rgba(0,0,0,0.6)",
                    fontSize: "14px",
                    letterSpacing: "0.17px",
                    marginRight: "8px",
                  }}
                  item
                >
                  {i.label}
                </Grid>
                <Grid
                  sx={{
                    width: "228px",
                    color: "rgba(0,0,0,0.87)",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "0.17px",
                    whiteSpace: "pre-line",
                  }}
                  item
                >
                  {content}
                </Grid>
              </Grid>
            )
          }
        })}
      </>
    )
  }
  return (
    <Box>
      <Grid container>{getContent(type)}</Grid>
    </Box>
  )
}
export default ReviewForm
