import { ChangeEvent, FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material"
import { debounce } from "lodash"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { IClinicalSettings } from "../../../core/app/slices/user/user.type"
import {
  fetchClinicalSettings,
  updateClinicalSettings,
} from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import usePermissions from "../../../hooks/userPermission"
import { CENETER_ROW_ALIGN } from "../../../theme/theme.util"
import { UText } from "../../../ui-component"
import FdiSvg from "../assets/fdi.svg"
import PalmerSvg from "../assets/palmer.svg"
import UniversalSvg from "../assets/universal.svg"

const TooltipContent: FC<{ img: string }> = ({ img }) => {
  return (
    <Box
      component={"img"}
      src={img}
      sx={{ height: "254px", width: "210px" }}
      alt={"tooltip"}
    />
  )
}

const NumberingRadioLabelContent: FC<{ label: string; img: string }> = ({
  label,
  img,
}) => {
  const UTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      sx={{
        padding: "1px",
      }}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      padding: "8px 8px 2px ",
    },
  }))

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <UTooltip title={<TooltipContent img={img} />} placement="right" arrow>
        <IconButton>
          <InfoIcon
            fontSize={"small"}
            sx={{
              color: (theme) => theme.palette.text.disabled,
              width: "24px",
              height: "20px",
            }}
          />
        </IconButton>
      </UTooltip>
    </Box>
  )
}

const ClinicalSettings: FC = () => {
  const { hasAny } = usePermissions()
  const [clinicalSetting, setClinicalSetting] = useState<IClinicalSettings>(
    {} as IClinicalSettings,
  )
  const { clinicalSettings, clinicalSettingStatus } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [stageValue, setStageValue] = useState("")
  const [imgName, setImgName] = useState("")
  const radioOptions = {
    numbering_system: [
      { value: "Pedro", label: "Palmer", img: PalmerSvg },
      { value: "General", label: "Universal", img: UniversalSvg },
      { value: "FDI", label: "FDI", img: FdiSvg },
    ],
    anterior: [
      { value: "1", label: "Smooth", id: "1" },
      { value: "0", label: "Semi Scallop", id: "0" },
    ],
    posterior: [
      { value: "1", label: "Smooth", id: "1" },
      { value: "0", label: "Semi Scallop", id: "0" },
    ],
    trim_line_level: [
      { value: "1", label: "No Offset", id: "1" },
      { value: "0", label: "0.75 mm", id: "0" },
    ],
  }

  useEffect(() => {
    dispatch(fetchClinicalSettings())
  }, [dispatch])

  useEffect(() => {
    if (clinicalSettings) {
      setClinicalSetting(clinicalSettings)
      if (clinicalSettings && clinicalSettings.AttachmentStage != 0) {
        setStageValue(clinicalSettings.AttachmentStage.toString() || "")
        setClinicalSetting({
          ...clinicalSettings,
          AttachmentStage: 1,
        })
      }
    }
  }, [clinicalSettings])

  useEffect(() => {
    const getId = (
      options: Array<{ value: string; id: string; label: string }>,
      value: number,
    ) => {
      const obj = options.find((option) => option.value === value.toString())
      return obj ? `${obj.value}` : "0"
    }
    const imgName =
      getId(radioOptions.anterior, clinicalSetting.CuttingLineCC || 0) +
      getId(radioOptions.posterior, clinicalSetting.CuttingLineCP || 0) +
      getId(
        radioOptions.trim_line_level,
        clinicalSetting.CuttingLineOffset || 0,
      )
    import(`../assets/${imgName}.png`)
      .then((image) => {
        setImgName(image.default)
      })
      .catch((error) => {
        console.error("Error loading image:", error)
      })
  }, [
    clinicalSetting.CuttingLineCC,
    clinicalSetting.CuttingLineCP,
    clinicalSetting.CuttingLineOffset,
  ])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    if (hasAny(UI_PERMISSION.UI_CLINICALSETTING_UPDATE)) {
      if (target.name === "stage_number") {
        setStageValue(target.value)
      }
      const obj: IClinicalSettings = { ...clinicalSetting }
      if (
        [
          "CuttingLineCC",
          "CuttingLineCP",
          "CuttingLineOffset",
          "AttachmentStage",
        ].includes(target.name)
      ) {
        obj[target.name] = parseInt(target.value)
      } else {
        obj[target.name] = target.value
      }
      if (obj.CuttingLineCC === 0 || obj.CuttingLineCP === 0) {
        obj.CuttingLineOffset = 1
      }
      setClinicalSetting(obj)
      debouncedOnInput(obj)
    }
  }

  const debouncedOnInput = debounce((obj) => {
    const data = { ...obj }
    if (data["stage_number"] && data.AttachmentStage == 1) {
      data.AttachmentStage = data["stage_number"]
    }
    if (hasAny(UI_PERMISSION.UI_CLINICALSETTING_UPDATE)) {
      dispatch(updateClinicalSettings(data)).then((res) => {
        if (res && res.payload && res.payload.response_code === 200) {
          setClinicalSetting(obj)
        } else {
          dispatch(
            setAlert({
              message: t("clinicalSettings.dataUpdatedFailure"),
              isError: true,
            }),
          )
        }
      })
    }
  }, 500)

  return (
    <Box sx={{ width: "640px", p: 4 }}>
      <Box sx={{ ...CENETER_ROW_ALIGN, justifyContent: "center", pb: "16px" }}>
        <UText variant={"h4"}>{t("clinicalSettings.title")}</UText>
      </Box>
      <Box>
        <Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <UText variant={"h6"} sxProp={{ color: "#000000" }}>
              {t("clinicalSettings.numberingSystem")}
            </UText>
            <FormControl>
              <RadioGroup
                aria-labelledby="numbering_system_label"
                name="NumberSystem"
                sx={{ px: 1 }}
                value={clinicalSetting["NumberSystem"] || ""}
                onChange={handleChange}
              >
                {radioOptions.numbering_system.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <NumberingRadioLabelContent
                        label={option.label}
                        img={option.img}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <UText variant={"h6"} sxProp={{ color: "#000000", pt: 1 }}>
              {t("clinicalSettings.uSmileClearAlignerTrimLinePreferences")}
            </UText>
            <UText variant={"body1"} sxProp={{ color: "#000000", my: 1 }}>
              {"Trim Line Curvature Control"}
            </UText>
            <FormControl>
              <FormLabel
                id={"anterior_label"}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {t("clinicalSettings.anterior")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="anterior_label"
                name="CuttingLineCC"
                sx={{ px: 1 }}
                value={clinicalSetting.CuttingLineCC === 0 ? "0" : "1"}
                onChange={handleChange}
              >
                {radioOptions.anterior.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <FormControl>
              <FormLabel
                id={"posterior_label"}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {t("clinicalSettings.posterior")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="posterior_label"
                name="CuttingLineCP"
                value={clinicalSetting.CuttingLineCP === 0 ? "0" : "1"}
                onChange={handleChange}
                sx={{ px: 1 }}
              >
                {radioOptions.posterior.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              mb: 3,
            }}
          >
            <UText variant={"body1"} sxProp={{ color: "#000000", mb: "4px" }}>
              {t("clinicalSettings.trimLineLevel")}
            </UText>
            <FormControl>
              <FormLabel
                id={"distance_label"}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: "14px",
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {t("clinicalSettings.distancebetweengumlineandtrimline")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="distance_label"
                name="CuttingLineOffset"
                sx={{ px: 1 }}
                value={clinicalSetting.CuttingLineOffset === 0 ? "0" : "1"}
                onChange={handleChange}
              >
                {radioOptions.trim_line_level.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                    }}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Box
              component={"img"}
              src={imgName}
              alt={"Trim Line Level"}
              sx={{ height: "286px", alignSelf: "stretch", mb: 1 }}
            />
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <UText variant={"h6"} sxProp={{ color: "#000000", mb: "4px" }}>
              {t("clinicalSettings.attachmentStage")}
            </UText>
            <FormControl>
              <FormLabel
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  "&.Mui-focused": {
                    color: (theme) => theme.palette.text.secondary,
                  },
                }}
              >
                {t("clinicalSettings.defaultStagetoputonAttachments")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="stage_attachments_label"
                name="AttachmentStage"
                value={clinicalSetting.AttachmentStage || "0"}
                onChange={handleChange}
                sx={{ px: 1 }}
              >
                <FormControlLabel
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                  }}
                  value={"0"}
                  control={<Radio />}
                  label="At beginning of treatment"
                />
                <FormControlLabel
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                  }}
                  value={"1"}
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {"At stage"}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size={"small"}
                        value={stageValue}
                        name={"stage_number"}
                        onChange={handleChange}
                        sx={{
                          mx: 1,
                          width: "80px",
                        }}
                      />
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClinicalSettings
