import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material"

import { UText } from "../../../ui-component"

interface IHelpCardProps {
  title: string
  subtitle: string
  imageUrl: string
}

const HelpCard: FC<IHelpCardProps> = ({ title, subtitle, imageUrl }) => {
  const { t } = useTranslation("common")
  return (
    <Card
      data-testid={"helpCard"}
      sx={{
        width: "100%",
        minHeight: "280px",
        "&:hover": {
          backgroundColor: "#FFFFFF0A",
          boxShadow: 3,
        },
        ">.MuiCardActionArea-root": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        },
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <CardMedia component={"img"} image={imageUrl} />
          <UText variant={"h6"}>{t(title)}</UText>
          <UText variant={"body1"}>{t(subtitle)}</UText>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default HelpCard
