const { VITE_ADYEN_CHECKOUT_ENV, VITE_ADYEN_CHECKOUT_CLIENTKEY } = import.meta
  .env

const styleObject = {
  base: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    fontFamily: `"Poppins", sans-serif`,
    fontWeight: 400,
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },
  error: {
    color: "#e23030",
  },
  placeholder: {
    color: "transparent",
  },
  validated: {
    color: "#333333",
  },
}

export const ADEN_CONFIG = {
  config: {
    // storePaymentMethod: true,
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      card: {
        styels: styleObject,
        brands: ["mc", "visa", "amex", "bcmc", "diners", "discover"],
        hasHolderName: true,
        holderNameRequired: true,
        ariaLabels: {
          holderName: {
            placeholder: "teste",
          },
        },
      },
    },
    locale: "en_US",
    clientKey: VITE_ADYEN_CHECKOUT_CLIENTKEY,
    environment: VITE_ADYEN_CHECKOUT_ENV,
  },
}
