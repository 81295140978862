import { FC, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useResizeObserver } from "react-use-observer"
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material"
import { setCurrentStep } from "@/UDTreat/udTreatSlice"
import NormalButton from "./normalButton"

const PassiveAligner = (props) => {

  return <div style={{
    width: "80px",
    height: "116px",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "center",
  }}>
    <NormalButton
      width={80}
      height={27}
      fontSize={13}
      radius={4}
      backgroundColor={"#02AFEC"}
      mouseInBackgroundColor={"#02AFECAA"}
      clickedBackgroundColor={"#02AFECDD"}
      color={"white"}
      mouseInColor={"white"}
      clickedColor={"white"}
      text={"U = L"}
      onClickHandle={props.onUpDownEqualHandle}>

    </NormalButton>

    <div style={{height: "23px"}}></div>

    <NormalButton
      width={80}
      height={27}
      fontSize={13}
      radius={4}
      backgroundColor={"#02AFEC"}
      mouseInBackgroundColor={"#02AFECAA"}
      clickedBackgroundColor={"#02AFECDD"}
      color={"white"}
      mouseInColor={"white"}
      clickedColor={"white"}
      text={"Delete All"}
      onClickHandle={props.onDeleteAllHandle}>

    </NormalButton>


  </div>
}
export default PassiveAligner
