import { ElementType, FC, ReactNode } from "react"
import React from "react"

import { Link, SxProps, Theme } from "../../mui.components"

export interface ILinkTextProps {
  to: string
  icon?: ReactNode | null
  linkText: string
  sxProp?: SxProps<Theme>
  component: ElementType
  underline?: "none" | "always" | "hover" | undefined
}

const LinkText: FC<ILinkTextProps> = ({
  to,
  icon,
  linkText,
  component,
  sxProp,
  underline,
}) => {
  return (
    <Link
      component={component}
      to={to}
      variant={"body2"}
      color={"text.secondary"}
      underline={underline}
      sx={{ display: "flex", ...sxProp }}
    >
      {linkText} {icon || ""}
    </Link>
  )
}

LinkText.defaultProps = {
  sxProp: undefined,
  icon: null,
  underline: undefined,
}

export default LinkText
