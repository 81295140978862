import { useState, useRef, useEffect } from "react"
import {
  Box,
  Card,
  IconButton,
  CardContent,
  CardHeader,
  CardActions,
  Paper,
  Button,
} from "@mui/material"
import { Slide } from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Prescription from "../USmartForms/Prescription"
import Retainer from "../USmartForms/Retainer"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setSmartRxShow } from "../udTreatSlice"
import { fetchFormLists } from "@/core/app/slices/uassist/formThunkApi"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import WarnBox from "../USmartForms/WarnBox"
const canEdit = ["SUBMITTED_TO_UASSIST"]
const SmartRx: FC = () => {
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { caseDisposition } = caseDetail
  const navigate = useMyNavigation()
  const smartRef = useRef("")
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { smartRxShow, caseRAtype } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )
  // bottom content type----edit or review or success
  const [actionType, setActionType] = useState<string>("edit")
  // review smartRX signal
  const [reviewNum, setReviewNum] = useState<number>(0)
  useEffect(() => {
    if (!smartRxShow) {
      setReviewNum(0)
      if (canEdit.includes(caseDisposition)) {
        setActionType("edit")
      }
    }
  }, [smartRxShow])
  useEffect(() => {
    !formTemplateList.length && dispatch(fetchFormLists())
    // if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])
  const [isRetainer, setIsRetainer] = useState<boolean>(false)

  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.udesign_category &&
      !caseDetail?.udesign_category?.includes("A")
    ) {
      setIsRetainer(true)
    }
  }, [caseDetail?.udesign_category])
  useEffect(() => {
    setActionType(canEdit.includes(caseDisposition) ? "edit" : "review")
  }, [caseDisposition])
  return (
    smartRef &&
    smartRxShow && (
      <Paper
        sx={{
          left: 84,
          top: 104,
          width: "auto",
          maxWidth: "640px",
          minWidth: "536px",
          maxHeight: `calc(100% - 114px)`,
          position: "absolute",
          overflow: "auto",
        }}
        elevation={6}
      >
        <Card>
          <CardHeader
            sx={{
              padding: "24px 10px 16px 32px",
              backgroundColor: "#EDF6F9",
              borderRadius: "8px",
              "& .MuiTypography-root": {
                fontSize: "24px",
                fontWeight: 500,
              },
            }}
            action={
              <IconButton
                aria-label="settings"
                sx={{
                  width: 24,
                  height: 24,
                  right: "10px",
                }}
                onClick={() => {
                  dispatch(setSmartRxShow(false))
                }}
              >
                <CloseIcon />
              </IconButton>
            }
            title={
              actionType === "edit"
                ? "uAssist Prescription"
                : actionType === "review"
                ? "Review Prescription"
                : "Thank you"
            }
          />
          <CardContent
            sx={{
              padding: "24px 32px",
              // height: actionType === "success" ? "288px" : "500px",
              maxHeight: `calc(100%)`,
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
            }}
          >
            {
              <>
                {/* <WarnBox /> */}
                {actionType === "review" && (
                  <Box
                    sx={{
                      width: "100%",
                      padding: "8px",
                      height: "40px",
                      marginBottom: "8px",
                      backgroundColor: "#eee",
                      fontSize: "12px",
                      color: "rgba(0,0,0,0.6)",
                      lineHeight: "24px",
                    }}
                  >
                    {"REVIW PRESCRIPTION"}
                  </Box>
                )}
                {isRetainer ? (
                  <Retainer
                    setUpperArchType={setUpperArchType}
                    setLowerArchType={setLowerArchType}
                    reviewNum={reviewNum}
                    setActionType={setActionType}
                    actionType={actionType}
                  />
                ) : (
                  <Prescription
                    setUpperArchType={setUpperArchType}
                    setLowerArchType={setLowerArchType}
                    reviewNum={reviewNum}
                    setActionType={setActionType}
                    actionType={actionType}
                  />
                )}
              </>
            }
          </CardContent>
          <CardActions
            sx={{
              width: "100%",
              padding: "16px 32px 24px 24px",
              backgroundColor: "#EDF6F9",
              display: canEdit.includes(caseDisposition) ? "flex" : "none",
              justifyContent: "end",
            }}
          >
            {actionType === "edit" ? (
              <Button
                sx={{
                  color: "#fff",
                  padding: "8px 14px 8px 22px",
                  borderRadius: "4px",
                  backgroundColor: "#215ECD",
                }}
                variant="contained"
                onClick={() => {
                  setReviewNum(reviewNum + 1)
                }}
              >
                <span style={{ fontSize: "15px", fontWeight: "500" }}>
                  {"review"}
                </span>
                <ChevronRightIcon
                  sx={{ marginLeft: "8px", width: "24px", height: "24px" }}
                />
              </Button>
            ) : actionType === "review" ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    padding: "8px 11px 8px 8px",
                    borderRadius: "4px",
                    color: "#215ECD",
                  }}
                  variant="text"
                  onClick={() => {
                    setActionType("edit")
                  }}
                >
                  <ChevronLeftIcon
                    sx={{ marginRight: "8px", width: "24px", height: "24px" }}
                  />
                  <span style={{ fontSize: "15px", fontWeight: "500" }}>
                    {"edit"}
                  </span>
                </Button>
                <Box>
                  {/* <Button
                    sx={{
                      padding: "8px 11px 8px 11px",
                      borderRadius: "4px",
                      color: "#215ECD",
                      marginRight: "10px",
                    }}
                    variant="text"
                    onClick={() => {
                      console.log("view ai setup")
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"view ai setup"}
                    </span>
                  </Button> */}
                  <Button
                    sx={{
                      color: "#fff",
                      padding: "8px 14px 8px 22px",
                      borderRadius: "4px",
                      backgroundColor: "#215ECD",
                    }}
                    variant="contained"
                    onClick={() => {
                      // TODO check photo\x-ray\dental chart
                      setReviewNum(reviewNum + 1)
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"submit"}
                    </span>
                    <ChevronRightIcon
                      sx={{
                        marginLeft: "8px",
                        height: "24px",
                        width: "24px",
                      }}
                    />
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box>
                  <Button
                    sx={{
                      padding: "8px 11px 8px 11px",
                      borderRadius: "4px",
                      color: "#215ECD",
                      marginRight: "10px",
                    }}
                    variant="text"
                    onClick={() => {
                      dispatch(setSmartRxShow(false))
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"close"}
                    </span>
                  </Button>
                  <Button
                    sx={{
                      color: "#fff",
                      padding: "8px 14px 8px 22px",
                      borderRadius: "4px",
                      backgroundColor: "#215ECD",
                    }}
                    variant="contained"
                    onClick={() => {
                      navigate("/patients")
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"go to patient list"}
                    </span>
                  </Button>
                </Box>
              </Box>
            )}
          </CardActions>
        </Card>
      </Paper>
    )
  )
}
export default SmartRx
