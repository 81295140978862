import { FC } from "react"
import { Controller } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  SxProps,
} from "../../../components/mui.components"
import { UText } from "../../../ui-component"

interface IReadAndAgreeProps {
  control: any
  sxProps?: SxProps
}

const ReadAndAgree: FC<IReadAndAgreeProps> = ({ control, sxProps }) => {
  const { t } = useTranslation("common")
  return (
    <>
      <FormGroup
        sx={{
          ...sxProps,
          px: 2,
        }}
      >
        <Controller
          name={"termsAndConditions"}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { name, onChange }, formState: { errors } }) => {
            console.log(errors)
            const hasError = !!errors[name]
            return (
              <FormControlLabel
                sx={{
                  m: 0,
                  ".MuiSvgIcon-root": {
                    width: "18px",
                    height: "18px",
                  },
                }}
                control={
                  <Checkbox
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                    color="primary"
                    sx={{
                      color: hasError ? "error.main" : "",
                    }}
                    size={"small"}
                  />
                }
                label={
                  <UText
                    color={"text.primary"}
                    sxProp={{
                      display: "flex",
                      color: hasError ? "error.main" : "",
                      whiteSpace: "pre",
                    }}
                    variant={"caption"}
                  >
                    {t("auth.activation.readAndAgree")}
                    <Link
                      sx={{
                        color: "primary.main",
                        fontWeight: 500,
                      }}
                      target={"_blank"}
                      href={"https://www.ulabsystems.com/terms-of-use/"}
                    >
                      {t("auth.activation.termsAndConditions")}
                    </Link>
                    {"."}
                  </UText>
                }
              />
            )
          }}
        />
        <Controller
          name={"privacyPolicy"}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { name, onChange }, formState: { errors } }) => {
            const hasError = !!errors[name]
            return (
              <FormControlLabel
                sx={{
                  m: 0,
                  ".MuiSvgIcon-root": {
                    width: "18px",
                    height: "18px",
                  },
                }}
                control={
                  <Checkbox
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                    color="primary"
                    sx={{
                      color: hasError ? "error.main" : "",
                    }}
                    size={"small"}
                  />
                }
                label={
                  <UText
                    color={"text.primary"}
                    sxProp={{
                      display: "flex",
                      color: hasError ? "error.main" : "",
                      whiteSpace: "pre",
                    }}
                    variant={"caption"}
                  >
                    {t("auth.activation.readAndAgree")}
                    <Link
                      sx={{
                        color: "primary.main",
                        fontWeight: 500,
                      }}
                      target={"_blank"}
                      href={"https://www.ulabsystems.com/privacy-policy/"}
                    >
                      {t("auth.activation.privacyPolicy")}
                    </Link>
                    {"."}
                  </UText>
                }
              />
            )
          }}
        />
      </FormGroup>
    </>
  )
}

export default ReadAndAgree
