import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { getIDs, toothIdTypes } from "../../column"
import {
  attachmentIDs,
  attachmentSvg,
  labels,
  qua1Ids,
  qua2Ids,
  qua3Ids,
  qua4Ids,
  svgs,
} from "../Auxilliaries/column"
import { auxProps } from "../Auxilliaries/type"

import "../Auxilliaries/auxilliaries.scss"
const AttachmentComponent: FC<auxProps> = ({ attachment, toothlist }) => {
  const [auxData, setData] = useState({})
  const [lengendData, setLengendData] = useState([])
  const { t } = useTranslation("common")
  const {
    clinicalSettings: { NumberSystem },
  } = useAppSelector((state: RootState) => state.userService)

  useEffect(() => {
    // attachment data
    const obj = {}
    // lengend data
    let lenTmp = []
    // data control
    for (const key of Object.keys(attachment)) {
      const attachTmp = attachment[key].attachment

      if (attachTmp && attachTmp.length) {
        for (let i = 0; i < attachTmp.length; i++) {
          const { attachmentId, onLingualSide } = attachTmp[i]
          if (attachmentIDs.includes(attachmentId)) {
            lenTmp.push(attachmentId)
            if (!obj[key]) {
              obj[key] = {}
            }
            if (onLingualSide) {
              obj[key].lingual = {
                icon: attachmentSvg[attachmentId].src,
              }
            } else {
              obj[key].buccal = {
                icon: attachmentSvg[attachmentId].src,
              }
            }
          }
        }
      }
    }
    // 出现过的lengend 数组去重
    lenTmp = Array.from(new Set(lenTmp))
    // 数组排序
    lenTmp = lenTmp.sort((a, b) => {
      return a - b
    })
    // Aterior tippomh twin 2 移动到 1 的后面
    if (lenTmp.includes(10) && lenTmp.includes(21)) {
      const twin1Index = lenTmp.findIndex((item) => item === 10)
      lenTmp.splice(twin1Index + 1, 0, 21)
      lenTmp = Array.from(new Set(lenTmp))
    }
    // Posterior tipping 2 移动到 1 的后面
    if (lenTmp.includes(18) && lenTmp.includes(22)) {
      const twin1Index = lenTmp.findIndex((item) => item === 18)
      lenTmp.splice(twin1Index + 1, 0, 22)
      lenTmp = Array.from(new Set(lenTmp))
    }
    setLengendData(lenTmp)
    setData(obj)
  }, [attachment])

  return (
    <Box className={"auxWrap"}>
      <div
        className={"legendsBox"}
        style={{
          marginRight: "auto",
          marginLeft: "34px",
          flexWrap: "wrap",
          paddingRight: "30px",
          height: "auto",
        }}
      >
        {lengendData.map((i) => {
          return (
            <div
              className={"container"}
              style={{ marginBottom: "12px" }}
              key={i}
            >
              <img
                style={{
                  width: i === 29 || i === 18 || i === 12 ? "14px" : "unset",
                }}
                src={attachmentSvg[i].src}
              />
              <p>{attachmentSvg[i].name}</p>
            </div>
          )
        })}
      </div>
      <div className={"content"}>
        <div className={"leftBox"}>
          <div className="names">
            <p>Lingual</p>
            <p>Buccal</p>
            <p>Lingual</p>
          </div>
          <div className={"quaBox"}>
            <div className={"qua qua1"}>
              {qua1Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* lingual */}

                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? `url(${
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual
                                .icon
                            })`
                          : "none",
                        backgroundSize: "auto",
                      }}
                    ></div>
                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua1[j]}
                    </div>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? `url(${
                                auxData[getIDs(toothIdTypes[i], auxData)].buccal
                                  .icon
                              })`
                            : "none",
                          backgroundSize: "auto",
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={"qua qua4"}>
              {qua4Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? `url(${
                                auxData[getIDs(toothIdTypes[i], auxData)].buccal
                                  .icon
                              })`
                            : "none",
                          backgroundSize: "auto",
                        }}
                      ></div>
                    )}
                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua4[j]}
                    </div>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? `url(${
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual
                                .icon
                            })`
                          : "none",
                        backgroundSize: "auto",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            <div className={"direction"} style={{ top: "0", right: "-8px" }}>
              R
            </div>
          </div>
        </div>
        <div className={"leftBox rightBox"}>
          <div className={"quaBox"}>
            <div className={"qua qua2"}>
              {qua2Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? `url(${
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual
                                .icon
                            })`
                          : "none",
                        backgroundSize: "auto",
                      }}
                    ></div>
                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua2[j]}
                    </div>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? `url(${
                                auxData[getIDs(toothIdTypes[i], auxData)].buccal
                                  .icon
                              })`
                            : "none",
                          backgroundSize: "auto",
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={"qua qua3"}>
              {qua3Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? `url(${
                                auxData[getIDs(toothIdTypes[i], auxData)].buccal
                                  .icon
                              })`
                            : "none",
                          backgroundSize: "auto",
                        }}
                      ></div>
                    )}

                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua3[j]}
                    </div>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? `url(${
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual
                                .icon
                            })`
                          : "none",
                        backgroundSize: "auto",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            <div className={"direction"} style={{ top: "0", left: "-8px" }}>
              L
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default AttachmentComponent
