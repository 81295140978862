import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box, IconButton } from "../../components/mui.components"
import { Close } from "../../components/mui.icons"
import { UButton, UModalPopup, UText } from "../../ui-component/components"

import { AddAddressForm } from "./AddAddressForm"

export const AddOrgAddress: FC = () => {
  const [openAddressForm, setOpenAddressForm] = useState(false)
  const { t } = useTranslation()

  const renderShippingfield = (key: string, val: string) => {
    return (
      <Box component={"div"} sx={{ pb: 1, display: "flex" }}>
        <UText variant="body1" sxProp={{ fontWeight: "500", width: "230px" }}>
          {key}
        </UText>
        <UText variant="body1">{val}</UText>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 4,
        backgroundColor: "background.default",
        boxShadow: 1,
        borderRadius: 2,
        width: "100%",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
          <UText variant="h6">{"Company Address"}</UText>
          <UButton
            variant={"contained"}
            btnText={t("uLabAccount.shipping.btn")}
            onClickHandler={() => setOpenAddressForm(true)}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box component={"div"}>
            {renderShippingfield(
              t("uLabAccount.shipping.alternateName"),
              "test",
            )}
            {renderShippingfield(
              t("uLabAccount.shipping.businessName"),
              "test",
            )}
            {renderShippingfield(t("uLabAccount.shipping.address"), "test")}
            {renderShippingfield(t("uLabAccount.shipping.line2"), "test")}
            {renderShippingfield(t("uLabAccount.shipping.city"), "test")}
            {renderShippingfield(t("uLabAccount.shipping.state"), "test")}
            {renderShippingfield(t("uLabAccount.shipping.zip"), "test")}
            {renderShippingfield(t("uLabAccount.shipping.country"), "test")}
            {renderShippingfield(t("uLabAccount.shipping.phone"), "test")}
          </Box>
          <UButton
            variant="text"
            btnText={t("button.edit")}
            onClickHandler={() => setOpenAddressForm(true)}
          />
        </Box>
        <UModalPopup
          sxModalProps={{
            "#titleCntr": {
              pt: 3,
              px: 3,
              pb: 2,
              backgroundColor: "divider",
              display: "block",
            },
          }}
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <UText variant={"h6"}>{"Add Company Address"}</UText>
              <IconButton
                aria-label={"close"}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                onClick={() => setOpenAddressForm(false)}
              >
                <Close
                  fontSize={"small"}
                  sx={{
                    color: "rgba(0, 0, 0, 0.56)",
                  }}
                />
              </IconButton>
            </Box>
          }
          content={<AddAddressForm setOpenAddressForm={setOpenAddressForm} />}
          isAlert={openAddressForm}
          btnList={[]}
        />
      </Box>
    </Box>
  )
}
