import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { IAddOptionType, attachmentModule, wasmModule } from "@/gluelayer"
import { protocalData } from "@/modules/Clinical/components/AuxPanel/columns"
import AuxFold from "@/modules/Clinical/components/AuxPanel/components/AuxFold/auxFold"
import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"

export const UDAuxProtocol = () => {
  const {
    clinicalSettings: { DefinAttachmentTemplate },
  } = useAppSelector((state: RootState) => state.userService)

  const [disCheckboxs, setDischeckboxs] = useState([false, false, false])

  const [mycheckboxMap, setCheckboxMap] = useState(new Map<string, boolean>())

  const setCheckValue = (key: string, value: boolean) => {
    setCheckboxMap((prevMap) => {
      let newMap = new Map(prevMap)
      newMap.set(key, value)
      return newMap
    })
  }

  useEffect(() => {
    // console.log('DefinAttachmentTemplate', DefinAttachmentTemplate)
    DefinAttachmentTemplate.map((data) => {
      setCheckValue(data.name, false)
    })
  }, [DefinAttachmentTemplate])

  const clearAll = () => {
    attachmentModule.deleteAllAttachment(0)
    setDischeckboxs([false, false, false])

    // 收集所有需要更新的键
    const keysToUpdate = Array.from(mycheckboxMap.keys())

    // 一次性更新所有键的值为false
    setCheckboxMap((prevMap) => {
      let newMap = new Map(prevMap)
      keysToUpdate.forEach((key) => {
        newMap.set(key, false)
      })
      return newMap
    })
  }

  // click protocol btn
  const onChecked = (data) => {
    setCheckValue(data.name, true)
    attachmentModule.setProtocolData(data)
  }
  return (
    <Box>
      <Stack direction="row-reverse">
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() => {
            clearAll()
          }}
        >
          CLEAR ALL
        </Button>
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        {DefinAttachmentTemplate.map((i) => {
          return (
            <Grid key={i.id} container spacing={2} alignItems={"center"}>
              <Grid item xs={3}>
                <Typography>{i.name}</Typography>
              </Grid>
              {i.name === "Distalization" ? (
                <>
                  <Grid item xs={3} alignItems={"center"}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={disCheckboxs[0]}
                      onChange={(e, checked) => {
                        if (checked) {
                          setDischeckboxs([
                            true,
                            disCheckboxs[1],
                            disCheckboxs[2],
                          ])
                          attachmentModule.setDistalization(
                            i,
                            IAddOptionType.Left,
                          )
                        }
                      }}
                      label="Left"
                    />
                  </Grid>
                  <Grid item xs={3} alignItems={"center"}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={disCheckboxs[1]}
                      onChange={(e, checked) => {
                        if (checked) {
                          setDischeckboxs([
                            disCheckboxs[0],
                            true,
                            disCheckboxs[1],
                          ])
                          attachmentModule.setDistalization(
                            i,
                            IAddOptionType.Right,
                          )
                        }
                      }}
                      label="Right"
                    />
                  </Grid>
                  <Grid item xs={3} alignItems={"center"}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={disCheckboxs[2]}
                      onChange={(e, checked) => {
                        if (checked) {
                          setDischeckboxs([
                            disCheckboxs[0],
                            disCheckboxs[1],
                            true,
                          ])
                          attachmentModule.setDistalization(
                            i,
                            IAddOptionType.All,
                          )
                        }
                      }}
                      label="Both"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={9} alignItems={"center"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={
                      mycheckboxMap.has(i.name)
                        ? mycheckboxMap.get(i.name)
                        : false
                    }
                    onChange={(e, checked) => {
                      if (checked) onChecked(i)
                    }}
                    label="Apply"
                  />
                </Grid>
              )}
            </Grid>
          )
        })}
      </Box>
    </Box>
  )
}
