import { useEffect, useState } from "react"
import { Box, Grid, Typography, Divider, Button, Switch } from "@mui/material"
import { UDTabs } from "../../custom/UDTabs"
import { UDTitleSelect } from "../../custom/UDSelectOptions"
import { UDSetupTooth } from "../../UDesign/UDSetupTooth"
import { UDSetupArchform } from "../../UDesign/UDSetupArchform"
import { RightPage } from "../RightPage"
import { gdsPlusSetup, wasmModule, SetupType } from "@/gluelayer"
import { useAppDispatch } from "@/core/app/hooks"
import { setDisable, updateAutoSetup } from "@/UDTreat/udSetupSlice"
import { setUpdateStaging } from "@/UDTreat/udTreatSlice"

const ReviewModAttTab = () => {
  return (
    <Grid container alignItems={"center"} spacing={0.25}>
      <UDTitleSelect
        title="Attachment"
        options={["Auto", "Deep Bite", "Open Bite", "Extraction"]}
      />
      <Grid item xs={12}>
        <Box sx={{ w: "100%", height: "300px", border: 1 }}>
          Attachment Panel
        </Box>
      </Grid>
    </Grid>
  )
}
export function UDReviewMod(props: any) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!wasmModule.isInit) return
    // dispatch(setLockCaseForEdit("Start"))
    gdsPlusSetup.SwitchAutoSetupModule(true)
    gdsPlusSetup.setupByType(SetupType.FineTune, () => {
      dispatch(setDisable(false))
      dispatch(updateAutoSetup())
      dispatch(setUpdateStaging())
    })
    return () => {
      console.log("End chairside setup")
      gdsPlusSetup.SwitchAutoSetupModule(false)
    }
  }, [])
  return (
    <RightPage
      title="Review/Mod. Case"
      onClose={props.onClose}
      content={
        <UDTabs labels={["Tooth", "Arch", "Attachment"]}>
          <UDSetupTooth />
          <UDSetupArchform />
          <ReviewModAttTab />
        </UDTabs>
      }
    />
  )
}
