import { useEffect, useState } from "react"
import { Stack, TextField, Typography, Tooltip, Box } from "@mui/material"
import { LeftPage } from "./LeftPage"

export function UDBolton(props: any) {
  return (
    <LeftPage
      title="Bolton Analysis"
      onClose={props.onClose}
      content={<Box> Bolton Table </Box>}
    />
  )
}
