import { createSlice } from "@reduxjs/toolkit"

import { PhotoObject } from "../photograph/photograph.type"

import {
  deleteFiles,
  deleteProfilePhoto,
  downloadDentalChart,
  downloadProfilePhoto,
  downloadReportpdf,
  downloadXray,
  uploadDentalchart,
  uploadProfilePhoto,
  uploadReportpdf,
  uploadXray,
} from "./xraysThunkApi"

interface XrayUploadState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  xrayUploadLoading: "idle" | "pending" | "succeeded" | "failed"
  pano: PhotoObject[]
  ceph: PhotoObject[]
  otherXrays: PhotoObject[]
  profile: PhotoObject[]
  dentalChart: Blob
  reportPDF: Blob
  xrayCount: number
  xrayAdditional: PhotoObject[]
}

const initialState: XrayUploadState = {
  loading: "idle",
  xrayUploadLoading: "idle",
  pano: [],
  ceph: [],
  otherXrays: [],
  profile: [],
  dentalChart: null,
  reportPDF: null,
  xrayCount: 0,
  xrayAdditional: [],
}

export const xrayServiceSlice = createSlice({
  name: "XrayService",
  initialState,
  reducers: {
    resetXrays: (state) => {
      state.pano = []
      state.ceph = []
      state.otherXrays = []
      state.xrayAdditional = []
    },
    resetXrayCount: (state) => {
      state.xrayCount = 0
    },
    setAdditionalXrays: (state) => {
      const newData = [...state.otherXrays, ...state.xrayAdditional]
      state.otherXrays = [...newData]
      state.xrayAdditional = []
    },
    removeAdditionalXray: (state, action) => {
      state.otherXrays = [...action.payload.newArray]
    },
    resetProfilePhoto: (state) => {
      state.profile = []
    },
    removeXrayAdditional: (state, action) => {
      const newArrray = state.xrayAdditional.filter(
        (obj) => obj.fileName !== action.payload.fileName,
      )
      state.xrayAdditional = [...newArrray]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadXray.pending, (state) => {
      state.xrayUploadLoading = "pending"
    })
    builder.addCase(uploadXray.fulfilled, (state, action) => {
      const result = action.payload.data.result
      const { file, radioGraphType, isAdditional } = action.meta.arg
      if (file && !isAdditional) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          sequence: result.sequence,
          position: result.position,
          fileName: result.file_name,
          size: file.size,
        }
        switch (radioGraphType) {
          case "pano":
            state.pano = [fileObj]
            break
          case "ceph":
            state.ceph = [fileObj]
            break
          case "others":
            state.otherXrays = [...state.otherXrays, fileObj]
            break
        }
      }
      if (file && isAdditional) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          sequence: result.sequence,
          position: result.photo_position,
          fileName: result.file_name,
          size: file.size,
        }
        if (fileObj.fileName.includes("others")) {
          state.xrayAdditional.push(fileObj)
        }
      }
      state.xrayUploadLoading = "succeeded"
      state.xrayCount++
    })
    builder.addCase(uploadXray.rejected, (state) => {
      console.log("rejected")
      state.xrayUploadLoading = "failed"
      state.xrayCount++
    })

    builder.addCase(downloadXray.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadXray.fulfilled, (state, action) => {
      const result = action.payload.data
      if (result) {
        const file = new File(
          [result],
          `${action.meta.arg.original_file_name}`,
          { type: "image/jpg" },
        )
        const obj: PhotoObject = {
          src: URL.createObjectURL(file),
          sequence: action.meta.arg.sequence,
          position: action.meta.arg.position,
          name: action.meta.arg.original_file_name,
          fileName: action.meta.arg.fileName,
          size: file.size,
        }
        const xrayType = action.meta.arg.XrayType
        switch (xrayType) {
          case "pano":
            state.pano = [obj]
            break
          case "ceph":
            state.ceph = [obj]
            break
          case "others":
            state.otherXrays = [...state.otherXrays, obj]
            break
          default:
            break
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadXray.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(deleteFiles.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(deleteFiles.fulfilled, (state, action) => {
      const res = action.payload // TODO: for testing purposes will remove this later
      if (res.data.status === "Success") {
        const fileName = action.meta.arg.fileName
        if (fileName.includes("pano")) {
          state.pano = []
        } else if (fileName.includes("ceph")) {
          state.ceph = []
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(deleteFiles.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(uploadProfilePhoto.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadProfilePhoto.fulfilled, (state, action) => {
      const result = action.payload.data.result
      const { file } = action.meta.arg
      if (file) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          fileName: result.file_name,
          size: file.size,
        }
        state.profile = [fileObj]
      }
      state.loading = "succeeded"
    })
    builder.addCase(uploadProfilePhoto.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })
    builder.addCase(downloadProfilePhoto.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadProfilePhoto.fulfilled, (state, action) => {
      const result = action.payload.data
      if (result) {
        const file = new File([result], `${action.meta.arg.fileName}`, {
          type: "image/jpg",
        })
        const obj: PhotoObject = {
          src: URL.createObjectURL(file),
          fileName: action.meta.arg.fileName,
          size: file.size,
        }
        state.profile = [obj]
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadProfilePhoto.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(deleteProfilePhoto.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(deleteProfilePhoto.fulfilled, (state, action) => {
      const result = action?.payload
      if (result?.status === 200) {
        state.profile = []
        state.loading = "succeeded"
      }
    })
    builder.addCase(deleteProfilePhoto.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(uploadDentalchart.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadDentalchart.fulfilled, (state, action) => {
      const result = action.payload.data.result
      const { file } = action.meta.arg
      if (file) {
        console.log(file, "fileobj")
        state.dentalChart = file
      }
      state.loading = "succeeded"
    })
    builder.addCase(uploadDentalchart.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })
    builder.addCase(downloadDentalChart.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadDentalChart.fulfilled, (state, action) => {
      const result = action.payload.data
      if (result) {
        state.dentalChart = result
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadDentalChart.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })

    builder.addCase(uploadReportpdf.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadReportpdf.fulfilled, (state, action) => {
      const result = action.payload.data.result
      const { file } = action.meta.arg
      if (file) {
        console.log(file, "fileobj")
        state.reportPDF = file
      }
      state.loading = "succeeded"
    })
    builder.addCase(uploadReportpdf.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })

    builder.addCase(downloadReportpdf.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadReportpdf.fulfilled, (state, action) => {
      const result = action.payload.data
      if (result) {
        state.reportPDF = result
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadReportpdf.rejected, (state) => {
      console.log("rejected")
      state.loading = "failed"
    })
  },
})
export const {
  resetXrays,
  setAdditionalXrays,
  removeAdditionalXray,
  resetProfilePhoto,
  resetXrayCount,
  removeXrayAdditional,
} = xrayServiceSlice.actions
