export const toothIdTypes = {
  1: [1, 501, 701],
  2: [2, 502, 702],
  3: [3, 503, 703],
  4: [4, 504, 704],
  5: [5, 505, 705],
  6: [6, 506, 706],
  7: [7, 507, 707],
  8: [8, 508, 708],
  9: [9, 509, 709],
  10: [10, 510, 710],
  11: [11, 511, 711],
  12: [12, 512, 712],
  13: [13, 513, 713],
  14: [14, 514, 714],
  15: [15, 515, 715],
  16: [16, 516, 716],
  17: [17, 517, 717],
  18: [18, 518, 718],
  19: [19, 519, 719],
  20: [20, 520, 720],
  21: [21, 521, 721],
  22: [22, 522, 722],
  23: [23, 523, 723],
  24: [24, 524, 724],
  25: [25, 525, 725],
  26: [26, 526, 726],
  27: [27, 527, 727],
  28: [28, 528, 728],
  29: [29, 529, 729],
  30: [30, 530, 730],
  31: [31, 531, 731],
  32: [32, 532, 732],
}

export const getIDs = (tmp: number[], obj: any) => {
  let id = 0
  tmp.map((i) => {
    if (obj[i]) {
      id = i
    }
  })
  return id
}
