import * as React from "react"
import { useEffect, useState } from "react"
import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { UDTreat } from "@/UDTreat/UDTreat"

import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import {
  resetAction,
  resetCase,
} from "../../core/app/slices/clinical/clinicalSlice"
import { RootState } from "../../core/app/store" // For uSmileAI
import WeSmileTreatNew from "../../wasm3d/WeSmileTreatNew"

import Scans from "./scans/Scans"
//UFlowChange
import Treat from "./Treat/Treat"

const ClinicalModule: FC = () => {
  return (
    <Routes>
      <Route path={"treat"} element={<SwitchSmileAI />} />
    </Routes>
  )
}

const SwitchSmileAI = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetAction())
    return () => {
      dispatch(resetAction())
    }
  }, [])

  /* 
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )
  return weSmileUI ? <WeSmileTreatNew /> : <Treat />*/
  return <UDTreat />
}

export default ClinicalModule
