import { FC, useEffect, useState } from "react"
import { Actions, ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import { useJsonForms } from "@jsonforms/react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  OutlinedInput,
} from "@mui/material"

import { UText } from "../../../ui-component"

interface InputProps {
  width: number
  key: string
  const: string
  errorMsg: string
  multiline?: boolean
  minRows?: number
  isNum?: boolean
  startLabel?: string
  endLabel?: string
  showInSameLine?: boolean
  startAdornment?: string
  placeholder?: string
  isOptional?: boolean
}

const UCheckboxGroup: FC<ControlProps> = (props) => {
  const { schema, path, label, data, visible, handleChange } = props
  const input = schema?.input as InputProps
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")

  const handleCheckboxChange = (value: string) => {
    let checkBoxValues
    schema.type === "array"
      ? (checkBoxValues = [...data])
      : (checkBoxValues = [...data.value])
    const index = checkBoxValues.indexOf(value)
    const temp = schema.type === "array" ? data : { ...data }
    if (index === -1) {
      checkBoxValues.push(value)
      if (schema.type === "object" && input.const === value) {
        temp[input.key] = ""
      }
    } else {
      checkBoxValues.splice(index, 1)
      if (schema.type === "object" && input.const === value && data) {
        delete temp[input.key]
      }
    }

    const newData =
      schema.type === "array"
        ? checkBoxValues
        : { ...temp, value: checkBoxValues }
    handleChange(path, newData)
  }

  const validateCustomField = () => {
    if (core?.schema.required?.indexOf(path) === -1 || !visible) {
      return
    }
    if (schema.type === "object") {
      updateErrors(
        !data.value.length ||
          // eslint-disable-next-line no-prototype-builtins
          (data.hasOwnProperty(input.key) &&
            !input.isOptional &&
            data[input.key] === "")
          ? "This is a required field"
          : "",
      )
    } else {
      updateErrors(data.length ? "" : "This is a required field")
    }
  }

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  useEffect(() => {
    if (!visible) {
      handleChange(path, schema.type === "array" ? [] : { value: [] })
      updateErrors("")
    } else if (core?.schema.required?.indexOf(path) !== -1) {
      updateErrors("This is a required field")
    }
  }, [visible])

  useEffect(() => {
    validateCustomField()
  }, [data, core?.validationMode])

  return (
    <>
      {visible && (
        <FormControl
          error={!!(errors && core?.validationMode === "ValidateAndShow")}
          sx={{ my: 1, display: "flex", flexDirection: "row", pl: 1 }}
        >
          <UText
            variant={"body2"}
            sxProp={{
              minWidth: 200,
              maxWidth: 200,
              marginTop: 1,
              color:
                errors && core?.validationMode === "ValidateAndShow"
                  ? "error.main"
                  : "text.secondary",
            }}
          >
            {label}
          </UText>
          <Box>
            <FormGroup sx={{ pl: 1 }}>
              {schema.checkboxes.map(
                (item: { label: string; value: string }, index: number) => {
                  return (
                    <Box key={index}>
                      <FormControlLabel
                        sx={{ display: "inline-block" }}
                        key={item.value}
                        control={
                          <Checkbox
                            checked={
                              data
                                ? schema.type === "array"
                                  ? data.indexOf(item.value) > -1
                                  : data.value.indexOf(item.value) > -1
                                : false
                            }
                            onChange={() => {
                              handleCheckboxChange(item.value)
                            }}
                            name={item.value}
                          />
                        }
                        label={
                          <span
                            style={{
                              // v1
                              width: "290px",
                              // v2:
                              // width: "200px",
                              display: "inline-flex",
                            }}
                          >
                            {item.label}
                          </span>
                        }
                      />
                      {input &&
                        input.const === item.value &&
                        data &&
                        data.value.indexOf(item.value) > -1 && (
                          <Box
                            sx={{
                              ml: input.showInSameLine ? "0" : "34px",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              justifyContent: "start",
                            }}
                          >
                            {input.startLabel && (
                              <UText variant={"body1"}>
                                {input.startLabel}
                              </UText>
                            )}
                            <OutlinedInput
                              fullWidth
                              multiline={input.multiline}
                              id={label}
                              sx={{
                                padding: "0 12px",
                                width: input.width,
                                minHeight: 40,
                                input: {
                                  padding: "0",
                                },
                              }}
                              minRows={input.minRows}
                              maxRows={4}
                              placeholder={input.placeholder}
                              value={data[input.key] || ""}
                              onChange={(e) => {
                                {
                                  handleChange(path, {
                                    ...data,
                                    [input.key]: e.target.value,
                                  })
                                }
                              }}
                            />
                            {input.endLabel && (
                              <UText variant="body1">{input.endLabel}</UText>
                            )}
                          </Box>
                        )}
                    </Box>
                  )
                },
              )}
              {errors && core?.validationMode === "ValidateAndShow" && (
                <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
              )}
            </FormGroup>
          </Box>
        </FormControl>
      )}
    </>
  )
}

export default withJsonFormsControlProps(UCheckboxGroup)
