import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import {
  downloadCaseZip,
  getCaseByCaseId,
  getCaseByPatientId,
  unarchiveCaseStatus,
  updateCaseByCaseId,
  updateCaseStatusById,
} from "./case.service"

export const updateCaseStatus = createAsyncThunk(
  "caseService /updateCaseStatus",
  async (
    {
      patientId,
      caseId,
      payload,
    }: {
      patientId: string
      caseId: string
      payload: { case_disposition: string }
    },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return updateCaseStatusById(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateCaseUnarchive = createAsyncThunk(
  "caseService /updateCaseUnarchive",
  async (
    {
      patientId,
      caseId,
    }: {
      patientId: string
      caseId: string
    },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return unarchiveCaseStatus(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getCaseById = createAsyncThunk(
  "caseService /getCaseById",
  async (
    {
      patientId,
      caseId,
    }: {
      patientId: string
      caseId: string
    },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return getCaseByCaseId(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getCaseByPatient = createAsyncThunk(
  "caseService/getCaseByPatient",
  async (
    {
      patientId,
    }: {
      patientId: string
    },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return getCaseByPatientId(orgId, patientId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateCaseById = createAsyncThunk(
  "caseService/updateCaseById",
  async (
    {
      patientId,
      caseId,
      payload,
    }: {
      patientId: string
      caseId: string
      payload: {
        assigned_to?: number
        txplan_name?: string
        txplan_subname?: string
        case_disposition?: string
      }
    },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return updateCaseByCaseId(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getCaseFilesByCaseId = createAsyncThunk(
  "caseService/getCaseFilesByCaseId",
  async (
    {
      patientId,
      caseId,
    }: {
      patientId: number
      caseId: number
    },
    { rejectWithValue },
  ) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await downloadCaseZip(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
