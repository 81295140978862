import { useEffect, useRef } from "react"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { isEqual } from "lodash"

import type { AppDispatch, RootState } from "./store"

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export function useDeepEffect<T>(fn, deps: T) {
  const renderRef = useRef<number | any>(0)
  const depsRef = useRef<T>(deps)
  if (!isEqual(deps, depsRef.current)) {
    renderRef.current += 1
  }
  depsRef.current = deps
  return useEffect(fn, [renderRef.current])
}
