import axios from "axios"

import httpIT from "@/core/global/httpIT"

import { CASE_STATUS } from "../../../../modules/Patient/config/status.config"
import http from "../../../global/http"
import {
  IExistingPatientList,
  IPatientList,
} from "../../../model/interface/IPatient"
import { ExistingPatientList, PatientList } from "../../../model/Patient"

import {
  AddNewPatientParams,
  ExistingPatientQueryParams,
  PatientQueryparams,
  updatePatientParams,
} from "./patient.types"

export const getPatientList = async (
  orgId: string,
  patientQueryparams: PatientQueryparams,
): Promise<any> => {
  try {
    const newPaload = JSON.parse(JSON.stringify(patientQueryparams))
    if (
      patientQueryparams.fields &&
      patientQueryparams.fields.status &&
      patientQueryparams.fields.status.length > 0
    ) {
      if (
        patientQueryparams.fields.status.includes(
          CASE_STATUS.ATTENTION_NEEDED,
        ) &&
        !patientQueryparams.fields.status.includes(
          CASE_STATUS.ATTENTION_NEEDED_REFINEMENT,
        )
      ) {
        newPaload.fields.status.push(CASE_STATUS.ATTENTION_NEEDED_REFINEMENT)
      }
      if (
        patientQueryparams.fields.status.includes(
          CASE_STATUS.SUBMITTED_TO_UASSIST,
        )
      ) {
        newPaload.fields.status.push(
          CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
        )
      }
      if (
        patientQueryparams.fields.status.includes(
          CASE_STATUS.PLANNING_IN_PROGRESS,
        )
      ) {
        newPaload.fields.status.push(
          CASE_STATUS.PLANNING_IN_PROGRESS_REFINEMENT,
        )
      }
    }
    const patients: IPatientList[] = []
    const rs: any = await http.post(`/shared/v1/org/${orgId}/search/patients`, {
      ...newPaload,
    })
    if (rs && rs.status === 200) {
      const { paging, results } = rs.data
      if (results && results.length > 0) {
        results.forEach((element) => {
          patients.push(new PatientList().Deserialize(element))
        })
      }
      return {
        totalRecords: paging.total_count,
        currentPage: paging.current_page,
        pageSize: paging.page_size,
        actionNeededCount: paging.action_needed_count,
        records: patients,
      }
    }
  } catch (err) {
    return err
  }
}

export const getExistingPatientList = async (
  orgId: string,
  existingPatientQueryparams: ExistingPatientQueryParams,
): Promise<any> => {
  try {
    const patients: IExistingPatientList[] = []
    const { firstName, lastName, dob } = existingPatientQueryparams
    const rs: any = await http.get(
      `/shared/v1/org/${orgId}/search/patients?first_name=${firstName}&last_name=${lastName}&dob=${dob}`,
    )
    if (rs && rs.status === 200) {
      const { results } = rs.data
      if (results && results.length > 0) {
        results.forEach((element) => {
          patients.push(new ExistingPatientList().Deserialize(element))
        })
      }
      return {
        records: patients,
      }
    }
  } catch (err) {
    return err
  }
}

export const fetchPatientByIdService = async (
  orgId: string,
  patientId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}?fields=id,first_name,last_name,patient_dob,ref_notes,email,phone,status,country`,
    )
    return data
  } catch (err) {
    return err
  }
}

/**
 * fetchPatientInformation
 * @param orgId
 * @param patientId
 * @returns
 */
export async function fetchPatientInformationByPatientId(
  orgId: string,
  patientId: string,
) {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}?fields=id,first_name,last_name,status,phone,email,patient_dob,create_ts,sms_optin,dm_patient_id`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const createPatient = async (
  orgId: string,
  addNewPatientParams: AddNewPatientParams,
) => {
  try {
    return await http.post(
      `/shared/v1/org/${orgId}/orchestration/patients/`,
      addNewPatientParams,
    )
  } catch (err) {
    return err
  }
}

export const addNewCaseService = async (
  orgId: string,
  patientId: string,
  payload: any,
) => {
  try {
    return await http.post(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans`,
      payload,
    )
  } catch (err) {
    return err
  }
}

export const updatePatient = async (
  orgId: string,
  patientId: string,
  payload: updatePatientParams,
) => {
  try {
    return await http.patch(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}`,
      payload,
    )
  } catch (err) {
    return err
  }
}

// add patient service version2
export const addPatientServiceV2 = async (payload: any) => {
  try {
    return await httpIT.post(`/ulab/assit/api/v2/patient`, payload)
  } catch (err) {
    return err
  }
}

// add case service version2
export const addCaseServiceV2 = async (payload: any) => {
  try {
    return await httpIT.post(`/ulab/assit/api/v2/case`, payload)
  } catch (err) {
    return err
  }
}
