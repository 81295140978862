import { FC, forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useParams } from "react-router-dom"
import { Box, Button } from "@mui/material"
import { pdf, PDFViewer } from "@react-pdf/renderer"
import html2canvas from "html2canvas"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { uploadReportpdf } from "../../../../core/app/slices/records/xrays"
import { fetchUserProfile } from "../../../../core/app/slices/user/userApis"
import { RootState } from "../../../../core/app/store"
import AttachmentComponent from "../Report/components/Attachment/attachment"
import Auxilliaries from "../Report/components/Auxilliaries/auxilliaries"
import IPR from "../Report/components/IPR/ipr"
import { reportPDFProps, reportProps } from "../Report/type"

import ReportPDF from "./reportPdf"

import "../Report/report.scss"

export const ScreeshotReport = forwardRef(
  ({ isReady, toothlist, attachment, iprData }: reportPDFProps, ref) => {
    const { patientData } = useAppSelector(
      (state: RootState) => state.patientService,
    )
    const { caseId } = useParams()

    const { org_name } = useAppSelector(
      (state: RootState) => state.userService.userProfile,
    )
    const dispatch = useAppDispatch()

    const attRef = useRef(null)
    const auxRef = useRef(null)
    const iprRef = useRef(null)

    useEffect(() => {
      dispatch(fetchUserProfile())
    }, [])

    useImperativeHandle(ref, () => ({
      uploadReportPDF: (status: "open" | "upload") => {
        exportComponentAsPdf(status)
      },
    }))

    const exportComponentAsPdf = (status: "open" | "upload") => {
      if (attRef && auxRef && iprRef) {
        Promise.all([
          html2canvas(attRef.current, { scale: 2 }),
          html2canvas(auxRef.current, { scale: 2 }),
          html2canvas(iprRef.current, { scale: 2 }),
        ]).then(async (res) => {
          const attCanvas = res[0]
          const auxCanvas = res[1]
          const iprCanvas = res[2]

          const auximgDataUrl = auxCanvas.toDataURL("image/png")
          const iprimgDataUrl = iprCanvas.toDataURL("image/png")
          const attimgDataUrl = attCanvas.toDataURL("image/png")

          const blob = await pdf(
            ReportPDF({
              auxshot: auximgDataUrl,
              iprshot: iprimgDataUrl,
              attshot: attimgDataUrl,
              patientName: patientData.firstName + " " + patientData.lastName,
              caseID: caseId,
              org_name,
            }),
          ).toBlob()

          if (status == "upload") {
            const newFile = new File([blob], `ipr-att-report.pdf`, {
              type: "image/png",
            })
            const formData = new FormData()
            formData.append(
              "attachment",
              new File([blob], newFile.name, {
                type: "image/png",
              }),
            )
            formData.append("original_file_name", "ipr-att-report.pdf")

            dispatch(
              uploadReportpdf({
                file: blob,
                patientId: patientData.id,
                caseId,
                formData: formData,
                fileName: newFile.name,
              }),
            )
          } else {
            const url = URL.createObjectURL(blob)
            window.open(url, "_blank")
          }
        })
      }
    }
    return (
      isReady && (
        <Box
          sx={{
            position: "fixed",
            zIndex: -100, //Just need to get the dom element, don't need to display it
          }}
        >
          <Box
            width={710}
            height={1080}
            sx={{
              backgroundColor: "#ECEFF1",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
              id="attachment"
              ref={attRef}
            >
              <AttachmentComponent
                toothlist={toothlist}
                attachment={attachment}
              />
            </div>

            <div
              style={{
                position: "relative",
              }}
              id="auxilliaries"
              ref={auxRef}
            >
              <Auxilliaries toothlist={toothlist} attachment={attachment} />
            </div>

            <div
              style={{
                position: "relative",
                width: 710,
                height: 663,
                borderRadius: 4,
                padding: "0 16px",
              }}
              id="ipr"
              ref={iprRef}
            >
              <IPR toothlist={toothlist} iprData={iprData} />
            </div>
          </Box>
        </Box>
      )
    )
  },
)
ScreeshotReport.displayName = "ScreeshotReport"
