import { FC } from "react"
import { Controller, RegisterOptions } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Checkbox, FormControlLabel } from "../../../components/mui.components"
import { UText } from "../../../ui-component"

interface ICheckboxInputForm {
  label?: string
  fieldName: string
  rules?: RegisterOptions
  checked?: boolean
  control: any
}

const CheckboxInput: FC<ICheckboxInputForm> = ({
  checked,
  label,
  fieldName,
  control,
  rules,
}) => {
  const { t } = useTranslation("common")

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field: { name, onChange }, formState: { errors } }) => {
        const hasError = !!errors[name]
        return (
          <FormControlLabel
            sx={{
              m: 0,
              ".MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
              },
              pointerEvents: "none",
            }}
            control={
              <Checkbox
                name={name}
                onChange={(e) => onChange(e.target.checked)}
                checked={checked}
                color="success"
                sx={{
                  color: hasError ? "error.main" : "",
                }}
                tabIndex={-1}
                size={"small"}
              />
            }
            label={
              <UText
                color={"text.primary"}
                sxProp={{
                  display: "flex",
                  color: hasError ? "error.main" : "",
                }}
                variant={"body2"}
              >
                {t(label)}
              </UText>
            }
          />
        )
      }}
    />
  )
}

export default CheckboxInput
