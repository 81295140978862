import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  ToggleButton,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { biteRampData } from "./columns"
import { biteRampModule, stagingManager } from "@/gluelayer"

export const UDAuxBiteRamp = () => {
  const [biterampControl, setBiterampControl] = useState(false)
  const [startFrom, setStartFrom] = useState(0)
  const [endAt, setEndAt] = useState(0)
  const [checkedBiteramp, setCheckedBiteramp] = useState<number[]>([])

  const upStage = stagingManager.wasmStageData.jointUpKeypoints

  let stageMenuIems = []
  upStage.forEach((val) => {
    // if (val.name === "0") return
    if (val.name.includes("R")) stageMenuIems.push(val.name)
    else stageMenuIems.push(`stage${val.name}`)
  })

  useEffect(() => {
    const rampTmp = []
    biteRampData.map((data) => {
      if (biteRampModule.isToothHasBiteRamp(data.key)) {
        rampTmp.push(data.key)
      }
    })
    setCheckedBiteramp(rampTmp)
    biteRampModule.setOperationEnable(false)

    return () => {
      biteRampModule.setOperationEnable(false)
    }
  }, [])

  useEffect(() => {
    biteRampModule.setOperationEnable(biterampControl)
  }, [biterampControl])

  useEffect(() => {
    //get start stage and end stage
    const start = biteRampModule.getStartStage()
    const end = biteRampModule.getEndStage()
    setStartFrom(start)
    setEndAt(end)
  }, [checkedBiteramp])

  // checkbox bite ramp
  const handleCheckRamp = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
  ) => {
    let rampTmp = JSON.parse(JSON.stringify(checkedBiteramp))
    if (event.target.checked) {
      let mirrorKey = 0
      if (key === 6) {
        mirrorKey = 11
      } else if (key === 7) {
        mirrorKey = 10
      } else if (key === 8) {
        mirrorKey = 9
      } else if (key === 9) {
        mirrorKey = 8
      } else if (key === 10) {
        mirrorKey = 7
      } else if (key === 11) {
        mirrorKey = 6
      }
      const addBool = biteRampModule.autoAddBiteRampToTooth(key)
      if (!addBool) {
        return
      }
      rampTmp.push(key)
      const addBoolMirror = biteRampModule.autoAddBiteRampToTooth(mirrorKey)
      if (!addBoolMirror) {
        return
      }
      rampTmp.push(mirrorKey)
    } else {
      rampTmp = rampTmp.filter((item) => item !== key)
      biteRampModule.deleteBiteRamp(key)
    }

    setCheckedBiteramp(rampTmp)
  }

  // change start stage
  const startStageChange = (event) => {
    biteRampModule.changeStartStage(event.target.value)
    setStartFrom(event.target.value)
  }
  // change end stage
  const endStageChange = (event) => {
    biteRampModule.changeEndStage(event.target.value)
    setEndAt(event.target.value)
  }
  return (
    <Box width={"404px"} paddingLeft={1}>
      <Stack justifyContent="center" spacing={1} direction={"column"}>
        <Stack
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}
        >
          <Typography variant="h6">Bite Ramp</Typography>
          <ToggleButton
            value="bitecontrol"
            selected={biterampControl}
            onClick={() => {
              setBiterampControl(!biterampControl)
            }}
          >
            Toggle
          </ToggleButton>
        </Stack>

        <Typography>Select teeth to add bite ramps</Typography>

        <Stack
          justifyContent={"center"}
          direction="row"
          alignItems={"center"}
          alignContent={"center"}
          spacing={0.5}
        >
          <Typography>R</Typography>

          {biteRampData.map((data) => {
            return (
              <FormControlLabel
                key={data.key}
                value={data.key}
                control={
                  <Checkbox
                    checked={checkedBiteramp.includes(data.key)}
                    onChange={(event) => {
                      handleCheckRamp(event, data.key)
                    }}
                  />
                }
                label={data.num}
                labelPlacement="top"
              />
            )
          })}

          <Typography>L</Typography>
        </Stack>

        <Divider orientation="horizontal" flexItem />
        <Stack direction="row" justifyContent={"center"} alignItems={"center"}>
          <FormControl
            sx={{ m: 1, minWidth: 140 }}
            disabled={checkedBiteramp.length === 0 || upStage.length === 0}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Start from
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={checkedBiteramp.length === 0 ? "" : startFrom}
              onChange={startStageChange}
              label="Start from"
            >
              {stageMenuIems.map((val, index) => {
                return (
                  <MenuItem key={val} value={index}>
                    {val}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl
            sx={{ m: 1, minWidth: 140 }}
            disabled={checkedBiteramp.length === 0 || upStage.length === 0}
          >
            <InputLabel id="demo-simple-select-standard-label">
              End at
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={checkedBiteramp.length === 0 ? "" : endAt}
              onChange={endStageChange}
              label="End at"
            >
              {stageMenuIems.map((val, index) => {
                return (
                  <MenuItem key={val} value={index}>
                    {val}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  )
}
