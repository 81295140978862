import Deserialize from "./interface/Deserialize"
import {
  IAlacarteInfo,
  IAlacarteProps,
  IBundleInfo,
  IBundleProps,
  IQuotation,
} from "./interface/IQuotation"

export class BundleInfo implements Deserialize, IBundleInfo {
  id = ""
  sku_name = ""
  description = {
    price: 0,
    standard_shipping: {} as IBundleProps,
    retainer: {} as IBundleProps,
    aligner: {} as IBundleProps,
    template: {} as IBundleProps,
    custom_packaging: {} as IBundleProps,
    aligner_case: {} as IBundleProps,
    "5_day_shipping": {} as IBundleProps,
    uassist: {},
  }
  alacarte? = {
    name: "",
    sku_name: "",
    aligner: {} as IAlacarteProps,
    retainer: {} as IAlacarteProps,
    template: {} as IAlacarteProps,
    custom_packaging: {} as IAlacarteProps,
  }
  name = ""
  total_price = 0
  Deserialize(input: any): this {
    if (input.id) {
      this.id = input.id
      this.sku_name = input.sku_name
      this.description = { ...input.description }
      if (!["4 Retainers", "2 Retainers"].includes(input.name)) {
        this.alacarte = input.alacarte
      } else {
        this.alacarte = undefined
      }
      this.name = input.name
      this.total_price = input.total_price
    }
    return this
  }
}

export class Quotation implements IQuotation, Deserialize {
  bundlesAvailable = false
  isBundleCompleted = false
  isCurrentBundle = false
  isAlaCartePurched = false
  bundleType = "ALIGNER"
  bundlesPurchasedForCase = false
  previousUassistCredit = 0
  currency = ""
  alacarte = {} as IAlacarteInfo
  bundles = []
  previouslyOrderedPricing = null
  isAlignerCase = false
  remainingOrder = null
  Deserialize(input: any): this {
    if (input) {
      this.bundlesAvailable = input.bundles_available
      this.bundlesPurchasedForCase = input.bundles_purchased_for_case
      this.previousUassistCredit = input.previous_uassist_credit
      this.currency = input.currency
      if (input.bundles) {
        const list = []
        if (input.bundles.Mild) {
          list.push(new BundleInfo().Deserialize(input.bundles.Mild))
        }
        if (input.bundles.Moderate) {
          list.push(new BundleInfo().Deserialize(input.bundles.Moderate))
        }
        if (input.bundles.Comprehensive) {
          list.push(new BundleInfo().Deserialize(input.bundles.Comprehensive))
        }
        if (input.bundles.Finishing) {
          list.push(new BundleInfo().Deserialize(input.bundles.Finishing))
        }
        if (input.bundles["2 Retainer"]) {
          this.bundleType = "RETAINER"
          list.push(new BundleInfo().Deserialize(input.bundles["2 Retainer"]))
        }
        if (input.bundles["4 Retainer"]) {
          this.bundleType = "RETAINER"
          list.push(new BundleInfo().Deserialize(input.bundles["4 Retainer"]))
        }
        this.bundles = list
      }
      if (input.alacarte) {
        this.isAlaCartePurched =
          input.previously_ordered_pricing &&
          input.previously_ordered_pricing.toLowerCase() === "alacarte"
        this.alacarte = { ...input.alacarte }
      }
      if (input.previously_ordered_pricing && input.bundles) {
        this.isCurrentBundle = true

        if (input.previously_ordered_pricing !== "alacarte") {
          if (
            ["4 retainer", "2 retainer"].includes(
              input.previously_ordered_pricing,
            )
          ) {
            this.bundleType = "RETAINER"
            const bundle: BundleInfo = new BundleInfo().Deserialize(
              input.bundles[
                input.previously_ordered_pricing === "4 retainer"
                  ? "4 Retainer"
                  : "2 Retainer"
              ],
            )
            this.bundles = [bundle]
          } else {
            const str = input.previously_ordered_pricing.toLowerCase()

            const bundle: BundleInfo = new BundleInfo().Deserialize(
              input.bundles[str.charAt(0).toUpperCase() + str.slice(1)],
            )
            this.bundles = [bundle]
          }
        }
      }
      this.previouslyOrderedPricing = input.previously_ordered_pricing || null
      this.isAlignerCase = input.is_aligner_case
      this.remainingOrder = input.tx_complete_order
      // TODO: tobe remove future version
      /**
       * when bundle completed and previous order bundle not an alacaret we get empty bundle list from backend
       */
      if (!input.bundles_available && input.bundles_purchased_for_case) {
        this.isBundleCompleted = true
        if (!input.bundles) {
          this.bundles = [
            {
              name:
                input.previously_ordered_pricing.charAt(0).toUpperCase() +
                input.previously_ordered_pricing.slice(1),
              sku_name: input.previously_ordered_pricing,
            },
          ]
          if (input.alacarte) {
            this.alacarte = { ...input.alacarte }
          }
        } else {
          const bundle = this.bundles[0]
          if (this.bundleType === "RETAINER") {
            this.isBundleCompleted =
              bundle &&
              bundle?.description &&
              bundle?.description?.retainer?.consumed ===
                bundle?.description?.retainer?.included
          }

          if (this.bundleType === "ALIGNER") {
            this.isBundleCompleted =
              bundle &&
              bundle?.description &&
              bundle?.description?.retainer?.consumed ===
                bundle?.description?.retainer?.included
          }
        }
      }
    }
    return this
  }
}
