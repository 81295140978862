import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { IShippingAddress } from "../../../core/model/interface/IShippingAddress"
import { UText } from "../../../ui-component"
import { Box, Paper } from "../../../ui-component/mui.components"

import OrderSummary from "./OrderSummary"

const ShipOrder: FC = () => {
  const { t } = useTranslation()
  const [docName, setDocName] = useState<string>("")
  const { currentOrder } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )

  useEffect(() => {
    if (doctorList.length && caseDetail.assigned_to) {
      setDocName(
        doctorList?.find((list) => list.id === caseDetail.assigned_to)?.label,
      )
    }
  }, [doctorList, caseDetail])

  const getShippingAddress = (address: IShippingAddress) => {
    return (
      <>
        {docName && (
          <>
            {docName}
            <br />
          </>
        )}{" "}
        {address.line_1 || ""}
        <br />
        {address.line_2 && (
          <>
            {address.line_2}
            <br />
          </>
        )}
        {address.city +
          ", " +
          address.state.short_name +
          ", " +
          address.country.code_1 +
          ", " +
          address.zip_code}
      </>
    )
  }

  return (
    <Paper elevation={0} sx={{ borderRadius: 2, p: 2 }}>
      <Box>
        <UText variant={"subtitle2"}>{t("overview.order.shipping")}</UText>
      </Box>
      {currentOrder &&
        currentOrder.shipAddress &&
        currentOrder.shipAddress.id && (
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: "4px",
              p: 2,
              my: 2,
              pt: 1,
            }}
          >
            <UText
              component={"div"}
              sxProp={{ pb: 1, fontWeight: "500", lineHeight: "150%" }}
              variant={"body1"}
            >
              {currentOrder.shipAddress.business_name}
            </UText>
            <UText component={"div"} variant={"body2"}>
              {getShippingAddress(currentOrder.shipAddress)}
            </UText>
          </Box>
        )}
      <OrderSummary />
    </Paper>
  )
}

export default ShipOrder
