import { FC } from "react"

import { UModalPopup, UText } from "../../../ui-component"

import PaymentDetails from "./paymentDetails"

export const CreditCardModal: FC = () => {
  return (
    <UModalPopup
      title={<UText variant={"h6"}>{"Payment Information"}</UText>}
      sxModalProps={{
        ".MuiDialog-paper": {
          borderRadius: "8px",
        },
        "#titleCntr": {
          padding: "16px 32px",
          backgroundColor: "#E0E0E0",
        },
        "#contentCntr": {
          pt: "16px",
        },
      }}
      content={<PaymentDetails isCardExpiredModel />}
      isAlert
      btnList={[]}
    />
  )
}
