import { useState, Children } from "react";
import { Box, Tabs, Tab } from "@mui/material";
export const UDTabs = (props, labels: string[]) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    if (value === index)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          <Box>{children}</Box>
        </div>
      );
    else return <></>;
  }
  return (
    <>
      <Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {props.labels.map((label, index) => (
            <Tab sx={{ textTransform: "none" }} key={label} label={label} />
          ))}
        </Tabs>
      </Box>
      {Children.map(props.children, (child, index) => (
        <TabPanel value={tabValue} key={index} index={index}>
          {child}
        </TabPanel>
      ))}
    </>
  );
};
