import { FC, useEffect } from "react"
import { Outlet, useParams } from "react-router-dom"

import { Grid } from "../../../../components/mui.components"
import { useAppSelector } from "../../../../core/app/hooks"
import { useAppDispatch } from "../../../../core/app/hooks"
import { fetchFilesList } from "../../../../core/app/slices/records/photograph/photographThunkApi"
import { resetProfilePhoto } from "../../../../core/app/slices/records/xrays/xraysSlice"
import { downloadProfilePhoto } from "../../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../../core/app/store"

import RefinementHeader from "./RefinementHeader"

const Refinement: FC = () => {
  const dispatch = useAppDispatch()
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { patientId } = useParams()

  const { caseList } = useAppSelector((state: RootState) => state.caseService)
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const caseId = caseList[0]?.id
  useEffect(() => {
    if (!caseId) return
    dispatch(fetchFilesList({ patientId, caseId }))
  }, [caseId])

  useEffect(() => {
    if (!fileList) return
    const profileFileName = fileList.find(
      (file) => file.attachment_type === "avatar",
    )
    if (profileFileName?.file_name) {
      dispatch(resetProfilePhoto())
      dispatch(
        downloadProfilePhoto({
          patientId: patientId,
          fileName: profileFileName?.file_name,
        }),
      )
    }
  }, [fileList])

  return (
    <>
      <Grid
        container
        direction={"column"}
        spacing={0}
        justifyContent={"center"}
        sx={{ mt: 4 }}
      >
        <Grid item>
          <RefinementHeader
            patientData={patientData}
            caseDetail={caseList[0] && caseList[0]}
          />
        </Grid>
      </Grid>
      <Outlet />
    </>
  )
}

export default Refinement
