import { FC, useEffect, useState } from "react"
import { Actions, JsonSchema } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
} from "@mui/material"

import { UText } from "../../../ui-component"

export const UMultipleCheckbox: FC<{
  path: string
  errors: string
  label: string
  schema: JsonSchema
  data: object
  handleChange: (path: string, value: any) => void
}> = ({ path, label, schema, data, handleChange }) => {
  const [value, setValue] = useState<object>({})
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")
  const [isError, setIsError] = useState<boolean>(true)
  const [showcheckboxes, setShowcheckboxes] = useState<boolean>(false)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    R3: false,
    R2: false,
    R1: false,
    l1: false,
    l2: false,
    l3: false,
  })

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    console.log(name, checked)
    let updatedCheckboxes = { ...selectedCheckboxes }

    if (checked) {
      if (name === "R3" || name === "l3") {
        updatedCheckboxes = {
          ...updatedCheckboxes,
          R3: checked,
          l3: checked,
        }
      }
      if (name === "R2" || name === "l2") {
        updatedCheckboxes = {
          ...updatedCheckboxes,
          R2: checked,
          l2: checked,
        }
      }
      if (name === "R1" || name === "l1") {
        updatedCheckboxes = {
          ...updatedCheckboxes,
          R1: checked,
          l1: checked,
        }
      }
    } else {
      updatedCheckboxes = {
        ...updatedCheckboxes,
        [name]: checked,
      }
    }

    setSelectedCheckboxes(updatedCheckboxes)
    handleChange(path, updatedCheckboxes)
  }

  useEffect(() => {
    if (data) {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key) && data[key] === true) {
          setShowcheckboxes(true)
          setSelectedCheckboxes(data)
        }
      }
    }
  }, [data])

  useEffect(() => {
    const hasValues = () => {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key) && data[key] === true) {
          return false
        }
      }
      return true
    }
    const hasNoData = data && hasValues()
    setIsError(hasNoData)
    if (core?.schema.required?.indexOf(path) === -1) return
    if (hasNoData) {
      updateErrors("This is a required field")
    } else {
      updateErrors("")
    }
  }, [data])

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  const generateCheckbox = (name: string, label: string) => (
    <FormControlLabel
      sx={{ m: 0 }}
      labelPlacement="top"
      control={
        <Checkbox
          sx={{
            // v1:
            p: "9px",
            // v2:
            // p: "2px",
            pt: "4px",
            ">.MuiSvgIcon-fontSizeMedium": { height: "25px", width: "25px" },
          }}
          checked={selectedCheckboxes[name]}
          onChange={(e) => {
            handleCheckboxChange(e)
          }}
          name={name}
        />
      }
      label={label}
    />
  )

  return (
    <Box sx={{ display: "flex", flexDirection: "row", my: 1, pl: 1 }}>
      <UText
        variant={"body2"}
        sxProp={{
          minWidth: 200,
          display: "flex",
          flexDirection: "column",
          color:
            errors && core?.validationMode === "ValidateAndShow"
              ? "error.main"
              : "text.secondary",
        }}
      >
        {label}
      </UText>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <FormControlLabel
          sx={{ pl: 1 }}
          labelPlacement="end"
          control={
            <Checkbox
              checked={showcheckboxes}
              onChange={(e) => {
                setShowcheckboxes(!showcheckboxes)
              }}
              name={"Yes"}
            />
          }
          label={"Yes"}
        />
        {showcheckboxes && (
          <>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", pl: "40px" }}
            >
              <UText
                variant={"h6"}
                sxProp={{
                  display: "flex",
                  alignItems: "center",
                  pl: "5px",
                  color: "text.secondary",
                }}
              >
                {"R"}
              </UText>
              {generateCheckbox("R3", "3")}
              {generateCheckbox("R2", "2")}
              {generateCheckbox("R1", "1")}
              <Divider orientation="vertical" />
              {generateCheckbox("l1", "1")}
              {generateCheckbox("l2", "2")}
              {generateCheckbox("l3", "3")}
              <UText
                variant={"h6"}
                sxProp={{
                  display: "flex",
                  alignItems: "center",
                  pl: "5px",
                  color: "text.secondary",
                }}
              >
                {"L"}
              </UText>
            </FormGroup>
            <Divider orientation="horizontal" sx={{ ml: 5 }} />
          </>
        )}

        {isError && core?.validationMode === "ValidateAndShow" && (
          <UText
            variant={"caption"}
            sxProp={{ marginLeft: 1, color: "error.main" }}
          >
            {errors}
          </UText>
        )}
      </Box>
    </Box>
  )
}

export default withJsonFormsControlProps(UMultipleCheckbox)
