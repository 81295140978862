import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { gdsPlusSetup } from "@/gluelayer"

const initState = {
  Params: {
    ArchformAnt: {
      title: "Anterior",
      name: "ArchformAnt",
      unit: "mm",
      value: 0,
    },
    ArchformMid: {
      title: "Middle",
      name: "ArchformMid",
      unit: "",
      value: 0,
    },
    ArchformPost: {
      title: "Posterior",
      name: "ArchformPost",
      unit: "mm",
      value: 0,
    },
    ArchformExpLeft: {
      title: "Left Exp",
      name: "ArchformExpLeft",
      unit: "",
      value: 0,
    },
    ArchformExpRight: {
      title: "Right Exp",
      name: "ArchformExpRight",
      unit: "",
      value: 0,
    },
    JawMoveLR: {
      title: "Left/Right",
      name: "JawMoveLR",
      unit: "mm",
      value: 0,
    },
    JawMoveMD: {
      title: "A/P",
      name: "JawMoveMD",
      unit: "mm",
      value: 0,
    },
    JawMoveUD: {
      title: "Up/Down",
      name: "JawMoveUD",
      unit: "mm",
      value: 0,
    },
    OJ: {
      title: "Overjet",
      name: "OJ",
      unit: "mm",
      value: 0,
    },
    OB: {
      title: "Overbite",
      name: "OB",
      unit: "mm",
      value: 0,
    },
    LL: {
      title: "Spee Curve",
      name: "LL",
      unit: "mm",
      value: 0,
    },
    MidlineUpper: {
      title: "U Midline",
      name: "MidlineUpper",
      unit: "mm",
      value: 0,
    },
    MidlineLower: {
      title: "L Midline",
      name: "MidlineLower",
      unit: "mm",
      value: 0,
    },
    AntInclUpper: {
      title: "U RootT.",
      name: "AntInclUpper",
      unit: "\xB0",
      value: 0,
    },
    AntInclLower: {
      title: "L RootT.",
      name: "AntInclLower",
      unit: "\xB0",
      value: 0,
    },
    TMJMoveLimit: {
      title: "TMJ Limit",
      name: "TMJMoveLimit",
      unit: "mm",
      value: 0,
    },
    IPRToPrev: {
      title: "IPR",
      name: "IPRToPrev",
      unit: "mm",
      value: 0,
    },
    SpaceToPrev: {
      title: "Space",
      name: "SpaceToPrev",
      unit: "mm",
      value: 0,
    },
    IPRToNext: {
      title: "IPR",
      name: "IPRToNext",
      unit: "mm",
      value: 0,
    },
    SpaceToNext: {
      title: "Space",
      name: "SpaceToNext",
      unit: "mm",
      value: 0,
    },
  },
  selectedTooth: {
    ID: 0,
    disabled: true,
    isFixed: false,
    isFixedIprSpacePrev: false,
    isFixedIprSpaceNext: false,
  },
  TMJMoveDist: {
    leftDist: 0,
    rightDist: 0,
  },
  uiWeFlow: true,
  disabled: true,
  autoOccContact: false,
}

export const udSetupSlice = createSlice({
  name: "udsetup",
  initialState: initState,
  reducers: {
    setSelToothFixed(preState, action) {
      const { fixed } = action.payload
      const { selectedTooth } = preState
      selectedTooth.isFixed = fixed
    },
    setSelIprSpaceFixed(preState, action) {
      const { fixed, isToNext } = action.payload
      const { selectedTooth } = preState
      if (isToNext) selectedTooth.isFixedIprSpaceNext = fixed
      else selectedTooth.isFixedIprSpacePrev = fixed
    },
    updateSelTooth(state, action: PayloadAction<number>) {
      if (state.disabled) return
      const { selectedTooth } = state
      const id = action.payload
      selectedTooth.ID = id
      selectedTooth.isFixed = gdsPlusSetup
        .getAutoSetupModule()
        .GetToothFixed(id)
      selectedTooth.isFixedIprSpacePrev = gdsPlusSetup.GetToothIPREnable(false)
      selectedTooth.isFixedIprSpaceNext = gdsPlusSetup.GetToothIPREnable(true)
      const toothIPRSpaces = [
        state.Params.IPRToPrev,
        state.Params.SpaceToPrev,
        state.Params.IPRToNext,
        state.Params.SpaceToNext,
      ]
      toothIPRSpaces.map((param, idx) => {
        // update tooth ipr space
        param.value = gdsPlusSetup.GetParamValue(param.name)
      })
    },
    setDisable(state, action: PayloadAction<boolean>) {
      state.disabled = action.payload
    },
    updateAutoSetup(state) {
      if (state.disabled) return
      Object.keys(state.Params).forEach((key) => {
        state.Params[key].value = gdsPlusSetup.GetParamValue(key)
      })
      state.TMJMoveDist.leftDist = gdsPlusSetup.GetTMJMoveDist(true)
      state.TMJMoveDist.rightDist = gdsPlusSetup.GetTMJMoveDist(false)
      state.selectedTooth.isFixed = gdsPlusSetup
        .getAutoSetupModule()
        .GetToothFixed(state.selectedTooth.ID)
      state.selectedTooth.isFixedIprSpacePrev =
        gdsPlusSetup.GetToothIPREnable(false)
      state.selectedTooth.isFixedIprSpaceNext =
        gdsPlusSetup.GetToothIPREnable(true)
    },
    setParamValue(state, action) {
      const { name, value } = action.payload
      state.Params[name].value = value
    },
    setUIWeFlow(preState, action) {
      preState.uiWeFlow = action.payload.value
    },
    setAutoOccContact(state, action: PayloadAction<boolean>) {
      state.autoOccContact = action.payload
    },
  },
})

export const {
  updateAutoSetup,
  setDisable,
  updateSelTooth,
  setParamValue,
  setAutoOccContact,
} = udSetupSlice.actions
