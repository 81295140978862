import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { UText } from "../../../../components"
import {
  Box,
  Button,
  Container,
  Paper,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import {
  setClinicalData,
  setIsScansRendered,
} from "../../../../core/app/slices/clinical/clinicalSlice"
import { setIsRouteChangeBlocked } from "../../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { RootState } from "../../../../core/app/store"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"
import Photograph from "../../../Records/Photograph/Photograph"
import { IRecordRoutesProps } from "../../../Records/Records.module"
import Scans from "../../../Records/Scans/Scans"
import Xrays from "../../../Records/Xrays/Xray"

const RecordRoutes: FC<IRecordRoutesProps> = ({
  showError,
  xrayErrors,
  scanErrors,
  photographErrors,
  setScanErrors,
  setPhotographErrors,
  setXrayErrors,
  flagNextBtn,
  steps,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  isDragOpen,
  setIsDragOpen,
  isRefinement,
  isShowRecords,
  handleSkip,
}) => {
  const { t } = useTranslation("common")

  return (
    <Routes>
      <Route
        path={"scans"}
        element={
          <Scans
            setScanErrors={setScanErrors}
            flagClickNext={flagNextBtn}
            steps={steps}
            isRefinement={isRefinement}
            handleSkip={handleSkip}
            caseType={"hold_refinement"}
          />
        }
      />
      <Route
        path={"photograph"}
        element={
          <Photograph
            title={t("records.refinement.photograph.title")}
            description={t("records.refinement.photograph.description")}
            setPhotographErrors={setPhotographErrors}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            setIsDragOpen={setIsDragOpen}
            isDragOpen={isDragOpen}
            isRefinement={isRefinement}
            isShowRecords={isShowRecords}
          />
        }
      />
      <Route
        path={"xray"}
        element={
          <Xrays
            title={t("records.refinement.xray.title")}
            description={t("records.refinement.xray.description")}
            showError={showError}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            xrayErrors={xrayErrors}
            setXrayErrors={setXrayErrors}
            isRefinement={isRefinement}
            isShowRecords={isShowRecords}
          />
        }
      />
      <Route path={"*"} element={<Navigate to={"scans"} replace />} />
    </Routes>
  )
}

const CourceRefinement: FC = () => {
  const { patientId, caseId } = useParams()
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()

  const steps = [
    {
      id: "scans",
      label: "Scans",
      to: `/overview/patient/${patientId}/case/${caseId}/courserefinement/scans`,
    },
    {
      id: "photographs",
      label: "Photographs",
      to: `/overview/patient/${patientId}/case/${caseId}/courserefinement/photograph`,
    },
    {
      id: "x-rays",
      label: "X-Rays",
      to: `/overview/patient/${patientId}/case/${caseId}/courserefinement/xray`,
    },
  ]
  const { t } = useTranslation("common")

  const [scanErrors, setScanErrors] = useState<boolean>(false)
  const [photographErrors, setPhotographErrors] = useState<boolean>(false)
  const [xrayErrors, setXrayErrors] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [isDragEmpty, setIsDragEmpty] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [flagNextBtn, setFlagNextBtn] = useState()
  const [isDragOpen, setIsDragOpen] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [isShowRecords, setIsShowRecords] = useState(false)

  const [isShowNext, setShowNext] = useState(false)

  const { compositePhoto, individualPhotos, fileList } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const { isScansRendered } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const { pano, ceph } = useAppSelector((state: RootState) => state.xrayService)

  const handleNext = () => {
    if (activeStep === 2) {
      navigate(`/overview/patient/${patientId}/case/${caseId}/form/refinement`)
    } else if (isDragEmpty && isDragOpen) {
      setIsAlertOpen(true)
    } else if (individualPhotos.length > 0 && !isDragOpen) {
      setIsDragOpen(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      navigate(steps[activeStep + 1].to)
      setIsShowRecords(false)
    }
  }

  const handleBack = () => {
    setIsShowRecords(false)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    navigate(steps[activeStep - 1].to)
  }

  const isStepOptional = (step: number) => {
    if (scanErrors && step === 0) {
      return true
    }
    if (photographErrors && step === 1) {
      return true
    }
  }

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    navigate(steps[activeStep + 1].to)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const location = useLocation()
  useEffect(() => {
    setFlagNextBtn(undefined)
    location?.pathname.includes("/scans")
      ? setActiveStep(0)
      : location?.pathname.includes("/photograph")
      ? setActiveStep(1)
      : location?.pathname.includes("/xray")
      ? setActiveStep(2)
      : setActiveStep(0)
  }, [location])

  useEffect(() => {
    if (isScansRendered) return
    if (location.pathname.includes("/scans")) {
      dispatch(setIsScansRendered(true))
    } else if (!isScansRendered) {
      navigate(
        `/overview/patient/${patientId}/case/${caseId}/courserefinement/scans`,
      )
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      !compositePhoto.length &&
      !fileList.find(
        (file) => file.file_name.includes("composite") && file.is_refinement,
      ) &&
      !individualPhotos.length &&
      !fileList.find(
        (file) => file.file_name.includes("indiviual") && file.is_refinement,
      )
    ) {
      setPhotographErrors(true)
    } else {
      setPhotographErrors(false)
    }
    if (pano.length === 0) {
      setXrayErrors(true)
    } else {
      setXrayErrors(false)
    }

    if (
      (activeStep === 1 &&
        (compositePhoto.length || individualPhotos.length)) ||
      (activeStep === 2 && (pano.length || ceph.length))
    ) {
      setShowNext(true)
    } else {
      setShowNext(false)
    }
  }, [activeStep, compositePhoto, individualPhotos, pano, ceph])

  const btnAction = () => {
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    if ((activeStep === 1 || activeStep === 2) && !isShowNext) {
      setIsShowRecords(true)
    } else {
      if (activeStep === 0) {
        dispatch(setClinicalData({ isLoading: "pending" }))
        setFlagNextBtn(!flagNextBtn)
      } else {
        if (activeStep === 2) {
          photographErrors || scanErrors || xrayErrors
            ? setShowError(true)
            : handleNext()
        } else if (isStepOptional(activeStep)) handleSkip()
        else {
          setIsShowRecords(false)
          handleNext()
        }
      }
    }
  }

  return (
    <Container component="main" maxWidth={"lg"} sx={{ mb: 4 }}>
      <UText
        sxProp={{ display: "flex", justifyContent: "center" }}
        variant={"h4"}
      >
        {t("records.refinement.title")}
      </UText>
      <Paper
        elevation={0}
        sx={{
          my: { xs: 3, md: 6 },
          p: "40px",
          borderRadius: "16px",
          marginTop: "10px !important",
          margin: "0 auto",
          width: "max-content",
        }}
      >
        <RecordRoutes
          xrayErrors={xrayErrors}
          scanErrors={scanErrors}
          photographErrors={photographErrors}
          showError={showError}
          setScanErrors={setScanErrors}
          setPhotographErrors={setPhotographErrors}
          setXrayErrors={setXrayErrors}
          flagNextBtn={flagNextBtn}
          steps={steps}
          setIsDragEmpty={setIsDragEmpty}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          isDragOpen={isDragOpen}
          setIsDragOpen={setIsDragOpen}
          isRefinement={true}
          isShowRecords={isShowRecords}
          handleSkip={handleSkip}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
          }}
        >
          {activeStep !== 0 && (
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{ width: "96px", height: "36px" }}
            >
              <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
              {t("button.back")}
            </Button>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={btnAction}
            variant="contained"
            sx={{ width: "92px", height: "36px" }}
            // disabled={
            //   isPhotoUploading === "pending" ||
            //   xrayLoading === "pending" ||
            //   isDownloading === "pending"
            // }
          >
            {(activeStep === 1 || activeStep === 2) && !isShowNext
              ? t("button.skip")
              : t("button.next")}
            <ChevronRightIcon sx={{ marginLeft: "3px" }} fontSize={"small"} />
          </Button>
        </Box>
      </Paper>
    </Container>
  )
}

export default CourceRefinement
