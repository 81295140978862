import { wasmModule } from "../wasm/wasmModule";
import { Event } from "../../../utils/event";

export enum EInitialfinalEvent {
  OPENED = "opened",
  CLOSED = "closed",
}

class InitialFinalModule {
  private cacheZoomCallback: any;
  private _event: Event = new Event();

  get event() {
    return this._event;
  }

  switchInitialFinalDisplayModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.moduleManager.SwitchInitialFinalDisplayModule(isOpen);
    if (isOpen) {
      const iniFinalModule =
        wasmModule.moduleManager.GetInitialFinalDisplayModule();
      console.log("iniFinalModule :", iniFinalModule);
      iniFinalModule.SetMinScaleValue(0.25);
      iniFinalModule.SetMaxScaleValue(2);
      if (this.cacheZoomCallback) this.cacheZoomCallback(1);
      this._event.fire(EInitialfinalEvent.OPENED);
    } else {
      this._event.fire(EInitialfinalEvent.CLOSED);
    }
  }

  openOcclusalView() {
    const iniFinalModule =
      wasmModule.moduleManager.GetInitialFinalDisplayModule();
    if (iniFinalModule) iniFinalModule.OpenOcclusalView();
  }

  zoomByUI(val: number) {
    const iniFinalModule =
      wasmModule.moduleManager.GetInitialFinalDisplayModule();
    if (iniFinalModule) iniFinalModule.ZoomByUI(val);
  }

  setUpdateSliderCB(cb: (val: number) => void) {
    this.cacheZoomCallback = cb;
    if (!(window as any).initialFinal) {
      (window as any).initialFinal = {};
    }
    (window as any).initialFinal.initFinalZoomCallback = cb;
    const iniFinalModule =
      wasmModule.moduleManager.GetInitialFinalDisplayModule();
    if (iniFinalModule)
      iniFinalModule.SetUpdateSliderCB("initialFinal.initFinalZoomCallback");
  }
}
export const initialfinalModule = new InitialFinalModule();
