const passwordFormDef = [
  {
    formKey: "activation-form",
    passwordLabel: "formfields.choosePassword",
    passwordFieldName: "newPassword",
    confirmLabel: "formfields.confirmPassword",
    confirmFieldName: "confirmPassword",
    passwordSxProps: {
      mb: 1,
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
        color: "text.secondary",
      },
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error.MuiInputLabel-shrink": {
        color: "error.main",
      },
    },
    confirmSxProps: {
      mt: 2,
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
        color: "text.secondary",
      },
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error.MuiInputLabel-shrink": {
        color: "error.main",
      },
    },
    passwordRequired: "formfieldErrors.choosePasswordRequired",
    confirmRequired: "formfieldErrors.confirmPasswordrequired",
  },
  {
    formKey: "reset-form",
    passwordLabel: "formfields.newPassword",
    passwordFieldName: "newPassword",
    confirmLabel: "formfields.confirmNewPassword",
    confirmFieldName: "confirmNewPassword",
    passwordSxProps: {
      mb: 2,
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
        color: "text.secondary",
      },
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error.MuiInputLabel-shrink": {
        color: "error.main",
      },
    },
    confirmSxProps: {
      mt: 3,
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
        color: "text.secondary",
      },
      ".MuiFormLabel-root.MuiInputLabel-root.Mui-error.MuiInputLabel-shrink": {
        color: "error.main",
      },
    },
    passwordRequired: "formfieldErrors.newPasswordrequired",
    confirmRequired: "formfieldErrors.confirmNewPasswordrequired",
  },
]

export const getPasswordFormDefinition = (formKey: string) => {
  const filtered = passwordFormDef.filter(
    (formDef) => formKey === formDef.formKey,
  )
  if (filtered && filtered.length <= 0) return passwordFormDef[0]
  return filtered[0]
}
