import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box } from "../../../components/mui.components"
import { UButton, UModalPopup, UText } from "../../../ui-component"

export const NoUassistPopup: FC<{
  showPopup: boolean
  setShowPopup: (val: boolean) => void
  setIsPdfLoading?: (val: boolean) => void
}> = ({ showPopup, setShowPopup, setIsPdfLoading }) => {
  const { t } = useTranslation("common")
  return (
    <>
      <UModalPopup
        sxModalProps={{
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            px: 3,
            pb: 3,
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
        }}
        title={<UText variant={"h6"}>{t("uassist.noUassistTitle")}</UText>}
        content={
          <>
            <Box component={"div"}>
              <UText
                variant={"body1"}
                sxProp={{ display: "block", height: "32px" }}
              >
                {t("uassist.noUassist")}
              </UText>
            </Box>
          </>
        }
        isAlert={showPopup}
        btnList={[
          <UButton
            key={t("button.ok")}
            variant="contained"
            btnText={t("button.ok")}
            onClickHandler={() => {
              setShowPopup(false)
              setIsPdfLoading(false)
            }}
            sxProp={{
              height: 36,
            }}
          ></UButton>,
        ]}
      ></UModalPopup>
    </>
  )
}
