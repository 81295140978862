import { Box, Grid, Tooltip, Typography } from "@mui/material"
import { hasRevaData, noRevaData, singleData } from "./columns"
import { useEffect, useState } from "react"
import { attachmentModule, viewControlInTreatment } from "@/gluelayer"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
const { drawAuxullarties } = viewControlInTreatment

export const UDAuxAtt = () => {
  // reva permission
  const { isRevaPermisson } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  if (isRevaPermisson) {
    singleData.subData[0].data = hasRevaData
  } else {
    singleData.subData[0].data = noRevaData
  }

  useEffect(() => {
    setTimeout(() => {
      const auxTmp = [{ type: "Normal", elements: [] }] as any
      singleData.subData.map((item) => {
        item.data.map((i) => {
          const ele = document.getElementById(`${i.name}${i.key}`)
          auxTmp[0].elements.push([i.id, ele])
        })
      })

      const canvasRoot = document.getElementById("scrollWrap")
      drawAuxullarties(canvasRoot, auxTmp)
    }, 50)
  }, [])

  const onSelectAux = (id) => {
    console.log(id)
    attachmentModule.setAttachmentSelectLibID(id)
  }

  const AuxWindow = (name, AuxDatas) => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6"> {name} </Typography>
        </Grid>
        {AuxDatas.map((auxData) => {
          return (
            <Grid item xs={3}>
              <Tooltip
                title={auxData.name}
                onClick={() => {
                  onSelectAux(+auxData.id)
                }}
              >
                <Box
                  sx={{
                    width: "64px",
                    height: "64px",
                    border: 1,
                  }}
                  key={auxData.key}
                  id={`${auxData.name}${auxData.key}`}
                />
              </Tooltip>
            </Grid>
          )
        })}
      </>
    )
  }
  return (
    <Box
      id="scrollWrap"
      sx={{
        width: "404px",
        height: "100%",
        position: "relative",
        overflowY: "auto",
      }}
    >
      <Grid container alignItems={"center"} spacing={0.25}>
        {singleData.subData.map((subdata) => {
          return AuxWindow(subdata.name, subdata.data)
        })}
      </Grid>
    </Box>
  )
}
