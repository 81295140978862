import React from "react"
import { MaterialLayoutRenderer } from "@jsonforms/material-renderers"
import { withJsonFormsLayoutProps } from "@jsonforms/react"
import { Box } from "@mui/material"

import { UText } from "../../../ui-component"

const UGroupContainer = (props) => {
  const { uischema, schema, path, visible, renderers } = props

  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: "column",
    visible: visible,
    uischema: uischema,
    renderers: renderers,
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          padding: 1,
          alignItems: "flex-start",
          backgroundColor: uischema.isSummary
            ? "var(--grey-100, #F5F5F5)"
            : "var(--grey-200, #EEE)",
          mb: "4px",
          mt: uischema.top ? uischema.top : "",
        }}
      >
        <UText
          variant={"caption"}
          sxProp={{
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.17px",
            textTransform: "uppercase",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {uischema.label}
        </UText>
      </Box>
      <MaterialLayoutRenderer {...layoutProps} />
    </>
  )
}

export default withJsonFormsLayoutProps(UGroupContainer)
