import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { Transition } from "history"

import { useAppDispatch, useAppSelector } from "../core/app/hooks"
import {
  setIsbrowserBack,
  setIsRouteChangeBlocked,
} from "../core/app/slices/navigationPopup/navigationPopupSlice"
import { resetPrescriptionSchema } from "../core/app/slices/uassist/formSlice"
import { RootState } from "../core/app/store"

import { useBlocker } from "./useBlocker"
import { useMyNavigation } from "./useMyNavigation"

export const useCallbackPrompt = (when: boolean) => {
  const navigate = useMyNavigation()
  const location = useLocation()
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const dispatch = useAppDispatch()

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
    dispatch(setIsbrowserBack(false))
    setLastLocation(null)
  }, [])

  const { isRouteChangeBlocked, isbrowserBack } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      // in if condition we are checking next location and current location are equals or not
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true)
        setLastLocation(nextLocation)
        return false
      }
      return true
    },
    [confirmedNavigation, location],
  )

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
    setTimeout(() => {
      dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    }, 0)
  }, [])

  useEffect(() => {
    if (confirmedNavigation) {
      if (isbrowserBack) {
        navigate(-1)
        dispatch(setIsbrowserBack(false))
      } else if (lastLocation) {
        navigate(lastLocation.location?.pathname)
      }
      setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, lastLocation])

  useBlocker(handleBlockedNavigation, when)

  return [showPrompt, confirmNavigation, cancelNavigation]
}
