import { UDTabs } from "../../custom/UDTabs"
import { UDAuxAtt } from "./UDAuxAtt"
import { UDAuxProtocol } from "./UDAuxProtocol"
import { RightPage } from "../../right/RightPage"
import { Box } from "@mui/material"
import { UDAuxBiteRamp } from "./UDAuxBiteRamp"
import { useEffect } from "react"
import { wasmModule, attachmentModule } from "@/gluelayer"

export const UDAux = (props) => {
  useEffect(() => {
    if (wasmModule.isInit) {
      attachmentModule.openAttachmentModule(true)
      attachmentModule.setAttachmentSelectAbleType("NormalAttachment")
      attachmentModule.setAttachBtnDown(true)
    }

    return () => {
      attachmentModule.setAttachmentSelectAbleType("AttachmentAndTeeth")
      attachmentModule.setAttachBtnDown(false)
    }
  }, [])
  return (
    <RightPage
      title="uDesign Auxillary"
      onClose={props.onClose}
      content={
        <UDTabs labels={["Protocol", "Attachment", "BiteRamp"]}>
          <UDAuxProtocol />
          <UDAuxAtt />
          <UDAuxBiteRamp />
        </UDTabs>
      }
      actions={<Box sx={{ height: "20px" }}></Box>}
    />
  )
}
