import { FC } from "react"
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Autocomplete, TextField } from "@mui/material"
import { SxProps } from "@mui/system"

export interface IAddressAutoCompleteProps {
  suggestedAddressData: any
  handleInputChange: (event: any, newInputValue: any) => void
  handleChange: (event: any, value: any) => void
  inputValue: string
  fieldName: string
  rules: Record<string, any>
  errors: FieldErrors<FieldValues>
  control: Control<FieldValues>
  formSxprops: SxProps
  paymentValue?: string
}

export const AddressAutocomplete: FC<IAddressAutoCompleteProps> = ({
  suggestedAddressData,
  handleInputChange,
  handleChange,
  inputValue,
  fieldName,
  rules,
  errors,
  control,
  formSxprops,
  paymentValue,
}) => {
  const { t } = useTranslation("common")

  return (
    <Controller
      name={fieldName}
      errors={errors}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          sx={{ ...formSxprops }}
          freeSolo
          disableClearable={true}
          options={suggestedAddressData.map((option) => option.street_address)}
          onInputChange={handleInputChange}
          value={inputValue || ""}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("userProfile.addressOnFile.streetaddress")}
              placeholder={t("userProfile.addressOnFile.streetaddress")}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      )}
    />
  )
}
