import { Paper, Box, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import { lightGreen } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"

export const LeftPage = (props: any) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        left: 84,
        top: 104,
        minWidth: "300px",
        maxWidth: "536px",
        borderRadius: "8px",
        backgroundColor: theme.transBkColor?.light,
        maxHeight: `calc(100% - 114px)`,
        position: "absolute",
        boxShadow: 6,
        ...props.sx,
      }}
      direction="column"
    >
      <Stack
        sx={{
          p: "24px",
          height: "48px",
          width: "100%",
          ...props.headersx,
        }}
        alignItems="center"
        justifyContent="space-between"
        direction={"row"}
      >
        <Typography variant="h6" sx={{ fontSize: 24 }}>
          {props.title}
        </Typography>
        <IconButton
          sx={{
            width: 30,
            height: 30,
          }}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box
        sx={{
          p: "24px",
          backgroundColor: "background.paper",
          overflow: "auto",
          ...props.contentsx,
        }}
      >
        {props.content}
      </Box>
      {props.actions ? (
        <Box sx={{ width: "100%" }}> {props.actions}</Box>
      ) : (
        <Box sx={{ width: "100%", height: "20px" }}></Box>
      )}
      {props.children}
    </Stack>
  )
}
