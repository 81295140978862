export const showCurrentBundleBanner = (bundles) => {
  if (bundles.length === 1) return true
  return false
}

export const showBundleCompletedBanner = (bundle) => {
  return (
    bundle?.description &&
    bundle?.description?.retainer?.consumed ===
      bundle?.description?.retainer?.included
  )
}

export const getPricingDetailLink = (countryCode) => {
  switch (countryCode) {
    case "CA":
      return "https://hub.ulabsystems.ca/pricing"
    case "AU":
      return "https://hub.ulabsystems.net.au/pricing"
    case "NZ":
      return "https://hub.ulabsystems.net.nz/pricing"
    default:
      return "https://hub.ulabsystems.net/pricing"
  }
}

export const ITEM_COSTS = {
  UASSIST_SERVICE: 85,
}
