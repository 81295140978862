import { useEffect, useState,useMemo,memo } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { filter, find, isEmpty } from "lodash"
import { LeftPage } from "./LeftPage"
import { PhotosAndXRays } from "./PhotosAndXRays"
import LoadingButton from "@mui/lab/LoadingButton"
import { RootState } from "@/core/app/store"
import { getAccessToken } from "@/core/config/okta.config"
import {postMessageToWebsocket} from "./utils"



export const UDPhotos = (props)=>{
  const [isAILoading,setIsAIloading] = useState(false);
  const [isAI, setisAI] = useState<{
    open: boolean
    upload?: { file: File; filename: string; type: string }[]
    params?: { category: string; filename: string }[]
  }>({
    open: false,
    upload: [],
    params: [],
  })
  const [jsonData,setJsonData] = useState()

  const { patientId, caseId } = useParams()
  const {
    user: { current_orgId },
  } = useAppSelector((state: RootState) => state.userService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [isReadonly] = useState(caseDetail.caseDisposition==="SUBMITTED_TO_UASSIST"?true:false)

  return <LeftPage
  id="leftpage"
  sx={{
    width: 536,
    Height: "auto",
    zIndex:10,
  }}
  title="Photos and X-rays"
  onClose={props.onClose}
  headersx={{
    height: 72,
  }}
  contentsx={{ padding: "8px 32px" }}
  content={
    <PhotosAndXRays
    jsonData={jsonData}
    setisAI={setisAI}
    isReadOnly={isReadonly}
    />
  }
  actions={
      <LoadingButton
        loading={isAILoading}
        disabled={isReadonly || !isAI.open}
        variant="contained"
        sx={{ margin: 2 }}
        onClick={async () => {
          const { upload, params } = isAI
          const imageList = params.reduce((obj, fileinfo)=>{
              obj.push(fileinfo.filename);
              return obj
            },[]);
          const paramsForAIReq = { 
            imageNameList:imageList, 
            orgId:current_orgId,
            patientId,caseId}
          setIsAIloading(true)
          const jsonData = await postMessageToWebsocket(paramsForAIReq);
          setIsAIloading(false);
          if (jsonData) {
            setJsonData(jsonData as any)
          }
        }}
      >
        {"AI"}
      </LoadingButton>
  }
  
  >
  </LeftPage>
}