import { useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import EditFilled from "@mui/icons-material/Edit"
import { Launch } from "@mui/icons-material"
import ExpandMoreFilled from "@mui/icons-material/ExpandMore"
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  FormControl,
  Select,
} from "@mui/material"
import { UText } from "../../ui-component"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import EditPatientInfoForm from "@/modules/Patient/Overview/components/EditPatientInfoForm"
import CaseStatusAction from "@/modules/Patient/Overview/components/CaseStatusAction"
import {
  downloadPhotographs1_1,
  downloadPhotoJSON,
  fetchFilesList,
} from "@/core/app/slices/records/photograph/photographThunkApi"
import { FileItem } from "@/core/app/slices/records/photograph/photograph.type"

const EditUser = ({ open, handleClose, userName }) => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: "#E4E8EA" }}>
        <Typography variant="h6">{userName}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <EditPatientInfoForm
          patientData={patientData}
          handleClose={handleClose}
        ></EditPatientInfoForm>
      </DialogContent>
      <DialogActions sx={{ background: "#E4E8EA" }}>
        <Button onClick={handleClose} size="large">
          CANCEL
        </Button>
        <Button variant="contained" type="submit" form="userinfo" size="large">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const typeNameToShowName = {
  composite: "Composite",
  front_smile: "Smile",
  front_normal: "Front",
  lateral_photo: "Profile",
  upper: "Upper Occlusal",
  lower: "Lower Occlusal",
  front: "Anterior",
  left: "Left",
  right: "Right",
  x_lateral: "Ceph",
  x_panorama: "Pano",
}

let PhotoThumbMap: Record<string, string> = {}
let ImageUrlMap: Record<string, string> = {}
export const PatientNameID = ({
  firstName,
  lastName,
  caseId,
  patientId,
  planName = "Initial Plan",
  creatDate = "1/8/24",
}) => {
  const [hoverAvatar, sethoverAvatar] = useState(false)
  const [openEditUser, setopenEditUser] = useState(false)
  const dispatch = useAppDispatch()

  const [frontPhoto, setFrontPhoto] = useState()
  const [showHistoryList, setShowHistoryList] = useState(false)

  useEffect(() => {
    downLoadPhotoJson()

    return () => {
      if (frontPhoto) URL.revokeObjectURL(frontPhoto)
    }
  }, [])

  const downLoadPhotoJson = async () => {
    const fileList = await dispatch(fetchFilesList({ patientId, caseId }))
    const listData = fileList.payload.data
    if (listData.response_code !== 200) {
      console.warn("Fetch flle list false::", listData)
      return
    }

    const typeFileInfo = (listData.result as Array<FileItem>).filter((item) => {
      return (
        item.file_name === "type.json" &&
        item.attachment_type === "photopositiontype"
      )
    })

    console.log("🚀 ~ typeFileInfo ~ typeFileInfo:", typeFileInfo)
    if (typeFileInfo && typeFileInfo.length > 0) {
      const ret = await dispatch(
        downloadPhotoJSON({
          caseId,
          patientId,
        }),
      )
      const retData = ret.payload
      if (retData.data) {
        const typeJson: any = retData.data
        console.log("🚀 ~ downLoadPhoto ~ typeJson:", typeJson)
        // PhotoThumbMap = typeJson.thumbs ? {...typeJson.thumbs} : {};
        downloadFrontPhoto(typeJson)
        // setIsNoData(false);
      }
    }
  }
  const downloadFrontPhoto = async (photoJson) => {
    for (const key in photoJson) {
      if (photoJson[key] === "front_normal") {
        console.log("front photo name:  " + key)

        const photos = await dispatch(
          downloadPhotographs1_1({ patientId, caseId, fileNames: [key] }),
        )
        // console.log("🚀 ~ downloadFrontPhoto ~ photos:", photos)

        const frontPhoto = photos.payload[0]
        console.log("🚀 ~ downloadFrontPhoto ~ frontPhoto:", frontPhoto)
        if (frontPhoto) {
          setFrontPhoto(frontPhoto)
        }
        break // 如果只需要第一个找到的，可以加上这行，否则会输出所有满足条件的键名
      }
    }
  }

  const testHistoryList = [
    { id: caseId, name: planName, date: creatDate },
    { id: "1214777", name: "Refinement 1", date: "1/8/24" },
    { id: "1214778", name: "Retainer 1", date: "1/8/24" },
  ] 
  
  const HistoryItem = ({ id, name, date}) => {
    return(
      <Box sx={{ display: "flex"}}>
        <Typography 
          variant="caption"
          sx={{
            color: id === caseId ? "#9C9C9C": "#000",
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "400",
            letterSpacing: "0.46px",
            marginTop: "7px",
            marginBottom: "7px",
            marginLeft: "15px",
            display: "inline-block",
            width: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            console.log("click history item", id)
          }}
        >
          {` #` + id + " | " + name + " - " + date}
        </Typography>
        <Launch 
          sx={{ 
            display: "inline-block", 
            position: "absolute", 
            right: "15px",
            marginTop: "9px", 
            width: 20, 
            height: 20,
            color: id === caseId ? "#9C9C9C": "#000",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        ></Launch>
      </Box>
    )
  }
  const HistoryListForm = () => {
    return(
      <FormControl 
        sx={{ 
          width: "331px", 
          position: "absolute", 
          top: "calc(100% + 8px)", 
          left: 80, 
          zIndex: 1000, 
          background: "#FFF", 
          paddingBottom: 1,
          paddingTop: 1,
          borderRadius: "4px",
          boxShadow: 5
        }}>
        {testHistoryList.map((item, index) => {
          return <HistoryItem key={"history-item-"+index} id={item.id} name={item.name} date={item.date} />
        })}
        <Box sx={{ display: "flex", background: "#F5F5F5" }}>
          <Typography 
            variant="caption"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: "400",
              letterSpacing: "0.46px",
              marginTop: "7px",
              marginBottom: "5px",
              marginLeft: "15px",
              display: "inline-block",
              width: "auto",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {"Edit Plan Name"}
          </Typography>
        </Box>
      </FormControl>
    )
  }
  return (
    <Box sx={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
      <Avatar
        variant="circular"
        sx={{
          bgcolor: "#FFF",
          width: 72,
          height: 72,
          fontSize: "32px",
          color: "rgba(0, 0, 0, 0.60)",
          fontweight: "500",
          position: "relative",
          backgroundImage: frontPhoto
            ? `url(${URL.createObjectURL(frontPhoto)})`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onMouseEnter={() => {
          sethoverAvatar(true)
        }}
        onMouseLeave={() => {
          sethoverAvatar(false)
        }}
      >
        {!frontPhoto &&
          firstName.substring(0, 1).toUpperCase() +
            lastName.substring(0, 1).toUpperCase()}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={hoverAvatar}
          onClick={() => {
            setopenEditUser(true)
          }}
        >
          <IconButton size="medium">
            <EditFilled sx={{ color: "#FFF" }}></EditFilled>
          </IconButton>
        </Backdrop>
      </Avatar>

      <Box
        sx={{
          marginLeft: "12px",
          height: "54px",
        }}
      >
        <Tooltip
          arrow
          title="Notes: Patient referred by Dr. Newey. This is a long tooltip to show what it would look like if the user enters a lot of text regarding the patient"
          placement="right"
          followCursor
        >
          <Typography
            variant="caption"
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "0.46px",
              textTransform: "uppercase",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {firstName + " " + lastName}
          </Typography>
        </Tooltip>

        <Box>
          <Typography
            variant="caption"
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "0.46px",
              textTransform: "uppercase",
            }}
          >
            {planName + ` #` + caseId + " - " + creatDate}
          </Typography>
          <IconButton 
            aria-label="more" 
            sx={{ 
              width: 28, 
              height: 28, 
              transform: showHistoryList? "scaleY(-1)": "scaleY(1)"
            }}
            onClick={() => {setShowHistoryList(!showHistoryList)}}
          >
            <ExpandMoreFilled sx={{ color: "rgba(255, 255, 255, 1)" }} />
          </IconButton>
          {/* {showHistoryList && <HistoryListForm /> } */}
        </Box>
      </Box>
      <EditUser
        open={openEditUser}
        handleClose={() => setopenEditUser(false)}
        userName={firstName + " " + lastName}
      ></EditUser>
    </Box>
  )
}
