import { useState, useEffect } from "react"
import { Typography, Grid, Button } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { OpenCaseById } from "./OpenCaseById"
import { getAllCaseList } from "@/core/app/slices/v2/v2.service"
import { useNavigate } from "react-router-dom"

interface ICaseInfo {
  patient_name: string
  org_id: string
  patient_id: number
  case_id: string
  create_time: string
  update_time: string
  status: string
}

export default function WeCaseList() {
  const navigate = useNavigate()
  const [curPage, setCurPage] = useState(0)
  const [rows, setRows] = useState<ICaseInfo[]>([])
  const getTimeStr = (timestamp_seconds: number) => {
    const time = new Date(timestamp_seconds * 1000)
    return time.toDateString()
  }
  const onClickRow = (row) => {
    console.log(row)
    navigate(`/org/${row.org_id}/overview/patient/${row.patient_id}`)
  }
  useEffect(() => {
    getAllCaseList(curPage).then((ret) => {
      const caseArray = ret.data.detail
      let newRows: ICaseInfo[] = []
      caseArray.map((value, index) => {
        const caseInfo = {
          patient_name:
            value.patient.first_name + " " + value.patient.last_name,
          org_id: value.create_org_id,
          patient_id: value.patient.id,
          case_id: value.case_id,
          create_time: getTimeStr(value.create_date),
          update_time: getTimeStr(value.update_date),
          status: value.case_disposition,
        }
        newRows.push(caseInfo)
      })
      setRows(newRows)
    })
  }, [curPage])

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h4">WeDesign Patient/Case List</Typography>
      </Grid>
      <Grid item xs={8}>
        <OpenCaseById />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Patient Name</TableCell>
                <TableCell align="right">Org Id</TableCell>
                <TableCell align="right">Patient Id</TableCell>
                <TableCell align="right">Case Id</TableCell>
                <TableCell align="right">Create Time</TableCell>
                <TableCell align="right">Update Time</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  onClick={() => onClickRow(row)}
                  key={row.case_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.patient_name}
                  </TableCell>
                  <TableCell align="right">{row.org_id}</TableCell>
                  <TableCell align="right">{row.patient_id}</TableCell>
                  <TableCell align="right">{row.case_id}</TableCell>
                  <TableCell align="right">{row.create_time}</TableCell>
                  <TableCell align="right">{row.update_time}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3}>
        CurPage: {curPage}
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={() => {
            setCurPage(Math.max(curPage - 1, 0))
          }}
        >
          Prev
        </Button>
      </Grid>
      <Button
        onClick={() => {
          setCurPage(curPage + 1)
        }}
      >
        Next
      </Button>
      <Grid item xs={3}></Grid>
    </Grid>
  )
}
