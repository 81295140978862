import * as React from "react"
import { useState } from "react"
import { Button, Grid, TextField, Typography } from "@mui/material"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import { FormInput } from "../../components"

import { UFlowImportSTL } from "./UFlowPresetup"

export const UFlowNewPatient = (props) => {
  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const onCancel = () => {
    props.onCancle()
  }
  const onCreate = () => {
    props.onCreate(firstname, lastname)
  }
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
    >
      <DialogTitle id="dlg-title">New Patient</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <TextField
              required
              id="firstname"
              label="First Name"
              defaultValue=""
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="lastname"
              label="Last Name"
              defaultValue=""
              onChange={(e) => {
                setLastName(e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onCreate(firstname, lastname)
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
