import { Box, styled } from "../../mui.components"

const BoxWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  boxShadow:
    "0px 0px 1px rgba(0, 0, 0, 0.31), 0px 8px 12px rgba(0, 0, 0, 0.15)",
  border: `1px solid #FFFFFF`,
  borderRadius: "32px",
  width: "500px",
  height: "auto",
  backgroundColor: "#FFFFFF",
}))

export default BoxWrapper
