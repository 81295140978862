import { createAsyncThunk } from "@reduxjs/toolkit"

import { DateRange } from "../../../model/billing"
import store from "../../store"

import {
  fetchInvoiceHistorybyOrg,
  getOutstandingBalance,
} from "./billing.service"

export const getOutstandingBalancebyOrg = createAsyncThunk(
  "billingService/getOutstandingBalancebyOrg",
  async (_, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      const result = await getOutstandingBalance(orgId)
      return result
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchInvoiceHistory = createAsyncThunk(
  "billingService/fetchInvoiceHistory",
  async (payload: DateRange, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      const result = await fetchInvoiceHistorybyOrg(orgId, payload)
      return result
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
