import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"

export const useMyNavigation = () => {
  const navigate = useNavigate()
  const { user } = useAppSelector((state: RootState) => state.userService)
  const location = useLocation()

  const goToPage = useCallback(
    (pageUrl, options = {}, orgId?: string) => {
      let path = pageUrl
      if (
        path === -1 ||
        path.includes("auth") ||
        (path.includes("settings") && !path.includes("overview")) ||
        path.includes("help") ||
        path.includes("organization")
      ) {
        navigate(path)
        return
      }
      if (user.current_orgId && user.isAllowToViewAllOrg) {
        if (!pageUrl.includes("org") && pageUrl[0] === "/") {
          path = `/org/${orgId || user.current_orgId}${pageUrl}`
        }
        options = { ...options, replace: false }
      }
      navigate(path, options)
    },
    [user.current_orgId],
  )
  //   const push = useCallback((to, state) => navigate(to, { state }), [])

  //   const replace = useCallback(
  //     (to, state) => navigate(to, { replace: true, state }),
  //     [],
  //   )

  //   const go = useCallback((delta) => navigate(`org/12/${delta}`), [])

  //   const back = useCallback(() => navigate(-1), [])

  //   const forward = useCallback(() => navigate(1), [])

  //   return {
  //     back,
  //     forward,
  //     go,
  //     push,
  //     replace,
  //   }

  return goToPage
}
// https://stackoverflow.com/questions/74094052/how-to-make-a-wrapper-function-for-navigate-in-react-router-v6
//https://codesandbox.io/p/sandbox/react-router-dom-dynamic-basename-lstx4?file=%2Findex.js
