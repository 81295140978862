import { useEffect, useState } from "react"
import { Box, Button, Stack, TextField } from "@mui/material"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { caseManagement } from "@/gluelayer"

import { setIsCasePreview } from "@/UDTreat/udTreatSlice"
import {
  fetchFileListWeDesign,
  fetchFileZipsWeDesign,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { wait } from "@testing-library/user-event/dist/cjs/utils/index.js"
import {
  getAllCaseList,
  getCaseByIdOnly,
  getPatientByIdOnly,
} from "@/core/app/slices/v2/v2.service"
import { useNavigate } from "react-router-dom"

export const OpenCaseById = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [idString, setIdString] = useState("")
  const [pidString, setPidString] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const { ucloud1_1 } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const openCase = (caseData: any) => {
    const org_id = caseData.create_org_id
    const patient_id = caseData.patient.id
    const case_id = caseData.case_id
    console.log("To open:", org_id, patient_id, case_id)
    navigate(`/org/${org_id}/overview/patient/${patient_id}`)
  }

  const onOpenCase = () => {
    let patientid = Number(pidString)
    let caseid = Number(idString)
    if (!caseid && !patientid) {
      setErrMsg("Please input patient ID or case ID.")
      return
    }
    setErrMsg("")
    if (pidString != "") {
      getPatientByIdOnly(patientid).then((ret) => {
        if (ret.data.summary.total_case != 1) {
          setErrMsg("Error: no patient found!")
          return
        }
        openCase(ret.data.detail[0])
      })
    } else if (idString != "") {
      getCaseByIdOnly(caseid).then((ret) => {
        if (ret.data.summary.total_case != 1) {
          setErrMsg("Error: no case found!")
          return
        }
        openCase(ret.data.detail[0])
      })
    }
    setPidString("")
    setIdString("")
  }
  return (
    <Box justifyContent="center" alignItems={"center"}>
      <TextField
        id="patient_id"
        label="Patient Id"
        type="number"
        size="small"
        value={pidString}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          setPidString(e.target.value)
        }}
        variant="filled"
      />
      Or
      <TextField
        id="case_id"
        label="Case Id"
        type="number"
        size="small"
        value={idString}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          setIdString(e.target.value)
        }}
        variant="filled"
      />
      <Button variant="contained" onClick={onOpenCase}>
        Open Patient Overview!
      </Button>
      <Box> {errMsg}</Box>
    </Box>
  )
}
