import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Chip } from "@mui/material"
import { Box } from "@mui/system"

import { useAppSelector } from "../../../../core/app/hooks"
import { RootState } from "../../../../core/app/store"
import { UText } from "../../../../ui-component"

const RejectedOverview: FC = () => {
  const { t } = useTranslation()
  const { bannerData } = useAppSelector(
    (state: RootState) => state.bannerService,
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <UText variant={"h6"}>{t("overview.rejectedCase.title")}</UText>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Chip
            size={"medium"}
            sx={{
              color: "white",
              ".MuiChip-label": {
                p: "3px 6px",
              },
            }}
            variant={"filled"}
            color={"warning"}
            label={t("overview.rejectedCase.rejectedChip")}
          />
        </Box>
      </Box>
      {bannerData && bannerData?.content && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "400px",
            alignItems: "center",
          }}
        >
          <UText color={"text.secondary"} variant={"body2"}>
            {t("overview.rejectedCase.message.title")}
          </UText>
          <UText
            sxProp={{
              p: 3,
              backgroundColor: "#F4F4F4",
              borderRadius: 4,
            }}
            variant={"body2"}
          >
            {bannerData?.content}
          </UText>
        </Box>
      )}
    </Box>
  )
}

export default RejectedOverview
