import { Dialog, styled } from "../../mui.components"

export const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "fit-content",
    minWidth: "444px",
    minHeight: "200px",
    overflow: "hidden",
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  "& .MuiDialogContent-root": {
    padding: 0,
    letterSpacing: "-0.5px",
    fontWeight: "normal",
  },
}))
