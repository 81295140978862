export const canAll =
  (state: string[]) =>
  (permissionsKey: string | string[]): boolean => {
    const arrayPermissions = Array.isArray(permissionsKey)
      ? permissionsKey
      : permissionsKey.split("|")
    if (state && state.length > 0) {
      return arrayPermissions.every((permission) =>
        state.includes(permission as never),
      )
    }
    return false
  }

export const canAny =
  (state: string[]) =>
  (permissionsKey: string | string[]): boolean => {
    const arrayPermissions = Array.isArray(permissionsKey)
      ? permissionsKey
      : permissionsKey.split("|")
    if (state && state.length > 0) {
      return arrayPermissions.some((permission) =>
        state.includes(permission as never),
      )
    }
    return false
  }

export const canNotHaveAll =
  (state: string[]) =>
  (permissionsKey: string | string[]): boolean => {
    const arrayPermissions = Array.isArray(permissionsKey)
      ? permissionsKey
      : permissionsKey.split("|")
    if (state && state.length > 0) {
      return arrayPermissions.every(
        (permission) => !state.includes(permission as never),
      )
    }
    return false
  }

export const canNotHaveAny =
  (state: string[]) =>
  (permissionsKey: string | string[]): boolean => {
    const arrayPermissions = Array.isArray(permissionsKey)
      ? permissionsKey
      : permissionsKey.split("|")
    if (state && state.length > 0) {
      return arrayPermissions.some(
        (permission) => !state.includes(permission as never),
      )
    }
    return false
  }
