import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface INavigationPopup {
  isRouteChangeBlocked: boolean
  isbrowserBack: boolean
}

const initialState: INavigationPopup = {
  isRouteChangeBlocked: false,
  isbrowserBack: false,
}

export const navigationPopupSlice = createSlice({
  name: "navigationPopup",
  initialState,
  reducers: {
    setIsRouteChangeBlocked: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      }
    },
    setIsbrowserBack: (state, action) => {
      state.isbrowserBack = action.payload
    },
  },
})

export const { setIsRouteChangeBlocked, setIsbrowserBack } =
  navigationPopupSlice.actions
export default navigationPopupSlice.reducer
