import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IAlertState {
  alertMsg: string
  isError: boolean
  btnText: string
  btnAction: () => void
}

const initialState: IAlertState = {
  alertMsg: "",
  isError: false,
  btnText: "",
  btnAction: undefined,
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (
      state,
      action: PayloadAction<{
        message: string
        isError?: boolean
        btnText?: string
        btnAction?: () => void
      }>,
    ) => {
      state.alertMsg = action.payload.message
      state.isError = action.payload.isError || false
      state.btnText = action.payload.btnText || ""
      state.btnAction = action.payload.btnAction
    },
  },
})

export const { setAlert } = alertSlice.actions
export default alertSlice.reducer
