import { IAddress, ICompanyProfile } from "../app/slices/user/user.type"

import Deserialize from "./interface/Deserialize"
import { IUser } from "./interface"

export default class User implements IUser, Deserialize {
  id = ""
  firstName = ""
  lastName = ""
  address = ""
  city = ""
  state = ""
  zipCode = ""
  email = ""
  phoneNumber = ""

  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.firstName
      this.lastName = input.lastName
      this.address = input.address
      this.city = input.city
      this.state = input.state
      this.zipCode = input.zipCode
      this.email = input.email
      this.phoneNumber = input.phoneNumber
    }
    return this
  }
}

export class CompanyProfile implements ICompanyProfile, Deserialize {
  id: 0
  name: ""
  orgType: ""
  orgStatus: ""
  orgCreatedOn: ""
  activatedOn: ""
  licenseValidDate: ""
  billingMethod: ""
  invoiceStartDate: ""
  address: IAddress[]
  accountOwner: ""
  clinicalEdSpec: ""

  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.name = input?.name
      this.orgType = input?.org_type
      this.orgStatus = input?.org_status
      this.orgCreatedOn = input?.org_created_on
      this.activatedOn = input?.activated_on
      this.invoiceStartDate = input?.invoice_start_date
      this.licenseValidDate = input?.licensevaliddate
      this.billingMethod = input?.billing_method
      this.accountOwner = input?.account_owner
      this.clinicalEdSpec = input?.clinical_ed_spec

      const updatedAddresses: IAddress[] = []
      input?.address?.map((eachAddress) => {
        const address: IAddress = {
          line_1: eachAddress?.line_1,
          line_2: eachAddress?.line_2,
          city: eachAddress?.city,
          state: eachAddress?.state,
          regionName: eachAddress?.region_name,
          countryName: eachAddress?.country_name,
          zipCode: eachAddress?.zip_code,
          email: eachAddress?.email,
          phone: eachAddress?.phone,
          type: eachAddress?.type,
        }
        updatedAddresses.push(address)
      })
      this.address = updatedAddresses
    }
    return this
  }
}
