import more40thumbDown from "../../assets/bottomToolBar/stage/more40selectDown.svg"
import more40thumbDownDrag from "../../assets/bottomToolBar/stage/more40selectDownDrag.svg"
import more40thumbDownHover from "../../assets/bottomToolBar/stage/more40selectDownHover.svg"
import more40thumbUp from "../../assets/bottomToolBar/stage/more40selectUp.svg"
import more40thumbUpDrag from "../../assets/bottomToolBar/stage/more40selectUpDrag.svg"
import more40thumbUpHover from "../../assets/bottomToolBar/stage/more40selectUpHover.svg"
import less40thumbDown from "../../assets/bottomToolBar/stage/selectedDown.svg"
import less40thumbDownDrag from "../../assets/bottomToolBar/stage/selectedDownDrag.svg"
import less40thumbDownHover from "../../assets/bottomToolBar/stage/selectedDownHover.svg"
import less40thumbUp from "../../assets/bottomToolBar/stage/selectedUp.svg"
import less40thumbUpDrag from "../../assets/bottomToolBar/stage/selectedUpDrag.svg"
import less40thumbUpHover from "../../assets/bottomToolBar/stage/selectedUpHover.svg"

export const stageConfig = {
  over40: {
    hoverLeft: "calc(50% - 6px)",
    normalCirLeft: "calc(50% - 2px)",
    normalCirTop: "6px",
    pointWidth: "4px",
    hoverWidth: "12px",
    hoverWidthNum: 12,
    everyPointNum: 10,
    thumbUp: more40thumbUp,
    thumbDown: more40thumbDown,
    thumbUpHover: more40thumbUpHover,
    thumbDownHover: more40thumbDownHover,
    thumbUpDrag: more40thumbUpDrag,
    thumbDownDrag: more40thumbDownDrag,
    thumbMarkMarginLeft: "-1px",
  },
  less40: {
    hoverLeft: "calc(50% - 10px)",
    normalCirLeft: "calc(50% - 4px)",
    normalCirTop: "4px",
    pointWidth: "8px",
    hoverWidth: "20px",
    hoverWidthNum: 20,
    everyPointNum: 5,
    thumbUp: less40thumbUp,
    thumbDown: less40thumbDown,
    thumbUpHover: less40thumbUpHover,
    thumbDownHover: less40thumbDownHover,
    thumbUpDrag: less40thumbUpDrag,
    thumbDownDrag: less40thumbDownDrag,
    thumbMarkMarginLeft: "-3px",
  },
}

export const stageBgColor = [
  "#546E7A",
  "#00838F",
  "#7E57C2",
  "#C2185B",
  "#BF360C",
  "#AFB42B",
  "#388E3C",
]
