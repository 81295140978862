import Deserialize from "./interface/Deserialize"
import { IShippingAddressTo } from "./interface/IShippingAddress"

export class ShippingAddressTo implements IShippingAddressTo, Deserialize {
  business_name: ""
  city: ""
  country_name: ""
  id: undefined
  line_1: ""
  line_2: ""
  region_name: ""
  zip_code: ""
  phone: ""
  Deserialize(input: any): this {
    if (input) {
      if (input.org_name) {
        this.business_name = input.org_name
      }
      if (input.shipping_address) {
        this.id = input.shipping_address.id
        this.city = input.shipping_address.city
        this.country_name = input.shipping_address.country_name
        this.line_1 = input.shipping_address.line_1
        this.line_2 = input.shipping_address.line_2
        this.region_name = input.shipping_address.region_name
        this.zip_code = input.shipping_address.zip_code
        this.phone = input.shipping_address.phone
      }
      if (input.shipAddress) {
        this.id = input.shipAddress.id
        this.city = input.shipAddress.city
        if (input.shipAddress.country) {
          this.country_name = input.shipAddress.country.code_1
        }
        this.line_1 = input.shipAddress.line_1
        this.line_2 = input.shipAddress.line_2
        if (input.shipAddress.state) {
          this.region_name = input.shipAddress.state.short_name
        }
        this.zip_code = input.shipAddress.zip_code
        this.phone = input.shipAddress.phone
        this.business_name = input.shipAddress.business_name
      }
    }
    return this
  }
}
