export const getContactInfo = (countryCode: string) => {
  switch (countryCode) {
    case "AU":
      return {
        phoneHref: "tel:+611300120102",
        phone: "+61 1300 120 102",
        emailHref: "mailto: anzclientservices@ulabsystems.com",
        email: "anzclientservices@ulabsystems.com",
      }
    case "NZ":
      return {
        phoneHref: "tel:+640800141456",
        phone: "+64 0800 141 456",
        emailHref: "mailto: anzclientservices@ulabsystems.com",
        email: "anzclientservices@ulabsystems.com",
      }
    default:
      return {
        phoneHref: "tel:+18669008522",
        phone: "+1 (866) 900-8522",
        emailHref: "mailto: support@ulabsystems.com",
        email: "support@ulabsystems.com",
      }
  }
}
