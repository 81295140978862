import { useState, useEffect } from "react"
import { Grid, Typography, Divider, Switch } from "@mui/material"
import { UDTitlePlusMinus } from "@/UDTreat/custom/UDSelectOptions"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup } from "../udSetupSlice"
import { gdsPlusSetup } from "@/gluelayer"
import { UDSetupStatus } from "./UDSetupStatus"

export const UDSetupTooth = () => {
  const dispatch = useAppDispatch()
  const { disabled, Params, selectedTooth } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )

  const getIPRSpaceValue = (idx: number) => {
    let value = 0
    if (idx === 0) value = Params.IPRToPrev.value
    else if (idx === 1) value = Params.SpaceToPrev.value
    else if (idx === 2) value = Params.IPRToNext.value
    else if (idx === 3) value = Params.SpaceToNext.value
    value = Math.round(value * 10) / 10
    return value
  }

  const onChangeIPRSpace = (idx: number, value: number) => {
    value = Math.max(0.0, Math.round(value * 10) / 10)
    const paraName = ["IPRToPrev", "SpaceToPrev", "IPRToNext", "SpaceToNext"]
    gdsPlusSetup.SetParamDoubleValue(paraName[idx], value)
    dispatch(updateAutoSetup())
  }

  const onToothFixedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fixed = event.target.checked
    gdsPlusSetup.SetToothFixed(selectedTooth.ID, fixed)
    dispatch(updateAutoSetup())
  }

  const onSelIPRSpaceFixedChange = (fixed: boolean, toNext: boolean) => {
    gdsPlusSetup.SetToothIPREnable(fixed, toNext)
    dispatch(updateAutoSetup())
  }

  return selectedTooth.ID > 0 && !disabled ? (
    <Grid container alignItems={"center"} spacing={0.25}>
      <Grid item justifyItems="center" xs={6}>
        <Typography variant="body1">Sel Tooth</Typography>
      </Grid>
      <Grid item justifyItems="center" xs={6}>
        <Typography variant="body1">{selectedTooth.ID}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">Movement</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">Do not Move</Typography>
      </Grid>
      <Grid item xs={2}>
        <Switch checked={selectedTooth.isFixed} onChange={onToothFixedChange} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">Contact Prev.</Typography>
      </Grid>
      <Grid item xs={3}>
        Fixed IPR/Space
      </Grid>
      <Grid item xs={3}>
        <Switch
          checked={selectedTooth.isFixedIprSpacePrev}
          onChange={(e) => {
            const fixed = e.target.checked
            onSelIPRSpaceFixedChange(fixed, false)
          }}
        />
      </Grid>
      <UDTitlePlusMinus
        title="IPR"
        value={getIPRSpaceValue(0)}
        deltaSmall={0.1}
        deltaLarge={0.5}
        onChange={(newValue) => {
          onChangeIPRSpace(0, newValue)
        }}
      />
      <UDTitlePlusMinus
        title="Space"
        value={getIPRSpaceValue(1)}
        deltaSmall={0.1}
        deltaLarge={0.5}
        onChange={(newValue) => {
          onChangeIPRSpace(1, newValue)
        }}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">Contact Next.</Typography>
      </Grid>
      <Grid item xs={3}>
        Fixed IPR/Space
      </Grid>
      <Grid item xs={3}>
        <Switch
          checked={selectedTooth.isFixedIprSpaceNext}
          onChange={(e) => {
            const fixed = e.target.checked
            onSelIPRSpaceFixedChange(fixed, true)
          }}
        />
      </Grid>
      <UDTitlePlusMinus
        title="IPR"
        value={getIPRSpaceValue(2)}
        deltaSmall={0.1}
        deltaLarge={0.5}
        onChange={(newValue) => {
          onChangeIPRSpace(2, newValue)
        }}
      />
      <UDTitlePlusMinus
        title="Space"
        value={getIPRSpaceValue(3)}
        deltaSmall={0.1}
        deltaLarge={0.5}
        onChange={(newValue) => {
          onChangeIPRSpace(3, newValue)
        }}
      />
    </Grid>
  ) : (
    <UDSetupStatus />
  )
}
