import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material"
import { useState } from "react"
import { UDSelectOptions } from "../custom/UDSelectOptions"
import { RightPage } from "./RightPage"

export function UDRetainerRx(props: any) {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const MsgCheckLabel = ({ msg, label }) => {
    return (
      <>
        <Grid item xs={6}>
          {msg}
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel control={<Checkbox />} label={label} />
        </Grid>
      </>
    )
  }
  const RxContent = () => {
    return (
      <>
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6">Retainer Rx Form</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ bgcolor: "text.disabled", p: 0.5 }} variant="h6">
              Archform
            </Typography>
          </Grid>
          <MsgCheckLabel msg="Select Arches" label="Upper" />
          <MsgCheckLabel msg="" label="Lower" />
          <Grid item xs={12}>
            <Typography sx={{ bgcolor: "text.disabled", p: 0.5 }} variant="h6">
              Details
            </Typography>
          </Grid>
          <MsgCheckLabel msg="Bracket Removal" label="Yes" />
          <MsgCheckLabel
            msg="Remove Scanned Attachments / Buttons"
            label="Yes"
          />
          <MsgCheckLabel msg="Cover Lingual Bar" label="Yes" />
          <MsgCheckLabel msg="Remove Lingual Bar" label="Yes" />
          <MsgCheckLabel msg="Add Bite Ramp" label="Yes" />
          <MsgCheckLabel msg="Pontic" label="Yes" />
        </Grid>
        <Grid container spacing={0.5} alignItems="top">
          <Grid item xs={6}>
            Additional Instructions
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              rows={4}
              id="instruction"
              label="Please enter your notes here"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </>
    )
  }
  return (
    <RightPage
      title="Retainer Rx"
      onClose={props.onClose}
      content={<RxContent />}
      actions={<Box sx={{ height: 20 }} />}
    />
  )
}
