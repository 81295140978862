import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Grid, Divider, Button, Typography, Stack, Switch } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { UDTitleSelect } from "../custom/UDSelectOptions"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import {
  wasmModule,
  gdsPlusSetup,
  OJOBType,
  SetupType,
  MidlineType,
  TreatArchType,
  IPRRange,
} from "@/gluelayer"
import { AutoSetupByRx } from "../right/CommonUI/AutoSetupUI"
import { AllToothType } from "./AllToothType"
import { caseManagement, resourcesSynchronization } from "@/gluelayer"
import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { setDisable } from "../udSetupSlice"

export const SetupByRx = ({ setupTypeAndStr }) => {
  const dispatch = useAppDispatch()
  const [teethType, setTeethType] = useState([])
  const [selTypeStr, setSelTypeStr] = useState("")
  const [setupRxStr, setSetupRxStr] = useState("")
  const getTypeStrs = () => {
    let strs = []
    setupTypeAndStr.forEach((value) => {
      strs.push(value[1] as string)
    })
    return strs
  }
  const onRunSetup = () => {
    let setupType = SetupType.Init
    for (let i = 0; i < setupTypeAndStr.length; i++) {
      if (selTypeStr === (setupTypeAndStr[i][1] as string)) {
        setupType = setupTypeAndStr[i][0] as SetupType
      }
    }
    const Rx = {
      SetupType: setupType,
      TreatArch: TreatArchType.Both,
      OJOBType: OJOBType.Improve,
      TeethType: teethType,
    }
    const RxStr = JSON.stringify(Rx)
    setSetupRxStr("")
    setTimeout(() => setSetupRxStr(RxStr), 100) // wait 100 ms then start rx
    dispatch(setDisable(false))
    console.log(RxStr)
  }

  const onRunAIPresetup = () => {}

  useEffect(() => {
    if (!wasmModule.isInit) return
    gdsPlusSetup.SwitchAutoSetupModule(true)
    const infoStr = gdsPlusSetup.getAutoSetupModule().GetCaseInfoString()
    const caseInfo = JSON.parse(infoStr)
    setTeethType(caseInfo["TeethType"])
    return () => {
      console.log("End chairside setup")
    }
  }, [])

  const onSetToothType = (tid, newType) => {
    const newTeethType = teethType
    newTeethType[tid - 1] = newType
    setTeethType([...newTeethType])
  }

  return (
    <Grid container alignItems={"center"} spacing={0.25}>
      <UDTitleSelect
        title="Treatment Type"
        options={getTypeStrs()}
        onChangeSel={(selStr) => setSelTypeStr(selStr)}
      />
      <AllToothType teethType={teethType} onSetToothType={onSetToothType} />
      <Grid item xs={6}>
        <Button variant="contained" onClick={onRunSetup}>
          Run / Rerun Setup
        </Button>
      </Grid>
      <AutoSetupByRx rxStr={setupRxStr} />
    </Grid>
  )
}

export const AdjustSetup = (props) => {
  const ojOptionType = {
    option: ["Improve", "Ant. 0.5mm", "Ant. 1.0mm", "Maintain"],
    type: [
      OJOBType.Improve,
      OJOBType.HalfMmSpace,
      OJOBType.OneMmSpace,
      OJOBType.Init,
    ],
  }
  const onChangeOverjet = (ojstr: string) => {
    for (let i = 0; i < ojOptionType.option.length; i++) {
      if (ojstr === ojOptionType.option[i]) {
        gdsPlusSetup.SetOJOBByTypeID(ojOptionType.type[i], () => {
          console.log("Set OJOB by type is done!")
        })
        break
      }
    }
  }

  const obOptionType = {
    option: ["3mm", "2mm", "1mm", "0mm", "-1mm", "Maintain"],
    type: [
      OJOBType.Improve,
      OJOBType.HalfMmSpace,
      OJOBType.OneMmSpace,
      OJOBType.OneMmSpace,
      OJOBType.OneMmSpace,
      OJOBType.Init,
    ],
  }
  const onChangeOverbite = (ojstr: string) => {
    const obMms = [3, 2, 1, 0, -1.0, 2]
    for (let i = 0; i < obOptionType.option.length; i++) {
      if (ojstr === obOptionType.option[i]) {
        gdsPlusSetup.getAutoSetupModule().SetParamDouble("OB", obMms[i])
        break
      }
    }
  }
  const midlineOptionType = {
    option: ["Move Both", "To Upper", "To Lower", "Maintain"],
    type: [
      MidlineType.MIDTYPE_CENTER,
      MidlineType.MIDTYPE_TOUPPER,
      MidlineType.MIDTYPE_TOLOWER,
      MidlineType.MIDTYPE_INIT,
    ],
  }
  const onChangeMidline = (ojstr: string) => {
    for (let i = 0; i < midlineOptionType.option.length; i++) {
      if (ojstr === midlineOptionType.option[i]) {
        gdsPlusSetup.SetMidlineByTypeID(midlineOptionType.type[i], () => {
          console.log("Set midline by type is done!")
        })
        break
      }
    }
  }
  const iprRangeOptionType = {
    option: ["Anterior Only", "Molar to Molar", "Incisor Only", "Canine Only"],
    type: [
      IPRRange.AntOnly,
      IPRRange.Full,
      IPRRange.IncisorOnly,
      IPRRange.CanineOnly,
    ],
  }
  const onChangeIprRange = (ojstr: string, upper: boolean) => {
    for (let i = 0; i < iprRangeOptionType.option.length; i++) {
      if (ojstr === iprRangeOptionType.option[i]) {
        gdsPlusSetup.SetIPRRangeByID(upper, iprRangeOptionType.type[i])
        break
      }
    }
  }

  return (
    <Grid container alignItems={"center"} spacing={0.25}>
      <Grid item xs={12}>
        <Typography variant="h6">Detail setup</Typography>
      </Grid>
      <UDTitleSelect
        title="Overjet"
        options={ojOptionType.option}
        onChangeSel={onChangeOverjet}
      />
      <UDTitleSelect
        title="Overbite"
        options={obOptionType.option}
        onChangeSel={onChangeOverbite}
      />
      <UDTitleSelect
        title="Midline"
        options={midlineOptionType.option}
        onChangeSel={onChangeMidline}
      />
      <UDTitleSelect
        title="Upper IPR Range"
        options={iprRangeOptionType.option}
        onChangeSel={(option) => {
          onChangeIprRange(option, true)
        }}
      />
      <UDTitleSelect
        title="Lower IPR Range"
        options={iprRangeOptionType.option}
        onChangeSel={(option) => {
          onChangeIprRange(option, false)
        }}
      />
    </Grid>
  )
}

export const UDSetupType = () => {
  const dispatch = useAppDispatch()
  const [showStl, setShowStl] = useState(false)
  const { patientId, caseId } = useParams()
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const setupTypeAndStr = [
    [SetupType.Full, "Auto"],
    [SetupType.Anterior, "Anterior"],
    [SetupType.FullNoAP, "Molar to Molar"],
    [SetupType.Full, "Full Arch"],
    [SetupType.Anterior, "Upper Only"],
    [SetupType.Anterior, "Lower Only"],
    [SetupType.FineTune, "Fine Tune"],
  ]
  const downloadCaseZip = async () => {
    const zipName =
      "ulabcase_" +
      patientId.toString() +
      "_" +
      caseId.toString() +
      "_" +
      patientData.firstName +
      "_" +
      patientData.lastName +
      ".zip"
    const files = await resourcesSynchronization.downloadCaseZip(zipName)
    console.log("Save", files)
  }
  const onClickSaveCase = (e) => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        const params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        console.log("🚀 ~ caseManagement.getCaseFiles ~ zips:", zips)
        dispatch(setDisable(true))
        dispatch(uploadCaseZips(params))
      }
    })
  }
  const handleShowStl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowStl(event.target.checked)
  }
  useEffect(() => {
    if (wasmModule.isInit) {
      wasmModule.statusController.UpdateArchScan()
      wasmModule.statusController.ShowArchScan(showStl)
    }
  }, [showStl])
  return (
    <>
      <SetupByRx setupTypeAndStr={setupTypeAndStr} />
      <Divider />
      <Stack direction="row" spacing={2}>
        <Button onClick={downloadCaseZip}> Download Case</Button>
        <Button variant="contained" size="small" onClick={onClickSaveCase}>
          Save Case
        </Button>
        <FormControlLabel
          control={
            <Switch checked={showStl} onChange={handleShowStl} name="showStl" />
          }
          label="Show STL"
        />
      </Stack>
      {/* <AdjustSetup /> */}
    </>
  )
}
