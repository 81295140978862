import { useState, useEffect, useRef } from "react"
import { Box, Button, Hidden, Paper } from "@mui/material"
import Toggle from "./Toggle/toggle"
import { useHotkeys } from 'react-hotkeys-hook'

import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
  stagingManager,
  treatView,
} from "@/gluelayer"
import { Height } from "@mui/icons-material"
import { number } from "prop-types"
import { current } from "@reduxjs/toolkit"
import ToggleForCompare from "./Toggle/toggleForCompare"

const {
  setcalsetupfinishCallback,
  getBoltonReport,
  getAttachmentIPRReport,
  getHasArchType,
  getTreatmentPlanList,
  addTreatment,
  changeTreatment,
  renameTreatment,
  delTx,
} = caseManagement

export const BottomToolBar = (props) => {
  const [stageBarShowType, setStageBarShowType] = useState(-1)
  const [compareOn, setCompareOn] = useState(false)
  const [stageBarWidth, setStageBarWidth] = useState(650)
  const [initalShow, setInitalShow] = useState(true)
  const [reportShow, setReportShow] = useState(true)
  const [openMovement, setopenMovement] = useState(true)
  const toggleRef = useRef(null)
  const toggleCompare1Ref = useRef(null)
  const toggleCompare2Ref = useRef(null)
  const [stageObj, setStageObj] = useState({
    upList: [],
    downList: [],
    up_current: 0,
    down_current: 0,
  })
  const [planList, setPlanList] = useState<{ txName: string; index: 1 | 2 }[]>(
    [],
  )
  const [currentPlan, setCurrentPlan] = useState<{
    txName: string
    index: 2 | 1
  }>({ txName: "", index: 1 })
  const [preparation, setPreparation] = useState<boolean>(false)

  // function to reNamePlan
  const renamePlanFunction = (newName: string) => {
    // renameTreatment(currentPlan.index, newName)
    // const txList = getTreatmentPlanList()
    // const txArr = assemblyPlanData(txList)
    // txArr.map((i) => {
    //   if (i.index === currentPlan.index) {
    //     setCurrentPlan(i)
    //   }
    // })
    // setPlanList(txArr)
  }
  // function to deletePlan
  const deletePlanFunction = (plan = currentPlan.index) => {
    // const isDel = delTx(plan)
    // const txList = getTreatmentPlanList()
    // const txArr = assemblyPlanData(txList)
    // setCurrentPlan(txArr[0])
    // setPlanList(txArr)
    // return isDel
  }
  const [attachmentObj, setAttachmentObj] = useState<{
    up: boolean
    down: boolean
  }>({ up: false, down: false })

  //stage bar是否处于折叠状态
  const [isStageFold, setIsStageFold] = useState(true)

  // which refinement key
  const [refinementKey, setRefinementKey] = useState<string>("")

  // Availability of upper and lower jaw
  const [isHasUpAndlow, setIsHasUpAndLow] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState(true)

  // up\low arch about view show icon bool(aligner and retainer)
  const [arLimitShow, setArLimitShow] = useState<boolean>(false)

  const [stagebarHeight, setStagebarHeight] = useState(0)

  //max refine
  const [maxRefine, setMaxRefine] = useState<number>(0)

  // up and low attachment stage
  const [attachStage, setAttachStage] = useState<{
    upStageIndex: number
    lowerStageIndex: number
  }>({ upStageIndex: 0, lowerStageIndex: 0 })

  // if have bite-jump
  const [hasBite, setHasBite] = useState<boolean>(true)

  // if refienment case and not last refinement
  const [isRANL, setIsRANL] = useState<boolean>(false)

  useHotkeys('ctrl+i', () => {setStageBarShowType(stageBarShowType === 0 ? 1:0)}, { keycode: 73 })

  // Assembly plan data
  const assemblyPlanData = (planData: string[]) => {
    const txArr = []
    planData.map((i, j) => {
      txArr.push({
        txName: i.trim(),
        index: j + 1,
      })
    })
    return txArr
  }

  // get txPlan data
  const getTxPlanFunc = () => {
    const txList = getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setPlanList(txArr)
    setCurrentPlan(txArr[0])
    //console.info("currentPlan name index", currentPlan.txName, currentPlan.index)
  }

  const txPlanChangedHandle = () => {
    const txList = getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setPlanList(txArr)
    console.info("before set, currentPlan name index", currentPlan.txName, currentPlan.index)
    if (txList.length > 0) {
      setCurrentPlan(txArr[txList.length - 1])
    }
    console.info("after set, currentPlan name index", txArr[txList.length - 1].txName, txArr[txList.length - 1].index)
  }

  const changeCurrentTxPlan = () => {

  }


  useEffect(() => {
    if (!preparation) {
      return
    }
    if (!currentPlan.index) {
      return
    }
    changeTreatment(currentPlan.index)
    setopenMovement(false)
    console.info("currentPlan name index", currentPlan.txName, currentPlan.index)
  }, [currentPlan])

  useEffect(() => {
    //for compare
    if (stageBarShowType === 0) {
      treatView.Open(false)
      setCompareOn(false)
    }
    else if(stageBarShowType === 1) {
      treatView.Open(true)
      treatView.StartComparison("Plan1", "Plan2")
      setCompareOn(true)
    }
  }, [stageBarShowType])

  useEffect(() => {
    setcalsetupfinishCallback(() => {
      setPreparation(true)
      // get tx list
      getTxPlanFunc()
      setStageBarShowType(0)
      // //for compare
      // if (stageBarShowType === 0) {
      //   // treatView.Open(false)
      //   setCompareOn(false)
      // }
      // else {
      //   treatView.Open(true)
      //   treatView.StartComparison("Plan1", "Plan1")
      //   setCompareOn(true)
      // }
    })

    stagingManager.setTxPlanChangedCallBack = txPlanChangedHandle
  }, [])


  const selectStageShowType = () => {
    if(stageBarShowType === -1) {
      return <></>
    }
    else if (stageBarShowType === 0) {
      return <Box sx={{
        left: props.parentRef.current ? ((props.parentRef.current.offsetWidth - stageBarWidth) / 2 < 0 ? 0 : (props.parentRef.current.offsetWidth - stageBarWidth) / 2) : 0,
        position: "absolute",
        // top: props.parentRef.current?.offsetHeight - (180 + 20),
        bottom: "2px",
        width: stageBarWidth,
        height: "146px",
        overflow: "hidden",
        background: "transparent"
      }}
      >
        <Toggle
          ref={toggleRef}
          preparation={true}
          setReportShow={setReportShow}
          setopenMovement={setopenMovement}
          setInitalShow={setInitalShow}
          initalShow={initalShow}
          openMovement={openMovement}
          isReady={true}
          setStageObj={setStageObj}
          planList={planList}
          setCurrentPlan={setCurrentPlan}
          currentPlan={currentPlan}
          renamePlanFunction={renamePlanFunction}
          deletePlanFunction={deletePlanFunction}
          attachmentObj={attachmentObj}
          setIsStageFold={setIsStageFold}
          refinementKey={refinementKey}
          setRefinementKey={setRefinementKey}
          isHasUpAndlow={isHasUpAndlow}
          isEdit={isEdit}
          arLimitShow={arLimitShow}
          setStagebarHeight={setStagebarHeight}
          maxRefine={maxRefine}
          setMaxRefine={setMaxRefine}
          attachStage={attachStage}
          hasBite={hasBite}
          isRANL={isRANL}
          stageBarWidth={stageBarWidth}
          setStageBarWidth={setStageBarWidth}
          getTxPlans={getTxPlanFunc}
        >

        </Toggle>
      </Box>
    }
    else if (stageBarShowType === 1) {
      return <Box sx={{
        left: 2,
        position: "absolute",
        // top: props.parentRef.current?.offsetHeight - (180 + 20),
        bottom: "2px",
        width: "100%",
        height: "146px",
        overflow: "hidden",
        background: "transparent",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        pointerEvents: "none",
      }}
      >
        <div style={{width: (props.parentRef.current?.offsetWidth-toggleCompare1Ref.current?.offsetWidth-toggleCompare2Ref.current?.offsetWidth)/4, height: "1px"}}/>
        <ToggleForCompare
          ref={toggleCompare1Ref}
          preparation={true}
          setReportShow={setReportShow}
          setopenMovement={setopenMovement}
          setInitalShow={setInitalShow}
          initalShow={initalShow}
          openMovement={openMovement}
          isReady={true}
          setStageObj={setStageObj}
          planList={planList}
          setCurrentPlan={setCurrentPlan}
          currentPlan={currentPlan}
          renamePlanFunction={renamePlanFunction}
          deletePlanFunction={deletePlanFunction}
          attachmentObj={attachmentObj}
          setIsStageFold={setIsStageFold}
          refinementKey={refinementKey}
          setRefinementKey={setRefinementKey}
          isHasUpAndlow={isHasUpAndlow}
          isEdit={isEdit}
          arLimitShow={arLimitShow}
          setStagebarHeight={setStagebarHeight}
          maxRefine={maxRefine}
          setMaxRefine={setMaxRefine}
          attachStage={attachStage}
          hasBite={hasBite}
          isRANL={isRANL}
          stageBarWidth={stageBarWidth}
          setStageBarWidth={setStageBarWidth}
          getTxPlans={getTxPlanFunc}
          viewIndex={0}
          onOff={compareOn}
        >
        </ToggleForCompare>
        <div style={{width: (props.parentRef.current?.offsetWidth-toggleCompare1Ref.current?.offsetWidth-toggleCompare2Ref.current?.offsetWidth)/4, height: "1px"}}/>
        <div style={{width: (props.parentRef.current?.offsetWidth-toggleCompare1Ref.current?.offsetWidth-toggleCompare2Ref.current?.offsetWidth)/4, height: "1px"}}/>
        <ToggleForCompare
          ref={toggleCompare2Ref}
          preparation={true}
          setReportShow={setReportShow}
          setopenMovement={setopenMovement}
          setInitalShow={setInitalShow}
          initalShow={initalShow}
          openMovement={openMovement}
          isReady={true}
          setStageObj={setStageObj}
          planList={planList}
          setCurrentPlan={setCurrentPlan}
          currentPlan={currentPlan}
          renamePlanFunction={renamePlanFunction}
          deletePlanFunction={deletePlanFunction}
          attachmentObj={attachmentObj}
          setIsStageFold={setIsStageFold}
          refinementKey={refinementKey}
          setRefinementKey={setRefinementKey}
          isHasUpAndlow={isHasUpAndlow}
          isEdit={isEdit}
          arLimitShow={arLimitShow}
          setStagebarHeight={setStagebarHeight}
          maxRefine={maxRefine}
          setMaxRefine={setMaxRefine}
          attachStage={attachStage}
          hasBite={hasBite}
          isRANL={isRANL}
          stageBarWidth={stageBarWidth}
          setStageBarWidth={setStageBarWidth}
          getTxPlans={getTxPlanFunc}
          viewIndex={1}
          onOff={compareOn}
        >
        </ToggleForCompare>
        <div style={{width: (props.parentRef.current?.offsetWidth-toggleCompare1Ref.current?.offsetWidth-toggleCompare2Ref.current?.offsetWidth)/4, height: "1px"}}/>
      </Box>
    }
  }

  return (
    selectStageShowType()
  )
}
