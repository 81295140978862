import { wasmModule } from "../../modules/wasm/wasmModule";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";
import { callUntilPromise } from "../../../utils/utils";
import { toothCorrectionModule } from "./toothcorrectiondispatcher";
import { createMachine, assign } from "xstate";

/**
 * 开启或者关闭SpaceCorrect功能
 * enabled  true开启 flase 关闭
 */
//this.moduleManager.ToggleSpaceCorrect(enabled:boolean)

class ToothSpaceCorrection {
  state;

  get moduleManager() {
    const ulabwinIns = wasmModule.getWasmObj()._ulabwinIns;
    const moduleManager = ulabwinIns.getModuleManager();
    return moduleManager;
  }

  constructor() {
    this.state = createMachine({
      id: "spacecorrectionmodule",
      initial: "on",
      context: {
        trimLine: false,
        scissors: false,
      },
      states: {
        on: {
          entry: [
            () => {
              this.moduleManager.ToggleSpaceCorrect(true);
            },
          ],
          exit: [
            () => {
              this.moduleManager.ToggleSpaceCorrect(false);
            },
          ],
        },
        off: {},
      },
    });
  }

  async doAction(setUplower: (item: "up" | "low") => void) {
    // const state = await getDvaApp()._store.getState();
    // const message = 'Clicking ok will delete stage.';
    // popupHelper.alertPopup('info', message, [
    //   {
    //     name: 'ok',
    //     func: () => {
    //       // 判断旧状态是什么状态
    //       if (state.mainModel.arch !== 'up') {
    //         setUplower('up');
    //       }
    //       callUntilPromise(
    //         async () => {
    //           const newState = await getDvaApp()._store.getState();
    //           return newState.mainModel.arch === 'up';
    //         },
    //         () => {
    //           toothCorrectionModule.service.send('TOGGLE_SPACECORRECTION');
    //         },
    //       );
    //     },
    //   },
    //   {
    //     name: 'cancel',
    //     func: () => { },
    //   },
    // ]);
  }
}

export const toothSpaceCorrection = new ToothSpaceCorrection();
