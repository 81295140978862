import { globalEvents, GlobalEvents } from "../../../utils";
import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { wasmModule } from "../wasm/wasmModule";

class RefinementModule {
  wasmRefinementDisplayModule;
  refinementInfo: string[];

  openRefinementModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    console.log("openHistoryModule");
    wasmModule.moduleManager.SwitchRefinementDisplayModule(isOpen);
    this.wasmRefinementDisplayModule =
      wasmModule.moduleManager.GetRefinementDisplayModule();
    console.log(
      "wasmRefinementDisplayModule :",
      this.wasmRefinementDisplayModule
    );
  }

  changeDisplayStageValue(index: number, archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    this.wasmRefinementDisplayModule.changeDisplayStageValue(index, archTpye);
  }

  setRefinementDisplayRange(displayRange: string) {
    if (!this.wasmRefinementDisplayModule) return false;
    stagingManager.showRefinementHistoryRangeType = displayRange;
    const res =
      this.wasmRefinementDisplayModule.setRefinementDisplayRange(displayRange);

    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE);

    return res;
  }

  getNumberOfRefinement(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return 0;
    return this.wasmRefinementDisplayModule.getRefinementCount(archTpye);
  }

  getRefinementInfo(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    const info =
      this.wasmRefinementDisplayModule.getRefinementDataInfo(archTpye);
    this.refinementInfo = info.split(";");
    return this.refinementInfo;
  }

  getCurNodes(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    const nodes = this.wasmRefinementDisplayModule.getCurNodes(archTpye);
    return nodes;
  }

  getCurStartStageIndex(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    return this.wasmRefinementDisplayModule.getCurStartStageIndex(archTpye);
  }

  getHistoryStageNumber(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
    const maxStageNumber =
      this.wasmRefinementDisplayModule.getMaxHistoryStageNumber(archTpye);
    const refinmentNumber =
      this.wasmRefinementDisplayModule.getNumberOfRefinement(archTpye);
    return maxStageNumber - refinmentNumber + 1;
  }

  undoRefinement(archTpye: number) {
    if (!this.wasmRefinementDisplayModule) return;
  }
}

export const refinementModule = new RefinementModule();
