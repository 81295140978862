import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { caseManagement, preview } from "@/gluelayer"

import { UText } from "../../../components"
import { Box, Button, Grid } from "../../../components/mui.components"
import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { updateCaseById } from "../../../core/app/slices/case"
import { uploadCaseZips } from "../../../core/app/slices/clinical/clinicalThunkApi"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { updateOrder } from "../../../core/app/slices/order/orderThunkApi"
import { updaterxFormData } from "../../../core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  updateFormSavedData,
} from "../../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../../core/app/store"
import { checkIfObjectHasKeys } from "../../../core/utils/formatters"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"

import { NoUassistPopup } from "./NoUassistPopup"
import Summary from "./Summary"

const OrderForms: FC = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [schema, setSchema] = useState({})
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [error, setError] = useState([])
  const { patientId, caseId } = useParams()
  const navigate = useMyNavigation()
  const [downloadPDF, setDownloadPDF] = useState<boolean>(false)
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)
  const { rxJsonForm } = useAppSelector((state: RootState) => state.formService)
  const { VITE_REFINEMENT } = import.meta.env

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const { caseList, caseDetail } = useAppSelector(
    (state: RootState) => state.caseService,
  )
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  useEffect(() => {
    const obj = rxJsonForm.data
    let err = []
    if (rxJsonForm.schema?.required?.length && !rxJsonForm.saveid) {
      err = Object.keys(rxJsonForm.schema.required).map((key) => {
        return { instancePath: "/" + rxJsonForm.schema.required[key] }
      })
    }

    const udesignCategory = caseList[0]?.udesign_category
    if (udesignCategory && checkIfObjectHasKeys(rxJsonForm.data)) {
      const arches = udesignCategory.split("/")
      const data = []
      if (arches[0].includes("R") || arches[0].includes("A")) {
        data.push("upper")
      }
      if (arches[1].includes("R") || arches[1].includes("A")) {
        data.push("lower")
      }
      data.length
        ? setError([
            ...err.filter((err) => err.instancePath !== "/arches_to_treat"),
          ])
        : setError([...err])

      handleDataChange({ ...rxJsonForm.data, arches_to_treat: data }, [])
    }
    // update the default stage number
    if (caseList[0]?.udesign_json) {
      const obj = JSON.parse(caseList[0].udesign_json)
      const Upper = obj.UpperMaxStageNum
      const Lower = obj.LowerMaxStageNum
      handleDataChange(
        { ...rxJsonForm.data, current_aligner_number: { Upper, Lower } },
        [],
      )
      const tempenum = [
        { name: "Upper", enum: [] },
        { name: "Lower", enum: [] },
      ]

      tempenum.map((item) => {
        if (item.name === "Upper") {
          for (let i = 0; i <= Upper; i++) {
            item.enum.push(i.toString())
          }
        } else {
          for (let i = 0; i <= Lower; i++) {
            item.enum.push(i.toString())
          }
        }
      })
      if (checkIfObjectHasKeys(rxJsonForm.schema)) {
        setSchema({
          ...rxJsonForm.schema,
          properties: {
            ...rxJsonForm.schema.properties,
            current_aligner_number: {
              ...rxJsonForm.schema.properties?.current_aligner_number,
              inputGroup: tempenum,
            },
          },
        })
      }
    }
  }, [caseList, rxJsonForm.schema])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: VITE_REFINEMENT,
        caseId: caseId,
        getSavedData: true,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (rxJsonForm?.data?.stage_ipr) {
      if (rxJsonForm?.data?.stage_ipr === "Not applicable" && error.length) {
        setError(
          error.filter(
            (item) =>
              item.instancePath !== "/stage_ipr" &&
              item.instancePath !== "/max_interproximal_conatct",
          ),
        )
      }
    }
  }, [rxJsonForm?.data?.stage_ipr])

  useEffect(() => {
    if (
      ((error &&
        error.length &&
        !error.find((e) => e.instancePath === "/current_aligner_number")) ||
        (error && !error.length)) &&
      rxJsonForm?.data?.current_aligner_number
    ) {
      if (
        Number(rxJsonForm?.data?.current_aligner_number?.Upper) === 0 &&
        Number(rxJsonForm?.data?.current_aligner_number?.Lower) === 0
      ) {
        const errorObj = {
          instancePath: "/current_aligner_number",
        }
        setError([...error, errorObj])
      }
    }
  }, [
    rxJsonForm?.data?.current_aligner_number?.Upper,
    rxJsonForm?.data?.current_aligner_number?.Lower,
    error,
  ])

  const handleDataChange = (data: any, errors: any) => {
    if (
      error &&
      error.length &&
      error.some((e) => e.instancePath === "/current_aligner_number")
    ) {
      if (
        data?.current_aligner_number?.Upper ||
        data?.current_aligner_number?.Lower
      ) {
        const updatedError = error.filter(
          (e) => e.instancePath !== "/current_aligner_number",
        )
        setError(updatedError)
      }
    }

    dispatch(updaterxFormData(data))
    if (!errors.length) return
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (currError.instancePath === "") return
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }

  useEffect(() => {
    console.log(error, rxJsonForm.data, "submitting retainer form")
    const saveform = async () => {
      if (downloadPDF) return
      if (error.length) {
        const getFocusId = setTimeout(() => {
          const errorElms = document.getElementsByClassName("Mui-error")
          if (errorElms.length > 0) {
            errorElms[0].scrollIntoView({ behavior: "smooth" })
          }
          clearTimeout(getFocusId)
        }, 100)
      } else if (isFormSubmitted) {
        if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
          setShowUNoUassistAlert(true)
        } else {
          const isarchSaved = await preview.saveDoRefinementStage(
            Number(rxJsonForm?.data?.current_aligner_number?.Upper) === 0
              ? "-1010"
              : rxJsonForm?.data?.current_aligner_number?.Upper,
            Number(rxJsonForm?.data?.current_aligner_number?.Lower) === 0
              ? "-1010"
              : rxJsonForm?.data?.current_aligner_number?.Lower,
          )
          if (isarchSaved) {
            caseManagement.getCaseFiles(["bulk2", "bulk10"]).then((r) => {
              const zips = []
              if (r) {
                for (const k in r) {
                  zips.push({
                    fileName: k,
                    file: r[k],
                  })
                }
                dispatch(
                  uploadCaseZips({
                    zips,
                    patientId,
                    caseId,
                  }),
                ).then((res) => {
                  dispatch(
                    updateFormSavedData({
                      formId: rxJsonForm.id,
                      caseId: caseId,
                      payload: rxJsonForm.data,
                      savedId: rxJsonForm.saveid,
                    }),
                  ).then((res) => {
                    if (res.payload.status === 200 && rxJsonForm?.id) {
                      dispatch(
                        setIsRouteChangeBlocked({
                          isRouteChangeBlocked: false,
                        }),
                      )
                      dispatch(
                        setAlert({
                          message: t(
                            "uassist.uAssistForm.refinementsavesuccess",
                          ),
                        }),
                      )
                      if (caseDetail && caseDetail.udesign_json) {
                        const udesignCategory = caseDetail?.udesign_category
                        const arches =
                          udesignCategory && udesignCategory.split("/")
                        const upper = arches && arches[0]
                        const lower = arches && arches[1]
                        const udesign_json = JSON.parse(caseDetail.udesign_json)
                        const newUdesignJsonObj = {
                          ...udesign_json,
                          LowerType: rxJsonForm.data.arches_to_treat.includes(
                            "lower",
                          )
                            ? lower
                            : "",
                          UpperType: rxJsonForm.data.arches_to_treat.includes(
                            "upper",
                          )
                            ? upper
                            : "",
                        }
                        dispatch(
                          updateCaseById({
                            patientId,
                            caseId,
                            payload: {
                              case_extra_attrs:
                                JSON.stringify(newUdesignJsonObj),
                            },
                          }),
                        ).then((res) => {
                          dispatch(
                            updateOrder({
                              caseId: caseId,
                              type: "Refinement uAssist",
                              upper: rxJsonForm.data.arches_to_treat.includes(
                                "upper",
                              )
                                ? upper
                                : "",
                              lower: rxJsonForm.data.arches_to_treat.includes(
                                "lower",
                              )
                                ? lower
                                : "",
                            }),
                          ).then((result: any) => {
                            if (result.payload.status === "Success") {
                              setDownloadPDF(true)
                            } else {
                              dispatch(
                                setAlert({
                                  message: t(
                                    "uassist.uAssistForm.refinementsavefailed",
                                  ),
                                  isError: true,
                                }),
                              )
                            }
                          })
                        })
                      }
                    } else {
                      dispatch(
                        setAlert({
                          message:
                            rxJsonForm?.id &&
                            t("uassist.uAssistForm.refinementsavefailed"),
                        }),
                      )
                    }
                  })
                })
              }
            })
          }
        }
      }
    }

    saveform()
  }, [error, isFormSubmitted, downloadPDF])

  return (
    <>
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsFormSubmitted}
      />
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          padding: 5,
          justifyContent: "start",
        }}
      >
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          {rxJsonForm?.id && (
            <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
              {t("uassist.uAssistForm.refinementTitle")}
            </UText>
          )}
        </Grid>
        <Grid item>
          {!!(
            checkIfObjectHasKeys(schema) &&
            checkIfObjectHasKeys(rxJsonForm.uischema) &&
            checkIfObjectHasKeys(rxJsonForm.data)
          ) && (
            <JsonFormBuilder
              schema={schema}
              uischema={rxJsonForm.uischema}
              defaultData={rxJsonForm.data}
              handleChange={handleDataChange}
              isFormSubmitted={isFormSubmitted}
              setIsFormSubmitted={setIsFormSubmitted}
            />
          )}
        </Grid>
        <Grid item container justifyContent={"space-between"} mt={2}>
          {rxJsonForm.id && (
            <>
              <Button
                variant={"outlined"}
                onClick={() => {
                  navigate(
                    `/overview/patient/${patientId}/case/${caseId}/courserefinement/xray`,
                  )
                }}
                sx={{ width: "92px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginLeft: "3px" }}
                />
                {t("button.back")}
              </Button>
              <Button
                variant={"contained"}
                sx={{ width: "196px", height: "36px" }}
                onClick={() => {
                  setIsFormSubmitted(true)
                }}
              >
                {t("button.submittoUassist")}
                <ChevronRightIcon
                  fontSize={"small"}
                  sx={{ height: "20px", width: "20px", marginLeft: "8px" }}
                />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {downloadPDF && (
        <Box sx={{ zIndex: -1, position: "fixed" }}>
          <Summary isRefinement isRetainer={false} />
        </Box>
      )}
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default OrderForms
