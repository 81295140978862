import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { formatAddress } from "../../../core/utils/formatters"
import { UText } from "../../../ui-component"
import { formatDate } from "../../Patient/PatientList/util/commonUtil"

const ULabCompanyProfile = () => {
  const { t } = useTranslation("common")
  const { companyProfile } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const [defaultAddress, setDefaultAddress] = useState({})

  useEffect(() => {
    if (companyProfile) {
      setDefaultAddress(
        companyProfile?.address?.find(
          (eachAddress) => eachAddress?.type?.toLowerCase() === "default",
        ),
      )
    }
  }, [companyProfile])

  return (
    <>
      {companyProfile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.default",
            boxShadow: 1,
            borderRadius: 2,
            width: "100%",
            p: 4,
            gap: 3,
          }}
        >
          <UText variant={"h6"}>{t("uLabAccount.companyProfile.title")}</UText>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "25%",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label1")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.name}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label2")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.id}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label3")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.orgType}
                </UText>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "25%",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label4")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {formatAddress(defaultAddress)}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label5")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {defaultAddress?.phone}
                </UText>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "25%",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label6")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.orgStatus}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label7")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.orgCreatedOn?.length
                    ? formatDate(companyProfile?.orgCreatedOn)
                    : "N/A"}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label8")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.activatedOn?.length
                    ? formatDate(companyProfile?.activatedOn)
                    : "N/A"}
                </UText>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "25%",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label9")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.invoiceStartDate?.length
                    ? formatDate(companyProfile?.invoiceStartDate)
                    : "N/A"}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label10")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.licenseValidDate?.length
                    ? formatDate(companyProfile?.licenseValidDate)
                    : "N/A"}
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UText variant={"subtitle2"}>
                  {t("uLabAccount.companyProfile.label11")}
                </UText>
                <UText variant={"body2"} color="text.secondary">
                  {companyProfile?.billingMethod?.length
                    ? companyProfile?.billingMethod
                    : "N/A"}
                </UText>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ULabCompanyProfile
