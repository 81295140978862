import { createSlice } from "@reduxjs/toolkit"

import { fetchDoctorsList } from "./doctorThunkApi"

export interface IDoctor {
  label: string
  id: string
  category: string
  first_name: string
  last_name: string
  user_role: string
}
interface IDoctorState {
  doctorList: IDoctor[]
  isFetching: boolean
  isSuccess: boolean
  isError: boolean
}

const initialState: IDoctorState = {
  doctorList: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
}

export const doctorServiceSlice = createSlice({
  name: "doctorService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDoctorsList.pending, (state) => {
      console.log("pending")

      state.isFetching = true
    })
    builder.addCase(fetchDoctorsList.fulfilled, (state, action) => {
      const { result } = action.payload
      if (result) {
        const { doctors, others } = result
        const doctorsList = doctors?.map((doctor) => {
          return {
            ...doctor,
            category: "Doctors",
            label: doctor.first_name + " " + doctor.last_name,
          }
        })
        const othersList = others?.map((other) => {
          return {
            ...other,
            category: "Others",
            label: other.first_name + " " + other.last_name,
          }
        })
        state.doctorList = doctorsList.concat(othersList)
      }
      state.isSuccess = true
      state.isFetching = false
    })
    builder.addCase(fetchDoctorsList.rejected, (state) => {
      console.log("rejected")

      state.isFetching = false
      state.isError = true
    })
  },
})
