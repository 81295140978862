import { FC } from "react"
import { useTranslation } from "react-i18next"

const PageNotFound: FC = () => {
  const { t } = useTranslation("common")
  return (
    <>
      {"404"} - {t("pageNotFound.description")}
    </>
  )
}

export default PageNotFound
