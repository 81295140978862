import { GlobalEvents, globalEvents } from "../../../utils";
import { ICaseExtInfo } from "../../common";
import { wasmModule } from "../wasm/wasmModule";

class TreatmentPlan {
  private _currentTx = "Treatment_1";
  private _currentTxIndex = 1;
  private _caseExtInfo: ICaseExtInfo;

  get caseExtInfo() {
    return this._caseExtInfo;
  }

  get currentTx() {
    return this._currentTx;
  }

  get currentTxIndex() {
    return this._currentTxIndex;
  }

  setCaseExtInfo(caseExtInfo: ICaseExtInfo) {
    this._caseExtInfo = caseExtInfo;
  }

  /**
   * Get treatment plan name list
   * @returns
   */
  getTxNameList() {
    const ret = [wasmModule.stagingcontoler.GetTXName(1)];
    if (wasmModule.stagingcontoler.Tx2Exist()) {
      ret.push(wasmModule.stagingcontoler.GetTXName(2));
    }
    this._currentTx = ret[0];
    return ret;
  }

  getTxDataChanged() {
    return wasmModule.stagingcontoler.GetTxDataChanged();
  }

  setTxDataChanged(changed:boolean) {
    return wasmModule.stagingcontoler.SetTxDataChanged(changed);
  }

  addTx() {
    return wasmModule.stagingcontoler.AddTreatment();
  }

  /**
   * change current tx to another
   * @param txIndex
   * @returns
   */
  changeTx(txIndex: 1 | 2) {
    // if (this._currentTx === txName) {
    //   return false;
    // }
    // const txIndex = this._getNameIndex(txName);
    this._currentTxIndex = txIndex;
    wasmModule.stagingcontoler.ChangeTreatment(txIndex);
    this._currentTx = wasmModule.stagingcontoler.GetTXName(txIndex);

    console.log("changeTx::", txIndex, `current Tx::${this._currentTx}`);

    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE);

    return true;
  }

  /**
   * del a tx plan
   * @param txIndex the tx plan index
   * @returns
   */
  delTx(txIndex: 1 | 2): boolean {
    // console.log("del::", this._currentTx, txName);
    // if (this._currentTx === txName) {
    //   console.log(`Can't del the current TxPlan.`);
    //   return false;
    // }
    // const txIndex = this._getNameIndex(txName);
    if (txIndex == 2) {
      if (wasmModule.stagingcontoler.Tx2Exist()) {
        const ret = wasmModule.stagingcontoler.DelTx(2);
        console.log(`Del Treatment index ${txIndex} done:`, ret);
        return ret;
      }
    }

    if (txIndex == 1) {
      const ret = wasmModule.stagingcontoler.DelTx(1);
      console.log(`Del Treatment index ${txIndex} done:`, ret);
      return ret;
    }
    return false;
  }

  saveCurrentTx() {
    return wasmModule.stagingcontoler.SaveCurTx();
  }

  /**
   * renameTx
   * @param txName old name
   * @param newName new name
   * @returns
   */
  renameTx(txIndex: 1 | 2, newName: string) {
    // const txIndex = this._getNameIndex(txName);
    // if (this._currentTx === txName) {
    //   this._currentTx = newName;
    // }
    return wasmModule.stagingcontoler.RenameTX(txIndex, newName);
  }

  copyTx() {
    console.log("the function is in development");
    return true;
  }

  _getNameIndex(txName: string) {
    if (txName === wasmModule.stagingcontoler.GetTXName(1)) {
      return 1;
    }
    if (txName === wasmModule.stagingcontoler.GetTXName(2)) {
      return 2;
    }
    return 0;
  }
}

export const treatmentPlan = new TreatmentPlan();
