import { wasmModule } from "../wasm/wasmModule";
import { EArchType } from "../../common";

/**
 * Crow 牙冠
 * Implant 种植牙
 * Bridge 桥体
 * pontic
 * Erupting
 * Extract  拔牙
 * Primary 乳牙 （牙号5xx）
 * Supernumerary 多生牙 (牙号7xx)
 */
export interface IToothProperty {
  isCrown: boolean;
  isImplant: boolean;
  isBridge: boolean;
  isPontic: boolean;
  isErupting: boolean;
  isExtract: boolean;
  isPrimary: boolean;
}

/**
 * get all tooth IDs include the extract and special teeth.
 * @returns
 */
function getToothList(): { uplist: number[]; lowlist: number[] } {
  const uplist: number[] = [];
  const lowlist: number[] = [];
  // if there is upper jaw
  const archModelUpper = wasmModule.getArchModel(EArchType.UpArch);
  if (archModelUpper) {
    const toothupIds = archModelUpper.GetNormalAndPulloutToothIds();
    const size = toothupIds.size();
    for (let i = 0; i < size; i++) {
      const toothid = toothupIds.get(i);
      uplist.push(toothid);
    }
    if (uplist[0] > uplist[uplist.length - 1]) {
      uplist.reverse();
    }
  }

  // if there is lower jaw
  const archModelLower = wasmModule.getArchModel(EArchType.LowArch);
  if (archModelLower) {
    const toothlowIds = archModelLower.GetNormalAndPulloutToothIds();
    const sizelow = toothlowIds.size();
    for (let i = 0; i < sizelow; i++) {
      const toothid = toothlowIds.get(i);
      lowlist.push(toothid);
    }
    if (lowlist[0] < lowlist[uplist.length - 1]) {
      lowlist.reverse();
    }
  }

  return { uplist, lowlist };
}

/**
 * 获取牙齿属性 需要能获取ToothModule才能调用
 * @param toothId
 * @returns
 */
function getToothProperties(toothId: number): IToothProperty | null {
  const toothModule = wasmModule.wrapInstance.GetMoveToothModule();
  if (!toothModule) return null;
  const properties = {
    isCrown: toothModule.GetToothCrownStatus(toothId),
    isImplant: toothModule.GetToothImplantStatus(toothId),
    isBridge: toothModule.GetToothBridgeStatus(toothId),
    isPontic: toothModule.GetToothPonticStatus(toothId),
    isErupting: toothModule.GetToothEruptStatus(toothId),
    isPrimary: toothModule.GetToothPrimaryStatus(toothId),
    isExtract: wasmModule.mouthModel.GetToothExtractStatus(toothId),
  };
  return properties;
}

/**
 * 通过牙齿ID 获取Primary牙齿标识(a,b,c,d,e)
 */
function getPrimaryToothMark(
  toothId: number
): "a" | "b" | "c" | "d" | "e" | undefined {
  const PrimaryMasks = {
    a: [508, 509, 524, 525],
    b: [507, 510, 523, 526],
    c: [506, 511, 522, 527],
    d: [505, 512, 521, 528],
    e: [504, 513, 520, 529],
  };

  const mark = PrimaryMasks.a.includes(toothId)
    ? "a"
    : PrimaryMasks.b.includes(toothId)
    ? "b"
    : PrimaryMasks.c.includes(toothId)
    ? "c"
    : PrimaryMasks.d.includes(toothId)
    ? "d"
    : PrimaryMasks.e.includes(toothId)
    ? "e"
    : undefined;
  return mark;
}

/**
 * 前牙
 * @param toothId
 * @returns
 */
export function isIncisorTeeth(toothId: number) {
  const id = toothId % 100;
  if ((id > 6 && id < 11) || (id > 22 && id < 27)) return true;
  return false;
}
/**
 * 尖牙
 * @param toothId
 * @returns
 */
export function isCaninesTeeth(toothId: number) {
  const id = toothId % 100;
  if (id === 6 || id === 11 || id === 22 || id === 27) return true;
  return false;
}
/**
 * 前磨牙
 * @param toothId
 * @returns
 */
export function isPremolarsTeeth(toothId: number) {
  const id = toothId % 100;
  if (
    id === 4 ||
    id === 5 ||
    id === 12 ||
    id === 13 ||
    id === 20 ||
    id === 21 ||
    id === 28 ||
    id === 29
  )
    return true;
  return false;
}
/**
 * 磨牙
 * @param toothId
 * @returns
 */
export function isMolarTeeth(toothId: number) {
  const id = toothId % 100;
  if (
    id === 2 ||
    id === 3 ||
    id === 14 ||
    id === 15 ||
    id === 18 ||
    id === 19 ||
    id === 30 ||
    id === 31
  )
    return true;
  return false;
}
/**
 * 智齿
 * @param toothId
 * @returns
 */
export function isWisdom(toothId: number) {
  const id = toothId % 100;
  if (id === 1 || id === 16 || id === 17 || id === 32) return true;
  return false;
}
/**
 * 乳牙
 * @param toothId
 * @returns
 */
export function isDeciduous(toothId: number) {
  const id = Math.trunc(toothId / 100);
  if (id === 5) return true;
  return false;
}
/**
 * 智齿+多生牙判定
 * @param toothId
 * @returns
 */
export function isExtraWisdom(toothId: number) {
  const id = toothId % 100;
  if (id === 1 || id === 16 || id === 17 || id === 32) return true;
  const ide = Math.trunc(toothId / 100);
  if (ide === 7) return true;
  return false;
}
/**
 * 切牙+尖牙判定
 * @param toothId
 * @returns
 */
export function isExtraWisdomCanineIncisor(toothId: number) {
  const id = toothId % 100;
  if (
    id === 1 ||
    id === 16 ||
    id === 17 ||
    id === 32 ||
    id === 23 ||
    id === 24 ||
    id === 25 ||
    id === 26 ||
    id === 7 ||
    id === 8 ||
    id === 9 ||
    id === 10
  )
    return true;
  const ide = Math.trunc(toothId / 100);
  if (ide === 7) return true;
  return false;
}
export function isSign(sign: "UpL" | "UpR" | "LowL" | "LowR", toothId: number) {
  const id = toothId % 100;
  if (id < 9 && sign === "UpR") return true;
  if (id > 8 && id < 17 && sign === "UpL") return true;
  if (id > 16 && id < 25 && sign === "LowL") return true;
  if (id > 24 && sign === "LowR") return true;
  return false;
}

/**
 * 是否是上牙
 * @param toothId
 * @returns
 */
export function isUpTooth(toothId: number) {
  const id = toothId % 100;
  if (id < 17) return true;
  return false;
}

export const toothModule = {
  isIncisorTeeth,
  isCaninesTeeth,
  isPremolarsTeeth,
  isMolarTeeth,
  isWisdom,
  isDeciduous,
  isExtraWisdom,
  isExtraWisdomCanineIncisor,
  isSign,
  isUpTooth,
  getToothList,
  getToothProperties,
  getPrimaryToothMark,
};
