import { createSlice } from "@reduxjs/toolkit"
import { isError } from "lodash"

import {
  IExistingPatientList,
  INewPatientData,
  IPatientList,
} from "../../../model/interface/IPatient"
import { PatientData } from "../../../model/Patient"

import {
  addNewCase,
  addNewPatient,
  fetchExistingPatientList,
  fetchPatientById,
  fetchPatientList,
  updatePatientData,
  addPatientV2,
  addCaseV2,
} from "./patientThunkApi"
interface NewPatientV2Res {
  addressLine1: any
  city: any
  country: any
  email: any
  first_name: string
  gender: any
  id: null
  last_name: string
  patientCountry: any
  patient_dob: string
  phone: any
  ref_notes: any
  state: any
  zipcode: any
}
interface IPage {
  currentPage: number
  pageSize: number
  totalRecords: number
  count: number
  actionNeededCount: number
}

interface IPatientServiceState {
  patients: IPatientList[]
  existingPatients: IExistingPatientList[]
  paging: IPage
  loading: "idle" | "pending" | "succeeded" | "failed"
  newPatientAPI: "idle" | "pending" | "succeeded" | "failed"
  updatePatientAPI: "idle" | "pending" | "succeeded" | "failed"
  patientData: INewPatientData
  caseId: string
  assigned_to: string
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  assignedTo: number
  newPatientV2ResData: NewPatientV2Res
  newCaseV2ResData: any
  versionNum: number
}

const initialState: IPatientServiceState = {
  patients: [],
  existingPatients: [],
  paging: {} as IPage,
  loading: "idle",
  newPatientAPI: "idle",
  updatePatientAPI: "idle",
  patientData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    notes: "",
    status: "",
  },
  caseId: "",
  assigned_to: "",
  isFetching: false,
  isError: false,
  isSuccess: false,
  assignedTo: 0,
  newPatientV2ResData: {
    addressLine1: null,
    city: null,
    country: null,
    email: null,
    first_name: "",
    gender: null,
    id: null,
    last_name: "",
    patientCountry: null,
    patient_dob: "",
    phone: null,
    ref_notes: null,
    state: null,
    zipcode: null,
  },
  newCaseV2ResData: {},
  versionNum: 1.1, // 1.1 or 2
}

export const patientServiceSlice = createSlice({
  name: "patientService",
  initialState,
  reducers: {
    setData(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    setAssignedTo: (state, action) => {
      state.assignedTo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchPatientList.fulfilled, (state, action) => {
      const {
        records,
        totalRecords,
        currentPage,
        pageSize,
        actionNeededCount,
      } = action.payload
      state.patients = records || []
      state.paging = {
        currentPage: currentPage,
        pageSize: pageSize,
        totalRecords: totalRecords,
        actionNeededCount: actionNeededCount,
        count: totalRecords,
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchPatientList.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
    builder.addCase(addNewPatient.pending, (state) => {
      state.caseId = ""
      state.newPatientAPI = "pending"
    })
    builder.addCase(addNewPatient.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data.status.toUpperCase() === "SUCCESS") {
        state.caseId = data.result.case.id
        state.patientData = new PatientData().Deserialize(data.result.patient)
      }
      state.newPatientAPI = "succeeded"
      state.loading = "succeeded"
    })
    builder.addCase(addNewPatient.rejected, (state) => {
      state.newPatientAPI = "failed"
    })
    builder.addCase(addNewCase.pending, (state) => {
      state.newPatientAPI = "pending"
    })
    builder.addCase(addNewCase.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data.status.toUpperCase() === "SUCCESS") {
        state.newPatientAPI = "succeeded"
        state.patientData = new PatientData().Deserialize(data.result.patient)
      }
    })
    builder.addCase(addNewCase.rejected, (state) => {
      state.newPatientAPI = "failed"
    })
    builder.addCase(fetchPatientById.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchPatientById.fulfilled, (state, action) => {
      const { result } = action.payload
      state.patientData = new PatientData().Deserialize(result)
      state.loading = "succeeded"
    })
    builder.addCase(fetchPatientById.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(fetchExistingPatientList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchExistingPatientList.fulfilled, (state, action) => {
      const { records } = action.payload
      state.existingPatients = records || []
      state.loading = "succeeded"
    })
    builder.addCase(fetchExistingPatientList.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(updatePatientData.pending, (state) => {
      state.updatePatientAPI = "pending"
    })
    builder.addCase(updatePatientData.fulfilled, (state, action) => {
      const { data } = action.payload
      if (data.status.toUpperCase() === "SUCCESS") {
        state.patientData = new PatientData().Deserialize(data.result)
      }
      state.updatePatientAPI = "succeeded"
    })
    builder.addCase(updatePatientData.rejected, (state) => {
      state.updatePatientAPI = "failed"
    })
    builder.addCase(addPatientV2.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(addPatientV2.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const { data } = action.payload
      if (data.msg.toUpperCase() === "SUCCESS") {
        state.newPatientV2ResData = data.data
      }
    })
    builder.addCase(addPatientV2.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(addCaseV2.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(addCaseV2.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const { data } = action.payload
      if (data.msg.toUpperCase() === "SUCCESS") {
        state.newCaseV2ResData = data.data
      }
    })
    builder.addCase(addCaseV2.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export const { setData, setAssignedTo } = patientServiceSlice.actions
