import { useEffect, useState } from "react"
import { Backdrop, Box, LinearProgress } from "@mui/material"
import tenSconds from "../../assets/tenSeconds.webp"
import { SetupType, gdsPlusSetup } from "@/gluelayer"
import { useAppDispatch } from "@/core/app/hooks"
import { setUpdateStaging, setUpdateSetup } from "@/UDTreat/udTreatSlice"

export const AutoSetupByType = ({ setupType }) => {
  const dispatch = useAppDispatch()
  const [isRunning, setIsRunning] = useState(false)
  const [runningMsg, setRunningMsg] = useState("")
  useEffect(() => {
    if (setupType !== "") {
      setRunningMsg("Running auto setup...")
      setIsRunning(true)
      const timer = setTimeout(() => {
        gdsPlusSetup.setupByType(setupType as SetupType, () => {
          console.log("Auto setup the case ...")
          setIsRunning(false)
          setRunningMsg("Done!")
          dispatch(setUpdateStaging())
        })
      }, 100) // wait 100ms so the backdrop is shown.
    }
  }, [setupType])
  return isRunning ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Box sx={{ width: "40%" }}>
        <Box
          component="img"
          sx={{ width: "100px", height: "50px" }}
          src={tenSconds}
        />
        {runningMsg}
        <LinearProgress />
      </Box>
    </Backdrop>
  ) : (
    <></>
  )
}

export const AutoSetupByRx = ({ rxStr }) => {
  const dispatch = useAppDispatch()
  const [isRunning, setIsRunning] = useState(false)
  const [runningMsg, setRunningMsg] = useState("")
  useEffect(() => {
    if (rxStr !== "") {
      setRunningMsg("Running auto setup...")
      setIsRunning(true)
      const timer = setTimeout(() => {
        gdsPlusSetup.setupByRxString(rxStr, () => {
          console.log("Auto setup the case ...")
          setIsRunning(false)
          setRunningMsg("Done!")
          dispatch(setUpdateStaging())
          dispatch(setUpdateSetup())
        })
      }, 100) // wait 100ms so the backdrop is shown.
    }
  }, [rxStr])
  return isRunning ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Box sx={{ width: "40%" }}>
        <Box
          component="img"
          sx={{ width: "100px", height: "50px" }}
          src={tenSconds}
        />
        {runningMsg}
        <LinearProgress />
      </Box>
    </Backdrop>
  ) : (
    <></>
  )
}