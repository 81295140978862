/**
 * bind callback function to window for wasm
 * @param param
 * @param cb
 */
export function bindCallbackToWindow(param: string, cb) {
  const params = param.split(".");
  let rootBind: any = window;
  for (let index = 0, len = params.length; index < len; index++) {
    const item = params[index];
    if (index === len - 1) {
      rootBind[item] = cb;
      // console.log('bind>>>>cb', window[params[0]]);
    } else {
      if (!rootBind[item]) {
        rootBind[item] = {};
      }
      rootBind = rootBind[item];
    }
  }
}
