import { createAsyncThunk } from "@reduxjs/toolkit"

import { getAccessToken } from "@/core/config/okta.config"
import * as v2Apis from "../../v2/v2.service"

import {
  downloadPhotographFiles,
  fetchFileList,
  getS3FileUrl,
  updatePhotoPositionAPI,
  uploadPhoto,
  uploadPhotographFiles,
  uploadFiles,
  uploadPhotoJSONAPI,
  uploadThumbnails,
  downloadPhotoJSONAPI,
  uploadOnePhotoFile,
  deletePhotographFile
} from "./photograph.service"
import {
  fetchFileParams,
  fileDownloadParams,
  IPhotosDownloadParams,
  fileUploadParams,
  updatePhotoPositionParams,
  fileArrayUploadParams,
  uploadPhotoTypeParams,
  IUploadFilesCommonParams,
  IPhotosDeleteParams
} from "./photograph.type"

export const uploadPhotographsInv_1 = createAsyncThunk(
  "PhotosService/uploadPhotographs",
  async (
    {
      patientId,
      caseId,
      formData,
      fileName,
      onFileProcesscallback,
    }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadOnePhotoFile(
        orgId,
        patientId,
        caseId,
        fileName,
        formData,
        onFileProcesscallback,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

interface UploadFormDatas{
  formData:FormData,
  fileName:string
}


export const uploadCaseFiles = createAsyncThunk(
  "PhotosService/uploadCaseFiles",
  async(
    {
      patientId,
      caseId,
      files,
      callback}:IUploadFilesCommonParams,
    {rejectWithValue, getState}
  ) =>{
    const orgId = getState().userService.user.current_orgId
    try{
      return uploadFiles({
        patientId,
        caseId,
        files,
        orgId,
        callback
      })
    }catch(err){
      return rejectWithValue(err)
    }
  }
)

export const uploadThumbnailv1_1 = createAsyncThunk(
  "PhotosService/uploadThumbnailArray",
  async({
    patientId,
    caseId,
    files,
    callback
  }:IUploadFilesCommonParams,
  {rejectWithValue, getState}) =>{
    const orgId = getState().userService.user.current_orgId
    try{
      return uploadThumbnails({
        patientId,
        caseId,
        files,
        orgId,
        callback
      })
    }catch(err){
      return rejectWithValue(err)
    }

  }
)


export const uploadPhotographsv1_1 = createAsyncThunk(
  "PhotosService/uploadPhotographArray",
  async (
    {
      patientId,
      caseId,
      files,
      callback,
    }: IUploadFilesCommonParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId

    const ret:Promise<any>[] = []
    await uploadPhotographFiles({
      orgId,
      patientId,
      caseId,
      files,
      callback
    })
    return ret;
  },
)

export const uploadPhotoJSON = createAsyncThunk(
  "PhotosService/uploadPhotoJSON",
  async (
    { patientId, caseId, jsonFile,callback }: uploadPhotoTypeParams,
    { rejectWithValue, getState },
  )=>{
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadPhotoJSONAPI(orgId, patientId, caseId, jsonFile,callback)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const downloadPhotoJSON = createAsyncThunk(
  "PhotosService/downloadPhotoJSON",
  async(
    { patientId, caseId, callback }: any,
    { rejectWithValue, getState },
  ) =>{
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadPhotoJSONAPI(orgId, patientId, caseId,callback)
    } catch (err) {
      return rejectWithValue(err)
    }

  }
)

export const downloadPhotographs1_1 = createAsyncThunk<File[],IPhotosDownloadParams>(
  "PhotosService/downloadPhotographs1_1",
  async (
    { patientId, caseId, fileNames },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      const ret = Promise.all(fileNames.map(async fileName=>{
        return await downloadPhotographFiles(orgId, patientId, caseId, fileName)
      }))
      return ret;
      //return await downloadPhotographFiles(orgId, patientId, caseId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deletePhotographs = createAsyncThunk<File[],IPhotosDeleteParams>(
  "PhotosServoce/deletePhotographs",
  async(
    { patientId, caseId, fileNames },
    { rejectWithValue, getState },
  )=>{
    const orgId = getState().userService.user.current_orgId
    try{
      const ret = Promise.all(fileNames.map(async fileName=>{
        return await deletePhotographFile(orgId, patientId, caseId, fileName)
      }))
    }catch(err){
      return rejectWithValue(err)
    }
  }
)



export const downloadPhotographs = createAsyncThunk(
  "PhotosService/downloadPhotographs",
  async (
    { patientId, caseId, fileName }: fileDownloadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadPhotographFiles(orgId, patientId, caseId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchFilesList = createAsyncThunk(
  "PhotosService/fetchFileList",
  async (
    { patientId, caseId }: fetchFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await fetchFileList(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updatePhotoPosition = createAsyncThunk(
  "PhotosService/updatePhotoPosition",
  async (
    { patientId, caseId, payload }: updatePhotoPositionParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await updatePhotoPositionAPI(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getS3FileUrlAPI = createAsyncThunk(
  "PhotosService/getS3FileUrl",
  async ({ caseId, casePhoto }, { rejectWithValue }) => {
    try {
      return await getS3FileUrl({ caseId, casePhoto })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const uploadPhotographsInv_2 = createAsyncThunk(
  "PhotosService/uploadPhoto",
  async ({ url, file }, { rejectWithValue }) => {
    try {
      console.log("upload:::>>>>", file, url)
      return await uploadPhoto({ url, file })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)


export const uploadPhotosToS3 = createAsyncThunk(
  "utils/uploadPhotosToS3",
  async (
    params: { caseId; files?: Record<string, File> },
    { rejectWithValue, getState },
  ) => {
    try {
      const response = await v2Apis.uploadFilesToS3(
        params.caseId,
        undefined,
        params.files,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadPhotosfromS3 = createAsyncThunk<
  Record<string, string | Blob>,
  { caseId: string; photos: v2Apis.IPhotoInfo[] }
>("PhotosService/downloadPhotosfromS3", async (params, { rejectWithValue }) => {
  try {
    const response = await v2Apis.requestS3Url({
      caseId: params.caseId,
      urlType: "download",
      casePhoto: params.photos,
    })
    if (response.code === 0 && response.msg === "success") {
      const respData = response.data
      console.log("respdata:::", respData)
      const result: Record<string, string | Blob | File> = {}
      let ret: Promise<File | null>[] = []
      for (let index = 0; index < respData.casePhoto.length; index++) {
        ret.push(
          new Promise(async (resolve, reject) => {
            const s3urlres = respData.casePhoto[index]
            let resRet = await v2Apis.downloadS3Resource(s3urlres)
            if (resRet instanceof Error) {
              resolve(null)
            } else {
              if (resRet.data instanceof Blob) {
                resRet = new File([resRet.data], s3urlres.destFilename)
              } else {
                // json obj
                resRet = resRet.data
              }
              resolve(resRet)
              result[s3urlres.destFilename] = resRet
            }
          }),
        )
      }

      await Promise.all(ret)

      return result
    }
  } catch (err) {
    return rejectWithValue(err)
  }
})
