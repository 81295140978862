import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { UButton, UText } from "../../../components"
import { Box } from "../../../components/mui.components"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"

const ForgotUsername: FC = () => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  return (
    <>
      <UText variant={"h5"} color={"text"} sxProp={{ mt: 2 }}>
        {t("auth.forgotusernametitle")}
      </UText>

      <Box
        component={"div"}
        sx={{
          my: 2,
          ...CENETER_COLUMN_ALIGN,
          color: "#000000",
          " > div": { m: "2px" },
        }}
      >
        <UText variant={"body1"} color={"inherit"} component={"div"}>
          {t("auth.forgotusernamehelptext")}
        </UText>
        <UText variant={"body1"} color={"inherit"} component={"div"}>
          {"(866) 900-8522"}
        </UText>
      </Box>
      <UButton
        sxProp={{ mt: 2, width: "100%" }}
        variant={"contained"}
        btnText={t("button.backtologin")}
        onClickHandler={() => navigate("/auth/login")}
      />
    </>
  )
}

export default ForgotUsername
