import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@mui/material"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

// Case lock doc in
// https://ulabsystems.atlassian.net/wiki/spaces/UE/pages/670662657/Case+Locking+System+for+uSmileAI#When-to-lock-/-unlock

// How to do
// 1. Before start editing, Dr. needs "lock" the case -- so others can't changed it on the same time.
// 2. If "lock" failed, means some one is working on it and already "lock" this case.
//      The case may be locked by uAssist, by Dr., by another user, or has been ordered
// 3. You can "force unlock" the case -- even someone else already "lock it".
// 4. After it's "force unlock", then dr. can "lock" the case.
// 5. After editing is doen, Dr. can then "unlock" the case and other can edit it.

import {
  lockCaseApi,
  unlockCaseApi,
  forcelockCaseApi,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { setLockCaseForEdit } from "./udTreatSlice"

const caseLockByUassist = [
  "CASE_ALREADY_LOCKED_BY_UASSIST",
  "CASE_CANNOT_BE_LOCKED_BY_DOCTOR",
]

const caseLockByShipped = ["CASE_HAS_BEEN_ORDERED"]
const caseLockByOtherUser = [
  "CASE_ALREADY_LOCKED_BY_OTHER_USER",
  "CASE_ALREADY_LOCKED_BY_OTHER_PRODUCT",
  "CASE_CANNOT_BE_LOCKED_BY_UASSIST",
  "CASE_CANNOT_BE_LOCKED_BY_CRB",
  "CASE_ALREADY_LOCKED_BY_ANOTHER_USER",
]
export const caseLockByCRB = ["CASE_ALREADY_LOCKED_BY_CRB"]

export const UDLockCaseForEdit = () => {
  const { lockCaseForEdit } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [lockFail, setLockFail] = useState(false) // default is success so dialog is not popup
  const [lockFailMsg, setLockFailMsg] = useState("")
  const [forceLock, setForceLock] = useState(false)
  const { t } = useTranslation("common")
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (lockCaseForEdit != "Start") return
    dispatch(lockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        setLockFail(false)
        dispatch(setLockCaseForEdit("Locked"))
      } else {
        setLockFail(true)
        const { errors, username, device_info, locked_date } = payload.data
        const { error_message } = errors[0]
        const info = error_message.split("::")[0]
        if (caseLockByUassist.includes(info)) {
          setLockFailMsg("The case is already locked by uAssist.")
          setForceLock(true)
        } else if (caseLockByShipped.includes(info)) {
          setLockFailMsg("The case is already shipped.")
          setForceLock(false)
        } else if (caseLockByOtherUser.includes(info)) {
          setLockFailMsg(
            "The case is already locked by user: " + username + ".",
          )
          setForceLock(true)
        } else if (caseLockByCRB.includes(info)) {
          setLockFailMsg("The case is already locked by uFab")
          setForceLock(false)
        }
      }
    })
  }, [lockCaseForEdit])
  const onForceLock = () => {
    if (!forceLock) return
    setLockFail(false) // To close the dialog
    dispatch(forcelockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        dispatch(setLockCaseForEdit("Locked"))
      } else {
        dispatch(setLockCaseForEdit("LockFailed"))
      }
    })
  }
  return lockFail ? (
    <Dialog open={true}>
      <DialogTitle>Lock Case for Edit</DialogTitle>
      <DialogContent>{lockFailMsg}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setLockFail(false)
            dispatch(setLockCaseForEdit("LockFailed"))
          }}
        >
          Cancel
        </Button>
        <Button disabled={!forceLock} variant="contained" onClick={onForceLock}>
          Unlock and force lock to me
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}
