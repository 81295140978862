import axios from "axios"
/**
 *  请求ucoud 2.0 S3服务
 */
const HttpS3 = ({ url }) => {
  const http = axios.create({
    baseURL: url,
    headers: { "Access-Control-Allow-Origin": true },
    withCredentials: true,
  })
  return http
}

export default HttpS3
