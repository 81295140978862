import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchBannerData, postMessageData } from "./bannerThunkApi"

interface IBanner {
  warning_subject: string
  content: string
  type: string
}

interface IBannerState {
  bannerData: IBanner
  isFetching: boolean
  bannerArray: Array<string>
}

const initialState: IBannerState = {
  bannerData: {} as IBanner,
  isFetching: false,
  bannerArray: [],
}

export const bannerServiceSlice = createSlice({
  name: "bannerService",
  initialState,
  reducers: {
    setBannerArray: (state, action: PayloadAction<any>) => {
      state.bannerArray = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBannerData.pending, (state) => {
      state.isFetching = true
    })
    builder.addCase(fetchBannerData.fulfilled, (state, action) => {
      const { payload } = action
      state.bannerData = payload
      state.isFetching = false
    })
    builder.addCase(fetchBannerData.rejected, (state) => {
      state.isFetching = false
    })
    builder.addCase(postMessageData.pending, (state) => {
      state.isFetching = true
    })
    builder.addCase(postMessageData.fulfilled, (state, action) => {
      state.isFetching = false
    })
    builder.addCase(postMessageData.rejected, (state) => {
      state.isFetching = false
    })
  },
})

export const { setBannerArray } = bannerServiceSlice.actions
