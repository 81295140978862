import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

const KEY = "weSmileUI"
export function loadWeSmileUIState() {
  try {
    const serializedState = localStorage.getItem(KEY)
    if (!serializedState) return false
    return JSON.parse(serializedState) as boolean
  } catch (e) {
    return false
  }
}
export async function saveWeSmileUIState(state: boolean) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(KEY, serializedState)
  } catch (e) {
    /* do nothing */
  }
}
export const wasm3DServiceSlice = createSlice({
  name: "wasm3DService",
  initialState: {
    curPanelName: "none",
    curArch: "all",
    curView: "front",
    hideRightNavPage: "", // "","start","end"
    runPresetup: "", // "","start","end"
    getPresetupZip: "", // "","start","end"
    weSmileUI: loadWeSmileUIState(),
    selCaseId: "",
    showTMChart: true,
    AWSPresetupStatus: "",
    AWSPresetupURL: "",
    curStage: 0,
    updateStaging: false,
    updateSetup: false,
    showManuPercent: false,
  },
  reducers: {
    setCurPanelName(state, action: PayloadAction<string>) {
      state.curPanelName = action.payload
    },
    setCurArch(state, action: PayloadAction<string>) {
      state.curArch = action.payload
    },
    setCurView(state, action: PayloadAction<string>) {
      state.curView = action.payload
    },
    setRunPresetup(state, action: PayloadAction<string>) {
      state.runPresetup = action.payload
    },
    setGetPresetupZip(state, action: PayloadAction<string>) {
      state.getPresetupZip = action.payload
    },
    setWeSmileUI(state, action: PayloadAction<boolean>) {
      saveWeSmileUIState(action.payload)
      state.weSmileUI = action.payload
    },
    setHideRightNavPage(state, action: PayloadAction<string>) {
      state.hideRightNavPage = action.payload
    },
    setSelCaseID(state, action: PayloadAction<string>) {
      state.selCaseId = action.payload
    },
    setShowTMChart(state, action: PayloadAction<boolean>) {
      state.showTMChart = action.payload
    },
    setAWSPresetupStatus(state, action: PayloadAction<string>) {
      state.AWSPresetupStatus = action.payload
    },
    setAWSPresetupURL(state, action: PayloadAction<string>) {
      state.AWSPresetupURL = action.payload
    },
    setCurStage(state, action: PayloadAction<number>) {
      state.curStage = action.payload
    },
    setUpdateSetup(state) {
      state.updateSetup = !state.updateSetup
    },
    setUpdateStaging(state) {
      state.updateStaging = !state.updateStaging
    },
    setShowManuPercent(state, action: PayloadAction<boolean>) {
      state.showManuPercent = action.payload
    },
  },
})

export const {
  setCurPanelName,
  setCurArch,
  setCurView,
  setRunPresetup,
  setGetPresetupZip,
  setWeSmileUI,
  setHideRightNavPage,
  setSelCaseID,
  setShowTMChart,
  setAWSPresetupStatus,
  setAWSPresetupURL,
  setCurStage,
  setUpdateSetup,
  setUpdateStaging,
  setShowManuPercent,
} = wasm3DServiceSlice.actions
