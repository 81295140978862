import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { Button, Grid } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { resetPrescriptionSchema } from "../../core/app/slices/uassist/formSlice"
import { RootState } from "../../core/app/store"
import { useMyNavigation } from "../../hooks/useMyNavigation"
import { UButton, UText } from "../../ui-component"

const OrderType: FC = () => {
  const navigate = useMyNavigation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { patientId, caseId } = useParams()

  return (
    <>
      <Grid
        sx={{
          width: "100%",
          padding: 5,
          borderRadius: 4,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          pb={4}
        >
          <UText variant={"h4"}>{t("uassist.ordertype")}</UText>
        </Grid>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <UButton
            variant={"contained"}
            btnType={"button"}
            btnText={t("button.aligner")}
            onClickHandler={() => {
              dispatch(resetPrescriptionSchema())
              navigate(
                `/uassist/patient/${patientId}/case/${caseId}/prescription`,
              )
            }}
            sxProp={{
              width: "100%",
              mb: 2,
            }}
          />
          <UButton
            variant={"contained"}
            btnType={"button"}
            btnText={t("button.alignerandRetainer")}
            onClickHandler={() => {
              dispatch(resetPrescriptionSchema())
              navigate(
                `/uassist/patient/${patientId}/case/${caseId}/prescription`,
              )
            }}
            sxProp={{
              width: "100%",
              mb: 2,
            }}
          />
          <UButton
            variant={"contained"}
            btnType={"button"}
            btnText={t("button.retainer")}
            onClickHandler={() => {
              navigate(`/uassist/patient/${patientId}/case/${caseId}/retainer`)
            }}
            sxProp={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item mt={10}>
          <Button
            variant={"outlined"}
            onClick={() => {
              navigate(
                `/uassist/patient/${patientId}/case/${caseId}/dentalchart`,
              )
            }}
            sx={{ width: "92px", height: "36px" }}
          >
            <ChevronLeftIcon fontSize={"small"} sx={{ marginLeft: "3px" }} />
            {t("button.back")}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default OrderType
