import React from 'react';
import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material";
import savePng from "../../assets/bottomToolBar/stage/save_stage.png";
import noSavePng from "../../assets/bottomToolBar/stage/no_save_stage.png";
import { Font } from '@react-pdf/renderer';
import { click } from '@testing-library/user-event/dist/cjs/convenience/click.js';

const StatusButton = (props) => {
  
  const [clicked, setClicked] = useState(false);

  const clickHandle = () => {
    setClicked(!clicked)
  }

  return <div style={{display: "flex", 
       flexDirection: "row",
       alignItems: "center",
       justifyContent: "center",
       userSelect: 'none',
       width: props.width,
       height: props.height,
       background: clicked ? props.clickedBackgroundColor : props.backgroundColor,
       color: clicked ? props.clickedColor : props.color,
       fontSize: props.fontSize,
       borderRadius: props.radius,
  }}
  onClick={()=>{
    if(props.onClickHandle(!clicked)) {
      clickHandle()
    }
  }}>
    {props.text}
  </div>
};

export default StatusButton;