import { FC } from "react"
import { useTranslation } from "react-i18next"

import { Box } from "../../components/mui.components"
import { UText } from "../../ui-component"

export const PricingHeader: FC<{
  pricingDate: { startDate: string; endDate: string }
}> = ({ pricingDate }) => {
  const { t } = useTranslation("common")

  const centerTextStyles = {
    display: "flex",
    justifyContent: "center",
    mt: 1,
    color: "#333333",
  }

  return (
    <Box sx={{ pt: 5, pb: "20px", backgroundColor: "#EEEE", width: "100%" }}>
      <UText
        variant="h4"
        sxProp={{ ...centerTextStyles, fontWeight: 600, color: "#16AADC" }}
      >
        {t("Pricing.title")}
      </UText>
      <UText variant="subtitle1" sxProp={{ ...centerTextStyles, mt: 1 }}>
        {t("Pricing.effectiveDate", {
          startDate: pricingDate?.startDate,
          endDate: pricingDate?.endDate,
        })}
      </UText>
      <UText variant="h6" sxProp={{ display: "flex", px: 8, mt: "30px" }}>
        {t("Pricing.note")}
      </UText>
    </Box>
  )
}
