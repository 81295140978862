import { FileItem } from "../app/slices/records/photograph/photograph.type"
// Get the name of the UR/UL by tooth id
export const getName = (id: number) => {
  let name = ""
  if (id >= 1 && id <= 8) {
    name = `UR${9 - id}`
  } else if (id >= 9 && id <= 16) {
    name = `UL${id - 8}`
  } else if (id >= 17 && id <= 24) {
    name = `LL${9 - (id - 16)}`
  } else {
    name = `LR${id - 24}`
  }
  return name
}
// Get the tooth number based on the tooth id and number setting.
export const getSettingLabel = (type: string, toothId: number) => {
  const ruya1 = [508, 509, 524, 525] //508,509,524,525 属于乳牙的一种，label 页面 标记为 a
  const ruya2 = [507, 510, 523, 526] //507,510,523,526 属于乳牙的一种，label 页面 标记为 b
  const ruya3 = [506, 511, 522, 527] //506,511,522,527 属于乳牙的一种，label 页面 标记为c
  const ruya4 = [505, 512, 521, 528] //505,512,521,528 属于乳牙的一种，label 页面 标记为d
  const ruya5 = [504, 513, 520, 529] //504,513,520,529 属于乳牙的一种，label 页面 标记为e

  const linshiya1 = [608, 609, 624, 625] //608,609,624,625 属于临时牙的一种，label 页面 标记为T1
  const linshiya2 = [607, 610, 623, 626] //607,610,623,626 属于临时牙的一种，label 页面 标记为T2
  const linshiya3 = [606, 611, 622, 627] //606,611,622,627 属于临时牙的一种，label 页面 标记为T3
  const linshiya4 = [605, 612, 621, 628] //605,612,621,628 属于临时牙的一种，label 页面 标记为T4
  const linshiya5 = [604, 613, 620, 629] //604,613,620,629 属于临时牙的一种，label 页面 标记为T5
  const linshiya6 = [603, 614, 619, 630] //603,614,619,630属于临时牙的一种，label 页面 标记为T6
  const linshiya7 = [602, 615, 618, 631] //602,615,618,631属于临时牙的一种，label 页面 标记为T7
  const linshiya8 = [601, 616, 617, 632] //601, 616,617,632属于临时牙的一种，label 页面 标记为T8

  const duoya1 = [708, 709, 724, 725] //708,709,724,725 属于多生牙的一种，label 页面 标记为S1
  const duoya2 = [707, 710, 723, 726] //707,710,723,726 属于多生牙的一种，label 页面 标记为S2
  const duoya3 = [706, 711, 722, 727] //706,711,722,727 属于多生牙的一种，label 页面 标记为S3
  const duoya4 = [705, 712, 721, 728] //705,712,721,728 属于多生牙的一种，label 页面 标记为S4
  const duoya5 = [704, 713, 720, 729] //704,713,720,729 属于多生牙的一种，label 页面 标记为S5
  const duoya6 = [703, 714, 719, 730] //703,714,719,730属于多生牙的一种，label 页面 标记为S6
  const duoya7 = [702, 715, 718, 731] //702,715,718,731属于多生牙的一种，label 页面 标记为S7
  const duoya8 = [701, 716, 717, 732] //701,716,717,732属于多生牙的一种，label 页面 标记为S8
  let name = ""
  // special tooth
  if (toothId > 32 || toothId < 1) {
    if (ruya1.includes(toothId)) {
      return "a"
    } else if (ruya2.includes(toothId)) {
      return "b"
    } else if (ruya3.includes(toothId)) {
      return "c"
    } else if (ruya4.includes(toothId)) {
      return "d"
    } else if (ruya5.includes(toothId)) {
      return "e"
    } else if (linshiya1.includes(toothId)) {
      return "T1"
    } else if (linshiya2.includes(toothId)) {
      return "T2"
    } else if (linshiya3.includes(toothId)) {
      return "T3"
    } else if (linshiya4.includes(toothId)) {
      return "T4"
    } else if (linshiya5.includes(toothId)) {
      return "T5"
    } else if (linshiya6.includes(toothId)) {
      return "T6"
    } else if (linshiya7.includes(toothId)) {
      return "T7"
    } else if (linshiya8.includes(toothId)) {
      return "T8"
    } else if (duoya1.includes(toothId)) {
      return "S1"
    } else if (duoya2.includes(toothId)) {
      return "S2"
    } else if (duoya3.includes(toothId)) {
      return "S3"
    } else if (duoya4.includes(toothId)) {
      return "S4"
    } else if (duoya5.includes(toothId)) {
      return "S5"
    } else if (duoya6.includes(toothId)) {
      return "S6"
    } else if (duoya7.includes(toothId)) {
      return "S7"
    } else if (duoya8.includes(toothId)) {
      return "S8"
    }
    return "not found"
  } else {
    switch (type) {
      case "Pedro":
        if (toothId >= 1 && toothId <= 8) {
          name = `UR${9 - toothId}`
        } else if (toothId >= 9 && toothId <= 16) {
          name = `UL${toothId - 8}`
        } else if (toothId >= 17 && toothId <= 24) {
          name = `LL${9 - (toothId - 16)}`
        } else {
          name = `LR${toothId - 24}`
        }
        return name
      // if (toothId >= 1 && toothId <= 8) {
      //   name = `A${9 - toothId}`
      // } else if (toothId >= 9 && toothId <= 16) {
      //   name = `B${toothId - 8}`
      // } else if (toothId >= 17 && toothId <= 24) {
      //   name = `C${9 - (toothId - 16)}`
      // } else {
      //   name = `D${toothId - 24}`
      // }
      // return name

      case "Palmer":
        if (toothId >= 1 && toothId <= 8) {
          name = `UR${9 - toothId}`
        } else if (toothId >= 9 && toothId <= 16) {
          name = `UL${toothId - 8}`
        } else if (toothId >= 17 && toothId <= 24) {
          name = `LL${9 - (toothId - 16)}`
        } else {
          name = `LR${toothId - 24}`
        }
        return name

      case "FDI":
        if (toothId >= 1 && toothId <= 8) {
          name = `1${9 - toothId}`
        } else if (toothId >= 9 && toothId <= 16) {
          name = `2${toothId - 8}`
        } else if (toothId >= 17 && toothId <= 24) {
          name = `3${9 - (toothId - 16)}`
        } else {
          name = `4${toothId - 24}`
        }
        return name
      case "General":
        name = toothId.toString()
        return name
    }
  }
}
export const getFiles = (
  fileList: FileItem[],
  attachmentType: string,
  isRefinementFiles: boolean,
  type: string,
  refinementRound?: number,
) => {
  return fileList.filter((file) => {
    if (isRefinementFiles) {
      return (
        file.is_refinement &&
        file.refinement_round === refinementRound &&
        file.attachment_type === attachmentType &&
        file.file_name.includes(type)
      )
    } else {
      return (
        file.attachment_type === attachmentType &&
        file.file_name.includes(type) &&
        !file.is_refinement
      )
    }
  })
}

export const isDeepEqual = (currData: any, prevData: any) => {
  // Check if the objects are of the same type and not null
  if (
    typeof currData !== "object" ||
    typeof prevData !== "object" ||
    currData === null ||
    prevData === null
  ) {
    return currData === prevData
  }

  const keysA = Object.keys(currData)
  const keysB = Object.keys(prevData)

  // Check if the number of keys is the same
  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    // Recursively compare nested objects
    if (!isDeepEqual(currData[key], prevData[key])) {
      return false
    }
  }

  // If no differences are found, the objects are equal
  return true
}
