import http from "../../../global/http"

import {
  clinicalRequestBasicType,
  getZipsType,
  messageBody,
  UploadZips,
} from "./clinical.types"
export const getFileInfo = async ({
  orgId,
  patientId,
  caseId,
}: clinicalRequestBasicType): Promise<unknown> => {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/files`,
    )

    return data
  } catch (err) {
    return err
  }
}
export const getFileZips = async ({
  patientId,
  caseId,
  zipNames,
  orgId,
}: getZipsType) => {
  return Promise.all(
    zipNames.map((item) => {
      return http.get(
        `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/cases/${item}.zip`,
        {
          method: "GET",
          responseType: "blob",
        },
      )
    }),
  )
}

/**
 * get treatment plan information
 * @param orgId
 * @param patientId
 * @returns: treament list
 */
export async function getCaseByPatient(orgId: string, patientId: string) {
  try {
    const { data } = await http.get(
      `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans`,
    )
    return data
  } catch (err) {
    return err
  }
}

export async function uploadZips({
  patientId,
  caseId,
  zips,
  orgId,
}: UploadZips) {
  return Promise.all(
    zips.map(({ file, fileName }) => {
      const formData = new FormData()
      formData.append("attachment", file)
      return http.post(
        `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/cases/${fileName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
    }),
  )
}

export async function postMessage({
  patientId,
  caseId,
  orgId,
  subject,
  txName,
  content,
}: messageBody) {
  const {
    data: { results, status },
  } = await http.post(
    `/shared/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/messages`,
    { subject, txName, content },
  )
  return { result: results[0], status }
}

export async function postModiMessage({
  patientId,
  caseId,
  orgId,
  subject,
  txName,
  content,
  is_modification_requested,
}: messageBody) {
  let payload = {
    message: content,
    plan_name: txName,
    is_modification_requested,
  }
  if (is_modification_requested === 0) {
    payload = {
      message: content,
      is_modification_requested,
    }
  }
  const { data } = await http.post(
    `/shared/v1/org/${orgId}/orchestration/patients/${patientId}/txplans/${caseId}/messages/`,
    { ...payload },
  )
  return { ...data }
}

export async function updateMessage({
  patientId,
  caseId,
  orgId,
  messageId,
  ...payload
}: messageBody) {
  const { data } = await http.put(
    `/shared/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/messages/${messageId}`,
    { ...payload },
  )
  return { ...data }
}

export async function getMessage({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { results, status },
  } = await http.get(
    `/shared/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/messages`,
  )
  return { results, status }
}

export async function getIsRead({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { results, status },
  } = await http.get(
    `/shared/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/messages?unreadOnly=true`,
  )
  return { results, status }
}

export async function getCaseInfo({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { result, status },
  } = await http.get(
    `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}`,
  )
  return { result, status }
}

export async function lockCaseSever({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { result, status },
  } = await http.post(
    `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/lock`,
  )
  return { result, status }
}

export async function unlockCaseSever({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { result, status },
  } = await http.post(
    `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/unlock`,
  )
  return { result, status }
}

export async function forcelockCaseSever({
  patientId,
  caseId,
  orgId,
}: clinicalRequestBasicType) {
  const {
    data: { result, status },
  } = await http.post(
    `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/lock`,
    {
      "force-unlock-first": true,
    },
  )
  return { result, status }
}
