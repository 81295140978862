import { FC } from "react"
import Avatar from "@mui/material/Avatar"

import { parseToObject } from "../../../../../core/utils/formatters"
import { getAvatarInitial } from "../../util/commonUtil"

const ImageAvatars: FC<{ name: string; preference: string }> = ({
  name,
  preference,
}) => {
  const preferenceObject = parseToObject(preference)
  const profileIconColor = preferenceObject?.profileIconColor
  return (
    <Avatar
      sx={{
        bgcolor: profileIconColor,
        fontSize: "20px",
        lineHeight: "100%",
        letterSpacing: "0.14px",
      }}
    >
      {getAvatarInitial(name)}
    </Avatar>
  )
}

export default ImageAvatars
