import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import { getBundlePricing } from "./pricing.service"

export const fetchBundlePricing = createAsyncThunk(
  "PricingService/fetchBundlePricing",
  async (_, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await getBundlePricing(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
