import { useState, useEffect } from "react"
import { Divider, Stack, Typography } from "@mui/material"
import { gdsPlusSetup, MidlineType, OJOBType } from "@/gluelayer"
import { stagingManager } from "@/gluelayer"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup, setParamValue } from "@/UDTreat/udSetupSlice"
import { FlowBtnGroup, FlowSliders } from "../../custom/FlowCustom"
import { setUpdateSetup, setUpdateStaging } from "../../udTreatSlice"
import { UDSetupStatus } from "@/UDTreat/UDesign/UDSetupStatus"

export const WeSetupAnterior = (props) => {
  const { Params, disabled } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )

  const dispatch = useAppDispatch()

  const antBtns = [
    { label: "Ant 0mm", name: "OJImprove" },
    { label: "Ant 0.5mm", name: "OJHalfMm" },
    { label: "Ant 1.0mm", name: "OJOneMm" },
  ]

  const antSliders = [
    { ...Params.OJ, step: 0.1, min: 1, max: 5, delta: [0.1, 0.5] },
    { ...Params.OB, step: 0.1, min: 0.5, max: 5, delta: [0.1, 0.5] },
    { ...Params.LL, step: 0.1, min: -1, max: 5, delta: [0.1, 0.5] },
  ]

  const midlineSliders = [
    { ...Params.MidlineUpper, step: 0.1, min: -4, max: 4, delta: [0.1, 0.5] },
    { ...Params.MidlineLower, step: 0.1, min: -4, max: 4, delta: [0.1, 0.5] },
  ]

  const antRootTorqueSliders = [
    { ...Params.AntInclUpper, step: 1, min: -10, max: 20, delta: [1, 5] },
    { ...Params.AntInclLower, step: 1, min: -20, max: 10, delta: [1, 5] },
  ]

  const midlineBtns = [
    { label: "Keep Init", name: "MidlineInit" },
    { label: "Center", name: "MidlineCenter" },
    { label: "To Lower", name: "MidlineToLower" },
    { label: "To Upper", name: "MidlineToUpper" },
  ]

  useEffect(() => {
    console.log("mount anterior")
    dispatch(updateAutoSetup())
    return () => {
      console.log("unmount anterior")
    }
  }, [])

  const onSliderChange = (name: string, value: number) => {
    stagingManager.setEndNodeIndex()
    const payload = { name, value }
    dispatch(setParamValue(payload))
  }

  const onSliderChangeCommitted = (name: string, value: number) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamValue(name, value)
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  const onButtonClick = (btnName: string) => {
    console.log(btnName, "clicked")
    switch (btnName) {
      case "OJImprove":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.Improve, () => ({}))
        break
      case "OJHalfMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.HalfMmSpace, () => ({}))
        break
      case "OJOneMm":
        gdsPlusSetup.SetOJOBByTypeID(OJOBType.OneMmSpace, () => ({}))
        break
      case "MidlineInit":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_INIT, () => ({}))
        break
      case "MidlineCenter":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_CENTER, () => ({}))
        break
      case "MidlineToLower":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_TOUPPER, () => ({}))
        break
      case "MidlineToUpper":
        gdsPlusSetup.SetMidlineByTypeID(MidlineType.MIDTYPE_TOLOWER, () => ({}))
        break
    }
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  return (
    <Stack direction="column" spacing={1}>
      <UDSetupStatus />
      <Typography variant="body1">Anterior</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={antBtns}
        onButtonClick={onButtonClick}
      />
      <FlowSliders
        params={antSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <Divider />
      <Typography variant="body1">Midline</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={midlineBtns}
        onButtonClick={onButtonClick}
      />
      <FlowSliders
        params={midlineSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <Typography variant="body1">Ant. Root Torque</Typography>
      <FlowSliders
        params={antRootTorqueSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
    </Stack>
  )
}
