import { Document, Image, Page, Text, View } from "@react-pdf/renderer"

import ulab_hrz_logo from "../../../../assets/images/clinical/report/pdf/ulab_hrz_logo_rgb.png"

import pdfstyles from "./styles"

const ReportPDF = (props: {
  auxshot: string
  iprshot: string
  attshot: string
  patientName: string
  caseID: number
  org_name: string
}) => (
  <Document>
    <Page size="LETTER" style={pdfstyles.page}>
      <View style={pdfstyles.content}>
        <div style={pdfstyles.header}>
          <div style={pdfstyles.logocontainer}>
            <Image style={pdfstyles.logo} source={ulab_hrz_logo} />
          </div>

          <Text style={pdfstyles.text}>IPR and Auxiliaries Report</Text>
        </div>

        <div style={pdfstyles.header}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.4,
              }}
            >
              Clinical:
            </Text>
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 12,
                fontWeight: 700,
                letterSpacing: 0.4,
              }}
            >
              {props.org_name}
            </Text>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.4,
              }}
            >
              Patient name:
            </Text>
            <Text
              style={{
                ...pdfstyles.text,
                fontSize: 12,
                fontWeight: 700,
                letterSpacing: 0.4,
              }}
            >
              {props.patientName}
            </Text>
          </div>
        </div>

        <Text
          style={{
            ...pdfstyles.text,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "157%",
            letterSpacing: 0.1,
          }}
        >
          Attachment
        </Text>
        <Image source={props.attshot} />

        <Text
          style={{
            ...pdfstyles.text,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "157%",
            letterSpacing: 0.1,
          }}
        >
          Auxiliaries
        </Text>
        <Image source={props.auxshot} />
      </View>

      <View style={{ ...pdfstyles.content, height: 636 }} wrap={false}>
        <div style={pdfstyles.header}>
          <div style={pdfstyles.logocontainer}>
            <Image style={pdfstyles.logo} source={ulab_hrz_logo} />
          </div>

          <Text style={pdfstyles.text}>IPR and Auxiliaries Report</Text>
        </div>
        <Text
          style={{
            ...pdfstyles.text,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "157%",
            letterSpacing: 0.1,
          }}
        >
          IPR
        </Text>
        <Image source={props.iprshot} />
      </View>

      <Text
        style={{
          position: "absolute",
          bottom: 24,
          left: 0,
          right: 0,
          textAlign: "center",
          ...pdfstyles.text,
          fontSize: 10,
          fontWeight: 500,
          color: "rgba(0, 0, 0, 0.38)",
        }}
        render={({ pageNumber }) => `Page ${pageNumber}`}
        fixed
      />

      <Text
        style={{
          position: "absolute",
          bottom: 24,
          left: 24,
          textAlign: "center",
          ...pdfstyles.text,
          fontSize: 10,
          fontWeight: 500,
          color: "rgba(0, 0, 0, 0.38)",
        }}
        render={({ pageNumber }) => `Case ID: ${props.caseID}`}
        fixed
      />

      <Text
        style={{
          position: "absolute",
          bottom: 24,
          right: 24,
          textAlign: "center",
          ...pdfstyles.text,
          fontSize: 10,
          fontWeight: 500,
          color: "rgba(0, 0, 0, 0.38)",
        }}
        render={({ pageNumber }) => `[Placeholder for Manufacturing ID]`}
        fixed
      />
    </Page>
  </Document>
)

export default ReportPDF
