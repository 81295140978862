import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import { UButton, UText } from "../../../../components"
import {
  Alert,
  Box,
  Divider,
  Grid,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateSelectedBundle } from "../../../../core/app/slices/order"
import { RootState } from "../../../../core/app/store"
import {
  IAlacarteInfo,
  IBundleInfo,
} from "../../../../core/model/interface/IQuotation"
import { formatCurrency } from "../../../../core/utils/formatters"

const AlecarteTitleAlert: FC<{
  isCurrentBundle: boolean
}> = ({ isCurrentBundle }) => {
  const text = useMemo(() => {
    if (isCurrentBundle) return "Current"
  }, [isCurrentBundle])

  return (
    <>
      {isCurrentBundle && (
        <Alert
          icon={
            <CheckCircleIcon
              fontSize="small"
              sx={{
                color: "success.main",
              }}
            />
          }
          severity={"success"}
          sx={{
            fontWeight: 500,
            color: "success.main",
            alignItems: "center",
            height: "56px",
          }}
        >
          {text}
        </Alert>
      )}
    </>
  )
}

const Alacarte: FC<{
  alacarte: IAlacarteInfo
  selectedBundle: string
  isCurrentBundle: boolean
  currency: string
  bundleList: IBundleInfo[]
}> = ({ alacarte, selectedBundle, currency, isCurrentBundle, bundleList }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )
  return (
    <Grid
      item
      sx={{
        width: "268px",
        border: "1px solid rgba(0, 0, 0, 0.56)",
        borderRadius: "4px",
        height: "fit-content",
        my: 4,
      }}
    >
      <AlecarteTitleAlert isCurrentBundle={isCurrentBundle} />
      <Box
        component={"div"}
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <UText
          variant={"h5"}
          color={"rgba(96, 125, 139, 1)"}
          sxProp={{ fontWeight: "500" }}
        >
          {alacarte?.name}
        </UText>
        {!isCurrentBundle && alacarte.sku_name === selectedBundle && (
          <CheckCircleIcon fontSize={"small"} sx={{ color: "success.main" }} />
        )}
      </Box>
      {countryCode && alacarte.sku_name && (
        <Box component={"div"} sx={{ p: 2 }}>
          {alacarte && (
            <Grid sx={{ display: "flex" }}>
              <UText variant={"h3"}>
                {formatCurrency(alacarte?.total_value, currency, countryCode)}
              </UText>
              <UText
                variant={"body2"}
                sxProp={{
                  ml: 1,
                  mt: "10px",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {currency}
                <br /> {t("bundle.type.alacartePrice")}
              </UText>
            </Grid>
          )}
          <Divider sx={{ marginTop: 2 }} />
          <Box>
            {alacarte?.aligner?.item_per_cost ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <UText variant={"caption"} sxProp={{ mt: "8px", mb: "4px" }}>
                  {t("bundle.alacarte.aligners")}
                </UText>
                <UText variant={"caption"} sxProp={{ mt: "8px", mb: "4px" }}>
                  {alacarte.aligner.item_count} x{" "}
                  {formatCurrency(
                    alacarte.aligner.item_per_cost,
                    currency,
                    countryCode,
                  )}
                </UText>
              </Box>
            ) : null}
            {alacarte?.retainer?.item_per_cost ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <UText variant={"caption"} sxProp={{ my: "4px" }}>
                  {t("bundle.alacarte.retainers")}
                </UText>
                <UText variant={"caption"} sxProp={{ my: "4px" }}>
                  {alacarte.retainer.item_count} x{" "}
                  {formatCurrency(
                    alacarte.retainer.item_per_cost,
                    currency,
                    countryCode,
                  )}
                </UText>
              </Box>
            ) : null}
            <Box>
              <UText
                variant={"caption"}
                sxProp={{ pb: "4px", color: "rgba(0, 0, 0, 0.6)" }}
              >
                {bundleList.length === 0 ||
                bundleList.some(
                  (item) =>
                    item.name === "Mild" ||
                    item.name === "Moderate" ||
                    item.name === "Comprehensive" ||
                    item.name === "Finishing",
                )
                  ? t("bundle.initial")
                  : ""}
              </UText>
            </Box>
            {!!alacarte?.custom_packaging?.item_total_value && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <UText variant={"caption"} sxProp={{ my: "4px" }}>
                  {t("bundle.type.customPackaging")}
                </UText>
                <UText variant={"caption"} sxProp={{ my: "4px" }}>
                  {formatCurrency(
                    alacarte.custom_packaging.item_total_value,
                    currency,
                    countryCode,
                  )}
                </UText>
              </Box>
            )}
            <Divider sx={{ mt: "4px", mb: "8px" }} />
            <UText variant={"caption"} sxProp={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {t("bundle.alacarte.shipping_calculated")}
            </UText>
          </Box>
        </Box>
      )}
      {!isCurrentBundle && (
        <UButton
          variant={"contained"}
          btnType={"button"}
          btnText={t("bundle.button.select")}
          sxProp={{ m: 1, width: "252px" }}
          onClickHandler={() => {
            dispatch(updateSelectedBundle(alacarte))
          }}
          disabled={selectedBundle === alacarte.sku_name}
        />
      )}
    </Grid>
  )
}

export default Alacarte
