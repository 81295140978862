import { FC, ReactElement, useEffect } from "react"

import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"
import { canAll } from "../core/utils/access/access"
import { useMyNavigation } from "../hooks/useMyNavigation"

interface AuthorizeRouteProps {
  component: ReactElement
  allowedPermissions: string | string[]
}

const AuthorizeRoute: FC<AuthorizeRouteProps> = ({
  component,
  allowedPermissions,
}) => {
  const navigate = useMyNavigation()
  const { user } = useAppSelector((state: RootState) => state.userService)

  const isAuthorized = canAll(user?.app_permissions || [])(allowedPermissions)

  useEffect(() => {
    if (!isAuthorized) {
      navigate(user?.isAllowToViewAllOrg ? "/organization" : "/patients")
    }
  }, [isAuthorized, navigate])

  if (!isAuthorized) {
    return null // Or a fallback component
  }

  return component
}

export default AuthorizeRoute
