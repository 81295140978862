import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormAutoComplete, FormInput } from "../../components"
import { Box, Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import {
  fetchCountryList,
  fetchStateLists,
} from "../../core/app/slices/user/userApis"
import { RootState } from "../../core/app/store"
import { UButton } from "../../ui-component/components"

export const AddAddressForm: FC<{
  setOpenAddressForm: (val: boolean) => void
}> = ({ setOpenAddressForm }) => {
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    handleSubmit,
  } = useForm<any>({ mode: "onSubmit" })
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [selectedState, setSelectedState] = useState<string>("")
  const { countries, states } = useAppSelector(
    (state: RootState) => state.userService,
  )

  const onSubmit: SubmitHandler<any> = async (data) => {
    const payload = {
      name: data.businessName,
      line_1: data.line_1,
      line_2: data.line_2 || "",
      city: data.city,
      country: data.country_name,
      region: data.region_name,
      zip_code: data.zip_code,
      phone: data.phone || null,
    }

    console.log(payload, "...payloadd")
  }

  useEffect(() => {
    if (!countries?.length) dispatch(fetchCountryList())
  }, [])

  return (
    <Grid container direction={"column"}>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ px: 4, pb: 4, pt: 1 }}>
          <FormInput
            formSxprops={{ mt: 1 }}
            inputLabel={t("uLabAccount.form.field.businessName")}
            fieldName={"businessName"}
            rules={{
              required: "uLabAccount.form.error.businessName",
            }}
            errors={errors}
            control={control}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.address")}
            fieldName={"line_1"}
            fieldType={"text"}
            rules={{
              required: "uLabAccount.form.error.address",
            }}
            errors={errors}
            control={control}
            formSxprops={{ mt: 2 }}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.line2")}
            fieldName={"line_2"}
            fieldType={"text"}
            rules={{}}
            errors={errors}
            control={control}
            formSxprops={{ mt: 2 }}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.city")}
            fieldName={"city"}
            fieldType={"text"}
            rules={{ required: "uLabAccount.form.error.city" }}
            errors={errors}
            control={control}
            formSxprops={{ mt: 2 }}
          />
          <FormAutoComplete
            inputLabel={t("uLabAccount.form.field.state")}
            fieldName={"region_name"}
            isInputLabel={true}
            rules={{
              required: "uLabAccount.form.error.state",
            }}
            options={states}
            errors={errors}
            callback={(e) => {
              if (e) {
                setValue("region_name", e.label)
                setSelectedState(e.label)
              }
              trigger("region_name")
            }}
            control={control}
            defaultValue={selectedState}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.zip")}
            fieldName={"zip_code"}
            fieldType={"text"}
            rules={{ required: "uLabAccount.form.error.zip" }}
            errors={errors}
            control={control}
            formSxprops={{ my: 0 }}
          />
          <FormAutoComplete
            formSxprops={{ mt: 2, mb: 1 }}
            inputLabel={t("uLabAccount.form.field.country")}
            fieldName={"country_name"}
            isInputLabel={true}
            rules={{
              required: "uLabAccount.form.error.country",
            }}
            options={countries}
            errors={errors}
            callback={(e) => {
              setValue("country_name", e.label)
              setValue("region_name", "")
              setSelectedState(null)
              const countryItem = countries.find(
                (item) => item.label === e.label,
              )
              countryItem &&
                dispatch(fetchStateLists({ countryid: countryItem.id }))
              trigger("country_name")
            }}
            control={control}
          />
          <FormInput
            formSxprops={{ mt: 1 }}
            fieldName={"phone"}
            inputLabel={t("uLabAccount.form.field.phone")}
            rules={{}}
            errors={errors}
            control={control}
          />
        </Box>
        <Grid
          container
          justifyContent={"flex-end"}
          pb={3}
          pt={2}
          px={3}
          gap={3}
          sx={{ backgroundColor: "divider" }}
        >
          <Grid item>
            <UButton
              sxProp={{ width: 69, height: "36px" }}
              variant={"text"}
              btnType={"button"}
              onClickHandler={() => setOpenAddressForm(false)}
              btnText={t("button.cancel")}
            />
          </Grid>
          <Grid item>
            <UButton
              sxProp={{ width: 69, height: "36px" }}
              variant={"contained"}
              btnType={"submit"}
              btnText={t("button.save")}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
