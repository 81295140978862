import { initReactI18next } from "react-i18next"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"

const supportedLngs = ["en"]
const fallbackLng = "en"

const languageConfig = i18next.createInstance()

languageConfig
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      //	transWrapTextNodes: "span",
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        cache: "no-cache",
      },
    },
    lng: "en",
    defaultNS: "common",
    fallbackLng,
    ns: ["common"],
    debug: false,
    supportedLngs,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default languageConfig
