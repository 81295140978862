import { ESetupType, PanelDataProps } from "./common/commontypes"

import { EToothDirection } from "./modules/toothmanager/toothdirection"
import {
  IAttachmentIPRVisible,
  IAuxullartiesShowInfo,
  ReportAttachmentData,
  ReportAttachmentFirstStageData,
  ReportAttachmentToothListData,
} from "./modules/attachment/attachmentModule"
import {
  CaseZipType,
  ICaseExtInfo,
  ICaseExtraAttr,
  ZipFileName,
  ZipFileList,
} from "./common/types"
import { ReportIPRData } from "./modules/ipr/iprModule"
import { TypeInNumber, IToothProperty } from "./modules/toothmanager"
import { IWASMBoltonReport } from "./modules/bolton"
import { EArchTreatmentType } from "./modules/preview/preview"
import { JointStageData } from "./modules/stagingdatamanager/stagingmanager"

export enum EBiteJumpLocationType {
  EBiteJumpLocation_Off = 0,
  EBiteJumpLocation_Start = 1,
  EBiteJumpLocation_All = 2,
  EBiteJumpLocation_End = 3,
}

export interface IPresetupOptions {
  upperArch: Blob
  lowerArch: Blob
  canvas: HTMLCanvasElement
  donecallback: () => void
}

export interface HoverToothData {
  isShow: boolean
  toothid: number
  isUpper: boolean
  position: [number, number]
  hoverString: string
  properties: {
    isCrown: boolean
    isImplant: boolean
    isBridge: boolean
    isPontic: boolean
    isPrimary: boolean
    isErupting: boolean
  }
}

/**
 * Case life management interface
 */
export interface ICaseManagement {
  initWasm(canvas:HTMLCanvasElement,callback?:()=>void)
  /**
   * open Case Or Switch Canvas
   * @param canvas
   * @param zipFiles
   * @param callback
   */
  openCaseOrSwitchCanvas(
    canvas: HTMLCanvasElement,
    zipFiles: Record<string, Blob> | File | string,
    callback: () => void,
  )

  /**
   * open case from patient list
   * zipFiles: the casedata files :
   *             {
   *                'bulk1.zip': zip File,
   *                'bulk2.zip': zip File，
   *                'bulk3.zip': zip File
   *                'bulk4.zip': zip File
   *                'bulk10.zip': zip File
   *             }
   * canvas: HTMLCanvasElement
   * initFinishedCallback: ()=>void
   */
  openCase: (
    canvas: HTMLCanvasElement,
    zipFiles: Record<string, Blob>,
  ) => Promise<void>
  
  openEmptyCase: (
    canvas: HTMLCanvasElement,
  ) => Promise<void>

  /**
   * open case from local zip
   * @param canvas HTMLCanvasElement
   * zipfile: local zip file
   * @returns
   */
  openCaseFromZip: (canvas: HTMLCanvasElement, zipfile: File) => Promise<void>
  /**
   * open case from local zip
   * @param canvas HTMLCanvasElement
   * @param fileUrl zip file url relate to public dir
   * @returns
   */
  openCaseFromLocalZipFileUrl: (
    canvas: HTMLCanvasElement,
    fileUrl: string,
  ) => Promise<void>

  /**
   * set callback for open case Finished
   * @param callback
   */
  setCallbackAfterOpencase: (callback: () => void) => void

  /**
   * when treatment model need enable some modules for initialization
   * @returns
   */
  enableTreatmentModules: () => void

  /**
   * close when return back from treatment page.
   */
  closeCase: () => void

  /**
   * Is case is finish setup
   * @returns
   */
  isCaseInit: () => boolean

  /**
   * do setup
   * @returns
   */
  setupCase: () => void
  /**
   * do presetup
   */
  presetupCase: (presetupOps: IPresetupOptions) => void
  /**
   * redo presetup
   */
  redoPresetup: (canvas: HTMLCanvasElement, donecallback: () => void) => void

  /**
   * get Case Extra Infomation
   * @returns
   */
  getCaseExtraInfomation: () => ICaseExtraAttr

  /**
   * whole process of presetup is placed in the cloud computing, and get a result zip package
   * @param upper Whether to calculate the upper jaw
   * @param lower Whether to calculate the lower jaw
   * @param orientation Whether to calculate orientation
   * @param caseId caseid
   * @returns
   */
  requestCloudPresetup: (
    upper: boolean,
    lower: boolean,
    orientation: boolean,
    orgId,
    patientId,
    caseId: number,
  ) => Promise<boolean>
  /**
   * 获取wasm中保存的扫描照片
   * @returns
   */
  getScansPhoto: () => Map<string, File>
  /**
   * 获取wasm中InitialFinal截图
   * @returns
   */
  getInitialFinalScreenshot: () => Map<string, File>
  /**
   *  get current case data
   *  if fileName is undefined  then return all files
   *  return file is like :
   *  {
   *     'bulk0.zip': zip File
   *     'bulk2.zip': zip File
   *     'bulk3.zip': zip File
   *     ....
   *  }
   *
   *  else
   *  return a specified file
   *
   * @param fileName it can be undifined,then get the all files.
   *                 it can be a ZipFileName  as 'bulk0' | 'bulk1' | 'bulk2' | 'bulk10' | 'raw' | 'photo'
   *                 it can be a ZipFileList as [ZipFileName] | [ZipFileName,ZipFileName] ...
   *
   */
  getCaseFiles: (
    fileName?: ZipFileName | ZipFileList,
  ) => Promise<Record<string, File>>

  /**
   * save json data to zip
   * @param data jsondata
   * @param fileName fimename
   * @param url
   * @returns
   */
  saveJsonDataToZip: (
    data: string,
    fileName: string,
    path: CaseZipType,
  ) => Promise<boolean>

  /**
   * save photo to zip
   * @param photo
   * @param fileName fimename
   * @returns
   */
  savePhotoToZip: (photo: File, fileName: string) => Promise<boolean>

  /**
   * read all photos from zip
   * @param photo
   * @param fileName fimename
   * @returns
   */
  getPhotoListFromZip: () => string[]

  /**
   * read all photos from zip
   * @param photo
   * @param fileName fimename
   * @returns
   */
  getPhotoFromZip: (fileName: string) => Promise<false | File>

  /**
   *
   * @param stl: STL file
   * @param isUpper: upper or lower
   * @returns
   */
  saveStlToZip: (stl: File, isUpper: boolean) => Promise<boolean>

  /**
   * get AttachmentReport and IPRReport
   * @param val
   * @returns
   */
  getAttachmentIPRReport: () => {
    attachment: ReportAttachmentData
    /**
     * full upper tooth ID is 1-16 and lower is 17-32,if it's not exist,it's a missing tooth.
     */
    toothlist: ReportAttachmentToothListData
    firstStage: ReportAttachmentFirstStageData
    ipr: ReportIPRData
  }

  /**
   * get AttachmentVisible and IPRVisible
   * @returns
   */
  getAttachmentIPRData: () => IAttachmentIPRVisible
  /**
   * get reports for tooth movement table
   * @param isUpper
   * @returns
   */
  getToothReports: (isUpper: boolean) => Map<number, any>

  /**
   * get bolton report data
   */
  getBoltonReport: () => IWASMBoltonReport

  /**
   * get Case Setup Type
   * @returns
   */
  getCaseSetupType: () => ESetupType

  /**
   * set Case Setup Type
   * @returns
   */
  setCaseSetupType: (type: ESetupType) => void
  /**
   * Complete Attachments Automatically
   * @param callback
   * @returns
   */
  setcalsetupfinishCallback: (callback: () => void) => void

  //------------------ Treatment plan ---------------------

  /**
   * set or get case extra information , it's a json object
   * please confirm that the caseExtInfo has been set before using other treatment-plan interface.
   * @returns CaseExInfo JSON obj
   */
  setCaseExtInfo: (caseExInfoJson: ICaseExtInfo) => void
  getCaseExtInfo: () => ICaseExtInfo

  /**
   * Get treatment plan name list
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * @returns the name list of treatment plans.
   */
  getTreatmentPlanList: () => string[]

  /**
   * get tx plan data is changed or not;
   * @returns 
   */
  getTxDataChanged: () => boolean

  /**
   * get tx plan data is changed or not;
   * @returns 
   */
  setTxDataChanged: (changed:boolean) => void

  /**
   * add new treatment plan
   * @returns 
   */
  addTreatment: () => boolean

  /**
   * Change to target tx
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * if target tx name equle current tx , nothing's gonna happen .
   * @param txIndex  1 | 2   the txPlan of which treatment to changed into.
   * @returns
   */
  changeTreatment: (txIndex: 1 | 2) => boolean

  /**
   * rename a exist treatment plan
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * @param txIndex: 1 | 2   the txPlan to be rename
   * @param newName new name
   * @returns
   */
  renameTreatment: (txIndex: 1 | 2, newName: string) => boolean

  /**
   * Save current Tx
   * notice: please confirm that the caseExtInfo has been set before using this interface. if not it'll throw a error.
   * @returns
   */
  saveCurrentTx: () => boolean

  /**
   * Del a Treatment Plan
   * @param txIndex
   * @returns
   */
  delTx: (txIndex: 1 | 2) => boolean

  /**
   * set mainpage's background color
   * @param r
   * @param g
   * @param b
   * @returns
   */
  setBackgroundColor: (r: number, g: number, b: number) => void

  /**
   * set background picture,Just pass in the full file name of the image
   * @param url 图片存储在resource/Pictures/下
   * @returns
   */
  setBackgroundPic: (url: string) => void

  /**
   * Setting the gradient background color
   * @param color1 hexadecimal color
   * @param color2 hexadecimal color
   * @returns
   */
  SetBackgroundGradient: (color1: string, color2: string) => void

  /**
   * get the archs is exist
   * @returns
   */
  getHasArchType: () => Promise<{
    hasUpperArch: boolean
    hasLowerArch: boolean
  }>

  /**
   * set on report data update callback
   * @returns
   */
  setOnReportUpdateCB: (callback: (reportsData) => void) => void
}

/**
 *  STLModel Preview of Uploading & Fillinghole
 */
export interface IDrawSTLOps {
  upperArch: File | null
  lowerArch: File | null
  canvas: HTMLCanvasElement
  zoomRange: [number, number]
  fillholeCallback?: (isSuccess: boolean, errmsg: string) => void
}

/**
 *the options of drawRefinementSTL
 */
export interface IDrawRefinementSTLOps {
  upperArch: File | null
  lowerArch: File | null
  zoomRange: [number, number]
}

export interface IPreView {
  drawSTL2: (drawSTLOps: IDrawSTLOps) => Promise<void>
  /**
   *  draw STL when upload file
   * @param drawSTLOps
   * @returns  this function do fill hole and draw 3DModel, return a Promise after all.
   */
  drawSTL: (drawSTLOps: IDrawSTLOps) => Promise<void>

  /**
   * fill hole is a preprocessing
   */
  fillHole: (
    fillholeCallback?: (isSuccess: boolean, isUpperFlag: "up" | "low") => void,
  ) => void

  /**
   * draw refinement stl in preview
   * @param drawSTLOps
   * @returns
   */
  drawRefinementSTL: (drawSTLOps: IDrawRefinementSTLOps) => Promise<void>
  /**
   *changeArchMode
   *param name  (1、up.2、low.3、both)
   * */
  changeArchMode: (viewState: "up" | "low" | "both") => void
  /**
   * zoom with scale
   * @param scale
   */
  zoomWithValue: (scale: number) => void
  /**
   * setup zoom callback
   * @param zoomRange  the [min,max] tuple value of the scale
   * @param callback  (val: number) => void)
   */
  setZoomCallback: (callback: (val: number) => void) => void

  /**
   * clear the preview after quit thie prewpage
   */
  clearPreview: () => void

  /**
   * Convert stl to mtc file
   * Called when you click next after uploading the stl
   * @param upperStl
   * @param lowerStl
   */
  saveStlToMtc(upperStl: File | null, lowerStl: File | null)

  /**
   * Returns whether the preview has been initialized
   */
  isInitPreview: () => boolean

  /**
   * Loading mtc files from zips in preView page
   * @param zips  zip lists: `bulk0`,`bulk1` ,`bulk2` ,`bulk10` ,`photo` ,`raw ` ,`storage.version.json.zip`
   * @param zoomRange [min sacle number, max scale number]  [0.25,2.0] is the default
   */
  drawScanMtc(zoomRange: [number, number])

  /**
   * Loading mtc files from zips in preView page
   * @param zips  zip lists: `bulk0`,`bulk1` ,`bulk2` ,`bulk10` ,`photo` ,`raw ` ,`storage.version.json.zip`
   * @param zoomRange [min sacle number, max scale number]  [0.25,2.0] is the default
   */
  drawMtcFromZips(
    zips: Record<string, File>,
    zoomRange: [number, number],
  ): Promise<Map<string, File>>

  /**
   * Loading refinement mtc files from zips in preView page
   * @param zips  zip lists: `bulk0`,`bulk1` ,`bulk2` ,`bulk10` ,`photo` ,`raw ` ,`storage.version.json.zip`
   * @param zoomRange [min sacle number, max scale number]  [0.25,2.0] is the default
   */
  drawRefinementMtcFromZips(
    zips: Record<string, File>,
    zoomRange: [number, number],
  ): Promise<Map<string, File>>

  /**
   * 初始化 scan / preview view
   * @param canvas
   * @param zips
   */
  initScanView: (
    canvas: HTMLCanvasElement,
    zips: Record<string, File>,
  ) => Promise<void>

  /**
   * 初始化refinement view
   * @param canvas
   * @param zips
   */
  initRefinementView: (
    canvas: HTMLCanvasElement,
    zips: Record<string, File>,
  ) => Promise<void>

  /**
   * just using in glue layer
   * @param canvas
   * @param zip
   * @returns
   */
  testInitRefinementView: (
    canvas: HTMLCanvasElement,
    zip: File,
  ) => Promise<void>

  /**
   * save upper or lower archType
   * @param upper
   * @param lower
   * @returns
   */
  saveArchType: (
    upperType: EArchTreatmentType | null,
    lowerType: EArchTreatmentType | null,
  ) => void

  /**
   * Storing the start stage for doing a refinement,
   * '-1010' means no refinement for upper or lower.
   * @param upperStage
   * @param lowerState
   * @returns
   */
  saveDoRefinementStage: (
    upperStage: number,
    lowerState: number,
  ) => Promise<boolean>
}

/**
 * Interfaces of Stage controling
 */
export interface IStageControl {
  /**
   * get wasm stage data
   * @returns
   */
  getWasmStageData: () => JointStageData
  /**
   * set stage value from UI
   * @param upstep
   * @param lowstep
   */
  setStageStep: (upstep: number, lowstep: number) => void

  /**
   * give notice to UI when current stage number change
   * @param arch
   * @param index
   */
  setStageCallback: (callback: (wasmStageData) => void) => void
  /**
   * give notice to UI when Setup Type
   * @param callback
   * @returns
   */
  setSetupTypeCaseCallback: (
    callback: (type: ESetupType, ndoeIndex: number) => void,
  ) => void

  /**
   * get count of KeyPoints ,return which is larger between upper and lower.
   * if the max number is 8,It means there are 9 steps
   * @returns
   */
  getMaxStageNodeIndex: () => number

  /**
   * get the current stage index
   * @returns
   */
  getCurrentStageIndex: () => { upper: number; lower: number }

  /**
   * switch step between Initial and current
   */
  switchIC: () => void

  /**
   * set BiteJump Type
   * @param biteJumpType
   * @returns
   */
  setBiteJumpType: (biteJumpType: EBiteJumpLocationType) => void

  /**
   * 获取下颌是否做过bite jump
   * @returns
   */
  isDownArchBiteMoved: () => boolean

  /**
   * play GM animation
   * @returns
   */
  playGMAnimation: () => number

  /**
   * set biteJump type and play GM
   * @param biteJumpType
   * @returns
   */
  setBiteJumpTypePlayGM: (biteJumpType: EBiteJumpLocationType) => void

  /**
   * get biteJump type
   * @returns
   */
  getBiteJumpType: () => number
}

/**
 * Interfaces of Initial-final
 */
export interface IInitialFinal {
  /**
   * 打开或者关闭initialfinal窗口
   * @param isEnable
   */
  setInitialFinalEnabled: (isEnabled: boolean) => void

  /**
   * 前端传入的zoom缩放值
   * @param scaleFac
   * @returns
   */
  zoomByScale: (scaleFac: number) => void

  /**
   * 鼠标滚轮滑动时回调给前端当前的zoom缩放值(每次重新打开initialfinal模块都需要调用)
   * @param callback
   * @returns
   */
  setZoomInitialFinalCallback: (callback: (value: number) => void) => void

  /**
   * 当打开initialfinal窗口后再点击occlusal按钮后调用
   * @returns
   */
  openOcclusalView: () => void
}

/**
 * Interfaces of Treatment
 */
export interface ITreatmentViewportControl {
  /**
   * setZoomCallbackInTreatment
   * it will be invoked on mouse wheel change
   * @param callback (scalevalue: number) => void
   * @returns
   */
  setZoomCallbackInTreatment: (callback: (value: number) => void) => void

  /**
   * zoomWithValueInTreatment
   * @param val  the scale value.default is 1.0
   * @returns
   */
  zoomWithValueInTreatment: (val: number) => void

  /**
   * show upper arch or lower
   * @param mode  'up' | 'low' | 'all'
   * @returns
   */
  showArchMode: (mode: "up" | "low" | "all") => void

  /**
   * set the view type
   * @param type 'left' | 'right' | 'front'|'back' | 'split'
   * @returns
   */
  setViewType: (type: "left" | "right" | "front" | "back" | "split") => void

  /**
   * set attachment is visibility?
   * @param isVisible boolean
   * @returns
   */
  setAttachmentVisibility: (isVisible: boolean) => void

  /**
   * draw auxullarties animation in a canvas
   * @param canvasElement canvas element
   * @param auxinfoList auxinfo include the info of aux and the element where to draw it. 
   *  interface IAuxullartiesShowInfo {
   *    //  aux type
        type: "Normal" | "PressurePoint" | "ButtonAndSlits";
        //  [auxId,the dom element where we draw the aux]
        elements: [string, HTMLElement][];
      }
      因为canvas.style.transform属性默认会跟随canvasElement的scrollTop属性，以跟随滑动，
      且canvas.stype.position = 'absolute'
      canvasElement的position属性，必须为'absolute'或者'relative',否则跟随会有问题。
      另外canvas.stype.z-index= '1' ，注意图层排序。

   * @returns
   */
  drawAuxullarties: (
    canvasElement: HTMLElement,
    auxinfoList: IAuxullartiesShowInfo[],
  ) => void

  /**
   * set IPR is visibile
   * @param val
   * @returns
   */
  setIPRVisibility: (val: boolean, stageIndexForShow: number) => void

  /**
   * set Grid is visibile
   * @param val
   * @returns
   */
  setGridVisibility: (isVisible: boolean) => void

  /**
   * set Super-impose Stage
   * @param val
   * @returns
   */
  setSuperimposeStage: (isUpper: boolean, step: number) => void

  /**
   * set Super-impose visibility
   * @param isVisible
   * @returns
   */
  setSuperimposeVisibility: (isVisible: boolean) => void

  /**
   * set Super-impose Opacity
   * @param isVisible
   * @returns
   */
  setSuperimposeOpacity: (opacityVal: number) => void

  /**
   * set Occlusion is Visible
   * @param isVisible
   */
  setOcclusionVisibility: (isVisible: boolean) => any

  /**
   * set space check is Visible
   * @param isVisible
   */
  setSpacecheckVisibility: (isVisible: boolean) => any

  /**
   * set Overlay is Visible
   * @param isVisible
   * @returns
   */
  setOverlayVisibility: (isVisible: boolean) => void

  /**
   * set Overlay opacity
   * @param opacity  from 0 to 1
   * @returns
   */
  setOverlayOpacity: (opacity: number) => void

  specialToothColorToggle: (isEnable: boolean) => void

  /**
   * 设置 Dome/Tooth display switch
   * if toothId is 0, it means user picked on dome without tooth
   */
  setDomeDisplayCallback: (
    cb: (
      isVisible: boolean,
      toothID: number,
      x: number,
      y: number,
      isRight: boolean,
      isUpper: boolean,
    ) => void,
  ) => void

  /**
   * 调用Dome Sync button 功能
   * 在最后一步，第一步，移动dome后，正常播放dome会动。点击这个按钮后，再播放，dome位置不变。
   * @returns
   */
  makeDomeStatic: () => void

  /**
   * to change between Picking demo or picking tooth
   * @param isPickDome
   */
  changePickDomeMode: (isPickDome: boolean) => void

  /* hover tooth 开关
   * @param isEnable
   * @returns
   */
  hoverToothToggle: (isEnable: boolean) => void

  /**
   * 鼠标悬浮在牙齿上返回牙齿的相关信息
   * @param callback
   * @returns
   */
  setHoverToothCallback: (callback: (hoverData: HoverToothData) => void) => void
}

/**
 * Interfaces of history actions
 */
export interface Ihistory {
  /**
   * undo one step
   * @returns
   */
  undo: () => boolean

  /**
   * redo one step
   * @returns
   */
  redo: () => boolean

  /**
   * clear all steps
   * @returns
   */
  clearAll: () => boolean
}

export interface IRefinement {
  /**
   * open history display module
   * @param isOpen
   */
  openRefinementModule: (isOpen: boolean) => void

  /**
   * set Refinement Display Range
   * @param displayRange (all/original/refinement1/refinement2/...)
   * @returns
   */
  setRefinementDisplayRange: (displayRange: string) => boolean

  /**
   * get Refinement Info
   * @param archTpye
   * @returns
   */
  getRefinementInfo: (archTpye: number) => string[]

  /**
   * change Display Stage Value
   */
  changeDisplayStageValue: (index: number, archTpye: number) => void

  /**
   * get Number Of Refinement
   * @param archTpye
   * @returns
   */
  getNumberOfRefinement: (archTpye: number) => number

  /**
   * undo refinement
   * @param archTpye
   * @returns
   */
  undoRefinement: (archTpye: number) => void
}

export interface IToothModule {
  /**
   * 查询牙齿属性
   * export interface IToothProperty {
      isCrown: boolean;
      isImplant: boolean;
      isBridge: boolean;
      isPontic: boolean;
      isExtract: boolean;
      isErupting: boolean;
      isPrimary: boolean;
    }
   * @returns
   */
  getToothProperties: (toothId: number) => IToothProperty | null
  /**
   * 获取已有牙齿牙号列表（不包括missing,包括其他特殊牙齿和拔牙）
   * @returns
   */
  getToothList: () => { uplist: number[]; lowlist: number[] }

  /**
   * 通过牙齿ID 获取Primary牙齿标识(a,b,c,d,e),
   * 前提这颗牙应该是Primiry牙齿，ID范围是5XX，否则返回 undefined
   * @param toothId
   * @returns
   */
  getPrimaryToothMark: (
    toothId: number,
  ) => "a" | "b" | "c" | "d" | "e" | undefined
}

/**
 * 待修改，目前定义较难理解，把计算流程给暴露出来让前端统一来串
 * 只定义三个接口 设置callback ,调预计算判断是否弹框接口，计算接口。
 */
export interface IToothMovement {
  /**
   * 当移牙做了PA(passive aligner) 或者 IPR 需要提示用户是否除去PA和IPR
   * 回调函数的参数: (hasIPR:0|1,hasPA:0|1)=>void
   */
  setIsExistIPROrPAPromptCallback: (
    callback: (hasIPR: 0 | 1, hasPA: 0 | 1) => void,
  ) => void

  /**
   * 用户选择确认或取消需要调用此接口通知WASM,
   * 确认则删除PA和IPR，取消则什么都不做。
   * @param confirmOrCancel
   * @returns
   */
  dealWithExistIprOrPAPrompt: (comfirmOrCancel: boolean) => void
  /**
   *  关闭/打开移牙功能
   * @param isEnabled
   * @returns
   */
  setIsEnableMoveTooth: (isEnabled: boolean) => void
  /**
   *  获取当前选中牙齿是否为锁定状态
   * @returns true:锁定 false:未锁定
   */
  getToothLockState: () => boolean
  /**
   *  锁定当前状态的牙齿move功能
   * @param isEnabled
   * @returns
   */
  lockMoveTooth: (isLock: boolean) => void
  /**
   *  获取当前是否可以移牙
   * @param callback  (isAllow:boolean)=>void
   * @returns
   */
  getIsAllowMoveTooth:  (callback: () => void) => void
  /**
   *  显示/隐藏Superimpose功能
   * @param isEnabled
   * @returns
   */
  showSingleSuperImpose: (isEnabled: boolean) => void
  /**
   * reset tooth movement
   * @returns 
   */
  resetToothMovement: () => void
  /**
   *  拔掉当前选中牙齿/恢复拔掉的牙齿
   * @param id：恢复的牙齿id,不传参：拔掉选中的牙齿
   * @returns
   */
  pulloutATooth:(id?: number) => void
  /**
   * 获取已拔掉的牙齿的id list
   * @returns toothid list
   */
  getExtractedToothList: () => number[]
  /**
   * 如果有选中牙齿，取消选中
   * @returns
   */
  cancelPickTooth: () => void
  /**
   * 点击Setup面板中控制牙齿移动的按钮
   * Click the toothmovement buttons in setup panel
   * @param dir
   * @param quick
   * @returns
   */
  disPatchButton: (dir: EToothDirection, quick: boolean) => void
  /**
   *  set callback for moving molar.
   *  点击 molar 牙齿的回调
   */
  setCallbackForMovingMolar: (callback: () => void) => void

  /**
   * set callback which invoke on tooth moving out of range.
   * 设置移动牙齿超出范围后的回调
   * @param callback
   */
  setCallbackOutOfRange: (callback: () => void) => void

  /**
   * set callback when modify the tooth  show modifybutton or not
   *  点击牙齿是否显示 ModificationCompleted 按钮的回调
   */
  setCallbackModifyTooth: (callback: (isShow: boolean) => void) => void

  /**
   *  ModificationCompleted clicked
   *
   * ModificationCompleted 按钮点击之后调用
   * 点击modificationCompleted后调用此方法
   * 此方法先预计算，判断当前case是否是simple-case,并且modify后会变成mediumcase.
   * 如果是则会调用回调‘ifSimpleCaseChangeToMediumCaseCallback’ 通知前端弹框提示
   * 如果不是则直接走 Modifycation计算。
   * 计算完成后会调用回调 finishedCallback
   *
   * if user select no then use the algorithm which keep case is simple ,else use the one which change case to medium.
   *
   * param finishedCallback
   *       ifSimpleCaseChangeToMediumCaseCallback
   */
  modifyButtonClicked: (callback: () => void, popupCallback: () => void) => void

  /**
   *  modification commpleted
   * modificationCompleted 过程完成后设置的回调
   */

  // modificationCompleted: (callback: () => void) => void;
  /**
   * 用户点击NO后调用的计算简单的牙齿方案
   */
  modificationSchemeSimple: () => void

  /**
   * 用户点击YES后调用的中等的牙齿方案
   */
  modificationSchemeSecondary: () => void

  /**
   * 设置不允许牙齿移动的回调窗口
   /
   */
  setCallbackCannotMoveTooth: (callback: () => void) => void

  /**
   *  设置 显示 toothmovement信息的回调窗口
   */
  setCallbackShowToothInfo: (
    callback: (panelDataP: PanelDataProps) => void,
  ) => void

  /**
   * 设置是否可以移牙
   * bReadonly :true  不可移  false :可以移
   */
  setToothReadonly: (bReadonly: boolean) => void

  /**
   * 设置移牙panel的root切换
   * @param isRoot
   * @returns
   */
  setRootControl: (isRoot: boolean) => void

  /**
   * 根据面板输入值改变牙齿movement
   * @param val 输入框中的值
   * @param dir 输入框代表的牙齿方向
   * @returns
   */
  moveWithInputValue: (val: number, dir: TypeInNumber) => boolean

  switchModuleFastSmartCircle: (onOff: boolean) => void
  switchModuleFineTuneCircle: (onOff: boolean) => void
  /**
   * 关闭ArchAdjustment
   * @returns
   */
  closeArchAdjustment: () => void
}

export interface IViewEdit {
  /**
   * view <-> edit switch
   * @param isToEdit
   * @returns void
   */
  switchViewToEdit: (isEdit: boolean) => void
}

export interface IOrderView {
  /**
   * 进入aligner coverage页面需要调用此方法初始化窗口
   * @returns
   */
  initAlignerCoverageViewInOrderPage: (
    bgcolor: [number, number, number],
  ) => void
  /**
   * 初始化和清理order选牙3D窗口
   * @param bgcolor 3D窗口背景颜色
   * @returns
   */
  initAndClearOrderView: (bgcolor: [number, number, number]) => void

  /**
   * 设置选中牙齿的颜色
   * @param toothSelectedColor 选中牙齿颜色
   * @returns
   */
  setTeethSelectedColor: (toothSelectedColor: [number, number, number]) => void

  /**
   * 设置默认选中的牙齿
   * @param toothIds 牙齿ID （1-32）
   * @returns
   */
  setDefaultTeethSelected: (toothIds: number[]) => void
  /**
   * 获取List of 选中的牙齿ID
   * @returns
   */
  getTeethSelected: () => number[]

  // /**
  //  * 保存Scan Buttion 选择牙齿
  //  * @param toothIds
  //  * @returns
  //  */
  // saveScanButtonsInfoToElasticJson: (toothIds: number[]) => void;

  /**
   * 获取Elastic 附件 json信息,返回的是json的字符串内容
   * @param teethIdArray scanbutton中选中的牙齿ID列表[1-32]
   */
  getElasticJson: (teethIdArray: number[]) => string
}

export interface IBiteRamp {
  /**
   * 打开/关闭BiteRamp界面功能
   * @param enable
   * @returns
   */
  setOperationEnable: (enable: boolean) => void

  /**
   * 对6-11号牙齿添加biteramp
   * @param toothID 6-11
   * @returns 添加失败会返回false
   */
  autoAddBiteRampToTooth: (toothID: number) => boolean

  /**
   * 取消打勾，删除牙齿上的bite ramp
   * @param toothID 6-11
   * @returns
   */
  deleteBiteRamp: (toothID: number) => void

  /**
   * 获取牙齿是否添加了biteramp
   * @param toothid 6-11
   * @returns
   */
  isToothHasBiteRamp: (toothid: number) => boolean

  /**
   * Get whether the upper or lower jaw has added a biteramp
   * @param isUpper
   * @returns
   */
  isHaveBiteRampOnArch: (isUpper: boolean) => boolean

  /**
   * 设置biteramp开始显示的stage
   * @param startStage startStage必须小于endStage
   * @returns
   */
  changeStartStage: (startStage: number) => void

  /**
   * 设置biteramp结束显示的stage
   * @param endStage
   * @returns startStage必须小于endStage
   */
  changeEndStage: (endStage: number) => void

  /**
   * 获取biteramp开始显示的stage
   * @returns
   */
  getStartStage: () => number
  /**
   * 获取biteramp结束显示的stage
   * @returns
   */
  getEndStage: () => number

  /**
   * 设置biteramp的显示/隐藏
   * @param bVisiable
   * @returns
   */
  setBiteRampVisiable: (bVisiable: boolean) => void

  /**
   * 同步牙齿高度到面板
   * @returns
   */
  syncBiteRampHeightToPlane: () => void

  /**
   * 显示/隐藏一个三颗牙齿的下颌,默认隐藏
   * @param visible
   * @returns
   */
  onShowDownTeethIn3to3Range: (visible: boolean) => void

  /**
   * 绑定按钮显示位置回调
   * @param callback  x mines left , y mines bottom in css
   * @returns
   */
  onButtonPosChangeCallback: (
    callback: (x: number, y: number, isShow: boolean) => void,
  ) => void
}

export interface ITreatView {
  /**
   * 
   */
  Open: (onOff:boolean) => void
  /**
   * compare for txs, start compare
   */
  StartComparison: (leftTreatName: string, rightTreatName: string) => boolean
  /**
     * compare for txs, get compare nodes
     */
  GetNodes: (viewId:number, archIndex:number) => any
  /**
   * compare for txs, start compare
   */
  PlayFrame: (viewId:number, stageIndex:number) => void
}