import { FC, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react"

import { ULoading } from "../components"
import { useAppDispatch } from "../core/app/hooks"
import { getPermissions } from "../core/app/slices/user/userApis"
import { useMyNavigation } from "../hooks/useMyNavigation"

const PermissionRoute: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPermissions()).then(() => {
      setIsLoading(false)
    })
  }, [])

  return isLoading ? <ULoading isLoading={true} /> : <Outlet />
}

export default PermissionRoute
