import React from 'react';
import { FC, useEffect, useState, useRef } from "react"
import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import TriangleIcon from './triangleIcon';
import StageAddIcon from './stageAddIcon';
import IprIcon from './iprIcon';

const StageFoldItem = (props) => {

  const topAreaHeight = 12
  const addMargin = 3
  const triangleMargin = 2
  const triangleHeight = 5

  return (
    <div style={{
      width: props.actualStageWidth,
      height: (topAreaHeight + addMargin + props.singleStageHeight + triangleMargin + triangleHeight),
      position: "relative",
      // backgroundColor: "red"
    }}
    onClick={() => props.stageButtonClickHandle(props.item, props.index, props.itemArray)}>

      {props.archType === 1 ? <TriangleIcon position={"absolute"} left={props.actualStageWidth/2} top={0} direction="down" visibility={props.index === props.stageCurrentStep ? "" : "hidden"} /> : <></>}
      {props.archType === 0 ? <StageAddIcon position={"absolute"} left={props.actualStageWidth/2-1} top={0}  status={props.isNodeContains(props.item.nodeTypes, 0)? "del":"add"} visibility={props.index === props.stageCurrentStep ? "" : "hidden"} onClick={props.addNodeHandler}/> : <></>}

      <div style={{
        width: props.actualStageWidth,
        height: "1px",
        position: "absolute",
        left: "0px",
        top: props.archType === 0 ? (topAreaHeight + addMargin + props.singleStageHeight / 2 - props.scaleHeight/2) : (triangleHeight + triangleMargin + props.singleStageHeight / 2 + props.scaleHeight/2),
        backgroundColor: "#919191",
      }}>
      </div>

      <div style={{
        width: "1px",
        height: props.scaleHeight,
        position: "absolute",
        left: props.actualStageWidth / 2 - 1,
        top: props.archType === 0 ? (topAreaHeight + addMargin + props.singleStageHeight / 2 - props.scaleHeight/2 + 1) : (triangleHeight + triangleMargin + props.singleStageHeight / 2 + props.scaleHeight/2 - props.scaleHeight),
        backgroundColor: props.index === props.stageCurrentStep ? "transparent" : "#919191",
      }}>
      </div>

      <div style={{
        width: props.actualStageWidth - 2*2,
        height: props.singleStageHeight,
        // border: "1px solid transparent",
        position: "absolute",
        left: "2px",
        top: props.archType === 0 ? (topAreaHeight + addMargin) : (triangleHeight + triangleMargin),
        borderRadius: "4px",
        backgroundColor: "transparent", //props.index === props.stageCurrentStep ? "#02AFEC" : "transparent",
      }}>

        <Box sx={{
          width: 6,
          height: 6,
          backgroundColor: props.isNodeContains(props.item.nodeTypes, 0) ? "#02AFEC" : "transparent",//props.isNodeContains(props.item.nodeTypes, 0) ? (props.stageCurrentStep === props.index ? "white" : "#02AFEC") : "transparent",
          borderRadius: 3,
          margin: '0 auto',
          position: "absolute",
          left: (props.actualStageWidth - 2*2) / 2 - (6 / 2) - 1,
          top: props.archType === 0 ? 3 : (props.singleStageHeight - 3 - 6)
        }}>
        </Box>

        <div style={{ 
          color: props.isNodeContains(props.item.nodeTypes, 6) ? (props.stageCurrentStep === props.index ? "white" : "#02AFEC") : "transparent",
          userSelect: "none",
          width: 6, 
          height: 12,
          position: "absolute",
          left: (props.actualStageWidth - 2*2) / 2 - (6 / 2),
          top: props.archType === 0 ? 3 : (props.singleStageHeight - 3 - 12),
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          fontSize: 12 }}>
            <b>P</b>
        </div>

        <span style={{
          userSelect: "none",
          width: props.actualStageWidth - 2*2, 
          height: 16, 
          fontSize: 15,
          position: "absolute",
          left: 0,
          bottom: props.archType === 0 ? -2 : (props.singleStageHeight - 2 - 16),
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          color: props.stageCurrentStep === props.index ? "black" : "transparent",
          zIndex: 1000,
          // backgroundColor: "gray"
        }}>{props.item.indexStr}</span>

      </div>

      {props.archType === 0 ? <TriangleIcon position={"absolute"} left={props.actualStageWidth/2} top={topAreaHeight + addMargin + props.singleStageHeight + triangleMargin} direction="up" visibility={props.index === props.stageCurrentStep ? "" : "hidden"} /> : <></>}
      {props.archType === 1 ? <StageAddIcon position={"absolute"} left={props.actualStageWidth/2-1} top={triangleHeight + addMargin + props.singleStageHeight + triangleMargin}  status={props.isNodeContains(props.item.nodeTypes, 0)? "del":"add"} visibility={props.index === props.stageCurrentStep ? "" : "hidden"} onClick={props.addNodeHandler}/> : <></>}

      {/* {props.archType === 0 ? <IprIcon position={"relative"} left={props.stageDiv / 2} top={topAreaHeight + addMargin - 5} direction="up"/> : <IprIcon position={"relative"} left={props.stageDiv / 2} top={triangleHeight + addMargin} direction="down"/>} */}
      
    </div>
  );
};
 
export default StageFoldItem;