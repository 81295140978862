import { FC, ReactNode, useState } from "react"

import { useMyNavigation } from "../../hooks/useMyNavigation"
import { UButton } from "../../ui-component"
import { Alert, AlertTitle, Box } from "../mui.components"

type themeInfo = "success" | "error" | "warning" | "info"
type SystemBanner = {
  bannerInfo: { [key: string]: string }
  isMarketingBanner?: boolean
}

const SystemBannerAlert: FC<{
  children: ReactNode
  closeModel: (val: boolean) => void
  theme: themeInfo
}> = ({ children, closeModel, theme }) => {
  const styleProps = { borderRadius: "0px", padding: "6px, 16px" }

  return (
    <Alert
      onClose={() => {
        closeModel(false)
      }}
      sx={{ ...styleProps }}
      variant="filled"
      severity={theme}
      data-testId="systemalert"
    >
      {children}
    </Alert>
  )
}

const MarketingBannerAlert: FC<{
  children: ReactNode
  closeModel: (val: boolean) => void
}> = ({ children, closeModel }) => {
  const styleProps = {
    borderRadius: "0px",
    padding: "6px, 16px",
    width: "100%",
  }

  return (
    <Alert
      onClose={() => {
        closeModel(false)
      }}
      sx={{
        ...styleProps,
        bgcolor: "black",
        width: "100%",
        ".MuiAlert-message": {
          width: "100%",
        },
      }}
      variant="filled"
      icon={false}
      severity="success"
      data-testId="marktealert"
    >
      {children}
    </Alert>
  )
}

const AlertBody: FC<{
  bannerInfo: { [key: string]: string }
  isMarketingBanner: boolean
}> = ({ bannerInfo, isMarketingBanner }) => {
  const navigate = useMyNavigation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
        }}
      >
        {bannerInfo.title && <AlertTitle>{bannerInfo.title}</AlertTitle>}
        {bannerInfo.description && <Box>{bannerInfo.description}</Box>}
      </Box>
      {isMarketingBanner && bannerInfo.bt_label && bannerInfo.bt_link && (
        <Box>
          <UButton
            sxProp={{ padding: "4px, 10px" }}
            btnType={"button"}
            btnText={bannerInfo.bt_label}
            variant={"contained"}
            size={"small"}
            onClickHandler={() => {
              if (bannerInfo.bt_link === "/UDesignApp") {
                navigate("/UDesignApp")
                document.documentElement.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const AlertBanner: FC<SystemBanner> = ({ bannerInfo, isMarketingBanner }) => {
  const [open, setOpen] = useState<boolean>(true)

  return (
    <>
      {open && isMarketingBanner && (
        <MarketingBannerAlert closeModel={setOpen}>
          <AlertBody
            bannerInfo={bannerInfo}
            isMarketingBanner={isMarketingBanner}
          />
        </MarketingBannerAlert>
      )}
      {open && !isMarketingBanner && (
        <SystemBannerAlert
          closeModel={setOpen}
          theme={bannerInfo.theme || "info"}
        >
          <AlertBody
            bannerInfo={bannerInfo}
            isMarketingBanner={isMarketingBanner}
          />
        </SystemBannerAlert>
      )}
    </>
  )
}

AlertBanner.defaultProps = {
  isMarketingBanner: false,
}

export default AlertBanner
