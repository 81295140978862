import { FC, useEffect, useState } from "react"
import { Actions, JsonSchema } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { UText } from "../../../ui-component"

export const UMultipleInput: FC<{
  path: string
  errors: string
  label: string
  schema: JsonSchema
  data: object
  handleChange: (path: string, value: any) => void
}> = ({ path, label, schema, data, handleChange }) => {
  const [value, setValue] = useState<object>({})
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")
  const [isError, setIsError] = useState<boolean>(true)

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  useEffect(() => {
    setValue(data)
  }, [data])

  useEffect(() => {
    const hasValues = () => {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key) && data[key] && Number(data[key]) !== 0) {
          return false
        }
      }
      return true
    }
    const hasNoData = data && hasValues()
    setIsError(hasNoData)
    if (core?.schema.required?.indexOf(path) === -1) return
    if (hasNoData) {
      updateErrors("This is a required field")
    } else {
      updateErrors("")
    }
  }, [data])

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", my: 1 }}>
        <UText
          variant={"body2"}
          sxProp={{
            maxWidth: 210,
            color:
              errors && core?.validationMode === "ValidateAndShow"
                ? "error.main"
                : "text.secondary",
          }}
        >
          {label}
        </UText>
        <Box>
          {schema &&
            schema?.inputGroup.map((inputField) => (
              <FormControl
                key={inputField.name}
                error={!!(errors && core?.validationMode === "ValidateAndShow")}
                sx={{ mt: 1, display: "flex", flexDirection: "row", pl: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" sx={{ minWidth: "96px" }}>
                    {inputField.name}
                  </Typography>
                  <Select
                    value={value[inputField.name] || "0"}
                    sx={{
                      width: "80px",
                    }}
                    onChange={(event: any) => {
                      const inputValue = event.target.value
                      if (!isNaN(inputValue)) {
                        handleChange(path, {
                          ...value,
                          [inputField.name]: inputValue,
                        })
                      }
                    }}
                  >
                    {inputField.name === "Upper"
                      ? inputField.enum?.map((e, i) => (
                          <MenuItem key={`${e}+${i}`} value={e}>
                            {e}
                          </MenuItem>
                        ))
                      : inputField.enum?.map((e, i) => (
                          <MenuItem key={`${e}+${i}`} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                  </Select>
                </Box>
              </FormControl>
            ))}
          {isError && core?.validationMode === "ValidateAndShow" && (
            <UText
              variant={"caption"}
              sxProp={{ marginLeft: 1, color: "error.main" }}
            >
              {errors}
            </UText>
          )}
        </Box>
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(UMultipleInput)
