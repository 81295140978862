import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"
import { Pagination, PaginationItem } from "@mui/material"

const UTablePaginationAction = ({
  ...UTablePaginationActionsubProps
}: {
  backIconButtonProps: undefined
  nextIconButtonProps: undefined
  className: string
  page: number
  count: number
  rowsPerPage: number
  showLastButton: boolean
  showFirstButton: boolean
  onPageChange: () => void | undefined
}) => {
  const {
    page,
    count,
    rowsPerPage,
    showLastButton,
    showFirstButton,
    onPageChange,
  } = UTablePaginationActionsubProps
  return (
    <Pagination
      count={Math.ceil(count / rowsPerPage) || 1}
      page={page + 1}
      shape="rounded"
      onChange={!!onPageChange && onPageChange}
      showFirstButton={!!showFirstButton && showFirstButton}
      showLastButton={!!showLastButton && showLastButton}
      size="medium"
      siblingCount={2}
      boundaryCount={2}
      renderItem={(item) => (
        <PaginationItem
          slots={{ first: SkipPreviousIcon, last: SkipNextIcon }}
          {...item}
        />
      )}
    />
  )
}

export default UTablePaginationAction
