import { FC, useEffect, useState } from "react"

import { IconButton, InputAdornment } from "../../../components/mui.components"
import { Visibility, VisibilityOff } from "../../../components/mui.icons"

const PasswordIcon: FC<{
  updatePasswordVisible: (val: boolean) => void
  isError: boolean
}> = ({ updatePasswordVisible, isError }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  useEffect(() => {
    updatePasswordVisible(showPassword)
  }, [showPassword, updatePasswordVisible])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const getColor = () => (isError ? "error" : "inherit")

  return (
    <InputAdornment position={"end"}>
      <IconButton
        data-testid={"showPasswordIcon"}
        aria-label={"toggle password visibility"}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge={"end"}
      >
        {showPassword ? (
          <Visibility color={getColor()} fontSize={"small"} />
        ) : (
          <VisibilityOff color={getColor()} fontSize={"small"} />
        )}
      </IconButton>
    </InputAdornment>
  )
}

export default PasswordIcon
