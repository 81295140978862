import { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"

import {
  Avatar,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Box, Toolbar, IconButton, Tooltip } from "@mui/material"
import SendIcon from "@mui/icons-material/SendOutlined"
import SendIconUAssist from "@mui/icons-material/SendToMobileOutlined"

import { RootState } from "@/core/app/store"
import { useAppDispatch, useAppSelector, useDeepEffect } from "@/core/app/hooks"
import moment from "moment-timezone"

import {
  clinicalRequestBasicType,
  messageBody,
} from "@/core/app/slices/clinical/clinical.types"
import {
  getCaseInfoApi,
  getMessageApi,
  postMessageApi,
  postModiApi,
  updateMessageApi,
} from "@/core/app/slices/clinical/clinicalThunkApi"

import { LeftPage } from "../left/LeftPage"
import { updateCurrentOrgId } from "@/core/app/slices/user/userSlice"

export const UDMessage = ({ onClose }) => {
  const scrollRef = useRef(null)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [holdplace, setHoldplace] = useState("")

  const { user_id, isCustomerSupport, my_orgId, current_orgId } =
    useAppSelector((state: RootState) => state.userService.user)

  const { messageList, historyList } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const getCurrentTimeByTimeZoom = (time) => {
    if (!time) return
    const current_time = moment
      .tz(time, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("MM/DD/YY hh:mm A")
    return current_time
  }

  const OneMessage = ({ messageInfo }) => {
    const LeftMessage = () => {
      return (
        <Box sx={{ width: "100%" }} justifyContent={"left"}>
          <Box sx={{ width: "66%", display: "flex" }} justifyContent={"left"}>
            <Stack sx={{ width: "100%" }}>
              <Stack direction={"row"}>
                <Avatar sx={{ bgcolor: "#FFF", width: 32, height: 32 }}>
                  {messageInfo.firstname[0] + " " + messageInfo.lastname[0]}
                </Avatar>

                <Box sx={{ padding: "0 8px" }}>
                  <Box
                    id="message-name"
                    sx={{ fontSize: 14, fontWeight: 400, color: "#FFF" }}
                  >
                    {messageInfo.firstname + " " + messageInfo.lastname}
                    <Box
                      component={"span"}
                      sx={{
                        color: "rgba(255, 255, 255, 0.70)",
                      }}
                    >
                      <Box
                        component={"span"}
                        sx={{
                          margin: "0 8px",
                          color: "rgba(255, 255, 255, 0.70)",
                        }}
                      >
                        |
                      </Box>
                      {messageInfo.role}
                    </Box>
                  </Box>
                  <Box id="message-time" sx={{ fontSize: 12, fontWeight: 400 }}>
                    {getCurrentTimeByTimeZoom(messageInfo.time)}
                  </Box>
                </Box>
              </Stack>
              <Typography
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(238, 238, 238, 1)",
                }}
                variant="body2"
              >
                {messageInfo.message.text}
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ width: "34%" }} />
        </Box>
      )
    }
    const RightMessage = () => {
      return (
        <Box sx={{ width: "100%", display: "flex" }} justifyContent={"right"}>
          <Box sx={{ width: "34%" }} />
          <Box sx={{ width: "66%", display: "flex" }} justifyContent={"right"}>
            <Stack sx={{ width: "100%" }}>
              <Typography
                sx={{ width: "100%", display: "flex" }}
                variant="body2"
                justifyContent={"right"}
              >
                {getCurrentTimeByTimeZoom(messageInfo.time)}
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(33, 150, 243, 0.08)",
                  padding: "16px",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                variant="body2"
              >
                {messageInfo.message.text}
              </Typography>
              {/* <Box sx={{ height: "20px", color: "#215ECD", width: "100%" }}>
                sent
              </Box> */}
            </Stack>
          </Box>
        </Box>
      )
    }
    return messageInfo.role === "UASSIST USER" ? (
      <LeftMessage />
    ) : (
      <RightMessage />
    )
  }

  useEffect(() => {
    dispatch(getMessageApi({ patientId, caseId }))
  }, [])

  useEffect(() => {
    if (messageList.length === 0 && historyList.length === 0) {
      setHoldplace("Write your comments to submit the modification request.")
      return
    }
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [messageList, historyList])

  const PostMeesageBox = () => {
    const [myMsg, setMyMsg] = useState("")
    const onClickSendMsg = () => {
      console.log("Send message: " + myMsg)
      if (!myMsg || myMsg === "") return
      if (isCustomerSupport) {
        dispatch(updateCurrentOrgId(my_orgId))
        setTimeout(() => {
          dispatch(
            postMessageApi({
              patientId,
              caseId,
              txName: "Plan 1",
              content: myMsg,
              cb: () => {
                dispatch(getMessageApi({ patientId, caseId }))
              },
            }),
          )
          setTimeout(() => {
            // switch back to current org Id
            dispatch(updateCurrentOrgId(current_orgId))
          }, 500)
        }, 500)
      } else {
        dispatch(
          postMessageApi({
            patientId,
            caseId,
            txName: "Plan 1",
            content: myMsg,
            cb: () => {
              dispatch(getMessageApi({ patientId, caseId }))
            },
          }),
        )
      }
      setMyMsg("")
      const txName = { name: "Hello", id: "100" }
      dispatch(getMessageApi({ patientId, caseId }))
    }
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Toolbar>
          <TextField
            sx={{ background: "#FFF", width: "100%" }}
            placeholder={"Enter message or modification request here"}
            id="outlined-size-small"
            value={myMsg}
            size="small"
            onChange={(e) => {
              setMyMsg(e.target.value)
            }}
          />
          <Tooltip title="Send message">
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={onClickSendMsg}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Box>
    )
  }

  const MessageListBox = () => {
    return (
      <>
        <Stack
          sx={{ maxHeight: "400px", overflow: "auto", overflowY: "hidden" }}
          direction={"column"}
          position="relative"
        >
          {historyList
            .slice(0)
            .reverse()
            .map((messageInfo, index) => (
              <OneMessage key={index} messageInfo={messageInfo} />
            ))}
          <Divider sx={{ color: "#1E88E5" }}>Last read</Divider>
          {messageList
            .slice(0)
            .reverse()
            .map((messageInfo, index) => (
              <OneMessage key={index} messageInfo={messageInfo} />
            ))}

          <Box ref={scrollRef} />
        </Stack>
      </>
    )
  }

  return (
    <LeftPage
      sx={{ Width: "536px", position: "relative" }}
      title="Messages"
      onClose={onClose}
      content={<MessageListBox />}
      actions={<PostMeesageBox />}
    />
  )
}
