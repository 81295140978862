import { default as dl1 } from "../../../../assets/svgs/dl1.svg"
import { default as dl2 } from "../../../../assets/svgs/dl2.svg"
import { default as dl3 } from "../../../../assets/svgs/dl3.svg"
import { default as dl4 } from "../../../../assets/svgs/dl4.svg"
import { default as dl5 } from "../../../../assets/svgs/dl5.svg"
import { default as dl6 } from "../../../../assets/svgs/dl6.svg"
import { default as dl7 } from "../../../../assets/svgs/dl7.svg"
import { default as dl8 } from "../../../../assets/svgs/dl8.svg"
import { default as dr1 } from "../../../../assets/svgs/dr1.svg"
import { default as dr2 } from "../../../../assets/svgs/dr2.svg"
import { default as dr3 } from "../../../../assets/svgs/dr3.svg"
import { default as dr4 } from "../../../../assets/svgs/dr4.svg"
import { default as dr5 } from "../../../../assets/svgs/dr5.svg"
import { default as dr6 } from "../../../../assets/svgs/dr6.svg"
import { default as dr7 } from "../../../../assets/svgs/dr7.svg"
import { default as dr8 } from "../../../../assets/svgs/dr8.svg"
import { default as ul1 } from "../../../../assets/svgs/ul1.svg"
import { default as ul2 } from "../../../../assets/svgs/ul2.svg"
import { default as ul3 } from "../../../../assets/svgs/ul3.svg"
import { default as ul4 } from "../../../../assets/svgs/ul4.svg"
import { default as ul5 } from "../../../../assets/svgs/ul5.svg"
import { default as ul6 } from "../../../../assets/svgs/ul6.svg"
import { default as ul7 } from "../../../../assets/svgs/ul7.svg"
import { default as ul8 } from "../../../../assets/svgs/ul8.svg"
import { default as ur1 } from "../../../../assets/svgs/ur1.svg"
import { default as ur2 } from "../../../../assets/svgs/ur2.svg"
import { default as ur3 } from "../../../../assets/svgs/ur3.svg"
import { default as ur4 } from "../../../../assets/svgs/ur4.svg"
import { default as ur5 } from "../../../../assets/svgs/ur5.svg"
import { default as ur6 } from "../../../../assets/svgs/ur6.svg"
import { default as ur7 } from "../../../../assets/svgs/ur7.svg"
import { default as ur8 } from "../../../../assets/svgs/ur8.svg"
export const svgList = {
  ul1,
  ul2,
  ul3,
  ul4,
  ul5,
  ul6,
  ul7,
  ul8,
  ur1,
  ur2,
  ur3,
  ur4,
  ur5,
  ur6,
  ur7,
  ur8,
  dl1,
  dl2,
  dl3,
  dl4,
  dl5,
  dl6,
  dl7,
  dl8,
  dr1,
  dr2,
  dr3,
  dr4,
  dr5,
  dr6,
  dr7,
  dr8,
}
/**
 * tooth position
 * [0,1]:  0: left/origin;  1: top/bottom
 */
export const position = {
  ul1: [150, 47],
  ul2: [125, 57],
  ul3: [102, 74],
  ul4: [84, 98],
  ul5: [72, 123],
  ul6: [61, 154],
  ul7: [52, 191],
  ul8: [49, 227],

  ur1: [149, 47],
  ur2: [125, 57],
  ur3: [102, 74],
  ur4: [83, 98],
  ur5: [71, 123],
  ur6: [60, 154],
  ur7: [52, 191],
  ur8: [49, 227],

  dl1: [161, 51],
  dl2: [135, 55],
  dl3: [112, 66],
  dl4: [91, 87],
  dl5: [77, 111],
  dl6: [62, 138],
  dl7: [56, 174.5],
  dl8: [52, 209],

  dr1: [159, 51],
  dr2: [135, 55],
  dr3: [112, 66],
  dr4: [91, 87],
  dr5: [77, 111],
  dr6: [62, 138],
  dr7: [56, 174],
  dr8: [52, 209],
}

/**
 *  number position
 * [0,1]:  0: left/origin;  1: top/bottom
 */
export const number = {
  ul1: [155, 24],
  ul2: [105, 42],
  ul3: [75, 66],
  ul4: [50, 96],
  ul5: [40, 126],
  ul6: [30, 160],
  ul7: [20, 196],
  ul8: [14, 231],

  ur1: [150, 24],
  ur2: [112, 42],
  ur3: [74, 66],
  ur4: [50, 96],
  ur5: [39, 126],
  ur6: [27, 160],
  ur7: [20, 196],
  ur8: [18, 231],

  dl1: [160, 30],
  dl2: [127, 32],
  dl3: [89, 52],
  dl4: [63, 81],
  dl5: [46, 110],
  dl6: [36, 144],
  dl7: [25, 180],
  dl8: [20, 215],

  dr1: [160, 30],
  dr2: [127, 32],
  dr3: [89, 52],
  dr4: [63, 81],
  dr5: [46, 110],
  dr6: [36, 144],
  dr7: [30, 180],
  dr8: [20, 215],
}
export const dentalChartMenuList = [
  "Implant (I)",
  "Erupting (E)",
  // "Extract (X)",
  "Bridge (B)",
  "Crown (Cr)",
  "Missing (M)",
  "Primary (a,b,c,d,e)",
  "Supernumerary (S)",
  // "Don’t move (Dm)",
]
