import { createAsyncThunk } from "@reduxjs/toolkit"

import { getBannerMessage, postMessage } from "./banner.service"

export const fetchBannerData = createAsyncThunk(
  "messageService/fetchBannerData",
  async (
    { patientId, caseId }: { patientId: string; caseId: string },
    { getState },
  ) => {
    try {
      const orgId = getState().userService.user.current_orgId
      return getBannerMessage(orgId, patientId, caseId)
    } catch (err) {
      return err
    }
  },
)

export const postMessageData = createAsyncThunk(
  "messageService/postMessageData",
  async (
    {
      patientId,
      caseId,
      payload,
    }: { patientId: string; caseId: string; payload: { message: string } },
    { getState },
  ) => {
    try {
      const orgId = getState().userService.user.current_orgId
      return postMessage(orgId, patientId, caseId, payload)
    } catch (err) {
      return err
    }
  },
)
