import { FC } from "react"

import { UText } from "../../../components"
import { Box, SxProps } from "../../../components/mui.components"

const ErrorAlert: FC<{ message: string; sxProps?: SxProps }> = ({
  message,
  sxProps,
}) => {
  return (
    <Box sx={{ textAlign: "center", ...sxProps }}>
      <UText variant={"body2"} color={"error"}>
        {message}
      </UText>
    </Box>
  )
}

ErrorAlert.defaultProps = {
  sxProps: {},
}
export default ErrorAlert
