import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PersonIcon from "@mui/icons-material/Person"

import ToothOutline from "../../assets/svgs/tooth-outline.svg"
import ToothOutlineBlue from "../../assets/svgs/toothOutlineBlue.svg"
import {
  Box,
  Chip,
  Container,
  Grid,
  Paper,
} from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { fetchFormLists } from "../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../core/app/store"
import { UI_PERMISSION } from "../../core/utils/access/permissionConstants"
import { dateFormat } from "../../core/utils/formatters"
import { useMyNavigation } from "../../hooks/useMyNavigation"
import usePermissions from "../../hooks/userPermission"
import AuthorizeRoute from "../../routes/AuthorizeRoute"
import { UButton, UText } from "../../ui-component"
import UModalPopup from "../../ui-component/components/UModalPopup/UModalPopup"
import SmartRxForm from "../Uassist/USmartForms/SmartRxForm"

import SettingTabOptions from "./components/TemplateFormOptions"
import ClinicalSettings from "./UserProfile/ClinicalSettings"
import UserProfile from "./UserProfile/UserProfile"

const { VITE_PRESCRIPTION_ID, VITE_REFINEMENT, VITE_RETAINER } = import.meta.env

const SettingsModule: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")
  const location = useLocation()
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()

  const { formTemplateList, rxJsonForm } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { userProfile } = useAppSelector(
    (state: RootState) => state.userService,
  )
  useEffect(() => {
    !formTemplateList.length && dispatch(fetchFormLists())
  }, [formTemplateList])

  const [isSaveOpen, setIsSaveOpen] = useState(false)
  const [isResetOpen, setIsResetOpen] = useState(false)
  const [isResetData, setIsResetData] = useState(false)
  const [isSaveData, setIsSaveData] = useState(false)

  const dataHandler = () => {
    if (isResetOpen) {
      setIsResetData(true)
      setIsResetOpen(false)
    }
    if (isSaveOpen) {
      setIsSaveData(true)
      setIsSaveOpen(false)
    }
  }

  return (
    <>
      <Container
        component="main"
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "16px 0",
          padding: "0 !important",
        }}
      >
        <UText
          variant={"h4"}
          sxProp={{ pt: 2, pb: 2, position: "sticky", top: 0 }}
        >
          {t("settings.title")}
        </UText>
        <Grid container>
          <Box
            sx={{
              width: "250px",
              display: "flex",
              flexDirection: "column",
              marginRight: "26px",
              maxHeight: "336px",
              position: "sticky",
              top: "80px",
            }}
          >
            {hasAny(UI_PERMISSION.UI_PROFILE_VIEW) && (
              <SettingTabOptions
                text={t("settings.userProfile.title")}
                pathName="userProfile"
                startIcon={<PersonIcon />}
              />
            )}
            {hasAny(UI_PERMISSION.UI_CLINICALSETTING_VIEW) && (
              <SettingTabOptions
                text={t("clinicalSettings.title")}
                pathName="clinicalSettings"
                startIcon={
                  <img
                    alt={t("clinicalSettings.title")}
                    src={
                      location.pathname.includes("clinicalSettings")
                        ? ToothOutlineBlue
                        : ToothOutline
                    }
                  />
                }
              />
            )}
            {hasAny(UI_PERMISSION.UI_SMARTRXTEMPLATE_VIEW) && (
              <SettingTabOptions
                text={t("settings.smartRxForms.title")}
                pathName={`smartRxForms/${
                  formTemplateList.find(
                    (formTemplate) => formTemplate.name === "Crowding",
                  )?.id
                }`}
                startIcon={<ContentPasteOutlinedIcon />}
                endIcon={
                  location.pathname.includes("smartRxForms") ? (
                    <ExpandLessIcon
                      sx={{
                        color: "#0000008F !important",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{
                        color: "#0000008F !important",
                      }}
                    />
                  )
                }
              />
            )}
            {location.pathname.includes("smartRxForms") && (
              <Grid
                container
                flexDirection={"column"}
                alignItems={"flex-start"}
                sx={{ ml: 6 }}
              >
                {formTemplateList.map((form, index) => {
                  return (
                    form.id !== VITE_PRESCRIPTION_ID &&
                    form.id !== VITE_REFINEMENT &&
                    form.id !== VITE_RETAINER && (
                      <UButton
                        btnText={form.name}
                        variant="text"
                        key={index}
                        disableRipple
                        sxProp={{
                          color:
                            form.id === rxJsonForm.id
                              ? "primary.main"
                              : "text.secondary",
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClickHandler={() =>
                          navigate(`/settings/smartRxForms/${form.id}`)
                        }
                      ></UButton>
                    )
                  )
                })}
              </Grid>
            )}
          </Box>
          <Box sx={{ marginRight: "22px" }}>
            <Paper
              elevation={0}
              sx={{
                my: { xs: 3, md: 6 },
                borderRadius: "16px",
                marginTop: "0px !important",
                margin: "0 auto",
                width: "max-content",
              }}
            >
              <Routes>
                {hasAny(UI_PERMISSION.UI_PROFILE_VIEW) && (
                  <Route
                    path={"userProfile"}
                    element={
                      <AuthorizeRoute
                        component={<UserProfile />}
                        allowedPermissions={[UI_PERMISSION.UI_PROFILE_VIEW]}
                      />
                    }
                  />
                )}
                <Route
                  path={"clinicalSettings"}
                  element={
                    <AuthorizeRoute
                      component={<ClinicalSettings />}
                      allowedPermissions={[
                        UI_PERMISSION.UI_CLINICALSETTING_VIEW,
                      ]}
                    />
                  }
                />
                <Route
                  path={"smartRxForms/:formid"}
                  element={
                    <AuthorizeRoute
                      component={
                        <SmartRxForm
                          isSettings={true}
                          isResetData={isResetData}
                          setIsResetData={setIsResetData}
                          isSaveData={isSaveData}
                          setIsSaveData={setIsSaveData}
                        />
                      }
                      allowedPermissions={[
                        UI_PERMISSION.UI_SMARTRXTEMPLATE_VIEW,
                      ]}
                    />
                  }
                />
                <Route
                  path={"*"}
                  element={<Navigate to={"userProfile"} replace />}
                />
              </Routes>
            </Paper>
          </Box>
          {location.pathname.includes("userProfile") && (
            <Container
              sx={{
                width: "254px",
                padding: "0 32px",
                margin: 0,
                position: "sticky",
                top: "16px",
                maxHeight: "200px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <UText variant="h6">{t("settings.userProfile.status")}</UText>
                <Chip
                  label="Active"
                  sx={{
                    color: "success.main",
                    border: "1px solid",
                    borderColor: "success.main",
                    borderRadius: "100px",
                    backgroundColor: "transparent",
                    padding: "3px 4px",
                    width: "61px",
                    height: "24px",
                    marginLeft: 2,
                    span: {
                      padding: 0,
                    },
                  }}
                ></Chip>
              </Box>
              {userProfile.createDate && (
                <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
                  <UText variant="subtitle2" color="text.secondary">
                    {t("settings.userProfile.creationDate")}
                  </UText>
                  <UText variant="subtitle2">
                    {dateFormat(
                      userProfile.createDate,
                      "MMM DD, YYYY",
                      "YYYY-MM-DDTh:mm:ss",
                    )}
                  </UText>
                </Box>
              )}
              {userProfile.updateDate && (
                <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
                  <UText variant="subtitle2" color="text.secondary">
                    {t("settings.userProfile.lastUpdate")}
                  </UText>
                  <UText variant="subtitle2">
                    {dateFormat(
                      userProfile.updateDate,
                      "MMM DD, YYYY",
                      "YYYY-MM-DDTh:mm:ss",
                    )}
                  </UText>
                </Box>
              )}

              {/* <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
                <UText variant="subtitle2" color="text.secondary">
                  {t("settings.userProfile.lastLogin")}
                </UText>
                <UText variant="subtitle2">Jun 28, 2023</UText>
              </Box> */}
            </Container>
          )}
          {hasAny(UI_PERMISSION.UI_SMARTRXTEMPLATE_UPDATE) &&
            location.pathname.includes("smartRxForms") && (
              <Grid
                sx={{
                  position: "sticky",
                  top: "16px",
                  maxHeight: "200px",
                }}
              >
                <UButton
                  btnText="Save"
                  variant="contained"
                  sxProp={{ width: 69, height: 36 }}
                  onClickHandler={() => setIsSaveOpen(true)}
                />
                <UButton
                  btnText="Reset"
                  variant="outlined"
                  sxProp={{ ml: 1, width: 74, height: 36 }}
                  onClickHandler={() => setIsResetOpen(true)}
                />
              </Grid>
            )}
        </Grid>
      </Container>
      <UModalPopup
        isAlert={isSaveOpen || isResetOpen}
        title={
          <UText
            variant={"h6"}
            sxProp={{
              width: "396px",
            }}
          >
            {isSaveOpen
              ? t("settings.saveModalTitle")
              : t("settings.resetModalTitle")}
          </UText>
        }
        sxModalProps={{
          "#titleCntr": {
            padding: "16px 24px",
          },
          "#contentCntr": {
            padding: "20px 24px",
          },
          "#btnListCntr": {
            padding: 1,
            gap: 1,
          },
          ".MuiDialog-paper": {
            maxWidth: 444,
          },
        }}
        content={
          <UText variant={"body1"} sxProp={{ display: "block" }}>
            {isSaveOpen
              ? t("settings.saveModalDescription")
              : t("settings.resetModalDescription")}
          </UText>
        }
        btnList={[
          <UButton
            key={t("button.cancel")}
            variant="text"
            btnText={t("button.cancel")}
            sxProp={{
              width: 116,
              height: 36,
            }}
            onClickHandler={
              isSaveOpen
                ? () => setIsSaveOpen(false)
                : () => setIsResetOpen(false)
            }
          ></UButton>,
          <UButton
            key={t("button.save")}
            variant="contained"
            btnText={
              isSaveOpen
                ? t("button.save")
                : isResetOpen
                ? t("button.reset")
                : ""
            }
            sxProp={{
              width: 136,
              height: 36,
            }}
            onClickHandler={dataHandler}
          ></UButton>,
        ]}
      ></UModalPopup>
    </>
  )
}

export default SettingsModule
