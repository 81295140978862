import { FC } from "react"
import { ReactSVG } from "react-svg"
import { Button, SxProps } from "@mui/material"

/**
 * svg按钮：仅支持单次点击
 * @param svg svg对象
 * @param clickFun 点击事件
 * @param isdisable true/false
 * @param boxSx svg父级样式
 * @param svgSx svg样式
 * @param svgColor svg的path fill
 * @param afterInjection 自定义svg的样式变化
 * @returns component SvgBtn
 */
const SvgBtn: FC<{
  svg: string
  clickFun: (arg?: unknown) => void
  isdisable?: boolean
  boxSx?: SxProps
  svgSx?: object
  svgColor?: string
  afterInjection?: (svg: Element) => void
}> = ({
  svg,
  clickFun,
  isdisable = false,
  boxSx,
  svgSx,
  svgColor,
  afterInjection,
}) => {
  return (
    <Button
      sx={{
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        padding: 0,
        minWidth: 0,
        ...boxSx,
      }}
      disabled={isdisable}
      onClick={clickFun}
    >
      <ReactSVG
        src={svg}
        style={{
          marginTop: 7,
          cursor: "pointer",
          ...svgSx,
        }}
        afterInjection={(svg) => {
          if (afterInjection) {
            afterInjection(svg)
            return
          }
          const path = svg.getElementsByTagName("path")[0]
          path?.setAttribute("fill-opacity", "0.56")

          if (isdisable) {
            path?.setAttribute("fill-opacity", "0.38")
          }
          if (svgColor) {
            path?.setAttribute("fill", svgColor)
          }
        }}
      ></ReactSVG>
    </Button>
  )
}
export default SvgBtn
