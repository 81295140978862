import React from 'react';

const TriangleIcon = (props) => {

  const position = (props.position === undefined || props.position === null ? "unset" : props.position)
  const left = (props.left === undefined || props.left === null ? "unset" : (props.left - 4))
  const top = (props.top === undefined || props.top === null ? "unset" : (props.top))

  return (
    <div style={{
      width: "5px",
      height: "5px",
      position: position,
      left: left,
      top: top,
      borderTop: props.direction === "down" ? "5px solid #4B4B4B" : "0px",
      borderLeft: "4px solid transparent",
      borderRight: "4px solid transparent",
      borderBottom: props.direction === "up" ? "5px solid #4B4B4B" : "0px",
      visibility: props.visibility
    }}>
      
    </div>
  );
};
 
export default TriangleIcon;