import { useState, useRef, useEffect } from "react"
import {
  Box,
  Card,
  IconButton,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@mui/material"
import { Slide } from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"

import { UDChairsideSetup } from "./ChairsideSetup/UDChairsideSetup"

import { UDSetup } from "../UDesign/UDSetup"
import { UDStaging } from "../UDesign/UDStaging"
import { UDAux } from "../UDesign/Attachments/UDAux"

import { UDQuickRx } from "./UDQuickRx"
import { UDSmartRx } from "./UDSmartRx"

import { UDReviewMod } from "./ReviewMod/UDReviewMod"
import { UDOrder } from "./order/UDOrder"
import { WeSetup } from "../WeDesign/WeSetup/WeSetup"
import { WeStaging } from "../WeDesign/WeStaging/WeStaging"
import { WeAux } from "../WeDesign/WeAux/WeAux"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setCurRightPage } from "../udTreatSlice"
import Prescription from "../USmartForms/Prescription"
import { UDRetainerRx } from "./UDRetainerRx"
import { WeTest } from "../WeDesign/WeTest/WeTest"

export function RightPages(props: any) {
  const { curRightPage } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [slideIn, setSlideIn] = useState(false)
  const curPage = useRef("")
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (curPage.current === curRightPage) return
    setSlideIn(false) // Slide current page out
    // Delay 1 seconds
    curPage.current = curRightPage
    setSlideIn(true) // Slide new page
    console.log("Delayed for 0.5 second.")
  }, [curRightPage])
  const onClosePage = () => {
    dispatch(setCurRightPage(""))
  }
  function getPage(pageName: string) {
    switch (pageName) {
      case "SmartRx":
        return <UDSmartRx onClose={onClosePage} />
      case "QuickRx":
        return <UDQuickRx onClose={onClosePage} />
      case "RetainerRx":
        return <UDRetainerRx onClose={onClosePage} />
      case "UDSetup":
        return <UDSetup onClose={onClosePage} />
      case "UDStaging":
        return <UDStaging onClose={onClosePage} />
      case "UDAux":
        return <UDAux onClose={onClosePage} />
      case "ReviewMod":
        return <UDReviewMod onClose={onClosePage} />
      case "Order":
        return <UDOrder onClose={onClosePage} />
      case "WeSetup":
        return <WeSetup onClose={onClosePage} />
      case "WeStaging":
        return <WeStaging onClose={onClosePage} />
      case "WeAux":
        // return <WeAux onClose={onClosePage} />
        return <UDAux onClose={onClosePage} />
      case "WeTest":
        return <WeTest onClose={onClosePage} />
      default:
        return <p>Page: {pageName} </p>
    }
    return <Box sx={{ w: 100, h: 100 }} />
  }

  return curRightPage && getPage(curRightPage)
}
