import { useEffect, useState } from "react"
import { Box, Stepper, Step, StepLabel } from "@mui/material"
import { Button, Typography, Paper } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import {
  setCurLeftPage,
  setCurRightPage,
  setWorkflowMode,
} from "@/UDTreat/udTreatSlice"

const steps = [
  "Scans",
  "Photo/X-ray",
  "Dental Chart",
  "Smart Rx",
  "Review/Submit",
]
const leftRightPages = [
  ["createcase", ""],
  ["records", ""],
  ["dentalchart", ""],
  ["records", "SmartRx"],
  ["", "SmartRx"],
  ["", ""],
]

export function UDSmartRxWorkflow() {
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const isStepOptional = (step: number) => {
    // return step === 1
    return false
  }
  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  useEffect(() => {
    dispatch(setCurLeftPage(leftRightPages[activeStep][0]))
    dispatch(setCurRightPage(leftRightPages[activeStep][1]))
  }, [, activeStep])

  const handleFinish = () => {
    dispatch(setWorkflowMode("aidesign"))
  }
  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Paper
      sx={{
        left: "30%",
        bottom: "10px",
        width: "40%",
        backgroundColor: "white",
        position: "absolute",
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }}> Current step: {activeStep} </Box>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>

        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <>
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={handleFinish}>Finish</Button>
          </>
        ) : (
          <Button onClick={handleNext}>Next</Button>
        )}
      </Box>
    </Paper>
  )
}
