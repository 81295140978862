import { CASE_STATUS } from "./status.config"

export const RECORDS_STATUS = [
  CASE_STATUS.COMPLETE_PATIENT_RECORDS,
  CASE_STATUS.FILL_OUT_RX_FORM,
  CASE_STATUS.SUBMIT_TO_UASSIST,
  CASE_STATUS.PLANNING_IN_PROGRESS,
  CASE_STATUS.SUBMITTED_TO_UASSIST,
  CASE_STATUS.DEFAULT,
  CASE_STATUS.ATTENTION_NEEDED,
  CASE_STATUS.REJECTED,
]

export const TABLE_VIEW_STATUS = [
  CASE_STATUS.REVIEW_PLAN,
  CASE_STATUS.MODIFICATION_REQUESTED,
  CASE_STATUS.REVIEW_MODIFICATION,
  CASE_STATUS.PLACE_ORDER,
  CASE_STATUS.IN_PRODUCTION,
  CASE_STATUS.SHIPPED,
  CASE_STATUS.DELIVERED,
  CASE_STATUS.ORDER_SUBMITTED,
  CASE_STATUS.TREATMENT_IN_PROGRESS,
  CASE_STATUS.TREATMENT_COMPLETE,
  CASE_STATUS.ARCHIVED,
  CASE_STATUS.CANCELLED,
  CASE_STATUS.ATTENTION_NEEDED_REFINEMENT,
  CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
  CASE_STATUS.PLANNING_IN_PROGRESS_REFINEMENT,
  CASE_STATUS.FROM_UDESIGN,
]

export const UASSIST_PROGRESS_STATUS = [
  CASE_STATUS.SHIPPED,
  CASE_STATUS.DELIVERED,
  CASE_STATUS.TREATMENT_IN_PROGRESS,
  CASE_STATUS.TREATMENT_COMPLETE,
  CASE_STATUS.FROM_UDESIGN,
]

const commonActions = {
  placeOrder: {
    btnText: "Place Order",
    btnAction: "OPEN_TREAT_PAGE",
    sxProps: {
      width: 273,
    },
  },
  downloadFiles: {
    btnText: "Download Case Files",
    btnAction: "DOWNLOAD_FILES",
    sxProps: {
      width: 273,
    },
  },
  archive: {
    btnText: "Archive",
    btnAction: "OPEN_CASE_ARCHIVE_MODEL",
    sxProps: {
      width: 273,
    },
  },
  cancel: {
    btnText: "Cancel",
    btnAction: "OPEN_CANCEL_MODEL",
    sxProps: {
      width: 273,
    },
  },
}

const actonButtonByStatus = [
  {
    status: CASE_STATUS.COMPLETE_PATIENT_RECORDS,
    bottomCaption: {
      textContent: (date: string) => `Last Updated ${date}`,
    },
    actions: [
      {
        btnText: "Complete Patient Records",
        btnAction: "OPEN_RECORDS",
        variant: "contained",
        sxProps: {
          width: 268,
        },
      },
      {
        btnText: "Archive Patient",
        btnAction: "OPEN_ARCHIVE_PATIENT_MODEL",
        variant: "outlined",
        sxProps: {
          width: 268,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.FILL_OUT_RX_FORM,
    bottomCaption: {
      textContent: (date: string) => `Last Updated ${date}`,
    },
    actions: [
      {
        btnText: "Open Prescription Form",
        btnAction: "OPEN_PRESCRIPTION_FORM",
        variant: "contained",
        sxProps: {
          width: 248,
        },
      },
      {
        btnText: "Archive Patient",
        btnAction: "OPEN_ARCHIVE_PATIENT_MODEL",
        variant: "outlined",
        sxProps: {
          width: 248,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.SUBMIT_TO_UASSIST,
    bottomCaption: {
      textContent: (date: string) => `Last Updated ${date}`,
    },
    actions: [
      {
        btnText: "Submit to uAssist",
        btnAction: "OPEN_SUMMARY",
        variant: "contained",
        sxProps: {
          width: 195,
        },
      },
      {
        btnText: "Archive Patient",
        btnAction: "OPEN_ARCHIVE_PATIENT_MODEL",
        variant: "outlined",
        sxProps: {
          width: 195,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.SUBMITTED_TO_UASSIST,
    topCaption: {
      textContent: "Perfect smiles coming soon",
    },
    bottomCaption: {
      textContent: (date: string) => `Submitted ${date}`,
    },
    actions: [
      //TODO: Enable in the future
      // {
      //   btnText: "Modify Patient Records",
      //   btnAction: "OPEN_RECORDS",
      //   variant: "outlined",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
      // {
      //   btnText: "Modify uAssist Forms",
      //   btnAction: "OPEN_PRESCRIPTION_FORM",
      //   variant: "outlined",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
      // {
      //   btnText: "Cancel uAssist Submission",
      //   btnAction: "OPEN_CANCEL_MODEL",
      //   variant: "outlined",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.ATTENTION_NEEDED,
    topCaption: {
      textContent: "Perfect smiles coming soon",
    },
    bottomCaption: {
      textContent: (date: string) => `Submitted ${date}`,
    },
    actions: [
      {
        btnText: "Cancel uAssist Submission",
        btnAction: "OPEN_CANCEL_MODEL",
        variant: "outlined",
        sxProps: {
          width: 273,
        },
      },
      // {
      //   btnText: "Cancel",
      //   btnAction: "OPEN_CANCEL_MODEL",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
    ],
  },
  {
    status: CASE_STATUS.PLANNING_IN_PROGRESS,
    topCaption: {
      textContent: "Perfect smiles coming soon",
    },
    bottomCaption: {
      textContent: (date: string) => `Submitted ${date}`,
    },
    actions: [
      // {
      //   btnText: "Cancel uAssist Submission",
      //   btnAction: "OPEN_CANCEL_MODEL",
      //   variant: "outlined",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.REVIEW_PLAN,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.MODIFICATION_REQUESTED,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.REVIEW_MODIFICATION,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.PLACE_ORDER,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.ORDER_SUBMITTED,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.IN_PRODUCTION,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.SHIPPED,
    actions: [
      {
        btnText: "Change Status to Treatment In Progress",
        btnAction: "UPDATE_STATUS_DELIVERED_TO_IN_PROGRESS",
        sxProps: {
          width: 273,
        },
      },
      commonActions.placeOrder,
      commonActions.cancel,
      commonActions.downloadFiles,
    ],
  },
  {
    status: CASE_STATUS.DELIVERED,
    actions: [
      {
        btnText: "Change Status to Treatment In Progress",
        btnAction: "UPDATE_STATUS_DELIVERED_TO_IN_PROGRESS",
        sxProps: {
          width: 273,
        },
      },
      // {
      //   btnText: "Start Refinement",
      //   btnAction: "START_REFINEMENT",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
      commonActions.placeOrder,
      commonActions.downloadFiles,
      commonActions.archive,
    ],
  },
  {
    status: CASE_STATUS.TREATMENT_IN_PROGRESS,
    actions: [
      {
        btnText: "Change Status to Treatment Complete",
        btnAction: "UPDATE_STATUS_IN_PROGRESS_TO_COMPLETE",
        sxProps: {
          width: 273,
        },
      },
      // {
      //   btnText: "Start Course Refinement",
      //   btnAction: "START_REFINEMENT",
      //   sxProps: {
      //     width: 273,
      //   },
      // },
      //TODO : we can enable in the future
      commonActions.placeOrder,
      commonActions.archive,
      commonActions.downloadFiles,
    ],
  },
  {
    status: CASE_STATUS.TREATMENT_COMPLETE,
    actions: [
      commonActions.placeOrder,
      commonActions.archive,
      commonActions.downloadFiles,
    ],
  },
  {
    status: CASE_STATUS.ARCHIVED,
    actions: [
      {
        btnText: "Unarchive",
        btnAction: "OPEN_CASE_UNARCHIVE_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.CANCELLED,
    actions: [
      {
        btnText: "Archive",
        btnAction: "OPEN_ARCHIVE_PATIENT_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.DEFAULT,
    bottomCaption: {
      textContent: (date: string) => `Last Updated ${date}`,
    },
    actions: [
      {
        btnText: "Archive Patient",
        btnAction: "OPEN_ARCHIVE_PATIENT_MODEL",
        variant: "outlined",
        sxProps: {
          width: 268,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.PLANNING_IN_PROGRESS_REFINEMENT,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.ATTENTION_NEEDED_REFINEMENT,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.FROM_UDESIGN,
    actions: [
      {
        btnText: "Cancel",
        btnAction: "OPEN_CANCEL_MODEL",
        sxProps: {
          width: 273,
        },
      },
      {
        btnText: "Download Case Files",
        btnAction: "DOWNLOAD_FILES",
        sxProps: {
          width: 273,
        },
      },
    ],
  },
  {
    status: CASE_STATUS.REJECTED,
    actions: [
      {
        btnText: "Archive",
        btnAction: "OPEN_CASE_ARCHIVE_MODEL",
        variant: "outlined",
        sxProps: {
          border: "1px solid rgba(33, 150, 243, 0.50)",
          marginTop: 2,
          fontSize: "15px",
          lineHeight: "173.333%",
          letterSpacing: "0.46px",
          color: "primary.main",
        },
      },
    ],
  },
]

export const getRefinementMenu = (
  status: string,
  isInitialuAssist,
  isRefinementReady,
) => {
  const menus = []
  if (status === CASE_STATUS.DELIVERED || status === CASE_STATUS.SHIPPED) {
    menus.push({
      btnText: "Change Status to Treatment In Progress",
      btnAction: "UPDATE_STATUS_DELIVERED_TO_IN_PROGRESS",
      sxProps: {
        width: 273,
      },
    })
  }

  if (status === CASE_STATUS.TREATMENT_IN_PROGRESS) {
    menus.push({
      btnText: "Change Status to Treatment Complete",
      btnAction: "UPDATE_STATUS_IN_PROGRESS_TO_COMPLETE",
      sxProps: {
        width: 273,
      },
    })
  }

  if (isInitialuAssist) {
    menus.push({
      btnText: "Start Initial uAssist",
      btnAction: "OPEN_RECORDS",
      sxProps: {
        width: 268,
      },
    })
  }
  if (isRefinementReady) {
    menus.push({
      btnText: "Start Course Refinement",
      btnAction: "START_REFINEMENT",
      sxProps: {
        width: 273,
      },
    })
  }

  // TODO : we can enable in the future
  menus.push({
    btnText: "Place Order",
    btnAction: "OPEN_TREAT_PAGE",
    sxProps: {
      width: 273,
    },
  })

  menus.push({
    btnText: "Archive",
    btnAction: "OPEN_CASE_ARCHIVE_MODEL",
    sxProps: {
      width: 273,
    },
  })

  menus.push({
    btnText: "Download Case Files",
    btnAction: "DOWNLOAD_FILES",
    sxProps: {
      width: 273,
    },
  })

  return { actions: menus }
}

export const UNARCHIVEPATIENT = {
  btnText: "Unarchive",
  btnAction: "OPEN_UNARCHIVE_PATIENT_MODEL",
  variant: "shade",
  sxProps: {
    width: 116,
  },
}

export const ARCHIVEPATIENT = {
  btnText: "Archive",
  btnAction: "OPEN_ARCHIVE_PATIENT_MODEL",
  variant: "shade",
  sxProps: {
    width: 95,
  },
}

export const getBtnActionsByStatus = (
  status: string,
  isInitialuAssist = false,
  isRefinementReady = false,
) => {
  if (
    UASSIST_PROGRESS_STATUS.includes(status) &&
    (isInitialuAssist || isRefinementReady)
  ) {
    return getRefinementMenu(status, isInitialuAssist, isRefinementReady)
  } else if (status) {
    const actionsByStatus = actonButtonByStatus.find(
      (obj) => obj.status === status,
    )
    return actionsByStatus || actonButtonByStatus[0]
  } else {
    return actonButtonByStatus.find((obj) => obj.status === CASE_STATUS.DEFAULT)
  }
}