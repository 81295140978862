import { FC, useEffect, useState } from "react"
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, FormGroup, FormLabel, SxProps } from "@mui/material"

import { FormInput } from "../../../components"
import { UText } from "../../../ui-component"
import { IActivationUserForm } from "../Activation/ActivationUser"
import { getPasswordFormDefinition } from "../Activation/passwordFormDef"
import PasswordIcon from "../Login/PasswordIcon"

import CheckboxInput from "./CheckboxInput"

interface IPasswordInputProps {
  formKey: string
  errors: FieldErrors
  control: any
  watch: UseFormWatch<any>
  sxProps?: SxProps
  setError: UseFormSetError<any>
  clearErrors: UseFormClearErrors<any>
  setValue: UseFormSetValue<any>
}

interface IPasswordFormDef {
  formKey: any
  passwordLabel: string
  passwordFieldName: any
  confirmLabel: string
  confirmFieldName: any
  passwordSxProps: SxProps
  confirmSxProps: SxProps
  passwordRequired: string
  confirmRequired: string
}

const PasswordInput: FC<IPasswordInputProps> = ({
  errors,
  control,
  watch,
  formKey,
  sxProps,
  setError,
  clearErrors,
  setValue,
}) => {
  const { t } = useTranslation("common")

  const [formDefinition, setFormDefinition] = useState<IPasswordFormDef>({
    formKey: "",
    passwordLabel: "",
    passwordFieldName: "",
    confirmLabel: "",
    confirmFieldName: "",
    passwordSxProps: undefined,
    confirmSxProps: undefined,
    passwordRequired: "",
    confirmRequired: "",
  })

  useEffect(() => {
    const formDef = getPasswordFormDefinition(formKey)
    if (formDef) {
      setFormDefinition(formDef)
    }
  }, [formKey])

  const newPasswordValue = watch(formDefinition.passwordFieldName, "")
  const confirmPasswordValue = watch(formDefinition.confirmFieldName, "")
  const hasSixChar = watch("hasSixChar", false)
  const hasSpecialChar = watch("hasSpecialChar", false)

  const [visibleNewPassword, setVisibleNewPassword] = useState<boolean>(false)
  const [visibileConfirmPassword, setVisibileConfirmPassword] =
    useState<boolean>(false)

  const validateNewPassword = () => {
    if (!newPasswordValue) {
      clearErrors(formDefinition.formKey)
      return
    }
    if (!hasSixChar) {
      setError("hasSixChar", { type: "required", message: "" })
    } else {
      clearErrors("hasSixChar")
    }
    if (!hasSpecialChar) {
      setError("hasSpecialChar", { type: "required", message: "" })
    } else {
      clearErrors("hasSpecialChar")
    }
  }

  const checkSpecialChar = (value: string) => {
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/
    return specialCharacterRegex.test(value)
  }

  const validatePasswordMatch = (value: string): string | boolean => {
    return value === newPasswordValue || t("formfieldErrors.passwordNotMatch")
  }

  return (
    formDefinition && (
      <Box sx={sxProps}>
        <FormInput
          inputLabel={formDefinition.passwordLabel}
          fieldName={formDefinition.passwordFieldName}
          fieldType={visibleNewPassword ? "text" : "password"}
          rules={{
            required: formDefinition.passwordRequired,
          }}
          errors={errors}
          control={control}
          formSxprops={formDefinition.passwordSxProps}
          onBlurChange={validateNewPassword}
          endAdornmentIcon={
            newPasswordValue && (
              <PasswordIcon
                updatePasswordVisible={setVisibleNewPassword}
                isError={!!(errors && errors.password)}
              />
            )
          }
          onInputChange={(value, newvalue) => {
            const hasSpecialChar = checkSpecialChar(newvalue)
            const hasSixChar = newvalue.length >= 6

            setValue("hasSixChar", hasSixChar)
            setValue("hasSpecialChar", hasSpecialChar)

            return newvalue
          }}
        />
        <FormGroup sx={{ px: 2 }}>
          <FormLabel id="password-requirements">
            <UText variant={"body2"}>
              {t("auth.resetPassword.passwordRequirementsLabel")}
            </UText>
          </FormLabel>
          <CheckboxInput
            control={control}
            label="auth.resetPassword.minimumRequirementsLabel"
            fieldName="hasSixChar"
            checked={hasSixChar}
            rules={{
              required: true,
            }}
          />
          <CheckboxInput
            control={control}
            fieldName="hasSpecialChar"
            label="auth.resetPassword.symbolValidationLabel"
            checked={hasSpecialChar}
            rules={{
              required: true,
            }}
          />
        </FormGroup>
        <FormInput
          inputLabel={formDefinition.confirmLabel}
          fieldName={formDefinition.confirmFieldName}
          fieldType={visibileConfirmPassword ? "text" : "password"}
          rules={{
            required: formDefinition.confirmRequired,
            validate: (value) => {
              return validatePasswordMatch(value) || ""
            },
          }}
          errors={errors}
          control={control}
          formSxprops={formDefinition.confirmSxProps}
          endAdornmentIcon={
            confirmPasswordValue && (
              <PasswordIcon
                updatePasswordVisible={setVisibileConfirmPassword}
                isError={!!(errors && errors.password)}
              />
            )
          }
        />
      </Box>
    )
  )
}

export default PasswordInput
