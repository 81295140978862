import { useState } from "react"
import { Box, Stack, Button } from "@mui/material"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "@/core/app/hooks"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { UploadZips } from "@/core/app/slices/clinical/clinical.types"

const allowedZipNames = [
  "bulk0.zip",
  `bulk1.zip`,
  `bulk2.zip`,
  `bulk10.zip`,
  `raw.zip`,
  `photo.zip`,
  `storage.version.json.zip`,
]

export const WeUploadCaseZip = () => {
  const dispatch = useAppDispatch()
  const [zipFileNames, setZipFileNames] = useState([])
  const [zipFiles, setZipFiles] = useState([])
  const { caseId, patientId } = useParams()

  const handleZipFilesChange = (event) => {
    const files = event.target.files
    const zipFiles = Array.from(files)
      .filter(
        (file) =>
          file.name.endsWith(".zip") && allowedZipNames.includes(file.name),
      )
      .map((file) => ({
        filename: file.name,
        file: file, // 这里file是File对象
      }))
    // 将ZIP文件名称添加到状态数组中
    setZipFileNames(zipFiles.map((file) => file.filename))
    setZipFiles(zipFiles)
  }

  return (
    <Box
      sx={{
        backgroundColor: "lightgray",
      }}
    >
      <Box>
        <input
          type="file"
          accept=".zip" // 限制只能选择ZIP文件
          multiple // 允许选择多个文件
          onChange={handleZipFilesChange}
        />
        {zipFileNames.length > 0 && (
          <ul>
            {zipFileNames.map((fileName, index) => (
              <li key={index}>{fileName}</li>
            ))}
          </ul>
        )}
      </Box>

      <Stack direction={"column-reverse"}>
        <Button
          disabled={zipFileNames.length === 0}
          variant="contained"
          onClick={() => {
            const params = {
              patientId: patientId,
              caseId: caseId,
              zips: zipFiles,
            } as UploadZips
            dispatch(uploadCaseZips(params))
          }}
        >
          Upload Local Zips
        </Button>
      </Stack>
    </Box>
  )
}
