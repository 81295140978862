import { default as dl1 } from "../../../../assets/svgs/dl1.svg"
import { default as dl2 } from "../../../../assets/svgs/dl2.svg"
import { default as dl3 } from "../../../../assets/svgs/dl3.svg"
import { default as dl4 } from "../../../../assets/svgs/dl4.svg"
import { default as dl5 } from "../../../../assets/svgs/dl5.svg"
import { default as dl6 } from "../../../../assets/svgs/dl6.svg"
import { default as dl6_05 } from "../../../../assets/svgs/dl6-0.5.svg"
import { default as dl6_025 } from "../../../../assets/svgs/dl6-0.25.svg"
import { default as dl6_075 } from "../../../../assets/svgs/dl6-0.75.svg"
import { default as dl7 } from "../../../../assets/svgs/dl7.svg"
import { default as dl7_05 } from "../../../../assets/svgs/dl7-0.5.svg"
import { default as dl7_025 } from "../../../../assets/svgs/dl7-0.25.svg"
import { default as dl7_075 } from "../../../../assets/svgs/dl7-0.75.svg"
import { default as dl8 } from "../../../../assets/svgs/dl8.svg"
import { default as dr8_05 } from "../../../../assets/svgs/dl8-0.5.svg"
import { default as dr8_025 } from "../../../../assets/svgs/dl8-0.25.svg"
import { default as dr8_075 } from "../../../../assets/svgs/dl8-0.75.svg"
import { default as dr1 } from "../../../../assets/svgs/dr1.svg"
import { default as dr2 } from "../../../../assets/svgs/dr2.svg"
import { default as dr3 } from "../../../../assets/svgs/dr3.svg"
import { default as dr4 } from "../../../../assets/svgs/dr4.svg"
import { default as dr5 } from "../../../../assets/svgs/dr5.svg"
import { default as dr6 } from "../../../../assets/svgs/dr6.svg"
import { default as dr6_05 } from "../../../../assets/svgs/dr6-0.5.svg"
import { default as dr6_025 } from "../../../../assets/svgs/dr6-0.25.svg"
import { default as dr6_075 } from "../../../../assets/svgs/dr6-0.75.svg"
import { default as dr7 } from "../../../../assets/svgs/dr7.svg"
import { default as dr7_05 } from "../../../../assets/svgs/dr7-0.5.svg"
import { default as dr7_025 } from "../../../../assets/svgs/dr7-0.25.svg"
import { default as dr7_075 } from "../../../../assets/svgs/dr7-0.75.svg"
import { default as dr8 } from "../../../../assets/svgs/dr8.svg"
import { default as dl8_05 } from "../../../../assets/svgs/dr8-0.5.svg"
import { default as dl8_025 } from "../../../../assets/svgs/dr8-0.25.svg"
import { default as dl8_075 } from "../../../../assets/svgs/dr8-0.75.svg"
import { default as ul1 } from "../../../../assets/svgs/ul1.svg"
import { default as ul2 } from "../../../../assets/svgs/ul2.svg"
import { default as ul3 } from "../../../../assets/svgs/ul3.svg"
import { default as ul4 } from "../../../../assets/svgs/ul4.svg"
import { default as ul5 } from "../../../../assets/svgs/ul5.svg"
import { default as ul6 } from "../../../../assets/svgs/ul6.svg"
import { default as ul6_05 } from "../../../../assets/svgs/ul6-0.5.svg"
import { default as ul6_025 } from "../../../../assets/svgs/ul6-0.25.svg"
import { default as ul6_075 } from "../../../../assets/svgs/ul6-0.75.svg"
import { default as ul7 } from "../../../../assets/svgs/ul7.svg"
import { default as ul7_05 } from "../../../../assets/svgs/ul7-0.5.svg"
import { default as ul7_025 } from "../../../../assets/svgs/ul7-0.25.svg"
import { default as ul7_075 } from "../../../../assets/svgs/ul7-0.75.svg"
import { default as ul8 } from "../../../../assets/svgs/ul8.svg"
import { default as ur8_05 } from "../../../../assets/svgs/ul8-0.5.svg"
import { default as ur8_025 } from "../../../../assets/svgs/ul8-0.25.svg"
import { default as ur8_075 } from "../../../../assets/svgs/ul8-0.75.svg"
import { default as ur1 } from "../../../../assets/svgs/ur1.svg"
import { default as ur2 } from "../../../../assets/svgs/ur2.svg"
import { default as ur3 } from "../../../../assets/svgs/ur3.svg"
import { default as ur4 } from "../../../../assets/svgs/ur4.svg"
import { default as ur5 } from "../../../../assets/svgs/ur5.svg"
import { default as ur6 } from "../../../../assets/svgs/ur6.svg"
import { default as ur6_05 } from "../../../../assets/svgs/ur6-0.5.svg"
import { default as ur6_025 } from "../../../../assets/svgs/ur6-0.25.svg"
import { default as ur6_075 } from "../../../../assets/svgs/ur6-0.75.svg"
import { default as ur7 } from "../../../../assets/svgs/ur7.svg"
import { default as ur7_05 } from "../../../../assets/svgs/ur7-0.5.svg"
import { default as ur7_025 } from "../../../../assets/svgs/ur7-0.25.svg"
import { default as ur7_075 } from "../../../../assets/svgs/ur7-0.75.svg"
import { default as ur8 } from "../../../../assets/svgs/ur8.svg"
import { default as ul8_05 } from "../../../../assets/svgs/ur8-0.5.svg"
import { default as ul8_025 } from "../../../../assets/svgs/ur8-0.25.svg"
import { default as ul8_075 } from "../../../../assets/svgs/ur8-0.75.svg"
export const svgList = {
  ul1,
  ul2,
  ul3,
  ul4,
  ul5,
  ul6,
  ul7,
  ul8,
  ur1,
  ur2,
  ur3,
  ur4,
  ur5,
  ur6,
  ur7,
  ur8,
  dl1,
  dl2,
  dl3,
  dl4,
  dl5,
  dl6,
  dl7,
  dl8,
  dr1,
  dr2,
  dr3,
  dr4,
  dr5,
  dr6,
  dr7,
  dr8,
  ur7_025,
  ur7_075,
  ur7_05,
  ur6_025,
  ur6_075,
  ur6_05,

  ul7_025,
  ul7_075,
  ul7_05,
  ul6_025,
  ul6_075,
  ul6_05,

  dr7_025,
  dr7_075,
  dr7_05,
  dr6_025,
  dr6_075,
  dr6_05,

  dl7_025,
  dl7_075,
  dl7_05,
  dl6_025,
  dl6_075,
  dl6_05,

  dl8_025,
  dl8_075,
  dl8_05,
  dr8_025,
  dr8_075,
  dr8_05,

  ul8_025,
  ul8_075,
  ul8_05,
  ur8_025,
  ur8_075,
  ur8_05,
}
/**
 * tooth position
 * [0,1]:  0: left/origin;  1: top/bottom
 */
export const position = {
  ul1: [150, 47 - 20],
  ul2: [125, 57 - 20],
  ul3: [102, 74 - 20],
  ul4: [84, 98 - 20],
  ul5: [72, 123 - 20],
  ul6: [61, 154 - 20],
  ul7: [52, 191 - 20],
  ul8: [49, 227 - 20],

  ur1: [149, 47 - 20],
  ur2: [125, 57 - 20],
  ur3: [102, 74 - 20],
  ur4: [83, 98 - 20],
  ur5: [71, 123 - 20],
  ur6: [60, 154 - 20],
  ur7: [52, 191 - 20],
  ur8: [49, 227 - 20],

  dl1: [161, 51 - 20],
  dl2: [135, 55 - 20],
  dl3: [112, 66 - 20],
  dl4: [91, 87 - 20],
  dl5: [77, 111 - 20],
  dl6: [62, 138 - 20],
  dl7: [56, 174.5 - 20],
  dl8: [52, 209 - 20],

  dr1: [159, 51 - 20],
  dr2: [135, 55 - 20],
  dr3: [112, 66 - 20],
  dr4: [91, 87 - 20],
  dr5: [77, 111 - 20],
  dr6: [62, 138 - 20],
  dr7: [56, 174 - 20],
  dr8: [52, 209 - 20],
}
