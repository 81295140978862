import { FC, useEffect, useState } from "react"
import React from "react"
function useResizeObserver(): [
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  sizes: { height?: number; width?: number },
] {
  const [ref, setRef] = useState<HTMLElement | null>(null)
  const [sizes, setSizes] = useState<{ height?: number; width?: number }>({})
  useEffect(() => {
    if (!ref) return
    const observer = new ResizeObserver(() => {
      setSizes({ height: ref.offsetHeight, width: ref.offsetWidth })
    })
    observer.observe(ref)
    return () => {
      observer.disconnect()
      setSizes({})
    }
  }, [ref])
  return [setRef, sizes]
}

const WasmCanves: FC<{ className?: string; style?: object }> = (props) => {
  const [ref, sizes] = useResizeObserver()

  useEffect(() => {
    // console.log("size:", sizes)
    window.dispatchEvent(new Event("resize"))
  }, [sizes])
  return (
    <canvas
      ref={ref}
      onContextMenu={(e) => {
        //禁止右键
        e.preventDefault()
        return false
      }}
      id="canvas"
      {...props}
    />
  )
}
export default WasmCanves
