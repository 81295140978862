import { FC, useRef } from "react"
import { Box, Typography } from "@mui/material"

import UTooltip from "../../../../../containers/LandingLayout/PatientInfoHeader/UTootip"
import { UText } from "../../../../../ui-component"

import ImageAvatars from "./ImageAvatars"

export interface UserDetail {
  id: string
  firstName: string
  lastName: string
  preference: string
}

export interface IAssignedUserProps {
  user: UserDetail
  width: number
}

const AssignedUser: FC<IAssignedUserProps> = ({ user, width }) => {
  const { firstName, lastName, preference } = user
  const name = firstName + " " + lastName
  const nameContainer = useRef(null)
  const container = nameContainer.current
  return (
    name && (
      <Box
        data-testid={"assignedUser"}
        sx={{
          display: "flex",
          alignItems: "center",
          width: width && width - 32,
        }}
      >
        <ImageAvatars name={name} preference={preference} />
        <UTooltip
          titleContent={
            container && container.scrollWidth > container.clientWidth ? (
              <UText
                sxProp={{
                  p: 1,
                }}
                variant={"body2"}
              >
                {name}
              </UText>
            ) : null
          }
        >
          <Typography
            ref={nameContainer}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingLeft: 1,
            }}
          >
            {name}
          </Typography>
        </UTooltip>
      </Box>
    )
  )
}

export default AssignedUser
