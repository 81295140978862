import { SvgIcon, SvgIconProps } from "@mui/material"

export const LeftArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:svg="http://www.w3.org/2000/svg"
      >
        <path
          d="M 23.803681,0.21472393 1.3312881,13.95092 23.803681,27.687116 Z"
          fill="gray"
        />
      </svg>
    </SvgIcon>
  )
}
export const RightArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 1.3312881,0.21472393 23.803681,13.95092 1.3312881,27.687116 Z"
          fill="gray"
        />
      </svg>
    </SvgIcon>
  )
}
