import { createMachine, assign, sendUpdate } from "xstate";
import { toothCorrectionModule } from "./toothcorrectiondispatcher";
import { wasmModule } from "../../modules/wasm/wasmModule";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";

/**
 * 进入或者退出Inout mode
 * 进出本功能都需要激活/关闭功能状态moduleManager.SwitchMeshDeformModule(enabled:boolean)
 * 这是使用其他功能的前提
 * param:
 *  enabled boolean true开启 false关闭
 */
// moduleManager.SwitchMeshDeformModule(enabled:boolean);

/**
 * 开启关闭SelectCellMode
 * param:
 *  enabled boolean true开启 false关闭
 */
// moduleManager.GetMeshDeformModule().StartSelectCells(enabled:boolean);

/**
 * 开启关闭DeformMode
 * param:
 *  isLeftward boolean 计算方向 true向外 false向内
 *  enabled boolean true开启 false关闭
 */
// moduleManager.GetMeshDeformModule().StartDeform(isLeftward:boolean, enabled:boolean);

/**
 * 开启关闭 Smooth Mode
 * param:
 *  num number smooth平滑参数(0-1)
 *  enabled boolean true开启 false关闭
 *
 */
// moduleManager.GetMeshDeformModule().StartSmoothMesh(num:number, enabled:boolean)

/**
 * 重置之前操作
 */
// moduleManager.GetMeshDeformModule().Reset()

/**
 * 返回上一步操作
 */
// moduleManager.GetMeshDeformModule().BackOneStep()

export enum EInoutType {
  Select = "select",
  Deform = "deform",
  Smooth = "smooth",
  Reset = "reset",
  BackOneStep = "backonestep",
}

class ToothCorrenctionInout {
  state;

  toggleInout(enabled: boolean) {
    console.log("SwitchMeshDeformModule....", enabled);
    this.moduleManager.SwitchMeshDeformModule(enabled);
  }

  startSelectCells(enabled: boolean) {
    if (!wasmModule.isInit) return;
    console.log(".....startSelectCells", enabled);
    this.moduleManager.GetMeshDeformModule().StartSelectCells(enabled);
  }

  enableDeform(enabled: boolean) {
    console.log(".....startDeform", enabled);
    this.moduleManager.GetMeshDeformModule().StartDeform(true, enabled);
  }

  startSmoothMesh(num: number) {
    console.log(".....startSmoothMesh", num);
    this.moduleManager.GetMeshDeformModule().StartSmoothMesh(num, true);
  }

  enableSmoothMesh(enabled: boolean) {
    this.moduleManager.GetMeshDeformModule().StartSmoothMesh(0, enabled);
  }

  reset() {
    console.log(".....reset");
    this.moduleManager.GetMeshDeformModule().Reset();
  }

  backOneStep() {
    console.log(".....backOneStep");
    this.moduleManager.GetMeshDeformModule().BackOneStep();
  }

  constructor() {
    this.state = createMachine({
      id: "inoutmodule",
      initial: "normal",
      context: {
        gangbi: false,
        inout: false,
        tooth: false,
      },
      states: {
        gangbi: {
          entry: [
            assign({ gangbi: (ctx: any) => true }),
            () => {
              this.startSelectCells(true);
            },
          ],
          exit: [
            assign({ gangbi: (ctx: any) => false }),
            () => {
              this.startSelectCells(false);
            },
          ],
        },
        inout: {
          entry: [
            assign({ inout: (ctx: any) => true }),
            context => {
              this.enableDeform(context.inout);
            },
          ],
          exit: [
            assign({ inout: (ctx: any) => false }),
            context => {
              this.enableDeform(context.inout);
            },
          ],
        },
        tooth: {
          entry: [
            assign({ tooth: (ctx: any) => true }),
            () => {
              this.enableSmoothMesh(true);
            },
          ],
          exit: [
            assign({ tooth: (ctx: any) => false }),
            () => {
              this.enableSmoothMesh(false);
            },
          ],
        },
        normal: {},
      },
      on: {
        TOGGLE_INOUT_GANGBI: [
          {
            target: "normal",
            cond: (context, event, guardMeta) => {
              return context.gangbi === true;
            },
          },
          {
            target: "gangbi",
            cond: (context, event, guardMeta) => {
              return context.gangbi === false;
            },
          },
        ],
        TOGGLE_INOUT_DEFORM: [
          {
            target: "normal",
            cond: (context, event, guardMeta) => {
              return context.inout === true;
            },
          },
          {
            target: "inout",
            cond: (context, event, guardMeta) => {
              return context.inout === false;
            },
          },
        ],
        TOGGLE_INOUT_TOOTH: [
          {
            target: "normal",
            cond: (context, event, guardMeta) => {
              return context.tooth === true;
            },
          },
          {
            target: "tooth",
            cond: (context, event, guardMeta) => {
              return context.tooth === false;
            },
          },
        ],
        CLOSE_ALL: "normal",
      },
    });
  }

  get moduleManager() {
    const ulabwinIns = wasmModule.getWasmObj()._ulabwinIns;
    const moduleManager = ulabwinIns.getModuleManager();
    return moduleManager;
  }

  doAction() {
    toothCorrectionModule.service.send("TOGGLE_INOUT");
  }
}

export const toothCorrenctionInout = new ToothCorrenctionInout();

/**
 * bind smooth model event
 */
globalEvents.on(GlobalEvents.TOOTH_CORRECTION_INOUT_SMOOTHMESH, num => {
  toothCorrenctionInout.startSmoothMesh(num);
});

/**
 * bind button clicked events
 */
globalEvents.on(
  GlobalEvents.TOOTH_CORRECTION_INOUT_CLICKED,
  (clickedType: EInoutType, value: any) => {
    switch (clickedType) {
      case EInoutType.Select:
        toothCorrectionModule.service.state.context.inoutRef.send(
          "TOGGLE_INOUT_GANGBI"
        );
        break;
      case EInoutType.Deform:
        toothCorrectionModule.service.state.context.inoutRef.send(
          "TOGGLE_INOUT_DEFORM"
        );
        break;
      case EInoutType.Smooth:
        toothCorrectionModule.service.state.context.inoutRef.send(
          "TOGGLE_INOUT_TOOTH"
        );
        break;
      case EInoutType.Reset:
        toothCorrenctionInout.reset();
        toothCorrectionModule.service.state.context.inoutRef.send("CLOSE_ALL");
        break;
      case EInoutType.BackOneStep:
        toothCorrenctionInout.backOneStep();
        toothCorrectionModule.service.state.context.inoutRef.send("CLOSE_ALL");
        break;
      default:
        break;
    }
    console.log("?????", toothCorrectionModule, toothCorrenctionInout.state);
  }
);
