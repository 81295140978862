import { FC, useEffect, useState } from "react"

import { Grid } from "../../../../../components/mui.components"

import USearch from "./USearch"

const UTableSearch: FC<{
  onSearch?: (value: string) => void | undefined
  searchText: string
}> = ({ onSearch, searchText }) => {
  const [search, setSearch] = useState<string>("")

  useEffect(() => {
    setSearch(searchText)
  }, [searchText])

  return (
    <USearch
      sxProp={{
        height: "40px",
        backgroundColor: "background.default",
        ".MuiInputBase-root": {
          height: "inherit  ",
        },
        ".MuiFormLabel-root": {
          top: "-6px",
          width: "100%",
        },
        ".MuiInputLabel-shrink": {
          top: 0,
        },
      }}
      search={search}
      updateSearch={(search: string) => {
        setSearch(search)
        onSearch && onSearch(search)
      }}
    />
  )
}

export default UTableSearch
