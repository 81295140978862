import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import {
  downloadCustomBoxFiles,
  getCustomPackagingListService,
  uploadNewCustomBoxService,
} from "./customPackaging.service"

export const getCustomPackagingList = createAsyncThunk(
  "customPackaging/getCustomPackagingList",
  async (_, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await getCustomPackagingListService(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const uploadNewCustomBoxImage = createAsyncThunk(
  "customPackaging/uploadNewCustomBoxImage",
  async (formData: FormData, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await uploadNewCustomBoxService(orgId, formData)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getCustomBoxFiles = createAsyncThunk(
  "customPackaging/getCustomBoxFiles",
  async (
    {
      accountId,
      imageType,
    }: { accountId: string; imageType: "top" | "side" | "box" },
    { rejectWithValue },
  ) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return await downloadCustomBoxFiles(orgId, accountId, imageType)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
