import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { UButton, UText } from "../../../components"
import { Box, Chip, TextField } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { placeAlignerOrders } from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import { PromoCode } from "../../../core/model/interface/AlignerOrder"

export const ApplyPromo: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { alignerOrderPayload } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  const [promoCodeList, setPromoCodeList] = useState<string[]>([])
  const [promo, setPromo] = useState<string>("")
  const [promoError, setPromoError] = useState<string>("")

  const validatePromoCode = async () => {
    if (!promo) return

    const promoPayload = {
      ...alignerOrderPayload,
      promo_code: [...promoCodeList, promo],
    }

    const res = await dispatch(placeAlignerOrders(promoPayload))

    if (res?.payload?.promo_code) {
      const newPromoCodes = res.payload.promo_code
      const validPromos = newPromoCodes.filter(
        (code: PromoCode) => !code.error_code,
      )
      const newCodes = validPromos
        .map((code) => code.code)
        .filter((code) => !promoCodeList.includes(code))

      if (validPromos.length > 0) {
        setPromoCodeList((prevCodes) => [...prevCodes, ...newCodes])
      }

      const errorPromo = newPromoCodes.find(
        (code: PromoCode) => code.error_code,
      )
      if (errorPromo) {
        setPromoError(errorPromo.message)
      } else {
        setPromo("")
      }
    }
  }

  const deletePromo = (code: string) => {
    const updatedPromoList = promoCodeList.filter((item) => item !== code)
    dispatch(
      placeAlignerOrders({
        ...alignerOrderPayload,
        promo_code: updatedPromoList,
      }),
    )
    setPromoCodeList(updatedPromoList)
  }

  const handlePromoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "")
    setPromo(inputValue)
    setPromoError("")
  }

  return (
    <>
      <UText variant="caption">{t("Promotion Code")}</UText>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", columnGap: 1 }}
      >
        <TextField
          sx={{ width: "100%" }}
          size="small"
          value={promo}
          error={!!promoError}
          onChange={handlePromoChange}
        />
        <UButton
          disabled={promo.length < 6 || promoCodeList.includes(promo)}
          variant="contained"
          btnText={t("Apply")}
          size="small"
          onClickHandler={validatePromoCode}
        />
      </Box>
      {promoError && (
        <UText
          component="div"
          variant="caption"
          color="error.main"
          sxProp={{ mt: "3px" }}
        >
          {promoError}
        </UText>
      )}
      {promoCodeList.map((code) => (
        <Chip
          key={code}
          sx={{ my: 1, textTransform: "uppercase" }}
          label={code}
          onDelete={() => deletePromo(code)}
        />
      ))}
    </>
  )
}
