import { useState } from "react"
import { ReactSVG } from "react-svg"
import CreateCaseIcon from "@mui/icons-material/AddCircleOutline"
import BoltonIcon from "@mui/icons-material/BarChartOutlined"
import RxIcon from "@mui/icons-material/BookOutlined"
import InsertPhoto from "@mui/icons-material/InsertPhoto"
import MsgIcon from "@mui/icons-material/MailLockOutlined"
import PhotoIcon from "@mui/icons-material/PhotoOutlined"
import LRPhotosIcon from "@mui/icons-material/PhotoAlbum"
import LRStlIcon from "@mui/icons-material/AddOutlined"
import ReportIcon from "@mui/icons-material/PictureAsPdf"
import IPRReportIcon from "@mui/icons-material/ReportOffOutlined"
import TMTableIcon from "@mui/icons-material/TableViewOutlined"
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined"
import {
  Badge,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

import Tooth from "../assets/left/tooth-outline.svg"
import { setCurLeftPage } from "../udTreatSlice"
import { useTheme } from "@mui/material/styles"
import { setSmartRxShow } from "../udTreatSlice"
export function LeftToolBar(props: any) {
  const theme = useTheme()
  const {
    isCasePreview,
    curCaseMode,
    curLeftPage,
    curRightPage,
    ucloud1_1,
    isNewCaseHasUpload,
    smartRxShow,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [toolName, SetToolName] = useState("")
  const dispatch = useAppDispatch()
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    if (nextTool === "smartRx") {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
      } else {
        dispatch(setSmartRxShow(true))
      }
    } else {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
      }
    }
    SetToolName(nextTool)
    dispatch(setCurLeftPage(nextTool))
  }
  const [selPhoto, setSelPhoto] = useState(false)

  const NewCaseBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        {!ucloud1_1 && (
          <Tooltip title="Upload Scan V2" placement="right">
            <ToggleButton value="createcasev2">
              <CreateCaseIcon />
            </ToggleButton>
          </Tooltip>
        )}
        {!ucloud1_1 && (
          <Tooltip title="uAssist" placement="right">
            <ToggleButton value="uassist">
              <MsgIcon />
            </ToggleButton>
          </Tooltip>
        )}
        {(isNewCaseHasUpload ||
          caseDetail?.udesign_json?.includes("upperUploaded")) && (
          <PatientInfoBtnGroup />
        )}
      </ToggleButtonGroup>
    )
  }
  const AllBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="Upload Scan V2" placement="right">
          <ToggleButton value="createcasev2">
            <CreateCaseIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="uAssist" placement="right">
          <ToggleButton value="uassist">
            <MsgIcon />
          </ToggleButton>
        </Tooltip>
        <PatientInfoBtnGroup />
        <Tooltip title="Bolton Analysis" placement="right">
          <ToggleButton value="bolton">
            <BoltonIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="IPR/Aux Report" placement="right">
          <IconButton
            onClick={() => {
              if (curLeftPage === "report") {
                handleChange(undefined, "")
              } else {
                handleChange(undefined, "report")
              }
            }}
            value="report"
          >
            <IPRReportIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Tooth Movement Table" placement="right">
          <ToggleButton value="tmtable">
            <TMTableIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Local Photos" placement="right">
          <ToggleButton value="lrphotos">
            <LRPhotosIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Local STLs" placement="right">
          <ToggleButton value="lrstls">
            <LRStlIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const ModiBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="uAssist" placement="right">
          <ToggleButton value="uassist">
            <MsgIcon />
          </ToggleButton>
        </Tooltip>
        <PatientInfoBtnGroup />
        <Tooltip title="IPR/Aux Report" placement="right">
          <IconButton
            onClick={() => {
              if (curLeftPage === "report") {
                handleChange(undefined, "")
              } else {
                handleChange(undefined, "report")
              }
            }}
            value="report"
          >
            <IPRReportIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Tooth Movement Table" placement="right">
          <ToggleButton value="tmtable">
            <TMTableIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const ViewBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="uAssist" placement="right">
          <ToggleButton value="uassist">
            <MsgIcon />
          </ToggleButton>
        </Tooltip>
        <PatientInfoBtnGroup />
        <Tooltip title="IPR/Aux Report" placement="right">
          <IconButton
            onClick={() => {
              if (curLeftPage === "report") {
                handleChange(undefined, "")
              } else {
                handleChange(undefined, "report")
              }
            }}
            value="report"
          >
            <IPRReportIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Tooth Movement Table" placement="right">
          <ToggleButton value="tmtable">
            <TMTableIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }

  const UcloudButtons = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        {caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" &&
          isCasePreview && (
            <Tooltip title="Upload Scan" placement="right">
              <ToggleButton value="createcase">
                <CreateCaseIcon />
              </ToggleButton>
            </Tooltip>
          )}
        <Tooltip title="Photos and X-rays" placement="right">
          <IconButton
            size="medium"
            onClick={() => {
              if (curLeftPage === "records") {
                handleChange(undefined, "")
                return
              }
              handleChange(undefined, "records")
            }}
          >
            <InsertPhoto
              color={curLeftPage === "records" ? "primary" : "inherit"}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Dental Chart" placement="right">
          <IconButton
            size="medium"
            // sx={{ marginTop: 1 }}
            onClick={() => {
              handleChange(undefined, "dentalchart")
            }}
          >
            <ReactSVG
              src={Tooth}
              afterInjection={(svg) => {
                const path = svg.getElementsByTagName("path")[0]
                if (curLeftPage === "dentalchart") {
                  path?.setAttribute("fill", "#215ECD")
                } else {
                  path?.setAttribute("fill", "black")
                }
              }}
            ></ReactSVG>
          </IconButton>
        </Tooltip>
        <Tooltip title="Smart Rx" placement="right">
          <IconButton
            onClick={() => {
              handleChange(undefined, "smartRx")
            }}
            value="smartRx"
          >
            <EditCalendarOutlinedIcon />
          </IconButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }

  const PatientInfoBtnGroup = () => {
    return (
      <>
        <Tooltip title="Photos and X-rays" placement="right">
          <IconButton
            size="medium"
            onClick={() => {
              if (curLeftPage === "records") {
                handleChange(undefined, "")
                return
              }
              handleChange(undefined, "records")
            }}
          >
            {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 4,
                top: 4,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
            <InsertPhoto
              color={curLeftPage === "records" ? "primary" : "inherit"}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Dental Chart" placement="right">
          <IconButton
            size="medium"
            // sx={{ marginTop: 1 }}
            onClick={() => {
              handleChange(undefined, "dentalchart")
            }}
          >
            {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 4,
                top: 4,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
            <ReactSVG
              src={Tooth}
              afterInjection={(svg) => {
                const path = svg.getElementsByTagName("path")[0]
                if (curLeftPage === "dentalchart") {
                  path?.setAttribute("fill", "#215ECD")
                } else {
                  path?.setAttribute("fill", "black")
                }
              }}
            ></ReactSVG>
          </IconButton>
        </Tooltip>
        <Tooltip title="Smart Rx" placement="right">
          <IconButton
            onClick={() => {
              handleChange(undefined, "smartRx")
            }}
            value="smartRx"
          >
            <EditCalendarOutlinedIcon />
          </IconButton>
        </Tooltip>
      </>
    )
  }
  return (
    <Paper
      sx={{
        width: "48px",
        borderRadius: "8px",
        left: 28,
        top: 104,
        backgroundColor: theme.transBkColor.light,
        maxHeight: `calc(100% - 114px)`,
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "1px",
        },
        position: "absolute",
      }}
      elevation={6}
    >
      <Stack direction="column">
        {ucloud1_1 ? (
          <UcloudButtons />
        ) : curCaseMode == "NewCase" ? (
          <NewCaseBtns />
        ) : curCaseMode == "UDPlan" ? (
          <AllBtns />
        ) : curCaseMode == "UDModi" ? (
          <ModiBtns />
        ) : curCaseMode == "UDView" ? (
          <ViewBtns />
        ) : curCaseMode == "WeDesign" ? (
          <AllBtns />
        ) : (
          <></>
        )}
      </Stack>
    </Paper>
  )
}
