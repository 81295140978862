import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import CaseType from "./CaseType/CaseType"
import PatientForm from "./PatientForm/PatientForm"
import PatientList from "./PatientList/PatientList"

const PatientModule: FC = () => {
  return (
    <Routes>
      <Route path={""} element={<PatientList />} />
      <Route path={"update/:patientId"} element={<PatientForm />} />
      <Route path={"new/"} element={<PatientForm />} />
      <Route
        path={"/choosetype/patient/:patientId/case/:caseId/"}
        element={<CaseType />}
      />
      <Route path={"*"} element={<Navigate to={""} replace />} />
    </Routes>
  )
}

export default PatientModule
