import React from "react"
import { MaterialLayoutRenderer } from "@jsonforms/material-renderers"
import { withJsonFormsLayoutProps } from "@jsonforms/react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
  Typography,
} from "@mui/material"

import { UText } from "../../../ui-component"

const UAccordionContainer = (props) => {
  const { uischema, schema, path, visible, renderers } = props

  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: "column",
    visible: visible,
    uischema: uischema,
    renderers: renderers,
  }
  return (
    <Hidden xsUp={!visible}>
      <Accordion
        sx={{
          boxShadow: "none",
          "&.Mui-expanded": { marginTop: "0px" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ height: "24px", width: "24px" }} />}
          sx={{
            backgroundColor: "#EEE",
            marginBottom: "4px",
            minHeight: "40px",
            height: "40px",
            padding: "8px",
            my: "4px",
            "&.Mui-expanded": { minHeight: "40px", height: "40px" },
          }}
        >
          <UText
            variant={"caption"}
            sxProp={{
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.17px",
              textTransform: "uppercase",
              color: "rgba(0, 0, 0, 0.6)",
              margin: 0,
            }}
          >
            {uischema.label}
          </UText>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>
          <MaterialLayoutRenderer {...layoutProps} />
        </AccordionDetails>
      </Accordion>
    </Hidden>
  )
}

export default withJsonFormsLayoutProps(UAccordionContainer)
