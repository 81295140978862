import { FC } from "react"

import vector from "../../../assets/images/clinical/vector.png"
import { Grid, ImageList, ImageListItem, SxProps } from "../../mui.components"
import { UText } from ".."

export interface IUImagePreviewProps {
  /**
   * Image file to render on screen.
   */
  files: { src: string; name: string | undefined }[]
  /**
   * Function to remove image.
   */
  deleteImage?: () => void
  /**
   * The width and height for the image
   */
  imageWidth?: number
  imageHeight?: number
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
   */
  sxImageProp?: SxProps
  showFileName?: boolean
}

const UImagePreview: FC<IUImagePreviewProps> = ({
  files,
  deleteImage,
  imageWidth,
  imageHeight,
  sxImageProp,
  showFileName,
}) => {
  return (
    <Grid>
      <ImageList cols={1} gap={20} sx={{ mt: 0, mb: 0 }}>
        {files.map((file, index) => (
          <Grid container flexDirection={"column"} gap={"10px"} key={index}>
            <ImageListItem
              key={index}
              sx={{
                "& .MuiImageListItem-img": {
                  width: imageWidth,
                  height: imageHeight,
                  objectFit: "contain",
                },
                borderRadius: "4px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                ...sxImageProp,
              }}
            >
              <img src={file.src} alt={file.name} loading="lazy" />
            </ImageListItem>
            {showFileName && (
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                width={imageWidth}
                sx={{ height: "28px" }}
              >
                <UText
                  variant={"caption"}
                  color={"text.secondary"}
                  sxProp={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "75%",
                    whiteSpace: "nowrap",
                  }}
                >
                  {file.name}
                </UText>
                <input
                  style={{ height: 12, width: 12, marginRight: 15 }}
                  type="image"
                  src={vector}
                  alt="delete"
                  onClick={deleteImage}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </ImageList>
    </Grid>
  )
}

UImagePreview.defaultProps = {
  sxImageProp: undefined,
  showFileName: false,
}

export default UImagePreview
