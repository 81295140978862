import { useEffect, useState } from "react"
import { Box, Button, Stack, ToggleButton } from "@mui/material"
import { biteRampModule, wasmModule } from "@/gluelayer"

const BiteRampButtons = () => {
  // button overlay
  const [openBiteOverlay, setOpenBiteOverlay] = useState<boolean>(false)
  // all plane position and isShow
  const [planeObj, setPlaneObj] = useState<{
    x: number
    y: number
    isShow: boolean
  }>({ x: 0, y: 0, isShow: false })

  useEffect(() => {
    if (!wasmModule.isInit) return

    biteRampModule.onButtonPosChangeCallback(
      (x: number, y: number, isShow: boolean) => {
        setPlaneObj({ x, y, isShow })
      },
    )
    if (planeObj.isShow) {
      setOpenBiteOverlay(true)
    }
  }, [wasmModule.isInit])
  return (
    <Box
      id="biteBox"
      sx={{
        position: "absolute",
        display: planeObj.isShow ? "block" : "none",
        left: planeObj.x,
        bottom: planeObj.y,
      }}
    >
      <Stack direction="column" spacing={0.5}>
        <ToggleButton
          value={"3x3Range"}
          onClick={() => {
            setOpenBiteOverlay(!openBiteOverlay)
            biteRampModule.onShowDownTeethIn3to3Range(!openBiteOverlay)
          }}
          selected={openBiteOverlay}
        >
          3x3Range
        </ToggleButton>
        <Button
          variant="contained"
          onClick={() => {
            biteRampModule.syncBiteRampHeightToPlane()
          }}
        >
          HeightToPlane
        </Button>
      </Stack>
    </Box>
  )
}
export default BiteRampButtons
