import { ChangeEvent, FC } from "react"
import { useTranslation } from "react-i18next"

import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateSelectedShippingMethode } from "../../../../core/app/slices/order"
import { RootState } from "../../../../core/app/store"
import { formatCurrency } from "../../../../core/utils/formatters"
import { UText } from "../../../../ui-component"
import Banner from "../../../Patient/Overview/components/banner"

export interface IDelivery {
  title: string
  text1: string
  text2: string
  text3: string
  amount: string
}

export interface IDeliveryServiceProps {
  isReviewOrder: boolean
}

const DeliveryService: FC<IDeliveryServiceProps> = ({ isReviewOrder }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { shippingMethod, selectedBundle, currentOrder, alignerOrderPayload } =
    useAppSelector((state: RootState) => state.orderService)
  let deliveryService = [
    {
      title: "Standard",
      type: "Standard",
      //text1: "Standard shipping",
      //text2: "10-business days from order acceptance",
      text3: "Schedule patient no earlier than 3 weeks from order submission",
      amount: "Included",
    },
  ]
  if (
    !(countryCode === "AU" || countryCode === "NZ") ||
    selectedBundle?.name === "A La Carte"
  ) {
    deliveryService.push(
      {
        title: "Premium",
        type: "Premium",
        //text1: "2-day shipping",
        //text2: "5-business days from order acceptance",
        text3: "Schedule patient no earlier than 2 weeks from order submission",
        amount: "$30",
      },
      {
        title: "Priority express",
        type: "Priority express",
        //text1: "Overnight shipping",
        //text2: "3-business days from order acceptance",
        text3: "Schedule patient no earlier than 1 weeks from order submission",
        amount: "$75",
      },
    )
  }
  //when its reainer display only Premium for US,CA customer
  if (
    !(countryCode === "AU" || countryCode === "NZ") &&
    caseDetail.udesign_category?.includes("R") &&
    !caseDetail.udesign_category?.includes("A") &&
    selectedBundle?.name !== "A La Carte"
  ) {
    deliveryService = [
      {
        title: "Premium",
        type: "Premium",
        text1: "2-day shipping",
        text2: "5-business days from order acceptance",
        text3: "Schedule patient no earlier than 2 weeks from order submission",
        amount: "$30",
      },
    ]
    dispatch(updateSelectedShippingMethode(deliveryService[0]?.type))
  }

  if (isReviewOrder) {
    deliveryService = deliveryService.filter(
      (o) => o.type?.toLowerCase() === shippingMethod?.toLowerCase(),
    )
  }

  const handleDeliveryServiceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateSelectedShippingMethode((event.target as HTMLInputElement).value),
    )
  }
  const getShippingPrice = (price: string): string => {
    if (isReviewOrder && currentOrder?.orderId) {
      const shipprice =
        currentOrder?.alacarte?.shipping_price?.item_total_value ||
        currentOrder?.bundle?.shipping_price?.item_total_value
      if (!isNaN(Number(shipprice))) {
        return formatCurrency(
          Number(shipprice),
          currentOrder.currency,
          countryCode,
        )
      } else {
        return shipprice.charAt(0).toUpperCase() + shipprice.slice(1)
      }
    } else {
      return price
    }
  }

  const checkBundleLimit = () => {
    const remainingCount = selectedBundle?.description

    if (!remainingCount) {
      return false
    }

    const alignerPayload = alignerOrderPayload?.stage.aligners
    const retainerPayload = alignerOrderPayload?.stage.retainers
    const templatesPayload = alignerOrderPayload?.stage.templates

    if (
      remainingCount.aligner?.included - remainingCount.aligner?.consumed <
        alignerPayload.up.length + alignerPayload.low.length ||
      remainingCount.retainer?.included - remainingCount.retainer?.consumed <
        retainerPayload.up + retainerPayload.low ||
      remainingCount.retainer?.included - remainingCount.retainer?.consumed <
        templatesPayload.up + templatesPayload.low
    ) {
      return true
    } else {
      return false
    }
  }

  const showWarningBanner = () => {
    if (
      (isReviewOrder &&
        currentOrder?.alacarte?.pricing_option &&
        currentOrder?.bundle?.pricing_option) ||
      (!isReviewOrder && selectedBundle && checkBundleLimit())
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Paper elevation={0} sx={{ padding: "16px", borderRadius: 4 }}>
      <Box>
        <UText component={"div"} variant={"subtitle2"} sxProp={{ mb: 2 }}>
          {t("overview.order.shippingPacking.deliveryService")}
        </UText>
        <RadioGroup
          aria-labelledby="shipping_method-group"
          name="shipping_method"
          onChange={handleDeliveryServiceChange}
        >
          {deliveryService.map((item, index) => (
            <Box
              key={item.type}
              sx={{ borderBottom: "1px solid #E0E0E0", pb: 2 }}
            >
              <Box sx={{ mx: isReviewOrder ? 2 : 3 }}>
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item md={10}>
                    {!isReviewOrder ? (
                      <Box>
                        <FormControlLabel
                          value={item.type}
                          checked={
                            shippingMethod?.toLowerCase() ===
                            item.type.toLowerCase()
                          }
                          sx={{
                            "> .MuiFormControlLabel-label": {
                              fontWeight: "400 !important",
                            },
                          }}
                          control={<Radio />}
                          label={item.title}
                        />
                      </Box>
                    ) : (
                      <Box>{item.title}</Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <UText sxProp={{ p: "12px" }} variant={"subtitle2"}>
                      {getShippingPrice(item.amount)}
                    </UText>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    color: "#464646",
                    fontSize: "12px",
                    ml: isReviewOrder ? 1 : 5,
                    mt: !isReviewOrder ? "-10px" : "0px",
                  }}
                >
                  <li>{item.text3}</li>
                </Box>
              </Box>
            </Box>
          ))}
        </RadioGroup>
        <Box sx={{ mt: 2 }}>
          <UText variant={"caption"} sxProp={{ color: "text.secondary" }}>
            {t("overview.order.shippingPacking.deliveryNote")}
          </UText>
        </Box>
        {showWarningBanner() && (
          <Box sx={{ mt: 2 }}>
            <Banner
              severity="warning"
              bannerContent={t("bundle.banner.seperateShipmentsWarning")}
            />
          </Box>
        )}
        {(countryCode === "AU" || countryCode === "NZ") && (
          <Box
            sx={{
              mt: 2,
              ".MuiAlert-root": {
                alignItems: "flex-start",
              },
              ".MuiAlert-icon": {
                mt: "2px",
              },
            }}
          >
            <Banner
              severity="warning"
              bannerContent={t("bundle.banner.anzShippingSurage")}
            />
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default DeliveryService
