import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, Link, Grid } from "@mui/material"
import { useParams } from "react-router-dom"
import successSvg from "../assets/formOk/success.svg"
const FormOk: FC = () => {
  const { t } = useTranslation("common")
  const { caseId } = useParams()
  return (
    <Box>
      <Typography
        sx={{ marginBottom: "24px", padding: "8px 0" }}
        variant="h5"
      >{`Thank you for submitting your case to uAssist`}</Typography>
      <Grid container>
        <Grid item sx={{ marginRight: "8px" }}>
          <img src={successSvg} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{`Your case has been submitted`}</Typography>
        </Grid>
      </Grid>
      <Link
        href="#"
        variant="body1"
        sx={{ marginLeft: "28px" }}
      >{`Case#${caseId}`}</Link>
      <Typography
        variant="body2"
        sx={{ marginTop: "16px", lineHeight: "143%" }}
      >{`Our uAssistants will begin working on your case shortly! Please keep an eye on the case status on the Patient List screen for any updates.`}</Typography>
    </Box>
  )
}
export default FormOk
