import { createSlice } from "@reduxjs/toolkit"

import {
  getCustomBoxFiles,
  getCustomPackagingList,
  uploadNewCustomBoxImage,
} from "./customPackagingThunkApi"

const initialState: any = {
  loading: "idle",
  customListFetching: "idle",
  myCustomPackagingList: [],
}

export const customPackagingSlice = createSlice({
  name: "customPackagingService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomPackagingList.pending, (state) => {
      state.customListFetching = "pending"
    })
    builder.addCase(getCustomPackagingList.fulfilled, (state, action) => {
      const { records } = action.payload
      state.customListFetching = "succeeded"
      state.myCustomPackagingList = records || []
    })
    builder.addCase(getCustomPackagingList.rejected, (state) => {
      state.customListFetching = "failed"
    })

    builder.addCase(uploadNewCustomBoxImage.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadNewCustomBoxImage.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(uploadNewCustomBoxImage.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getCustomBoxFiles.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getCustomBoxFiles.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(getCustomBoxFiles.rejected, (state) => {
      state.loading = "failed"
    })
  },
})
