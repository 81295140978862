import { Event } from "../../../utils/event";
// import { notification } from 'antd';

// import request from '../../../../utils/request';

type ResourceType = "blob" | "json" | "text" | "zip";
const DOWNLOAD_WAITING_TIME = 10;

export interface DataFileInfo {
  path: string;
  name: string;
  reqType: ResourceType;
  isFetchCmmonFiles?: boolean;
  isStatic?: boolean;
}

/**
 * events
 */
export enum DownloaderEventType {
  /**
   * args: none
   */
  DOWNLOAD_START_EVENT = "DownloadStartEvent",

  /**
   * args: load percent
   */
  FETCH_DATA_EVENT = "FetchDataEvent",
  /**
   * args: ture or false
   */
  DOWNLOAD_FINISHED_EVENT = "DownloadEndEvent",
}

export class Downloader {
  /** Property------------------------------------------- */
  /**
   * filelist for download
   */
  loadFileList: DataFileInfo[] = [];
  events = new Event();
  outFileList: Map<string, any> = new Map<string, any>();
  percent = 0;
  loadErrorCount = 0;

  /** Functions------------------------------------------- */

  clear() {
    this.loadFileList = [];
    this.outFileList = new Map<string, any>();
    this.percent = 0;
    this.loadErrorCount = 0;
    this.events.offAll();
  }

  /**
   * the interface of register download file for custom
   * @param fileInfo
   */
  registerDownloadFiles(fileInfo: DataFileInfo) {
    this.loadFileList.push(fileInfo);
  }

  /**
   * interface to download all case resource
   * @param caseInfo
   * @param downloadType
   */
  async downloadFiles(uuid: string, isNoWait: boolean = false) {
    // this.percent = 0;
    // this.loadErrorCount = 0;

    const waitAMoment = () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, DOWNLOAD_WAITING_TIME);
      });
    };

    this.events.fire(
      DownloaderEventType.DOWNLOAD_START_EVENT,
      this.loadFileList
    );

    // download all file referenced of case
    for (let index = 0, len = this.loadFileList.length; index < len; index++) {
      const fileInfo = this.loadFileList[index];
      console.log("fetch::", fileInfo.path);
      if (fileInfo.isStatic && fileInfo.isStatic === true) {
        this.fetchCaseDataFromLocal(fileInfo);
      } else {
        this.fetchCaseDataFromRemote({
          ...fileInfo,
          uuid,
        } as any);
      }

      if (!isNoWait) {
        await waitAMoment();
      }
    }
  }

  private async requestData(url: string, reqType: ResourceType) {
    let ret;
    if (reqType === "blob") {
      // ret = request(url, { responseType: 'blob' });
    } else if (reqType === "json") {
      // ret = request(url, { responseType: 'json' });
    } else if (reqType === "text") {
      // ret = request(url, { responseType: 'text' });
    } else if (reqType === "zip") {
      // ret = request(url, { responseType: 'blob' });
    }
    return ret;
  }

  /**
   * fetch data by http request
   * @param param0
   */
  private async fetchCaseDataFromRemote(fechDataInfo: DataFileInfo) {
    const ret = await this.requestData(fechDataInfo.path, fechDataInfo.reqType);

    // await fechDataInfo.callback(ret);
    this.outFileList.set(fechDataInfo.name, ret);
    // console.log("fetch data done:", fechDataInfo.path);
    (fechDataInfo as any).resp = ret;
    // this.events.fire(DownloaderEventType.FETCH_DATA_EVENT, fechDataInfo);
    this.updateLoadPercent(ret);
  }

  private updateLoadPercent(fetchRet) {
    const loadCount = this.loadFileList.length;
    this.percent += 100 / loadCount;
    const errCount = this.loadErrorCount || 0;
    // if (fetchRet instanceof Response) {
    //   if (fetchRet.status !== 200 && fetchRet.status !== 404) {
    //     errCount += 1;
    //   }
    // }
    if (this.percent >= 99.9) {
      if (errCount > 0) {
        // notification.error({
        //   message: `数据下载错误`,
        // });
      }
      // console.log('download done!');
      this.events.fire(
        DownloaderEventType.DOWNLOAD_FINISHED_EVENT,
        errCount === 0
      );
    }
    // console.log('download ', this.percent);
    this.events.fire(DownloaderEventType.FETCH_DATA_EVENT, {
      percent: this.percent,
    });
  }

  /**
   * fetch data from local
   * @param fechDataInfo
   */
  private async fetchCaseDataFromLocal(fechDataInfo: DataFileInfo) {
    let ret;

    if (fechDataInfo.reqType === "blob" || "zip") {
      ret = await this.fetchFileData(fechDataInfo.path);
    } else if (fechDataInfo.reqType === "json") {
      ret = await this.fetchJsonData(fechDataInfo.path);
    } else if (fechDataInfo.reqType === "text") {
      ret = await this.fetchTextData(fechDataInfo.path);
    }

    // await fechDataInfo.callback(ret);
    this.outFileList.set(fechDataInfo.name, ret);
    // console.log("fetch data done:", fechDataInfo.path);
    (fechDataInfo as any).resp = ret;
    // this.events.fire(DownloaderEventType.FETCH_DATA_EVENT, fechDataInfo);
    this.updateLoadPercent(ret);
  }

  private async fetchFileData(url) {
    const resp: Response = await fetch(url, {
      method: "get",
      responseType: "arraybuffer",
    } as any);

    const blob = await resp.blob();
    return blob;
  }

  private async fetchJsonData(url) {
    const resp: Response = await fetch(url, {
      method: "get",
      responseType: "json",
    } as any);
    const result = await resp.json();
    return result;
  }

  private async fetchTextData(url) {
    const resp: Response = await fetch(url, {
      method: "get",
      responseType: "json",
    } as any);
    const result = await resp.text();
    return result;
  }
}
