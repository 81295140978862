import { wasmModule } from "../../modules/wasm/wasmModule";
import { createMachine, StateMachine, assign } from "xstate";
import { toothCorrectionModule } from "./toothcorrectiondispatcher";

/**
 * 开启/关闭 Extraction 功能
 * param enabled   true:开启 false:关闭
 */
// moduleManager.ToggleToothExtraction(enabled:boolean)

class ToothExtractions {
  state;

  getState() {
    return this.state;
  }

  constructor() {
    this.state = createMachine({
      id: "extractionmodule",
      initial: "on",
      states: {
        on: {
          entry: [
            () => {
              this.moduleManager.ToggleToothExtraction(true);
            },
          ],
          exit: [
            () => {
              this.moduleManager.ToggleToothExtraction(false);
            },
          ],
        },
        off: {},
      },
    });
  }

  get moduleManager() {
    const ulabwinIns = wasmModule.getWasmObj()._ulabwinIns;
    const moduleManager = ulabwinIns.getModuleManager();
    return moduleManager;
  }

  doAction() {
    // const message = "Do you want to save the changes to the treatment plan?";
    // popupHelper.alertPopup("info", message, [
    //   {
    //     name: "ok",
    //     func: () => {
    //       toothCorrectionModule.service.send("TOGGLE_EXTRACTION");
    //     },
    //   },
    //   {
    //     name: "cancel",
    //     func: () => {},
    //   },
    // ]);
  }
}

export const toothExtractions = new ToothExtractions();
