export const allowStringwithOneSpace = {
  pattern: {
    value: /^[a-zA-Z\s]*$/g,
    message: "errors.invalidfieldvalue",
  },
}

export const allowStringonly = {
  pattern: {
    value: /^([a-zA-Z])*$/,
    message: "errors.invalidfieldvalue",
  },
}

export const minAndMaxValue = {
  min: {
    value: 2,
    message: "errors.invalidfieldvalue",
  },
  max: {
    value: 10,
    message: "errors.invalidfieldvalue",
  },
}

export const validations = {
  firstName: {
    required: "errors.field_required",
    ...allowStringwithOneSpace,
  },
  lastName: {
    required: "errors.field_required",
    ...allowStringwithOneSpace,
  },
  city: {
    required: "errors.field_required",
    ...allowStringwithOneSpace,
  },
  required: {
    required: "errors.field_required",
  },
  numberOnly: {
    pattern: {
      value: /^[1-9][0-9]*$/,
      message: "errors.invalidfieldvalue",
    },
  },
  email: {
    required: "errors.field_required",
    pattern: {
      value:
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "formfieldErrors.userEmailinvalid",
    },
  },
  zipCode: {
    required: "errors.field_required",
    pattern: {
      value: /^\d*$/,
      message: "errors.invalidfieldvalue",
    },
  },
  phoneNumber: {
    required: "errors.field_required",
    pattern: {
      value: /^(\d{10})$/,
      message: "errors.invalidfieldvalue",
    },
  },
  month: {
    required: "errors.field_required",
    pattern: {
      value: /^(0?[1-9]|1[0-2])$/,
      message: "formfieldErrors.invalidMonth",
    },
    inputFormat: /^[0-9]+$/,
  },
  day: {
    required: "errors.field_required",
    pattern: {
      value: /^(0?[1-9]|[12][0-9]|3[01])$/,
      message: "formfieldErrors.invalidDate",
    },
    inputFormat: /^[0-9]+$/,
  },
  year: {
    required: "errors.field_required",
    pattern: {
      value: /^\d{0,4}$/,
      message: "formfieldErrors.invalidYear",
    },
    inputFormat: /^[1-9][0-9]*$/,
  },
  answer: {
    pattern: {
      value: /^.{3,}$/,
    },
  },
}
