export const CountryCode = [
  {
    label: "Afghanistan +93",
    code: "+93",
    id: "AF",
  },
  {
    label: "Aland Islands +358",
    code: "+358",
    id: "AX",
  },
  {
    label: "Albania +355",
    code: "+355",
    id: "AL",
  },
  {
    label: "Algeria +213",
    code: "+213",
    id: "DZ",
  },
  {
    label: "AmericanSamoa +1684",
    code: "+1684",
    id: "AS",
  },
  {
    label: "Andorra +376",
    code: "+376",
    id: "AD",
  },
  {
    label: "Angola +244",
    code: "+244",
    id: "AO",
  },
  {
    label: "Anguilla +1264",
    code: "+1264",
    id: "AI",
  },
  {
    label: "Antarctica +672",
    code: "+672",
    id: "AQ",
  },
  {
    label: "Antigua and Barbuda +1268",
    code: "+1268",
    id: "AG",
  },
  {
    label: "Argentina +54",
    code: "+54",
    id: "AR",
  },
  {
    label: "Armenia +374",
    code: "+374",
    id: "AM",
  },
  {
    label: "Aruba +297",
    code: "+297",
    id: "AW",
  },
  {
    label: "Australia +61",
    code: "+61",
    id: "AU",
  },
  {
    label: "Austria +43",
    code: "+43",
    id: "AT",
  },
  {
    label: "Azerbaijan +994",
    code: "+994",
    id: "AZ",
  },
  {
    label: "Bahamas +1242",
    code: "+1242",
    id: "BS",
  },
  {
    label: "Bahrain +973",
    code: "+973",
    id: "BH",
  },
  {
    label: "Bangladesh +880",
    code: "+880",
    id: "BD",
  },
  {
    label: "Barbados +1246",
    code: "+1246",
    id: "BB",
  },
  {
    label: "Belarus +375",
    code: "+375",
    id: "BY",
  },
  {
    label: "Belgium +32",
    code: "+32",
    id: "BE",
  },
  {
    label: "Belize +501",
    code: "+501",
    id: "BZ",
  },
  {
    label: "Benin +229",
    code: "+229",
    id: "BJ",
  },
  {
    label: "Bermuda +1441",
    code: "+1441",
    id: "BM",
  },
  {
    label: "Bhutan +975",
    code: "+975",
    id: "BT",
  },
  {
    label: "Bolivia, Plurinational State of +591",
    code: "+591",
    id: "BO",
  },
  {
    label: "Bosnia and Herzegovina +387",
    code: "+387",
    id: "BA",
  },
  {
    label: "Botswana +267",
    code: "+267",
    id: "BW",
  },
  {
    label: "Brazil +55",
    code: "+55",
    id: "BR",
  },
  {
    label: "British Indian Ocean Territory +246",
    code: "+246",
    id: "IO",
  },
  {
    label: "Brunei Darussalam +673",
    code: "+673",
    id: "BN",
  },
  {
    label: "Bulgaria +359",
    code: "+359",
    id: "BG",
  },
  {
    label: "Burkina Faso +226",
    code: "+226",
    id: "BF",
  },
  {
    label: "Burundi +257",
    code: "+257",
    id: "BI",
  },
  {
    label: "Cambodia +855",
    code: "+855",
    id: "KH",
  },
  {
    label: "Cameroon +237",
    code: "+237",
    id: "CM",
  },
  {
    label: "Canada +1",
    code: "+1",
    id: "CA",
  },
  {
    label: "Cape Verde +238",
    code: "+238",
    id: "CV",
  },
  {
    label: "Cayman Islands + 345",
    code: "+ 345",
    id: "KY",
  },
  {
    label: "Central African Republic +236",
    code: "+236",
    id: "CF",
  },
  {
    label: "Chad +235",
    code: "+235",
    id: "TD",
  },
  {
    label: "Chile +56",
    code: "+56",
    id: "CL",
  },
  {
    label: "China +86",
    code: "+86",
    id: "CN",
  },
  {
    label: "Christmas Island +61",
    code: "+61",
    id: "CX",
  },
  {
    label: "Cocos (Keeling) Islands +61",
    code: "+61",
    id: "CC",
  },
  {
    label: "Colombia +57",
    code: "+57",
    id: "CO",
  },
  {
    label: "Comoros +269",
    code: "+269",
    id: "KM",
  },
  {
    label: "Congo +242",
    code: "+242",
    id: "CG",
  },
  {
    label: "Congo, The Democratic Republic of the Congo +243",
    code: "+243",
    id: "CD",
  },
  {
    label: "Cook Islands +682",
    code: "+682",
    id: "CK",
  },
  {
    label: "Costa Rica +506",
    code: "+506",
    id: "CR",
  },
  {
    label: "Cote d'Ivoire +225",
    code: "+225",
    id: "CI",
  },
  {
    label: "Croatia +385",
    code: "+385",
    id: "HR",
  },
  {
    label: "Cuba +53",
    code: "+53",
    id: "CU",
  },
  {
    label: "Cyprus +357",
    code: "+357",
    id: "CY",
  },
  {
    label: "Czech Republic +420",
    code: "+420",
    id: "CZ",
  },
  {
    label: "Denmark +45",
    code: "+45",
    id: "DK",
  },
  {
    label: "Djibouti +253",
    code: "+253",
    id: "DJ",
  },
  {
    label: "Dominica +1767",
    code: "+1767",
    id: "DM",
  },
  {
    label: "Dominican Republic +1849",
    code: "+1849",
    id: "DO",
  },
  {
    label: "Ecuador +593",
    code: "+593",
    id: "EC",
  },
  {
    label: "Egypt +20",
    code: "+20",
    id: "EG",
  },
  {
    label: "El Salvador +503",
    code: "+503",
    id: "SV",
  },
  {
    label: "Equatorial Guinea +240",
    code: "+240",
    id: "GQ",
  },
  {
    label: "Eritrea +291",
    code: "+291",
    id: "ER",
  },
  {
    label: "Estonia +372",
    code: "+372",
    id: "EE",
  },
  {
    label: "Ethiopia +251",
    code: "+251",
    id: "ET",
  },
  {
    label: "Falkland Islands (Malvinas) +500",
    code: "+500",
    id: "FK",
  },
  {
    label: "Faroe Islands +298",
    code: "+298",
    id: "FO",
  },
  {
    label: "Fiji +679",
    code: "+679",
    id: "FJ",
  },
  {
    label: "Finland +358",
    code: "+358",
    id: "FI",
  },
  {
    label: "France +33",
    code: "+33",
    id: "FR",
  },
  {
    label: "French Guiana +594",
    code: "+594",
    id: "GF",
  },
  {
    label: "French Polynesia +689",
    code: "+689",
    id: "PF",
  },
  {
    label: "Gabon +241",
    code: "+241",
    id: "GA",
  },
  {
    label: "Gambia +220",
    code: "+220",
    id: "GM",
  },
  {
    label: "Georgia +995",
    code: "+995",
    id: "GE",
  },
  {
    label: "Germany +49",
    code: "+49",
    id: "DE",
  },
  {
    label: "Ghana +233",
    code: "+233",
    id: "GH",
  },
  {
    label: "Gibraltar +350",
    code: "+350",
    id: "GI",
  },
  {
    label: "Greece +30",
    code: "+30",
    id: "GR",
  },
  {
    label: "Greenland +299",
    code: "+299",
    id: "GL",
  },
  {
    label: "Grenada +1473",
    code: "+1473",
    id: "GD",
  },
  {
    label: "Guadeloupe +590",
    code: "+590",
    id: "GP",
  },
  {
    label: "Guam +1671",
    code: "+1671",
    id: "GU",
  },
  {
    label: "Guatemala +502",
    code: "+502",
    id: "GT",
  },
  {
    label: "Guernsey +44",
    code: "+44",
    id: "GG",
  },
  {
    label: "Guinea +224",
    code: "+224",
    id: "GN",
  },
  {
    label: "Guinea-Bissau +245",
    code: "+245",
    id: "GW",
  },
  {
    label: "Guyana +595",
    code: "+595",
    id: "GY",
  },
  {
    label: "Haiti +509",
    code: "+509",
    id: "HT",
  },
  {
    label: "Holy See (Vatican City State) +379",
    code: "+379",
    id: "VA",
  },
  {
    label: "Honduras +504",
    code: "+504",
    id: "HN",
  },
  {
    label: "Hong Kong +852",
    code: "+852",
    id: "HK",
  },
  {
    label: "Hungary +36",
    code: "+36",
    id: "HU",
  },
  {
    label: "Iceland +354",
    code: "+354",
    id: "IS",
  },
  {
    label: "India +91",
    code: "+91",
    id: "IN",
  },
  {
    label: "Indonesia +62",
    code: "+62",
    id: "ID",
  },
  {
    label: "Iran, Islamic Republic of Persian Gulf +98",
    code: "+98",
    id: "IR",
  },
  {
    label: "Iraq +964",
    code: "+964",
    id: "IQ",
  },
  {
    label: "Ireland +353",
    code: "+353",
    id: "IE",
  },
  {
    label: "Isle of Man +44",
    code: "+44",
    id: "IM",
  },
  {
    label: "Israel +972",
    code: "+972",
    id: "IL",
  },
  {
    label: "Italy +39",
    code: "+39",
    id: "IT",
  },
  {
    label: "Jamaica +1876",
    code: "+1876",
    id: "JM",
  },
  {
    label: "Japan +81",
    code: "+81",
    id: "JP",
  },
  {
    label: "Jersey +44",
    code: "+44",
    id: "JE",
  },
  {
    label: "Jordan +962",
    code: "+962",
    id: "JO",
  },
  {
    label: "Kazakhstan +77",
    code: "+77",
    id: "KZ",
  },
  {
    label: "Kenya +254",
    code: "+254",
    id: "KE",
  },
  {
    label: "Kiribati +686",
    code: "+686",
    id: "KI",
  },
  {
    label: "Korea, Democratic People's Republic of Korea +850",
    code: "+850",
    id: "KP",
  },
  {
    label: "Korea, Republic of South Korea +82",
    code: "+82",
    id: "KR",
  },
  {
    label: "Kuwait +965",
    code: "+965",
    id: "KW",
  },
  {
    label: "Kyrgyzstan +996",
    code: "+996",
    id: "KG",
  },
  {
    label: "Laos +856",
    code: "+856",
    id: "LA",
  },
  {
    label: "Latvia +371",
    code: "+371",
    id: "LV",
  },
  {
    label: "Lebanon +961",
    code: "+961",
    id: "LB",
  },
  {
    label: "Lesotho +266",
    code: "+266",
    id: "LS",
  },
  {
    label: "Liberia +231",
    code: "+231",
    id: "LR",
  },
  {
    label: "Libyan Arab Jamahiriya +218",
    code: "+218",
    id: "LY",
  },
  {
    label: "Liechtenstein +423",
    code: "+423",
    id: "LI",
  },
  {
    label: "Lithuania +370",
    code: "+370",
    id: "LT",
  },
  {
    label: "Luxembourg +352",
    code: "+352",
    id: "LU",
  },
  {
    label: "Macao +853",
    code: "+853",
    id: "MO",
  },
  {
    label: "Macedonia +389",
    code: "+389",
    id: "MK",
  },
  {
    label: "Madagascar +261",
    code: "+261",
    id: "MG",
  },
  {
    label: "Malawi +265",
    code: "+265",
    id: "MW",
  },
  {
    label: "Malaysia +60",
    code: "+60",
    id: "MY",
  },
  {
    label: "Maldives +960",
    code: "+960",
    id: "MV",
  },
  {
    label: "Mali +223",
    code: "+223",
    id: "ML",
  },
  {
    label: "Malta +356",
    code: "+356",
    id: "MT",
  },
  {
    label: "Marshall Islands +692",
    code: "+692",
    id: "MH",
  },
  {
    label: "Martinique +596",
    code: "+596",
    id: "MQ",
  },
  {
    label: "Mauritania +222",
    code: "+222",
    id: "MR",
  },
  {
    label: "Mauritius +230",
    code: "+230",
    id: "MU",
  },
  {
    label: "Mayotte +262",
    code: "+262",
    id: "YT",
  },
  {
    label: "Mexico +52",
    code: "+52",
    id: "MX",
  },
  {
    label: "Micronesia, Federated States of Micronesia +691",
    code: "+691",
    id: "FM",
  },
  {
    label: "Moldova +373",
    code: "+373",
    id: "MD",
  },
  {
    label: "Monaco +377",
    code: "+377",
    id: "MC",
  },
  {
    label: "Mongolia +976",
    code: "+976",
    id: "MN",
  },
  {
    label: "Montenegro +382",
    code: "+382",
    id: "ME",
  },
  {
    label: "Montserrat +1664",
    code: "+1664",
    id: "MS",
  },
  {
    label: "Morocco +212",
    code: "+212",
    id: "MA",
  },
  {
    label: "Mozambique +258",
    code: "+258",
    id: "MZ",
  },
  {
    label: "Myanmar +95",
    code: "+95",
    id: "MM",
  },
  {
    label: "Namibia +264",
    code: "+264",
    id: "NA",
  },
  {
    label: "Nauru +674",
    code: "+674",
    id: "NR",
  },
  {
    label: "Nepal +977",
    code: "+977",
    id: "NP",
  },
  {
    label: "Netherlands +31",
    code: "+31",
    id: "NL",
  },
  {
    label: "Netherlands Antilles +599",
    code: "+599",
    id: "AN",
  },
  {
    label: "New Caledonia +687",
    code: "+687",
    id: "NC",
  },
  {
    label: "New Zealand +64",
    code: "+64",
    id: "NZ",
  },
  {
    label: "Nicaragua +505",
    code: "+505",
    id: "NI",
  },
  {
    label: "Niger +227",
    code: "+227",
    id: "NE",
  },
  {
    label: "Nigeria +234",
    code: "+234",
    id: "NG",
  },
  {
    label: "Niue +683",
    code: "+683",
    id: "NU",
  },
  {
    label: "Norfolk Island +672",
    code: "+672",
    id: "NF",
  },
  {
    label: "Northern Mariana Islands +1670",
    code: "+1670",
    id: "MP",
  },
  {
    label: "Norway +47",
    code: "+47",
    id: "NO",
  },
  {
    label: "Oman +968",
    code: "+968",
    id: "OM",
  },
  {
    label: "Pakistan +92",
    code: "+92",
    id: "PK",
  },
  {
    label: "Palau +680",
    code: "+680",
    id: "PW",
  },
  {
    label: "Palestinian Territory, Occupied +970",
    code: "+970",
    id: "PS",
  },
  {
    label: "Panama +507",
    code: "+507",
    id: "PA",
  },
  {
    label: "Papua New Guinea +675",
    code: "+675",
    id: "PG",
  },
  {
    label: "Paraguay +595",
    code: "+595",
    id: "PY",
  },
  {
    label: "Peru +51",
    code: "+51",
    id: "PE",
  },
  {
    label: "Philippines +63",
    code: "+63",
    id: "PH",
  },
  {
    label: "Pitcairn +872",
    code: "+872",
    id: "PN",
  },
  {
    label: "Poland +48",
    code: "+48",
    id: "PL",
  },
  {
    label: "Portugal +351",
    code: "+351",
    id: "PT",
  },
  {
    label: "Puerto Rico +1939",
    code: "+1939",
    id: "PR",
  },
  {
    label: "Qatar +974",
    code: "+974",
    id: "QA",
  },
  {
    label: "Romania +40",
    code: "+40",
    id: "RO",
  },
  {
    label: "Russia +7",
    code: "+7",
    id: "RU",
  },
  {
    label: "Rwanda +250",
    code: "+250",
    id: "RW",
  },
  {
    label: "Reunion +262",
    code: "+262",
    id: "RE",
  },
  {
    label: "Saint Barthelemy +590",
    code: "+590",
    id: "BL",
  },
  {
    label: "Saint Helena, Ascension and Tristan Da Cunha +290",
    code: "+290",
    id: "SH",
  },
  {
    label: "Saint Kitts and Nevis +1869",
    code: "+1869",
    id: "KN",
  },
  {
    label: "Saint Lucia +1758",
    code: "+1758",
    id: "LC",
  },
  {
    label: "Saint Martin +590",
    code: "+590",
    id: "MF",
  },
  {
    label: "Saint Pierre and Miquelon +508",
    code: "+508",
    id: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines +1784",
    code: "+1784",
    id: "VC",
  },
  {
    label: "Samoa +685",
    code: "+685",
    id: "WS",
  },
  {
    label: "San Marino +378",
    code: "+378",
    id: "SM",
  },
  {
    label: "Sao Tome and Principe +239",
    code: "+239",
    id: "ST",
  },
  {
    label: "Saudi Arabia +966",
    code: "+966",
    id: "SA",
  },
  {
    label: "Senegal +221",
    code: "+221",
    id: "SN",
  },
  {
    label: "Serbia +381",
    code: "+381",
    id: "RS",
  },
  {
    label: "Seychelles +248",
    code: "+248",
    id: "SC",
  },
  {
    label: "Sierra Leone +232",
    code: "+232",
    id: "SL",
  },
  {
    label: "Singapore +65",
    code: "+65",
    id: "SG",
  },
  {
    label: "Slovakia +421",
    code: "+421",
    id: "SK",
  },
  {
    label: "Slovenia +386",
    code: "+386",
    id: "SI",
  },
  {
    label: "Solomon Islands +677",
    code: "+677",
    id: "SB",
  },
  {
    label: "Somalia +252",
    code: "+252",
    id: "SO",
  },
  {
    label: "South Africa +27",
    code: "+27",
    id: "ZA",
  },
  {
    label: "South Sudan +211",
    code: "+211",
    id: "SS",
  },
  {
    label: "South Georgia and the South Sandwich Islands +500",
    code: "+500",
    id: "GS",
  },
  {
    label: "Spain +34",
    code: "+34",
    id: "ES",
  },
  {
    label: "Sri Lanka +94",
    code: "+94",
    id: "LK",
  },
  {
    label: "Sudan +249",
    code: "+249",
    id: "SD",
  },
  {
    label: "Surilabel +597",
    code: "+597",
    id: "SR",
  },
  {
    label: "Svalbard and Jan Mayen +47",
    code: "+47",
    id: "SJ",
  },
  {
    label: "Swaziland +268",
    code: "+268",
    id: "SZ",
  },
  {
    label: "Sweden +46",
    code: "+46",
    id: "SE",
  },
  {
    label: "Switzerland +41",
    code: "+41",
    id: "CH",
  },
  {
    label: "Syrian Arab Republic +963",
    code: "+963",
    id: "SY",
  },
  {
    label: "Taiwan +886",
    code: "+886",
    id: "TW",
  },
  {
    label: "Tajikistan +992",
    code: "+992",
    id: "TJ",
  },
  {
    label: "Tanzania, United Republic of Tanzania +255",
    code: "+255",
    id: "TZ",
  },
  {
    label: "Thailand +66",
    code: "+66",
    id: "TH",
  },
  {
    label: "Timor-Leste +670",
    code: "+670",
    id: "TL",
  },
  {
    label: "Togo +228",
    code: "+228",
    id: "TG",
  },
  {
    label: "Tokelau +690",
    code: "+690",
    id: "TK",
  },
  {
    label: "Tonga +676",
    code: "+676",
    id: "TO",
  },
  {
    label: "Trinidad and Tobago +1868",
    code: "+1868",
    id: "TT",
  },
  {
    label: "Tunisia +216",
    code: "+216",
    id: "TN",
  },
  {
    label: "Turkey +90",
    code: "+90",
    id: "TR",
  },
  {
    label: "Turkmenistan +993",
    code: "+993",
    id: "TM",
  },
  {
    label: "Turks and Caicos Islands +1649",
    code: "+1649",
    id: "TC",
  },
  {
    label: "Tuvalu +688",
    code: "+688",
    id: "TV",
  },
  {
    label: "Uganda +256",
    code: "+256",
    id: "UG",
  },
  {
    label: "Ukraine +380",
    code: "+380",
    id: "UA",
  },
  {
    label: "United Arab Emirates +971",
    code: "+971",
    id: "AE",
  },
  {
    label: "United Kingdom +44",
    code: "+44",
    id: "GB",
  },
  {
    label: "United States +1",
    code: "+1",
    id: "US",
  },
  {
    label: "Uruguay +598",
    code: "+598",
    id: "UY",
  },
  {
    label: "Uzbekistan +998",
    code: "+998",
    id: "UZ",
  },
  {
    label: "Vanuatu +678",
    code: "+678",
    id: "VU",
  },
  {
    label: "Venezuela, Bolivarian Republic of Venezuela +58",
    code: "+58",
    id: "VE",
  },
  {
    label: "Vietnam +84",
    code: "+84",
    id: "VN",
  },
  {
    label: "Virgin Islands, British +1284",
    code: "+1284",
    id: "VG",
  },
  {
    label: "Virgin Islands, U.S. +1340",
    code: "+1340",
    id: "VI",
  },
  {
    label: "Wallis and Futuna +681",
    code: "+681",
    id: "WF",
  },
  {
    label: "Yemen +967",
    code: "+967",
    id: "YE",
  },
  {
    label: "Zambia +260",
    code: "+260",
    id: "ZM",
  },
  {
    label: "Zimbabwe +263",
    code: "+263",
    id: "ZW",
  },
]
