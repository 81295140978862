import Deserialize from "./interface/Deserialize"
import { IBundle } from "./interface/IBundle"

export class Bundle implements IBundle, Deserialize {
  name = ""
  purchaseDate = ""
  expiryDate = ""
  aligner = {
    included: null,
    requested: null,
    consumed: null,
    leftAfterOrder: null,
  }
  retainer = {
    included: null,
    requested: null,
    consumed: null,
    leftAfterOrder: null,
  }
  template = {
    included: null,
    requested: null,
    consumed: null,
    leftAfterOrder: null,
  }
  Deserialize(input: any): this {
    if (input) {
      this.name = input.name
      this.purchaseDate = input.purchase_date
      this.expiryDate = input.expiry_date
      if (input.description) {
        if (input.description.aligner) {
          const aligner = input.description.aligner
          this.aligner = {
            included: aligner.included,
            consumed: aligner.consumed,
            leftAfterOrder: aligner.leftAfterOrder,
            requested: aligner.requested,
          }
        }
        if (input.description.retainer) {
          const retainer = input.description.retainer
          this.retainer = {
            included: retainer.included,
            consumed: retainer.consumed,
            leftAfterOrder: retainer.leftAfterOrder,
            requested: retainer.requested,
          }
        }
        if (input.description.template) {
          const template = input.description.template
          this.template = {
            included: template.included,
            consumed: template.consumed,
            leftAfterOrder: template.leftAfterOrder,
            requested: template.requested,
          }
        }
      }
    }
    return this
  }
}
