import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useAppDispatch } from "../../core/app/hooks"
import { unlockCaseApi } from "../../core/app/slices/clinical/clinicalThunkApi"
import { useMyNavigation } from "../../hooks/useMyNavigation"
import { UButton, UModalPopup, UText } from "../../ui-component/components"
import { Box } from "../../ui-component/mui.components"

const ExitOrderAlertPopup: FC<{
  showAlert: boolean
  closeAlert: () => void
  buttonTxt: string
}> = ({ showAlert, closeAlert, buttonTxt }) => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()

  const handleNavigate = () => {
    if (buttonTxt === "Back") {
      dispatch(unlockCaseApi({ patientId, caseId })).then((res) => {
        navigate(`/clinical/patient/${patientId}/case/${caseId}/treat`)
      })
    } else {
      navigate("/patients")
    }
  }

  return (
    <UModalPopup
      isAlert={showAlert}
      title={
        <UText
          variant={"h6"}
          sxProp={{
            width: "396px",
          }}
        >
          {t("exitBundlePopup.title")}
        </UText>
      }
      sxModalProps={{
        ".MuiDialog-paper": {
          maxWidth: 444,
        },
        "#titleCntr": {
          padding: "16px 24px",
        },
        "#contentCntr": {
          padding: "20px 24px",
        },
        "#btnListCntr": {
          padding: 1,
          gap: 1,
        },
      }}
      content={
        <Box component={"div"}>
          <UText variant={"body1"} sxProp={{ display: "block" }}>
            {t("exitBundlePopup.description")}
          </UText>
        </Box>
      }
      btnList={[
        <UButton
          key={t("exitBundlePopup.continueBundleBtn")}
          variant="shade"
          btnText={t("exitBundlePopup.continueBundleBtn")}
          onClickHandler={closeAlert}
          sxProp={{
            height: 36,
          }}
        ></UButton>,
        <UButton
          key={t("exitBundlePopup.leaveBtn")}
          variant="contained"
          btnText={t("exitBundlePopup.leaveBtn")}
          sxProp={{
            width: 128,
            height: 36,
          }}
          onClickHandler={handleNavigate}
        ></UButton>,
      ]}
    ></UModalPopup>
  )
}
export default ExitOrderAlertPopup
