import { and, RankedTester, rankWith, schemaMatches } from "@jsonforms/core"

export const URadioGroupTester: RankedTester = rankWith(
  3,
  and(
    schemaMatches((schema, uiSchema) => {
      // eslint-disable-next-line no-prototype-builtins
      return schema.hasOwnProperty("isRadioGroup")
    }),
  ),
)
