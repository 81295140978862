import { FC } from "react"
import { useTranslation } from "react-i18next"

import { UText } from "../../components"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "../../components/mui.components"
import { Volume } from "../../core/app/slices/pricing/pricingSlice"

export const VolumeCard: FC<{
  isStl?: boolean
  title: string
  volume: Volume
}> = ({ isStl, title, volume }) => {
  const { t } = useTranslation()

  const getCount = (
    alignerCount: string | number,
    retainerCount: string | number,
  ) => {
    return (
      <Box component={"div"} sx={{ visibility: isStl ? "hidden" : "" }}>
        <UText
          component={"div"}
          variant="subtitle2"
          sxProp={{ color: "#777777", lineHeight: "24px", pt: 1 }}
        >
          Aligners {alignerCount}
        </UText>
        <UText
          variant="subtitle2"
          component={"div"}
          sxProp={{ color: "#777777", lineHeight: "24px", pt: 1 }}
        >
          Retainers {retainerCount}
        </UText>
      </Box>
    )
  }
  return (
    <Card
      className="volume-card"
      sx={{ borderColor: !isStl ? "#215ECD" : "#9c27b0" }}
    >
      <CardHeader
        className="volume-header"
        title={
          <Box className="volume-title">
            <UText variant="body2">{title}</UText>
          </Box>
        }
      />
      <CardContent sx={{ display: "flex", flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ textAlign: "center" }}>
            <Box sx={{ my: 1 }}>
              <UText variant="subtitle2" sxProp={{ fontWeight: 700 }}>
                {t("Pricing.volume.currentMonthVol")}
              </UText>
            </Box>
            <UText
              variant="h3"
              className="volume-value"
              sxProp={{ color: isStl ? "#9c27b0" : null }}
            >
              {volume?.CurrentTotal}
            </UText>
            {getCount(volume?.CurrentAligner, volume?.CurrentRetainer)}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center" }}>
            <Box sx={{ my: 1 }}>
              <UText variant="subtitle2" sxProp={{ fontWeight: 700 }}>
                {t("Pricing.volume.sixMonthVol")}
              </UText>
            </Box>
            <UText
              variant="h3"
              className="volume-value"
              sxProp={{ color: isStl ? "#9c27b0" : null }}
            >
              {volume?.SixTotal}
            </UText>
            {getCount(volume?.SixAligner, volume?.SixRetainer)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
