import { Stack, Button, Box } from "@mui/material"
import { gdsPlusSetup, wasmModule, stagingManager } from "@/gluelayer"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { updateAutoSetup } from "@/UDTreat/udSetupSlice"
import { setUpdateStaging } from "@/UDTreat/udTreatSlice"
export const WeSetupCommonActions = (props) => {
  const dispatch = useAppDispatch()
  const onClickCloseSpace = () => {
    if (!wasmModule.isInit) return
    gdsPlusSetup.ResolveAllColl(() => {
      console.log("ResolveAllColl finished!")
      dispatch(updateAutoSetup)
      dispatch(setUpdateStaging)
    })
  }
  const onClickResolveContact = () => {
    if (!wasmModule.isInit) return
    stagingManager.setEndNodeIndex()
    console.log("Resolve Occ Post clicked")
    gdsPlusSetup.ResolveOccColl(true, false, () => {
      console.log("ResolveAllColl finished!")
      dispatch(updateAutoSetup)
      dispatch(setUpdateStaging)
    })
  }
  return (
    <Stack
      sx={{ height: 50 }}
      direction="row"
      spacing={1.5}
      alignItems={"center"}
      justifyContent="flex-end"
    >
      <Button
        sx={{ height: 35 }}
        size="small"
        variant="contained"
        onClick={onClickCloseSpace}
      >
        Close Arch Space
      </Button>
      <Button
        sx={{ height: 35 }}
        size="small"
        variant="contained"
        onClick={onClickResolveContact}
      >
        Remove Occ Contact
      </Button>
    </Stack>
  )
}
