/**
 * We can use this function in future if needed. now its disabled
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, PropsWithChildren, useEffect } from "react"
import ReactGA4 from "react-ga4"
import { useLocation } from "react-router-dom"

interface IWrapperProps {
  initialized: boolean
  children: PropsWithChildren<any>
}

const AnalyticsWrapper: FC<IWrapperProps> = ({ initialized, children }) => {
  const location = useLocation()
  useEffect(() => {
    if (initialized) {
      ReactGA4.set({ page: location.pathname + location.search })
      ReactGA4.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      })
    }
  }, [initialized, location])

  return children
}

export default AnalyticsWrapper
