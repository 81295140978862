import Deserialize from "./interface/Deserialize"
import { IBanner } from "./interface/IBanner"

export class Banner implements IBanner, Deserialize {
  content: ""
  subject: ""
  warning_subject: ""
  Deserialize(input: any): this {
    if (input) {
      this.content = input.content
      this.subject = input.subject
      this.warning_subject = input.warning_subject
    }
    return this
  }
}
