export const LABELMAP = {
  general: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 32, 31, 30, 29, 28,
    27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17,
  ],
  fdi: [
    18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46,
    45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
  ],
  pedro: [
    "A8",
    "A7",
    "A6",
    "A5",
    "A4",
    "A3",
    "A2",
    "A1",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "6B",
    "B7",
    "B8",
    "C8",
    "C7",
    "C6",
    "C5",
    "C4",
    "C3",
    "C2",
    "C1",
    "D1",
    "D2",
    "D3",
    "D4",
    "D5",
    "D6",
    "D7",
    "D8",
  ],
  palmer: [
    "UR8",
    "UR7",
    "UR6",
    "UR5",
    "UR4",
    "UR3",
    "UR2",
    "UR1",
    "UL1",
    "UL2",
    "UL3",
    "UL4",
    "UL5",
    "UL6",
    "UL7",
    "UL8",
    "LR8",
    "LR7",
    "LR6",
    "LR5",
    "LR4",
    "LR3",
    "LR2",
    "LR1",
    "LL1",
    "LL2",
    "LL3",
    "LL4",
    "LL5",
    "LL6",
    "LL7",
    "LL8",
  ],
}
export const ArchivedStatus = [
  "COMPLETE_PATIENT_RECORDS",
  "FILL_OUT_RX_FORM",
  "SUBMIT_TO_UASSIST",
  "SUBMITTED_TO_UASSIST",
  // "SUBMITTED_TO_UASSIST_REFINEMENT",
  "PLANNING_IN_PROGRESS",
  // "PLANNING_IN_PROGRESS_REFINEMENT",
  "ATTENTION_NEEDED",
  // "ATTENTION_NEEDED_REFINEMENT",
]
export const orderButtonDisabledTmp = ["MODIFICATION_REQUESTED"]
export const orderButtonPopUpTmp = ["ORDER_SUBMITTED", "IN_PRODUCTION"]

export const caseLockByUassist = [
  "CASE_ALREADY_LOCKED_BY_UASSIST",
  "CASE_CANNOT_BE_LOCKED_BY_DOCTOR",
]
export const caseLockByShipped = ["CASE_HAS_BEEN_ORDERED"]
export const caseLockByOtherUser = [
  "CASE_ALREADY_LOCKED_BY_OTHER_USER",
  "CASE_ALREADY_LOCKED_BY_OTHER_PRODUCT",
  "CASE_CANNOT_BE_LOCKED_BY_UASSIST",
  "CASE_CANNOT_BE_LOCKED_BY_CRB",
  "CASE_ALREADY_LOCKED_BY_ANOTHER_USER",
]
export const caseLockByCRB = ["CASE_ALREADY_LOCKED_BY_CRB"]
export const planStarTmp = ["PLACE_ORDER", "ORDER_SUBMITTED"]
export const orderAbleTmp = [
  "REVIEW_PLAN",
  "REVIEW_MODIFICATION",
  "PLACE_ORDER",
  "ORDER_SUBMITTED",
  "IN_PRODUCTION",
  "SHIPPED",
  "DELIVERED",
  "TREATMENT_COMPLETE",
]
