import { Grid, Button } from "@mui/material"
import { useState } from "react"
import { UDSelectOptions, UDTitleSelect } from "../../custom/UDSelectOptions"
import { RightPage } from "../RightPage"
export const UDOrder = (props) => {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <RightPage
      title="Order"
      onClose={props.onClose}
      content={
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          minWidth={"lg"}
        >
          <Grid container maxWidth={"lg"} flexDirection={"column"}>
            <Grid item xs={6}>
              <Button variant="contained">Check out</Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
