import { FC, ReactNode } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { UButton } from "../../../ui-component"

const SettingTabOptions: FC<{
  text: string
  pathName: string
  startIcon: ReactNode
  endIcon?: ReactNode
}> = ({ text, pathName, startIcon, endIcon }) => {
  const navigate = useMyNavigation()
  const location = useLocation()

  return (
    <UButton
      btnText={text}
      variant="text"
      sxProp={{
        width: "100%",
        padding: "9px 16px",
        height: "42px",
        borderRadius: 0,
        justifyContent: "flex-start",
        backgroundColor:
          location.pathname.includes("smartRxForms") &&
          pathName.includes("smartRxForms")
            ? "rgba(144, 202, 249, 0.16) !important"
            : "transparent",
        color: location.pathname.includes(pathName.split("/")[0])
          ? "primary.main"
          : "text.secondary",
        borderLeft: `2px solid ${
          location.pathname.includes(pathName.split("/")[0])
            ? "#215ECD"
            : "transparent"
        }`,
        ":hover": {
          backgroundColor: "transparent",
        },
        ".MuiButton-startIcon": {
          ml: 0,
          mr: "6px",
          width: 24,
          height: 24,
          svg: {
            fontSize: 24,
          },
        },
        ".MuiButton-endIcon": {
          display: "flex",
          ml: "auto",
        },
      }}
      onClickHandler={() => {
        if (
          !(
            location.pathname.includes("smartRxForms") &&
            pathName.includes("smartRxForms")
          )
        )
          navigate(`/settings/${pathName}`)
      }}
      startIcon={startIcon}
      endIcon={endIcon}
    />
  )
}

export default SettingTabOptions
