import { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link as RouterLink, useSearchParams } from "react-router-dom"
import { Box } from "@mui/material"
import { AuthnTransaction, OAuthError } from "@okta/okta-auth-js"
import { useOktaAuth } from "@okta/okta-react"
import { v4 } from "uuid"

import {
  FormInput,
  LinkText,
  UButton,
  ULoading,
  UText,
} from "../../../components"
import { validations } from "../../../core/utils/validations"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"
import ErrorAlert from "../components/ErrorAlert"

import PasswordIcon from "./PasswordIcon"

interface IUser {
  username: string
  password: string
}

const Login: FC = () => {
  const { t } = useTranslation("common")
  const { oktaAuth } = useOktaAuth()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get("redirectTo")

  const [visibilePassword, setVisibilePassword] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>()
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IUser>({ mode: "onSubmit" })

  const onSubmit: SubmitHandler<IUser> = async (data) => {
    setIsLoginLoading(true)
    setErrorMsg(null)
    await oktaAuth
      .signInWithCredentials(data)
      .then((transaction: AuthnTransaction) => {
        if (transaction.status === "SUCCESS") {
          setIsLoginLoading(false)
          const uniqueId = v4()
          localStorage.setItem("web-uuid", uniqueId)
          oktaAuth.signInWithRedirect({
            sessionToken: transaction.sessionToken,
            originalUri: redirectTo,
          })
        } else {
          throw new Error(`Could not sign in: ${transaction.status}`)
        }
      })
      .catch((e: OAuthError) => {
        setIsLoginLoading(false)
        if (e.errorCode === "E0000004") {
          setErrorMsg("E0000004")
        }
      })
  }

  return (
    <>
      <ULoading isLoading={isLoginLoading} />
      <UText variant={"h4"} color={"text"} sxProp={{ textAlign: "center" }}>
        {t("auth.logintitle")}
      </UText>
      {errorMsg && <ErrorAlert message={t(`auth.oautherror.${errorMsg}`)} />}
      <Box
        component={"form"}
        data-testid={"login-form"}
        sx={{ ...CENETER_COLUMN_ALIGN, width: "100%", mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          inputLabel={"formfields.email"}
          fieldName={"username"}
          rules={{
            required: "formfieldErrors.emailrequired",
            pattern: {
              value: validations.email.pattern.value,
              message: "formfieldErrors.userEmailinvalid",
            },
          }}
          errors={errors}
          control={control}
        />
        <Box component={"div"} sx={{ alignSelf: "start", mt: -1, mb: 2 }}>
          <LinkText
            to={"/auth/forgotUsername"}
            linkText={t("auth.forgotemailLinkText")}
            component={RouterLink}
            sxProp={{
              "&:hover": { textDecoration: "none" },
            }}
          />
        </Box>
        <FormInput
          inputLabel={"formfields.password"}
          fieldName={"password"}
          fieldType={visibilePassword ? "text" : "password"}
          rules={{ required: "formfieldErrors.passwordrequired" }}
          errors={errors}
          control={control}
          endAdornmentIcon={
            <PasswordIcon
              updatePasswordVisible={setVisibilePassword}
              isError={!!(errors && errors.password)}
            />
          }
        />
        <Box component={"div"} sx={{ alignSelf: "start", mt: -1, mb: 2 }}>
          <LinkText
            to={"/auth/forgotPassword"}
            linkText={t("auth.forgotpasswordLinkText")}
            component={RouterLink}
            sxProp={{
              "&:hover": { textDecoration: "none" },
            }}
          />
        </Box>
        <UButton
          sxProp={{ mt: 3, width: "100%" }}
          variant={"contained"}
          btnType={"submit"}
          btnText={t("button.login")}
        />
      </Box>
    </>
  )
}

export default Login
