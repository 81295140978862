import { ElementType, FC, ReactNode } from "react"
import React from "react"
import { SxProps } from "@mui/system"

import BoxWrapper from "./Box.style"

export interface IBoxProps {
  /**
   * Component Element  "div" or "span"
   */
  component: ElementType
  /**
   * Child element
   */
  children: ReactNode
  /**
   * 	
The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
   */
  sxProp?: SxProps
}

const UBox: FC<IBoxProps> = ({ component, sxProp, children }) => {
  return (
    <BoxWrapper component={component} sx={sxProp}>
      {children}
    </BoxWrapper>
  )
}

UBox.defaultProps = {
  sxProp: undefined,
}

export default UBox
