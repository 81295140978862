import { wasmModule } from "../wasm/wasmModule";
import { GlobalEvents, globalEvents } from "../../../utils/globalevents";

export interface IIPRData {
  stage: number;
  value: any;
}

export type ReportIPRData = Record<number, IIPRData[]>;

class IprModule {
  private isOpen = false;
  private stageIndexForShow = 0;

  setIPRVisibility(isVisible, stageIndexForShow: number) {
    if (!wasmModule.isInit) return;
    this.isOpen = isVisible;
    this.stageIndexForShow = stageIndexForShow;
    wasmModule.statusController.DisplayArchIPR(
      wasmModule.statusController.GetCurrentDiplayArch(),
      this.isOpen,
      this.stageIndexForShow
    );
  }
  updateIPRVisibility() {
    if (this.isOpen)
      wasmModule.statusController.DisplayArchIPR(
        wasmModule.statusController.GetCurrentDiplayArch(),
        this.isOpen,
        this.stageIndexForShow
      );
  }
  getIPRReport(): ReportIPRData {
    const iprReport: ReportIPRData = {};

    const newIpr = wasmModule.statusController.GetStagesIPRReport();

    for (let i = 0; i < newIpr.size(); i++) {
      const iprs = newIpr.get(i);
      const toothid = iprs.toothId;
      const iprData: IIPRData[] = [];

      for (let j = 0; j < iprs.valVec.size(); j++) {
        const ipr = iprs.valVec.get(j);
        const stage = ipr.stepNum;
        const value = ipr.value;

        if (!value || Number.parseFloat(value) == 0) continue;

        iprData.push({
          stage,
          value,
        });
        // console.log("ysss🚀 ~ file: iprModule.ts:44 ~ ipr:", ipr);
      }

      iprReport[toothid] = iprData;
    }
    return iprReport;
  }
}

export const iprModule = new IprModule();

globalEvents.on(GlobalEvents.VIEW_SWITCH_TYPE, (type: string) => {
  console.log("updateIPRVisibility......", type);
  iprModule.updateIPRVisibility();
});
