import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { UDTabs } from "../custom/UDTabs"
import { LeftPageCard } from "../left/LeftPageCard"
import Report from "./Report/report"
import { LeftPage } from "../left/LeftPage"

export const UDReport = ({
  onClose,
  toothlist,
  attachment,
  setReportShow,
  iprData,
  openReportFunc,
  reportShow,
}) => {
  return (
    <LeftPage
      sx={{ maxWidth: "unset" }}
      title="IPR and Attachment Report"
      onClose={onClose}
      content={
        <Report
          onClose={onClose}
          reportShow={reportShow}
          toothlist={toothlist}
          attachment={attachment}
          setReportShow={setReportShow}
          iprData={iprData}
          openReportFunc={openReportFunc}
        />
      }
    />
  )
}
