import { FC, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { debounce } from "lodash"

import { FormAutoComplete, FormInput } from "../../../../components"
import { Box, Grid } from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { setAlert } from "../../../../core/app/slices/alert/alertSlice"
import {
  updateAlternateAddress,
  updateSelectedShippingToAddressId,
} from "../../../../core/app/slices/order"
import { createAlternateAddress } from "../../../../core/app/slices/order/orderThunkApi"
import {
  fetchAddressSuggestions,
  fetchStateLists,
} from "../../../../core/app/slices/user/userApis"
import { clearAddressSuggestions } from "../../../../core/app/slices/user/userSlice"
import { RootState } from "../../../../core/app/store"
import { validations } from "../../../../core/utils/validations"
import { UButton, UText } from "../../../../ui-component"
import { AddressAutocomplete } from "../../../../ui-component/components/UAutoComplete/UAddressAutoComplete"
import { CountryCode } from "../../../CountryCode"

const AddressForm: FC<{ setOpenAddressForm: (val: boolean) => void }> = ({
  setOpenAddressForm,
}) => {
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    setError,
    handleSubmit,
  } = useForm<any>({ mode: "onSubmit" })
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [selectedState, setSelectedState] = useState<string>("")
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const [stateName, setStateName] = useState(null)

  const { countries, states, paymentInfo, suggestedAddressData } =
    useAppSelector((state: RootState) => state.userService)

  const { alternateAddress } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  const onSubmit: SubmitHandler<any> = async (data) => {
    const payload = {
      name: data.firstName + " " + data.lastName,
      line_1: data.line_1,
      line_2: data.line_2 || "",
      email: data.email || "",
      city: data.city,
      country: data.country_name,
      region: data.region_name,
      zip_code: data.zip_code,
      phone: data.phone,
      type: "Temporary Shipping",
    }
    if (alternateAddress?.id) {
      payload.id = alternateAddress.id
    }
    dispatch(createAlternateAddress(payload)).then((res) => {
      if (res.payload.status == "Success") {
        dispatch(updateAlternateAddress({ ...data, id: res.payload.result.id }))
        dispatch(updateSelectedShippingToAddressId(res.payload.result.id))
        setOpenAddressForm(false)
      } else {
        if (
          res?.payload?.data?.errors[0]?.error_message === "Address is Invalid"
        ) {
          setError("line_1", {
            type: "manual",
            message: t("formfieldErrors.poBoxError"),
          })
        }
        setOpenAddressForm(true)
        dispatch(
          setAlert({
            message: t("overview.order.alternateAddress.updateFailed"),
            isError: true,
          }),
        )
      }
    })
  }

  const setSuggesstionDetails = () => {
    if (selectedAddress) {
      setValue("country_name", "USA")
      setValue("line_1", selectedAddress?.street_line)
      setValue("city", selectedAddress?.city)
      if (stateName) {
        setValue("region_name", stateName)
        setSelectedState(stateName)
      }
      setValue("zip_code", selectedAddress?.zipcode)
    }
  }

  useEffect(() => {
    setSuggesstionDetails()
  }, [selectedAddress, suggestedAddressData])

  useEffect(() => {
    throttleOnSearch(inputValue)
  }, [inputValue, dispatch])

  const throttleOnSearch = useCallback(
    debounce((value) => {
      if (value.length < 3) {
        dispatch(clearAddressSuggestions())
      } else {
        dispatch(fetchAddressSuggestions(value))
      }
    }, 300),
    [],
  )

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
    if (!newInputValue) {
      setSelectedAddress(null)
    }
  }

  const handleChange = (event, value) => {
    const selectedObject = suggestedAddressData.find(
      (option) => option.street_address === value,
    )
    const stateObject = states.find(
      (item) => item.code.toString() === selectedObject.state.toString(),
    )
    setStateName(stateObject?.label)
    setSelectedAddress(selectedObject)
    setInputValue(selectedObject?.street_line)
  }

  useEffect(() => {
    if (countries?.length > 0) {
      if (alternateAddress?.id) {
        const defaultCountry = countries.find(
          (r) => r.label === alternateAddress.country_name,
        )
        if (defaultCountry?.id) {
          setValue("country_name", defaultCountry.label)
          dispatch(fetchStateLists({ countryid: defaultCountry.id }))
        }
      } else {
        const defaultCountry = countries.find((r) => r.label === "USA")
        if (defaultCountry?.id) {
          setValue("country_name", defaultCountry.label)
          dispatch(fetchStateLists({ countryid: defaultCountry.id }))
        }
      }
    }
  }, [countries])

  useEffect(() => {
    if (alternateAddress?.id) {
      setValue("firstName", alternateAddress?.firstName || "")
      setValue("lastName", alternateAddress?.lastName || "")
      setValue("city", alternateAddress?.city || "")
      setValue(
        "countryCode",
        alternateAddress?.countryCode || "United States +1",
      )
      setValue("country_name", alternateAddress?.country_name || "")
      setValue("email", alternateAddress?.email || "")
      setValue("line_1", alternateAddress?.line_1 || "")
      setInputValue(alternateAddress?.line_1)
      setValue("line_2", alternateAddress?.line_2 || "")
      setValue("phone", alternateAddress?.phone || "")
      if (alternateAddress?.region_name) {
        setValue("region_name", alternateAddress?.region_name || "")
        setSelectedState(alternateAddress.region_name)
      }
      setValue("zip_code", alternateAddress?.zip_code || "")
    }
  }, [alternateAddress])

  const defaultCountryCode = () => {
    if (alternateAddress?.id) {
      return (
        CountryCode?.find((list) => list.id === alternateAddress?.countryCode)
          ?.label || "United States +1"
      )
    } else {
      return "United States +1"
    }
  }

  const defaultCountryName = () => {
    if (alternateAddress?.id) {
      return alternateAddress.country_name
    } else {
      return paymentInfo?.billing_address
        ? paymentInfo.billing_address.country
        : "USA"
    }
  }

  return (
    <Grid container direction={"column"} padding={2}>
      <UText variant={"subtitle2"}>
        {t("overview.order.alternateAddress.subtitle")}
      </UText>
      <UText variant={"caption"} sxProp={{ color: "info.main" }}>
        {t("overview.order.alternateAddress.note")}
      </UText>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <UText variant={"h6"}>{t("formfields.name")}</UText>
        <Grid container rowSpacing={0} columnSpacing={1} marginBottom={2}>
          <Grid item sm={6}>
            <FormInput
              formSxprops={{ my: 1 }}
              inputLabel={"formfields.firstname"}
              fieldName={"firstName"}
              rules={{
                required: "formfieldErrors.firstnamerequired",
              }}
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item sm={6}>
            <FormInput
              formSxprops={{ my: 1 }}
              inputLabel={"formfields.lastname"}
              fieldName={"lastName"}
              rules={{
                required: "formfieldErrors.larstnamerequired",
              }}
              errors={errors}
              control={control}
            />
          </Grid>
        </Grid>

        <UText variant={"h6"}>{t("formfields.address")}</UText>
        <Grid container direction={"column"}>
          <Grid item>
            <AddressAutocomplete
              suggestedAddressData={suggestedAddressData}
              handleInputChange={handleInputChange}
              handleChange={handleChange}
              inputValue={inputValue}
              fieldName={"line_1"}
              rules={{ required: "userProfile.addressOnFile.streetRequired" }}
              errors={errors}
              control={control}
              formSxprops={{ mt: 1 }}
            />
            <FormInput
              inputLabel={"userProfile.addressOnFile.addressLine2"}
              fieldName={"line_2"}
              fieldType={"text"}
              rules={{}}
              errors={errors}
              control={control}
              formSxprops={{ mt: 2 }}
            />
          </Grid>
          <Grid item sx={{ display: "flex", gap: 1, mt: 2 }}>
            <FormInput
              formSxprops={{ width: 468 }}
              inputLabel={"userProfile.addressOnFile.city"}
              fieldName={"city"}
              fieldType={"text"}
              rules={{ required: "userProfile.addressOnFile.cityRequired" }}
              errors={errors}
              control={control}
            />
            <FormAutoComplete
              formSxprops={{ width: 468 }}
              inputLabel={"userProfile.addressOnFile.state"}
              fieldName={"region_name"}
              isInputLabel={true}
              rules={{
                required: "userProfile.addressOnFile.stateRequired",
              }}
              options={states}
              errors={errors}
              callback={(e) => {
                if (e) {
                  setValue("region_name", e.label)
                  setSelectedState(e.label)
                }
                trigger("region_name")
              }}
              control={control}
              defaultValue={selectedState}
            />
            <FormInput
              formSxprops={{ width: 138 }}
              inputLabel={"userProfile.addressOnFile.zip"}
              fieldName={"zip_code"}
              fieldType={"text"}
              rules={{ required: "userProfile.addressOnFile.zipRequired" }}
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item>
            <FormAutoComplete
              formSxprops={{ mt: 2, mb: 0 }}
              inputLabel={"userProfile.cn"}
              fieldName={"country_name"}
              isInputLabel={true}
              rules={{
                required: "userProfile.addressOnFile.countryRequired",
              }}
              options={countries}
              errors={errors}
              callback={(e) => {
                setValue("country_name", e.label)
                setValue("region_name", "")
                setSelectedState(null)
                const countryItem = countries.find(
                  (item) => item.label === e.label,
                )
                countryItem &&
                  dispatch(fetchStateLists({ countryid: countryItem.id }))
                trigger("country_name")
              }}
              defaultValue={defaultCountryName()}
              control={control}
            />
          </Grid>
        </Grid>

        <UText variant={"h6"} sxProp={{ mt: 3 }}>
          {t("formfields.contactinformation")}
        </UText>
        <Grid container direction={"column"} gap={2}>
          <Grid item>
            <FormInput
              inputLabel={"formfields.emailaddressOptional"}
              fieldName={"email"}
              fieldType={"text"}
              rules={{
                pattern: validations.email.pattern,
              }}
              errors={errors}
              control={control}
              formSxprops={{ mt: 1 }}
            />
          </Grid>
          <Grid item sx={{ display: "flex", gap: 1 }}>
            <FormAutoComplete
              formSxprops={{
                mt: 1,
                width: 250,
                ".MuiOutlinedInput-root": { paddingRight: "0 !important" },
              }}
              inputLabel={"formfields.countrycode"}
              fieldName={"countryCode"}
              isInputLabel={true}
              options={CountryCode}
              errors={errors}
              control={control}
              defaultValue={defaultCountryCode()}
            />
            <FormInput
              formSxprops={{ mt: 1 }}
              fieldName={"phone"}
              inputLabel={"formfields.phonenumber"}
              rules={{
                required: true,
                pattern: validations.phoneNumber.pattern,
              }}
              errors={errors}
              control={control}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent={"flex-end"} mt={3} gap={3}>
          <Grid item>
            <UButton
              sxProp={{ mt: 1, width: 69, height: "36px" }}
              variant={"text"}
              btnType={"button"}
              onClickHandler={() => setOpenAddressForm(false)}
              btnText={t("button.cancel")}
            />
          </Grid>
          <Grid item>
            <UButton
              sxProp={{ mt: 1, width: 69, height: "36px" }}
              variant={"contained"}
              btnType={"submit"}
              btnText={t("button.save")}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default AddressForm
