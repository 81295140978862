export const STATUS_UPDATED = [
  { displayText: "Past hour", key: "past_hour" },
  { displayText: "Today", key: "today" },
  { displayText: "Yesterday", key: "yesterday" },
  { displayText: "Last 7 days", key: "last_7_days" },
  { displayText: "Last 30 days", key: "last_30_days" },
  { displayText: "Last 6 months", key: "last_6_months" },
  { displayText: "Last 12 months", key: "last_12_months" },
]

export const ORDER_TYPE: any[] = [
  { label: "Comprehensive", id: "comprehensive" },
  { label: "Moderate", id: "moderate" },
  { label: "Mild", id: "mild" },
  { label: "2 Retainer", id: "2 Retainer" },
  { label: "4 Retainer", id: "4 Retainer" },
  { label: "Finishing", id: "finishing" },
  { label: "A La Carte", id: "Alacarte" },
]
