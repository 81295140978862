import axios from "axios"

import http from "../../../global/http"
import { OrgQueryparams } from "../patients/patient.types"

export const getOrganizationList = async (
  orgQueryparams: OrgQueryparams,
): Promise<any> => {
  try {
    const org = []

    const res: any = await http.get(`/user-mgmt/v1/org/all/account/`, {
      params: orgQueryparams,
    })
    if (res && res.status === 200) {
      const { paging, result } = res.data
      if (result && result.length > 0) {
        result.forEach((element) => {
          const obj = {
            ...element,
            patient_list: "View List",
            invoice_list: "Billing History",
          }
          org.push(obj)
        })
      }
      return {
        totalRecords: paging.total_count,
        currentPage: paging.current_page,
        pageSize: paging.page_size,
        records: org,
      }
    }
  } catch (err) {
    return err
  }
}
