import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  getCustomPackagePrice,
  getQuotation,
} from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import usePermissions from "../../../hooks/userPermission"
import { UText } from "../../../ui-component"
import { Box, Container } from "../../../ui-component/mui.components"
import { getQuoteRequestObj } from "../../Patient/PatientList/util/commonUtil"
import { AddNewPackaging } from "../CustomPackaging/AddNewPackaging"

import { MyCustomPackaging } from "./MyCustomPackaging"

export const CustomPackagingModule: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  useEffect(() => {
    dispatch(getCustomPackagePrice())
  }, [])

  return (
    <Container
      component="main"
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0 !important",
      }}
    >
      <Box component={"div"} display={"flex"} justifyContent={"start"}>
        <UText variant={"h4"} sxProp={{ pt: 6, pb: 5 }}>
          {t("customPackaging.title")}
        </UText>
      </Box>
      <Box component={"div"}>{<AddNewPackaging />}</Box>
      <Box mt={4}>{<MyCustomPackaging />}</Box>
    </Container>
  )
}
