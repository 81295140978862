import { ElementType, FC, ForwardedRef, forwardRef, ReactNode } from "react"
import { Variant } from "@mui/material/styles/createTypography"

import { SxProps, Typography } from "../../mui.components"

export interface ITitleProps {
  /**
   * We can pass different variants of the title h1,h2, ...,h6,body1, body2,caption, overline, button,subtitle1,subtitle2
   */
  variant: Variant
  /**
   * We can pass different colors of the title primary, error, text, inherit,
   */
  color?: string | "inherit"
  /**
   * Component Element  "div" or "span"
   */
  component?: ElementType
  /**
   * Child element
   */
  children: ReactNode
  /**
 * 	
The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
 */
  sxProp?: SxProps

  /**
   * onClick handler method when click the text
   */
  onClickHandler?: () => void | undefined
}

const UText: FC<ITitleProps & React.HTMLProps<HTMLElement>> = forwardRef(
  (
    props: ITitleProps & React.HTMLProps<HTMLElement>,
    ref: ForwardedRef<any>,
  ) => {
    const {
      variant,
      color,
      component = "span",
      children,
      onClickHandler,
      sxProp,
      ...restProps
    } = props

    return (
      <Typography
        {...restProps}
        ref={ref}
        component={component}
        variant={variant}
        color={color}
        sx={{ ...sxProp }}
        onClick={onClickHandler}
      >
        {children}
      </Typography>
    )
  },
)

UText.defaultProps = {
  sxProp: undefined,
  color: "inherit",
}

UText.displayName = "UText"

export default UText
