import { max } from "lodash"

const COVERAGE_SETTINGS = {
  "1": "Full",
  "0.75": "3/4",
  "0.5": "1/2",
  "0.25": "1/4",
}

const THICKNESS_SETTINGS = {
  "0.03": "030",
  "0.04": "040",
  "0.27": "027",
}

export const sortStage = (values: number[]) => {
  const newValues = [...values].sort((a, b) => a - b)
  return newValues
}
export const range = (count: number): number[] => {
  return Array.from({ length: count }, (_, i) => i + 1) || []
}
export const rangeFrom = (count: number, start = 500): number[] => {
  return Array.from({ length: count }, (_, i) => start + i) || []
}

export const stageRange = (count: number) => {
  const x = range(count)
  if (x.length) {
    return x.join(",")
  }
  return ""
}

export const retainerStageRange = (count: number, start = 500) => {
  const x = rangeFrom(count, start)
  if (x.length) {
    return x.join(",")
  }
  return ""
}

export const maxRangeVal = (count: number): number => {
  const x = range(count)
  return x.length > 0 ? max(x) : 1
}

export const getCoverage = (value: string) => {
  if (!value) return ""
  return COVERAGE_SETTINGS[value] || ""
}

export const getThickness = (value: string) => {
  if (!value) return ""
  return THICKNESS_SETTINGS[value] || ""
}

export const getSelectedTeeth = (value) => {
  return value ? value : ""
}
