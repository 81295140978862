import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material"
import { Button, Checkbox, Radio, RadioGroup } from "@mui/material"
import { NativeSelect, Select } from "@mui/material"

export const WeStagingDlg = ({ open, handleClose, onSubmit }) => {
  const [ocType, setOcType] = useState(0)
  const [seqType, setSeqType] = useState(0)
  const [iprStageType, setIprStageType] = useState(0)
  const [cchainType, setCchainType] = useState(0)

  const mapSeqType = [7, 1, 2, 3, 4, 5, 6, 0, 8, 9, 10]
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({
      ocType,
      seqType: mapSeqType[seqType],
      iprStageType,
      cchainType,
    })
    handleClose()
  }

  const SelectOption = (props) => {
    const handleChange = (e) => {
      const sel = e.target.value as number
      props.onChange(sel)
    }
    return (
      <>
        <FormControl fullWidth>
          <InputLabel id="SelOption">{props.label}</InputLabel>
          <Select
            labelId="SelOption"
            value={props.sel}
            onChange={handleChange}
            label={props.label}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {props.options.map((option, index) => {
              return (
                <MenuItem key={index} value={index}>
                  {option}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Automatic Staging</DialogTitle>
      <DialogContent>
        <Grid sx={{ w: 400 }} container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={6}>
            <SelectOption
              label="Over Correction"
              sel={ocType}
              options={["None", "Before Final", "After Final"]}
              onChange={(index) => {
                setOcType(index)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectOption
              label="Sequential Movement"
              sel={seqType}
              options={[
                "New Auto Seq", // 7
                "Dist by Space", //1
                "Post. Expanstion", //2
                "Premolar Extraction", // 3
                "Dist. by Block", //4
                "Adaptive ExpansiON", //5
                "Seq. Normal", // 6
                "Old Auto Seq", // 0
                "Dist by Step", // 8
                "Step 5", // 9
                "Step 4", // 10
              ]}
              onChange={(index) => {
                setSeqType(index)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectOption
              label="C-chain"
              sel={cchainType}
              options={["None", "Anterior 3x3", "Anterior 4x4"]}
              onChange={(index) => {
                setCchainType(index)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectOption
              label="IPR Stage"
              sel={iprStageType}
              options={["Auto", "Current Stage", "Start Stage"]}
              onChange={(index) => {
                setIprStageType(index)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit} autoFocus>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

