import { CASE_STATUS } from "../../../config/status.config"

export const CASE_TABLE_COLUMNS = [
  "casePlan",
  "alignersToOrder",
  "createDate",
  "caseStatus",
]

export const BUNDLE_TABLE_COLUMNS = [
  "bundleType",
  "remainingBundleAllotment",
  "purchaseDate",
  "expiryDate",
]

export const ORDER_TABLE_COLUMNS = [
  "treatmentPlan",
  "order",
  "orderType",
  "orderDate",
  "orderStatus",
  "orderDetails",
  "orderShippingStatus",
  "shipmentTrackingNo",
  "orderId",
]

export const CUSTOMPACAKGE_TABLE_COLUMNS = [
  "imageView",
  "customPackagingName",
  "accNo",
  "orgName",
  "createdDate",
  "packagingStatus",
]

export const ORDER_TYPE = {
  MILD: "Mild Bundle",
  MODERATE: "Moderate Bundle",
  COMPREHENSIVE: "Comprehensive Bundle",
  FINISHING: "Finishing Bundle",
  ALACARTE: "A La Carte",
  "2 RETAINER": "2 RETAINER Bundle",
  "3 RETAINER": "3 RETAINER Bundle",
}

export const SHOW_AS_LINK_STATUS = [
  CASE_STATUS.PLACE_ORDER,
  CASE_STATUS.ORDER_SUBMITTED,
]

const sortStageValue = (stageValue) => {
  return stageValue ? stageValue.split(",").sort((a, b) => a - b) : []
}

export const calculateMixedValue = (string) => {
  const numbersArray = string.split(",").map(Number)
  const arr = numbersArray.sort((a, b) => a - b)
  if (arr.length === 0) return ""

  const ranges: string[] = []
  let rangeStart: number | null = null
  let rangeEnd: number | null = null

  // Helper function to add range or single number to ranges array
  const addRange = (start: number | null, end: number | null) => {
    if (start !== null && end !== null) {
      if (start === end) {
        ranges.push(`${start}`)
      } else {
        ranges.push(`${start}-${end}`)
      }
    }
  }

  for (const num of arr) {
    if (rangeStart === null) {
      rangeStart = num
      rangeEnd = num
    } else if (num === rangeEnd + 1) {
      rangeEnd = num
    } else {
      addRange(rangeStart, rangeEnd)
      rangeStart = num
      rangeEnd = num
    }
  }
  // Add the last range after the loop
  addRange(rangeStart, rangeEnd)
  return ranges.join(", ")
}

export const addTemplateStage = (templateStage, RetainerTemplate) => {
  if (templateStage) {
    const arrayTemplate = sortStageValue(templateStage)
    if (arrayTemplate.length > 0) {
      RetainerTemplate += "T, "
    }
  }
  return RetainerTemplate
}

export const addRetainerStage = (retainerStage, RetainerTemplate) => {
  if (retainerStage) {
    const arrayRetainer = sortStageValue(retainerStage)
    if (arrayRetainer.length > 0) {
      RetainerTemplate += "R"
    }
  }
  return RetainerTemplate
}
