import { createSlice } from "@reduxjs/toolkit"

import { IOrganizationList } from "../../../model/interface/IOrganization"

import { fetchOrganizationList } from "./organizationThunkApi"

interface IPage {
  currentPage: number
  pageSize: number
  totalRecords: number
  count: number
}

interface IOrganizationServiceState {
  organizations: IOrganizationList[]
  paging: IPage
  loading: "idle" | "pending" | "succeeded" | "failed"
}

const initialState: IOrganizationServiceState = {
  organizations: [],
  paging: {} as IPage,
  loading: "idle",
}

export const organizationServiceSlice = createSlice({
  name: "organizationService",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchOrganizationList.fulfilled, (state, action) => {
      const { records, totalRecords, currentPage, pageSize } = action.payload
      state.organizations = records || []
      state.paging = {
        currentPage: currentPage,
        pageSize: pageSize,
        totalRecords: totalRecords,
        count: totalRecords,
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchOrganizationList.rejected, (state) => {
      console.log("rejected")

      state.loading = "failed"
    })
  },
})
