import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import {
  deleteFormSavedDataService,
  fetchFormList,
  getFormByIdWithMergeData,
  saveDefaultFormDataService,
  updateFormSavedDataService,
  uploadSummarypdf,
} from "./form.Service"

export const fetchFormLists = createAsyncThunk(
  "formService/fetchFormLists",
  async () => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return fetchFormList(orgId)
    } catch (err) {
      return err
    }
  },
)
export const saveDefaultFormData = createAsyncThunk(
  "formService/saveDefaultFormData",
  async (
    { formId, payload }: { formId: string; payload: any },
    { rejectWithValue, getState },
  ) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return saveDefaultFormDataService(orgId, formId, JSON.stringify(payload))
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateFormSavedData = createAsyncThunk(
  "formService/updateFormSavedData",
  async (
    {
      formId,
      caseId,
      payload,
      savedId,
    }: {
      formId: string
      caseId: string
      payload: any
      savedId: string
    },
    { rejectWithValue, getState },
  ) => {
    const userState = getState().userService.user
    const orgId = store.getState().userService.user.current_orgId
    const userId = userState.user_id
    try {
      return updateFormSavedDataService(
        orgId,
        formId,
        caseId,
        JSON.stringify(payload),
        userId,
        savedId,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchFormByCaseIdWithMergeData = createAsyncThunk(
  "rxFormService/fetchFormByIdWithMergeData",
  async ({
    formId,
    caseId,
    getSavedData,
  }: {
    formId: string
    caseId: string
    getSavedData: boolean
  }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      const rs = getFormByIdWithMergeData(orgId, formId, caseId, getSavedData)
      return rs
    } catch (err) {
      return err
    }
  },
)

export const saveUassistSummary = createAsyncThunk(
  "rxFormService/saveUassistSummary",
  async ({
    patientId,
    caseId,
    payload,
    isRefinement,
    isRetainer,
  }: {
    patientId: string
    caseId: string
    payload: any
    isRefinement: boolean
    isRetainer?: boolean
  }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      const res = uploadSummarypdf(
        orgId,
        patientId,
        caseId,
        payload,
        isRefinement,
        isRetainer,
      )
      return res
    } catch (err) {
      return err
    }
  },
)

export const deleteFormSavedData = createAsyncThunk(
  "formService/deleteFormSavedData",
  async (
    {
      formId,
    }: {
      formId: string
    },
    { rejectWithValue, getState },
  ) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return deleteFormSavedDataService(orgId, formId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
