import http from "../../../global/http"
import {FS_FILEZIP} from "@/gluelayer"

const V2_BASE_URL="https://it-uds-ugateway.dev.ulabsystems.cn"

const { VITE_IT_URL } = import.meta.env

export type ZipTypes = 'raw' | 'bulk0'| 'bulk1'| 'bulk2'| 'bulk10' | 'photo' | 'version'

export interface IPhotoInfo{
  category:string,
  filename:string,
}

export interface RequestS3ResourceOption{
  caseId:string,
  urlType:'download'|'upload',
  caseZip?:ZipTypes[],
  casePhoto?:IPhotoInfo[]
}

interface S3ResourceInfo{
  httpMethod:'PUT' | 'POST' | 'GET',
  origFilename:string,
  destFilename:string,
  url:string,
  expirationTime:number,
}

export interface IReqS3UrlPhotoInfo{
  type?:'composite'|'pano'|'ceph'|'other'|'json',
  category:'photographs',
  filename:string
}


interface RequestS3ResponseData{
  caseId:string,
  urlType:'download'|'upload',
  casePhoto:S3ResourceInfo[],
  caseZip:S3ResourceInfo[]
}

interface RespMsg<T>{
  code:number,
  msg:string,
  data:T
}

import axios,{ResponseType} from "axios"

const httpDirect = axios.create({
  baseURL: "",
})

export async function uploadS3Resource(res:S3ResourceInfo,file:File){
  try{
    const ret = await httpDirect.put(res.url,file,{headers:{"Content-Type":file.type}})
    return ret
  }catch(err){
    return err
  }
}

export async function downloadS3Resource(res:S3ResourceInfo){
  try{
    let type:ResponseType = "blob"
    if (res.origFilename.includes(".json")) {
      type = "json"
    }
    const ret = await httpDirect.get<File>(res.url,{responseType:type})
    return ret
  }catch(err){
    return err
  }
}

export async function requestS3Url(options:RequestS3ResourceOption):Promise<RespMsg<RequestS3ResponseData>>{
  try {
    const { data } = await http.post(
      `/ulab/case/api/v2/case/s3url`,
      JSON.stringify(options),
      {baseURL:VITE_IT_URL},
    )
    const ret:RespMsg<RequestS3ResponseData> = (data as any)

    return ret
    
  } catch (err) {
    return err
  }
}

export async function getAllCaseList(page_no: number) {
  try {
    const { data } = await http.get(
      `/ulab/case/api/v2/cases?page_no=${page_no}`,
      {
        baseURL: VITE_IT_URL,
      },
    )
    const ret = data as any
    return ret
  } catch (err) {
    return err
  }
}

export async function getPatientByIdOnly(patient_id: number) {
  try {
    const { data } = await http.get(
      `/ulab/case/api/v2/cases?patient_id=${patient_id}`,
      {
        baseURL: VITE_IT_URL,
      },
    )
    const ret = data as any
    return ret
  } catch (err) {
    return err
  }
}

export async function getCaseByIdOnly(case_id: number) {
  try {
    const { data } = await http.get(
      `/ulab/case/api/v2/cases?case_id=${case_id}`,
      {
        baseURL: VITE_IT_URL,
      },
    )
    const ret = data as any
    return ret
  } catch (err) {
    return err
  }
}

interface RespUploadReport{
  caseId:string,
  state:number,
  stateName:string,
  disposition:string
}

/**
 * get upload report when upload all files ok
 * @param caseId 
 * @returns 
 */
export async function getUploadReport(caseId:string){
  try{
    const { data } = await http.post(
      `/ulab/case/api/v2/case/stlupload/report`,
      JSON.stringify({
        "caseId" : caseId
      }),
      {baseURL:VITE_IT_URL}
    )
    const ret:RespMsg<RespUploadReport> = (data as any)
    return ret
  }catch(excp){
    return null
  }
  
}


export async function uploadFilesToS3(caseId:string,zipFiles?:Record<string, File>,photoFiles?:Record<string, File>){
  // check if the files are zips or photos?
  const zipfiles: Record<string,File> = {}
  const zipfileNames:string[] = []
  if (zipFiles) {
    const keys = Object.keys(zipFiles);
    for(const fileName of keys){
      const name = fileName.replace('.zip','')
      const file = zipFiles[fileName];
      if (FS_FILEZIP.includes(name)) {
        zipfiles[fileName]=file;
        zipfileNames.push(name);
      }
    }
  }

  // upload photos
  const photos:IReqS3UrlPhotoInfo[] = [];
  if (photoFiles) {
    const keys = Object.keys(photoFiles);
    for(const fileName of keys){
      const postfix = fileName.split('.').pop();
      if (postfix === 'json') {
        photos.push({
          type:'json',
          category:'photographs',
          filename:fileName,
        })
      }else{
        photos.push({
          category:'photographs',
          filename:fileName,
        })
      }
    }

  }

  const response = await requestS3Url({
    caseId:caseId,
    urlType:'upload',
    casePhoto:photos,
    caseZip:zipfileNames as any,
  });
  console.log('uploadRequestS3URL:',response)
  const uploadResult = {caseZip:null,casePhotos:{}}
  if(response.code === 0 && response.msg === 'success'){
    const respData = response.data;
    // upload zips
    if (zipfileNames.length>0) {
      for(const res of response.data.caseZip){
        const matchName = res.destFilename
        console.log('upload:',zipfiles[matchName],zipfiles,matchName)
        if (zipfiles[matchName]) {
          const ret = await uploadS3Resource(res,zipfiles[matchName]);
          console.log('upload zip ok>>>',ret);
        }
      }
      const reportResp = await getUploadReport(caseId);
      if (reportResp.code === 0) {
        uploadResult.caseZip = true;
      }else{
        uploadResult.caseZip = false;
      }
    }

    // upload zip
    if (photos.length>0) {
      const allPromis = []
      for(const res of response.data.casePhoto){
        const matchName = res.origFilename
        if (photoFiles[matchName]) {
          const pms = new Promise(async resolve=>{
            const uploadRet= await uploadS3Resource(res,photoFiles[matchName]);
            if (uploadRet.status === 200) {
              uploadResult.casePhotos[res.destFilename]=photoFiles[matchName];
              resolve(true)
            }
            resolve(false)
          })
          allPromis.push(pms);
        }
      }
      const rets = await Promise.all(allPromis);
      console.log('upload end::',rets)
    }

  }

  // check if uploading is successful.
  //const reportResp = await v2Apis.getUploadReport(caseId);

  console.log('upload finished.',uploadResult);


  return uploadResult;
}