import { createSlice } from "@reduxjs/toolkit"

import { ShippingAddressTo } from "../../../model/Address"
import {
  IAlignerOrder,
  IAlignerOrderPayload,
  PACKAGETYPE,
  SHIPPINGMETHOD,
} from "../../../model/interface/AlignerOrder"
import { IOrder } from "../../../model/interface/IOrder"
import {
  IAlacarteInfo,
  IBundleInfo,
  IQuotation,
} from "../../../model/interface/IQuotation"
import { IShippingAddressTo } from "../../../model/interface/IShippingAddress"
import {
  createAlternateAddress,
  getActiveOrdersAPI,
  getCustomPackagePrice,
  getMaterialsAPI,
  getOrdersByCaseId,
  getPackageDetail,
  getQuotation,
  getShippingAddressByCase,
  getStageDetailApi,
  placeAlignerOrders,
  placeManufacturingOrders,
  updateOrder,
} from "../order/orderThunkApi"

interface IData {
  [key: string]: IOrder[]
}

interface IPage {
  currentPage: number
  pageSize: number
  totalRecords: number
  count: number
}

interface OrderState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  orderList: IData
  currentOrder: IAlignerOrder
  paging: IPage
  bundleList: IBundleInfo[]
  bundlesAvailable: boolean
  bundlesPurchasedForCase: boolean
  previousUassistCredit: number
  previouslyOrderedPricing: string
  shippingMethod: SHIPPINGMETHOD
  packageType: PACKAGETYPE | ""
  selectedBundle: IBundleInfo
  alacarte: IAlacarteInfo
  shippingToAddress: IShippingAddressTo
  currency: string
  alignerOrderPayload: IAlignerOrderPayload
  stageDetail: Array<object>
  remainingOrder: { aligner: any; retainer: any; template: any }
  quotation: IQuotation
  isAlignerCase: 0 | 1
  alternateAddress: IShippingAddressTo
  selectedShippingToAddressId: number | undefined
  isCustomPackagingDisabled: boolean
  isRevaPermisson: boolean
  orderData: any
  customPackaging: { setupFee: number; unitCharge: number }
}

const initialState: OrderState = {
  loading: "idle",
  orderList: {},
  paging: {} as IPage,
  selectedBundle: null,
  shippingMethod: "Standard",
  packageType: "",
  isAlignerCase: 0,
  currentOrder: {
    bundle: {
      pricing_option: "",
      aligner: {
        remaining: null,
        requested: null,
        included: null,
      },
      retainer: {
        remaining: null,
        requested: null,
        included: null,
      },
      alignercase: {
        cost: null,
        count: null,
      },
      previous_uassist_credit: null,
      shipping_price: {
        type: "",
        item_total_value: "",
      },
      custom_packaging: null,
      tax_amt: null,
      sub_total: null,
    },
    alacarte: {
      pricing_option: "",
      aligner: {
        cost: null,
        count: null,
      },
      retainer: {
        cost: null,
        count: null,
      },
      template: {
        cost: null,
        count: null,
      },
      alignercase: {
        cost: null,
        count: null,
      },
      custom_package: {
        cost: null,
        count: null,
      },
      uassist_service: {
        cost: null,
        count: null,
      },
      shipping_price: {
        type: "",
        item_total_value: "",
      },
      custom_packaging: null,
      tax_amt: null,
      sub_total: null,
    },
    total: null,
    shipAddress: undefined,
    currency: "",
    orderId: null,
    subOrderId: null,
  },
  bundleList: [],
  bundlesAvailable: false,
  bundlesPurchasedForCase: false,
  previousUassistCredit: 0,
  previouslyOrderedPricing: "",
  alacarte: {},
  shippingToAddress: {} as IShippingAddressTo,
  currency: "",
  alignerOrderPayload: {
    case_id: null,
    shipping_method: "Standard",
    order_type: "",
    upper_stage_range: "",
    lower_stage_range: "",
    upper_stage_count: null,
    lower_stage_count: null,
    upper_retainer_stage: "",
    lower_retainer_stage: "",
    upper_template_stage: "",
    lower_template_stage: "",
    aligner_material: "",
    thickness: "30",
    package_type: "ulab_packaging",
    upper_left_aligner_coverage: "",
    upper_right_aligner_coverage: "",
    lower_left_aligner_coverage: "",
    lower_right_aligner_coverage: "",
    upper_left_lasttoothtocover: "",
    upper_right_lasttoothtocover: "",
    lower_left_lasttoothtocover: "",
    lower_right_lasttoothtocover: "",
    shipping_address_id: null,
    extra_info: "",
    pricing_option: "",
    quote: undefined,
    complete_upper_stage: "",
    complete_lower_stage: "",
    complete_upper_retainer: "",
    complete_lower_retainer: "",
    complete_upper_template: "",
    complete_lower_template: "",
    pricing_id: "",
    aligner_case: null,
    stage: {
      aligners: { up: [], low: [] },
      retainers: { up: 0, low: 0 },
      templates: { up: null, low: null },
    },
    scanned: [],
    recession: [],
    coverage: {},
    extra: {},
    materials: { aligner: [], retainer: [] },
  },
  selectedShippingToAddressId: undefined,
  stageDetail: [],
  remainingOrder: { aligner: null, retainer: null, template: null },
  quotation: {} as IQuotation,
  alternateAddress: {} as IShippingAddressTo,
  isCustomPackagingDisabled: true,
  isRevaPermisson: false,
  orderData: [],
  customPackaging: { setupFee: null, unitCharge: null },
}

export const OrderServiceSlice = createSlice({
  name: "orderService",
  initialState,
  reducers: {
    resetOrder: (state) => initialState,
    updateSelectedBundle: (state, action) => {
      state.selectedBundle = action.payload
    },
    updateSelectedShippingMethode: (state, action) => {
      state.shippingMethod = action.payload
    },
    updateSelectedPackageType: (state, action) => {
      state.packageType = action.payload
    },
    updateIsAlignerCase: (state, action) => {
      state.isAlignerCase = action.payload
    },
    updateSelectedShippingToAddressId: (state, action) => {
      state.selectedShippingToAddressId = action.payload
    },
    updateAlignerOrderPayload: (state, action) => {
      state.alignerOrderPayload = {
        ...state.alignerOrderPayload,
        ...action.payload,
      }
    },
    updateAlternateAddress: (state, action) => {
      state.alternateAddress = { ...action.payload }
    },
    updateCustomization: (state, action) => {
      state.alignerOrderPayload = {
        ...state.alignerOrderPayload,
        ...action.payload,
      }
    },
    updateOrderStage: (state, action) => {
      if (action?.payload?.aligners?.low) {
        action.payload.aligners.low.sort()
      }
      if (action?.payload?.aligners?.up) {
        action.payload.aligners.up.sort()
      }
      state.alignerOrderPayload.stage = {
        ...action.payload,
      }
    },
    resetPayload: (state) => {
      state.alignerOrderPayload = {} as IAlignerOrderPayload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateOrder.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updateOrder.fulfilled, (state) => {
      state.loading = "succeeded"
    })
    builder.addCase(updateOrder.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getOrdersByCaseId.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getOrdersByCaseId.fulfilled, (state, action) => {
      if (action.payload.length) {
        state.orderList = {
          ...state.orderList,
          [action.payload[0].caseId]: action.payload,
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(getOrdersByCaseId.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getQuotation.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getQuotation.fulfilled, (state, action) => {
      state.loading = "succeeded"
      state.bundleList = action.payload.bundles
      state.alacarte = action.payload.alacarte
      state.currency = action.payload.currency
      state.remainingOrder = action.payload.remainingOrder
      state.quotation = action.payload
      state.quotation.isAlignerCase = action.payload.isAlignerCase
      state.bundlesAvailable = action.payload.bundlesAvailable
      state.bundlesPurchasedForCase = action.payload.bundlesPurchasedForCase
      state.previousUassistCredit = action.payload.previousUassistCredit
      state.previouslyOrderedPricing = action.payload.previouslyOrderedPricing
      if (
        action.payload.previouslyOrderedPricing &&
        action.payload.bundlesAvailable &&
        action.payload.bundlesPurchasedForCase
      ) {
        state.selectedBundle = action.payload.bundles[0]
      }
      if (
        action.payload.previouslyOrderedPricing &&
        action.payload.previouslyOrderedPricing.toLowerCase() === "alacarte"
      ) {
        state.selectedBundle = action.payload.alacarte
      }
    })
    builder.addCase(getQuotation.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(placeAlignerOrders.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(placeAlignerOrders.fulfilled, (state, action) => {
      state.currentOrder = action.payload
      state.shippingToAddress = new ShippingAddressTo().Deserialize(
        action.payload,
      )
      state.shippingMethod = action.payload.shippingMethod
      state.packageType = action.payload.packageType

      state.loading = "succeeded"
    })
    builder.addCase(placeAlignerOrders.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getShippingAddressByCase.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getShippingAddressByCase.fulfilled, (state, action) => {
      state.loading = "succeeded"
      state.shippingToAddress = action.payload
    })
    builder.addCase(getShippingAddressByCase.rejected, (state) => {
      state.loading = "failed"
    })

    builder.addCase(placeManufacturingOrders.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(placeManufacturingOrders.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(placeManufacturingOrders.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getPackageDetail.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getPackageDetail.fulfilled, (state, action) => {
      if (action.payload?.result?.custom_logo) {
        state.isCustomPackagingDisabled = false
      }
      if (action.payload?.result?.org_features) {
        action.payload?.result?.org_features.map((i) => {
          if (i.type.name === "Reva") {
            state.isRevaPermisson = true
          }
        })
      }
      state.loading = "succeeded"
    })
    builder.addCase(getPackageDetail.rejected, (state) => {
      state.loading = "failed"
    })

    builder.addCase(getStageDetailApi.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const {
        payload: { results },
      } = action
      state.stageDetail = results
    })
    builder.addCase(getStageDetailApi.rejected, (state) => {
      state.loading = "failed"
    })

    builder.addCase(createAlternateAddress.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(createAlternateAddress.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(createAlternateAddress.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getMaterialsAPI.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getMaterialsAPI.fulfilled, (state, action) => {
      state.loading = "succeeded"
      state.alignerOrderPayload.materials = action.payload
    })
    builder.addCase(getMaterialsAPI.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getActiveOrdersAPI.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getActiveOrdersAPI.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const { records, totalRecords, currentPage, pageSize } = action.payload
      state.orderData = records || []
      state.paging = {
        currentPage: currentPage,
        pageSize: pageSize,
        totalRecords: totalRecords,
        count: totalRecords,
      }
    })
    builder.addCase(getActiveOrdersAPI.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(getCustomPackagePrice.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getCustomPackagePrice.fulfilled, (state, action) => {
      if (action.payload?.result) {
        const promoEngineResults = action.payload?.result["promo-engine"]
        if (promoEngineResults && promoEngineResults.length > 0) {
          const quote = promoEngineResults[0].quote
          if (quote) {
            state.customPackaging = {
              setupFee: quote["custom-packaging-artwork"].total,
              unitCharge: quote["custom-packaging"].total,
            }
          }
        }
      }
      state.loading = "succeeded"
    })

    builder.addCase(getCustomPackagePrice.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export const {
  updateAlignerOrderPayload,
  updateSelectedBundle,
  updateSelectedShippingMethode,
  updateSelectedPackageType,
  updateIsAlignerCase,
  updateAlternateAddress,
  updateSelectedShippingToAddressId,
  updateCustomization,
  updateOrderStage,
  resetPayload,
  resetOrder,
} = OrderServiceSlice.actions
