// 判断是否是PC 端
export function isTouchPoint() {
  return !!navigator.maxTouchPoints;
}
// 生成随机id
export function generateRandomId(length) {
  // length是你的id的长度，可自定义
  return Math.random().toString(36).substr(3, length);
}
// blob转换为base64
export function blobToBase64(blob_data, callback) {
  const reader = new FileReader();
  reader.onload = e => {
    if (callback) {
      callback(e.target?.result);
    }
  };
  reader.readAsDataURL(blob_data);
}

export function toLocaleDateString(time) {
  const timestamp4 = new Date(time);
  return `${timestamp4.toLocaleDateString().replace(/\//g, ".")} ${timestamp4
    .toTimeString()
    .substr(0, 8)}`;
}

/*
  通过id返回应该显示的数字或字母（1-8标记法）
  5xx:乳牙，乳牙显示a,b,c,d,e,
  7xx:多生牙，显示：S${名字}
  不带方向
  */
export const getSpcialURLspcial = (toothId: number) => {
  let str = "";
  // UR---第一象限
  if (toothId >= 1 && toothId <= 8) {
    str = `${9 - toothId}`;
  }
  // UL---第二象限
  if (toothId > 8 && toothId <= 16) {
    str = `${toothId - 8}`;
  }
  // LL---第三象限
  if (toothId > 16 && toothId <= 24) {
    str = `${24 - toothId + 1}`;
  }
  // LR---第四象限
  if (toothId > 24 && toothId <= 32) {
    str = `${toothId - 24}`;
  }
  if (Math.floor(toothId / 100) === 5) {
    const index = toothId % 100;
    return String.fromCharCode(Number(getSpcialURLspcial(index)) + 96);
  }
  if (Math.floor(toothId / 100) === 7) {
    const index = toothId % 100;
    return `S${getSpcialURLspcial(index)}`;
  }

  return str;
};

/* 通过牙齿id得出URL值 带方向 */
export const getURL = (toothId: number) => {
  let str = "";
  // UR---第一象限
  if (toothId >= 1 && toothId <= 8) {
    str = `UR${9 - toothId}`;
  }
  // UL---第二象限
  if (toothId > 8 && toothId <= 16) {
    str = `UL${toothId - 8}`;
  }
  // LL---第三象限
  if (toothId > 16 && toothId <= 24) {
    str = `LL${24 - toothId + 1}`;
  }
  // LR---第四象限
  if (toothId > 24 && toothId <= 32) {
    str = `LR${toothId - 24}`;
  }
  if (Math.floor(toothId / 100) === 5) {
    const index = toothId % 100;
    str = String.fromCharCode(Number(getSpcialURLspcial(index)) + 96);
  }
  if (Math.floor(toothId / 100) === 7) {
    const index = toothId % 100;
    str = `S${getSpcialURLspcial(index)}`;
  }
  return str;
};

/**
 * 防抖
 * @param fn
 * @param wait
 * @param immediate
 * @returns
 */
// export function debounce4(fn, wait, immediate?) {
//   let timer;
//   return function () {
//     if (timer) {
//       console.log('cleartimer');
//       clearTimeout(timer);
//     }
//     if (immediate) {
//       // 如果已经执行过，不再执行
//       const callNow = !timer;
//       timer = setTimeout(() => {
//         timer = null;
//       }, wait);
//       if (callNow) {
//         fn.apply(this, arguments);
//       }
//     } else {
//       timer = setTimeout(() => {
//         fn.apply(this, arguments);
//       }, wait);
//     }
//   };
// }

/**
 * not call until the condicon is done!
 * @param getCondition its a promise
 * @param callback
 */
export function callUntilPromise(
  getCondition: () => Promise<boolean>,
  callback: () => void
) {
  const timer = setInterval(async () => {
    const condition = await getCondition();
    if (condition) {
      callback();
      clearInterval(timer);
    }
  }, 200);
}

/**
 * not call until the condicon is done!
 * @param getCondition
 * @param callback
 */
export function callUntil(getCondition: () => boolean, callback: () => void) {
  const timer = setInterval(() => {
    const condition = getCondition();
    if (condition) {
      callback();
      clearInterval(timer);
    }
  }, 300);
}

export const getAccessToken = () => {
  const tokenObj = localStorage.getItem("okta-token-storage");
  if (tokenObj) {
    const { accessToken } = JSON.parse(tokenObj);
    return accessToken?.accessToken || null;
  }
  return null;
};
