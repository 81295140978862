import { useState,useEffect } from "react";
import { useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

import { FormControlLabel, Stack, Switch, Divider, Button } from "@mui/material"
import { RightPage } from "@/UDTreat/right/RightPage"
import { WeCaseStatus } from "./WeCaseStatus"
import {
  wasmModule,
  caseManagement,
  resourcesSynchronization,
} from "@/gluelayer"
import { WeUploadCaseZip } from "./WeUploadCaseZip"

import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { OpenCaseById } from "./OpenCaseById"
import { setSwitchGum } from "@/UDTreat/udTreatSlice"

export const WeTest = (props) => {
  const dispatch = useAppDispatch()

  const { orgId, patientId, caseId } = useParams()
  const [showStl, setShowStl] = useState(false)
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { switchGum } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const handleShowStl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowStl(event.target.checked)
  }
  const openCaseNewTab = () => {
    console.log("open a new tab")
    // navigate(`/clinical/patient/${patientId}/case/${caseId1}/treat`)
    const newRouteWithOrg = orgId
      ? `org/${orgId}/clinical/patient/${patientId}/case/${caseId}/treat`
      : `clinical/patient/${patientId}/case/${caseId}/treat`
    window.open(`${window.location.origin}/${newRouteWithOrg}`)
  }

  const downloadCaseZip = async () => {
    const zipName =
      "ulabcase_" +
      patientId.toString() +
      "_" +
      caseId.toString() +
      "_" +
      patientData.firstName +
      "_" +
      patientData.lastName +
      ".zip"
    const files = await resourcesSynchronization.downloadCaseZip(zipName)
    console.log("Save", files)
  }

  useEffect(() => {
    if (wasmModule.isInit) {
      if (showStl) wasmModule.statusController.UpdateArchScan()
      wasmModule.statusController.ShowArchScan(showStl)
    }
  }, [showStl])

  useEffect(() => {
    if (!wasmModule.isInit) return
    wasmModule.statusController.ShowGum(switchGum)
  }, [switchGum])

  const onSwitchGum = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!wasmModule.isInit) return
    dispatch(setSwitchGum(event.target.checked))
  }

  const onClickSaveCase = (e) => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        const params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        dispatch(uploadCaseZips(params))
      }
    })
  }
  return (
    <RightPage
      title="WeDesign Staging"
      onClose={props.onClose}
      content={
        <Stack>
          <WeCaseStatus />
          <Divider />
          <FormControlLabel
            control={
              <Switch
                checked={showStl}
                onChange={handleShowStl}
                name="showStl"
              />
            }
            label="Show STL"
          />
          <Stack direction={"row"} spacing={2}>
            <Button variant="contained" onClick={onClickSaveCase}>
              Save to Cloud
            </Button>
            <Button variant="contained" onClick={downloadCaseZip}>
              Download to Local
            </Button>
          </Stack>
          <Divider />
          <WeUploadCaseZip />
          <Button onClick={openCaseNewTab}>Open case in new tab</Button>
          <Divider />
          <FormControlLabel
            control={
              <Switch
                checked={switchGum}
                onChange={onSwitchGum}
                name="switchGum"
              />
            }
            label="Switch Gum"
          />
        </Stack>
      }
    />
  )
}
