import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { useLocation } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"

import { UText } from "@/components/index"
import { Box, Button, Grid } from "@/components/mui.components"
import NavigationBlockPopup from "@/components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { setIsRouteChangeBlocked } from "@/core/app/slices/navigationPopup/navigationPopupSlice"
import {
  updaterxFormData,
  updateRxFormSchema,
} from "@/core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  updateFormSavedData,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys } from "@/core/utils/formatters"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "@/routes/status.route.config"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"

import { NoUassistPopup } from "./NoUassistPopup"
import Summary from "./Summary"
import ReviewForm from "./ReviewForm"
import FormOk from "./FormOk"
const Retainer: FC<{
  setUpperArchType?: (val: number) => void
  setLowerArchType?: (val: number) => void
  postSettingTozips?: () => void
  reviewNum?: number
  setActionType?: (val: string) => void
  actionType?: string
}> = ({
  setUpperArchType,
  setLowerArchType,
  postSettingTozips,
  reviewNum,
  setActionType,
  actionType,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [downloadPDF, setDownloadPDF] = useState<boolean>(false)
  const [error, setError] = useState([])
  const { patientId, caseId } = useParams()
  const { VITE_RETAINER } = import.meta.env
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)

  const { rxJsonForm } = useAppSelector((state: RootState) => state.formService)
  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const handleDataChange = (data: any, errors: any) => {
    dispatch(updaterxFormData(data))
    setUpperArchType &&
      setUpperArchType(data.arches_to_treat.includes("upper") ? 1 : null)
    setLowerArchType &&
      setLowerArchType(data.arches_to_treat.includes("lower") ? 1 : null)
    if (!errors.length) return
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (currError.instancePath === "") return
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }

  useEffect(() => {
    let err = []
    if (rxJsonForm.schema?.required?.length && !rxJsonForm.saveid) {
      err = Object.keys(rxJsonForm.schema.required).map((key) => {
        return { instancePath: "/" + rxJsonForm.schema.required[key] }
      })
    }
    setError([...err])
  }, [rxJsonForm.schema])

  useEffect(() => {
    if (caseDetail && caseDetail.udesign_json && rxJsonForm.schema.properties) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      let arches_to_treat = {
        ...rxJsonForm.schema.properties.arches_to_treat,
      }
      if (
        !(
          udesign_json.UpperType === "A" ||
          udesign_json.UpperType === "R" ||
          (arches && arches[0].includes("R")) ||
          (arches && arches[0].includes("A")) ||
          udesign_json.upperUploaded
        )
      ) {
        arches_to_treat.checkboxes = arches_to_treat.checkboxes.filter(
          (checkbox) => checkbox.value !== "upper",
        )
        dispatch(updateRxFormSchema(arches_to_treat))
      }
      if (
        !(
          udesign_json.LowerType === "A" ||
          udesign_json.LowerType === "R" ||
          (arches && arches[1].includes("R")) ||
          (arches && arches[1].includes("A")) ||
          udesign_json.lowerUploaded
        )
      ) {
        arches_to_treat.checkboxes = arches_to_treat.checkboxes.filter(
          (checkbox) => checkbox.value !== "lower",
        )

        dispatch(updateRxFormSchema(arches_to_treat))
      }
    }
  }, [caseDetail.udesign_json, rxJsonForm.id])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: VITE_RETAINER,
        caseId: caseId,
        getSavedData: true,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (downloadPDF) return
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
      return
    }
    // else if (isFormSubmitted) {
    //   if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
    //     setShowUNoUassistAlert(true)
    //   } else {
    //     dispatch(
    //       updateFormSavedData({
    //         formId: rxJsonForm.id,
    //         caseId: caseId,
    //         payload: rxJsonForm.data,
    //         savedId: rxJsonForm.saveid,
    //       }),
    //     ).then((res) => {
    //       // if (res.payload.status === 200) {
    //       //   postSettingTozips && postSettingTozips()
    //       //   dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    //       //   dispatch(
    //       //     setAlert({
    //       //       message:
    //       //         rxJsonForm?.id &&
    //       //         t("uassist.uAssistForm.retainersavesuccess"),
    //       //     }),
    //       //   )
    //       //   setDownloadPDF(true)
    //       // } else {
    //       //   dispatch(
    //       //     setAlert({
    //       //       message:
    //       //         rxJsonForm?.id && t("uassist.uAssistForm.retainersavefailed"),
    //       //     }),
    //       //   )
    //       // }
    //     })
    //   }
    // }
  }, [error, isFormSubmitted, downloadPDF])
  // submit retainer forms
  const submitForm = () => {
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
      return
    } else {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        dispatch(
          updateFormSavedData({
            formId: rxJsonForm.id,
            caseId: caseId,
            payload: rxJsonForm.data,
            savedId: rxJsonForm.saveid,
          }),
        ).then((res) => {
          if (res.payload.status === 200) {
            setActionType("success")
            // postSettingTozips && postSettingTozips()
            // dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
            // dispatch(
            //   setAlert({
            //     message:
            //       rxJsonForm?.id &&
            //       t("uassist.uAssistForm.retainersavesuccess"),
            //   }),
            // )
            // setDownloadPDF(true)
          } else {
            dispatch(
              setAlert({
                message:
                  rxJsonForm?.id && t("uassist.uAssistForm.retainersavefailed"),
              }),
            )
          }
        })
      }
    }
  }
  useEffect(() => {
    if (reviewNum) {
      if (!error.length) {
        setActionType("review")
      }
      setIsFormSubmitted(true)
      if (actionType === "review") {
        submitForm()
      }
    }
  }, [reviewNum])
  return (
    <>
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsFormSubmitted}
      />
      <Grid
        container
        // flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          padding: 0,
          // justifyContent: "start",
        }}
      >
        {/* <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
            {t("uassist.uAssistForm.retainerTitile")}
          </UText>
        </Grid> */}
        <Grid
          sx={{
            width: "100%",
            "& .MuiGrid-container": {
              "& .MuiGrid-item": {
                maxWidth: "100%",
              },
            },
          }}
          item
        >
          {actionType === "review" ? (
            <ReviewForm type={"retainer"} data={rxJsonForm.data} />
          ) : actionType === "edit" ? (
            !!(
              checkIfObjectHasKeys(rxJsonForm.schema) &&
              checkIfObjectHasKeys(rxJsonForm.uischema) &&
              checkIfObjectHasKeys(rxJsonForm.data)
            ) && (
              <JsonFormBuilder
                schema={rxJsonForm.schema}
                uischema={rxJsonForm.uischema}
                defaultData={rxJsonForm.data}
                handleChange={handleDataChange}
                isFormSubmitted={isFormSubmitted}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            )
          ) : (
            <FormOk />
          )}
        </Grid>
        {/* <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box>
            {!location.pathname.includes(
              `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
            ) && (
              <Button
                variant={"outlined"}
                onClick={() => {
                  navigate(
                    `/uassist/patient/${patientId}/case/${caseId}/ordertype`,
                  )
                }}
                sx={{ width: "96px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                {t("button.back")}
              </Button>
            )}
          </Box>

          <Button
            variant={"contained"}
            onClick={() => {
              setIsFormSubmitted(true)
            }}
            sx={{ width: "196px", height: "36px" }}
          >
            {t("button.submittoUassist")}
            <ChevronRight
              fontSize={"small"}
              sx={{
                height: "20px",
                width: "20px",
                marginLeft: "8px",
              }}
            />
          </Button>
        </Grid> */}
      </Grid>
      {downloadPDF && (
        <Box sx={{ zIndex: -1, position: "fixed" }}>
          <Summary isRefinement={false} isRetainer />
        </Box>
      )}
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default Retainer
