import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import * as userApiService from "./user.service"
import {
  AccountActivateParams,
  IForgotPassword,
  IResetPassword,
  IUpdateCardPayload,
  IUpdatePassword,
  IUpdateUser,
  IUserPreferences,
} from "./user.type"

export const fetchUserPreferences = createAsyncThunk(
  "userService/fetchUserPreferences",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    if (userState.preferences) {
      return userState.preferences
    }
    try {
      const orgId = userState.isAllowToViewAllOrg
        ? userState.authorized_roles[0].org_id
        : userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getUserPreferences(orgId, userId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUserPreferences = createAsyncThunk(
  "userService/updateUserPreferences",
  async (payload: IUserPreferences, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.isAllowToViewAllOrg
        ? userState.authorized_roles[0].org_id
        : userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.updateUserPreferences(
        orgId,
        userId,
        payload,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchUserProfile = createAsyncThunk(
  "userService/fetchUserProfile",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.isAllowToViewAllOrg
        ? userState.authorized_roles[0].org_id
        : userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getUserProfile(orgId, userId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUserProfile = createAsyncThunk(
  "userService/updateUserProfile",
  async (payload: IUpdateUser, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.isAllowToViewAllOrg
        ? userState.authorized_roles[0].org_id
        : userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.updateUserProfile(
        orgId,
        userId,
        payload,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updatePassword = createAsyncThunk(
  "userService/updatePassword",
  async (payload: IUpdatePassword, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.isAllowToViewAllOrg
        ? userState.authorized_roles[0].org_id
        : userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.updatePasswordService(
        { ...payload, okta_user_id: userState.uid },
        userId,
        orgId,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const resetPassword = createAsyncThunk(
  "userService/resetPassword",
  async (payload: IResetPassword, { rejectWithValue }) => {
    try {
      const response = await userApiService.resetPasswordService({ ...payload })
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const forgotPassword = createAsyncThunk(
  "userService/forgotPassword",
  async (payload: IForgotPassword, { rejectWithValue }) => {
    try {
      const response = await userApiService.forgotPasswordService({
        ...payload,
      })
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchClinicalSettings = createAsyncThunk(
  "userService/fetchClinicalSettings",
  async (_, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const res = await userApiService.getClinicalSettting(orgId)
      if (res.response_code === 200) {
        const { result } = res
        return result.clinical_settings || {}
      }
      return {}
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateClinicalSettings = createAsyncThunk(
  "userService/updateClinicalSetttings",
  async (payload: unknown, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const response = await userApiService.saveClinicalSettting(orgId, payload)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchCountryList = createAsyncThunk(
  "userService/fetchCountryList",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const response = await userApiService.getCountryList(orgId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchStateLists = createAsyncThunk(
  "userService/fetchStateList",
  async (
    { countryid }: { countryid: number },
    { rejectWithValue, getState },
  ) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const response = await userApiService.getStatesList(orgId, countryid)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const saveCreditCard = createAsyncThunk(
  "userService/saveCreditCard",
  async (payload: IUpdateCardPayload, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const res = await userApiService.addCardDetails(orgId, payload)

      return res
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateCreditCard = createAsyncThunk(
  "userService/updateCreditCard",
  async (payload: IUpdateCardPayload, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const res = await userApiService.updateCardDetails(orgId, payload)

      return res
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getCreditCardDetails = createAsyncThunk(
  "userService/getCreditCardDetails",
  async (_, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const response = await userApiService.getCardDetails(orgId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const activateUser = createAsyncThunk(
  "userService/activateUser",
  async (payload: AccountActivateParams, { rejectWithValue }) => {
    try {
      return await userApiService.activateUser(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchIfuDoc = createAsyncThunk(
  "userService/FetchIfuDocument",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getIfuDocument(orgId, userId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchReleaseNotes = createAsyncThunk(
  "userService/fetchReleaseNotes",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getReleaseNotes(orgId, userId)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchHelpDocuments = createAsyncThunk(
  "userService/fetchHelpDocuments",
  async (fileName: string, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id
      const response = await userApiService.getHelpDocuments(
        orgId,
        userId,
        fileName,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getAccountDetail = createAsyncThunk(
  "userService/getAccountDetail",
  async () => {
    const orgId = store.getState()?.userService?.user?.current_orgId
    try {
      return await userApiService.getAccountDetails(orgId)
    } catch (err) {
      return err
    }
  },
)

export const getPermissions = createAsyncThunk(
  "userService/getPermissions",
  async (_, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.isAllowToViewAllOrg
        ? userState.authorized_roles[0].org_id
        : userState.current_orgId
      return await userApiService.getUserPermissions(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getUDAppExe = createAsyncThunk(
  "userService/getUDAppExe",
  async (version: string, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      return await userApiService.getUDApp(orgId, version)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getSystemMessage = createAsyncThunk(
  "userService/getSystemMessage",
  async (arg, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      return await userApiService.getSystemMessages(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchAddressSuggestions = createAsyncThunk(
  "userService/fetchAddressSuggestions",
  async (payload: string, { rejectWithValue, getState }) => {
    const userState = getState().userService.user
    try {
      const orgId = userState.current_orgId
      const userId = userState.user_id

      const response = await userApiService.getAddressSuggestions(
        orgId,
        userId,
        payload,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
