import { FC } from "react"

import { Grid } from "../components/mui.components"
import ULabAccountContent from "../modules/ULabAccount/ULabAccountContent"

const ULabAccount: FC = () => {
  return (
    <>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        minWidth={"lg"}
        sx={{ minHeight: "85vh" }}
      >
        <Grid container maxWidth={"lg"} flexDirection={"column"}>
          <ULabAccountContent />
        </Grid>
      </Grid>
    </>
  )
}

export default ULabAccount
