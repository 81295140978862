/* eslint-disable */
import * as React from "react"
import { FC, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tab,
  Tabs,
  TextField,
  Backdrop,
} from "@mui/material"
import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
  stagingManager,
} from "@/gluelayer"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

import downSvg from "../../assets/bottomToolBar/stage/downDirect.svg"
import initalSvg from "../../assets/bottomToolBar/stage/initalBtn.svg"
import initalDisSvg from "../../assets/bottomToolBar/stage/initalDisBtn.svg"
import movementSvg from "../../assets/bottomToolBar/stage/movementBtn.svg"
import movementDisSvg from "../../assets/bottomToolBar/stage/movementDisBtn.svg"
import nextSvg from "../../assets/bottomToolBar/stage/next.svg"
import nextDisSvg from "../../assets/bottomToolBar/stage/nextDis.svg"
import playSvg from "../../assets/bottomToolBar/stage/play.svg"
import previousSvg from "../../assets/bottomToolBar/stage/previous.svg"
import previousDisSvg from "../../assets/bottomToolBar/stage/previousDis.svg"
import reportSvg from "../../assets/bottomToolBar/stage/reportBtn.svg"
import reportDisSvg from "../../assets/bottomToolBar/stage/reportDisBtn.svg"
import sideSvg from "../../assets/bottomToolBar/stage/sideBySide.svg"
import sideDisSvg from "../../assets/bottomToolBar/stage/sideBySideDis.svg"
import stopSvg from "../../assets/bottomToolBar/stage/stop.svg"
import stopDisSvg from "../../assets/bottomToolBar/stage/stopDis.svg"
import tabSvg from "../../assets/bottomToolBar/stage/tabBtn.svg"
import upSvg from "../../assets/bottomToolBar/stage/upDirect.svg"
import upDisSvg from "../../assets/bottomToolBar/stage/upDirectDis.svg"
import biteJump from "../../assets/bottomToolBar/stage/biteJump.svg"
import biteJumpDis from "../../assets/bottomToolBar/stage/biteJumpDis.svg"

import helpSvg from "../../assets/bottomToolBar/stage/help.svg"
import starIcon from "../../assets/bottomToolBar/stage/star.svg"
import UButton from "../../../ui-component/components/UButton/UButton"
import DialogBox from "../DialogBox/dialogBox"
import StageBar from "../StageBar/stageBar"
import ToolButton from "../ToolBar/toolButton"

import { toggleProps } from "./type"

import "./toggle.scss"
import { planStarTmp } from "./column"
import StageTxTab from "../StageBar/stageTxTab"
import StageBarForCompare from "../StageBar/stageBarForCompare"

// timer
let timer: string | number | NodeJS.Timeout | undefined
interface BtnObj {
  isBool: boolean
  type: string
}
const { cancelPickTooth } = toothMovement
const { setInitialFinalEnabled } = initialfinal
const bottomNoRefinement = 200
const bottomRefinement = 222
const allNoRefinement = 248
const allRefinement = 270

const {
  setAttachmentVisibility,
  setViewType,
  showArchMode,
  setSpacecheckVisibility,
  setGridVisibility,
  setOcclusionVisibility,
  setSuperimposeVisibility,
  setOverlayVisibility,
  hoverToothToggle,
} = viewControlInTreatment

const {
  addTreatment,
  delTx,
} = caseManagement

const ToggleForCompare: FC<toggleProps> = ({
  preparation,
  setReportShow,
  setopenMovement,
  setInitalShow,
  initalShow,
  openMovement,
  isReady,
  setStageObj,
  planList,
  setCurrentPlan,
  currentPlan,
  renamePlanFunction,
  deletePlanFunction,
  attachmentObj,
  setIsStageFold,
  refinementKey,
  setRefinementKey,
  isHasUpAndlow,
  isEdit,
  arLimitShow,
  setStagebarHeight,
  maxRefine,
  setMaxRefine,
  attachStage,
  hasBite,
  isRANL,
  stageBarWidth,
  setStageBarWidth,
  getTxPlans,
  viewIndex,
  onOff,
}) => {
  const {
    caseInfo: { txplan_name = "", case_disposition },
  } = useAppSelector((state: RootState) => state.clinicalService)

  const { t } = useTranslation("common")

  // 是否折叠,默认折叠状态(Control whether to fold or not)
  const [isFold, setIsFold] = useState<boolean>(true)
  // control btn status
  const [btnObj, setBtnObj] = useState<BtnObj>({
    isBool: true,
    type: "stop",
  })
  const stageBarContainerBottomWrapRef = useRef(null)
  // refinement bool
  const [isRefinement, setIsRefinement] = useState<boolean>(false)
  // click btns
  const clickToPlay = (type: string) => {
    if (type === "play") {
      setBtnObj({
        type: btnObj.type !== "play" ? "play" : "stop",
        isBool: !btnObj.isBool,
      })
    } else {
      setBtnObj({
        type,
        isBool: !btnObj.isBool,
      })
    }
  }

  useEffect(() => {
    if (!initalShow) {
      setInitialFinalEnabled(false)
    }
  }, [initalShow])
  // refinement key is all will close report and movement
  useEffect(() => {
    if (maxRefine && !refinementKey.includes(maxRefine.toString())) {
      setReportShow(false)
      setopenMovement(false)
    }
  }, [refinementKey])
  
  const handleKeyDown = (e) => {
    if (!preparation) {
      return
    }
    const elementFocused = document.activeElement

    if (e.keyCode === 32) {
      if (!isReady || arLimitShow) {
        return
      }
      if (
        elementFocused &&
        ((elementFocused.tagName.toLowerCase() === "input" &&
          elementFocused.getAttribute("type") === "text") ||
          elementFocused.tagName.toLowerCase() === "textarea")
      ) {
        return
      }
      e.preventDefault()
      clickToPlay("play")
    }
    //undo
    if (e.ctrlKey && e.keyCode === 90) {
      if (
        !isEdit
          ? true
          : !isReady ||
            initalShow ||
            arLimitShow ||
            isRANL ||
            (maxRefine && !refinementKey.includes(maxRefine.toString()))
      ) {
        return
      }
      history.undo()
    }
    //redo
    if (e.ctrlKey && e.keyCode === 89) {
      if (
        !isEdit
          ? true
          : !isReady ||
            initalShow ||
            arLimitShow ||
            isRANL ||
            (maxRefine && !refinementKey.includes(maxRefine.toString()))
      ) {
        return
      }
      history.redo()
    }
  }
  // keydown

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [
    btnObj,
    preparation,
    isReady,
    arLimitShow,
    isEdit,
    initalShow,
    isRANL,
    maxRefine,
    refinementKey,
  ])

  return (
    <Box
      id="stageBarContainer"
      sx={{
        width: stageBarWidth,
        height: "156px",
        border: "none",
        background: "#FFFFFF00", //#82AEBB
        pointerEvents: "auto",
      }}
    >
      <Container
        className={"stageBarContainerTopWrap"}
        style={{
          borderBottom: isFold ? "unset" : "1px solid #455A64",
          width: "100%",
          height: "30px",
          maxWidth: "none",
          display: "flex",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div
          id="left"
          className={"topLeft"}
          style={{
            height: "30px",
            width: "calc(50% - 72px)",
            background: "transparent",
          }}
        >
          
        </div>
        <div id="center" className={"topCenter"} style={{ width: "144px" }}>
          <ToolButton
            disabled={!isReady || arLimitShow}
            data-testid="Backwards"
            bg={previousSvg}
            disabledBg={previousDisSvg}
            title={"Initial stage"}
            val={"Initial stage"}
            placement={"top"}
            isFocused={true}
            callBack={() => {
              clickToPlay("icStart")
            }}
            sxProps={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              marginTop: "0px",
            }}
          />
          <ToolButton
            disabled={!isReady || arLimitShow}
            data-testid="Play"
            bg={btnObj.type !== "play" ? stopSvg : playSvg}
            disabledBg={stopDisSvg}
            title={btnObj.type !== "play" ? "Play" : "Pause"}
            val={"play"}
            placement={"top"}
            isFocused={true}
            sxProps={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              margin: "0px 16px 0 16px",
            }}
            callBack={() => {
              clickToPlay("play")
            }}
          />
          <ToolButton
            disabled={!isReady || arLimitShow}
            data-testid="Forward"
            bg={nextSvg}
            disabledBg={nextDisSvg}
            title={"Final stage"}
            val={"Final stage"}
            placement={"top"}
            isFocused={true}
            sxProps={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              marginTop: "0px",
            }}
            callBack={() => {
              clickToPlay("icEnd")
            }}
          />
        </div>
        <Box
          className={"topRight"}
          sx={{
            width: "calc(50% - 72px)",
            height: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center"
          }}
        >
          
        </Box>
      </Container>
      <div
        className={"stageBarContainerBottomWrap"}
        style={{
          width: "100%",
          height: "150px",
          display: "flex",
          border: "none",
          justifyContent:
            stageBarWidth > stageBarContainerBottomWrapRef.current?.offsetWidth
              ? "left"
              : "center",
          padding: "0px",
          position: "relative",
        }}
        ref={stageBarContainerBottomWrapRef}
      >
        <StageBarForCompare
          preparation={preparation}
          btnObj={btnObj}
          setBtnObj={setBtnObj}
          isFold={isFold}
          setStageObj={setStageObj}
          setIsRefinement={setIsRefinement}
          isRefinement={isRefinement}
          attachmentObj={attachmentObj}
          setRefinementKey={setRefinementKey}
          refinementKey={refinementKey}
          setStagebarHeight={setStagebarHeight}
          setStageBarWidth={setStageBarWidth}
          maxRefine={maxRefine}
          setMaxRefine={setMaxRefine}
          attachStage={attachStage}
          stageBarWidth={stageBarWidth}
          getTxPlans={getTxPlans}
          viewIndex={viewIndex}
          onOff={onOff}
        />
      </div>
    </Box>
  )
}
export default ToggleForCompare
