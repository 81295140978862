import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setOpenReopenCaseDlg } from "../udTreatSlice"
import { setBottomStatusMsg, setShowBottomStatusBar } from "../udTreatSlice"
import { caseManagement } from "@/gluelayer"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import {
  fetchFileList,
  getTreatmentInfos,
} from "@/core/app/slices/clinical/clinicalThunkApi"

export const ReopenCaseDlg = () => {
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const { openReopenCaseDlg } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const onReopenOK = async () => {
    dispatch(setOpenReopenCaseDlg(false))
    dispatch(setBottomStatusMsg("Reopen case."))
    // when presetup has finished then reopen case
    await dispatch(resetCase())
    caseManagement.closeCase()
    dispatch(getTreatmentInfos({ patientId }))
    await dispatch(
      fetchFileList({
        patientId,
        caseId,
      }),
    )
    dispatch(setShowBottomStatusBar(false))
  }
  const onCancel = () => {
    dispatch(setOpenReopenCaseDlg(false))
  }
  return (
    <Dialog open={openReopenCaseDlg} onClose={onCancel}>
      <DialogTitle>Reopen Case</DialogTitle>
      <DialogContent>
        <p> AI presetup is finished correctly. </p>{" "}
        <p> Do you want to re-open the case?</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onReopenOK}>
          Reopen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
