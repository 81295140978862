import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box, Grid } from "../../components/mui.components"
import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { formatCurrency } from "../../core/utils/formatters"
import { UText } from "../../ui-component"

export const OtherPricing: FC = () => {
  const { t } = useTranslation("common")
  const { stlPrice, customPackaging, shipping } = useAppSelector(
    (state: RootState) => state.PricingService,
  )
  const [shipMethod, setShipMethod] = useState<shippingMethod[]>([])
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )

  useEffect(() => {
    if (shipping) {
      const tempShipping = []
      shipping.map((item) => {
        if (item.type == "Standard") {
          const val: shippingMethod = {}
          val.type = item.type
          if (countryCode === "AU" || countryCode === "NZ") {
            val.subType = t("Pricing.shipping.stdnoteAU")
          } else if (countryCode === "US") {
            val.subType = t("Pricing.shipping.stdnoteUS")
          }
          val.price = Number(item.price)
          val.desc = item.description
          val.business_days =
            item.business_days + "-" + t("Pricing.shipping.businessdays")
          val.waiting = t("Pricing.shipping.stdnote3")
          tempShipping.push(val)
        } else if (item.type == "Premium" || item.type == "Priority Express") {
          const val: shippingMethod = {}
          if (item.type === "Premium") {
            val.waiting = t("Pricing.shipping.premiumnote")
          } else if (item.type === "Priority Express") {
            val.waiting = t("Pricing.shipping.prioritynote")
          }
          val.business_days =
            item.business_days + "-" + t("Pricing.shipping.businessdays")
          val.type = item.type
          val.desc = item.description
          val.price = Number(item.price)
          tempShipping.push(val)
        }
      })
      setShipMethod(tempShipping)
    }
  }, [shipping])

  const stlComponent = (type: string, price: string) => {
    return (
      <Grid container sx={{ pt: 2 }}>
        <Grid item xs={8}>
          <UText variant="body2">{type}</UText>
        </Grid>
        <Grid item xs={4}>
          <UText variant={"body2"} sxProp={{ fontWeight: "600" }}>
            {formatCurrency(price)}
          </UText>
        </Grid>
      </Grid>
    )
  }
  interface shippingMethod {
    type?: string
    subType?: string
    price?: number
    desc?: string
    business_days?: string
    waiting?: string
  }

  return (
    <Grid
      container
      sx={{
        boxShadow: "none",
        background: "none",
        display: "flex",
        width: "88%",
      }}
      id="otherPrice"
    >
      <Box component={"div"} marginBottom={1}>
        <UText variant={"h6"} sxProp={{ fontWeight: "600" }}>
          {t("Pricing.otherPrice")}
        </UText>
      </Box>
      <Grid container>
        <Grid item xs={12} md={5} lg={5} sx={{ pl: 0, pr: 0 }}>
          {stlPrice && (
            <Box sx={{ boxShadow: "none" }}>
              <UText
                component={"div"}
                sxProp={{
                  display: "flex",
                  justifyContent: "start",
                  pl: 1,
                  fontSize: "32px",
                }}
                variant={"h5"}
              >
                {t("Pricing.stl.title")}
              </UText>
              <Box sx={{ pl: 1, pr: 0 }}>
                {stlComponent(t("Pricing.stl.stlexp"), stlPrice?.price)}
                {stlComponent(
                  t("Pricing.stl.stl50permonth"),
                  stlPrice?.discountedPrice,
                )}
                {stlComponent(t("Pricing.stl.stlcap"), stlPrice?.monthlyCap)}
              </Box>
            </Box>
          )}
          {customPackaging && (
            <Box sx={{ boxShadow: "none", mt: { sm: 2 } }}>
              <UText
                component={"div"}
                sxProp={{
                  display: "flex",
                  justifyContent: "start",
                  pl: 1,
                  fontSize: "32px",
                }}
                variant={"h5"}
              >
                {t("Pricing.custompackaging.title")}
              </UText>
              <Box sx={{ pl: 1, pr: 0 }}>
                {stlComponent(
                  t("Pricing.custompackaging.artsetup"),
                  customPackaging?.setup,
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={7} sx={{ pl: 0, pr: 0 }}>
          {shipping && (
            <Box sx={{ boxShadow: "none" }}>
              <UText
                component={"div"}
                sxProp={{
                  display: "flex",
                  justifyContent: "start",
                  pl: 1,
                  pt: 1,
                  pb: 2,
                  fontSize: "32px",
                }}
                variant={"h5"}
              >
                {t("Pricing.shipping.title")}
              </UText>
              <Box sx={{ pl: 1, pr: 0 }}>
                {shipMethod.map((data) => (
                  <Grid container key={data.type}>
                    <Grid item xs={10} sx={{ pr: { lg: 0, md: 0 } }}>
                      <UText
                        variant={"subtitle2"}
                        sxProp={{ fontWeight: "700", color: "#333" }}
                      >
                        {data.type}
                      </UText>
                      {data.subType && (
                        <UText
                          component={"div"}
                          variant={"caption"}
                          color="primary.main"
                        >
                          {data.subType}
                        </UText>
                      )}
                      <UText
                        component={"div"}
                        variant={"caption"}
                        sxProp={{ fontWeight: "700" }}
                        color="#777"
                      >
                        {t("Pricing.shipping.estimateddelivery")}
                      </UText>
                      <ul style={{ fontSize: "14px", color: "#333" }}>
                        <li>{data.desc}</li>
                        <li>{data.business_days}</li>
                        <li>{data.waiting}</li>
                      </ul>
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 0 }}>
                      <UText variant={"body2"} sxProp={{ fontWeight: "700" }}>
                        {formatCurrency(data.price)}
                      </UText>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
