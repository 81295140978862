import { Grid } from "../../../../components/mui.components"
import PhotoList from "../../../Uassist/PhotoList"
import SmartRxForm from "../../../Uassist/USmartForms/SmartRxForm"

const SmartRxFormBanner = () => {
  return (
    <Grid container>
      <Grid item md={5}>
        <PhotoList />
      </Grid>
      <Grid item md={7}>
        <SmartRxForm />
      </Grid>
    </Grid>
  )
}

export default SmartRxFormBanner
