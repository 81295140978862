import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AxiosProgressEvent } from "axios"
import { v4 } from "uuid"

import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  UModalPopup,
} from "../../components"
import { Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { uploadPhotographsInv_1 } from "../../core/app/slices/records/photograph"
import { PhotoObject } from "../../core/app/slices/records/photograph/photograph.type"
import {
  removeAdditionalPhoto,
  removeIndividualAdditional,
  resetCount,
  setAdditionalPhotos,
  setIndividualPhotos,
} from "../../core/app/slices/records/photograph/photographSlice"
import {
  removeAdditionalXray,
  removeXrayAdditional,
  resetXrayCount,
  setAdditionalXrays,
} from "../../core/app/slices/records/xrays/xraysSlice"
import {
  deleteFiles,
  uploadXray,
} from "../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../core/app/store"
import { convertMBtoKB } from "../../core/utils/formatters"

const FILE_ALLOWED_TYPES = ["png", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 50 //in MB
const FILE_ALLOWED_LIMIT = 30

const UploadPhotographs: FC<{
  file: File
  onRemove: (f: any) => void
  sequence: number
  isXray?: boolean
  photographType: string
  removeCompletedFiles?: (file: File) => void
  setUploadedFiles: (callback: (o: PhotoObject[]) => PhotoObject[]) => void
  isRefinement?: boolean
}> = ({
  file,
  onRemove,
  sequence,
  isXray,
  photographType,
  removeCompletedFiles,
  setUploadedFiles,
  isRefinement,
}) => {
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressStatus, setProgressStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")
  const [uploadError, setUploadError] = useState<string>()

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { t } = useTranslation("common")

  useEffect(() => {
    const uniqueId = v4()
    const newFile = new File(
      [file],
      `${
        photographType !== "individual" ? "others" : "individual"
      }-${uniqueId}.${file.type.split("/")[1]}`,
      { type: file.type },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", file.name)
    formPayload.append("sequence", sequence.toString())
    if (isRefinement) formPayload.append("is_refinement", "1")
    const apiFunction = isXray ? uploadXray : uploadPhotographsInv_1
    dispatch(
      apiFunction({
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: newFile.name,
        isAdditional: true,
        file,
        radioGraphType: "others",
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgressStatus("loading")

          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
        },
      }),
    )
      .then((action) => {
        if (action.payload.status === 200) {
          setProgressStatus("complete")
          removeCompletedFiles && removeCompletedFiles(file)
          setUploadedFiles((o: PhotoObject[]) => [
            {
              src: URL.createObjectURL(file),
              position: "",
              sequence: sequence.toString(),
              name: file.name,
              fileName: newFile.name,
              size: file.size,
            },
            ...o,
          ])
        } else {
          setProgressStatus("failed")
          setUploadError(t("records.photograph.uploadError"))
        }
      })
      .catch((error) => {
        setProgressStatus("failed")
      })
  }, [file])

  return (
    <Grid item sm={12}>
      <UFileProcessingCard
        fileName={file.name}
        fileSize={file.size}
        progressValue={progressValue}
        progressstatus={progressStatus}
        onRemove={() => onRemove(file)}
        filleErrorMessage={uploadError}
      />
    </Grid>
  )
}

const AdditionalPhotograph: FC<{
  title: string
  openModal: boolean
  setOpenModal: (value: boolean) => void
  showAdditionalFilesSection: boolean
  linkText?: string
  downloadedFiles?: { name: string; size: number; fileName: string }[]
  isXray?: boolean
  photographType?: string
  version?: string
  fileType?: string
  isRefinement?: boolean
}> = ({
  title,
  openModal,
  setOpenModal,
  showAdditionalFilesSection,
  linkText,
  downloadedFiles,
  isXray,
  photographType,
  fileType,
  version,
  isRefinement,
}) => {
  const { t } = useTranslation("common")
  const {
    isPhotoUploading,
    individualPhotos,
    additionalPhotos,
    count,
    individualAdditional,
  } = useAppSelector((state: RootState) => state.PhotosService)

  const { otherXrays, xrayCount, xrayUploadLoading, xrayAdditional } =
    useAppSelector((state: RootState) => state.xrayService)

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [additionalFiles, setAdditionalFiles] = useState<File[]>([])
  const [newFiles, setNewFiles] = useState<File[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<PhotoObject[]>([])
  const [renderedUploads, setRenderedUploads] = useState<JSX.Element[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [imgcount, setImgcount] = useState<number>(0)

  useEffect(() => {
    if (file !== null) {
      const obj = uploadedFiles.find((obj) => obj.name === file?.name)
      const fileObj = additionalFiles.filter((item) => item.name !== file?.name)
      const renderObj = renderedUploads.filter(
        (item) => item.props.file.name !== file?.name,
      )
      setAdditionalFiles(fileObj)
      setRenderedUploads(renderObj)
      const additionalFilesArray = isXray
        ? xrayAdditional
        : individualAdditional
      const name = obj?.fileName.split(".")[0]
      const newObj = additionalFilesArray.find((obj) =>
        obj.fileName.includes(name),
      )
      const removeFiles = isXray
        ? removeXrayAdditional
        : removeIndividualAdditional
      dispatch(
        deleteFiles({
          patientId: patientId,
          caseId: caseId,
          fileName: isRefinement ? newObj?.fileName : obj?.fileName,
          fileType: fileType,
          version: version,
        }),
      ).then((res) => {
        if (res.payload?.status === 200) {
          dispatch(
            removeFiles({
              fileName: isRefinement ? newObj?.fileName : obj?.fileName,
            }),
          )
          if (!isRefinement) {
            const newArray = uploadedFiles.filter(
              (obj) => obj.name !== file?.name,
            )
            setUploadedFiles(newArray)
          }
        }
      })
    }
  }, [file])

  useEffect(() => {
    // Upload files in batches of 5 to prevent failures until all files are uploaded.
    const fileCount = !isXray ? count : xrayCount
    if (
      !newFiles.length ||
      fileCount === newFiles.length ||
      fileCount % 5 !== 0
    )
      return
    const slicedFiles = newFiles
      .slice(fileCount, fileCount + 5)
      .map((f, index) => {
        if (
          (imgcount && fileCount !== imgcount) ||
          (imgcount === count && newFiles.length > count) ||
          (imgcount === xrayCount && newFiles.length > xrayCount)
        ) {
          setImgcount(index + 1 + imgcount)
        } else {
          resetCount()
          setImgcount(index + 1)
        }
        setAdditionalFiles((o) => [f, ...o])
        return (
          <UploadPhotographs
            isXray={isXray}
            file={f}
            photographType={photographType}
            sequence={
              photographType === "individual"
                ? individualPhotos?.length &&
                  Number(
                    individualPhotos[individualPhotos.length - 1].sequence,
                  ) +
                    additionalFiles.length +
                    index +
                    1
                : 0
            }
            setUploadedFiles={setUploadedFiles}
            onRemove={() => setFile(f)}
            key={f.name}
            isRefinement={isRefinement}
          />
        )
      })
    setRenderedUploads((prevUploads) => [...prevUploads, ...slicedFiles])
  }, [newFiles, count, xrayCount])

  const selectedAdditionalFiles = (files: FileList): void => {
    if (imgcount === 0 || count === imgcount || xrayCount === imgcount) {
      dispatch(resetCount())
      setImgcount(0)
      dispatch(resetXrayCount())
    }
    const newFiles = Array.from(files).filter(
      (file) =>
        !additionalFiles.map((file) => file.name).includes(file.name) &&
        (photographType === "composite"
          ? !additionalPhotos.map((file) => file.name).includes(file.name)
          : !otherXrays.map((file) => file.name).includes(file.name)),
    )
    setNewFiles([...newFiles])
  }

  const deleteAdditionalFiles = () => {
    const removeFiles = isXray
      ? removeXrayAdditional
      : removeIndividualAdditional
    const additionalFilesArray = isXray ? xrayAdditional : individualAdditional
    const filesArray = isRefinement ? additionalFilesArray : uploadedFiles
    filesArray?.map((file) => {
      dispatch(
        deleteFiles({
          patientId: patientId,
          caseId: caseId,
          fileName: file.fileName,
          fileType: fileType,
          version: version,
        }),
      ).then((res) => {
        if (res.payload?.status === 200) {
          dispatch(removeFiles({ fileName: file?.fileName }))
        }
      })
    })
    setAdditionalFiles([])
    setRenderedUploads([])
    setUploadedFiles([])
    setOpenModal(false)
  }

  const resetStateValues = () => {
    setAdditionalFiles([])
    setRenderedUploads([])
    setUploadedFiles([])
    setNewFiles([])
    setOpenModal(false)
  }

  return (
    showAdditionalFilesSection && (
      <>
        {photographType !== "individual" &&
          downloadedFiles &&
          downloadedFiles.length > 0 && (
            <Grid
              container
              gap={"10px"}
              sx={{
                width: 400,
                margin: "auto",
                pb: "6px",
                mt: "18px",
              }}
            >
              {downloadedFiles.map((file, index) => (
                <Grid item sm={12} key={file.name}>
                  <UFileProcessingCard
                    fileName={file.name}
                    fileSize={file.size}
                    progressstatus={"complete"}
                    onRemove={() => {
                      dispatch(
                        deleteFiles({
                          patientId: patientId,
                          caseId: caseId,
                          fileName: file.fileName,
                          fileType: fileType,
                          version: version,
                        }),
                      ).then((action) => {
                        if (action.payload.data.status === "Success") {
                          const filteredFilesArray = downloadedFiles.filter(
                            (item) => item.fileName !== file.fileName,
                          )
                          const newArray = [...filteredFilesArray]
                          if (photographType === "composite") {
                            dispatch(removeAdditionalPhoto({ newArray }))
                          }
                          if (photographType === "xrays") {
                            dispatch(removeAdditionalXray({ newArray }))
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}

        {linkText && (
          <UButton
            variant="text"
            disableRipple
            btnText={linkText}
            sxProp={{
              textDecoration: "underline",
              textTransform: "initial",
              fontWeight: 400,
              lineHeight: 1.43,
              letterSpacing: "0.17px",
              width: "100%",
              padding: "6px 0",
              height: "inherit",
              ":hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                textDecorationColor: "rgb(33, 94, 205, .4)",
                cursor: "pointer",
              },
            }}
            onClickHandler={() => setOpenModal(true)}
          />
        )}

        <UModalPopup
          title={title}
          sxModalProps={{
            ".MuiDialog-paper": {
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: 563,
              borderRadius: 4,
              maxHeight: "80vh",
            },
            "#titleCntr": { justifyContent: "center" },
            "#contentCntr": {
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            },
            "#btnListCntr": { gap: 1 },
          }}
          isAlert={openModal}
          content={
            <>
              <Grid container flexDirection={"column"}>
                <UFileBox
                  boxSize={{ height: "216px", mt: "0 !important" }}
                  allowedFileExtensions={FILE_ALLOWED_TYPES}
                  allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
                  fileAcceptType={"image/*"}
                  id="composite"
                  selectedFile={selectedAdditionalFiles}
                  fileLimit={FILE_ALLOWED_LIMIT}
                  isRequired={false}
                  isMultiple
                  messages={{
                    fileNote: t("records.file.filenote", {
                      fileTypes: "JPG, PNG, BMP",
                      fileSize: FILE_ALLOWED_SIZE,
                    }),
                    uploadButton: t("records.file.btnclicktoupload"),
                    uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                    invalidfileFormat: (
                      <Trans components={{ newLine: <br /> }}>
                        {"records.photograph.invalidfileformat"}
                      </Trans>
                    ),
                    invalidFileSize: t("records.file.invalidfilesize", {
                      fileSize: FILE_ALLOWED_SIZE,
                    }),
                    invalidFileLimit: t(
                      "records.file.invalidfilelimitComposite",
                      {
                        noOfFiles: FILE_ALLOWED_LIMIT,
                      },
                    ),
                  }}
                />
              </Grid>
              {(additionalFiles.length !== 0 || uploadedFiles.length !== 0) && (
                <Grid
                  container
                  flexDirection={"column"}
                  gap={"10px"}
                  flexWrap={"nowrap"}
                  overflow={"scroll"}
                  sx={{
                    padding: "0 2px 1px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {renderedUploads.length > 0 && renderedUploads}
                </Grid>
              )}
            </>
          }
          btnList={[
            <UButton
              key={"Cancel"}
              btnText="Cancel"
              variant="outlined"
              disabled={
                isPhotoUploading === "pending" ||
                (count && imgcount && count !== imgcount) ||
                xrayUploadLoading === "pending" ||
                (xrayCount && imgcount && xrayCount !== imgcount)
              }
              onClickHandler={() => {
                deleteAdditionalFiles()
                dispatch(resetCount())
                dispatch(resetXrayCount())
                setImgcount(0)
                setNewFiles([])
              }}
              sxProp={{ width: 90, height: 36 }}
            ></UButton>,
            <UButton
              key={"Done"}
              btnText="Done"
              variant="contained"
              disabled={
                isPhotoUploading === "pending" ||
                (count && imgcount && count !== imgcount) ||
                xrayUploadLoading === "pending" ||
                (xrayCount && imgcount && xrayCount !== imgcount)
              }
              onClickHandler={() => {
                dispatch(resetCount())
                dispatch(resetXrayCount())
                setImgcount(0)
                switch (photographType) {
                  case "composite":
                    dispatch(setAdditionalPhotos({ fileObj: uploadedFiles }))
                    break
                  case "individual":
                    dispatch(setIndividualPhotos())
                    break
                  case "xrays":
                    dispatch(setAdditionalXrays())
                }
                resetStateValues()
              }}
              sxProp={{ width: 72, height: 36 }}
            ></UButton>,
          ]}
        ></UModalPopup>
      </>
    )
  )
}

export default AdditionalPhotograph
