import moment from "moment-timezone"

import { maxRangeVal, stageRange } from "../../../Order/stage.helper"

export const getAvatarInitial = (value: string) => {
  const names = value.split(" ")
  return (
    names.length > 1
      ? names[0].charAt(0) + names[1].charAt(0)
      : names[0].charAt(0)
  ).toUpperCase()
}

export const formatDate = (inputDate) => {
  if (inputDate) {
    return moment(inputDate).format("MMM DD, YYYY")
  }
}

export const CapitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const convertBlobToPdfUrl = (response) => {
  const blob = new Blob([response], { type: "application/pdf" })
  return URL.createObjectURL(blob)
}

export const calculateAge = (inputDate) => {
  const dobDate = new Date(inputDate)
  const currentDate = new Date()

  if (isNaN(dobDate)) return
  const timeDiff = Math.abs(currentDate - dobDate)
  return Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25)).toString()
}

export const splitCountryCodeAndMobileNumber = (inputString) => {
  const regex = /^(\+\d+)(\d{10})$/

  const match = inputString.match(regex)

  if (match) {
    const countryCode = match[1]
    const mobileNumber = match[2]
    return { countryCode, mobileNumber }
  }
}

export const getQuoteRequestObj = (caseDetail) => {
  const requestObj = {
    case_id: caseDetail.id,
    upper_template_stage: "",
    lower_template_stage: "",
    upper_aligner_stage: "",
    lower_aligner_stage: "",
    upper_retainer_stage: "",
    lower_retainer_stage: "",
  }
  const udesign_json = JSON.parse(caseDetail.udesign_json)
  if (udesign_json.UpperMaxStageNum && udesign_json.UpperType === "A") {
    requestObj.upper_aligner_stage = stageRange(+udesign_json.UpperMaxStageNum)
    requestObj.upper_retainer_stage =
      500 + maxRangeVal(+udesign_json.UpperMaxStageNum) + ""
  }
  if (udesign_json.LowerMaxStageNum && udesign_json.LowerType === "A") {
    requestObj.lower_aligner_stage = stageRange(+udesign_json.LowerMaxStageNum)
    requestObj.lower_retainer_stage =
      500 + maxRangeVal(+udesign_json.LowerMaxStageNum) + ""
  }

  if (udesign_json.UpperType === "R") {
    requestObj.upper_retainer_stage = "500"
  }
  if (udesign_json.LowerType === "R") {
    requestObj.lower_retainer_stage = "500"
  }

  if (udesign_json.UpperTemplate > 0) {
    requestObj.upper_template_stage = "400"
  }
  if (udesign_json.LowerTemplate > 0) {
    requestObj.lower_template_stage = "400"
  }
  return requestObj
}

export const downloadZipFile = (blobRes: Blob, caseId: number) => {
  const link = document.createElement("a")
  link.style.display = "none"
  document.body.appendChild(link)
  const blob = new Blob([blobRes], {
    type: "application/zip",
  })
  link.href = URL.createObjectURL(blob)
  link.download = `Case_${caseId}.zip`
  link.click()
  document.body.removeChild(link)
}

export const getCreditVal = (val: string) => {
  const number = Number(val.replace(/[^0-9.-]+/g, ""))
  const formattedNum = number.toFixed(2)
  if (number < 0) {
    return `(-$${Math.abs(number).toFixed(2)})`
  } else {
    return `$${formattedNum}`
  }
}
