const UD_APP_VERSION = "9.1"
const UD_APP_DOWNLOAD_VERSION = "9.1.0"
const UD_APP_DI = "00860461001783"
const UD_APP_BUILD = "910"
export const UDApp = {
  version: UD_APP_VERSION,
  downloadable_version: UD_APP_DOWNLOAD_VERSION,
  about_version: `uDesign Software, FG0064 | Version: ${UD_APP_VERSION}`,
  about_build: `DI: ${UD_APP_DI} | Pl: uDesign ${UD_APP_VERSION} build ${UD_APP_BUILD}`,
  date: `2016 – ${new Date().getFullYear()}  © uLab Systems Inc.`,
  releaseNote: [
    {
      title: "New Features and Tools - Treatment Planning",
      list: [
        `
    <ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          Bite Ramp function available on anterior teeth of both arches with ability to hide and
          show leveling plane, opposing arch and includes an interactive view when adjusting individual bite ramps—
          bite ramps auto populate on selected teeth and can be added and deleted at specific stages.
       </li>
       <li style="display: list-item; padding: 0;">
          Pontic in the library has enhanced widget.
       </li>
       <li style="display: list-item; padding: 0;">
          Eruption dome has enhanced widget Attachment size improvements.
       </li>
       <li style="display: list-item; padding: 0;">
          Course Refinement
          <ul style="list-style-type: lower-alpha; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
             <li>Simplified process for submission to uAssist and self-planned refinements</li>
             <li>Refinement numbering starts over at 1 instead of continuing the numbering from previous treatment
                plan.
             </li>
             <li>Separate tabs to view original treatment plan as well as any additional refinements.</li>
             <li>Ability to see spaces/collisions, staged IPR and associated treatment summary.</li>
          </ul>
       </li>
       <li style="display: list-item; padding: 0;">
          Treatment Summary will be included in shipments when there are attachments and/or staged IPR.
       </li>
    </ol>`,
      ],
    },
    {
      title: "New Features and Tools – Checkout & Ordering",
      list: [
        `
    <ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          Stage selection screen— Simplified view when placing an order for refinements to only focus on the current
          treatment plan (removed original order history).
       </li>
       <li style="display: list-item; padding: 0;">
          Layout on bundle tiles and a la carte option defaulted to the open position.
       </li>
    </ol>`,
      ],
    },
    {
      title: "Removed Items",
      list: [
        `
    <ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          The need to select stage for self-planned refinement.
       </li>
    </ol>`,
      ],
    },
  ],
}
