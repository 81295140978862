import { AxiosProgressEvent } from "axios"

import { getResizedFile } from "../../../../../modules/Records/Photograph/photographUtil"
import http from "../../../../global/http"

export const uploadXrayFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
  formData: FormData,
  callback: (progress: AxiosProgressEvent) => void,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/radiograph/${fileName}`
  try {
    return getResizedFile(formData.get("attachment")).then(
      (resizedFile: File) => {
        formData.set("attachment", resizedFile)
        const res = http.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: callback,
        })
        return res
      },
    )
  } catch (err) {
    return err
  }
}

export const downloadXrayFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/radiograph/${fileName}`
  try {
    const res = http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}

export const deleteUploadedFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
  fileType: string,
  version: string,
) => {
  try {
    const res = http.delete(
      `/patient-mgmt/${version}/org/${orgId}/patients/${patientId}/txplans/${caseId}/${fileType}/${fileName}`,
    )
    return res
  } catch (err) {
    return err
  }
}

export const uploadProfileImage = (
  orgId: string,
  patientId: string,
  formData: FormData,
  fileName: string,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/avatar/${fileName}`
  try {
    return getResizedFile(formData.get("attachment")).then(
      (resizedFile: File) => {
        formData.set("attachment", resizedFile)
        const res = http.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        return res
      },
    )
  } catch (err) {
    return err
  }
}

export const downloadProfileFile = (
  orgId: string,
  patientId: string,
  fileName: string,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/avatar/${fileName}`
  try {
    const res = http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}

export const deleteProfileFile = (
  orgId: string,
  patientId: string,
  fileName: string,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/avatar/${fileName}`
  try {
    const res = http.delete(url)
    return res
  } catch (err) {
    return err
  }
}

export const uploadDentalChart = (
  orgId: string,
  patientId: string,
  caseId: string,
  formData: FormData,
  fileName: string,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/dental-chart/${fileName}`
  try {
    const res = http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res
  } catch (err) {
    return err
  }
}

export const downloadDentalChartBlob = (
  orgId: string,
  patientId: string,
  caseId: string,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/dental-chart/dental_chart.png`
  try {
    const res = http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}

export const uploadReportPDF = (
  orgId: string,
  patientId: string,
  caseId: string,
  formData: FormData,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/ipr-att-report/ipr-att-report.pdf`
  try {
    const res = http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res
  } catch (err) {
    return err
  }
}

export const downloadReportPDFBlob = (
  orgId: string,
  patientId: string,
  caseId: string,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/ipr-att-report/ipr-att-report.pdf`
  try {
    const res = http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}
