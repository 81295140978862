import { ChangeEvent, FC, useEffect, useState } from "react"
import { Actions, ControlProps } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material"

import { JInput } from "../UInput/UInput"

const URadioTextGroup: FC<ControlProps> = (props) => {
  const { schema, label, data, handleChange, path } = props
  const properties = schema.properties
  const [value, setValue] = useState("")
  const [errors, setErrors] = useState("")
  const [outData, setOutData] = useState({})
  const { core, dispatch } = useJsonForms()
  useEffect(() => {
    if (data) {
      setValue(data["planning_for_restorations"] || "")
      setOutData(data)
    }
  }, [data])

  useEffect(() => {
    handleChange(path, Object.keys(outData).length === 0 ? undefined : outData)
  }, [outData])

  useEffect(() => {
    validateField()
  }, [data, core?.validationMode])

  const validateField = () => {
    if (core?.schema.required?.indexOf(path) === -1) return
    Object.keys(outData).map((key) => {
      updateErrors(
        schema.required?.indexOf(key) !== -1 && !outData[key]
          ? "This is a required field"
          : "",
      )
    })
  }

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  const delegateSchema = properties.planning_for_restorations

  const textProps = properties.planning_for_restorations.options

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value
    setValue(val)
    setOutData({ planning_for_restorations: val })
    handleChange("planning_for_restorations", val)
  }

  const renderRadioList = () =>
    delegateSchema &&
    delegateSchema.radioOptions &&
    delegateSchema.radioOptions.map((v, index) => {
      return (
        <Box key={index}>
          <FormControlLabel value={v} control={<Radio />} label={v} />
          {textProps.propertieDisplay === v && v === value && (
            <Box sx={{ mx: 4, width: "100%" }}>
              <OutlinedInput
                fullWidth
                id={label}
                multiline
                minRows={
                  schema.properties[textProps.propertieName].options.minRows
                }
                maxRows={
                  schema.properties[textProps.propertieName].options.maxRows
                }
                value={outData[textProps.propertieName] || ""}
                onChange={(event: any) => {
                  const obj = {}
                  obj[textProps.propertieName] = event.target.value
                  setOutData((existingValues) => ({
                    ...existingValues,
                    ...obj,
                  }))
                }}
              />
            </Box>
          )}
        </Box>
      )
    })

  return (
    <Box sx={{ display: "flex", mt: 1 }}>
      <FormControl
        component="fieldset"
        variant="standard"
        error={!!(errors && core?.validationMode === "ValidateAndShow")}
        sx={{ width: "95%" }}
      >
        <FormLabel
          sx={{
            mb: 1,
            color: "rgba(0, 0, 0, 0.60)",
            "&.Mui-focused": {
              color: "rgba(0, 0, 0, 0.60)",
            },
          }}
          component="legend"
        >
          {schema.label}
        </FormLabel>
        <RadioGroup value={value} onChange={handleChangeInput}>
          {renderRadioList()}
        </RadioGroup>
        {errors && core?.validationMode === "ValidateAndShow" && (
          <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default withJsonFormsControlProps(URadioTextGroup)
