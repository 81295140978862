import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { UButton } from "../../../components"
import { Box, Grid, SxProps } from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { useAppDispatch } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

import PatientBasicInfo from "./PatientBasicInfo"

const PatientInfoHeader: FC<{
  chipLabel: string
  onBtnCancelClick: () => void
  cancelBtnText?: string
  chipSx: SxProps
}> = ({ chipLabel, onBtnCancelClick, cancelBtnText, chipSx }) => {
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")

  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  return (
    <Grid container sx={{ mt: 2, py: 2, height: "fit-content" }}>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <Grid item sm={6} display={"flex"}>
          <PatientBasicInfo patientData={patientData} caseDetail={caseDetail} />
        </Grid>
        <Grid item sm={6} display={"flex"} justifyContent={"end"}>
          <UButton
            variant={"shade"}
            btnType={"button"}
            btnText={t(cancelBtnText)}
            onClickHandler={onBtnCancelClick}
            sxProp={{
              fontSize: "13px",
              padding: "4px 10px",
              height: "30px",
            }}
          />
        </Grid>
      </Box>
    </Grid>
  )
}

PatientInfoHeader.defaultProps = {
  cancelBtnText: "button.cancel",
}

export default PatientInfoHeader
