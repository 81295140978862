import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"

import { ICase } from "../../../../core/model/interface/ICase"
import { UText } from "../../../../ui-component"
import UTable from "../../PatientList/components/UTable/UTable"

const OverviewTableRenderer: FC<{
  id?: string
  tableConfig: any
  tableData: any
  hideMenu?: boolean
  executeAction?: (action: string, caseObj: ICase) => void
}> = ({ id, tableConfig, tableData, hideMenu, executeAction }) => {
  const { t } = useTranslation()

  return (
    <>
      <Grid sx={{ mb: 1 }} id={id}>
        <UText variant="h6">{t(`overview.${tableConfig.title}`)}</UText>
      </Grid>
      <Grid item container justifyContent={"center"}>
        <UTable
          sxTableProps={{
            body: {
              "&.MuiTableRow-root": {
                verticalAlign: "top",
              },
              "&.MuiTableRow-root:hover": {
                backgroundColor: "transparent",
              },
            },
          }}
          columnData={tableData}
          columnDef={tableConfig.columndef}
          name={id}
          isFetching={"succeeded"}
          hidePagination
          enableFilters={false}
          hideMenu={hideMenu}
          onMenuBtnClick={executeAction}
        ></UTable>
      </Grid>
    </>
  )
}

export default OverviewTableRenderer
