import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { Invoice } from "../../../core/model/billing"
import { formatCurrencyWithDecimals } from "../../../core/utils/formatters"
import { UText } from "../../../ui-component"
import {
  CreditTable,
  ShippingTable,
  UsmileOrderTable,
} from "../../Patient/PatientList/patientTable/table.config"
import { getCreditVal } from "../../Patient/PatientList/util/commonUtil"

import { BillingTable } from "./billingTable"

export const InvoiceDetails: FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const { t } = useTranslation("common")
  const [expanded, setExpanded] = useState<string[]>([])

  console.log(invoice, "...invoice Details")
  const getInvoiceSummary = (
    item: string,
    value: string | number,
    isLineBreak?: boolean,
  ) => {
    return (
      <>
        <Grid item xs={1} sm={5} md={6} lg={7} />
        <Grid item xs={11} sm={7} md={6} lg={5}>
          {isLineBreak ? <Divider /> : ""}
          <Grid container sx={{ py: "3px" }}>
            <Grid item xs={7} sm={7} md={8} lg={7}>
              <UText
                variant="body2"
                sxProp={{ fontWeight: isLineBreak ? 700 : "", color: "#333" }}
              >
                {item}
              </UText>
            </Grid>
            <Grid
              item
              xs={5}
              sm={5}
              md={4}
              lg={5}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <UText
                variant="body2"
                sxProp={{ fontWeight: isLineBreak ? 700 : "", color: "#333" }}
              >
                {value}
              </UText>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const getStlTotal = () => {
    return invoice.details.in_office_outputs.lines.reduce(
      (a, b) => a + b.total,
      0,
    )
  }
  const getUsmileTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.usmile_orders.total,
      0,
    )
  }
  const getUassistTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.uassist_orders.total,
      0,
    )
  }
  const getShippingTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.shipping.total,
      0,
    )
  }
  const getcreditsTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.credits.total,
      0,
    )
  }
  const promotionsOutputTotal = () => {
    return invoice.credit_provided
  }
  const getSubTotal = () => {
    return (
      getStlTotal() +
      getUsmileTotal() +
      getUassistTotal() +
      getcreditsTotal() -
      promotionsOutputTotal()
    )
  }
  const getinvoiceTotal = () => {
    return invoice.total_amount
  }
  const getAmountPaidTotal = () => {
    return invoice.paid_amount
  }
  const getBalancedueTotal = () => {
    return invoice.remaining_amount
  }
  const getSalesTaxTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.shipping.tax + b.usmile_orders.tax,
      0,
    )
  }

  return (
    <>
      {invoice?.details?.details_by_address?.length &&
        invoice.details.details_by_address.map((detail) => (
          <Accordion
            key={detail.address}
            onChange={() => {
              if (expanded.includes(detail.address)) {
                const index = expanded.indexOf(detail.address)
                const arr = expanded.filter((_, i) => i !== index)
                setExpanded(arr)
              } else {
                setExpanded([...expanded, detail.address])
              }
            }}
            sx={{
              mt: 1,
              "&.Mui-expanded": { mt: 1 },
              ":before": {
                backgroundColor: "transparent",
              },
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                backgroundColor: "#F6F6F6",
                borderRadius: "4px",
                color: "primary.main",
                fontSize: "14px",
                fontWeight: "500",
                "&:hover": {
                  background: "#EEEEEE",
                },
                "&.MuiAccordionSummary-root": { minHeight: "48px" },
                "&.Mui-expanded .MuiAccordionSummary-content": {
                  my: "12px !important",
                },
              }}
            >
              {expanded.includes(detail.address)
                ? "Hide details for "
                : "Show details for "}
              {detail.address}
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container>
                <UText variant="body1" sxProp={{ my: 1, fontWeight: "600" }}>
                  {t("billingHistory.invoices.usmile")}
                </UText>
                <BillingTable
                  tableHeader={UsmileOrderTable.columnDef}
                  rows={detail.usmile_orders.lines}
                  currencyCode={invoice.currency.code}
                  showPagination={true}
                  extraRows={[
                    { val: "Total", span: 4 },
                    { val: detail?.usmile_orders?.qty, span: 1 },
                    {
                      val: formatCurrencyWithDecimals(getUsmileTotal()),
                      span: 1,
                    },
                  ]}
                />
                <UText variant="body1" sxProp={{ my: 1, fontWeight: "600" }}>
                  {t("billingHistory.invoices.uassist")}
                </UText>
                <BillingTable
                  tableHeader={UsmileOrderTable.columnDef}
                  rows={detail.uassist_orders.lines}
                  showPagination={true}
                  currencyCode={invoice.currency.code}
                  extraRows={[
                    { val: "Total", span: 5 },
                    {
                      val: formatCurrencyWithDecimals(getUassistTotal()),
                      span: 1,
                    },
                  ]}
                ></BillingTable>
                <UText variant="body1" sxProp={{ my: 1, fontWeight: "600" }}>
                  {t("billingHistory.invoices.shipping")}
                </UText>
                <BillingTable
                  tableHeader={ShippingTable.columnDef}
                  rows={detail.shipping.lines}
                  currencyCode={invoice.currency.code}
                  showPagination={true}
                  extraRows={[
                    { val: "Total", span: 3 },
                    {
                      val: formatCurrencyWithDecimals(getShippingTotal()),
                      span: 1,
                    },
                  ]}
                ></BillingTable>
                <UText variant="body1" sxProp={{ my: 1, fontWeight: "600" }}>
                  {t("billingHistory.invoices.credits")}
                </UText>
                <BillingTable
                  tableHeader={CreditTable.columnDef}
                  rows={detail.credits.lines}
                  currencyCode={invoice.currency.code}
                  showPagination={true}
                  isCreditTable
                  extraRows={[
                    { val: "Total", span: 3 },
                    {
                      val: getCreditVal(
                        formatCurrencyWithDecimals(getcreditsTotal()),
                      ),
                      span: 1,
                    },
                  ]}
                ></BillingTable>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      <UText
        variant="h6"
        sxProp={{ mt: 3, mb: 1, display: "flex", justifyContent: "start" }}
      >
        {t("billingHistory.invoices.totalforInvoice")}
      </UText>
      <Divider sx={{ mb: 1 }} />
      <Grid container sx={{ pb: 3 }}>
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.stlExports"),
          formatCurrencyWithDecimals(getStlTotal()),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.usmile"),
          formatCurrencyWithDecimals(getUsmileTotal()),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.uassist"),
          formatCurrencyWithDecimals(getUassistTotal()),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.credits"),
          getCreditVal(formatCurrencyWithDecimals(getcreditsTotal())),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.subTotal"),
          formatCurrencyWithDecimals(getSubTotal()),
          true,
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.shipping"),
          formatCurrencyWithDecimals(getShippingTotal()),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.salesTax"),
          formatCurrencyWithDecimals(getSalesTaxTotal()),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.total", {
            currency: invoice.currency.code,
          }),
          formatCurrencyWithDecimals(getinvoiceTotal()),
          true,
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.amountPaid", {
            currency: invoice.currency.code,
          }),
          formatCurrencyWithDecimals(getAmountPaidTotal()),
          true,
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.summary.balanceDueTotal", {
            currency: invoice.currency.code,
          }),
          formatCurrencyWithDecimals(getBalancedueTotal()),
          true,
        )}
      </Grid>
    </>
  )
}
