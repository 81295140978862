import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, Button, Stack } from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { LeftPage } from "./LeftPage"
import UDdentalComponent from "../UDdentalComponent/UDdentalComponent"
import { RootState } from "@/core/app/store"
const canEdit = ["SUBMITTED_TO_UASSIST"]
export function UDDentalChart(props) {
  const inputFile = useRef(null)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { caseDisposition } = caseDetail
  return (
    <LeftPage
      title="Dental Chart"
      content={
        <UDdentalComponent isView={!canEdit.includes(caseDisposition)} />
      }
      onClose={props.onClose}
      actions={
        // <Box
        //   sx={{
        //     display: "flex",
        //     height: "48px",
        //     alignItems: "center",
        //     justifyContent: "flex-end",
        //     width: "100%",
        //     padding: "32px 16px 32px 24px",
        //   }}
        // >
        //   <Button
        //     sx={{
        //       color: "#fff",
        //       padding: "8px 22px",
        //       borderRadius: "4px",
        //       backgroundColor: "#215ECD",
        //     }}
        //     variant="contained"
        //     onClick={() => {}}
        //   >
        //     {"Done"}
        //   </Button>
        // </Box>
        <></>
      }
    />
  )
}
