import { FC, isValidElement, ReactNode, useState } from "react"
import React from "react"

import { MenuItem, Select, SelectChangeEvent } from "../../mui.components"

interface items {
  key: string
  value: string
}
export interface ISelectProps {
  /**
   * Unique identifier for select
   */
  id: string
  /**
   * associated with  label  and select element
   */
  labelId: string

  /**
   *  label for select
   */
  label: string
  /**
   * value holding the selected value
   */
  defaultValue?: string

  /**
   * To get selected value
   * @param value
   * @returns
   */
  onChangeHandler?: (value: string) => void

  /**
   * We can pass the optiona as two ways
   * option 1:
   *  - value as array of [{key: string, value: string}]
   * option 2:
   *  - value as react element
   */
  options: ReactNode | items[]
}

const USelect: FC<ISelectProps> = ({
  id,
  label,
  labelId,
  defaultValue,
  onChangeHandler,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue || "")

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value)
    onChangeHandler && onChangeHandler(event.target.value || "")
  }
  return (
    <>
      <Select
        labelId={labelId}
        id={id}
        value={selectedValue}
        label={label}
        onChange={handleChange}
      >
        {Array.isArray(options) &&
          options.map(({ value, key }) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}

        {isValidElement(options) && options}
      </Select>
    </>
  )
}

USelect.defaultProps = {
  defaultValue: "",
}

export default USelect
