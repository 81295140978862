import React, { FC, useEffect, useState } from "react"
import { Box, Paper } from "@mui/material"
import { viewControlInTreatment } from "@/gluelayer"

const ToothHover: FC = () => {
  const [open, setopen] = useState({
    position: [0, 0],
    isShow: false,
    hoverString: "",
  })
  useEffect(() => {
    viewControlInTreatment.hoverToothToggle(true)
    viewControlInTreatment.setHoverToothCallback(
      ({ position, isShow, hoverString }) => {
        setopen({ position, isShow, hoverString })
      },
    )
  }, [])

  return (
    <Paper
      sx={{
        position: "absolute",
        bottom: open.position[1],
        left: open.position[0],
        visibility: open.isShow ? "visible" : "hidden",
        zIndex: 1000,
        background: "rgba(97, 97, 97, 0.90)",
        borderRadius: "4px",
      }}
    >
      <Box sx={{ display: "flex", padding: "4px 8px", alignItems: "center" }}>
        {open.hoverString}
      </Box>
    </Paper>
  )
}
export default ToothHover
