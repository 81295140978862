import http from "../../../global/http"
import { Bundle } from "../../../model/Bundle"

export const getBundleByCase = async (orgId: string, caseId: number) => {
  try {
    const bundle = []
    const { data } = await http.get(
      `/order-mgmt/v2/org/${orgId}/orders/txplans/${caseId}/bundles`,
    )
    if (
      data &&
      data.response_code === 200 &&
      data.result &&
      data.result.length > 0
    ) {
      data.result.forEach((r) => {
        bundle.push(new Bundle().Deserialize(r))
      })
    }

    return bundle
  } catch (err) {
    return err
  }
}
