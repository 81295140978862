function defaultValue(e, t) {
  return null != e ? e : t;
}
class Vector2 {
  static create(...e) {
    if (1 !== e.length || 2 !== e[0].length)
      return 2 === e.length
        ? new Float32Array([e[0], e[1]])
        : new Float32Array([0, 0]);
    e = e[0];
    return e ? new Float32Array(e) : new Float32Array(2);
  }
  static get ZERO() {
    return Vector2.create(0, 0);
  }
  static get ONE() {
    return Vector2.create(1, 1);
  }
  constructor() {}
  static copy(e, t) {
    let r = defaultValue(t, Vector2.create());
    return (r[0] = e[0]), (r[1] = e[1]), this;
  }
  static clone(e) {
    return Vector2.create(e[0], e[1]);
  }
  static add(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] + t[0]), (a[1] = e[1] + t[1]), a;
  }
  static addScalar(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] + t), (a[1] = e[1] + t), a;
  }
  static sub(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] - t[0]), (a[1] = e[1] - t[1]), a;
  }
  static subScalar(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] - t), (a[1] = e[1] - t), a;
  }
  static multiply(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] * t[0]), (a[1] = e[1] * t[1]), a;
  }
  static multiplyScalar(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] * t), (a[1] = e[1] * t), a;
  }
  static divide(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] / t[0]), (a[1] = e[1] / t[1]), a;
  }
  static divideScalar(e, t, r) {
    let a = defaultValue(r, Vector2.create());
    return (a[0] = e[0] / t), (a[1] = e[1] / t), a;
  }
  static negate(e, t) {
    let r = defaultValue(t, Vector2.create());
    return (r[0] = -e[0]), (r[1] = -e[1]), r;
  }
  static dot(e, t) {
    return e[0] * t[0] + e[1] * t[1];
  }
  static sizeSquare(e) {
    return e[0] * e[0] + e[1] * e[1];
  }
  static size(e) {
    return Math.sqrt(e[0] * e[0] + e[1] * e[1]);
  }
  static normalize(e, t) {
    var r = defaultValue(t, Vector2.create()),
      t = Vector2.size(e);
    return Vector2.divideScalar(e, t, r), r;
  }
  static distanceToSquare(e, t) {
    var r = e[0] - t[0],
      t = e[1] - t[1];
    return r * r + t * t;
  }
  static distanceTo(e, t) {
    return Math.sqrt(Vector2.distanceToSquare(e, t));
  }
}
class Vector3 {
  constructor() {}
  static create(...e) {
    if (1 !== e.length || 3 !== e[0].length)
      return 3 === e.length
        ? new Float32Array([e[0], e[1], e[2]])
        : new Float32Array([0, 0, 0]);
    e = e[0];
    return e ? new Float32Array(e) : new Float32Array(3);
  }
  static get ZERO() {
    return new Float32Array([0, 0, 0]);
  }
  static get ONE() {
    return new Float32Array([1, 1, 1]);
  }
  static copy(e, t) {
    return (t[0] = e[0]), (t[1] = e[1]), (t[2] = e[2]), t;
  }
  static clone(e) {
    return Float32Array.from(e);
  }
  static add(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] + t[0]), (a[1] = e[1] + t[1]), (a[2] = e[2] + t[2]), a;
  }
  static cross(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (
      (a[0] = e[1] * t[2] - e[2] * t[1]),
      (a[1] = e[2] * t[0] - e[0] * t[2]),
      (a[2] = e[0] * t[1] - e[1] * t[0]),
      a
    );
  }
  static addScalar(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] + t), (a[1] = e[1] + t), (a[2] = e[2] + t), a;
  }
  static sub(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] - t[0]), (a[1] = e[1] - t[1]), (a[2] = e[2] - t[2]), a;
  }
  static subScalar(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] - t), (a[1] = e[1] - t), (a[2] = e[2] - t), a;
  }
  static multiply(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] * t[0]), (a[1] = e[1] * t[1]), (a[2] = e[2] * t[2]), a;
  }
  static multiplyScalar(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] * t), (a[1] = e[1] * t), (a[2] = e[2] * t), a;
  }
  static divide(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] / t[0]), (a[1] = e[1] / t[1]), (a[2] = e[2] / t[2]), a;
  }
  static divideScalar(e, t, r) {
    let a = defaultValue(r, Vector3.create());
    return (a[0] = e[0] / t), (a[1] = e[1] / t), (a[2] = e[2] / t), a;
  }
  static negate(e, t) {
    let r = defaultValue(t, Vector3.create());
    return (r[0] = -e[0]), (r[1] = -e[1]), (r[2] = -e[2]), r;
  }
  static transformMat4(e, t, r) {
    var a = e[0],
      i = e[1],
      n = e[2],
      e = t.data,
      t = 1 / (e[3] * a + e[7] * i + e[11] * n + e[15]);
    let s = defaultValue(r, Vector3.create());
    return (
      (s[0] = (e[0] * a + e[4] * i + e[8] * n + e[12]) * t),
      (s[1] = (e[1] * a + e[5] * i + e[9] * n + e[13]) * t),
      (s[2] = (e[2] * a + e[6] * i + e[10] * n + e[14]) * t),
      s
    );
  }
  static dot(e, t) {
    return e[0] * t[0] + e[1] * t[1] + e[2] * t[2];
  }
  static sizeSquare(e) {
    return e[0] * e[0] + e[1] * e[1] + e[2] * e[2];
  }
  static size(e) {
    return Math.sqrt(e[0] * e[0] + e[1] * e[1] + e[2] * e[2]);
  }
  static normalize(e, t) {
    t = defaultValue(t, Vector3.create());
    return Vector3.divideScalar(e, Vector3.size(e) || 1, t), t;
  }
  static distanceToSquare(e, t) {
    var r = e[0] - t[0],
      a = e[1] - t[1],
      t = e[2] - t[2];
    return r * r + a * a + t * t;
  }
  static distanceTo(e, t) {
    return Math.sqrt(Vector3.distanceToSquare(e, t));
  }
}
class Vector4 {
  static ZERO = Vector4.create(0, 0, 0, 0);
  constructor() {}
  static create(...e) {
    if (1 !== e.length || 4 !== e[0].length)
      return 4 === e.length
        ? new Float32Array([e[0], e[1], e[2], e[3]])
        : new Float32Array([0, 0, 0, 0]);
    e = e[0];
    return e ? new Float32Array(e) : new Float32Array(4);
  }
  static clone(e) {
    return Float32Array.from(e);
  }
  static set(e, t, r, a, i) {
    (i[0] = e), (i[1] = t), (i[2] = r), (i[3] = a);
  }
  static add(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] + t[0]),
      (a[1] = e[1] + t[1]),
      (a[2] = e[2] + t[2]),
      (a[3] = e[3] + t[3]),
      a
    );
  }
  static addScalar(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] + t),
      (a[1] = e[1] + t),
      (a[2] = e[2] + t),
      (a[3] = e[3] + t),
      a
    );
  }
  static sub(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] - t[0]),
      (a[1] = e[1] - t[1]),
      (a[2] = e[2] - t[2]),
      (a[3] = e[3] - t[3]),
      a
    );
  }
  static subScalar(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] - t),
      (a[1] = e[1] - t),
      (a[2] = e[2] - t),
      (a[3] = e[3] - t),
      a
    );
  }
  static multiplyScalar(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] * t),
      (a[1] = e[1] * t),
      (a[2] = e[2] * t),
      (a[3] = e[3] * t),
      a
    );
  }
  static multiply(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] * t[0]),
      (a[1] = e[1] * t[1]),
      (a[2] = e[2] * t[2]),
      (a[3] = e[3] * t[3]),
      a
    );
  }
  static divideScalar(e, t, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      (a[0] = e[0] / t),
      (a[1] = e[1] / t),
      (a[2] = e[2] / t),
      (a[3] = e[3] / t),
      a
    );
  }
  static negate(e, t) {
    let r = defaultValue(t, Vector4.create(0, 0, 0, 0));
    return (r[0] = -e[0]), (r[1] = -e[1]), (r[2] = -e[2]), (r[3] = -e[3]), r;
  }
  static dot(e, t) {
    return e[0] * t[0] + e[1] * t[1] + e[2] * t[2] + e[3] * t[3];
  }
  static sizeSquare(e) {
    return e[0] * e[0] + e[1] * e[1] + e[2] * e[2] + e[3] * e[3];
  }
  static size(e) {
    return Math.sqrt(e[0] * e[0] + e[1] * e[1] + e[2] * e[2] + e[3] * e[3]);
  }
  static normalize(e, t) {
    return Vector4.divideScalar(e, Vector4.size(e) || 1, t);
  }
  static distanceToSquare(e, t) {
    var r = e[0] - t[0],
      a = e[1] - t[1],
      i = e[2] - t[2],
      t = e[3] - t[3];
    return r * r + a * a + i * i + t * t;
  }
  static distanceTo(e, t) {
    return Math.sqrt(Vector4.distanceToSquare(e, t));
  }
  static fromArray(e, t = 0, r) {
    let a = defaultValue(r, Vector4.create(0, 0, 0, 0));
    return (
      void 0 === t && (t = 0),
      (a[0] = e[t]),
      (a[1] = e[t + 1]),
      (a[2] = e[t + 2]),
      (a[3] = e[t + 3]),
      a
    );
  }
}
class Matrix4 {
  _data = new Float32Array(16);
  constructor(e, t, r, a, i, n, s, o, l, u, h, d, c, f, _, p) {
    const E = this._data;
    (E[0] = defaultValue(e, 1)),
      (E[1] = defaultValue(t, 0)),
      (E[2] = defaultValue(r, 0)),
      (E[3] = defaultValue(a, 0)),
      (E[4] = defaultValue(i, 0)),
      (E[5] = defaultValue(n, 1)),
      (E[6] = defaultValue(s, 0)),
      (E[7] = defaultValue(o, 0)),
      (E[8] = defaultValue(l, 0)),
      (E[9] = defaultValue(u, 0)),
      (E[10] = defaultValue(h, 1)),
      (E[11] = defaultValue(d, 0)),
      (E[12] = defaultValue(c, 0)),
      (E[13] = defaultValue(f, 0)),
      (E[14] = defaultValue(_, 0)),
      (E[15] = defaultValue(p, 1));
  }
  get data() {
    return this._data;
  }
  copy(e) {
    let t = this._data;
    e = e._data;
    return t.set(e), this;
  }
  clone() {
    let e = new Matrix4();
    return e.copy(this), e;
  }
  static identity(e) {
    e = e || new Matrix4();
    const t = e._data;
    return (
      (t[0] = 1),
      (t[1] = 0),
      (t[2] = 0),
      (t[3] = 0),
      (t[4] = 0),
      (t[5] = 1),
      (t[6] = 0),
      (t[7] = 0),
      (t[8] = 0),
      (t[9] = 0),
      (t[10] = 1),
      (t[11] = 0),
      (t[12] = 0),
      (t[13] = 0),
      (t[14] = 0),
      (t[15] = 1),
      e
    );
  }
  static translate(e, t, r) {
    var a = r || new Matrix4(),
      i = e.data;
    const n = a.data;
    var s = t[0],
      o = t[1],
      l = t[2],
      u = i[0],
      h = i[1],
      d = i[2],
      c = i[3],
      f = i[4],
      _ = i[5],
      p = i[6],
      E = i[7],
      g = i[8],
      T = i[9],
      m = i[10],
      S = i[11],
      A = i[12],
      v = i[13],
      t = i[14],
      i = i[15];
    return (
      e !== r &&
        ((n[0] = u),
        (n[1] = h),
        (n[2] = d),
        (n[3] = c),
        (n[4] = f),
        (n[5] = _),
        (n[6] = p),
        (n[7] = E),
        (n[8] = g),
        (n[9] = T),
        (n[10] = m),
        (n[11] = S)),
      (n[12] = u * s + f * o + g * l + A),
      (n[13] = h * s + _ * o + T * l + v),
      (n[14] = d * s + p * o + m * l + t),
      (n[15] = c * s + E * o + S * l + i),
      a
    );
  }
  equals(e) {
    if (!e) return !1;
    for (var t = this._data, r = e._data, a = 0; a < 16; a++)
      if (t[a] !== r[a]) return !1;
    return !0;
  }
  determinant() {
    var e = this._data,
      t = e[0],
      r = e[4],
      a = e[8],
      i = e[12],
      n = e[1],
      s = e[5],
      o = e[9],
      l = e[13],
      u = e[2],
      h = e[6],
      d = e[10],
      c = e[14];
    return (
      e[3] *
        (+i * o * h -
          a * l * h -
          i * s * d +
          r * l * d +
          a * s * c -
          r * o * c) +
      e[7] *
        (+t * o * c -
          t * l * d +
          i * n * d -
          a * n * c +
          a * l * u -
          i * o * u) +
      e[11] *
        (+t * l * h -
          t * s * c -
          i * n * h +
          r * n * c +
          i * s * u -
          r * l * u) +
      e[15] *
        (-a * s * u - t * o * h + t * s * d + a * n * h - r * n * d + r * o * u)
    );
  }
  compose(e, t, r) {
    let a = this._data;
    var i = t[0],
      n = t[1],
      s = t[2],
      o = t[3],
      l = i + i,
      u = n + n,
      h = s + s,
      d = i * l,
      c = i * u,
      f = i * h,
      t = n * u,
      i = n * h,
      n = s * h,
      s = o * l,
      l = o * u,
      u = o * h,
      o = r[0],
      h = r[1],
      r = r[2];
    return (
      (a[0] = (1 - (t + n)) * o),
      (a[1] = (c + u) * o),
      (a[2] = (f - l) * o),
      (a[3] = 0),
      (a[4] = (c - u) * h),
      (a[5] = (1 - (d + n)) * h),
      (a[6] = (i + s) * h),
      (a[7] = 0),
      (a[8] = (f + l) * r),
      (a[9] = (i - s) * r),
      (a[10] = (1 - (d + t)) * r),
      (a[11] = 0),
      (a[12] = e[0]),
      (a[13] = e[1]),
      (a[14] = e[2]),
      (a[15] = 1),
      this
    );
  }
  decompose(e, t, r) {
    let a = new Matrix4();
    var i = this._data;
    let n = Vector3.size([i[0], i[1], i[2]]);
    var s = Vector3.size([i[4], i[5], i[6]]),
      o = Vector3.size([i[8], i[9], i[10]]);
    this.determinant() < 0 && (n = -n),
      (e[0] = i[12]),
      (e[1] = i[13]),
      (e[2] = i[14]),
      a.copy(this);
    var l = 1 / n,
      e = 1 / s,
      i = 1 / o;
    return (
      (a.data[0] *= l),
      (a.data[1] *= l),
      (a.data[2] *= l),
      (a.data[4] *= e),
      (a.data[5] *= e),
      (a.data[6] *= e),
      (a.data[8] *= i),
      (a.data[9] *= i),
      (a.data[10] *= i),
      (r[0] = n),
      (r[1] = s),
      (r[2] = o),
      this
    );
  }
  static fromArray(e, t) {
    let r = defaultValue(t, new Matrix4());
    return (r._data = new Float32Array(e)), r;
  }
  static multiply(e, t) {
    var r = e._data,
      a = r[0],
      i = r[1],
      n = r[2],
      s = r[3],
      o = r[4],
      l = r[5],
      u = r[6],
      h = r[7],
      d = r[8],
      c = r[9],
      f = r[10],
      _ = r[11],
      p = r[12],
      E = r[13],
      g = r[14],
      T = r[15],
      m = t._data,
      S = m[0],
      A = m[1],
      v = m[2],
      R = m[3],
      b = m[4],
      y = m[5],
      L = m[6],
      w = m[7],
      N = m[8],
      I = m[9],
      M = m[10],
      x = m[11],
      D = m[12],
      O = m[13],
      C = m[14],
      e = m[15],
      r = a * S + o * A + d * v + p * R,
      t = i * S + l * A + c * v + E * R,
      m = n * S + u * A + f * v + g * R,
      S = s * S + h * A + _ * v + T * R,
      A = a * b + o * y + d * L + p * w,
      v = i * b + l * y + c * L + E * w,
      R = n * b + u * y + f * L + g * w,
      b = s * b + h * y + _ * L + T * w,
      y = a * N + o * I + d * M + p * x,
      L = i * N + l * I + c * M + E * x,
      w = n * N + u * I + f * M + g * x,
      x = s * N + h * I + _ * M + T * x,
      p = a * D + o * O + d * C + p * e,
      E = i * D + l * O + c * C + E * e,
      g = n * D + u * O + f * C + g * e,
      e = s * D + h * O + _ * C + T * e;
    return new Matrix4(r, t, m, S, A, v, R, b, y, L, w, x, p, E, g, e);
  }
  static add(e, t) {
    var r = e._data,
      e = t._data,
      t = new Matrix4();
    let a = t._data;
    return (
      (a[0] = r[0] + e[0]),
      (a[1] = r[1] + e[1]),
      (a[2] = r[2] + e[2]),
      (a[3] = r[3] + e[3]),
      (a[4] = r[4] + e[4]),
      (a[5] = r[5] + e[5]),
      (a[6] = r[6] + e[6]),
      (a[7] = r[7] + e[7]),
      (a[8] = r[8] + e[8]),
      (a[9] = r[9] + e[9]),
      (a[10] = r[10] + e[10]),
      (a[11] = r[11] + e[11]),
      (a[12] = r[12] + e[12]),
      (a[13] = r[13] + e[13]),
      (a[14] = r[14] + e[14]),
      (a[15] = r[15] + e[15]),
      t
    );
  }
  static subtract(e, t) {
    var r = e._data,
      e = t._data,
      t = new Matrix4();
    let a = t._data;
    return (
      (a[0] = r[0] - e[0]),
      (a[1] = r[1] - e[1]),
      (a[2] = r[2] - e[2]),
      (a[3] = r[3] - e[3]),
      (a[4] = r[4] - e[4]),
      (a[5] = r[5] - e[5]),
      (a[6] = r[6] - e[6]),
      (a[7] = r[7] - e[7]),
      (a[8] = r[8] - e[8]),
      (a[9] = r[9] - e[9]),
      (a[10] = r[10] - e[10]),
      (a[11] = r[11] - e[11]),
      (a[12] = r[12] - e[12]),
      (a[13] = r[13] - e[13]),
      (a[14] = r[14] - e[14]),
      (a[15] = r[15] - e[15]),
      t
    );
  }
  static scale(e, t, r) {
    var a = t[0],
      i = t[1],
      t = t[2],
      r = defaultValue(r, new Matrix4());
    let n = r._data;
    e = e._data;
    return (
      (n[0] = a * e[0]),
      (n[1] = a * e[1]),
      (n[2] = a * e[2]),
      (n[3] = 0),
      (n[4] = i * e[4]),
      (n[5] = i * e[5]),
      (n[6] = i * e[6]),
      (n[7] = 0),
      (n[8] = t * e[8]),
      (n[9] = t * e[9]),
      (n[10] = t * e[10]),
      (n[11] = 0),
      (n[12] = e[12]),
      (n[13] = e[13]),
      (n[14] = e[14]),
      (n[15] = 1),
      r
    );
  }
  static negate(e) {
    var t = new Matrix4();
    let r = t._data;
    e = e._data;
    return (
      (r[0] = -e[0]),
      (r[1] = -e[1]),
      (r[2] = -e[2]),
      (r[3] = -e[3]),
      (r[4] = -e[4]),
      (r[5] = -e[5]),
      (r[6] = -e[6]),
      (r[7] = -e[7]),
      (r[8] = -e[8]),
      (r[9] = -e[9]),
      (r[10] = -e[10]),
      (r[11] = -e[11]),
      (r[12] = -e[12]),
      (r[13] = -e[13]),
      (r[14] = -e[14]),
      (r[15] = -e[15]),
      t
    );
  }
  static axisRotate(e, t, r, a) {
    var i = a || new Matrix4();
    const n = i.data;
    var s = e.data,
      o = t[0],
      l = t[1],
      u = t[2],
      h = Math.sqrt(o * o + l * l + u * u),
      d = (o /= h) * o,
      c = (l /= h) * l,
      f = (u /= h) * u,
      _ = Math.cos(r),
      p = Math.sin(r),
      E = 1 - _,
      g = d + (1 - d) * _,
      T = o * l * E + u * p,
      m = o * u * E - l * p,
      S = o * l * E - u * p,
      A = c + (1 - c) * _,
      v = l * u * E + o * p,
      R = o * u * E + l * p,
      b = l * u * E - o * p,
      t = f + (1 - f) * _,
      h = s[0],
      r = s[1],
      d = s[2],
      c = s[3],
      l = s[4],
      u = s[5],
      E = s[6],
      o = s[7],
      p = s[8],
      f = s[9],
      _ = s[10],
      s = s[11];
    return (
      (n[0] = g * h + T * l + m * p),
      (n[1] = g * r + T * u + m * f),
      (n[2] = g * d + T * E + m * _),
      (n[3] = g * c + T * o + m * s),
      (n[4] = S * h + A * l + v * p),
      (n[5] = S * r + A * u + v * f),
      (n[6] = S * d + A * E + v * _),
      (n[7] = S * c + A * o + v * s),
      (n[8] = R * h + b * l + t * p),
      (n[9] = R * r + b * u + t * f),
      (n[10] = R * d + b * E + t * _),
      (n[11] = R * c + b * o + t * s),
      e !== a &&
        ((n[12] = e[12]), (n[13] = e[13]), (n[14] = e[14]), (n[15] = e[15])),
      i
    );
  }
  static lookat(e, t, r) {
    let a = Vector3.create();
    var i = Vector3.create(),
      n = Vector3.create(),
      s = new Matrix4();
    let o = s._data;
    return (
      Vector3.sub(e, t, a),
      0 === Vector3.sizeSquare(a) && (a[2] = 1),
      Vector3.normalize(a, a),
      Vector3.cross(r, a, i),
      0 === Vector3.sizeSquare(i) &&
        (1 === Math.abs(r[2]) ? (a[0] += 1e-4) : (a[2] += 1e-4),
        Vector3.normalize(a, a),
        Vector3.cross(r, a, i)),
      Vector3.normalize(i, i),
      Vector3.cross(a, i, n),
      (o[0] = i[0]),
      (o[4] = n[0]),
      (o[8] = a[0]),
      (o[1] = i[1]),
      (o[5] = n[1]),
      (o[9] = a[1]),
      (o[2] = i[2]),
      (o[6] = n[2]),
      (o[10] = a[2]),
      s
    );
  }
  static lookAt2(e, t, r, a) {
    var i = defaultValue(a, new Matrix4()),
      n = e[0],
      s = e[1],
      o = e[2],
      l = r[0],
      u = r[1],
      h = r[2],
      d = t[0],
      c = t[1],
      f = t[2];
    if (
      Math.abs(n - d) < 1e-6 &&
      Math.abs(s - c) < 1e-6 &&
      Math.abs(o - f) < 1e-6
    )
      return Matrix4.identity();
    (a = n - d),
      (e = s - c),
      (r = o - f),
      (d = u * (r *= t = 1 / Math.sqrt(a * a + e * e + r * r)) - h * (e *= t)),
      (c = h * (a *= t) - l * r),
      (f = l * e - u * a),
      (t = Math.sqrt(d * d + c * c + f * f))
        ? ((d *= t = 1 / t), (c *= t), (f *= t))
        : (f = c = d = 0),
      (h = e * f - r * c),
      (l = r * d - a * f),
      (u = a * c - e * d),
      (t = Math.sqrt(h * h + l * l + u * u))
        ? ((h *= t = 1 / t), (l *= t), (u *= t))
        : (u = l = h = 0);
    let _ = i.data;
    return (
      (_[0] = d),
      (_[1] = h),
      (_[2] = a),
      (_[3] = 0),
      (_[4] = c),
      (_[5] = l),
      (_[6] = e),
      (_[7] = 0),
      (_[8] = f),
      (_[9] = u),
      (_[10] = r),
      (_[11] = 0),
      (_[12] = -(d * n + c * s + f * o)),
      (_[13] = -(h * n + l * s + u * o)),
      (_[14] = -(a * n + e * s + r * o)),
      (_[15] = 1),
      i
    );
  }
  static perspective(e, t, r, a) {
    var i = new Matrix4();
    let n = i._data;
    e = 1 / Math.tan(e / 2);
    return (
      (n[0] = e / t),
      (n[1] = 0),
      (n[2] = 0),
      (n[3] = 0),
      (n[4] = 0),
      (n[5] = e),
      (n[6] = 0),
      (n[7] = 0),
      (n[8] = 0),
      (n[9] = 0),
      (n[11] = -1),
      (n[12] = 0),
      (n[13] = 0),
      (n[15] = 0),
      null != a && a !== 1 / 0
        ? ((e = 1 / (r - a)), (n[10] = (a + r) * e), (n[14] = 2 * a * r * e))
        : ((n[10] = -1), (n[14] = -2 * r)),
      i
    );
  }
  static orthographic(e, t, r, a, i, n) {
    var s = 1 / (t - e),
      o = 1 / (a - r),
      l = 1 / (i - n);
    return Matrix4.fromArray([
      2 * s,
      0,
      0,
      0,
      0,
      2 * o,
      0,
      0,
      0,
      0,
      2 * l,
      0,
      -(e + t) * s,
      -(a + r) * o,
      (n + i) * l,
      1,
    ]);
  }
  static fromQuat(e, t) {
    let r = defaultValue(t, new Matrix4());
    var [a, i, n, s] = e,
      o = a + a,
      l = i + i,
      u = n + n,
      h = a * o,
      t = a * l,
      e = a * u,
      a = i * l,
      i = i * u,
      n = n * u,
      o = s * o,
      l = s * l,
      u = s * u;
    return (
      r._data.set([
        1 - (a + n),
        t + u,
        e - l,
        0,
        t - u,
        1 - (h + n),
        i + o,
        0,
        e + l,
        i - o,
        1 - (h + a),
        0,
        0,
        0,
        0,
        1,
      ]),
      r
    );
  }
  static toEulerAngles(e) {
    let t, r, a;
    e = e._data;
    return (
      (a =
        e[10] < 0.998 && -0.998 < e[10]
          ? ((t = Math.asin(-e[8])),
            (r = Math.atan2(e[6], e[10])),
            Math.atan2(e[4], e[0]))
          : ((t = Math.atan2(e[6], e[7])), (r = Math.atan2(e[2], e[1])), 0)),
      [t, r, a]
    );
  }
}
function create() {
  return new Float32Array([0, 0, 0, 1]);
}
function identity(e) {
  return (e[0] = 0), (e[1] = 0), (e[2] = 0), (e[3] = 1), e;
}
function copy(e, t) {
  let r = defaultValue(t, create());
  return (r[0] = e[0]), (r[1] = e[1]), (r[2] = e[2]), (r[3] = e[3]), r;
}
function getAngle(e, t) {
  t = dot(e, t);
  return Math.acos(2 * t * t - 1);
}
function normalize(e, t) {
  return Vector4.normalize(e, t);
}
function rotateX(e, t, r) {
  let a = defaultValue(r, create());
  t *= 0.5;
  var i = e[0],
    n = e[1],
    s = e[2],
    r = e[3],
    e = Math.sin(t),
    t = Math.cos(t);
  return (
    (a[0] = i * t + r * e),
    (a[1] = n * t + s * e),
    (a[2] = s * t - n * e),
    (a[3] = r * t - i * e),
    a
  );
}
function rotateY(e, t, r) {
  let a = defaultValue(r, create());
  t *= 0.5;
  var i = e[0],
    n = e[1],
    s = e[2],
    r = e[3],
    e = Math.sin(t),
    t = Math.cos(t);
  return (
    (a[0] = i * t - s * e),
    (a[1] = n * t + r * e),
    (a[2] = s * t + i * e),
    (a[3] = r * t - n * e),
    a
  );
}
function rotateZ(e, t, r) {
  let a = defaultValue(r, create());
  t *= 0.5;
  var i = e[0],
    n = e[1],
    s = e[2],
    r = e[3],
    e = Math.sin(t),
    t = Math.cos(t);
  return (
    (a[0] = i * t + n * e),
    (a[1] = n * t - i * e),
    (a[2] = s * t + r * e),
    (a[3] = r * t - s * e),
    a
  );
}
function fromEulerAngles(e, t, r) {
  var a = 0.5 * e,
    i = 0.5 * t,
    n = 0.5 * r,
    e = Math.cos(a),
    t = Math.sin(a),
    r = Math.cos(i),
    a = Math.sin(i),
    i = Math.cos(n),
    n = Math.sin(n);
  return [
    i * r * t - n * a * e,
    i * a * e + n * r * t,
    n * r * e - i * a * t,
    i * r * e + n * a * t,
  ];
}
function multiple(e, t, r) {
  let a = defaultValue(r, create());
  var i = e[0],
    n = e[1],
    s = e[2],
    o = e[3],
    l = t[0],
    r = t[1],
    e = t[2],
    t = t[3];
  return (
    (a[0] = i * t + o * l + n * e - s * r),
    (a[1] = n * t + o * r + s * l - i * e),
    (a[2] = s * t + o * e + i * r - n * l),
    (a[3] = o * t - i * l - n * r - s * e),
    a
  );
}
function dot(e, t) {
  return Vector4.dot(e, t);
}
function rotateByAxis(e, t, r, a) {
  let i = defaultValue(a, create());
  r *= 0.5;
  var n = Math.sqrt(t[0] * t[0] + t[1] * t[1] + t[2] * t[2]);
  if (Math.abs(n) < Number.EPSILON) return e;
  var s = Math.sin(r) / n,
    o = s * t[0],
    l = s * t[1],
    a = s * t[2],
    n = Math.cos(r),
    s = e[0],
    t = e[1],
    r = e[2],
    e = e[3];
  return (
    (i[0] = s * n + e * o + t * a - r * l),
    (i[1] = t * n + e * l + r * o - s * a),
    (i[2] = r * n + e * a + s * l - t * o),
    (i[3] = e * n - s * o - t * l - r * a),
    i
  );
}
var quat = Object.freeze({
  __proto__: null,
  create: create,
  identity: identity,
  copy: copy,
  getAngle: getAngle,
  normalize: normalize,
  rotateX: rotateX,
  rotateY: rotateY,
  rotateZ: rotateZ,
  fromEulerAngles: fromEulerAngles,
  multiple: multiple,
  dot: dot,
  rotateByAxis: rotateByAxis,
});
function radiansToDegrees(e) {
  return e * (180 / Math.PI);
}
function degreesToRadians(e) {
  return (e / 180) * Math.PI;
}
function areRectanglesIntersecting(e, t, r = !1) {
  if (e.topRight[0] < t.bottomLeft[0] || t.topRight[0] < e.bottomLeft[0])
    return !1;
  if (r) {
    if (e.topRight[1] > t.bottomLeft[1] || t.topRight[1] > e.bottomLeft[1])
      return !1;
  } else if (e.topRight[1] < t.bottomLeft[1] || t.topRight[1] < e.bottomLeft[1])
    return !1;
  return !0;
}
var LogLevel,
  ERGBEType,
  EDataFormatType,
  GLDrawType,
  GLBingBufferTarget,
  EGLDataType,
  EEnabledCapability,
  EDrawType,
  common = Object.freeze({
    __proto__: null,
    radiansToDegrees: radiansToDegrees,
    degreesToRadians: degreesToRadians,
    areRectanglesIntersecting: areRectanglesIntersecting,
  });
class LoadingManager {
  onStart;
  onLoad;
  onProgress;
  onError;
  itemsTotal = 0;
  itemsLoaded = 0;
  isLoading = !1;
  constructor(e, t, r, a) {
    (this.onStart = e),
      (this.onLoad = t),
      (this.onProgress = r),
      (this.onError = a);
  }
  load(e, t, r, a) {}
  itemStart(e) {
    this.itemsTotal++,
      !1 === this.isLoading &&
        this.onStart &&
        this.onStart(e, this.itemsLoaded, this.itemsTotal),
      (this.isLoading = !0);
  }
  itemEnd(e) {
    this.itemsLoaded++,
      this.onProgress && this.onProgress(e, this.itemsLoaded, this.itemsTotal),
      this.itemsLoaded === this.itemsTotal &&
        ((this.isLoading = !1), this.onLoad && this.onLoad());
  }
  itemError(e) {
    this.onError && this.onError(e);
  }
}
!(function (e) {
  (e[(e.DEBUG = 0)] = "DEBUG"),
    (e[(e.INFO = 1)] = "INFO"),
    (e[(e.WARN = 2)] = "WARN"),
    (e[(e.ERROR = 3)] = "ERROR");
})((LogLevel = LogLevel || {}));
class Logger {
  _level;
  constructor(e) {
    this._level = e;
  }
  getCurrentTime() {
    return performance.now().toString();
  }
  print(e, t, ...r) {
    var a;
    e >= this._level &&
      ((a = this.getCurrentTime().split(".")[0]),
      (e = LogLevel[e]),
      console.log(`[Mirror:${a}] [${e}] ${t}`, r));
  }
  get level() {
    return this._level;
  }
  set level(e) {
    this._level = e;
  }
  debug(e, ...t) {
    this.print(LogLevel.DEBUG, e, t);
  }
  log(e, ...t) {
    this.print(LogLevel.INFO, e, t);
  }
  warn(e, ...t) {
    this.print(LogLevel.WARN, e, t);
  }
  error(e, ...t) {
    this.print(LogLevel.ERROR, e, t);
  }
}
const logger =
  "pro" === process.env.NODE_ENV
    ? new Logger(LogLevel.WARN)
    : new Logger(LogLevel.INFO);
function fetchFile(e) {
  const t = fetch(e);
  return t.then(e => e.blob().then(e => e.arrayBuffer()));
}
function fetchText(e) {
  const t = fetch(e);
  return t.then(e => e.text());
}
function fetchJson(e) {
  const t = fetch(e);
  return t.then(e => e.json());
}
async function loadImageElement(i) {
  return new Promise((t, r) => {
    const a = new Image();
    (a.onload = e => {
      t(a);
    }),
      (a.onerror = e => {
        logger.warn("load Image false", e, i), r(e);
      }),
      (a.src = i);
  });
}
async function loadImageBitmap(e) {
  try {
    const r = await fetch(e);
    var t = await r.blob();
    return createImageBitmap(t, {
      colorSpaceConversion: "none",
      resizeQuality: "high",
    });
  } catch (e) {
    logger.warn(e);
  }
  return null;
}
!(function (e) {
  (e[(e.UNSIGNEDBYTETYPE = 0)] = "UNSIGNEDBYTETYPE"),
    (e[(e.FLOATTYPE = 1)] = "FLOATTYPE"),
    (e[(e.HALFFLOATTYPE = 2)] = "HALFFLOATTYPE");
})((ERGBEType = ERGBEType || {})),
  (function (e) {
    (e[(e.RGBEFORMAT = 0)] = "RGBEFORMAT"),
      (e[(e.RGBFORMAT = 1)] = "RGBFORMAT");
  })((EDataFormatType = EDataFormatType || {}));
const _floatView = new Float32Array(1),
  _int32View = new Int32Array(_floatView.buffer);
function toHalfFloat(e) {
  _floatView[0] = e;
  var t = _int32View[0];
  let r = (t >> 16) & 32768,
    a = (t >> 12) & 2047;
  e = (t >> 23) & 255;
  return (
    e < 103 ||
      (142 < e
        ? ((r |= 31744), (r |= (255 == e ? 0 : 1) && 8388607 & t))
        : e < 113
        ? ((a |= 2048), (r |= (a >> (114 - e)) + ((a >> (113 - e)) & 1)))
        : ((r |= ((e - 112) << 10) | (a >> 1)), (r += 1 & a))),
    r
  );
}
class RGBELoader extends LoadingManager {
  constructor() {
    super(), (this.type = ERGBEType.UNSIGNEDBYTETYPE);
  }
  type;
  parse(r) {
    var T = 1,
      a = 2,
      m = 3,
      S = 4,
      A = (e, t) => {
        switch (e) {
          case T:
            logger.error("RGBELoader Read Error: " + (t || ""));
            break;
          case a:
            logger.error("RGBELoader Write Error: " + (t || ""));
            break;
          case m:
            logger.error("RGBELoader Bad File Format: " + (t || ""));
            break;
          default:
            logger.error("RGBELoader: Error: " + (t || ""));
        }
        return -1;
      },
      l = 1,
      u = 2,
      h = 4,
      d = "\n";
    const c = (e, t, r) => {
      t = t || 1024;
      for (
        var a = e.pos,
          i = -1,
          n = 0,
          s = "",
          o = String.fromCharCode.apply(
            null,
            new Uint16Array(e.subarray(a, a + 128))
          );
        (i = o.indexOf(d)) < 0 && n < t && a < e.byteLength;

      )
        (s += o),
          (n += o.length),
          (a += 128),
          (o += String.fromCharCode.apply(
            null,
            new Uint16Array(e.subarray(a, a + 128))
          ));
      return -1 < i
        ? (!1 !== r && (e.pos += n + i + 1), s + o.slice(0, i))
        : null;
    };
    let e = new Uint8Array(r);
    e.pos = 0;
    var i,
      n,
      s,
      o,
      f,
      _,
      p,
      E,
      g,
      v,
      R = (e => {
        var t,
          r,
          a = /^\s*GAMMA\s*=\s*(\d+(\.\d+)?)\s*$/,
          i = /^\s*EXPOSURE\s*=\s*(\d+(\.\d+)?)\s*$/,
          n = /^\s*FORMAT=(\S+)\s*$/,
          s = /^\s*\-Y\s+(\d+)\s+\+X\s+(\d+)\s*$/,
          o = {
            valid: 0,
            string: "",
            comments: "",
            programtype: "RGBE",
            format: "",
            gamma: 1,
            exposure: 1,
            width: 0,
            height: 0,
          };
        if (e.pos >= e.byteLength || !(t = c(e)))
          return A(T, "no header found");
        if (!(r = t.match(/^#\?(\S+)/))) return A(m, "bad initial token");
        for (o.valid |= l, o.programtype = r[1], o.string += t + "\n"; ; ) {
          if (null === (t = c(e))) break;
          if (((o.string += t + "\n"), "#" !== t.charAt(0))) {
            if (
              ((r = t.match(a)) && (o.gamma = parseFloat(r[1])),
              (r = t.match(i)) && (o.exposure = parseFloat(r[1])),
              (r = t.match(n)) && ((o.valid |= u), (o.format = r[1])),
              (r = t.match(s)) &&
                ((o.valid |= h),
                (o.height = parseInt(r[1], 10)),
                (o.width = parseInt(r[2], 10))),
              o.valid & u && o.valid & h)
            )
              break;
          } else o.comments += t + "\n";
        }
        return o.valid & u
          ? o.valid & h
            ? o
            : A(m, "missing image size specifier")
          : A(m, "missing format specifier");
      })(e);
    if ((logger.debug(">>>readHeader:", R), -1 !== R)) {
      var b = R.width,
        y = R.height,
        r = ((e, t, r) => {
          var a,
            i,
            n,
            s,
            o,
            l,
            u,
            h,
            d,
            c,
            f,
            _,
            p,
            E = t,
            g = r;
          if (E < 8 || 32767 < E || 2 !== e[0] || 2 !== e[1] || 128 & e[2])
            return new Uint8Array(e);
          if (E !== ((e[2] << 8) | e[3])) return A(m, "wrong scanline width");
          if (!(a = new Uint8Array(4 * t * r)).length)
            return A(S, "unable to allocate buffer space");
          for (
            n = i = 0, h = 4 * E, p = new Uint8Array(4), l = new Uint8Array(h);
            0 < g && n < e.byteLength;

          ) {
            if (n + 4 > e.byteLength) return A(T);
            if (
              ((p[0] = e[n++]),
              (p[1] = e[n++]),
              (p[2] = e[n++]),
              (p[3] = e[n++]),
              2 != p[0] || 2 != p[1] || ((p[2] << 8) | p[3]) != E)
            )
              return A(m, "bad rgbe scanline format");
            for (u = 0; u < h && n < e.byteLength; ) {
              if (
                ((_ = 128 < (s = e[n++])) && (s -= 128), 0 === s || h < u + s)
              )
                return A(m, "bad scanline data");
              if (_) for (o = e[n++], d = 0; d < s; d++) l[u++] = o;
              else l.set(e.subarray(n, n + s), u), (u += s), (n += s);
            }
            for (c = E, d = 0; d < c; d++)
              (a[i] = l[d + (f = 0)]),
                (f += E),
                (a[i + 1] = l[d + f]),
                (f += E),
                (a[i + 2] = l[d + f]),
                (f += E),
                (a[i + 3] = l[d + f]),
                (i += 4);
            g--;
          }
          return a;
        })(e.subarray(e.pos), b, y);
      if (-1 !== r) {
        let e, t;
        var L = r;
        switch (this.type) {
          case ERGBEType.UNSIGNEDBYTETYPE:
            (e = L), (t = EDataFormatType.RGBEFORMAT);
            break;
          case ERGBEType.FLOATTYPE:
            for (
              var w = (L.length / 4) * 3, N = new Float32Array(w), I = 0;
              I < w;
              I++
            )
              (E = N),
                (g = 3 * I),
                (v = void 0),
                (v = (_ = L)[(p = 4 * I) + 3]),
                (v = Math.pow(2, v - 128) / 255),
                (E[g + 0] = _[p + 0] * v),
                (E[g + 1] = _[p + 1] * v),
                (E[g + 2] = _[p + 2] * v);
            (e = N), (t = EDataFormatType.RGBFORMAT);
            break;
          case ERGBEType.HALFFLOATTYPE:
            for (
              var w = (L.length / 4) * 3, M = new Uint16Array(w), I = 0;
              I < w;
              I++
            )
              (s = M),
                (o = 3 * I),
                (f = void 0),
                (f = (i = L)[(n = 4 * I) + 3]),
                (f = Math.pow(2, f - 128) / 255),
                (s[o + 0] = toHalfFloat(i[n + 0] * f)),
                (s[o + 1] = toHalfFloat(i[n + 1] * f)),
                (s[o + 2] = toHalfFloat(i[n + 2] * f));
            (e = M), (t = EDataFormatType.RGBFORMAT);
            break;
          default:
            logger.error("RGBELoader: unsupported type: ", this.type);
        }
        return {
          width: b,
          height: y,
          data: e,
          header: R.string,
          gamma: R.gamma,
          exposure: R.exposure,
          format: t,
          type: this.type,
        };
      }
    }
    return null;
  }
  async load(e) {
    e = await fetchFile(e);
    return this.parse(e);
  }
}
class MapCache {
  objects = {};
  get(e) {
    return this.objects[e];
  }
  add(e, t) {
    this.objects[e] = t;
  }
  remove(e) {
    delete this.objects[e];
  }
  clear() {
    this.objects = {};
  }
}
class ImageLoader extends LoadingManager {
  static get singleton() {
    return (
      void 0 === ImageLoader._singleton &&
        (ImageLoader._singleton = new ImageLoader()),
      ImageLoader._singleton
    );
  }
  constructor() {
    super();
  }
  _cache = new MapCache();
  static _singleton;
  load(e, t, r, a) {
    let i = this._cache.get(e);
    if (i)
      return (
        this.itemStart(e),
        setTimeout(() => {
          t && t(i), this.itemEnd(e);
        }, 0),
        i
      );
    let n = new Image();
    if (((n.crossOrigin = ""), (n.src = e), n.complete))
      return (
        this.itemStart(e),
        void setTimeout(() => {
          t && t(n), this.itemEnd(e);
        }, 0)
      );
    n.onload = () => {
      t && t(n), this.itemEnd(e);
    };
  }
}
class FileLoader extends LoadingManager {
  _cache = new MapCache();
  _loading = {};
  _path;
  static _instanse;
  static get singleton() {
    return (
      this._instanse || (this._instanse = new FileLoader()), this._instanse
    );
  }
  constructor() {
    super();
  }
  setPath(e) {
    this._path = e;
  }
  load(r, e, t, a) {
    var i = r || "";
    this._path && this._path;
    let n = this._cache.get(r);
    if (void 0 !== n)
      return (
        this.itemStart(r),
        setTimeout(() => {
          e && e(n), this.itemEnd(r);
        }, 0),
        n
      );
    null == this._loading[r]
      ? (r.match(/^data:(.*?)(;base64)?,(.*)$/),
        (this._loading[r] = []),
        this._loading[r].push({ onLoad: e, onProgress: t, onErr: a }),
        this._fetchResource(r).then(t => {
          let e = this._loading[r];
          delete this._loading[r],
            !0 === t.ok
              ? e.forEach(e => {
                  e.onLoad && e.onLoad(t);
                })
              : this.itemError(r),
            this.itemEnd(r);
        }),
        this.itemStart(r))
      : this._loading[r].push({ onLoad: e, onProgress: t, onErr: a });
  }
  _fetchResource(e) {
    return fetch(e);
  }
}
const fileloader = FileLoader.singleton;
class LoaderUtils {
  static decodeText(e) {
    if ("undefined" != typeof TextDecoder) return new TextDecoder().decode(e);
    for (var t = "", r = 0, a = e.length; r < a; r++)
      t += String.fromCharCode(e[r]);
    return decodeURIComponent(escape(t));
  }
  static extractUrlBase(e) {
    var t = e.lastIndexOf("/");
    return -1 === t ? "./" : e.substr(0, t + 1);
  }
}
!(function (e) {
  (e[(e.STATIC_DRAW = 0)] = "STATIC_DRAW"),
    (e[(e.DYNAMIC_DRAW = 1)] = "DYNAMIC_DRAW"),
    (e[(e.STREAM_DRAW = 2)] = "STREAM_DRAW");
})((GLDrawType = GLDrawType || {})),
  (function (e) {
    (e[(e.ARRAY_BUFFER = 0)] = "ARRAY_BUFFER"),
      (e[(e.ELEMENT_ARRAY_BUFFER = 1)] = "ELEMENT_ARRAY_BUFFER");
  })((GLBingBufferTarget = GLBingBufferTarget || {})),
  (function (e) {
    (e[(e.BYTE = 5120)] = "BYTE"),
      (e[(e.UNSIGNED_BYTE = 5121)] = "UNSIGNED_BYTE"),
      (e[(e.SHORT = 5122)] = "SHORT"),
      (e[(e.UNSIGNED_SHORT = 5123)] = "UNSIGNED_SHORT"),
      (e[(e.INT = 5124)] = "INT"),
      (e[(e.UNSIGNED_INT = 5125)] = "UNSIGNED_INT"),
      (e[(e.FLOAT = 5126)] = "FLOAT"),
      (e[(e.UNSIGNED_SHORT_4_4_4_4 = 32819)] = "UNSIGNED_SHORT_4_4_4_4"),
      (e[(e.UNSIGNED_SHORT_5_5_5_1 = 32820)] = "UNSIGNED_SHORT_5_5_5_1"),
      (e[(e.UNSIGNED_SHORT_5_6_5 = 33635)] = "UNSIGNED_SHORT_5_6_5"),
      (e[(e.HALF_FLOAT = 5131)] = "HALF_FLOAT"),
      (e[(e.UNSIGNED_INT_2_10_10_10_REV = 33640)] =
        "UNSIGNED_INT_2_10_10_10_REV"),
      (e[(e.UNSIGNED_INT_10F_11F_11F_REV = 35899)] =
        "UNSIGNED_INT_10F_11F_11F_REV"),
      (e[(e.UNSIGNED_INT_5_9_9_9_REV = 35902)] = "UNSIGNED_INT_5_9_9_9_REV"),
      (e[(e.FLOAT_32_UNSIGNED_INT_24_8_REV = 36269)] =
        "FLOAT_32_UNSIGNED_INT_24_8_REV"),
      (e[(e.UNSIGNED_INT_24_8 = 34042)] = "UNSIGNED_INT_24_8"),
      (e[(e.FLOAT_VEC2 = 35664)] = "FLOAT_VEC2"),
      (e[(e.FLOAT_VEC3 = 35665)] = "FLOAT_VEC3"),
      (e[(e.FLOAT_VEC4 = 35666)] = "FLOAT_VEC4"),
      (e[(e.INT_VEC2 = 35667)] = "INT_VEC2"),
      (e[(e.INT_VEC3 = 35668)] = "INT_VEC3"),
      (e[(e.INT_VEC4 = 35669)] = "INT_VEC4"),
      (e[(e.BOOL = 35670)] = "BOOL"),
      (e[(e.BOOL_VEC2 = 35671)] = "BOOL_VEC2"),
      (e[(e.BOOL_VEC3 = 35672)] = "BOOL_VEC3"),
      (e[(e.BOOL_VEC4 = 35673)] = "BOOL_VEC4"),
      (e[(e.FLOAT_MAT2 = 35674)] = "FLOAT_MAT2"),
      (e[(e.FLOAT_MAT3 = 35675)] = "FLOAT_MAT3"),
      (e[(e.FLOAT_MAT4 = 35676)] = "FLOAT_MAT4"),
      (e[(e.SAMPLER_2D = 35678)] = "SAMPLER_2D"),
      (e[(e.SAMPLER_CUBE = 35680)] = "SAMPLER_CUBE"),
      (e[(e.SAMPLER_3D = 35679)] = "SAMPLER_3D"),
      (e[(e.SAMPLER_2D_SHADOW = 35682)] = "SAMPLER_2D_SHADOW"),
      (e[(e.FLOAT_MAT2x3 = 35685)] = "FLOAT_MAT2x3"),
      (e[(e.FLOAT_MAT2x4 = 35686)] = "FLOAT_MAT2x4"),
      (e[(e.FLOAT_MAT3x2 = 35687)] = "FLOAT_MAT3x2"),
      (e[(e.FLOAT_MAT3x4 = 35688)] = "FLOAT_MAT3x4"),
      (e[(e.FLOAT_MAT4x2 = 35689)] = "FLOAT_MAT4x2"),
      (e[(e.FLOAT_MAT4x3 = 35690)] = "FLOAT_MAT4x3"),
      (e[(e.SAMPLER_2D_ARRAY = 36289)] = "SAMPLER_2D_ARRAY"),
      (e[(e.SAMPLER_2D_ARRAY_SHADOW = 36292)] = "SAMPLER_2D_ARRAY_SHADOW"),
      (e[(e.SAMPLER_CUBE_SHADOW = 36293)] = "SAMPLER_CUBE_SHADOW"),
      (e[(e.UNSIGNED_INT_VEC2 = 36294)] = "UNSIGNED_INT_VEC2"),
      (e[(e.UNSIGNED_INT_VEC3 = 36295)] = "UNSIGNED_INT_VEC3"),
      (e[(e.UNSIGNED_INT_VEC4 = 36296)] = "UNSIGNED_INT_VEC4"),
      (e[(e.INT_SAMPLER_2D = 36298)] = "INT_SAMPLER_2D"),
      (e[(e.INT_SAMPLER_3D = 36299)] = "INT_SAMPLER_3D"),
      (e[(e.INT_SAMPLER_CUBE = 36300)] = "INT_SAMPLER_CUBE"),
      (e[(e.INT_SAMPLER_2D_ARRAY = 36303)] = "INT_SAMPLER_2D_ARRAY"),
      (e[(e.UNSIGNED_INT_SAMPLER_2D = 36306)] = "UNSIGNED_INT_SAMPLER_2D"),
      (e[(e.UNSIGNED_INT_SAMPLER_3D = 36307)] = "UNSIGNED_INT_SAMPLER_3D"),
      (e[(e.UNSIGNED_INT_SAMPLER_CUBE = 36308)] = "UNSIGNED_INT_SAMPLER_CUBE"),
      (e[(e.UNSIGNED_INT_SAMPLER_2D_ARRAY = 36311)] =
        "UNSIGNED_INT_SAMPLER_2D_ARRAY"),
      (e[(e.TEXTURE_2D = 3553)] = "TEXTURE_2D"),
      (e[(e.TEXTURE_CUBE_MAP = 34067)] = "TEXTURE_CUBE_MAP"),
      (e[(e.TEXTURE_3D = 32879)] = "TEXTURE_3D"),
      (e[(e.TEXTURE_2D_ARRAY = 35866)] = "TEXTURE_2D_ARRAY");
  })((EGLDataType = EGLDataType || {}));
class GLHelper {
  static glBindBuffer(e, t, r, a, i) {
    t =
      t === GLBingBufferTarget.ARRAY_BUFFER
        ? e.ARRAY_BUFFER
        : e.ELEMENT_ARRAY_BUFFER;
    e.bindBuffer(t, r), e.bufferData(t, a, i);
  }
  static createBufferFromTypedArray(e, t, r, a) {
    var i = e.createBuffer();
    return GLHelper.bindBuffer(e, i, t, r, a), i;
  }
  static bindBuffer(e, t, r, a, i) {
    let n = e.STATIC_DRAW;
    i === GLDrawType.DYNAMIC_DRAW && (n = e.DYNAMIC_DRAW),
      i === GLDrawType.STREAM_DRAW && (n = e.STREAM_DRAW),
      GLHelper.glBindBuffer(e, r, t, a, n);
  }
  static bindIBOFromVertexInfo(t, r) {
    let a = r.vertexAttrBufferCache;
    if (a.hasOwnProperty("indices")) {
      let e = a.indices;
      r = e.attrInfo;
      e.glBuffer
        ? GLHelper.bindBuffer(
            t,
            e.glBuffer,
            t.ELEMENT_ARRAY_BUFFER,
            r.buffer,
            t.STATIC_DRAW
          )
        : (e.glBuffer = GLHelper.createBufferFromTypedArray(
            t,
            t.ELEMENT_ARRAY_BUFFER,
            r.buffer
          ));
    }
  }
}
!(function (e) {
  (e[(e.BLEND = 0)] = "BLEND"),
    (e[(e.CULL_FACE = 1)] = "CULL_FACE"),
    (e[(e.DEPTH_TEST = 2)] = "DEPTH_TEST"),
    (e[(e.DITHER = 3)] = "DITHER"),
    (e[(e.POLYGON_OFFSET_FILL = 4)] = "POLYGON_OFFSET_FILL"),
    (e[(e.SAMPLE_ALPHA_TO_COVERAGE = 5)] = "SAMPLE_ALPHA_TO_COVERAGE"),
    (e[(e.SAMPLE_COVERAGE = 6)] = "SAMPLE_COVERAGE"),
    (e[(e.SCISSOR_TEST = 7)] = "SCISSOR_TEST"),
    (e[(e.STENCIL_TEST = 8)] = "STENCIL_TEST");
})((EEnabledCapability = EEnabledCapability || {}));
class State {
  constructor() {
    this._initEnabled();
  }
  _initEnabled() {
    this.enable(EEnabledCapability.DEPTH_TEST),
      this.enable(EEnabledCapability.DITHER),
      this.enable(EEnabledCapability.CULL_FACE);
  }
  _enabledCapabilitieNameMap;
  _enabledCapabilities = {};
  get capabilities() {
    return this._enabledCapabilities;
  }
  isCapabilityEnabled(e) {
    return this._enabledCapabilities[e] || !1;
  }
  getStateFlags() {
    const e = Object.keys(this._enabledCapabilities);
    return e.map(e => parseInt(e, 10));
  }
  disableAll() {
    var r = this.getStateFlags();
    for (let e = 0, t = r.length; e < t; e++) {
      var a = r[e];
      this.disable(a);
    }
  }
  enable(e) {
    !0 !== this._enabledCapabilities[e] && (this._enabledCapabilities[e] = !0);
  }
  apply() {}
  disable(e) {
    this._enabledCapabilities[e] && delete this._enabledCapabilities[e];
  }
}
class Helper {
  static calculateTimestamp(e, t) {
    var r = performance.now();
    e();
    r = performance.now() - r;
    (t = t || ""),
      logger.debug(`${t}: a task took ${r}  milliseconds to execute. `);
  }
  static generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, e => {
      var t = (16 * Math.random()) | 0;
      const r = "x" === e ? t : (3 & t) | 8;
      return r.toString(16);
    });
  }
}
!(function (e) {
  (e[(e.POINTS = 0)] = "POINTS"),
    (e[(e.LINES = 1)] = "LINES"),
    (e[(e.LINES_LOOP = 2)] = "LINES_LOOP"),
    (e[(e.LINES_STRIP = 3)] = "LINES_STRIP"),
    (e[(e.TRIANGLES = 4)] = "TRIANGLES"),
    (e[(e.TRIANGLES_STRIP = 5)] = "TRIANGLES_STRIP"),
    (e[(e.TRIANGLE_FAN = 6)] = "TRIANGLE_FAN");
})((EDrawType = EDrawType || {}));
class DrawableObj {
  constructor() {
    this.id = Helper.generateUUID();
  }
  id;
  bindIBO(e) {
    GLHelper.bindIBOFromVertexInfo(e, this);
  }
  texture;
  material;
  shaderGenerator;
  drawType;
  drawArrayOffset;
  drawArrayCount;
  vertexAttrBufferCache = null;
  vertexInfo;
  programInfo;
  uniforms = {};
  onBeforeDraw;
  onAfterDraw;
  renderState = new State();
  isDrawWithoutVPMatrix = !1;
}
class AttributeInfo {
  name;
  buffer;
  isConstantToShader;
  numComponents;
  sizeofStride;
  offsetofStride;
  normalized;
  glType;
  buffertype;
  constructor(e, t) {
    (this.name = e),
      (this.buffer = t.buffer),
      (this.isConstantToShader = t.isConstantToShader || !1),
      (this.numComponents = t.numComponents),
      (this.sizeofStride = t.sizeofStride),
      (this.offsetofStride = t.offsetofStride),
      (this.normalized = t.normalized || !1),
      this._checkBufferType();
  }
  copy(e) {
    (this.name = e.name),
      e.buffertype
        ? (this.buffertype = e.buffertype)
        : (this.buffertype = Float32Array),
      (this.buffer = new this.buffertype(e.buffer.buffer.slice(0))),
      (this.isConstantToShader = e.isConstantToShader),
      (this.numComponents = e.numComponents),
      (this.sizeofStride = e.sizeofStride),
      (this.offsetofStride = e.offsetofStride),
      (this.normalized = e.normalized);
  }
  clone() {
    let e = {};
    return e.copy(this), e;
  }
  _checkBufferType() {
    this.buffer || (this.buffertype = void 0),
      this.buffer instanceof Float32Array
        ? ((this.buffertype = Float32Array), (this.glType = EGLDataType.FLOAT))
        : this.buffer instanceof Int8Array
        ? ((this.buffertype = Int8Array), (this.glType = EGLDataType.BYTE))
        : this.buffer instanceof Uint8Array
        ? ((this.buffertype = Uint8Array),
          (this.glType = EGLDataType.UNSIGNED_BYTE))
        : this.buffer instanceof Int16Array
        ? ((this.buffertype = Int16Array), (this.glType = EGLDataType.SHORT))
        : this.buffer instanceof Uint16Array
        ? ((this.buffertype = Uint16Array),
          (this.glType = EGLDataType.UNSIGNED_SHORT))
        : this.buffer instanceof Int32Array
        ? ((this.buffertype = Int32Array), (this.glType = EGLDataType.INT))
        : this.buffer instanceof Uint32Array
        ? ((this.buffertype = Uint32Array),
          (this.glType = EGLDataType.UNSIGNED_INT))
        : this.buffer instanceof Float64Array
        ? ((this.buffertype = Float64Array),
          logger.warn(
            "Geometry Attributes: Unsupported data buffer format: Float64Array."
          ))
        : (logger.warn("Geometry Attributes: Invalid buffer data"),
          (this.buffertype = void 0));
  }
}
class Geometry {
  _vertexInfo = {};
  drawCount;
  drawOffset;
  drawType = EDrawType.TRIANGLES;
  clone() {
    let e = new Geometry();
    return e.copy(this), e;
  }
  copy(e) {
    for (const t of Object.keys(this._vertexInfo))
      this._vertexInfo[t] = e.vertexInfo[t].clone();
    (this.drawCount = e.drawCount),
      (this.drawOffset = e.drawOffset),
      (this.drawType = e.drawType);
  }
  setVertexAttribute(e, t) {
    t = new AttributeInfo(e, t);
    (this._vertexInfo[e] = t),
      "indices" === e &&
        ((this.drawCount = t.buffer.length / t.numComponents),
        (this.drawOffset = 0)),
      "position" !== e ||
        this._vertexInfo.indices ||
        ((this.drawCount = t.buffer.length / (t.sizeofStride / 4)),
        (this.drawOffset = 0));
  }
  get vertexInfo() {
    return this._vertexInfo;
  }
}
var Mirror,
  ECameraType,
  ERenderGroupType,
  EShaderType,
  ESetterType,
  colorVS =
    "// Attributes\nattribute vec4 aPosition;\n// Uniforms\nuniform mat4 uMvpMatrix;\n\nvoid main(){\n    gl_Position = uMvpMatrix * aPosition;//position;//\n}",
  colorFS =
    "precision mediump float;\r\nuniform vec4 color;\r\n\r\nvoid main(void) {\r\n\tgl_FragColor = color;\r\n}",
  transformDef = `
in vec3 aPosition;
in vec3 aNormal;
in vec3 aTangent;

uniform mat4 uModelMatrix;
uniform mat4 uVpMatrix;

out vec3 oNormal;
out vec3 oPos;
`;
const BEGIN = "void main(){ \n",
  END = "} \n",
  getTBNMatrix_vert = `
    out mat3 oTBN;

    mat3 getTBNMatrix(){
        //vec3 T =  normalize(vec3((transpose(inverse(uModelMatrix))) * vec4(aTangent,0.0)));
        //vec3 N = normalize(vec3((transpose(inverse(uModelMatrix))) * vec4(aNormal,0.0)));
        vec3 T = normalize(aTangent);
        vec3 N = normalize(aNormal);
        T = normalize(T - dot(T, N) * N);
        vec3 B = cross(N,T);

        // mat3 TBN = mat3(T,B,N);
        mat4 TBNmat4 = mat4(vec4(T,0.0),vec4(B,0.0),vec4(N,0.0), vec4(0.0,0.0,0.0,1.0));
        mat3 TBN = mat3 ((transpose(inverse(uModelMatrix))) * TBNmat4);
        return TBN;
    }
`,
  getNormal_frag = e => {
    let t = "";
    return (
      e.normalMap
        ? (t += `
            vec3 normal = texture(uNormalMap, vUv0).rgb;
            normal = normal * 2.0 - 1.0;   
            normal =  normalize(oTBN * normal);
        `)
        : (t += "vec3 normal = normalize(oNormal);"),
      t
    );
  },
  getTBNMatrix_frag = `
    in mat3 oTBN;
`,
  getNormalMap = `
    uniform sampler2D uNormalMap;

`;
function generateShaderCode(e) {
  var { options: r, material: t } = e;
  let a = "";
  (a += transformDef),
    (a += getTBNMatrix_vert),
    t.diffuseMap &&
      (a += `
        in vec2 aTexcoord_0;
        out vec2 vUv0;
        `);
  let i = "";
  (i += BEGIN),
    (i += `
        vec4 v4Position = vec4(aPosition,1.0);
        gl_Position = (uVpMatrix * (uModelMatrix * v4Position));
        oNormal = mat3(transpose(inverse(uModelMatrix))) * aNormal; 
        oTBN = getTBNMatrix();
        oPos = vec3(uModelMatrix*v4Position);
    `),
    t.diffuseMap && (i += "    vUv0 = aTexcoord_0;\n"),
    (i += END);
  var n = "#version 300 es";
  n += a;
  e = n += i;
  a = `
        precision highp float;
        uniform vec3 uAmbientColor;
        uniform float uDiffuseIntensity;
        uniform float uSpecularIntensity;
        uniform float uOpacity;
        uniform vec3 uViewPos;
        out vec4 outColor; `;
  for (let e = 0, t = r.lights.length; e < t; e++)
    a += `
        uniform vec3 uLightPos_${e};  
        uniform float uLightIntensity_${e}; 
        uniform vec3 uLightColor_${e}; 
        uniform vec3 uLightDirection_${e}; `;
  (a += `
        in vec3 oNormal; 
        in vec3 oPos;
    `),
    (a += getNormalMap),
    (a += getTBNMatrix_frag),
    t.diffuseMap &&
      (a += `
            in vec2 vUv0;
            uniform sampler2D uDiffuseMap;
        `),
    (i = ""),
    (i += BEGIN),
    (i += getNormal_frag(t)),
    (i += `vec3 color = uAmbientColor;
    `),
    t.diffuseMap &&
      (i += `
        color = texture(uDiffuseMap, vUv0).rgb *0.5;
        `);
  for (let e = 0, t = r.lights.length; e < t; e++)
    i += `vec3 lightDir = normalize(uLightDirection_${e});
        float diffDegree = max(dot(normal,lightDir),0.0);
        vec3 diffuse = diffDegree * uLightColor_${e};
        vec3 viewDir = normalize(  uViewPos - oPos);
        vec3 reflectDir = reflect(-lightDir, normal);
        float spec = pow(max(dot(viewDir, reflectDir), 0.0), 32.0);
        vec3 specular = uSpecularIntensity * spec * uLightColor_${e};
        color += uDiffuseIntensity * diffuse + specular;
        `;
  return (
    (i += "outColor = vec4(color, 1.0); "),
    (i += END),
    (n = "#version 300 es"),
    (n += a),
    { vs: e, fs: (n += i) }
  );
}
class Material {
  id;
  name;
  className = "Material";
  alphaTest;
  depthTest;
  depthWrite;
  cullMode;
  renderPipline;
  isModified = !0;
  clone() {
    let e = {};
    return (
      (e.id = this.id),
      (e.name = this.name),
      (e.alphaTest = this.alphaTest),
      (e.depthTest = this.depthTest),
      (e.depthWrite = this.depthWrite),
      (e.cullMode = this.cullMode),
      (e.isModified = !0),
      e
    );
  }
  attachToDrawObj(e) {}
}
class ColorMaterial extends Material {
  className = "ColorMaterial";
  color = Vector4.create(1, 1, 1, 1);
  constructor(e) {
    super(), e && e.color && (this.color = e.color);
  }
  attachToDrawObj(e) {
    var t;
    e &&
      ((t = this.color),
      (e.uniforms.color = [t[0], t[1], t[2], t[3]]),
      (e.shaderGenerator = () => ({ vs: colorVS, fs: colorFS })));
  }
}
class PhoneMaterial extends Material {
  className = "PhoneMaterial";
  emissiveColor = Vector3.ZERO;
  emissiveIntensity = 0.5;
  specularIntensity = 0.5;
  diffuseIntensity = 0.5;
  diffuseMap;
  diffuseColor = Vector3.ZERO;
  ambientColor = Vector3.ZERO;
  normalMap;
  attachToDrawObj(e) {
    e &&
      ((e.material = this),
      (e.shaderGenerator = generateShaderCode),
      (e.uniforms.uSpecularIntensity = this.specularIntensity),
      (e.uniforms.uDiffuseIntensity = this.diffuseIntensity),
      (e.uniforms.uAmbientColor = this.ambientColor),
      this.diffuseMap && (e.uniforms.uDiffuseMap = this.diffuseMap),
      this.normalMap && (e.uniforms.uNormalMap = this.normalMap));
  }
}
class Mesh {
  primitives = new Map();
  addPrimitive(e) {
    e && this.primitives.set(e, e);
  }
  removePrimitive(e) {
    e && this.primitives.delete(e);
  }
  copy(e) {
    this.primitives.clear(),
      e.primitives.forEach(e => {
        this.addPrimitive(e.clone());
      });
  }
  clone() {
    let e = new Mesh();
    return e.copy(this), e;
  }
}
class Primitive {
  geometry;
  material;
  drawMode;
  name;
  constructor(e, t, r) {
    (this.geometry = e), (this.material = t), (this.drawMode = r);
  }
  clone() {
    let e = new Primitive();
    return e.copy(this), e;
  }
  copy(e) {
    (this.geometry = e.geometry.clone()),
      (this.material = e.material.clone()),
      (this.drawMode = e.drawMode),
      (this.name = e.name);
  }
}
(Mirror || (Mirror = {})).defined = function (e) {
  return null != e;
};
class MiObject {
  _className = "MiObject";
  _name = "";
  _id;
  _isModified = !1;
  _modifyCallbacks = [];
  get name() {
    return this._name;
  }
  get className() {
    return this._className;
  }
  get id() {
    return this._id;
  }
  set name(e) {
    this._name = e;
  }
  set isModified(e) {
    this._isModified = e;
  }
  get isModified() {
    return this._isModified;
  }
  constructor() {
    this._id = Helper.generateUUID();
  }
}
class SceneNode extends MiObject {
  _root;
  _position = Vector3.ZERO;
  _rotation = Vector3.ZERO;
  _quaternion = Vector4.ZERO;
  _scale = Vector3.ONE;
  matrix = Matrix4.identity();
  worldMatrix = Matrix4.identity();
  parent;
  children = [];
  onChange = [];
  constructor(e) {
    super(), (this._className = "SceneNode"), (this.parent = e);
  }
  set root(e) {
    this._root = e;
  }
  get root() {
    return this._root;
  }
  get rotation() {
    return this._rotation;
  }
  set rotation([e, t, r]) {
    this._rotation = [e, t, r];
    (e = degreesToRadians(e)),
      (t = degreesToRadians(t)),
      (r = degreesToRadians(r));
    (this._quaternion = fromEulerAngles(e, t, r)), this._updateTransform();
  }
  get position() {
    return this._position;
  }
  set position(e) {
    (this._position = e), this._updateTransform();
  }
  get quaternion() {
    return this._quaternion;
  }
  set quaternion(e) {
    this._quaternion = e;
    e = Matrix4.fromQuat(e);
    (this._rotation = Matrix4.toEulerAngles(e)),
      (this._rotation = [
        radiansToDegrees(this._rotation[0]),
        radiansToDegrees(this._rotation[1]),
        radiansToDegrees(this._rotation[2]),
      ]),
      this._updateTransform();
  }
  get scale() {
    return this._scale;
  }
  set scale(e) {
    (this._scale = e), this._updateTransform();
  }
  setTransform(e) {
    e.position && (this._position = e.position),
      e.quaternion && (this._quaternion = e.quaternion),
      e.scale && (this._scale = e.scale),
      this._updateTransform();
  }
  _updateListenerArray = [];
  addUpdateListener(e) {
    e && this._updateListenerArray.push(e);
  }
  update() {
    this._updateListenerArray.forEach(e => {
      e(this);
    });
    let e = this.children;
    e &&
      0 < e.length &&
      e.forEach(e => {
        e.update();
      });
  }
  traverse(t) {
    t && t(this);
    let e = this.children;
    e &&
      0 < e.length &&
      e.forEach(e => {
        e.traverse(t);
      });
  }
  addNode(e) {
    e
      ? (this.children.push(e),
        "Scene" === (e.parent = this)._className
          ? (e.root = this)
          : (e.root = this._root),
        this.onChange &&
          this.onChange.forEach(e => {
            e(this);
          }),
        "Scene" === this._className && (this.isModified = !0))
      : logger.error("node isn't a scenenode");
  }
  removeNode(t) {
    var e = this.children.findIndex(e => e === t);
    -1 !== e && this.children.splice(e, 1);
  }
  removeAll() {
    this.children = [];
  }
  addOnChangeListener(e) {
    var t = this.onChange.length;
    return this.onChange.push(e), t;
  }
  removeOnChangeListener(e) {
    this.onChange.slice(e, 1);
  }
  _updateTransform() {
    this.matrix.compose(this._position, this._quaternion, this._scale);
    let e = this.parent;
    for (this.worldMatrix = this.matrix; e; )
      (this.worldMatrix = Matrix4.multiply(e.matrix, this.worldMatrix)),
        (e = e.parent);
    for (let e = 0, t = this.children.length; e < t; e++) {
      const r = this.children[e];
      r._updateTransform();
    }
    this._root && (this._root.isModified = !0);
  }
  applyMatrix(e) {
    (this.matrix = Matrix4.multiply(e, this.matrix)),
      this.matrix.decompose(this._position, this._quaternion, this._scale),
      this._updateTransform();
  }
}
!(function (e) {
  (e[(e.Perspective = 0)] = "Perspective"),
    (e[(e.Orthographic = 1)] = "Orthographic");
})((ECameraType = ECameraType || {}));
class Camera extends SceneNode {
  _viewMatrix = Matrix4.identity();
  _projectionMatrix = Matrix4.identity();
  _type = ECameraType.Perspective;
  upVector = Vector3.create([0, 1, 0]);
  lookAt = Vector3.create([0, 0, 0]);
  viewPort = Vector4.create(0, 0, 1, 1);
  fovy = 1;
  lookVector = void 0;
  near = 0.1;
  far = 1e3;
  aspect = 16 / 9;
  _left = -100;
  _right = 100;
  _bottom = -100;
  _top = 100;
  _orthoHeight = 100;
  set orthoHeight(e) {
    var t = e * this.aspect;
    (this._bottom = -e),
      (this._top = e),
      (this._left = -t),
      (this._right = t),
      (this._orthoHeight = e);
  }
  get orthoHeight() {
    return this._orthoHeight;
  }
  set type(e) {
    this._type = e;
  }
  get type() {
    return this._type;
  }
  constructor(e = {}) {
    super(),
      (this.fovy = e.fovy || this.fovy),
      (this.aspect = e.aspect || this.aspect),
      (this.near = e.near || this.near),
      (this.far = e.far || this.far),
      (this._left = e.left || this._left),
      (this._right = e.right || this._right),
      (this._bottom = e.bottom || this._bottom),
      (this._top = e.top || this._top),
      this.addUpdateListener(e => {
        e.updateMatrix();
      });
  }
  getLookVector() {
    return (
      (this.lookVector = Vector3.sub(this.position, this.lookAt)),
      Vector3.normalize(this.lookVector, this.lookVector),
      this.lookVector
    );
  }
  get viewMatrix() {
    return this._viewMatrix;
  }
  get projectionMatrix() {
    return this._projectionMatrix;
  }
  updateMatrix() {
    this.upVector &&
      this.lookAt &&
      (this._viewMatrix = Matrix4.lookAt2(
        this.position,
        this.lookAt,
        this.upVector
      )),
      this._type === ECameraType.Perspective
        ? (this._projectionMatrix = Matrix4.perspective(
            this.fovy,
            this.aspect,
            this.near,
            this.far
          ))
        : (this._projectionMatrix = Matrix4.orthographic(
            this._left,
            this._right,
            this._bottom,
            this._top,
            this.near,
            this.far
          ));
  }
  updateCamera(e) {
    Object.assign(this, e);
  }
}
class RenderList {
  renderObjs = [];
}
!(function (e) {
  (e[(e.BACKGROUND = 0)] = "BACKGROUND"),
    (e[(e.OPAQUE = 1)] = "OPAQUE"),
    (e[(e.TRANSPARENCE = 2)] = "TRANSPARENCE"),
    (e[(e.UILAYOUT = 3)] = "UILAYOUT");
})((ERenderGroupType = ERenderGroupType || {}));
class RenderGroup {
  id;
  priority;
  renderListArray;
  clear() {
    this.renderListArray = [];
  }
  constructor(e, t) {
    (this.id = e), (this.priority = t), (this.renderListArray = []);
  }
}
class RenderGroups {
  _renderGroupList;
  constructor() {
    this._setupRenderGroups();
  }
  get renderGroupList() {
    return this._renderGroupList;
  }
  addDrawAbleObj(e, t) {
    if (this._renderGroupList.hasOwnProperty(t)) {
      const r = this._renderGroupList[t];
      0 === r.renderListArray.length &&
        r.renderListArray.push(new RenderList()),
        r.renderListArray[0].renderObjs.push(e);
    }
  }
  clearRenderGroup() {
    for (const e in this._renderGroupList)
      this._renderGroupList.hasOwnProperty(e) &&
        this._renderGroupList[e].clear();
  }
  _setupRenderGroups() {
    (this._renderGroupList = {}),
      (this._renderGroupList[ERenderGroupType.BACKGROUND] = new RenderGroup(
        ERenderGroupType.BACKGROUND,
        500
      )),
      (this._renderGroupList[ERenderGroupType.OPAQUE] = new RenderGroup(
        ERenderGroupType.OPAQUE,
        400
      )),
      (this._renderGroupList[ERenderGroupType.TRANSPARENCE] = new RenderGroup(
        ERenderGroupType.TRANSPARENCE,
        300
      )),
      (this._renderGroupList[ERenderGroupType.UILAYOUT] = new RenderGroup(
        ERenderGroupType.UILAYOUT,
        100
      ));
  }
}
!(function (e) {
  (e[(e.VERTEX_SHADER = 0)] = "VERTEX_SHADER"),
    (e[(e.FRAGMENT_SHADER = 1)] = "FRAGMENT_SHADER");
})((EShaderType = EShaderType || {}));
class ProgramInfo {
  vertexShaderSource;
  fragmentShaderSource;
  program;
  constructor(e, t) {
    (this.vertexShaderSource = e), (this.fragmentShaderSource = t);
  }
}
class Program {
  static programIDCount = 0;
  id;
  name;
  usedTimes = 0;
  glProgram;
  glVertexShader;
  glFragmentShader;
  uniforms;
  attributes;
  constructor(e, t, r, a, i, n) {
    (this.id = t),
      (this.name = r),
      (this.glProgram = a),
      (this.glVertexShader = i),
      (this.glFragmentShader = n),
      (this.uniforms = new Uniforms(e, a)),
      (this.attributes = new Attributes(e, a));
  }
  static createProgram(e, t) {
    var r = Program._createShader(
        e,
        EShaderType.VERTEX_SHADER,
        t.vertexShaderSource
      ),
      a = Program._createShader(
        e,
        EShaderType.FRAGMENT_SHADER,
        t.fragmentShaderSource
      ),
      i = Program._createProgram(e, [r, a]),
      a = new Program(e, "", "", i, r, a);
    return (t.program = a);
  }
  static _createShader(e, t, r) {
    let a;
    return (
      t === EShaderType.VERTEX_SHADER
        ? (a = e.createShader(e.VERTEX_SHADER))
        : t === EShaderType.FRAGMENT_SHADER &&
          (a = e.createShader(e.FRAGMENT_SHADER)),
      a
        ? (e.shaderSource(a, r),
          e.compileShader(a),
          e.getShaderParameter(a, e.COMPILE_STATUS)
            ? a
            : (logger.error(
                "error occured compiling the shaders:" + e.getShaderInfoLog(a)
              ),
              e.deleteShader(a),
              null))
        : (logger.error("Create shader false with invalid shader type ! "),
          null)
    );
  }
  static _createProgram(t, e) {
    const r = t.createProgram();
    if (
      (e.forEach(e => {
        t.attachShader(r, e);
      }),
      t.linkProgram(r),
      t.getProgramParameter(r, t.LINK_STATUS))
    )
      return r;
    e = t.getProgramInfoLog(r);
    return (
      logger.error("Error in program linking:" + e), t.deleteProgram(r), null
    );
  }
}
!(function (e) {
  (e[(e.NORMALSETTER = 0)] = "NORMALSETTER"),
    (e[(e.SAMPLERSETTER = 1)] = "SAMPLERSETTER");
})((ESetterType = ESetterType || {}));
class Setter {
  type = ESetterType.NORMALSETTER;
  cache;
}
class Setter1f extends Setter {
  setValue(e, t, r) {
    e.uniform1f(t, r);
  }
}
class Setter1fv extends Setter {
  setValue(e, t, r) {
    e.uniform1fv(t, r);
  }
}
class Setter2fv extends Setter {
  setValue(e, t, r) {
    e.uniform2fv(t, r);
  }
}
class Setter3fv extends Setter {
  setValue(e, t, r) {
    e.uniform3fv(t, r);
  }
}
class Setter4fv extends Setter {
  setValue(e, t, r) {
    e.uniform4fv(t, r);
  }
}
class Setter1i extends Setter {
  setValue(e, t, r) {
    e.uniform1i(t, r);
  }
}
class Setter1iv extends Setter {
  setValue(e, t, r) {
    e.uniform1iv(t, r);
  }
}
class Setter2iv extends Setter {
  setValue(e, t, r) {
    e.uniform2iv(t, r);
  }
}
class Setter3iv extends Setter {
  setValue(e, t, r) {
    e.uniform3iv(t, r);
  }
}
class Setter4iv extends Setter {
  setValue(e, t, r) {
    e.uniform4iv(t, r);
  }
}
class Setter1ui extends Setter {
  setValue(e, t, r) {
    e.uniform1ui(t, r);
  }
}
class Setter1uiv extends Setter {
  setValue(e, t, r) {
    e.uniform1uiv(t, r);
  }
}
class Setter2uiv extends Setter {
  setValue(e, t, r) {
    e.uniform2uiv(t, r);
  }
}
class Setter3uiv extends Setter {
  setValue(e, t, r) {
    e.uniform3uiv(t, r);
  }
}
class Setter4uiv extends Setter {
  setValue(e, t, r) {
    e.uniform4uiv(t, r);
  }
}
class SetterMatrix2fv extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix2fv(t, !1, r);
  }
}
class SetterMatrix3fv extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix3fv(t, !1, r);
  }
}
class SetterMatrix4fv extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix4fv(t, !1, r);
  }
}
class SetterMatrix23f extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix2x3fv(t, !1, r);
  }
}
class SetterMatrix32f extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix3x2fv(t, !1, r);
  }
}
class SetterMatrix24f extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix3x2fv(t, !1, r);
  }
}
class SetterMatrix42f extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix4x2fv(t, !1, r);
  }
}
class SetterMatrix34f extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix3x4fv(t, !1, r);
  }
}
class SetterMatrix43f extends Setter {
  setValue(e, t, r) {
    e.uniformMatrix4x3fv(t, !1, r);
  }
}
class SetterSampler extends Setter {
  bindPoint;
  type = ESetterType.SAMPLERSETTER;
  constructor(e) {
    super(), (this.bindPoint = e);
  }
  setValue(e, t, r, a) {
    a = a || 0;
    e.activeTexture(e.TEXTURE0 + a),
      e.bindTexture(this.bindPoint, r),
      e.uniform1i(t, a);
  }
}
class Uniforms {
  static arrayTypeMap = {
    [EGLDataType.FLOAT]: new Setter1fv(),
    [EGLDataType.INT]: new Setter1iv(),
    [EGLDataType.UNSIGNED_INT]: new Setter1uiv(),
    [EGLDataType.BOOL]: new Setter1uiv(),
  };
  static typeMap = {
    [EGLDataType.FLOAT]: new Setter1f(),
    [EGLDataType.FLOAT_VEC2]: new Setter2fv(),
    [EGLDataType.FLOAT_VEC3]: new Setter3fv(),
    [EGLDataType.FLOAT_VEC4]: new Setter4fv(),
    [EGLDataType.INT]: new Setter1i(),
    [EGLDataType.INT_VEC2]: new Setter2iv(),
    [EGLDataType.INT_VEC3]: new Setter3iv(),
    [EGLDataType.INT_VEC4]: new Setter4iv(),
    [EGLDataType.UNSIGNED_INT]: new Setter1ui(),
    [EGLDataType.UNSIGNED_INT_VEC2]: new Setter2uiv(),
    [EGLDataType.UNSIGNED_INT_VEC3]: new Setter3uiv(),
    [EGLDataType.UNSIGNED_INT_VEC4]: new Setter4uiv(),
    [EGLDataType.BOOL]: new Setter1ui(),
    [EGLDataType.BOOL_VEC2]: new Setter2uiv(),
    [EGLDataType.BOOL_VEC3]: new Setter3uiv(),
    [EGLDataType.BOOL_VEC4]: new Setter4uiv(),
    [EGLDataType.FLOAT_MAT2]: new SetterMatrix2fv(),
    [EGLDataType.FLOAT_MAT3]: new SetterMatrix3fv(),
    [EGLDataType.FLOAT_MAT4]: new SetterMatrix4fv(),
    [EGLDataType.FLOAT_MAT2x3]: new SetterMatrix23f(),
    [EGLDataType.FLOAT_MAT2x4]: new SetterMatrix24f(),
    [EGLDataType.FLOAT_MAT3x2]: new SetterMatrix32f(),
    [EGLDataType.FLOAT_MAT3x4]: new SetterMatrix34f(),
    [EGLDataType.FLOAT_MAT4x2]: new SetterMatrix42f(),
    [EGLDataType.FLOAT_MAT4x3]: new SetterMatrix43f(),
    [EGLDataType.SAMPLER_2D]: new SetterSampler(EGLDataType.TEXTURE_2D),
    [EGLDataType.SAMPLER_CUBE]: new SetterSampler(EGLDataType.TEXTURE_CUBE_MAP),
    [EGLDataType.SAMPLER_3D]: new SetterSampler(EGLDataType.TEXTURE_3D),
    [EGLDataType.SAMPLER_2D_SHADOW]: new SetterSampler(EGLDataType.TEXTURE_2D),
    [EGLDataType.SAMPLER_2D_ARRAY]: new SetterSampler(
      EGLDataType.TEXTURE_2D_ARRAY
    ),
    [EGLDataType.SAMPLER_2D_ARRAY_SHADOW]: new SetterSampler(
      EGLDataType.TEXTURE_2D_ARRAY
    ),
    [EGLDataType.INT_SAMPLER_2D]: new SetterSampler(EGLDataType.TEXTURE_2D),
    [EGLDataType.INT_SAMPLER_3D]: new SetterSampler(EGLDataType.TEXTURE_3D),
    [EGLDataType.INT_SAMPLER_CUBE]: new SetterSampler(
      EGLDataType.TEXTURE_CUBE_MAP
    ),
    [EGLDataType.INT_SAMPLER_2D_ARRAY]: new SetterSampler(
      EGLDataType.TEXTURE_2D_ARRAY
    ),
    [EGLDataType.UNSIGNED_INT_SAMPLER_2D]: new SetterSampler(
      EGLDataType.TEXTURE_2D
    ),
    [EGLDataType.UNSIGNED_INT_SAMPLER_3D]: new SetterSampler(
      EGLDataType.TEXTURE_3D
    ),
    [EGLDataType.UNSIGNED_INT_SAMPLER_CUBE]: new SetterSampler(
      EGLDataType.TEXTURE_CUBE_MAP
    ),
    [EGLDataType.UNSIGNED_INT_SAMPLER_2D_ARRAY]: new SetterSampler(
      EGLDataType.TEXTURE_2D_ARRAY
    ),
  };
  map = {};
  seq = [];
  _textureCount = 0;
  constructor(t, r) {
    var a = t.getProgramParameter(r, t.ACTIVE_UNIFORMS);
    for (let e = 0; e < a; ++e) {
      var i = t.getActiveUniform(r, e),
        n = t.getUniformLocation(r, i.name);
      this._parseUniform(i, n);
    }
  }
  _parseUniform(e, t) {
    var r = 0 | e.name;
    this._addUniform(r, e.name, t, e.type),
      logger.debug("add uniform:" + e.name + " | " + e.type);
  }
  _addUniform(e, t, r, a) {
    var i = t.endsWith("[0]");
    let n;
    n = i
      ? ((t = t.replace("[0]", "")), Uniforms.arrayTypeMap[a])
      : Uniforms.typeMap[a];
    let s = new Uniform(e, r, n, i);
    s.setter.type === ESetterType.SAMPLERSETTER &&
      ((s.textureUnit = this._textureCount), this._textureCount++),
      (this.map[t] = s),
      this.seq.push(s);
  }
}
class Uniform {
  id;
  addr;
  setter;
  isArray;
  textureUnit;
  constructor(e, t, r, a) {
    (this.id = e), (this.addr = t), (this.setter = r), (this.isArray = a);
  }
}
class AttribFloatSetter extends Setter {
  setValue(e, t, r) {
    var a = r.attrInfo;
    if (!0 === a.isConstantToShader) {
      e.disableVertexAttribArray(t);
      var i = a.buffer;
      switch (i.length) {
        case 4:
          e.vertexAttrib4fv(t, i.buffer);
          break;
        case 3:
          e.vertexAttrib3fv(t, i.buffer);
          break;
        case 2:
          e.vertexAttrib2fv(t, i.buffer);
          break;
        case 1:
          e.vertexAttrib1fv(t, i.buffer);
          break;
        default:
          throw new Error(
            "the length of a float constant value must be between 1 and 4!"
          );
      }
    } else
      r.glBuffer
        ? e.bindBuffer(e.ARRAY_BUFFER, r.glBuffer)
        : (logger.debug("no glbuffer:" + a.buffer.length),
          (r.glBuffer = e.createBuffer()),
          e.bindBuffer(e.ARRAY_BUFFER, r.glBuffer),
          e.bufferData(e.ARRAY_BUFFER, a.buffer, e.STATIC_DRAW)),
        e.enableVertexAttribArray(t),
        e.vertexAttribPointer(
          t,
          a.numComponents,
          e.FLOAT,
          a.normalized || !1,
          a.sizeofStride || 0,
          a.offsetofStride || 0
        ),
        e.bindBuffer(e.ARRAY_BUFFER, null);
  }
}
class AttribIntSetter extends Setter {
  setValue(e, t, r) {
    var a = r.attrInfo;
    !0 !== a.isConstantToShader &&
      (r.glBuffer
        ? e.bindBuffer(e.ARRAY_BUFFER, r.glBuffer)
        : ((r.glBuffer = e.createBuffer()),
          e.bindBuffer(e.ARRAY_BUFFER, r.glBuffer),
          e.bufferData(e.ARRAY_BUFFER, a.buffer, e.STATIC_DRAW)),
      e.enableVertexAttribArray(t),
      e.vertexAttribIPointer(
        t,
        a.numComponents,
        a.glType || e.INT,
        a.sizeofStride || 0,
        a.offsetofStride || 0
      ),
      e.bindBuffer(e.ARRAY_BUFFER, null));
  }
}
class AttribUIntSetter extends Setter {
  setValue(e, t, r) {
    var a = r.attrInfo;
    !0 !== a.isConstantToShader &&
      (r.glBuffer
        ? e.bindBuffer(e.ARRAY_BUFFER, r.glBuffer)
        : ((r.glBuffer = e.createBuffer()),
          e.bindBuffer(e.ARRAY_BUFFER, r.glBuffer),
          e.bufferData(e.ARRAY_BUFFER, a.buffer, e.STATIC_DRAW)),
      e.enableVertexAttribArray(t),
      e.vertexAttribIPointer(
        t,
        a.numComponents,
        a.glType || e.UNSIGNED_INT,
        a.sizeofStride || 0,
        a.offsetofStride || 0
      ),
      e.bindBuffer(e.ARRAY_BUFFER, null));
  }
}
class Attribute {
  id;
  addr;
  setter;
  buffer;
  type;
  constructor(e, t, r) {
    (this.id = e),
      (this.addr = t),
      (this.setter = Attributes.typeMap[r]),
      (this.type = r);
  }
}
class Attributes {
  static typeMap = {
    [EGLDataType.FLOAT]: new AttribFloatSetter(),
    [EGLDataType.FLOAT_VEC2]: new AttribFloatSetter(),
    [EGLDataType.FLOAT_VEC3]: new AttribFloatSetter(),
    [EGLDataType.FLOAT_VEC4]: new AttribFloatSetter(),
    [EGLDataType.INT]: new AttribIntSetter(),
    [EGLDataType.INT_VEC2]: new AttribIntSetter(),
    [EGLDataType.INT_VEC3]: new AttribIntSetter(),
    [EGLDataType.INT_VEC4]: new AttribIntSetter(),
    [EGLDataType.UNSIGNED_INT]: new AttribUIntSetter(),
    [EGLDataType.UNSIGNED_INT_VEC2]: new AttribUIntSetter(),
    [EGLDataType.UNSIGNED_INT_VEC3]: new AttribUIntSetter(),
    [EGLDataType.UNSIGNED_INT_VEC4]: new AttribUIntSetter(),
    [EGLDataType.BOOL]: new AttribIntSetter(),
    [EGLDataType.BOOL_VEC2]: new AttribIntSetter(),
    [EGLDataType.BOOL_VEC3]: new AttribIntSetter(),
    [EGLDataType.BOOL_VEC4]: new AttribIntSetter(),
  };
  map = {};
  seq = [];
  _isBuiltIn(e) {
    const t = e.name;
    return t.startsWith("gl_") || t.startsWith("webgl_");
  }
  constructor(t, r) {
    var a = t.getProgramParameter(r, t.ACTIVE_ATTRIBUTES);
    for (let e = 0; e < a; ++e) {
      var i,
        n,
        s = t.getActiveAttrib(r, e);
      this._isBuiltIn(s) ||
        ((i = t.getAttribLocation(r, s.name)),
        (n = 0 | s.name),
        (n = new Attribute(n, i, s.type)),
        (this.map[s.name] = n),
        this.seq.push(n),
        logger.debug("parse attribute:" + s.name + "|" + i));
    }
  }
}
var ETextureFormat,
  ETextureDataType,
  ETextureMagFilterType,
  ETextureMinFilterType,
  ETextureWrapType,
  ButtonsType,
  LightType,
  defaultVsCode = `
attribute vec4 aPosition;
uniform mat4 uMvpMatrix;
void main() {
    gl_Position = uMvpMatrix * aPosition;
}
`,
  defaultFsCode = `
precision mediump float;
void main() {
    gl_FragColor = vec4(1.0,1.0,1.0,1.0);  // green
}
`,
  lambert_vs = `
attribute vec4 position;
attribute vec4 normal;
uniform mat4 transform;
uniform vec3 lightColor;        //入射光颜色
uniform vec3 lightDir;          //入射光方向
uniform vec3 lightColorAmbient; // 环境光颜色
varying vec4 fragColor;         //这里作为顶点光源计算颜色输出到fs

void main() {
    gl_Position = transform * position;
    vec4 aColor = vec4(1.0,1.0,1.0,1.0);
    //lambert光照模型计算
    vec3 normalizeNormal = normalize(vec3(normal));// 归一化法向量
    float cos = max(dot(lightDir, normalizeNormal), 0.0);// 计算入射角余弦值
    vec3 diffuse = lightColor * vec3(aColor) * cos;// 计算漫反射颜色
    vec3 ambient = lightColorAmbient * aColor.rgb;// 计算环境光反射颜色
    fragColor = vec4(diffuse + ambient, aColor.a); // outColor = ambient + diffuse
}
`,
  lambert_fs = `
precision mediump float;

varying vec4 fragColor;
void main() {
    gl_FragColor = fragColor;
}
`;
class BuiltInProgram {
  static createNormalProgram() {
    return new ProgramInfo(defaultVsCode, defaultFsCode);
  }
  static createLambertProgram() {
    return new ProgramInfo(lambert_vs, lambert_fs);
  }
}
const IDENTITY_MATRIX = Matrix4.identity();
class Entity extends SceneNode {
  drawableObjs = [];
  renderType;
  mesh = void 0;
  _currentTransform;
  constructor(e) {
    super(),
      (this.renderType = e || ERenderGroupType.OPAQUE),
      (this._className = "Entity");
  }
  updatePositionTransform(t) {
    var { viewMatrix: e, projectionMatrix: r } = t;
    let a = Matrix4.multiply(r, e),
      i = Matrix4.multiply(a, this.worldMatrix);
    (void 0 !== this._currentTransform && this._currentTransform.equals(i)) ||
      ((this._currentTransform = i),
      logger.debug("transformdata:", t.position),
      this.drawableObjs.forEach(e => {
        e.isDrawWithoutVPMatrix
          ? ((e.uniforms.uViewPos = IDENTITY_MATRIX.data),
            (e.uniforms.uMvpMatrix = IDENTITY_MATRIX.data),
            (e.uniforms.uVpMatrix = IDENTITY_MATRIX.data),
            (e.uniforms.uModelMatrix = IDENTITY_MATRIX.data))
          : ((e.uniforms.uViewPos = t.position),
            (e.uniforms.uMvpMatrix = i.data),
            (e.uniforms.uVpMatrix = a.data),
            (e.uniforms.uModelMatrix = this.worldMatrix.data));
      }));
  }
  addGeometry(e, t) {
    const r = new DrawableObj();
    (r.vertexInfo = e.vertexInfo),
      (r.drawArrayOffset = e.drawOffset),
      (r.drawArrayCount = e.drawCount),
      (r.drawType = e.drawType),
      t && t.attachToDrawObj(r),
      this.drawableObjs.push(r);
  }
  addDrawable(e) {
    this.drawableObjs.push(e);
  }
  setUniform(t, r) {
    this.drawableObjs.forEach(e => {
      e.uniforms[t] = r;
    });
  }
  setState(t, r = !0) {
    0 !== this.drawableObjs.length
      ? this.drawableObjs.forEach(e => {
          r
            ? (logger.debug("enbale state::", t), e.renderState.enable(t))
            : e.renderState.disable(t);
        })
      : logger.warn(
          "There are not any drawobjs in this entity! before doing setstate You need add a drawobj before."
        );
  }
  static createEntFromDrawableObj(e, t) {
    let r = new Entity(t);
    r.drawableObjs.push(e);
    var a = e.vertexInfo.indices,
      t = e.vertexInfo.position;
    return (
      (e.drawArrayCount = a
        ? a.buffer.length / a.numComponents
        : t.buffer.length / t.numComponents),
      (e.drawArrayOffset = 0),
      r
    );
  }
  static createEntFromMesh(e) {
    let i = new Entity();
    return (
      e.primitives.forEach((e, t, r) => {
        logger.debug("create drawobj");
        let a = new DrawableObj();
        (a.vertexInfo = e.geometry.vertexInfo),
          (a.drawArrayCount = e.geometry.drawCount),
          (a.drawArrayOffset = e.geometry.drawOffset),
          e.material
            ? (logger.debug("create primitive::", e),
              e.material.attachToDrawObj(a))
            : ((a.drawType = e.drawMode),
              (a.programInfo = BuiltInProgram.createNormalProgram())),
          i.drawableObjs.push(a);
      }),
      (i.mesh = e),
      i
    );
  }
}
class Scene extends SceneNode {
  _lights = [];
  _renderGroups = new RenderGroups();
  constructor() {
    super(), (this._className = "Scene");
  }
  get renderGroups() {
    return this._renderGroups;
  }
  getLights() {
    return this._lights;
  }
  addLight(e) {
    this._lights.push(e);
  }
  destory() {
    this._lights = [];
  }
  updatePositionTransform(t, e) {
    let r = (e || this).children;
    r &&
      r.forEach(e => {
        "Entity" === e.className && e.updatePositionTransform(t),
          this.updatePositionTransform(t, e);
      });
  }
}
!(function (e) {
  (e.RGB = "RGB"),
    (e.RGBA = "RGBA"),
    (e.ALPHA = "ALPHA"),
    (e.LUMINANCE = "LUMINANCE"),
    (e.LUMINANCE_ALPHA = "LUMINANCE_ALPHA"),
    (e.DEPTH_COMPONENT = "DEPTH_COMPONENT"),
    (e.DEPTH_STENCIL = "DEPTH_STENCIL"),
    (e.SRGB_EXT = "SRGB_EXT"),
    (e.SRGB_ALPHA_EXT = "SRGB_ALPHA_EXT");
})((ETextureFormat = ETextureFormat || {})),
  (function (e) {
    (e.UNSIGNED_BYTE = "UNSIGNED_BYTE"),
      (e.UNSIGNED_SHORT_4_4_4_4 = "UNSIGNED_SHORT_4_4_4_4"),
      (e.UNSIGNED_SHORT_5_5_5_1 = "UNSIGNED_SHORT_5_5_5_1"),
      (e.UNSIGNED_SHORT_5_6_5 = "UNSIGNED_SHORT_5_6_5"),
      (e.UNSIGNED_SHORT = "UNSIGNED_SHORT"),
      (e.UNSIGNED_INT = "UNSIGNED_INT"),
      (e.FLOAT = "FLOAT"),
      (e.HALF_FLOAT_OES = "HALF_FLOAT_OES");
  })((ETextureDataType = ETextureDataType || {})),
  (function (e) {
    (e.NEAREST = "NEAREST"), (e.LINEAR = "LINEAR");
  })((ETextureMagFilterType = ETextureMagFilterType || {})),
  (function (e) {
    (e.NEAREST = "NEAREST"),
      (e.LINEAR = "LINEAR"),
      (e.NEAREST_MIPMAP_NEAREST = "NEAREST_MIPMAP_NEAREST"),
      (e.LINEAR_MIPMAP_NEAREST = "LINEAR_MIPMAP_NEAREST"),
      (e.NEAREST_MIPMAP_LINEAR = "NEAREST_MIPMAP_LINEAR"),
      (e.LINEAR_MIPMAP_LINEAR = "LINEAR_MIPMAP_LINEAR");
  })((ETextureMinFilterType = ETextureMinFilterType || {})),
  (function (e) {
    (e.REPEAT = "REPEAT"),
      (e.CLAMP_TO_EDGE = "CLAMP_TO_EDGE"),
      (e.MIRRORED_REPEAT = "MIRRORED_REPEAT");
  })((ETextureWrapType = ETextureWrapType || {}));
const glconstant = {
    texFilter: void 0,
    texWrapType: void 0,
    glType: void 0,
    glFormat: void 0,
  },
  defaultOptions$1 = {
    magFilterType: ETextureMagFilterType.LINEAR,
    minFilterType: ETextureMinFilterType.NEAREST_MIPMAP_LINEAR,
    wrapSType: ETextureWrapType.REPEAT,
    wrapTType: ETextureWrapType.REPEAT,
    format: ETextureFormat.RGBA,
    type: ETextureDataType.UNSIGNED_BYTE,
    isYFlip: !1,
    isGenerateMipmap: !0,
  };
class Texture {
  constructor(e) {
    this._param = Object.assign(
      { width: e.data.width, height: e.data.height },
      defaultOptions$1,
      e
    );
  }
  _param;
  _texture;
  get texture() {
    return this._texture;
  }
  get width() {
    return this._param.width;
  }
  get height() {
    return this._param.height;
  }
  _isPowerOf2(e) {
    return 0 == (e & (e - 1));
  }
  getFilterType(e) {
    glconstant.texFilter ||
      (glconstant.texFilter = {
        NEAREST: e.NEAREST,
        LINEAR: e.LINEAR,
        NEAREST_MIPMAP_NEAREST: e.NEAREST_MIPMAP_NEAREST,
        LINEAR_MIPMAP_NEAREST: e.LINEAR_MIPMAP_NEAREST,
        NEAREST_MIPMAP_LINEAR: e.NEAREST_MIPMAP_LINEAR,
        LINEAR_MIPMAP_LINEAR: e.LINEAR_MIPMAP_LINEAR,
      }),
      glconstant.texWrapType ||
        (glconstant.texWrapType = {
          REPEAT: e.REPEAT,
          CLAMP_TO_EDGE: e.CLAMP_TO_EDGE,
          MIRRORED_REPEAT: e.MIRRORED_REPEAT,
        }),
      glconstant.glType ||
        (glconstant.glType = {
          UNSIGNED_BYTE: e.UNSIGNED_BYTE,
          UNSIGNED_SHORT: e.UNSIGNED_SHORT,
          UNSIGNED_INT: e.UNSIGNED_INT,
          FLOAT: e.FLOAT,
        }),
      glconstant.glFormat ||
        (glconstant.glFormat = {
          RGB: e.RGB,
          RGBA: e.RGBA,
          ALPHA: e.ALPHA,
          LUMINANCE: e.LUMINANCE,
          LUMINANCE_ALPHA: e.LUMINANCE_ALPHA,
          DEPTH_COMPONENT: e.DEPTH_COMPONENT,
          DEPTH_STENCIL: e.DEPTH_STENCIL,
        });
  }
  _isArrayBufferData(e) {
    return void 0 !== e.buffer;
  }
  _isBrowserData(e) {
    return (
      ("undefined" != typeof HTMLCanvasElement &&
        e instanceof HTMLCanvasElement) ||
      ("undefined" != typeof HTMLImageElement &&
        e instanceof HTMLImageElement) ||
      ("undefined" != typeof HTMLVideoElement &&
        e instanceof HTMLVideoElement) ||
      ("undefined" != typeof ImageBitmap && e instanceof ImageBitmap)
    );
  }
  createGlTexture(e) {
    if (this._param) {
      logger.debug("createglTexture::>>", this._param), this.getFilterType(e);
      var t = e.createTexture();
      e.activeTexture(e.TEXTURE0),
        e.bindTexture(e.TEXTURE_2D, t),
        this._param.isYFlip && e.pixelStorei(e.UNPACK_FLIP_Y_WEBGL, !0);
      var r = this._param.minFilterType;
      r &&
        e.texParameteri(
          e.TEXTURE_2D,
          e.TEXTURE_MIN_FILTER,
          glconstant.texFilter[r]
        );
      var a = this._param.magFilterType;
      a &&
        e.texParameteri(
          e.TEXTURE_2D,
          e.TEXTURE_MAG_FILTER,
          glconstant.texFilter[a]
        );
      var i = this._param.wrapSType;
      i &&
        e.texParameteri(
          e.TEXTURE_2D,
          e.TEXTURE_WRAP_S,
          glconstant.texWrapType[i]
        );
      var n = this._param.wrapTType;
      n &&
        e.texParameteri(
          e.TEXTURE_2D,
          e.TEXTURE_WRAP_T,
          glconstant.texWrapType[n]
        );
      var s = this._param.data,
        r = glconstant.glFormat[this._param.format],
        a = glconstant.glType[this._param.type],
        i = this._param.width,
        n = this._param.height;
      return (
        s
          ? this._isArrayBufferData(this._param.data)
            ? e.texImage2D(e.TEXTURE_2D, 0, r, i, n, 0, r, a, s)
            : this._isBrowserData(this._param.data) &&
              e.texImage2D(e.TEXTURE_2D, 0, r, r, a, s)
          : e.texImage2D(e.TEXTURE_2D, 0, r, i, n, 0, r, a, null),
        this._param.isGenerateMipmap && e.generateMipmap(e.TEXTURE_2D),
        e.bindTexture(e.TEXTURE_2D, null),
        t
      );
    }
  }
}
const BINARY_EXTENSION_HEADER_MAGIC = "glTF",
  BINARY_EXTENSION_HEADER_LENGTH = 12,
  BINARY_EXTENSION_CHUNK_TYPES = { JSON: 1313821514, BIN: 5130562 },
  EXTENSIONS = {
    KHR_BINARY_GLTF: "KHR_binary_glTF",
    KHR_DRACO_MESH_COMPRESSION: "KHR_draco_mesh_compression",
    KHR_LIGHTS_PUNCTUAL: "KHR_lights_punctual",
    KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS:
      "KHR_materials_pbrSpecularGlossiness",
    KHR_MATERIALS_UNLIT: "KHR_materials_unlit",
    KHR_TEXTURE_TRANSFORM: "KHR_texture_transform",
    MSFT_TEXTURE_DDS: "MSFT_texture_dds",
  };
let ATTRIBUTES = {
    POSITION: "position",
    NORMAL: "normal",
    TANGENT: "tangent",
    TEXCOORD_0: "texcoord_0",
    TEXCOORD_1: "texcoord_1",
    COLOR_0: "color",
    WEIGHTS_0: "skinWeight",
    JOINTS_0: "skinIndex",
  },
  WEBGL_COMPONENT_TYPES = {
    5120: Int8Array,
    5121: Uint8Array,
    5122: Int16Array,
    5123: Uint16Array,
    5125: Uint32Array,
    5126: Float32Array,
  },
  WEBGL_TYPE_SIZES = {
    SCALAR: 1,
    VEC2: 2,
    VEC3: 3,
    VEC4: 4,
    MAT2: 4,
    MAT3: 9,
    MAT4: 16,
  },
  WEBGL_CONSTANTS = {
    FLOAT: 5126,
    FLOAT_MAT3: 35675,
    FLOAT_MAT4: 35676,
    FLOAT_VEC2: 35664,
    FLOAT_VEC3: 35665,
    FLOAT_VEC4: 35666,
    LINEAR: 9729,
    REPEAT: 10497,
    SAMPLER_2D: 35678,
    POINTS: 0,
    LINES: 1,
    LINE_LOOP: 2,
    LINE_STRIP: 3,
    TRIANGLES: 4,
    TRIANGLE_STRIP: 5,
    TRIANGLE_FAN: 6,
    UNSIGNED_BYTE: 5121,
    UNSIGNED_SHORT: 5123,
  };
class GLTFLoader extends LoadingManager {
  static get singleton() {
    return (
      void 0 === GLTFLoader._singleton &&
        (GLTFLoader._singleton = new GLTFLoader()),
      GLTFLoader._singleton
    );
  }
  constructor() {
    super();
  }
  _cache = new MapCache();
  static _singleton;
  resourcePath;
  load(t, r, e, a) {
    let i = fileloader;
    (this.resourcePath = LoaderUtils.extractUrlBase(t)),
      logger.debug("start load ...", this.resourcePath);
    let n = e => {
      a ? a(e) : logger.error(e), this.itemError(t), this.itemEnd(t);
    };
    i.load(t, e => {
      logger.debug(`load gltf[${t}] : ${e.ok} }`);
      try {
        e.arrayBuffer().then(e => {
          this.parse(
            e,
            e => {
              r(e);
            },
            n
          );
        });
      } catch (e) {
        logger.error("gltfloader exception :" + e);
      }
    });
  }
  parse(e, n, t) {
    let r,
      a = {};
    if ("string" == typeof e) r = e;
    else if (
      LoaderUtils.decodeText(new Uint8Array(e, 0, 4)) ===
      BINARY_EXTENSION_HEADER_MAGIC
    ) {
      logger.debug("it's glb file data stream");
      try {
        a[EXTENSIONS.KHR_BINARY_GLTF] = new GLTFBinaryExtension(e);
      } catch (e) {
        return void (t && t(e));
      }
      r = a[EXTENSIONS.KHR_BINARY_GLTF].content;
    } else
      logger.debug("it's gltf file data stream"),
        (r = LoaderUtils.decodeText(new Uint8Array(e)));
    e = JSON.parse(r);
    if (void 0 === e.asset || e.asset.version[0] < 2)
      return (
        logger.error("gltf Unsupported asset"),
        void (
          t &&
          t(
            " Unsupported asset. glTF versions >=2.0 are supported. Use LegacyGLTFLoader instead."
          )
        )
      );
    e.extensionsUsed && logger.debug("need extensions!!!");
    var s = new GLTFParser(e, a, { path: this.resourcePath });
    s.parse((e, t, r, a, i) => {
      i = {
        scene: e,
        scenes: t,
        cameras: r,
        animations: a,
        asset: i.asset,
        parser: s,
        userData: {},
      };
      logger.debug("[GTLTF >>>]load All ok .......:", i), n(i);
    }, t);
  }
}
class GLTFBinaryExtension {
  header;
  name = EXTENSIONS.KHR_BINARY_GLTF;
  content = null;
  body = null;
  constructor(e) {
    var t = new DataView(e, 0, BINARY_EXTENSION_HEADER_LENGTH);
    if (
      ((this.header = {
        magic: LoaderUtils.decodeText(new Uint8Array(e.slice(0, 4))),
        version: t.getUint32(4, !0),
        length: t.getUint32(8, !0),
      }),
      this.header.magic !== BINARY_EXTENSION_HEADER_MAGIC)
    )
      throw new Error(" Unsupported glTF-Binary header.");
    if (this.header.version < 2)
      throw new Error(
        " Legacy binary file detected. Use LegacyGLTFLoader instead."
      );
    for (
      var r = new DataView(e, BINARY_EXTENSION_HEADER_LENGTH), a = 0;
      a < r.byteLength;

    ) {
      var i = r.getUint32(a, !0);
      a += 4;
      var n,
        s = r.getUint32(a, !0);
      (a += 4),
        s === BINARY_EXTENSION_CHUNK_TYPES.JSON
          ? ((n = new Uint8Array(e, BINARY_EXTENSION_HEADER_LENGTH + a, i)),
            (this.content = LoaderUtils.decodeText(n)))
          : s === BINARY_EXTENSION_CHUNK_TYPES.BIN &&
            ((s = BINARY_EXTENSION_HEADER_LENGTH + a),
            (this.body = e.slice(s, s + i))),
        (a += i);
    }
    if (null === this.content) throw new Error(" JSON content not found.");
  }
}
class GLTFParser {
  json;
  extensions;
  options;
  cache;
  fileLoader = fileloader;
  primitiveCache = {};
  constructor(e, t, r) {
    (this.json = e || {}),
      logger.debug("GLTF_json", e),
      (this.extensions = t || {}),
      (this.options = r),
      (this.cache = new MapCache());
  }
  parse(i, e) {
    var n = this.json;
    this.markDefs(),
      this.getMultiDependencies(["scene", "animation", "camera"])
        .then(e => {
          var t = e.scenes || [],
            r = t[n.scene || 0],
            a = e.animations || [],
            e = e.cameras || [];
          i(r, t, e, a, n);
        })
        .catch(e);
  }
  markDefs() {
    let r = this.json.nodes || [];
    var a = this.json.skins || [];
    let i = this.json.meshes || [],
      n = {},
      s = {};
    for (let e = 0, t = a.length; e < t; e++) {
      var o = a[e].joints;
      for (let e = 0, t = o.length; e < t; e++) r[o[e]].isBone = !0;
    }
    for (let e = 0, t = r.length; e < t; e++) {
      var l = r[e];
      void 0 !== l.mesh &&
        (void 0 === n[l.mesh] && (n[l.mesh] = s[l.mesh] = 0),
        n[l.mesh]++,
        void 0 !== l.skin && (i[l.mesh].isSkinnedMesh = !0));
    }
    (this.json.meshReferences = n), (this.json.meshUses = s);
  }
  getMultiDependencies(a) {
    let i = {},
      n = [];
    logger.debug("getdependencis:", a);
    for (let t = 0, e = a.length; t < e; t++) {
      let r = a[t],
        e = this.getDependencies(r);
      var s = e.then(e => {
        var t = r + ("mesh" === r ? "es" : "s");
        logger.debug("getDependencies...." + t + " | " + e), (i[t] = e);
      });
      n.push(s);
    }
    return Promise.all(n).then(
      () => (
        logger.debug(
          " getMultiDependencies ... ok :" + Object.getOwnPropertyNames(i)
        ),
        i
      )
    );
  }
  getDependencies(r) {
    let t = this.cache.get(r);
    if (!t) {
      let e = this.json[r + ("mesh" === r ? "es" : "s")] || [];
      (t = Promise.all(e.map((e, t) => this.getDependency(r, t)))),
        this.cache.add(r, t);
    }
    return t;
  }
  getDependency(e, t) {
    var r = e + ":" + t;
    let a = this.cache.get(r);
    if (!a) {
      switch (e) {
        case "scene":
          logger.debug("load scene...."), (a = this.loadScene(t));
          break;
        case "node":
          logger.debug("load node...."), (a = this.loadNode(t));
          break;
        case "mesh":
          logger.debug("load mesh...."), (a = this.loadMesh(t));
          break;
        case "accessor":
          logger.debug("load accessor...."), (a = this.loadAccessor(t));
          break;
        case "bufferView":
          logger.debug("load bufferView...."), (a = this.loadBufferView(t));
          break;
        case "buffer":
          logger.debug("load buffer...."), (a = this.loadBuffer(t));
          break;
        case "material":
          a = this.loadMaterial(t);
          break;
        case "camera":
          a = this.loadCamera(t);
          break;
        default:
          throw new Error("Unknown type: " + e);
      }
      this.cache.add(r, a);
    }
    return a;
  }
  resolveURL(e, t) {
    return "string" != typeof e || "" === e
      ? ""
      : /^(https?:)?\/\//i.test(e) ||
        /^data:.*,.*$/i.test(e) ||
        /^blob:.*$/i.test(e)
      ? e
      : t + e;
  }
  loadBuffer(e) {
    var t = this.json.buffers[e],
      a = this.fileLoader;
    if (t.type && "arraybuffer" !== t.type)
      throw new Error(" " + t.type + " buffer type is not supported.");
    if (void 0 === t.uri && 0 === e)
      return Promise.resolve(this.extensions[EXTENSIONS.KHR_BINARY_GLTF].body);
    var i = this.options;
    return new Promise((r, e) => {
      a.load(
        this.resolveURL(t.uri, i.path),
        e => {
          logger.debug("loadbuffer done ");
          let t = e.arrayBuffer();
          t.then(e => {
            logger.debug("loadbuffer ok"), r(e);
          }).catch(e => {
            logger.debug("loadbuffer false:" + e);
          });
        },
        void 0,
        () => {
          e(new Error(' Failed to load buffer "' + t.uri + '".'));
        }
      );
    });
  }
  async loadCamera(e) {
    logger.debug("loadCamera:", e);
    var t = this.json.cameras[e],
      e = t.perspective;
    const r = new Camera({
      fovy: e.yfov,
      near: e.znear,
      far: e.zfar,
      aspect: e.aspectRatio,
    });
    return (r.name = t.name), r;
  }
  async loadImage(e) {
    var t = this.json.images[e];
    const r = self.URL || self.webkitURL;
    let a = t.uri || "",
      i = !1;
    var n = t.mimeType;
    if (void 0 !== a.bufferView) {
      i = !0;
      var s = await this.getDependency("bufferView", t.bufferView),
        s = new Blob([s], { type: t.mimeType });
      a = r.createObjectURL(s);
    } else if (void 0 === t.uri)
      throw new Error(
        "GLTFLoader: loadImage " + e + " is missing URI and bufferView"
      );
    return { uri: a, isObjectURL: i, mimeType: n };
  }
  async loadTexture(e) {
    var { uri: t, isObjectURL: r } = await this.loadImage(e),
      e = this.options.path + t,
      t = await loadImageBitmap(e),
      t = new Texture({ data: t });
    return !0 === r && URL.revokeObjectURL(e), t;
  }
  async loadMaterial(e) {
    logger.debug("loadMaterial>>>", e);
    var t = this.json.materials[e];
    let r,
      a = Vector3.ZERO;
    t.pbrMetallicRoughness &&
      ((e = t.pbrMetallicRoughness).baseColorTexture &&
        (r = await this.loadTexture(e.baseColorTexture.index)),
      e.metallicRoughnessTexture &&
        (await this.loadTexture(e.metallicRoughnessTexture.index)),
      e.baseColorFactor && (a = Vector3.create(e.baseColorFactor)));
    let i;
    t.normalTexture && (i = await this.loadTexture(t.normalTexture.index)),
      t.occlusionTexture && (await this.loadTexture(t.occlusionTexture.index)),
      t.emissiveTexture && (await this.loadTexture(t.emissiveTexture.index)),
      logger.debug("material::", t, r);
    const n = new PhoneMaterial();
    return (
      r ? (n.diffuseMap = r) : (n.ambientColor = a), i && (n.normalMap = i), n
    );
  }
  async loadBufferView(e) {
    var a = this.json.bufferViews[e];
    return this.getDependency("buffer", a.buffer).then(e => {
      logger.debug("loadbufferview ok");
      var t = a.byteLength || 0,
        r = a.byteOffset || 0;
      return e.slice(r, r + t);
    });
  }
  loadNode(e) {
    var a = this.json.nodes[e],
      t = this.json.meshReferences,
      r = this.json.meshUses;
    return (async () => {
      if (!0 !== a.isBone) {
        if (void 0 !== a.mesh)
          return this.getDependency("mesh", a.mesh).then(e => {
            logger.debug("mesh load ok.....!");
            e = 1 < t[a.mesh] ? (r[a.mesh]++, e.clone()) : e;
            return Entity.createEntFromMesh(e);
          });
        if (void 0 !== a.camera)
          return await this.getDependency("camera", a.camera);
        a.extensions &&
          a.extensions[EXTENSIONS.KHR_LIGHTS_PUNCTUAL] &&
          a.extensions[EXTENSIONS.KHR_LIGHTS_PUNCTUAL].light;
      }
      return Promise.resolve(new SceneNode());
    })().then(e => {
      if (
        (logger.debug("Entity create ok .................", a, e),
        (e.name = a.name),
        void 0 !== a.matrix)
      ) {
        var t = Matrix4.fromArray(a.matrix);
        e.applyMatrix(t);
      } else {
        const r = {};
        void 0 !== a.translation &&
          (r.position = Vector3.create(a.translation)),
          void 0 !== a.rotation &&
            (r.quaternion = Vector4.fromArray(a.rotation)),
          void 0 !== a.scale && (r.scale = Vector3.create(a.scale)),
          e.setTransform(r);
      }
      return e;
    });
  }
  loadMesh(o) {
    let l = this.json.meshes[o],
      u = l.primitives;
    return this.loadGeometries(u).then(async i => {
      logger.debug("geometries load ok ,geometries count:" + i.length);
      let n = [];
      for (let a = 0, e = i.length; a < e; a++) {
        var s = i[a];
        let e = u[a];
        e.mode = e.mode || WEBGL_CONSTANTS.TRIANGLES;
        let t, r;
        switch (
          ((r =
            void 0 !== e.material
              ? await this.loadMaterial(e.material)
              : new ColorMaterial()),
          logger.debug("create material::", r),
          e.mode)
        ) {
          case WEBGL_CONSTANTS.TRIANGLES:
            t = new Primitive(s, r, EDrawType.TRIANGLES);
            break;
          case WEBGL_CONSTANTS.TRIANGLE_STRIP:
            t = new Primitive(s, r, EDrawType.TRIANGLES_STRIP);
            break;
          case WEBGL_CONSTANTS.TRIANGLE_FAN:
            t = new Primitive(s, r, EDrawType.TRIANGLE_FAN);
            break;
          default:
            throw new Error(
              "GLTFLoader : primitive mode unsupported " + e.mode
            );
        }
        (t.name = l.name || "mesh_" + o), n.push(t);
      }
      let r = new Mesh();
      for (let e = 0, t = n.length; e < t; e++) r.addPrimitive(n[e]);
      return r;
    });
  }
  loadAccessor(e) {
    var u = this.json.accessors[e];
    if (void 0 === u.bufferView && void 0 === u.sparse)
      return Promise.resolve(null);
    e = [];
    return (
      void 0 !== u.bufferView
        ? e.push(this.getDependency("bufferView", u.bufferView))
        : e.push(null),
      void 0 !== u.sparse &&
        (e.push(this.getDependency("bufferView", u.sparse.indices.bufferView)),
        e.push(this.getDependency("bufferView", u.sparse.values.bufferView))),
      Promise.all(e).then(e => {
        logger.debug("load all buffer views ok....");
        var t = e[0],
          r = WEBGL_TYPE_SIZES[u.type];
        let a = WEBGL_COMPONENT_TYPES[u.componentType];
        var i = a.BYTES_PER_ELEMENT * r,
          n = u.byteOffset || 0,
          s =
            void 0 !== u.bufferView
              ? this.json.bufferViews[u.bufferView].byteStride
              : void 0,
          e = !0 === u.normalized;
        let o, l;
        return (
          (s && s !== i) ||
            ((o = null === t ? new a(u.count * r) : new a(t, n, u.count * r)),
            (l = new AttributeInfo("", {
              buffer: o,
              numComponents: r,
              sizeofStride: i,
              offsetofStride: 0,
              normalized: e,
            }))),
          u.sparse,
          l
        );
      })
    );
  }
  loadGeometries(e) {
    logger.debug("load geometries");
    let t = this.primitiveCache;
    var r = t =>
      this.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION]
        .decodePrimitive(t, this)
        .then(e => this.addPrimitiveAttributes(e, t));
    let a = [];
    for (
      var i = 0, n = (e = this.isMultiPassGeometry(e) ? [e[0]] : e).length;
      i < n;
      i++
    ) {
      var s = e[i],
        o = this.createPrimitiveKey(s),
        l = t[o];
      l
        ? a.push(l.promise)
        : ((l =
            s.extensions && s.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION]
              ? (logger.debug("create draco geometry"), r(s))
              : (logger.debug("create new geometry"),
                this.addPrimitiveAttributes(new Geometry(), s))),
          (t[o] = { primitive: s, promise: l }),
          a.push(l));
    }
    return Promise.all(a).then(
      e => (logger.debug("all geometry load ok.........."), e)
    );
  }
  addPrimitiveAttributes(r, a) {
    var e,
      t = a.attributes,
      i = [],
      n = (e, t) =>
        this.getDependency("accessor", e).then(e => {
          logger.debug("load accessor ok:" + t + " | " + e.buffer.length),
            (e.name = t),
            r.setVertexAttribute(t, e),
            a.indices
              ? "indices" === t &&
                ((r.drawCount = e.buffer.length / e.numComponents),
                (r.drawOffset = 0))
              : "position" === t &&
                ((r.drawCount = e.buffer.length / e.numComponents),
                (r.drawOffset = 0));
        });
    for (e in t) {
      var s = ATTRIBUTES[e];
      s &&
        (s in r.vertexInfo ||
          (logger.debug("setup attribute:" + e), i.push(n(t[e], s))));
    }
    return (
      void 0 !== a.indices &&
        (logger.debug("setup attribute indices"),
        i.push(n(a.indices, "indices"))),
      Promise.all(i).then(
        () => (logger.debug("geometry attribute load ok.........."), r)
      )
    );
  }
  isMultiPassGeometry(e) {
    if (e.length < 2) return !1;
    var t = e[0],
      r = t.targets || [];
    if (void 0 === t.indices) return !1;
    for (var a = 1, i = e.length; a < i; a++) {
      var n = e[a];
      if (t.mode !== n.mode) return !1;
      if (void 0 === n.indices) return !1;
      if (n.extensions && n.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION])
        return !1;
      if (!this.isObjectEqual(t.attributes, n.attributes)) return !1;
      var s = n.targets || [];
      if (r.length !== s.length) return !1;
      for (var o = 0, l = r.length; o < l; o++)
        if (!this.isObjectEqual(r[o], s[o])) return !1;
    }
    return !0;
  }
  isObjectEqual(e, t) {
    if (Object.keys(e).length !== Object.keys(t).length) return !1;
    for (var r in e) if (e[r] !== t[r]) return !1;
    return !0;
  }
  createPrimitiveKey(e) {
    var t = e.extensions && e.extensions[EXTENSIONS.KHR_DRACO_MESH_COMPRESSION],
      e = t
        ? "draco:" +
          t.bufferView +
          ":" +
          t.indices +
          ":" +
          this.createAttributesKey(t.attributes)
        : e.indices +
          ":" +
          this.createAttributesKey(e.attributes) +
          ":" +
          e.mode;
    return e;
  }
  createAttributesKey(e) {
    for (var t = "", r = Object.keys(e).sort(), a = 0, i = r.length; a < i; a++)
      t += r[a] + ":" + e[r[a]] + ";";
    return t;
  }
  loadScene(e) {
    let h = (s, o, l, e) => {
      var u = l.nodes[s];
      return this.getDependency("node", s).then(e => {
        logger.debug("node load ok..............:" + s), o.addNode(e);
        var t = [];
        if (u.children)
          for (var r = u.children, a = 0, i = r.length; a < i; a++) {
            var n = r[a];
            t.push(h(n, e, l));
          }
        return Promise.all(t);
      });
    };
    var t = this.json;
    this.extensions;
    var e = this.json.scenes[e],
      r = new Scene();
    void 0 !== e.name && (r.name = e.name);
    for (var a = e.nodes || [], i = [], n = 0, s = a.length; n < s; n++)
      i.push(h(a[n], r, t));
    return Promise.all(i).then(function () {
      return r;
    });
  }
}
const RADPDEG = Math.PI / 180,
  DEGPRAD = 180 / Math.PI;
class MathUtils {
  static degToRad(e) {
    return e * RADPDEG;
  }
  static radToDeg(e) {
    return e * DEGPRAD;
  }
}
!(function (e) {
  (e[(e.NONE = 0)] = "NONE"),
    (e[(e.LEFT_BUTTON = 1)] = "LEFT_BUTTON"),
    (e[(e.RIGHT_BUTTON = 2)] = "RIGHT_BUTTON"),
    (e[(e.MIDDLE_BUTTON = 4)] = "MIDDLE_BUTTON");
})((ButtonsType = ButtonsType || {}));
class OrbitCameraController {
  previousPosition;
  camera;
  view;
  wheelTimeoutID = 0;
  setup(e) {
    (this.camera = e.getCamera()),
      (this.view = e).eventMouseMove.onFire(this.onMouseMove.bind(this)),
      e.eventMouseDown.onFire(e => {
        1 == (e.buttons & ButtonsType.LEFT_BUTTON) && this.onMouseLeftPress(e);
      }),
      e.eventMouseUp.onFire(e => {
        0 == (e.buttons & ButtonsType.LEFT_BUTTON) &&
          this.onMouseLeftRelease(e);
      }),
      e.eventMouseWheel.onFire(e => {
        0 === this.wheelTimeoutID
          ? this.view.requestAnimation("zoom")
          : (this.onMouseWheel(e), clearTimeout(this.wheelTimeoutID)),
          (this.wheelTimeoutID = setTimeout(() => {
            this.view.cancelAnimation("zoom"), (this.wheelTimeoutID = 0);
          }, 200));
      });
  }
  _axis2 = Vector3.create();
  _onButtonPressed = !1;
  zoomSpeed = 0.1;
  onMouseWheel(e) {
    const t = this.camera;
    var r = t.lookAt,
      a = Vector3.sub(r, t.position),
      i = Vector3.size(a);
    Vector3.normalize(a, a);
    var n = 0 < e.deltaY ? this.zoomSpeed + 1 : 1 / (this.zoomSpeed + 1),
      e = i / n;
    logger.debug("distance....", i, n, e),
      (t.position = [r[0] - e * a[0], r[1] - e * a[1], r[2] - e * a[2]]);
  }
  onMouseLeftPress(e) {
    this.view.requestAnimation("camerarotate"), (this._onButtonPressed = !0);
  }
  onMouseLeftRelease(e) {
    this.view.cancelAnimation("camerarotate"),
      (this._onButtonPressed = !1),
      (this.previousPosition = void 0);
  }
  onMouseMove(e) {
    if (this._onButtonPressed) {
      const s = this.camera;
      var t = s.lookAt,
        r = Matrix4.identity();
      Matrix4.translate(r, t, r),
        this.previousPosition ||
          (this.previousPosition = [e.clientX, e.clientY]);
      var a = this.previousPosition[0] - e.clientX,
        i = this.previousPosition[1] - e.clientY,
        n = [this.view.viewRect.width, this.view.viewRect.height];
      this.previousPosition = [e.clientX, e.clientY];
      e = s.upVector;
      Matrix4.axisRotate(r, e, MathUtils.degToRad(((360 * a) / n[0]) * 0.5), r);
      a = s.getLookVector();
      Vector3.cross(a, e, this._axis2),
        Matrix4.axisRotate(
          r,
          this._axis2,
          MathUtils.degToRad(((-360 * i) / n[1]) * 0.5),
          r
        ),
        Matrix4.translate(r, Vector3.negate(t, [0, 0, 0]), r),
        Vector3.add(e, s.position, a),
        Vector3.transformMat4(a, r, a);
      i = s.position;
      Vector3.transformMat4(i, r, i);
      n = s.lookAt;
      Vector3.transformMat4(t, r, n),
        Vector3.sub(a, i, e),
        s.root && (s.root.isModified = !0);
    }
  }
}
!(function (e) {
  (e[(e.LIGHTTYPE_DIRECTIONAL = 0)] = "LIGHTTYPE_DIRECTIONAL"),
    (e[(e.LIGHTTYPE_POINT = 1)] = "LIGHTTYPE_POINT"),
    (e[(e.LIGHTTYPE_SPOT = 2)] = "LIGHTTYPE_SPOT");
})((LightType = LightType || {}));
class Light extends SceneNode {
  constructor(e) {
    super(e), (this._className = "Light");
  }
  color = Vector3.create(1, 1, 1);
  intensity = 1;
}
class DirectionLight extends Light {
  lightType = LightType.LIGHTTYPE_DIRECTIONAL;
  constructor(e) {
    super(e), (this._className = "DirectionLight");
  }
  _direction = Vector3.create(0, 0, 1);
  set direction(e) {
    this._direction = e;
  }
  get direction() {
    return this._direction;
  }
}
class Event {
  events = new Set();
  onFire(e) {
    this.events.has(e) || this.events.add(e);
  }
  onFireOnce(t) {
    const r = async e => {
      await t(e), this.off(r);
    };
    this.onFire(r);
  }
  fire(t) {
    this.events.forEach(e => {
      e(t);
    });
  }
  off(e) {
    this.events.has(e) && this.events.delete(e);
  }
  hasEvent(e) {
    return this.events.has(e);
  }
}
let defaultOptions = { camera: null };
class Viewer {
  name;
  renderer;
  _container;
  _canvas;
  _viewports = [];
  _gl;
  _viewRect;
  _calldraw = 0;
  constructor(e) {
    let t = {};
    Object.assign(t, defaultOptions, e),
      (this.name = "Viewer" + Viewer.gViewerCount++),
      t.container &&
        ((t.width = t.width || t.container.clientWidth),
        (t.height = t.height || t.container.clientHeight)),
      (this._canvas = t.canvas),
      (this.renderer = t.renderer),
      this.bindContainer(t.container),
      (this._viewRect = this._container.getBoundingClientRect()),
      logger.log("[create view Rect::]", this._viewRect);
    const r = new Scene();
    e = new Camera({ fovy: 1, aspect: t.width / t.height, near: 1, far: 1e4 });
    r.addNode(e),
      this._viewports.push({
        scene: r,
        backgroundColor: Vector4.ZERO,
        viewportElem: t.container,
        camera: t.camera || e,
      }),
      this._viewports[0].scene.addNode(this._viewports[0].camera),
      this._bindSceneChangeToRender(),
      this._registerAllEvents(),
      (this._gl = this.renderer.getGLContext()),
      this.eventBeforeRender.onFire(() => {
        this._renderTarget && this._renderTarget.beginRenderToTarget(this._gl);
      }),
      this.eventAfterRender.onFire(() => {
        this._renderTarget && this._renderTarget.endRenderToTarget(this._gl);
      }),
      this.renderer.setState(EEnabledCapability.CULL_FACE, !0),
      this.renderer.setState(EEnabledCapability.DEPTH_TEST, !0),
      this.renderer.setState(EEnabledCapability.DITHER, !0);
  }
  clear() {
    this.renderer.extLoseContext?.loseContext();
  }
  clearCanvas() {
    this.renderer.viewport([
      0,
      0,
      this._canvas.clientWidth,
      this._canvas.clientHeight,
    ]),
      this.renderer.clear();
  }
  get viewRect() {
    return this._viewRect;
  }
  get viewports() {
    return this._viewports;
  }
  getViewport(e = 0) {
    return this._viewports[e];
  }
  getScene(e = 0) {
    return this._viewports[e].scene;
  }
  getCamera(e = 0) {
    return this._viewports[e].camera;
  }
  setCamera(e, t = 0) {
    this._viewports[t].camera = e;
  }
  getContainer() {
    return this._container;
  }
  getCanvas() {
    return this.renderer.canvas;
  }
  setViewports(e) {
    (this._viewports = e),
      this._bindSceneChangeToRender(),
      1 < e.length
        ? this.renderer.setState(EEnabledCapability.SCISSOR_TEST, !0)
        : this.renderer.setState(EEnabledCapability.SCISSOR_TEST, !1);
  }
  resize() {
    logger.debug("resize....."),
      (this._canvas.width = this._container.clientWidth),
      (this._canvas.height = this._container.clientHeight);
    for (let e = 0, t = this._viewports.length; e < t; e++) {
      const a = this._viewports[e],
        i = a.camera;
      var r = a.viewportElem.getBoundingClientRect(),
        r = r.width / r.height;
      i.updateCamera({ aspect: r }), this.render();
    }
  }
  animationRequestorSet = new Set();
  animationRequest = null;
  _doRenderLoop = () => {
    this.render(),
      (this.animationRequest = requestAnimationFrame(this._doRenderLoop));
  };
  requestAnimation(e) {
    e &&
      (this.animationRequestorSet.has(e) ||
        (0 === this.animationRequestorSet.size &&
          (this.animationRequest = requestAnimationFrame(this._doRenderLoop)),
        this.animationRequestorSet.add(e)));
  }
  cancelAnimation(e) {
    e &&
      this.animationRequestorSet.has(e) &&
      (this.animationRequestorSet.delete(e),
      0 === this.animationRequestorSet.size &&
        this.animationRequest &&
        (cancelAnimationFrame(this.animationRequest),
        (this.animationRequest = null)));
  }
  static gViewerCount = 0;
  _renderTarget = void 0;
  eventBeforeRender = new Event();
  eventAfterRender = new Event();
  eventMouseMove = new Event();
  eventMouseUp = new Event();
  eventMouseDown = new Event();
  eventMouseWheel = new Event();
  _eventMouseMoveHandleRef;
  _eventMouseDownHandleRef;
  _eventMouseUpHandleRef;
  _eventMouseWheelHandleRef;
  _eventResizeHandleRef;
  get renderTarget() {
    return this._renderTarget;
  }
  set renderTarget(e) {
    this._renderTarget = e;
  }
  setRenderTarget(e) {
    this.renderTarget = e;
  }
  bindContainer(e) {
    this._container && this._removeAllEvents(), (this._container = e);
  }
  _mouseupHandler(e) {
    this.eventMouseUp.fire(e);
  }
  _mousedownHandler(e) {
    this.eventMouseDown.fire(e);
  }
  _mousemoveHandler(e) {
    this.eventMouseMove.fire(e);
  }
  _mousewheelHandler(e) {
    this.eventMouseWheel.fire(e);
  }
  _registerAllEvents() {
    (this._eventMouseMoveHandleRef = this._mousemoveHandler.bind(this)),
      (this._eventMouseUpHandleRef = this._mouseupHandler.bind(this)),
      (this._eventMouseDownHandleRef = this._mousedownHandler.bind(this)),
      (this._eventMouseWheelHandleRef = this._mousewheelHandler.bind(this)),
      (this._eventResizeHandleRef = this.resize.bind(this)),
      this._container.addEventListener("mouseup", this._eventMouseUpHandleRef),
      this._container.addEventListener(
        "mousedown",
        this._eventMouseDownHandleRef
      ),
      this._container.addEventListener(
        "mousemove",
        this._eventMouseMoveHandleRef
      ),
      this._container.addEventListener("wheel", this._eventMouseWheelHandleRef),
      window.addEventListener("resize", this._eventResizeHandleRef);
  }
  _removeAllEvents() {
    this._container.removeEventListener("mouseup", this._eventMouseUpHandleRef),
      this._container.removeEventListener(
        "mousedown",
        this._eventMouseDownHandleRef
      ),
      this._container.removeEventListener(
        "mousemove",
        this._eventMouseMoveHandleRef
      ),
      this._container.removeEventListener(
        "wheel",
        this._eventMouseWheelHandleRef
      );
  }
  _bindSceneChangeToRender() {
    logger.debug("[bindscenelistener]", this._viewports.length);
    for (let e = 0, t = this._viewports.length; e < t; e++) {
      const r = this._viewports[e];
      r.scene.addOnChangeListener(e => {
        this._updateSceneToRenderer(e);
      }),
        this._updateSceneToRenderer(r.scene);
    }
  }
  _updateSceneToRenderer(e) {
    e.renderGroups.clearRenderGroup(),
      this._updateNodeToRenderer(e.renderGroups, e);
  }
  _updateNodeToRenderer(r, e) {
    logger.debug("update node to renderer", e.children),
      e.children.forEach(e => {
        if ("Entity" === e.className) {
          let t = e;
          logger.debug("add ent...", t),
            t.drawableObjs.forEach(e => {
              logger.debug("add drawableObj...", e),
                r.addDrawAbleObj(e, t.renderType);
            });
        }
        e.children && 0 < e.children.length && this._updateNodeToRenderer(r, e);
      });
  }
  calFps(e) {
    var t = performance.now();
    e && e();
    (e = performance.now() - t), (t = 1e3 / e);
    logger.debug(`'fps:${t} a frame took ${e}  milliseconds to execute. `);
  }
  render() {
    (this._calldraw = 0),
      (this._viewRect = this._container.getBoundingClientRect());
    for (let e = 0, t = this._viewports.length; e < t; e++) {
      var r = this._viewports[e];
      this.renderAViewport(r);
    }
    logger.debug("[calldraw:]", this._calldraw);
  }
  renderAViewport(e) {
    var t = e.viewportElem.getBoundingClientRect(),
      r = t.width,
      a = t.height,
      i = t.left - this._viewRect.left,
      n = this._viewRect.bottom - t.bottom,
      t = areRectanglesIntersecting(
        { bottomLeft: [t.left, t.bottom], topRight: [t.right, t.top] },
        {
          bottomLeft: [this._viewRect.left, this._viewRect.bottom],
          topRight: [this._viewRect.right, this._viewRect.top],
        },
        !0
      );
    if ((logger.debug("isNeedRender::", t), t)) {
      const s = e.scene;
      e = e.camera;
      logger.debug("viewport::", [i, n, r, a]),
        this.renderer.viewport([i, n, r, a]),
        this.renderer.clear(),
        this.eventBeforeRender.fire(),
        s.update(),
        (this._gl.canvas.style.transform = `translateY(${this._container.scrollTop}px)`),
        e && s.updatePositionTransform(e);
      const o = s.getLights();
      Helper.calculateTimestamp(() => {
        this.renderAframe(s.renderGroups, { lights: o });
      }, "rendercost::"),
        this.eventAfterRender.fire(),
        (s.isModified = !1);
    }
  }
  renderAframe(e, t) {
    let r = 0;
    const a = e.renderGroupList;
    for (const i in a)
      if (a.hasOwnProperty(i)) {
        const n = a[i];
        n.renderListArray.forEach(e => {
          this.drawObjects(e, t), (r += e.renderObjs.length);
        });
      }
  }
  drawObjects(e, t) {
    Helper.calculateTimestamp(() => {
      e.renderObjs.forEach(e => {
        (this._calldraw += 1), this.renderer.drawObject(e, t);
      });
    }, "2");
  }
}
var Stats = function () {
  function e(e) {
    return a.appendChild(e.dom), e;
  }
  function t(e) {
    for (var t = 0; t < a.children.length; t++)
      a.children[t].style.display = t === e ? "block" : "none";
    r = e;
  }
  var r = 0,
    a = document.createElement("div");
  (a.style.cssText =
    "position:fixed;top:0;left:0;cursor:pointer;opacity:0.9;z-index:10000"),
    a.addEventListener(
      "click",
      function (e) {
        e.preventDefault(), t(++r % a.children.length);
      },
      !1
    );
  var i,
    n = (performance || Date).now(),
    s = n,
    o = 0,
    l = e(new Stats.Panel("FPS", "#0ff", "#002")),
    u = e(new Stats.Panel("MS", "#0f0", "#020"));
  return (
    self.performance &&
      self.performance.memory &&
      (i = e(new Stats.Panel("MB", "#f08", "#201"))),
    t(0),
    {
      REVISION: 16,
      dom: a,
      addPanel: e,
      showPanel: t,
      begin: function () {
        n = (performance || Date).now();
      },
      end: function () {
        o++;
        var e,
          t = (performance || Date).now();
        return (
          u.update(t - n, 200),
          s + 1e3 < t &&
            (l.update((1e3 * o) / (t - s), 100), (s = t), (o = 0), i) &&
            ((e = performance.memory),
            i.update(e.usedJSHeapSize / 1048576, e.jsHeapSizeLimit / 1048576)),
          t
        );
      },
      update: function () {
        n = this.end();
      },
      domElement: a,
      setMode: t,
    }
  );
};
Stats.Panel = function (r, a, i) {
  var n = 1 / 0,
    s = 0,
    o = Math.round,
    l = o(window.devicePixelRatio || 1),
    u = 80 * l,
    e = 48 * l,
    h = 3 * l,
    d = 2 * l,
    c = 3 * l,
    f = 15 * l,
    _ = 74 * l,
    p = 30 * l,
    E = document.createElement("canvas");
  (E.width = u), (E.height = e), (E.style.cssText = "width:80px;height:48px");
  var g = E.getContext("2d");
  return (
    (g.font = "bold " + 9 * l + "px Helvetica,Arial,sans-serif"),
    (g.textBaseline = "top"),
    (g.fillStyle = i),
    g.fillRect(0, 0, u, e),
    (g.fillStyle = a),
    g.fillText(r, h, d),
    g.fillRect(c, f, _, p),
    (g.fillStyle = i),
    (g.globalAlpha = 0.9),
    g.fillRect(c, f, _, p),
    {
      dom: E,
      update: (e, t) => {
        (n = Math.min(n, e)),
          (s = Math.max(s, e)),
          (g.fillStyle = i),
          (g.globalAlpha = 1),
          g.fillRect(0, 0, u, f),
          (g.fillStyle = a),
          g.fillText(o(e) + " " + r + " (" + o(n) + "-" + o(s) + ")", h, d),
          g.drawImage(E, c + l, f, _ - l, p, c, f, _ - l, p),
          g.fillRect(c + _ - l, f, l, p),
          (g.fillStyle = i),
          (g.globalAlpha = 0.9),
          g.fillRect(c + _ - l, f, l, o((1 - e / t) * p));
      },
    }
  );
};
var Easing = Object.freeze({
    Linear: Object.freeze({
      None: function (e) {
        return e;
      },
      In: function (e) {
        return this.None(e);
      },
      Out: function (e) {
        return this.None(e);
      },
      InOut: function (e) {
        return this.None(e);
      },
    }),
    Quadratic: Object.freeze({
      In: function (e) {
        return e * e;
      },
      Out: function (e) {
        return e * (2 - e);
      },
      InOut: function (e) {
        return (e *= 2) < 1 ? 0.5 * e * e : -0.5 * (--e * (e - 2) - 1);
      },
    }),
    Cubic: Object.freeze({
      In: function (e) {
        return e * e * e;
      },
      Out: function (e) {
        return --e * e * e + 1;
      },
      InOut: function (e) {
        return (e *= 2) < 1 ? 0.5 * e * e * e : 0.5 * ((e -= 2) * e * e + 2);
      },
    }),
    Quartic: Object.freeze({
      In: function (e) {
        return e * e * e * e;
      },
      Out: function (e) {
        return 1 - --e * e * e * e;
      },
      InOut: function (e) {
        return (e *= 2) < 1
          ? 0.5 * e * e * e * e
          : -0.5 * ((e -= 2) * e * e * e - 2);
      },
    }),
    Quintic: Object.freeze({
      In: function (e) {
        return e * e * e * e * e;
      },
      Out: function (e) {
        return --e * e * e * e * e + 1;
      },
      InOut: function (e) {
        return (e *= 2) < 1
          ? 0.5 * e * e * e * e * e
          : 0.5 * ((e -= 2) * e * e * e * e + 2);
      },
    }),
    Sinusoidal: Object.freeze({
      In: function (e) {
        return 1 - Math.sin(((1 - e) * Math.PI) / 2);
      },
      Out: function (e) {
        return Math.sin((e * Math.PI) / 2);
      },
      InOut: function (e) {
        return 0.5 * (1 - Math.sin(Math.PI * (0.5 - e)));
      },
    }),
    Exponential: Object.freeze({
      In: function (e) {
        return 0 === e ? 0 : Math.pow(1024, e - 1);
      },
      Out: function (e) {
        return 1 === e ? 1 : 1 - Math.pow(2, -10 * e);
      },
      InOut: function (e) {
        return 0 === e
          ? 0
          : 1 === e
          ? 1
          : (e *= 2) < 1
          ? 0.5 * Math.pow(1024, e - 1)
          : 0.5 * (2 - Math.pow(2, -10 * (e - 1)));
      },
    }),
    Circular: Object.freeze({
      In: function (e) {
        return 1 - Math.sqrt(1 - e * e);
      },
      Out: function (e) {
        return Math.sqrt(1 - --e * e);
      },
      InOut: function (e) {
        return (e *= 2) < 1
          ? -0.5 * (Math.sqrt(1 - e * e) - 1)
          : 0.5 * (Math.sqrt(1 - (e -= 2) * e) + 1);
      },
    }),
    Elastic: Object.freeze({
      In: function (e) {
        return 0 === e
          ? 0
          : 1 === e
          ? 1
          : -Math.pow(2, 10 * (e - 1)) * Math.sin(5 * (e - 1.1) * Math.PI);
      },
      Out: function (e) {
        return 0 === e
          ? 0
          : 1 === e
          ? 1
          : Math.pow(2, -10 * e) * Math.sin(5 * (e - 0.1) * Math.PI) + 1;
      },
      InOut: function (e) {
        return 0 === e
          ? 0
          : 1 === e
          ? 1
          : (e *= 2) < 1
          ? -0.5 * Math.pow(2, 10 * (e - 1)) * Math.sin(5 * (e - 1.1) * Math.PI)
          : 0.5 *
              Math.pow(2, -10 * (e - 1)) *
              Math.sin(5 * (e - 1.1) * Math.PI) +
            1;
      },
    }),
    Back: Object.freeze({
      In: function (e) {
        return 1 === e ? 1 : e * e * (2.70158 * e - 1.70158);
      },
      Out: function (e) {
        return 0 === e ? 0 : --e * e * (2.70158 * e + 1.70158) + 1;
      },
      InOut: function (e) {
        var t = 2.5949095;
        return (e *= 2) < 1
          ? e * e * ((1 + t) * e - t) * 0.5
          : 0.5 * ((e -= 2) * e * ((1 + t) * e + t) + 2);
      },
    }),
    Bounce: Object.freeze({
      In: function (e) {
        return 1 - Easing.Bounce.Out(1 - e);
      },
      Out: function (e) {
        return e < 1 / 2.75
          ? 7.5625 * e * e
          : e < 2 / 2.75
          ? 7.5625 * (e -= 1.5 / 2.75) * e + 0.75
          : e < 2.5 / 2.75
          ? 7.5625 * (e -= 2.25 / 2.75) * e + 0.9375
          : 7.5625 * (e -= 2.625 / 2.75) * e + 0.984375;
      },
      InOut: function (e) {
        return e < 0.5
          ? 0.5 * Easing.Bounce.In(2 * e)
          : 0.5 * Easing.Bounce.Out(2 * e - 1) + 0.5;
      },
    }),
    generatePow: function (t) {
      return (
        (t =
          1e4 <
          (t = (t = void 0 === t ? 4 : t) < Number.EPSILON ? Number.EPSILON : t)
            ? 1e4
            : t),
        {
          In: function (e) {
            return Math.pow(e, t);
          },
          Out: function (e) {
            return 1 - Math.pow(1 - e, t);
          },
          InOut: function (e) {
            return e < 0.5
              ? Math.pow(2 * e, t) / 2
              : (1 - Math.pow(2 - 2 * e, t)) / 2 + 0.5;
          },
        }
      );
    },
  }),
  now = function () {
    return performance.now();
  },
  Group = (function () {
    function e() {
      (this._tweens = {}), (this._tweensAddedDuringUpdate = {});
    }
    return (
      (e.prototype.getAll = function () {
        var t = this;
        return Object.keys(this._tweens).map(function (e) {
          return t._tweens[e];
        });
      }),
      (e.prototype.removeAll = function () {
        this._tweens = {};
      }),
      (e.prototype.add = function (e) {
        (this._tweens[e.getId()] = e),
          (this._tweensAddedDuringUpdate[e.getId()] = e);
      }),
      (e.prototype.remove = function (e) {
        delete this._tweens[e.getId()],
          delete this._tweensAddedDuringUpdate[e.getId()];
      }),
      (e.prototype.update = function (e, t) {
        void 0 === e && (e = now()), void 0 === t && (t = !1);
        var r = Object.keys(this._tweens);
        if (0 === r.length) return !1;
        for (; 0 < r.length; ) {
          this._tweensAddedDuringUpdate = {};
          for (var a = 0; a < r.length; a++) {
            var i = this._tweens[r[a]];
            i && !1 === i.update(e, !t) && !t && delete this._tweens[r[a]];
          }
          r = Object.keys(this._tweensAddedDuringUpdate);
        }
        return !0;
      }),
      e
    );
  })(),
  Interpolation = {
    Linear: function (e, t) {
      var r = e.length - 1,
        a = r * t,
        i = Math.floor(a),
        n = Interpolation.Utils.Linear;
      return t < 0
        ? n(e[0], e[1], a)
        : 1 < t
        ? n(e[r], e[r - 1], r - a)
        : n(e[i], e[r < i + 1 ? r : i + 1], a - i);
    },
    Bezier: function (e, t) {
      for (
        var r = 0,
          a = e.length - 1,
          i = Math.pow,
          n = Interpolation.Utils.Bernstein,
          s = 0;
        s <= a;
        s++
      )
        r += i(1 - t, a - s) * i(t, s) * e[s] * n(a, s);
      return r;
    },
    CatmullRom: function (e, t) {
      var r = e.length - 1,
        a = r * t,
        i = Math.floor(a),
        n = Interpolation.Utils.CatmullRom;
      return e[0] === e[r]
        ? n(
            e[((i = t < 0 ? Math.floor((a = r * (1 + t))) : i) - 1 + r) % r],
            e[i],
            e[(i + 1) % r],
            e[(i + 2) % r],
            a - i
          )
        : t < 0
        ? e[0] - (n(e[0], e[0], e[1], e[1], -a) - e[0])
        : 1 < t
        ? e[r] - (n(e[r], e[r], e[r - 1], e[r - 1], a - r) - e[r])
        : n(
            e[i ? i - 1 : 0],
            e[i],
            e[r < i + 1 ? r : i + 1],
            e[r < i + 2 ? r : i + 2],
            a - i
          );
    },
    Utils: {
      Linear: function (e, t, r) {
        return (t - e) * r + e;
      },
      Bernstein: function (e, t) {
        var r = Interpolation.Utils.Factorial;
        return r(e) / r(t) / r(e - t);
      },
      Factorial: (function () {
        var a = [1];
        return function (e) {
          var t = 1;
          if (a[e]) return a[e];
          for (var r = e; 1 < r; r--) t *= r;
          return (a[e] = t);
        };
      })(),
      CatmullRom: function (e, t, r, a, i) {
        var n = 0.5 * (r - e),
          e = 0.5 * (a - t),
          a = i * i;
        return (
          (2 * t - 2 * r + n + e) * (i * a) +
          (-3 * t + 3 * r - 2 * n - e) * a +
          n * i +
          t
        );
      },
    },
  },
  Sequence = (function () {
    function e() {}
    return (
      (e.nextId = function () {
        return e._nextId++;
      }),
      (e._nextId = 0),
      e
    );
  })(),
  mainGroup = new Group(),
  Tween$1 = (function () {
    function e(e, t) {
      void 0 === t && (t = mainGroup),
        (this._object = e),
        (this._group = t),
        (this._isPaused = !1),
        (this._pauseStart = 0),
        (this._valuesStart = {}),
        (this._valuesEnd = {}),
        (this._valuesStartRepeat = {}),
        (this._duration = 1e3),
        (this._isDynamic = !1),
        (this._initialRepeat = 0),
        (this._repeat = 0),
        (this._yoyo = !1),
        (this._isPlaying = !1),
        (this._reversed = !1),
        (this._delayTime = 0),
        (this._startTime = 0),
        (this._easingFunction = Easing.Linear.None),
        (this._interpolationFunction = Interpolation.Linear),
        (this._chainedTweens = []),
        (this._onStartCallbackFired = !1),
        (this._onEveryStartCallbackFired = !1),
        (this._id = Sequence.nextId()),
        (this._isChainStopped = !1),
        (this._propertiesAreSetUp = !1),
        (this._goToEnd = !1);
    }
    return (
      (e.prototype.getId = function () {
        return this._id;
      }),
      (e.prototype.isPlaying = function () {
        return this._isPlaying;
      }),
      (e.prototype.isPaused = function () {
        return this._isPaused;
      }),
      (e.prototype.to = function (e, t) {
        if ((void 0 === t && (t = 1e3), this._isPlaying))
          throw new Error(
            "Can not call Tween.to() while Tween is already started or paused. Stop the Tween first."
          );
        return (
          (this._valuesEnd = e),
          (this._propertiesAreSetUp = !1),
          (this._duration = t),
          this
        );
      }),
      (e.prototype.duration = function (e) {
        return (this._duration = e = void 0 === e ? 1e3 : e), this;
      }),
      (e.prototype.dynamic = function (e) {
        return (this._isDynamic = e = void 0 === e ? !1 : e), this;
      }),
      (e.prototype.start = function (e, t) {
        if (
          (void 0 === e && (e = now()),
          void 0 === t && (t = !1),
          this._isPlaying)
        )
          return this;
        if (
          (this._group && this._group.add(this),
          (this._repeat = this._initialRepeat),
          this._reversed)
        )
          for (var r in ((this._reversed = !1), this._valuesStartRepeat))
            this._swapEndStartRepeatValues(r),
              (this._valuesStart[r] = this._valuesStartRepeat[r]);
        if (
          ((this._isPlaying = !0),
          (this._isPaused = !1),
          (this._onStartCallbackFired = !1),
          (this._onEveryStartCallbackFired = !1),
          (this._isChainStopped = !1),
          (this._startTime = e),
          (this._startTime += this._delayTime),
          !this._propertiesAreSetUp || t)
        ) {
          if (((this._propertiesAreSetUp = !0), !this._isDynamic)) {
            var a,
              i = {};
            for (a in this._valuesEnd) i[a] = this._valuesEnd[a];
            this._valuesEnd = i;
          }
          this._setupProperties(
            this._object,
            this._valuesStart,
            this._valuesEnd,
            this._valuesStartRepeat,
            t
          );
        }
        return this;
      }),
      (e.prototype.startFromCurrentValues = function (e) {
        return this.start(e, !0);
      }),
      (e.prototype._setupProperties = function (e, t, r, a, i) {
        for (var n in r) {
          var s = e[n],
            o = Array.isArray(s),
            l = o ? "array" : typeof s,
            u = !o && Array.isArray(r[n]);
          if ("undefined" != l && "function" != l) {
            if (u) {
              if (0 === (p = r[n]).length) continue;
              for (var h = [s], d = 0, c = p.length; d < c; d += 1) {
                var f = this._handleRelativeValue(s, p[d]);
                if (isNaN(f)) {
                  (u = !1),
                    console.warn("Found invalid interpolation list. Skipping.");
                  break;
                }
                h.push(f);
              }
              u && (r[n] = h);
            }
            if (("object" != l && !o) || !s || u)
              (void 0 !== t[n] && !i) || (t[n] = s),
                o || (t[n] *= 1),
                (a[n] = u ? r[n].slice().reverse() : t[n] || 0);
            else {
              t[n] = o ? [] : {};
              var _ = s;
              for (E in _) t[n][E] = _[E];
              a[n] = o ? [] : {};
              var p = r[n];
              if (!this._isDynamic) {
                var E,
                  g = {};
                for (E in p) g[E] = p[E];
                r[n] = p = g;
              }
              this._setupProperties(_, t[n], p, a[n], i);
            }
          }
        }
      }),
      (e.prototype.stop = function () {
        return (
          this._isChainStopped ||
            ((this._isChainStopped = !0), this.stopChainedTweens()),
          this._isPlaying &&
            (this._group && this._group.remove(this),
            (this._isPlaying = !1),
            (this._isPaused = !1),
            this._onStopCallback && this._onStopCallback(this._object)),
          this
        );
      }),
      (e.prototype.end = function () {
        return (this._goToEnd = !0), this.update(1 / 0), this;
      }),
      (e.prototype.pause = function (e) {
        return (
          void 0 === e && (e = now()),
          this._isPaused ||
            !this._isPlaying ||
            ((this._isPaused = !0),
            (this._pauseStart = e),
            this._group && this._group.remove(this)),
          this
        );
      }),
      (e.prototype.resume = function (e) {
        return (
          void 0 === e && (e = now()),
          this._isPaused &&
            this._isPlaying &&
            ((this._isPaused = !1),
            (this._startTime += e - this._pauseStart),
            (this._pauseStart = 0),
            this._group && this._group.add(this)),
          this
        );
      }),
      (e.prototype.stopChainedTweens = function () {
        for (var e = 0, t = this._chainedTweens.length; e < t; e++)
          this._chainedTweens[e].stop();
        return this;
      }),
      (e.prototype.group = function (e) {
        return (this._group = e = void 0 === e ? mainGroup : e), this;
      }),
      (e.prototype.delay = function (e) {
        return (this._delayTime = e = void 0 === e ? 0 : e), this;
      }),
      (e.prototype.repeat = function (e) {
        return (
          (this._initialRepeat = e = void 0 === e ? 0 : e),
          (this._repeat = e),
          this
        );
      }),
      (e.prototype.repeatDelay = function (e) {
        return (this._repeatDelayTime = e), this;
      }),
      (e.prototype.yoyo = function (e) {
        return (this._yoyo = e = void 0 === e ? !1 : e), this;
      }),
      (e.prototype.easing = function (e) {
        return (
          void 0 === e && (e = Easing.Linear.None),
          (this._easingFunction = e),
          this
        );
      }),
      (e.prototype.interpolation = function (e) {
        return (
          void 0 === e && (e = Interpolation.Linear),
          (this._interpolationFunction = e),
          this
        );
      }),
      (e.prototype.chain = function () {
        for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];
        return (this._chainedTweens = e), this;
      }),
      (e.prototype.onStart = function (e) {
        return (this._onStartCallback = e), this;
      }),
      (e.prototype.onEveryStart = function (e) {
        return (this._onEveryStartCallback = e), this;
      }),
      (e.prototype.onUpdate = function (e) {
        return (this._onUpdateCallback = e), this;
      }),
      (e.prototype.onRepeat = function (e) {
        return (this._onRepeatCallback = e), this;
      }),
      (e.prototype.onComplete = function (e) {
        return (this._onCompleteCallback = e), this;
      }),
      (e.prototype.onStop = function (e) {
        return (this._onStopCallback = e), this;
      }),
      (e.prototype.update = function (e, t) {
        if (
          (void 0 === e && (e = now()),
          void 0 === t && (t = !0),
          this._isPaused)
        )
          return !0;
        var r,
          a = this._startTime + this._duration;
        if (!this._goToEnd && !this._isPlaying) {
          if (a < e) return !1;
          t && this.start(e, !0);
        }
        if (((this._goToEnd = !1), e < this._startTime)) return !0;
        !1 === this._onStartCallbackFired &&
          (this._onStartCallback && this._onStartCallback(this._object),
          (this._onStartCallbackFired = !0)),
          !1 === this._onEveryStartCallbackFired &&
            (this._onEveryStartCallback &&
              this._onEveryStartCallback(this._object),
            (this._onEveryStartCallbackFired = !0)),
          (a = (e - this._startTime) / this._duration);
        (a = 0 === this._duration || 1 < a ? 1 : a),
          (t = this._easingFunction(a));
        if (
          (this._updateProperties(
            this._object,
            this._valuesStart,
            this._valuesEnd,
            t
          ),
          this._onUpdateCallback && this._onUpdateCallback(this._object, a),
          1 !== a)
        )
          return !0;
        if (0 < this._repeat) {
          for (r in (isFinite(this._repeat) && this._repeat--,
          this._valuesStartRepeat))
            this._yoyo ||
              "string" != typeof this._valuesEnd[r] ||
              (this._valuesStartRepeat[r] =
                this._valuesStartRepeat[r] + parseFloat(this._valuesEnd[r])),
              this._yoyo && this._swapEndStartRepeatValues(r),
              (this._valuesStart[r] = this._valuesStartRepeat[r]);
          return (
            this._yoyo && (this._reversed = !this._reversed),
            void 0 !== this._repeatDelayTime
              ? (this._startTime = e + this._repeatDelayTime)
              : (this._startTime = e + this._delayTime),
            this._onRepeatCallback && this._onRepeatCallback(this._object),
            !(this._onEveryStartCallbackFired = !1)
          );
        }
        this._onCompleteCallback && this._onCompleteCallback(this._object);
        for (var i = 0, n = this._chainedTweens.length; i < n; i++)
          this._chainedTweens[i].start(this._startTime + this._duration, !1);
        return (this._isPlaying = !1);
      }),
      (e.prototype._updateProperties = function (e, t, r, a) {
        for (var i in r) {
          var n, s, o, l;
          void 0 !== t[i] &&
            ((n = t[i] || 0),
            (s = r[i]),
            (o = Array.isArray(e[i])),
            (l = Array.isArray(s)),
            !o && l
              ? (e[i] = this._interpolationFunction(s, a))
              : "object" == typeof s && s
              ? this._updateProperties(e[i], n, s, a)
              : "number" == typeof (s = this._handleRelativeValue(n, s)) &&
                (e[i] = n + (s - n) * a));
        }
      }),
      (e.prototype._handleRelativeValue = function (e, t) {
        return "string" != typeof t
          ? t
          : "+" === t.charAt(0) || "-" === t.charAt(0)
          ? e + parseFloat(t)
          : parseFloat(t);
      }),
      (e.prototype._swapEndStartRepeatValues = function (e) {
        var t = this._valuesStartRepeat[e],
          r = this._valuesEnd[e];
        (this._valuesStartRepeat[e] =
          "string" == typeof r
            ? this._valuesStartRepeat[e] + parseFloat(r)
            : this._valuesEnd[e]),
          (this._valuesEnd[e] = t);
      }),
      e
    );
  })(),
  VERSION = "21.0.0",
  nextId = Sequence.nextId,
  TWEEN = mainGroup,
  getAll = TWEEN.getAll.bind(TWEEN),
  removeAll = TWEEN.removeAll.bind(TWEEN),
  add = TWEEN.add.bind(TWEEN),
  remove = TWEEN.remove.bind(TWEEN),
  update = TWEEN.update.bind(TWEEN),
  exports = {
    Easing: Easing,
    Group: Group,
    Interpolation: Interpolation,
    now: now,
    Sequence: Sequence,
    nextId: nextId,
    Tween: Tween$1,
    VERSION: VERSION,
    getAll: getAll,
    removeAll: removeAll,
    add: add,
    remove: remove,
    update: update,
  };
class Engine {
  static _singleton;
  static get singleton() {
    return (
      Engine._singleton || (Engine._singleton = new Engine()), Engine._singleton
    );
  }
  constructor() {}
  _viewerList = [];
  static gViewerPriority = 0;
  _isShowFPS = !1;
  _maxfps = 60;
  _stats;
  get isShowFPS() {
    return this._isShowFPS;
  }
  set isShowFPS(e) {
    this._isShowFPS = e;
  }
  getViewerList() {
    return this._viewerList;
  }
  setEnableStatsPanel(e) {
    if (e) {
      let e = Stats;
      this._stats || (this._stats = new e()),
        document.body.appendChild(this._stats.dom);
    } else this._stats && document.body.removeChild(this._stats.dom);
  }
  clearAllViews() {
    for (let e = 0, t = this._viewerList.length; e < t; e++) {
      var r = this._viewerList[e];
      this.removeView(r);
    }
    this._viewerList = [];
  }
  removeView(t) {
    var e = this._viewerList.findIndex(e => e === t);
    -1 !== e && (this._viewerList[e].clear(), this._viewerList.splice(e, 1));
  }
  createDefaultView(e, t, r) {
    let a = document.createElement("canvas");
    t && r
      ? ((a.style.position = "relative"),
        (a.style.width = `${t}`),
        (a.style.height = `${r}`),
        (a.style.textAlign = "center"),
        (a.width = t),
        (a.height = r))
      : ((a.style.position = "absolute"),
        (a.style.left = "0"),
        (a.style.top = "0"),
        (a.style.width = "100%"),
        (a.style.height = "100%"),
        (a.style.zIndex = "-1"),
        (a.width = e.clientWidth),
        (a.height = e.clientHeight)),
      e.appendChild(a);
    (r = new Renderer(a)),
      (e = new Viewer({
        canvas: a,
        renderer: r,
        container: e,
        width: a.width,
        height: a.height,
      }));
    return this._viewerList.push(e), e;
  }
  _isInRenderLoop = !1;
  _isStopRenderLoop = !1;
  stopRenderLoop() {
    logger.debug("----stop renderloop ----"),
      this._isInRenderLoop && (this._isStopRenderLoop = !0);
  }
  startRenderLoop() {
    if (
      (this._isStopRenderLoop && (this._isStopRenderLoop = !1),
      !this._isInRenderLoop)
    ) {
      (this._isInRenderLoop = !0),
        this._isShowFPS && this.setEnableStatsPanel(!0),
        logger.debug("----start scene render loop----");
      let n = performance.now(),
        s = 1e3 / this._maxfps;
      1e4 < this._maxfps && (s = 0.1);
      try {
        let a = window.requestAnimationFrame,
          i = e => {
            var t = performance.now(),
              r = t - n;
            if (
              (r > s &&
                ((n = t - (r % s)),
                this.renderAFrame(e),
                this._stats && this._stats.update()),
              this._isStopRenderLoop)
            )
              return (
                (this._isStopRenderLoop = !1),
                (this._isInRenderLoop = !1),
                void this.setEnableStatsPanel(!1)
              );
            a(i);
          };
        a(i);
      } catch (e) {
        logger.warn("EXCP:" + e);
      }
    }
  }
  set maxfps(e) {
    e < 1 ? logger.warn("the MaxFPS cant less than 1") : (this._maxfps = e);
  }
  renderAFrame(e) {
    logger.debug("render a frame----------------------"), exports.update();
    var r = this._viewerList;
    for (let e = 0, t = r.length; e < t; e++) {
      const a = r[e];
      a.render();
    }
  }
}
class RenderTarget {
  rttFramebuffer;
  _gl;
  constructor(e) {
    this._gl = e;
  }
  static createTextureRenderTarget(t, r, a) {
    try {
      let e = new RenderTarget(t);
      e.rttFramebuffer = t.createFramebuffer();
      var i = e.rttFramebuffer;
      t.bindFramebuffer(t.FRAMEBUFFER, i);
      const o = new Texture({
        width: r,
        height: a,
        format: ETextureFormat.RGBA,
        type: ETextureDataType.UNSIGNED_BYTE,
        data: null,
      });
      var n = o.createGlTexture(t),
        s = t.createRenderbuffer();
      return (
        t.bindRenderbuffer(t.RENDERBUFFER, s),
        t.renderbufferStorage(t.RENDERBUFFER, t.DEPTH_COMPONENT16, r, a),
        t.framebufferTexture2D(
          t.FRAMEBUFFER,
          t.COLOR_ATTACHMENT0,
          t.TEXTURE_2D,
          n,
          0
        ),
        t.framebufferRenderbuffer(
          t.FRAMEBUFFER,
          t.DEPTH_ATTACHMENT,
          t.RENDERBUFFER,
          s
        ),
        t.bindTexture(t.TEXTURE_2D, null),
        t.bindRenderbuffer(t.RENDERBUFFER, null),
        t.bindFramebuffer(t.FRAMEBUFFER, null),
        e
      );
    } catch (e) {
      throw (logger.error("createTextureRenderTarget ERROR:" + e), e);
    }
  }
  pickColor(e, t, r, a) {
    let i = this._gl;
    i.bindFramebuffer(i.FRAMEBUFFER, this.rttFramebuffer);
    var n = new Uint8Array(4 * r * a);
    return (
      i.readPixels(e, t, r, a, i.RGBA, i.UNSIGNED_BYTE, n),
      i.bindFramebuffer(i.FRAMEBUFFER, null),
      n
    );
  }
  pickColorFromRenderTarget(e, t) {
    let r = this._gl,
      a;
    logger.debug("bind frame buffer...", this.rttFramebuffer),
      r.bindFramebuffer(r.FRAMEBUFFER, this.rttFramebuffer);
    var i = new Uint8Array(4);
    return (
      r.readPixels(e, t, 1, 1, r.RGBA, r.UNSIGNED_BYTE, i),
      i && i.length && (a = Vector4.create(i[0], i[1], i[2], i[3])),
      r.bindFramebuffer(r.FRAMEBUFFER, null),
      a
    );
  }
  beginRenderToTarget(e) {
    logger.debug("begin render to FBO", e),
      e.bindFramebuffer(e.FRAMEBUFFER, this.rttFramebuffer);
  }
  endRenderToTarget(e) {
    logger.debug("end render to FBO"), e.bindFramebuffer(e.FRAMEBUFFER, null);
  }
}
class ResourceManager {
  static singleton;
  static getSingleton() {
    return (
      ResourceManager.singleton ||
        (ResourceManager.singleton = new ResourceManager()),
      ResourceManager.singleton
    );
  }
  shaderCache = {};
  loadShaders(t) {
    return new Promise((e, r) => {
      let a = 0,
        i = () => {
          a >= t.length && e(this.shaderCache);
        };
      t.forEach(t => {
        this._fetchResource(t)
          .then(
            e => {
              e.ok
                ? e.text().then(e => {
                    (this.shaderCache[t] = e), (a += 1), i();
                  })
                : (logger.warn(`load shader false ${t}`), (a += 1), i());
            },
            e => {
              logger.warn(`load shader false ${t}: ${e}`), (a += 1), i();
            }
          )
          .catch(e => {
            r(e);
          });
      });
    });
  }
  _fetchResource(e) {
    return fetch(e, {
      method: "get",
      headers: new Headers({ "Conten-Type": "text/plain" }),
    });
  }
}
function calculateOrigin(e) {
  var t = e.vertexInfo.indices.buffer,
    r = e.vertexInfo.position.buffer;
  let a = 1 / 0,
    i = 1 / 0,
    n = 1 / 0,
    s = -1 / 0,
    o = -1 / 0,
    l = -1 / 0;
  for (let e = 0; e < t.length; e += 3) {
    var u = [t[e], t[e + 1], t[e + 2]];
    for (let e = 0; e < 3; e++) {
      var h = 3 * u[e];
      r[h] < a && (a = r[h]),
        r[1 + h] < i && (i = r[1 + h]),
        r[2 + h] < n && (n = r[2 + h]),
        r[h] > s && (s = r[h]),
        r[1 + h] > o && (o = r[1 + h]),
        r[2 + h] > l && (l = r[2 + h]);
    }
  }
  return [(a + s) / 2, (i + o) / 2, (n + l) / 2];
}
class Renderer {
  enabledStates = {};
  _enabledCapabilitieNameMap;
  clear(e = [0, 0, 0, 0]) {
    let t = this.gl;
    t.clearColor(e[0], e[1], e[2], e[3]),
      t.clearDepth(1),
      t.clear(t.COLOR_BUFFER_BIT | t.DEPTH_BUFFER_BIT);
  }
  scissor(e, t, r, a) {
    this.gl.scissor(e, t, r, a);
  }
  viewport(e) {
    this.gl.viewport(e[0], e[1], e[2], e[3]),
      (this._viewport = Vector4.clone(e)),
      this._state.isCapabilityEnabled(EEnabledCapability.SCISSOR_TEST) &&
        this.scissor(e[0], e[1], e[2], e[3]);
  }
  _initEnabledCapabilities() {
    if (!this._enabledCapabilitieNameMap) {
      this._enabledCapabilitieNameMap = {};
      let e = Object.keys(EEnabledCapability);
      e.forEach(e => {
        var t = EEnabledCapability[e];
        switch (t) {
          case EEnabledCapability.BLEND:
            this._enabledCapabilitieNameMap[t] = this.gl.BLEND;
            break;
          case EEnabledCapability.CULL_FACE:
            this._enabledCapabilitieNameMap[t] = this.gl.CULL_FACE;
            break;
          case EEnabledCapability.DEPTH_TEST:
            this._enabledCapabilitieNameMap[t] = this.gl.DEPTH_TEST;
            break;
          case EEnabledCapability.DITHER:
            this._enabledCapabilitieNameMap[t] = this.gl.DITHER;
            break;
          case EEnabledCapability.POLYGON_OFFSET_FILL:
            this._enabledCapabilitieNameMap[t] = this.gl.POLYGON_OFFSET_FILL;
            break;
          case EEnabledCapability.SAMPLE_ALPHA_TO_COVERAGE:
            this._enabledCapabilitieNameMap[t] =
              this.gl.SAMPLE_ALPHA_TO_COVERAGE;
            break;
          case EEnabledCapability.SAMPLE_COVERAGE:
            this._enabledCapabilitieNameMap[t] = this.gl.SAMPLE_COVERAGE;
            break;
          case EEnabledCapability.SCISSOR_TEST:
            this._enabledCapabilitieNameMap[t] = this.gl.SCISSOR_TEST;
            break;
          case EEnabledCapability.STENCIL_TEST:
            this._enabledCapabilitieNameMap[t] = this.gl.STENCIL_TEST;
        }
      });
    }
  }
  setState(e, t) {
    logger.debug("enable state::", e, t);
    var r = this._enabledCapabilitieNameMap[e];
    t
      ? (this.gl.enable(r), this._state.enable(e))
      : (this.gl.disable(r), this._state.disable(e));
  }
  applyState(e) {
    var t = this._state.capabilities;
    logger.debug("[Applystate_start::]", t);
    var r = e.capabilities,
      a = Object.keys(t);
    for (let e = 0; e < a.length; e++) {
      var i,
        n = parseInt(a[e], 10);
      r[n] && r[n] !== t[n]
        ? ((i = this._enabledCapabilitieNameMap[n]),
          logger.debug("[EnableState::]", n),
          this.gl.enable(i),
          this._state.enable(n))
        : !r[n] &&
          t[n] &&
          (logger.debug("[DisableState::]", n),
          (i = this._enabledCapabilitieNameMap[n]),
          this.gl.disable(i),
          this._state.disable(n));
    }
    var s = Object.keys(r);
    for (let e = 0; e < s.length; e++) {
      var o,
        l = parseInt(s[e], 10);
      !t[l] &&
        r[l] &&
        (logger.debug("[EnableState::]", l),
        (o = this._enabledCapabilitieNameMap[l]),
        this.gl.enable(o),
        this._state.enable(l));
    }
    logger.debug("[Applystate_end::]", this._state.capabilities);
  }
  lastUsedProgramInfo;
  gl;
  canvas;
  extTextureFloat;
  extTextureHalfFloat;
  extTextureHalfFloatLinear;
  extLoseContext;
  _viewport;
  _state = new State();
  getGLContext() {
    return this.gl;
  }
  initExtentions(r) {
    const a = {};
    r.getSupportedExtensions().forEach(e => {
      a[e] = !0;
    });
    var e = (...t) => {
      for (let e = 0; e < t.length; e++)
        if (a.hasOwnProperty(t[e])) return r.getExtension(t[e]);
      return null;
    };
    (this.extTextureFloat = e("OES_texture_float")),
      (this.extTextureHalfFloat = e("OES_texture_half_float")),
      (this.extTextureHalfFloatLinear = e("OES_texture_half_float_linear")),
      (this.extLoseContext = e("WEBGL_lose_context")),
      this.extLoseContext ||
        logger.warn("[Extension]:WEBGL_lose_context is not supported!");
  }
  constructor(e) {
    (this.canvas = e),
      this.gl ||
        ((this.gl = e.getContext("webgl2", { preserverDrawingBuffer: !1 })),
        this.initExtentions(this.gl),
        logger.log("webgl version: webgl2"),
        e.addEventListener(
          "webglcontextlost",
          function (e) {
            e.preventDefault(), logger.warn("WebGL context lost!");
          },
          !1
        )),
      this._state.disableAll(),
      this._initEnabledCapabilities();
  }
  _setupLights(r, e) {
    if (0 !== e.lights.length) {
      var a,
        i = e.lights;
      for (let e = 0, t = i.length; e < t; e++)
        i[e] instanceof DirectionLight &&
          ((a = i[e]),
          (r.uniforms[`uLightPos_${e}`] = a._position),
          (r.uniforms[`uLightIntensity_${e}`] = a.intensity),
          (r.uniforms[`uLightColor_${e}`] = a.color),
          (r.uniforms[`uLightDirection_${e}`] = a.direction));
    }
  }
  _createNeedTexture(t) {
    var r = Object.keys(t.uniforms);
    for (let e = 0; e < r.length; e++) {
      var a = r[e];
      t.uniforms[a] instanceof Texture &&
        (logger.debug("create texture::", a),
        (t.uniforms[a] = t.uniforms[a].createGlTexture(this.gl)));
    }
  }
  drawObject(e, t) {
    if ((logger.debug("drawObj:", e), !e.vertexAttrBufferCache)) {
      e.vertexAttrBufferCache = {};
      for (const h of Object.keys(e.vertexInfo))
        e.vertexAttrBufferCache[h] = { attrInfo: e.vertexInfo[h] };
    }
    if (
      (this._createNeedTexture(e),
      this._setupLights(e, t),
      !e.vertexInfo && !e.programInfo)
    )
      throw new Error(
        "there is invalid vertexInfo or programInfo in vertexInfo"
      );
    var r;
    e.texture &&
      ((r = e.texture.createGlTexture(this.gl)), (e.uniforms.sampler = r)),
      e.bindIBO(this.gl),
      logger.debug("[programInfo]", e),
      e.programInfo ||
        (e.shaderGenerator
          ? ((t = e.shaderGenerator({ material: e.material, options: t })),
            logger.debug("create programInfo from shaderGenerator:", t),
            (e.programInfo = new ProgramInfo(t.vs, t.fs)))
          : ((e.programInfo = new ProgramInfo(defaultVsCode, defaultFsCode)),
            logger.debug("new program!!!"))),
      this.lastUsedProgramInfo !== e.programInfo &&
        (e.programInfo.program ||
          (logger.debug("create program"),
          Program.createProgram(this.gl, e.programInfo)),
        logger.debug("setup program"),
        (this.lastUsedProgramInfo = e.programInfo),
        this.gl.useProgram(e.programInfo.program.glProgram));
    let a = e.vertexInfo,
      i = e.programInfo.program;
    for (const d in i.attributes.map)
      if (i.attributes.map.hasOwnProperty(d)) {
        var n = d.slice(1).toLowerCase();
        if (a.hasOwnProperty(n)) {
          var s = e.vertexAttrBufferCache[n];
          const c = i.attributes.map[d];
          c.setter.setValue(this.gl, c.addr, s);
        } else logger.debug("vertexInfo isn't has property:" + n);
      }
    let o = e.uniforms;
    for (const f in i.uniforms.map)
      if (i.uniforms.map.hasOwnProperty(f))
        if (o && o.hasOwnProperty(f)) {
          var l = o[f];
          const _ = i.uniforms.map[f];
          _.setter.type === ESetterType.SAMPLERSETTER
            ? _.setter.setValue(this.gl, _.addr, l, _.textureUnit)
            : _.setter.setValue(this.gl, _.addr, l);
        } else
          logger.warn(
            "drawobj isn't has property 'uniforms' " + f,
            i.uniforms.map
          );
    let u = this.gl.TRIANGLES;
    e.drawType === EDrawType.POINTS
      ? (u = this.gl.POINTS)
      : e.drawType === EDrawType.LINES && (u = this.gl.LINES),
      e.vertexInfo.indices
        ? this.gl.drawElements(
            u,
            e.drawArrayCount,
            e.vertexInfo.indices.glType | this.gl.UNSIGNED_BYTE,
            e.drawArrayOffset
          )
        : this.gl.drawArrays(u, e.drawArrayOffset, e.drawArrayCount);
  }
}
function arrayBufferToString(e) {
  const t = new TextDecoder("latin1");
  return t.decode(e);
}
class STLLoader {
  parseText(t) {
    if (t) {
      const c = (t, r) => {
        for (let e = 0; e < t.length; e++) r.push(t[e]);
      };
      var r = t.split("\n");
      let e = 1;
      for (var a = [], i = [], n = []; -1 !== e; )
        e = ((e, t, r, a, i) => {
          const n = e[t];
          if (void 0 === n) return -1;
          if (-1 !== n.indexOf("endfacet")) return t + 1;
          if (-1 === n.indexOf("facet")) return t + 1;
          let s = 0,
            o = 2;
          for (
            var l = r.length / 3,
              u = n
                .split(/[ \t]+/)
                .filter(e => e)
                .slice(-3)
                .map(Number);
            -1 !== e[t + o].indexOf("vertex");

          ) {
            const d = e[t + o];
            var h = d
              .split(/[ \t]+/)
              .filter(e => e)
              .slice(-3)
              .map(Number);
            c(h, r), c(u, i), s++, o++;
          }
          for (let e = 0; e < s; e++) a.push(l + e);
          for (; e[t + o] && -1 !== e[t + o].indexOf("endfacet"); ) o++;
          return t + o + 2;
        })(r, e, a, i, n);
      logger.debug("Parse ASCII STL:", a, i, n);
      const s = new Geometry();
      return (
        s.setVertexAttribute("position", {
          buffer: new Float32Array(a),
          numComponents: 3,
          sizeofStride: 12,
          offsetofStride: 0,
        }),
        s.setVertexAttribute("normal", {
          buffer: new Float32Array(n),
          numComponents: 3,
          sizeofStride: 12,
          offsetofStride: 0,
        }),
        s.setVertexAttribute("indices", {
          buffer: new Int32Array(i),
          numComponents: 1,
          sizeofStride: 4,
          offsetofStride: 0,
        }),
        s
      );
    }
  }
  parseBinary(e) {
    if (e) {
      const _ = new DataView(e, 0, 84);
      var r = 84 + 50 * _.getUint32(80, !0) === e.byteLength;
      if ((logger.debug("Is this STL a binary??", r), !r))
        return this.parseText(arrayBufferToString(e));
      (r = arrayBufferToString(e.slice(0, 80))), (r = this.parseHeader(r));
      const p = new DataView(e, 84);
      var a = (e.byteLength - 84) / 50;
      const E = new Float32Array(9 * a),
        g = new Float32Array(9 * a),
        T = new Uint32Array(3 * a),
        m = new Uint16Array(a);
      let t = 0;
      for (let e = 0; e < a; e++) {
        var i = 50 * e;
        (g[9 * e + 0] = p.getFloat32(0 + i, !0)),
          (g[9 * e + 1] = p.getFloat32(4 + i, !0)),
          (g[9 * e + 2] = p.getFloat32(8 + i, !0)),
          (g[9 * e + 3] = p.getFloat32(0 + i, !0)),
          (g[9 * e + 4] = p.getFloat32(4 + i, !0)),
          (g[9 * e + 5] = p.getFloat32(8 + i, !0)),
          (g[9 * e + 6] = p.getFloat32(0 + i, !0)),
          (g[9 * e + 7] = p.getFloat32(4 + i, !0)),
          (g[9 * e + 8] = p.getFloat32(8 + i, !0)),
          (E[9 * e + 0] = p.getFloat32(12 + i, !0)),
          (E[9 * e + 1] = p.getFloat32(16 + i, !0)),
          (E[9 * e + 2] = p.getFloat32(20 + i, !0)),
          (E[9 * e + 3] = p.getFloat32(24 + i, !0)),
          (E[9 * e + 4] = p.getFloat32(28 + i, !0)),
          (E[9 * e + 5] = p.getFloat32(32 + i, !0)),
          (E[9 * e + 6] = p.getFloat32(36 + i, !0)),
          (E[9 * e + 7] = p.getFloat32(40 + i, !0)),
          (E[9 * e + 8] = p.getFloat32(44 + i, !0)),
          (T[t++] = 3 * e + 0),
          (T[t++] = 3 * e + 1),
          (T[t++] = 3 * e + 2),
          (m[e] = p.getUint16(48 + i, !0));
      }
      logger.debug("header::", r);
      e = "SPACE";
      if (e in r && "LPS" !== r[e]) {
        var n = r[e];
        const A = new Float32Array(16);
        switch (((A[15] = 1), n[0])) {
          case "L":
            A[0] = 1;
            break;
          case "R":
            A[0] = -1;
            break;
          default:
            return void logger.error(
              `Can not convert STL file from ${n} to LPS space: ` +
                "permutations not supported. Use itk.js STL reader instead."
            );
        }
        switch (n[1]) {
          case "P":
            A[5] = 1;
            break;
          case "A":
            A[5] = -1;
            break;
          default:
            return void logger.error(
              `Can not convert STL file from ${n} to LPS space: ` +
                "permutations not supported. Use itk.js STL reader instead."
            );
        }
        switch (n[2]) {
          case "S":
            A[10] = 1;
            break;
          case "I":
            A[10] = -1;
            break;
          default:
            return void logger.error(
              `Can not convert STL file from ${n} to LPS space: ` +
                "permutations not supported. Use itk.js STL reader instead."
            );
        }
        var s = [0, 0, 0],
          o = Matrix4.fromArray(A);
        for (let e = 0; e < E.length; e += 3) {
          var l = E[e],
            u = E[e + 1],
            h = E[e + 2];
          Vector3.transformMat4([l, u, h], o, s),
            (E[e] = s[0]),
            (E[e + 1] = s[1]),
            (E[e + 2] = s[2]);
        }
        for (let e = 0; e < g.length; e += 3) {
          var d = g[e],
            c = g[e + 1],
            f = g[e + 2];
          Vector3.transformMat4([d, c, f], o, s),
            (g[e] = s[0]),
            (g[e + 1] = s[1]),
            (g[e + 2] = s[2]);
        }
      }
      logger.debug("Parse Binary STL:", E, g, T, m);
      const S = new Geometry();
      return (
        S.setVertexAttribute("position", {
          buffer: E,
          numComponents: 3,
          sizeofStride: 12,
          offsetofStride: 0,
        }),
        S.setVertexAttribute("normal", {
          buffer: g,
          numComponents: 3,
          sizeofStride: 12,
          offsetofStride: 0,
        }),
        S.setVertexAttribute("indices", {
          buffer: T,
          numComponents: 1,
          sizeofStride: 4,
          offsetofStride: 0,
        }),
        S
      );
    }
  }
  parseHeader(e) {
    const t = e.split(" ");
    var r = t.filter(e => -1 < e.indexOf("="));
    const a = {};
    for (let e = 0; e < r.length; ++e) {
      const n = r[e];
      var i = n.split("=");
      2 === i.length && (a[i[0]] = i[1]);
    }
    return a;
  }
}
class BoxGeometry extends Geometry {
  constructor(e) {
    super();
    var t = e.width || 1,
      r = e.height || 1,
      a = e.length || 1;
    let i = [
      1, -1, 1, -1, -1, 1, -1, 1, 1, 1, 1, 1, 1, 1, -1, -1, 1, -1, -1, -1, -1,
      1, -1, -1, 1, 1, -1, 1, -1, -1, 1, -1, 1, 1, 1, 1, -1, 1, 1, -1, -1, 1,
      -1, -1, -1, -1, 1, -1, -1, 1, 1, -1, 1, -1, 1, 1, -1, 1, 1, 1, 1, -1, 1,
      1, -1, -1, -1, -1, -1, -1, -1, 1,
    ];
    let n = [],
      s = [],
      o = e.faceUV || new Array(6),
      l = e.faceColors;
    for (let e = 0; e < 6; e++)
      void 0 === o[e] && (o[e] = Vector4.create(0, 0, 1, 1)),
        l && void 0 === l[e] && (l[e] = Vector4.create(1, 1, 1, 1));
    for (let t = 0; t < 6; t++)
      if (
        (n.push(o[t][2], o[t][3]),
        n.push(o[t][0], o[t][3]),
        n.push(o[t][0], o[t][1]),
        n.push(o[t][2], o[t][1]),
        l)
      )
        for (let e = 0; e < 4; e++) s.push(l[t][0], l[t][1], l[t][2], l[t][3]);
    var u = [t / 2, r / 2, a / 2];
    for (let e = 0; e < i.length; e++) i[e] = i[e] * u[e % 3];
    this.setVertexAttribute("position", {
      buffer: new Float32Array(i),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0,
    }),
      this.setVertexAttribute("normal", {
        buffer: new Float32Array([
          0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,
          0, -1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,
          0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0,
          0, -1, 0, 0, -1, 0,
        ]),
        numComponents: 3,
        sizeofStride: 12,
        offsetofStride: 0,
      }),
      this.setVertexAttribute("texcoord_0", {
        buffer: new Float32Array(n),
        numComponents: 2,
        sizeofStride: 8,
        offsetofStride: 0,
      }),
      this.setVertexAttribute("indices", {
        buffer: new Uint16Array([
          0, 3, 2, 0, 2, 1, 4, 7, 6, 4, 6, 5, 8, 11, 10, 8, 10, 9, 12, 15, 14,
          12, 14, 13, 16, 19, 18, 16, 18, 17, 20, 23, 22, 20, 22, 21,
        ]),
        numComponents: 1,
        sizeofStride: 2,
        offsetofStride: 0,
      }),
      l &&
        this.setVertexAttribute("color", {
          buffer: new Float32Array(s),
          numComponents: 4,
          sizeofStride: 16,
          offsetofStride: 0,
        });
  }
}
class SphereGeometry extends Geometry {
  _radius;
  _segments;
  get radius() {
    return this._radius;
  }
  get segments() {
    return this._segments;
  }
  setRedius(e) {
    (this._radius = e), this._generate();
  }
  setSegments(e) {
    (this._segments = e), this._generate();
  }
  updateGeomerty(e) {
    e.radius && (this._radius = e.radius),
      e.segments && (this._segments = e.segments),
      this._generate();
  }
  constructor(e) {
    super(),
      (this._radius = e.radius || 1),
      (this._segments = e.segments || 16),
      this._generate();
  }
  _generate() {
    var r = this._segments,
      t = this._radius;
    const a = [],
      i = [],
      n = [];
    for (let e = 0; e <= r; e++) {
      var s = (e * Math.PI) / r;
      for (let e = 0; e <= 2 * r; e++) {
        var o = (2 * e * Math.PI) / (2 * r),
          l = t * Math.sin(s) * Math.cos(o),
          u = t * Math.sin(s) * Math.sin(o),
          o = t * Math.cos(s);
        a.push(l, u, o), i.push(...[l / t, u / t, o / t]);
      }
    }
    for (let t = 0; t < r; t++)
      for (let e = 0; e < 2 * r; e++) {
        var h = t * (2 * r + 1) + e,
          d = h + 2 * r + 1;
        n.push(h, d, h + 1), n.push(d, d + 1, h + 1);
      }
    this.setVertexAttribute("position", {
      buffer: new Float32Array(a),
      numComponents: 3,
      sizeofStride: 12,
      offsetofStride: 0,
    }),
      this.setVertexAttribute("normal", {
        buffer: new Float32Array(i),
        numComponents: 3,
        sizeofStride: 12,
        offsetofStride: 0,
      }),
      this.setVertexAttribute("indices", {
        buffer: new Uint16Array(n),
        numComponents: 1,
        sizeofStride: 2,
        offsetofStride: 0,
      });
  }
}
class CylinderGeometry extends Geometry {
  _radius;
  _height;
  _segments;
  get radius() {
    return this._radius;
  }
  setRedius(e) {
    (this._radius = e), this._generate();
  }
  get segments() {
    return this._segments;
  }
  setSegments(e) {
    (this._segments = e), this._generate();
  }
  get height() {
    return this._height;
  }
  setHeight(e) {
    (this._height = e), this._generate();
  }
  updateGeomerty(e) {
    e.radius && (this._radius = e.radius),
      e.height && (this._height = e.height),
      e.segments && (this._segments = e.segments),
      this._generate();
  }
  constructor(e) {
    super(),
      (this._radius = e.radius || 1),
      (this._height = e.height || 1),
      (this._segments = e.segments || 16),
      this._generate();
  }
  _generate() {
    const s = [],
      o = [],
      l = [],
      u = this._segments,
      h = this._radius;
    var t = this._height;
    for (let e = 0; e <= u; ++e) {
      var r = (e / u) * 2 * Math.PI,
        a = h * Math.cos(r),
        i = h * Math.sin(r);
      s.push(a, 0, i), s.push(a, t, i);
      (i = Math.cos(r)), (r = Math.sin(r));
      o.push(i, 0, r),
        o.push(i, 0, r),
        e < u &&
          ((r = 2 * e), l.push(r, 1 + r, 3 + r), l.push(r, 3 + r, 2 + r));
    }
    var e = (t, r) => {
      var a = s.length / 3;
      s.push(0, t, 0), o.push(0, r, 0);
      for (let e = 0; e <= u; ++e) {
        var i = (e / u) * 2 * Math.PI,
          n = h * Math.cos(i),
          i = h * Math.sin(i);
        s.push(n, t, i),
          o.push(0, r, 0),
          e < u &&
            (0 < r
              ? l.push(a, a + e + 2, a + e + 1)
              : l.push(a, a + e + 1, a + e + 2));
      }
    };
    e(0, -1),
      e(t, 1),
      this.setVertexAttribute("position", {
        buffer: new Float32Array(s),
        numComponents: 3,
        sizeofStride: 12,
        offsetofStride: 0,
      }),
      this.setVertexAttribute("normal", {
        buffer: new Float32Array(o),
        numComponents: 3,
        sizeofStride: 12,
        offsetofStride: 0,
      }),
      this.setVertexAttribute("indices", {
        buffer: new Uint16Array(l),
        numComponents: 1,
        sizeofStride: 2,
        offsetofStride: 0,
      });
  }
}
const TweenEasingType = { Linear: Easing.Linear, Quadratic: Easing.Quadratic };
class Tween extends MiObject {
  _tween;
  _obj;
  constructor(e) {
    super(), (this._className = "Tween"), (this._obj = e.src);
    let t = new Tween$1(e.src);
    t.dynamic(!0),
      e.to &&
        (t.to(e.to.updateobj, e.to.costtime),
        e.easing && t.easing(e.easing),
        e.onUpdate && t.onUpdate(e.onUpdate),
        e.onStart && t.onStop(e.onStart),
        e.onStop && t.onStop(e.onStop),
        e.repeat && t.repeat(e.repeat),
        e.yoyo && t.yoyo(e.yoyo),
        e.chain && t.chain(e.chain)),
      (this._tween = t);
  }
  get tween() {
    return this._tween;
  }
  start() {
    this._tween.start();
  }
  stop() {
    this._tween.stop();
  }
  pause() {
    this._tween.pause();
  }
  end() {
    this._tween.end();
  }
  resume(e) {
    this._tween.resume(e);
  }
  repeat(e = 1 / 0) {
    this._tween.repeat(e);
  }
  yoyo(e) {
    this._tween.yoyo(e);
  }
  chain(e) {
    this._tween.chain(e._tween);
  }
  onComplete(e) {
    this._tween.onComplete(e);
  }
  onRepeat(e) {
    this._tween.onRepeat(e);
  }
  onStop(e) {
    this._tween.onStop(e);
  }
  onStart(e) {
    this._tween.onStart(e);
  }
  onUpdate(e) {
    this._tween.onUpdate(e);
  }
}
class Animation {
  _tweens = [];
  addTween(e) {
    this._tweens.push(e);
  }
  removeTween(t) {
    t.stop();
    var e = this._tweens.findIndex(e => e.id === t.id);
    this._tweens.splice(e, 1);
  }
  removeAll() {
    this.stop(), (this._tweens = []);
  }
  calTweenCostTime(e) {
    var t = e._duration * (1 + e._repeat),
      r = e._chainedTweens;
    let a = t;
    for (let e = 0, t = r.length; e < t; e++) {
      var i = r[e];
      a += this.calTweenCostTime(i);
    }
    return a;
  }
  start(e) {
    for (let e = 0, t = this._tweens.length; e < t; e++) {
      const s = this._tweens[e];
      s.start();
    }
    let r = 0,
      a;
    for (let e = 0, t = this._tweens.length; e < t; e++) {
      var i = this._tweens[e],
        n = i.tween._startTime + this.calTweenCostTime(i.tween);
      n > r && ((r = n), (a = i));
    }
    if (a) {
      const t = a.tween._chainedTweens;
      (0 < t.length ? t[t.length - 1] : a).onComplete(() => {
        Engine.singleton.stopRenderLoop();
      });
    }
    Engine.singleton.startRenderLoop();
  }
  stop() {
    for (let e = 0, t = this._tweens.length; e < t; e++) {
      const r = this._tweens[e];
      r.stop();
    }
    Engine.singleton.stopRenderLoop();
  }
  pause() {
    for (let e = 0, t = this._tweens.length; e < t; e++) {
      const r = this._tweens[e];
      r.pause();
    }
  }
}
export {
  Animation,
  Attribute,
  AttributeInfo,
  Attributes,
  BoxGeometry,
  Camera,
  ColorMaterial,
  CylinderGeometry,
  DirectionLight,
  DrawableObj,
  ECameraType,
  EDrawType,
  EEnabledCapability,
  ERenderGroupType,
  ESetterType,
  ETextureDataType,
  ETextureFormat,
  ETextureMagFilterType,
  ETextureMinFilterType,
  ETextureWrapType,
  Engine,
  Entity,
  GLTFLoader,
  Geometry,
  ImageLoader,
  Light,
  Material,
  common as MathUtils,
  Matrix4,
  OrbitCameraController,
  PhoneMaterial,
  Program,
  ProgramInfo,
  quat as Quat,
  RGBELoader,
  RenderGroup,
  RenderGroups,
  RenderList,
  RenderTarget,
  Renderer,
  ResourceManager,
  STLLoader,
  Scene,
  SceneNode,
  SphereGeometry,
  State,
  Texture,
  Tween,
  TweenEasingType,
  Uniform,
  Vector2,
  Vector3,
  Vector4,
  Viewer,
  calculateOrigin,
  fetchFile,
  fetchJson,
  fetchText,
  loadImageBitmap,
  loadImageElement,
};
