import { FC } from "react"

import {
  FormControl,
  SelectChangeEvent,
  SxProps,
} from "../../../../../components/mui.components"

import SelectCheckBox from "./selectCheckBox"

interface FilterListProps {
  selectedMenu: string[] | number[]
  menuList: string[] | number[]
  placeHolder: string
  filterKey: string
  listStyle: SxProps
  label?: string
  onFilterChange: (e: SelectChangeEvent, filterVal: string) => void
}

export const UTableFilterList: FC<FilterListProps> = ({
  selectedMenu,
  menuList,
  placeHolder,
  filterKey,
  listStyle,
  label,
  onFilterChange,
}) => {
  return (
    <FormControl sx={{ width: "100%", backgroundColor: "background.default" }}>
      {menuList?.length ? (
        <SelectCheckBox
          dataTestId={placeHolder + "filter"}
          placeHolder={placeHolder}
          sxProps={{
            ".MuiSelect-select": {
              padding: "16px 12px",
            },
            height: 40,
            ...listStyle,
          }}
          selectedCheckBox={selectedMenu}
          handleChange={(e) => onFilterChange(e, filterKey)}
          menuList={menuList}
          listLabel={!label ? "" : label}
        />
      ) : null}
    </FormControl>
  )
}
