import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { useAppSelector } from "../../../../../../core/app/hooks"
import { RootState } from "../../../../../../core/app/store"
import { getIDs, toothIdTypes } from "../../column"

import {
  cutoutIDs,
  labels,
  leftNums,
  legendsTmp,
  pressIDs,
  qua1Ids,
  qua2Ids,
  qua3Ids,
  qua4Ids,
  rightNums,
  slitIDs,
  biteIDs,
  svgs,
} from "./column"
import { auxProps } from "./type"

import "./auxilliaries.scss"
const Auxilliaries: FC<auxProps> = ({ attachment, toothlist }) => {
  const [auxData, setData] = useState({})
  console.log("99888auxData", auxData)
  const { t } = useTranslation("common")
  const {
    clinicalSettings: { NumberSystem },
  } = useAppSelector((state: RootState) => state.userService)

  useEffect(() => {
    const obj = {}

    for (const key of Object.keys(attachment)) {
      const attachTmp = attachment[key].attachment

      if (attachTmp && attachTmp.length) {
        for (let i = 0; i < attachTmp.length; i++) {
          const { attachmentId, onLingualSide } = attachTmp[i]

          //press
          if (pressIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }
            if (onLingualSide) {
              if (obj[key].lingual) {
                obj[key].lingual.icon = svgs.pressure
              } else {
                obj[key].lingual = {
                  icon: svgs.pressure,
                }
              }
            } else {
              if (obj[key].buccal) {
                obj[key].buccal.icon = svgs.pressure
              } else {
                obj[key].buccal = {
                  icon: svgs.pressure,
                }
              }
            }
          }
          //cutout
          if (cutoutIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }

            if (onLingualSide) {
              if (key < 500) {
                if (obj[key].lingual) {
                  obj[key].lingual.icon =
                    key <= 16 ? svgs.cutoutUp : svgs.cutoutDown
                } else {
                  obj[key].lingual = {
                    icon: key <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
                  }
                }
              } else if (key > 500 && key < 700) {
                if (obj[key].lingual) {
                  obj[key].lingual.icon =
                    key - 500 <= 16 ? svgs.cutoutUp : svgs.cutoutDown
                } else {
                  obj[key].lingual = {
                    icon: key - 500 <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
                  }
                }
              } else if (key > 700) {
                if (obj[key].lingual) {
                  obj[key].lingual.icon =
                    key - 700 <= 16 ? svgs.cutoutUp : svgs.cutoutDown
                } else {
                  obj[key].lingual = {
                    icon: key - 700 <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
                  }
                }
              }
            } else {
              if (key < 500) {
                if (obj[key].buccal) {
                  obj[key].buccal.icon =
                    key <= 16 ? svgs.cutoutUp : svgs.cutoutDown
                } else {
                  obj[key].buccal = {
                    icon: key <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
                  }
                }
              } else if (key > 500 && key < 700) {
                if (obj[key].buccal) {
                  obj[key].buccal.icon =
                    key - 500 <= 16 ? svgs.cutoutUp : svgs.cutoutDown
                } else {
                  obj[key].buccal = {
                    icon: key - 500 <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
                  }
                }
              } else if (key > 700) {
                if (obj[key].buccal) {
                  obj[key].buccal.icon =
                    key - 700 <= 16 ? svgs.cutoutUp : svgs.cutoutDown
                } else {
                  obj[key].buccal = {
                    icon: key - 700 <= 16 ? svgs.cutoutUp : svgs.cutoutDown,
                  }
                }
              }
            }
          }
          //slit
          if (slitIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }

            if (onLingualSide) {
              if (key < 500) {
                if (obj[key].lingual) {
                  obj[key].lingual.icon =
                    (key >= 1 && key <= 8) || (key >= 17 && key <= 24)
                      ? svgs.slit13
                      : svgs.slit24
                } else {
                  obj[key].lingual = {
                    icon:
                      (key >= 1 && key <= 8) || (key >= 17 && key <= 24)
                        ? svgs.slit13
                        : svgs.slit24,
                  }
                }
              } else if (key > 500 && key < 700) {
                if (obj[key].lingual) {
                  obj[key].lingual.icon =
                    (key - 500 >= 1 && key - 500 <= 8) ||
                    (key - 500 >= 17 && key - 500 <= 24)
                      ? svgs.slit13
                      : svgs.slit24
                } else {
                  obj[key].lingual = {
                    icon:
                      (key - 500 >= 1 && key - 500 <= 8) ||
                      (key - 500 >= 17 && key - 500 <= 24)
                        ? svgs.slit13
                        : svgs.slit24,
                  }
                }
              } else if (key > 700) {
                if (obj[key].lingual) {
                  obj[key].lingual.icon =
                    (key - 700 >= 1 && key - 700 <= 8) ||
                    (key - 700 >= 17 && key - 700 <= 24)
                      ? svgs.slit13
                      : svgs.slit24
                } else {
                  obj[key].lingual = {
                    icon:
                      (key - 700 >= 1 && key - 700 <= 8) ||
                      (key - 700 >= 17 && key - 700 <= 24)
                        ? svgs.slit13
                        : svgs.slit24,
                  }
                }
              }
            } else {
              if (key < 500) {
                if (obj[key].buccal) {
                  obj[key].buccal.icon =
                    (key >= 1 && key <= 8) || (key >= 17 && key <= 24)
                      ? svgs.slit13
                      : svgs.slit24
                } else {
                  obj[key].buccal = {
                    icon:
                      (key >= 1 && key <= 8) || (key >= 17 && key <= 24)
                        ? svgs.slit13
                        : svgs.slit24,
                  }
                }
              } else if (key > 500 && key < 700) {
                if (obj[key].buccal) {
                  obj[key].buccal.icon =
                    (key - 500 >= 1 && key - 500 <= 8) ||
                    (key - 500 >= 17 && key - 500 <= 24)
                      ? svgs.slit13
                      : svgs.slit24
                } else {
                  obj[key].buccal = {
                    icon:
                      (key - 500 >= 1 && key - 500 <= 8) ||
                      (key - 500 >= 17 && key - 500 <= 24)
                        ? svgs.slit13
                        : svgs.slit24,
                  }
                }
              } else if (key > 700) {
                if (obj[key].buccal) {
                  obj[key].buccal.icon =
                    (key - 700 >= 1 && key - 700 <= 8) ||
                    (key - 700 >= 17 && key - 700 <= 24)
                      ? svgs.slit13
                      : svgs.slit24
                } else {
                  obj[key].buccal = {
                    icon:
                      (key - 700 >= 1 && key - 700 <= 8) ||
                      (key - 700 >= 17 && key - 700 <= 24)
                        ? svgs.slit13
                        : svgs.slit24,
                  }
                }
                obj[key].buccal = {
                  icon:
                    (key - 700 >= 1 && key - 700 <= 8) ||
                    (key - 700 >= 17 && key - 700 <= 24)
                      ? svgs.slit13
                      : svgs.slit24,
                }
              }
            }
          }
          //bite ramp
          if (biteIDs.includes(attachmentId)) {
            if (!obj[key]) {
              obj[key] = {}
            }
            if (onLingualSide) {
              if (obj[key].lingual) {
                obj[key].lingual.biteLigual = svgs.bite
              } else {
                obj[key].lingual = {
                  biteLigual: svgs.bite,
                }
              }
            } else {
              if (obj[key].buccal) {
                obj[key].buccal.biteBuccal = svgs.bite
              } else {
                obj[key].buccal = {
                  biteBuccal: svgs.bite,
                }
              }
            }
          }
        }
      }
    }

    setData(obj)
  }, [attachment])
  return (
    <Box className={"auxWrap"}>
      <div className={"legendsBox"}>
        {legendsTmp.map((i) => {
          return (
            <div className={"container"} key={i.key}>
              <img src={i.icon} />
              <p>{i.name}</p>
            </div>
          )
        })}
      </div>
      <div className={"content"}>
        <div className={"leftBox"}>
          <div className="names">
            <p>Lingual</p>
            <p>Buccal</p>
            <p>Lingual</p>
          </div>
          <div className={"quaBox"}>
            <div className={"qua qua1"}>
              {qua1Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? Object.values(
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual,
                            )
                              .map(
                                (yuan, index) =>
                                  `url(${yuan})${
                                    index ===
                                    Object.values(
                                      auxData[getIDs(toothIdTypes[i], auxData)]
                                        .lingual,
                                    ).length -
                                      1
                                      ? ""
                                      : ","
                                  }`,
                              )
                              .join("")
                          : "none",
                        border: "none",
                      }}
                    ></div>
                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua1[j]}
                    </div>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? Object.values(
                                auxData[getIDs(toothIdTypes[i], auxData)]
                                  .buccal,
                              )
                                .map(
                                  (yuan, index) =>
                                    `url(${yuan})${
                                      index ===
                                      Object.values(
                                        auxData[
                                          getIDs(toothIdTypes[i], auxData)
                                        ].buccal,
                                      ).length -
                                        1
                                        ? ""
                                        : ","
                                    }`,
                                )
                                .join("")
                            : "none",
                          border: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? "none"
                            : "1px solid #455A64",
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={"qua qua4"}>
              {qua4Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? Object.values(
                                auxData[getIDs(toothIdTypes[i], auxData)]
                                  .buccal,
                              )
                                .map(
                                  (yuan, index) =>
                                    `url(${yuan})${
                                      index ===
                                      Object.values(
                                        auxData[
                                          getIDs(toothIdTypes[i], auxData)
                                        ].buccal,
                                      ).length -
                                        1
                                        ? ""
                                        : ","
                                    }`,
                                )
                                .join("")
                            : "none",
                          border: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? "none"
                            : "1px solid #455A64",
                        }}
                      ></div>
                    )}
                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua4[j]}
                    </div>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? Object.values(
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual,
                            )
                              .map(
                                (yuan, index) =>
                                  `url(${yuan})${
                                    index ===
                                    Object.values(
                                      auxData[getIDs(toothIdTypes[i], auxData)]
                                        .lingual,
                                    ).length -
                                      1
                                      ? ""
                                      : ","
                                  }`,
                              )
                              .join("")
                          : "none",
                        border: "none",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            <div className={"direction"} style={{ top: "0", right: "-8px" }}>
              R
            </div>
          </div>
        </div>
        <div className={"leftBox rightBox"}>
          <div className={"quaBox"}>
            <div className={"qua qua2"}>
              {qua2Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? Object.values(
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual,
                            )
                              .map(
                                (yuan, index) =>
                                  `url(${yuan})${
                                    index ===
                                    Object.values(
                                      auxData[getIDs(toothIdTypes[i], auxData)]
                                        .lingual,
                                    ).length -
                                      1
                                      ? ""
                                      : ","
                                  }`,
                              )
                              .join("")
                          : "none",
                        border: "none",
                      }}
                    ></div>
                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua2[j]}
                    </div>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? Object.values(
                                auxData[getIDs(toothIdTypes[i], auxData)]
                                  .buccal,
                              )
                                .map(
                                  (yuan, index) =>
                                    `url(${yuan})${
                                      index ===
                                      Object.values(
                                        auxData[
                                          getIDs(toothIdTypes[i], auxData)
                                        ].buccal,
                                      ).length -
                                        1
                                        ? ""
                                        : ","
                                    }`,
                                )
                                .join("")
                            : "none",
                          border: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? "none"
                            : "1px solid #455A64",
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className={"qua qua3"}>
              {qua3Ids.map((i, j) => {
                return (
                  <div className="commonWrap" key={i}>
                    {/* buccal */}
                    {!toothIdTypes[i].some((item) =>
                      toothlist.includes(item),
                    ) ? (
                      <div
                        style={{
                          border: "none",
                          backgroundImage: `url(${svgs.miss})`,
                        }}
                        className="commonBox"
                      ></div>
                    ) : (
                      <div
                        className="commonBox"
                        style={{
                          backgroundImage: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? Object.values(
                                auxData[getIDs(toothIdTypes[i], auxData)]
                                  .buccal,
                              )
                                .map(
                                  (yuan, index) =>
                                    `url(${yuan})${
                                      index ===
                                      Object.values(
                                        auxData[
                                          getIDs(toothIdTypes[i], auxData)
                                        ].buccal,
                                      ).length -
                                        1
                                        ? ""
                                        : ","
                                    }`,
                                )
                                .join("")
                            : "none",
                          border: toothIdTypes[i].some(
                            (item) => auxData[item] && auxData[item].buccal,
                          )
                            ? "none"
                            : "1px solid #455A64",
                        }}
                      ></div>
                    )}

                    <div className="commonBox commonCenter">
                      {labels[NumberSystem].qua3[j]}
                    </div>
                    {/* lingual */}
                    <div
                      className="commonBox"
                      style={{
                        visibility: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? "visible"
                          : "hidden",
                        backgroundImage: toothIdTypes[i].some(
                          (item) => auxData[item] && auxData[item].lingual,
                        )
                          ? Object.values(
                              auxData[getIDs(toothIdTypes[i], auxData)].lingual,
                            )
                              .map(
                                (yuan, index) =>
                                  `url(${yuan})${
                                    index ===
                                    Object.values(
                                      auxData[getIDs(toothIdTypes[i], auxData)]
                                        .lingual,
                                    ).length -
                                      1
                                      ? ""
                                      : ","
                                  }`,
                              )
                              .join("")
                          : "none",
                        border: "none",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            <div className={"direction"} style={{ top: "0", left: "-8px" }}>
              L
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default Auxilliaries
