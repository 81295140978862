import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Tooltip } from "@mui/material"

import { toolButtonProps } from "./type"

import "./toolButton.scss"
const ToolButton: FC<toolButtonProps> = ({
  val,
  placement = "bottom",
  title,
  callBack,
  bg,
  isSelected,
  disabled = false,
  disabledBg = "",
  isFocused = false,
  sxProps,
  unsetColor = "unset",
  selectColor = "#0288D1",
  hoverColor = "rgba(255, 255, 255, 0.08)",
  needSpan = true,
}) => {
  const { t } = useTranslation("common")
  return (
    <Tooltip
      key={val}
      title={title}
      placement={placement}
      classes={{ popper: "toolButton" }}
    >
      {needSpan ? (
        <span>
          <Button
            disabled={disabled}
            onClick={(event) => {
              callBack && callBack(val || event)
            }}
            sx={{
              width: "48px",
              height: "48px",
              padding: 0,
              color: isFocused ? "#fff" : selectColor,
              minWidth: "unset!important",
              maxWidth: "none",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: 0,
              border: "0",
              margin: "0",
              cursor: disabled ? "default" : "pointer",
              backgroundImage: `url(${disabled ? disabledBg : bg})`,
              boxShadow: "none",
              backgroundColor:
                !disabled && isSelected
                  ? `${selectColor}!important`
                  : unsetColor,
              borderRight: isSelected
                ? "1px solid rgba(255,255,255,0.12)"
                : "0",
              "&:hover": {
                backgroundColor:
                  !disabled && !isSelected
                    ? `${hoverColor}!important`
                    : unsetColor,
              },
              "&:focus": {
                backgroundColor: isFocused
                  ? "rgba(255, 255, 255, 0.12)!important"
                  : unsetColor,
              },
              ...sxProps,
            }}
          />
        </span>
      ) : (
        <Button
          disabled={disabled}
          onClick={(event) => {
            callBack && callBack(val || event)
          }}
          sx={{
            width: "48px",
            height: "48px",
            padding: 0,
            color: isFocused ? "#fff" : selectColor,
            minWidth: "unset!important",
            maxWidth: "none",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: 0,
            border: "0",
            margin: "0",
            cursor: disabled ? "default" : "pointer",
            backgroundImage: `url(${disabled ? disabledBg : bg})`,
            boxShadow: "none",
            backgroundColor:
              !disabled && isSelected ? `${selectColor}!important` : unsetColor,
            borderRight: isSelected ? "1px solid rgba(255,255,255,0.12)" : "0",
            "&:hover": {
              backgroundColor:
                !disabled && !isSelected
                  ? `${hoverColor}!important`
                  : unsetColor,
            },
            "&:focus": {
              backgroundColor: isFocused
                ? "rgba(255, 255, 255, 0.12)!important"
                : unsetColor,
            },
            ...sxProps,
          }}
        />
      )}
    </Tooltip>
  )
}
export default ToolButton
