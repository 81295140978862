import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  deleteProfileFile,
  deleteUploadedFiles,
  downloadDentalChartBlob,
  downloadProfileFile,
  downloadReportPDFBlob,
  downloadXrayFiles,
  uploadDentalChart,
  uploadProfileImage,
  uploadReportPDF,
  uploadXrayFiles,
} from "./xray.service"
import {
  fileDownloadParams,
  fileUploadParams,
  profileFileParams,
} from "./xrays.type"

export const uploadXray = createAsyncThunk(
  "XrayService/uploadXray",
  async (
    {
      patientId,
      caseId,
      formData,
      fileName,
      onFileProcesscallback,
    }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadXrayFiles(
        orgId,
        patientId,
        caseId,
        fileName,
        formData,
        onFileProcesscallback,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadXray = createAsyncThunk(
  "XrayService/downloadXray",
  async (
    { patientId, caseId, fileName }: fileDownloadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadXrayFiles(orgId, patientId, caseId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteFiles = createAsyncThunk(
  "XrayService/deleteFiles",
  async (
    {
      patientId,
      caseId,
      fileName,
      fileType,
      version,
    }: Partial<fileUploadParams>,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await deleteUploadedFiles(
        orgId,
        patientId,
        caseId,
        fileName,
        fileType,
        version,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const uploadProfilePhoto = createAsyncThunk(
  "XrayService/uploadProfilePhoto",
  async (
    { patientId, formData, fileName }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadProfileImage(orgId, patientId, formData, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadProfilePhoto = createAsyncThunk(
  "XrayService/downloadProfileImage",
  async (
    { patientId, fileName }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadProfileFile(orgId, patientId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteProfilePhoto = createAsyncThunk(
  "XrayService/deleteProfileImage",
  async (
    { patientId, fileName }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await deleteProfileFile(orgId, patientId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const uploadDentalchart = createAsyncThunk(
  "XrayService/uploadDentalChart",
  async (
    { patientId, caseId, formData, fileName }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadDentalChart(
        orgId,
        patientId,
        caseId,
        formData,
        fileName,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadDentalChart = createAsyncThunk(
  "XrayService/downloadDentalChart",
  async (
    { patientId, caseId }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadDentalChartBlob(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const uploadReportpdf = createAsyncThunk(
  "XrayService/uploadReportPDF",
  async (
    { patientId, caseId, formData, fileName }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadReportPDF(orgId, patientId, caseId, formData)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadReportpdf = createAsyncThunk(
  "XrayService/downloadReportPDFBlob",
  async (
    { patientId, caseId }: profileFileParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadReportPDFBlob(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
