import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { UText } from "../../../components"
import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { getCaseById } from "../../../core/app/slices/case"
import { FileItem } from "../../../core/app/slices/records/photograph/photograph.type"
import {
  removeAdditionalPhoto,
  setDownloadStatus,
} from "../../../core/app/slices/records/photograph/photographSlice"
import { downloadPhotographs } from "../../../core/app/slices/records/photograph/photographThunkApi"
import { deleteFiles } from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"
import { getFiles } from "../../../core/utils/commonMethod"

import CompositePhotgraph from "./CompositePhotgraph"
import Individualphotograph from "./IndividualphotoGraph"
import {UDRecord} from "../../../UDTreat/left/UDRecord"
import { PhotosAndXRays } from "@/UDTreat/left/PhotosAndXRaysForCreatingProcess"

const Photograph: FC<{
  setPhotographErrors: (value: boolean) => void
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  setIsDragOpen: (Value: boolean) => void
  isDragOpen: boolean
  title: string
  description?: string
  isRefinement?: boolean
  isShowRecords?: boolean
  postMessageUpdate?: () => void
}> = ({
  setPhotographErrors,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  isDragOpen,
  setIsDragOpen,
  title,
  description,
  isRefinement,
  isShowRecords,
  postMessageUpdate,
}) => {
  const [showUploadSection, setShowUploadSection] = useState<
    "composite" | "individual" | "both"
  >("both")
  const [noPrevRefinementFiles, setNoPrevRefinementFiles] =
    useState<boolean>(false)
  const selectedFileSection = (
    section: "composite" | "individual" | "both",
  ) => {
    setShowUploadSection(section)
  }

  const { patientId, caseId } = useParams()

  const dispatch = useAppDispatch()
  const { fileList, compositePhoto, individualPhotos, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const getPhotographType = (file_name: string) => {
    if (file_name.includes("composite")) {
      return "composite"
    } else if (file_name.includes("individual")) {
      return "individual"
    } else {
      return "others"
    }
  }
  useEffect(() => {
    let downloadTotal = 0
    const downloadFiles = (item: FileItem, totalFilesToDownload: number) => {
      const { file_name, original_file_name, sequence, photo_position } = item
      dispatch(setDownloadStatus("pending"))

      dispatch(
        downloadPhotographs({
          patientId: patientId,
          caseId: caseId,
          fileName: file_name,
          photographType: getPhotographType(file_name),
          original_file_name: original_file_name,
          sequence: sequence,
          position: photo_position,
        }),
      ).then((res) => {
        downloadTotal++
        if (downloadTotal === totalFilesToDownload) {
          dispatch(setDownloadStatus("succeeded"))
        }
      })
    }
    if (fileList.length && showUploadSection === "both") {
      const photoGraphType = []
      let files = []
      let refinementFiles = []
      if (!compositePhoto.length) photoGraphType.push("composite")
      if (!individualPhotos.length) photoGraphType.push("individual")
      if (!additionalPhotos.length) photoGraphType.push("others")
      photoGraphType.map((type) => {
        files = [
          ...files,
          ...getFiles(
            fileList,
            "photographs",
            caseDetail.refinementRound > 0,
            type,
            caseDetail.refinementRound,
          ),
        ]
        refinementFiles = [
          ...refinementFiles,
          ...getFiles(
            fileList,
            "photographs",
            true,
            type,
            caseDetail.refinementRound + 1,
          ),
        ]
      })
      if (!files.length) {
        setNoPrevRefinementFiles(true)
        photoGraphType.map((type) => {
          files = [
            ...files,
            ...getFiles(
              fileList,
              "photographs",
              false,
              type,
              caseDetail.refinementRound,
            ),
          ]
        })
      }
      if (!isRefinement || isShowRecords) {
        files.map((file) => {
          if (
            caseDetail.refinementRound === 0 ||
            noPrevRefinementFiles ||
            file.file_name.includes(
              `${"refinement"}_${caseDetail.refinementRound}`,
            )
          ) {
            downloadFiles(file, files.length)
          }
        })
      } else if (refinementFiles.length) {
        refinementFiles.map((file) =>
          downloadFiles(file, refinementFiles.length),
        )
      }
    }
  }, [fileList, isShowRecords, caseDetail])

  useEffect(() => {
    if (compositePhoto.length) {
      setShowUploadSection("composite")
    } else if (individualPhotos.length) {
      setShowUploadSection("individual")
    }
    if (isRefinement) {
      dispatch(getCaseById({ patientId, caseId }))
    }
  }, [])

  useEffect(() => {
    if (showUploadSection === "individual") {
      additionalPhotos.forEach((file) => {
        dispatch(
          deleteFiles({
            patientId: patientId,
            caseId: caseId,
            fileName: file.fileName,
            fileType: "photographs",
            version: "v2",
          }),
        ).then((action) => {
          if (action.payload.data.status === "Success") {
            const filteredFilesArray = additionalPhotos.filter(
              (item) => item.fileName !== file.fileName,
            )
            const newArray = [...filteredFilesArray]
            dispatch(removeAdditionalPhoto({ newArray }))
          }
        })
      })
    }
  }, [showUploadSection])

  return (
    
    <PhotosAndXRays
      isZoomoutAbled={true}
      isDragAbled={true}
      isDeleteAbled={true}
    />

    // <>
    //   <UText
    //     sxProp={{
    //       display: "flex",
    //       justifyContent: "center",
    //       lineHeight: "133.4%",
    //       // height: 40,
    //     }}
    //     color={"text.primary"}
    //     variant={"h5"}
    //   >
    //     {title}
    //   </UText>
    //   {description && showUploadSection !== "individual" && (
    //     <UText
    //       variant="caption"
    //       sxProp={{
    //         display: "flex",
    //         justifyContent: "center",
    //         color: "rgba(0,0,0,0.60)",
    //         mb: 1,
    //       }}
    //     >
    //       {description}
    //     </UText>
    //   )}
    //   <Grid
    //     container
    //     spacing={3}
    //     justifyContent={isDragOpen ? "unset" : "center"}
    //     ml={0}
    //     mt={!isDragOpen && "8px"}
    //     width={isDragOpen ? "1070px" : "872px"}
    //     minHeight={328}
    //     sx={{ flexWrap: "nowrap" }}
    //   >
    //     {(showUploadSection === "both" ||
    //       showUploadSection === "composite") && (
    //       <Grid
    //         sm={10}
    //         md={6}
    //         item
    //         justifyContent={"center"}
    //         sx={{
    //           padding: "10px !important",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "flex-start",
    //           gap: "10px",
    //           maxWidth: "416px",
    //         }}
    //       >
    //         <CompositePhotgraph
    //           selectedFileSection={selectedFileSection}
    //           isRefinement={isRefinement}
    //         />
    //       </Grid>
    //     )}
    //     {showUploadSection === "both" && (
    //       <Box
    //         display={"flex"}
    //         justifyContent={"center"}
    //         alignItems={"center"}
    //         sx={{ position: "relative", padding: 1 }}
    //       >
    //         <UText
    //           variant={"body1"}
    //           color={"text.primary"}
    //           sxProp={{
    //             mt: 3,
    //             position: "inherit",
    //             top: "-25px",
    //           }}
    //         >
    //           {"OR"}
    //         </UText>
    //       </Box>
    //     )}
    //     {(showUploadSection === "both" ||
    //       showUploadSection === "individual") && (
    //       <Grid
    //         item
    //         justifyContent={"center"}
    //         sx={{
    //           padding: isDragOpen ? "0!important" : "10px !important",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "flex-start",
    //           gap: !isDragOpen && "10px",
    //           maxWidth: isDragOpen ? "1070px" : "416px",
    //         }}
    //       >
    //         <Individualphotograph
    //           selectedFileSection={selectedFileSection}
    //           isDragOpen={isDragOpen}
    //           setIsDragOpen={setIsDragOpen}
    //           setIsDragEmpty={setIsDragEmpty}
    //           isAlertOpen={isAlertOpen}
    //           setIsAlertOpen={setIsAlertOpen}
    //           showUploadSection={showUploadSection}
    //           isRefinement={isRefinement}
    //           postMessageUpdate={postMessageUpdate}
    //         />
    //       </Grid>
    //     )}
    //   </Grid>
    // </>
  )
}

export default Photograph
