import ReactGA4 from "react-ga4"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IAnalyticState {
  category?: "button" | "link" | "checkboxList" | "feedback"
  action?: string
  label: string
}

const initialState = {}

export const analyticSlice = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    triggerGaAnalyticsEvenet: (
      _state,
      action: PayloadAction<IAnalyticState>,
    ) => {
      ReactGA4.event({
        category: action.payload.category || "",
        action: action.payload.action || "",
        label: action.payload.label,
      })
    },
  },
})

export const { triggerGaAnalyticsEvenet } = analyticSlice.actions

export default analyticSlice.reducer
