import { FC, useEffect, useState } from "react"
import { Box } from "@mui/material"
import {
  caseManagement,
  orderView,
  viewControlInTreatment,
  wasmModule,
} from "@/gluelayer"
import { isEmpty } from "lodash"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { updateCustomization } from "../../../core/app/slices/order/orderSlice"
import { RootState } from "../../../core/app/store"
import { ULoading, UText } from "../../../ui-component"

const Scanned: FC<{
  isUpper: boolean
  isLower: boolean
  setzoomValue: (arg: number) => void
  zoomValue
  wasmReady: boolean
}> = ({ isUpper, isLower, setzoomValue, zoomValue, wasmReady }) => {
  const { zipList } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const dispatch = useAppDispatch()

  const {
    alignerOrderPayload: { scanned },
  } = useAppSelector((state: RootState) => state.orderService)
  useEffect(() => {
    return () => {
      if (!wasmReady) return
      const toothId = orderView.getTeethSelected()
      dispatch(
        updateCustomization({
          scanned: toothId,
        }),
      )
      //orderView.saveScanButtonsInfoToElasticJson(toothId)
      const jsonObj = orderView.getElasticJson(toothId)
      caseManagement.saveJsonDataToZip(
        jsonObj,
        "elasticControlPoints.json",
        "Setting Data2",
      )
    }
  }, [])

  useEffect(() => {
    if (!wasmReady) return
    orderView.initAndClearOrderView([36 / 255, 46 / 255, 51 / 255])
    viewControlInTreatment.setViewType("front")
    viewControlInTreatment.showArchMode("all")
    !isEmpty(scanned) && orderView.setDefaultTeethSelected(scanned)

    caseManagement.setBackgroundColor(36 / 255, 46 / 255, 51 / 255)
  }, [wasmReady])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.zoomWithValueInTreatment(zoomValue)
  }, [zoomValue, wasmReady])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.showArchMode(
      isUpper ? "up" : isLower ? "low" : "all",
    )
  }, [isUpper, isLower, wasmReady])

  return (
    <>
      <ULoading isLoading={!wasmReady} />
      <Box sx={{ marginBottom: 2, textAlign: "center" }}>
        <UText variant={"caption"}>
          Select tooth/teeth if have previously scanned precision aligner
          buttons
        </UText>
      </Box>
    </>
  )
}
export default Scanned
