import { UASSIST_PROGRESS_STATUS } from "../../modules/Patient/config/menuAction.config"
import { CASE_STATUS } from "../../modules/Patient/config/status.config"

import Deserialize from "./interface/Deserialize"
import { ICase } from "./interface/ICASE"

// Temporary hard-coded values will be removed after API fix
export default class Case implements ICase, Deserialize {
  id = undefined
  status = undefined
  upper_retainer_stage = ""
  lower_retainer_stage = ""
  uuid = ""
  number? = undefined
  description = ""
  createDate = ""
  udesign_json = ""
  phase = undefined
  custom_category = ""
  treatment_plan = ""
  prescription_notes = ""
  planned_upper = ""
  planned_lower = ""
  caseDisposition = ""
  updateDate = ""
  submittedDate = ""
  txplanName = ""
  txplanSubname = ""
  assigned_to = ""
  org_name = ""
  create_org_id = undefined
  orderDate = ""
  remainingAlignerOrder = undefined
  remainingRetainerOrder = undefined
  refinementRound = 0
  dental_chart_data = ""
  udesign_category = ""
  uassist_progress = ""
  isInitialuAssist = false
  isRefinementReady = false
  objtype = "CASE"
  treatmentPlanName = ""
  caseArch = "both"
  upperType = "--"
  lowerType = "--"
  Deserialize(input: any): this {
    if (input) {
      this.id = input.id
      this.status = input.status
      if (input.upper_retainer_stage) {
        this.upper_retainer_stage = input.upper_retainer_stage
      }
      if (input.lower_retainer_stage) {
        this.lower_retainer_stage = input.lower_retainer_stage
      }
      this.uuid = input.uuid
      this.description = input.description
      this.treatmentPlanName = input.treatment_plan_name
      this.createDate = input.create_date
      this.udesign_json = input.udesign_json
      this.phase = input.phase
      this.custom_category = input.custom_category
      this.treatment_plan = input.treatment_plan
      this.prescription_notes = input.prescription_notes
      this.planned_upper = input.planned_upper
      this.planned_lower = input.planned_lower
      this.uassist_progress = input.uassist_progress
      this.caseDisposition = input.case_disposition
        ? input.case_disposition
        : CASE_STATUS.FROM_UDESIGN
      if (
        (input.uassist_progress && !input.case_disposition) ||
        (input.case_disposition &&
          UASSIST_PROGRESS_STATUS.includes(input.case_disposition))
      ) {
        if (["READY"].includes(input.uassist_progress)) {
          this.isInitialuAssist = true
        }
        if (["REFINEMENT_READY"].includes(input.uassist_progress)) {
          this.isRefinementReady = true
        }
      }
      this.org_name = input.org_name
      this.create_org_id = input.create_org_id
      this.assigned_to = input.assigned_to
      this.txplanSubname = input.txplan_subname
      this.txplanName = input.txplan_name
      this.updateDate = input.update_date
      this.submittedDate = input.submitted_date
      this.orderDate = input.order_date
      this.remainingAlignerOrder = input.remaining_order.aligner
      this.remainingRetainerOrder = input.remaining_order.retainer
      this.refinementRound = input.refinement_round
      this.dental_chart_data = input.dental_chart_data
      this.udesign_category = input.udesign_category
      if (input.udesign_category) {
        const [UpperType, LowerType] = input.udesign_category.split("/")
        this.upperType = UpperType
        this.lowerType = LowerType
        if (UpperType === "--") {
          this.caseArch = "lower"
        } else if (LowerType === "--") {
          this.caseArch = "upper"
        } else {
          this.caseArch = "both"
        }
      } else {
        this.caseArch = "both"
      }
      return this
    }
  }
}
