import React, { FC, useEffect, useState } from "react"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import LastPageIcon from "@mui/icons-material/LastPage"
import { v4 } from "uuid"

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { formatCurrencyWithDecimals } from "../../../core/utils/formatters"
import { UText } from "../../../ui-component"
import { getCreditVal } from "../../Patient/PatientList/util/commonUtil"

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}
interface TableHeader {
  name: string
  id: string
  dataKey: string
  isCurrency?: boolean
  isRightAlign?: boolean
}
export const BillingTable: FC<{
  tableHeader: TableHeader[]
  rows: any[]
  currencyCode: string
  showPagination?: boolean
  extraRows?: { val: string; span: number }[]
  isCreditTable?: boolean
}> = ({
  tableHeader,
  rows,
  currencyCode,
  showPagination,
  extraRows,
  isCreditTable,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc")
  const [sortCol, setSortCol] = useState("")
  const [tableRows, setTableRows] = useState(rows)
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const cellStyle = {
    maxWidth: 250,
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSortClick = (item: TableHeader) => {
    if (!sortCol || sortCol !== item.dataKey) {
      setSortDir("asc")
      setSortCol(item.dataKey)
    } else if (sortDir === "desc") {
      setSortCol("")
      setSortDir("asc")
    } else {
      setSortDir(sortDir === "asc" ? "desc" : "asc")
      setSortCol(item.dataKey)
    }
  }

  const sortObjectsArray = () => {
    const sortedArr = tableRows.slice().sort((a, b) => {
      let valueA = a[sortCol]
      let valueB = b[sortCol]
      if (typeof valueA === "string" && !isNaN(Date.parse(valueA))) {
        valueA = new Date(valueA)
      }
      if (typeof valueB === "string" && !isNaN(Date.parse(valueB))) {
        valueB = new Date(valueB)
      }

      if (typeof valueA === "string") valueA = valueA.toLowerCase()
      if (typeof valueB === "string") valueB = valueB.toLowerCase()

      if (valueA < valueB) {
        return sortDir === "asc" ? -1 : 1
      }
      if (valueA > valueB) {
        return sortDir === "asc" ? 1 : -1
      }
      return 0
    })

    setTableRows(sortedArr)
  }
  useEffect(() => {
    if (sortCol) {
      sortObjectsArray()
    }
  }, [sortCol, sortDir])

  const getCellStyle = (dataKey: string, isCurrency: boolean) => {
    const baseStyle = {
      color: "#333",
      //isCurrency?"right":"left" - - can be used when right align needed for currency value
      textAlign: "left",
    }

    return dataKey === "desc" ? { ...cellStyle, ...baseStyle } : baseStyle
  }

  function renderCellData(row, column, isCreditTable: boolean) {
    if (column.isCurrency) {
      if (isCreditTable) {
        return getCreditVal(formatCurrencyWithDecimals(row[column.dataKey]))
      } else {
        return formatCurrencyWithDecimals(row[column.dataKey])
      }
    } else {
      return row[column.dataKey]
    }
  }

  return (
    <TableContainer component={Paper} sx={{ mb: 2, boxShadow: "none" }}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow key={"header"}>
            {tableHeader.map((item) => (
              <TableCell
                key={item.id}
                component="th"
                scope="row"
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  color: "text.primary",
                  //item.isCurrency || item.isRightAlign ? "left" : "left"
                  textAlign: "left",
                }}
              >
                <TableSortLabel
                  sx={{
                    "&.Mui-active": {
                      color: "primary.main",
                      ">.MuiTableSortLabel-icon": {
                        color: "primary.main",
                      },
                    },
                    "&:hover": {
                      color: "primary.main",
                      ">.MuiTableSortLabel-icon": {
                        color: "primary.main",
                      },
                    },
                  }}
                  active={item.dataKey === sortCol}
                  direction={sortDir}
                  onClick={() => handleSortClick(item)}
                >
                  {item.name}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {countryCode &&
            (rowsPerPage > 0
              ? tableRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : tableRows
            ).map((row, i) => (
              <TableRow key={`${row.order_number}-${v4()}`}>
                {tableHeader.map((column) => (
                  <TableCell
                    key={`${row[column.dataKey]}-${row[column.id]}`}
                    component="th"
                    scope="row"
                    sx={getCellStyle(
                      column.dataKey,
                      column?.isCurrency || column.isRightAlign,
                    )}
                  >
                    {renderCellData(row, column, isCreditTable)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {extraRows && rows?.length > 0 ? (
            <TableRow>
              {extraRows.map((col, i) => (
                <TableCell
                  key={`${col.val}-${i}`}
                  colSpan={col.span}
                  sx={{
                    fontWeight: 600,
                    // col.val === "Total" ? "left" : "right" - can be used when right align needed for currency value
                    textAlign: "left",
                    borderBottom:
                      showPagination && rows?.length > 5
                        ? "1px solid #E0E0E0"
                        : "none !important",
                  }}
                >
                  {col.val}
                </TableCell>
              ))}
            </TableRow>
          ) : null}

          {rows.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={12}
                sx={{ textAlign: "center", borderBottom: "none" }}
              >
                <UText
                  variant={"body2"}
                  component={"div"}
                  sxProp={{ color: "#333" }}
                >
                  {"No data available"}
                </UText>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {showPagination && tableRows?.length > 5 && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={12} sx={{ p: 0, pr: 5 }}>
                <TablePagination
                  sx={{
                    ".MuiTablePagination-toolbar": {
                      display: "flex",
                      justifyContent: "flex-end",
                    },
                    ".MuiTablePagination-selectLabel": {
                      flexBasis: "16% !important",
                    },
                    ".MuiTablePagination-select": {
                      textAlignLast: "left !important",
                    },
                  }}
                  component="div"
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
