import { FC, ReactElement } from "react"

import { Tooltip } from "../../../components/mui.components"

const UTooltip: FC<{
  titleContent: ReactElement | string
  children: ReactElement
}> = ({ titleContent, children }) => {
  return (
    <Tooltip
      title={titleContent}
      placement={"top"}
      arrow
      slotProps={{
        tooltip: {
          style: {
            padding: 0,
            marginBottom: "7px",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default UTooltip
