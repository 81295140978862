import React, { FC, ReactElement } from "react"

import { Alert, Snackbar, SxProps } from "../../mui.components"

export interface IAlertProps {
  alertContent: ReactElement | string
  alertProps?: SxProps
  showAlert: boolean
  verticalPosition?: "top" | "bottom"
  horizontalPosition?: "center" | "left" | "right"
  onClickHandler: () => void
  variant: "filled" | "outlined"
  severity: "error" | "warning" | "info" | "success"
}

const UAlert: FC<IAlertProps> = ({
  alertContent,
  alertProps,
  showAlert,
  onClickHandler,
  variant,
  severity,
  verticalPosition,
  horizontalPosition,
}) => {
  const anchorOrigin = {
    vertical: verticalPosition || "bottom",
    horizontal: horizontalPosition || "left",
  }

  return (
    <>
      <Snackbar
        open={showAlert}
        anchorOrigin={anchorOrigin}
        autoHideDuration={10000}
        onClose={() => {
          onClickHandler()
        }}
      >
        <Alert
          sx={{
            width: "fit-content",
            alignItems: "center",
            "& .MuiAlert-action": {
              paddingTop: "0px !important",
              alignSelf: "center",
            },
          }}
          variant={variant}
          severity={severity}
          onClose={() => {
            onClickHandler()
          }}
        >
          {alertContent}
        </Alert>
      </Snackbar>
    </>
  )
}

export default UAlert
