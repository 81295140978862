import { FC } from "react"
import { useTranslation } from "react-i18next"

import { Box, Divider } from "../../../components/mui.components"
import {
  INewPatientData,
  IPatient,
} from "../../../core/model/interface/IPatient"
import { UText } from "../../../ui-component/components"

const PatientTooltipContent: FC<{
  patientData: INewPatientData | IPatient
}> = ({ patientData }) => {
  const { t } = useTranslation("common")
  return (
    patientData && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          maxWidth: "240px",
          padding: "4px 8px",
          paddingBottom: patientData.notes ? "8px" : "",
        }}
      >
        <UText variant={"body1"}>
          {patientData.firstName + " " + patientData.lastName}
        </UText>
        {patientData.notes && (
          <Box>
            <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.4)" }} />
            <UText
              sxProp={{
                marginRight: "3px",
                fontSize: "10px",
                fontWeight: 700,
                lineHeight: "140%",
              }}
              variant={"body1"}
            >
              {t("records.tooltipNotesLabel")}
            </UText>
            <UText
              sxProp={{
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "140%",
              }}
              variant={"subtitle1"}
            >
              {patientData.notes}
            </UText>
          </Box>
        )}
      </Box>
    )
  )
}

export default PatientTooltipContent
