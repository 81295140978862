import { FC, useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import { Box, IconButton, SxProps } from "@mui/material"

export interface IMenuIconButtonProps {
  onClickHandler: (event) => void
  sxProps?: SxProps
  id: number
  isSelected: boolean
}

const MenuIconButton: FC<IMenuIconButtonProps> = ({
  onClickHandler,
  sxProps,
  id,
  isSelected,
}) => {
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null)
  const open = Boolean(menuOpen)
  return (
    <Box
      component={"div"}
      sx={{ display: "flex", justifyContent: "center", ...sxProps }}
    >
      <IconButton
        data-testId={"menuIconBtn"}
        onClick={(event) => {
          setMenuOpen(event.currentTarget)
          onClickHandler(event)
        }}
        id={`${id}-button`}
        aria-controls={open ? `${id}-content` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          p: 0.5,
          backgroundColor: isSelected ? "divider" : "transparent",
          "&:hover": {
            backgroundColor: "divider",
          },
        }}
      >
        <MenuIcon
          sx={{
            width: "24px",
            height: "24px",
          }}
        />
      </IconButton>
    </Box>
  )
}

export default MenuIconButton
