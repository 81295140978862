import { FC } from "react"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

import EditPatientInfoForm from "./components/EditPatientInfoForm"

const UpdatePatient: FC = () => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  return <EditPatientInfoForm patientData={patientData} />
}

export default UpdatePatient
