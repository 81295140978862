import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useState, useEffect } from "react"
import PlusIcon from "@mui/icons-material/Add"
import MinusIcon from "@mui/icons-material/Remove"
import { preview, viewControlInTreatment, wasmModule } from "@/gluelayer"
import { useTheme } from "@mui/material/styles"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

export const ZoomBar = () => {
  const theme = useTheme()
  const options = [100, 200, 400]
  const [value, setValue] = useState(options[0])

  const { hasUploadSTLs, curCaseMode } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  useEffect(() => {
    if (preview.isInitPreview()) {
      preview.zoomWithValue(value / 100)
    }
    if (!wasmModule.isInit) return
    if (!value) return
    viewControlInTreatment.zoomWithValueInTreatment(value / 100)
  }, [value])
  const handleSelectChange = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("wasm is init successful")
    viewControlInTreatment.setZoomCallbackInTreatment((curZoomVal) => {
      //   console.log("cur zoom value", Math.round(curZoomVal * 100))
      setValue(Math.round(curZoomVal * 100))
    })
  }, [wasmModule.isInit])

  useEffect(() => {
    if (!wasmModule.isInit && preview.isInitPreview()) {
      preview.setZoomCallback((curZoomVal) => {
        setValue(Math.round(curZoomVal * 100))
      })
    }
  }, [hasUploadSTLs])

  const handlePlusOrMin = (num) => {
    let newZoomValue = value + num
    newZoomValue = Math.min(Math.max(newZoomValue, 25), 400)
    setValue(newZoomValue)
  }

  const checkShowZoomBar = () => {
    if (curCaseMode === "NewCase") {
      if (hasUploadSTLs[0] || hasUploadSTLs[1]) return true
      else return false
    } else return true
  }
  return (
    checkShowZoomBar() && (
      <Stack
        sx={{
          height: 48,
          borderRadius: 2,
          backgroundColor: theme.transBkColor.light,
          boxShadow: 3,
        }}
        direction={"row"}
      >
        <IconButton onClick={() => handlePlusOrMin(-10)}>
          <MinusIcon />
        </IconButton>
        <FormControl
          variant="outlined"
          sx={{
            m: 1,
            minWidth: 100,
            position: "relative", // 添加相对定位
          }}
        >
          <Select
            sx={{ height: 35 }}
            value={""}
            label=""
            onChange={handleSelectChange}
            //   displayEmpty
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option + "%"}
              </MenuItem>
            ))}
          </Select>
          <Typography
            sx={{
              position: "absolute",
              height: 20,
              top: 5,
              left: 15,
            }}
          >
            {value + "%"}
          </Typography>
        </FormControl>
        <IconButton onClick={() => handlePlusOrMin(10)}>
          <PlusIcon />
        </IconButton>
      </Stack>
    )
  )
}
