import { wasmModule } from "../wasm/wasmModule";
import {
  EInitialfinalEvent,
  initialfinalModule,
} from "../initialfinal/initialfinalmodule";
import { GlobalEvents, globalEvents } from "../../../utils/globalevents";
import { HoverToothData } from "../../typesofinterface";
import { EArchType } from "../../common";

export enum EToothColorType {
  Normal,
  Implant,
  Fix,
  Pontic,
  Bridge,
  Crown,
  Primary,
}

/**
 * check is in split screen status
 * UDesignModuleManager::IsSplitScreenOpened
 */

class DisplayModule {
  private _showType: "left" | "right" | "front" | "back" | "split" = "front";
  private _showMode: "up" | "low" | "all" = "all";
  private _isSpliteMode = false;
  private _zoomRange: [number, number] = [0.25, 2.0];
  private _zoomCb?: (val: number) => void;

  private _overlayStatus = false;

  private hoverToothcallback: (hoverData: HoverToothData) => void;

  setHoverToothcallback(callback) {
    this.hoverToothcallback = callback;
  }

  get zoomCallback() {
    return this._zoomCb;
  }
  getShowType() {
    return this._showMode;
  }

  setShowType(type: "left" | "right" | "front" | "back" | "split") {
    this._showType = type;
    this._checkIsInSplitMode();
    if (this.zoomCallback) {
      this.zoomCallback(1.0);
    }
    globalEvents.fire(GlobalEvents.VIEW_SWITCH_TYPE, type);
  }

  private _checkIsInSplitMode() {
    const splitScreenModule = wasmModule.moduleManager.GetSplitScreenModule();
    if (!splitScreenModule) {
      this._isSpliteMode = false;
      return;
    }

    this._isSpliteMode = true;
    wasmModule.moduleManager
      .GetSplitScreenModule()
      .SetMinScaleValue(this._zoomRange[0]);
    wasmModule.moduleManager
      .GetSplitScreenModule()
      .SetMaxScaleValue(this._zoomRange[1]);
    if (!(window as any).treatment) {
      (window as any).treatment = {};
    }
    (window as any).treatment.viewZoomCallbackInside = this._zoomCb;
    wasmModule.moduleManager
      .GetSplitScreenModule()
      .SetUpdateSliderCB("treatment.viewZoomCallbackInside");
  }

  /**
   * set callback which emited on zoom in WASM
   * @param cb
   */
  setZoomCallback(range: [number, number], cb: (val: number) => void) {
    if (!wasmModule.isWASMInit) {
      return;
    }

    wasmModule.moduleManager.SwitchMainWindowModule(true);

    // uninside
    wasmModule.moduleManager.GetMainWindowModule().SetMinScaleValue(range[0]);
    wasmModule.moduleManager.GetMainWindowModule().SetMaxScaleValue(range[1]);

    this._zoomRange = range;
    if (cb) {
      this._zoomCb = cb;
    }

    if (!(window as any).treatment) {
      (window as any).treatment = {};
    }
    (window as any).treatment.viewZoomCallback = cb;
    wasmModule.moduleManager
      .GetMainWindowModule()
      .SetUpdateSliderCB("treatment.viewZoomCallback");
  }

  /**
   * invoted from FE to zoom
   * @param val the value of scale
   * @returns
   */
  zoomWithValue(scale: number) {
    if (
      !wasmModule.isWASMInit ||
      !wasmModule.moduleManager.GetSplitScreenModule
    ) {
      return;
    }

    wasmModule.moduleManager.SwitchMainWindowModule(true);

    if (this._isSpliteMode === true) {
      wasmModule.moduleManager.GetSplitScreenModule().ZoomAutomatic(scale);
    } else {
      wasmModule.moduleManager.GetMainWindowModule().ZoomAutomatic(scale);
    }
  }

  /**
   * SetToRightBuccalView
   */
  setToRightBuccalView() {
    wasmModule.statusController.SetToRightBuccalView();
  }

  /**
   * SetToAnteriorView
   */
  setToAnteriorView() {
    wasmModule.statusController.SetToAnteriorView();
  }

  /**
   * SetToLeftBuccalView
   */
  setToLeftBuccalView() {
    wasmModule.statusController.SetToLeftBuccalView();
  }

  /**
   * SplitScreenDisplay
   * @param isOn open or close
   */
  splitScreenDisplay(isOn: boolean) {
    if (isOn !== wasmModule.moduleManager.IsSplitScreenOpened()) {
      wasmModule.statusController.SplitScreenDisplay(isOn);
      if (this.zoomCallback) {
        this.zoomCallback(1.0);
      }
    }
  }

  /**
   * set show arch mode ("up" | "low" | "all")
   * @param mode
   */
  showArchMode(mode: "up" | "low" | "all") {
    displayModule.splitScreenDisplay(false);
    if (mode === "up") displayModule._showArchByIndex(0);
    else if (mode === "low") displayModule._showArchByIndex(1);
    else displayModule._showArchByIndex(2);
  }

  /**
   * show upper or lower arch
   * @param arch 0:upper  1:lower
   */
  private _showArchByIndex(arch: number) {
    if (arch === 0) {
      if (wasmModule.getArchModel(EArchType.UpArch)) {
        wasmModule.statusController.DisplayUpperArch();
        this._showMode = "up";
      }
    } else if (arch === 1) {
      if (wasmModule.getArchModel(EArchType.LowArch)) {
        wasmModule.statusController.DisplayLowerArch();
        this._showMode = "low";
      }
    } else {
      if (
        wasmModule.getArchModel(EArchType.UpArch) &&
        wasmModule.getArchModel(EArchType.LowArch)
      ) {
        wasmModule.statusController.DisplayBothArch();
        this._showMode = "all";
      }
    }
    this._checkIsInSplitMode();
  }

  switchGum(isShowNewGum: boolean) {
    wasmModule.statusController.SwitchGum(isShowNewGum);
  }

  switchOverlayModule(isOpen: boolean) {
    if (!wasmModule.isInit) return;
    wasmModule.moduleManager.SwitchModuleOverlay(isOpen);
    this._overlayStatus = isOpen;
  }

  opacityOverlay(opacity: number) {
    if (!wasmModule.isInit) return;
    const overlayModule = wasmModule.moduleManager.GetModuleOverlay();
    if (overlayModule) {
      overlayModule.AdjustOpacityForCutOtherArch(opacity);
    } else {
      console.log("overlayModule not find!!!");
    }
  }

  setSpecialToothColor(toothtype: EToothColorType, r, g, b) {
    if (!wasmModule.isInit) return;

    wasmModule.statusController.SetToothTypeColor(toothtype, r, g, b);
  }

  setHoverToothToggle(isHover: boolean) {
    if (!wasmModule.isInit) return;

    wasmModule.moduleManager.SwitchModulePropOnHover(isHover);

    if (!isHover) this.hoverToothProperties(-1, 0, [0, 0], "");
  }

  /**
   * 接收到hover回调后，获取牙齿的属性
   * @param toothid
   * @param archType
   * @param hoverPos
   */
  hoverToothProperties = (
    toothid: number,
    archType: number,
    hoverPos: [number, number],
    hoverstr: string //wasm中默认显示的hover属性，为空的话就从js取
  ) => {
    const toothModule = wasmModule.wrapInstance.GetMoveToothModule();
    if (!toothModule) return;
    const properties = {
      isCrown: toothModule.GetToothCrownStatus(toothid),
      isImplant: toothModule.GetToothImplantStatus(toothid),
      isBridge: toothModule.GetToothBridgeStatus(toothid),
      isPontic: toothModule.GetToothPonticStatus(toothid),
      isPrimary: toothModule.GetToothPrimaryStatus(toothid),
      isErupting: toothModule.GetToothEruptStatus(toothid),
    };

    const hoverString =
      hoverstr === "" || hoverstr === undefined || hoverstr === null
        ? this.findTrueProperty(properties)
        : hoverstr;

    const hoverData: HoverToothData = {
      isShow: toothid > 0 && hoverString !== "",
      toothid: toothid,
      isUpper: archType === 0,
      position: hoverPos,
      properties,
      hoverString,
    };

    if (this.hoverToothcallback) this.hoverToothcallback(hoverData);
  };

  findTrueProperty = properties => {
    for (const property in properties) {
      if (properties[property] === true) {
        return property.substring(2); // 去掉前缀 "is"
      }
    }
    return "";
  };
}

export const displayModule = new DisplayModule();
initialfinalModule.event.on(EInitialfinalEvent.CLOSED, () => {
  displayModule.zoomWithValue(1.0);
  if (displayModule.zoomCallback) {
    displayModule.zoomCallback(1.0);
  }
});
