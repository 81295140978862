import { createAsyncThunk } from "@reduxjs/toolkit"

import { OrgQueryparams } from "../patients/patient.types"

import { getOrganizationList } from "./organization.service"

export const fetchOrganizationList = createAsyncThunk(
  "organizationService/fetchOrganizationList",
  async (
    { orgQueryparams }: { orgQueryparams: OrgQueryparams },
    { rejectWithValue, getState },
  ) => {
    try {
      return await getOrganizationList(orgQueryparams)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
