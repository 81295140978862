import { FC, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { UText } from "../../../../../components"
import { Box, Chip, Grid, Link } from "../../../../../components/mui.components"
import UTooltip from "../../../../../containers/LandingLayout/PatientInfoHeader/UTootip"
import { useAppDispatch } from "../../../../../core/app/hooks"
import { updateCurrentOrgId } from "../../../../../core/app/slices/user/userSlice"
import { UI_PERMISSION } from "../../../../../core/utils/access/permissionConstants"
import { dateFormat } from "../../../../../core/utils/formatters"
import { useMyNavigation } from "../../../../../hooks/useMyNavigation"
import usePermissions from "../../../../../hooks/userPermission"
import CustomImagePreview from "../../../../Settings/CustomPackaging/component/imagePreview"
import { PACKAGING_STATUS } from "../../../../Settings/CustomPackaging/config"
import { CASE_STATUS, STATUS } from "../../../config/status.config"
import ProgressBar from "../../../Overview/components/ProgressBar"
import { getAlignerRange } from "../../../utils"

import AssignedUser from "./AssignedUser"
import StatusButton from "./StatusButton"
import {
  addRetainerStage,
  addTemplateStage,
  BUNDLE_TABLE_COLUMNS,
  calculateMixedValue,
  CASE_TABLE_COLUMNS,
  CUSTOMPACAKGE_TABLE_COLUMNS,
  ORDER_TABLE_COLUMNS,
  ORDER_TYPE,
  SHOW_AS_LINK_STATUS,
} from "./UTableCellRendererUtility"

const UTableCellRenderer: FC<{ dataKey: string; row: any; width?: number }> = ({
  dataKey,
  row,
  width,
}) => {
  const { hasAny, doesNotHaveAny } = usePermissions()
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { patientId } = useParams()
  const { t } = useTranslation("common")
  const data = row[dataKey]

  const renderDate = () => {
    return (
      <UText
        sxProp={{
          display: "block",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant={"body2"}
      >
        {dateFormat(data, "MM/DD/YYYY", "YYYY-MM-DDTh:mm:ss")}
      </UText>
    )
  }

  const renderDateWithTime = () => {
    return (
      <UText
        sxProp={{
          display: "block",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant={"body2"}
      >
        {dateFormat(data, "M/D/YYYY, h:mm A", "YYYY-MM-DDTh:mm:ss")}
      </UText>
    )
  }

  const renderStageTemplate = (
    type: "U" | "L",
    stageRange: string,
    template: string,
  ) => {
    const stageRangeParts = stageRange.split(",")
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <UText variant={"body2"}>{type}</UText>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "50%",
            ml: type === "U" ? 1 : 1.5,
          }}
        >
          <Box>
            {stageRangeParts.map((part) => (
              <Box key={uuidv4()}>
                <UText variant={"body2"}>{`${part.trim()}`}</UText>
              </Box>
            ))}
          </Box>
          <Box>
            <UText variant={"body2"}>{template}</UText>
          </Box>
        </Box>
      </Box>
    )
  }

  const renderUpperLowerVal = (range: string, orderType: string) => {
    return (
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "70%",
          ml: 2,
        }}
      >
        <Grid item xs={9}>
          <UText variant={"body2"}>{range}</UText>
        </Grid>
        <Grid item xs={3}>
          <UText variant={"body2"}>{orderType}</UText>
        </Grid>
      </Grid>
    )
  }

  const renderPrintedandOrdered = () => {
    let upperMax = 0
    let LowerMax = 0

    if (row.udesign_json) {
      const res = JSON.parse(row?.udesign_json)
      LowerMax = res.LowerMaxStageNum || 0
      upperMax = res.UpperMaxStageNum || 0
    }

    const orderArr = []
    if (row?.order_metric?.length) {
      const item = row.order_metric
      item.map((val) => {
        const temparr = {
          upperR: val.print_ordered_upper_retainer ? "R" : "",
          lowerR: val.print_ordered_lower_retainer ? "R" : "",
          upperT: val.print_ordered_upper_template ? "T" : "",
          lowerT: val.print_ordered_lower_template ? "T" : "",
          upperRange: val.upper_print_ordered_stage_range_count,
          lowerRange: val.lower_print_ordered_stage_range_count,
        }
        orderArr.push(temparr)
      })
    }

    return (
      <Grid container direction="column" flexWrap={"nowrap"}>
        {orderArr.map((orderItem, index) => (
          <Box key={`${row?.id} ${index}`}>
            <Grid item sx={{ mb: 1, mt: index === 0 ? null : 2 }}>
              {renderUpperLowerVal(
                `U${"\u00A0\u00A0\u00A0"}` +
                  orderItem.upperRange +
                  "/" +
                  upperMax,
                orderItem.upperR +
                  (orderItem.upperR && orderItem.upperT ? ", " : "") +
                  orderItem.upperT,
              )}
            </Grid>
            <Grid item>
              {renderUpperLowerVal(
                `L${"\u00A0\u00A0\u00A0\u00A0"}` +
                  orderItem.lowerRange +
                  "/" +
                  LowerMax,
                orderItem.lowerR +
                  (orderItem.lowerR && orderItem.lowerT ? ", " : "") +
                  orderItem.lowerT,
              )}
            </Grid>
          </Box>
        ))}
      </Grid>
    )
  }

  const renderOrderDetails = () => {
    let upperStageValue = ""
    let lowerStageValue = ""
    let upperRT = ""
    let lowerRT = ""
    if (
      row.upperStageRange ||
      row.upperRetainerStage ||
      row.upperTemplateStage
    ) {
      if (row.upperStageRange) {
        upperStageValue = calculateMixedValue(row.upperStageRange)
      }
      upperRT = addTemplateStage(row.upperTemplateStage, upperRT)
      upperRT = addRetainerStage(row.upperRetainerStage, upperRT)

      upperRT = upperRT?.replace(/,\s$/g, "")
    }

    if (
      row.lowerStageRange ||
      row.lowerRetainerStage ||
      row.lowerTemplateStage
    ) {
      if (row.lowerStageRange) {
        lowerStageValue = calculateMixedValue(row.lowerStageRange)
      }
      lowerRT = addTemplateStage(row.lowerTemplateStage, lowerRT)
      lowerRT = addRetainerStage(row.lowerRetainerStage, lowerRT)

      lowerRT = lowerRT?.replace(/,\s$/g, "")
    }

    return (
      <Grid container direction="column">
        <Grid item>{renderStageTemplate("U", upperStageValue, upperRT)}</Grid>
        <Grid item>{renderStageTemplate("L", lowerStageValue, lowerRT)}</Grid>
      </Grid>
    )
  }

  const renderOrderStatus = () => {
    return (
      <>
        {row.caseDisposition && (
          <Box sx={{ marginBottom: "3px" }}>
            <StatusButton {...row.order} caseStatus={row.caseDisposition} />
          </Box>
        )}
        {row.updateDate && (
          <UText variant="caption" sxProp={{ color: "text.secondary", ml: 1 }}>
            {dateFormat(row.updateDate, "MM/DD/YYYY", "YYYY-MM-DDTh:mm:ss")}
          </UText>
        )}
      </>
    )
  }

  const renderShippingTrackingNo = () => {
    return (
      <>
        {row.shipmentTrackingNo ? (
          <Link
            sx={{
              display: "block",
              width: "118px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            target={"_blank"}
            onClick={(event) => event.stopPropagation()}
            href={`https://www.fedex.com/fedextrack/?trknbr=${row.shipmentTrackingNo}`}
          >
            {row.shipmentTrackingNo}
          </Link>
        ) : (
          <>
            {row.estimationShipDate && (
              <>
                <UText
                  variant="body2"
                  component={"div"}
                  sxProp={{ color: "text.primary", wordBreak: "break-word" }}
                >
                  {dateFormat(
                    row.estimationShipDate,
                    "MM/DD/YYYY",
                    "YYYY-MM-DDTh:mm:ss",
                  )}
                </UText>
              </>
            )}
          </>
        )}
      </>
    )
  }

  const renderTxPlan = () => {
    return (
      <Box>
        <UText
          sxProp={{ wordBreak: "break-word" }}
          variant="body2"
          component={"div"}
        >
          {row.txplanName}
        </UText>
        {row.treatmentPlanName && (
          <UText
            variant="caption"
            sxProp={{ color: "text.secondary", wordBreak: "break-word" }}
          >
            {row.treatmentPlanName}
          </UText>
        )}
      </Box>
    )
  }
  const textContainer = useRef(null)
  const container = textContainer.current

  const renderText = (value?: string, isWrappable?: boolean) => {
    return (
      <UTooltip
        titleContent={
          container && container.scrollWidth > container.clientWidth ? (
            <UText
              sxProp={{
                p: 1,
              }}
              variant="body2"
            >
              {value || data}
            </UText>
          ) : null
        }
      >
        <UText
          ref={textContainer}
          sxProp={{
            display: "block",
            width: width ? width - 32 : "100%",
            whiteSpace: isWrappable ? "pre-wrap" : "nowrap",
            wordBreak: isWrappable ? "break-word" : "keep-all",
            overflow: isWrappable ? "" : "hidden",
            textOverflow: isWrappable ? "" : "ellipsis",
          }}
          variant="body2"
        >
          {value || data}
        </UText>
      </UTooltip>
    )
  }

  const renderStatus = (
    status: string,
    date?: string,
    trackingNo?: string,
    showAsLink = false,
  ) => {
    return (
      <Box>
        <Box>
          <>
            {showAsLink ? (
              <Link
                sx={{
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  navigate(
                    `/clinical/patient/${patientId}/case/${row.id}/treat`,
                  )
                }}
              >
                {status}
              </Link>
            ) : (
              <UText sxProp={{ wordBreak: "break-word" }} variant="body2">
                {status}
              </UText>
            )}
            {trackingNo && (
              <>
                {" - "}
                <Link
                  target={"_blank"}
                  onClick={(event) => event.stopPropagation()}
                  href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNo}`}
                >
                  {trackingNo}
                </Link>
              </>
            )}
          </>
        </Box>
        {date && (
          <UText variant="caption" sxProp={{ color: "text.secondary" }}>
            {dateFormat(date, "MM/DD/YYYY", "YYYY-MM-DDTh:mm:ss")}
          </UText>
        )}
      </Box>
    )
  }

  const renderProgressBar = (colorCode: string, bundle: any) => {
    return (
      <Grid container gap={1}>
        <ProgressBar
          colorCode={colorCode}
          total={bundle.total}
          completed={bundle.completed}
        />
      </Grid>
    )
  }

  const renderCaseTableData = (key: string) => {
    const alignerOrder = row.remainingAlignerOrder
    switch (key) {
      case "casePlan":
        return renderTxPlan()
      case "alignersToOrder": {
        const remainindAligners = [
          {
            title: "Upper",
            total: alignerOrder.upper.total_count || 0,
            completed: alignerOrder.upper.remaining_count || 0,
          },
          {
            title: "Lower",
            total: alignerOrder.lower.total_count || 0,
            completed: alignerOrder.lower.remaining_count || 0,
          },
        ]
        return (
          <>
            {remainindAligners.map((aligner, index: number) => (
              <Grid container direction={"column"} mb={1} key={index}>
                <UText variant="body2">{aligner.title}</UText>
                <UText
                  variant="caption"
                  sxProp={{ color: "text.secondary" }}
                >{`Aligners: ${aligner.completed} of ${aligner.total}`}</UText>
                {renderProgressBar("primary.light", aligner)}
              </Grid>
            ))}
          </>
        )
      }
      case "createDate":
        return renderDate()
      case "caseStatus": {
        const status = STATUS.find(
          (obj) => obj.key === row["caseDisposition"],
        ).displayText
        let date = row.updateDate
        if (row["caseDisposition"] === CASE_STATUS.SUBMITTED_TO_UASSIST)
          date = row.submittedDate
        if (row["caseDisposition"] === CASE_STATUS.ORDER_SUBMITTED)
          date = row.orderDate
        return renderStatus(
          status,
          date,
          "",
          SHOW_AS_LINK_STATUS.includes(row["caseDisposition"]),
        )
      }
      default:
        return renderText()
    }
  }

  const renderOrderTableData = (key: string) => {
    switch (key) {
      case "orderDate":
        return renderDate()
      case "treatmentPlan":
        return renderTxPlan()
      case "orderDetails":
        return renderOrderDetails()
      case "orderShippingStatus":
        return renderOrderStatus()
      case "shipmentTrackingNo":
        return renderShippingTrackingNo()
      case "order":
        return (
          <Grid container direction={"column"} gap={1}>
            {!!row.alignerCount && (
              <Grid item display={"flex"} flexDirection={"column"}>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.alignerCount} Aligners`}</UText>
                {row.upperStageRange && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`Upper ${getAlignerRange(row.upperStageRange)}`}</UText>
                )}
                {row.lowerStageRange && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`Lower ${getAlignerRange(row.lowerStageRange)}`}</UText>
                )}
                {row.alignerMaterial && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.alignerMaterial}`}</UText>
                )}
              </Grid>
            )}

            {!!row.templateCount && (
              <Grid item display={"flex"} flexDirection={"column"}>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.templateCount} Templates`}</UText>
                {row.upperTemplateStage && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.upperTemplateStage.split(",").length} Upper`}</UText>
                )}
                {row.lowerTemplateStage && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.lowerTemplateStage.split(",").length} Lower`}</UText>
                )}
              </Grid>
            )}

            {!!row.retainerCount && (
              <Grid item display={"flex"} flexDirection={"column"}>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.retainerCount} Retainers`}</UText>
                {row.upperRetainerCount && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.upperRetainerCount.split(",").length} Upper`}</UText>
                )}
                {row.lowerRetainerCount && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.lowerRetainerCount.split(",").length} Lower`}</UText>
                )}
              </Grid>
            )}

            {row.isCustomPackaging && (
              <Grid item>
                <UText sxProp={{ wordBreak: "break-word" }} variant="body2">
                  Custom Packaging
                </UText>
              </Grid>
            )}

            {row.shippingMethod && (
              <Grid item>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.shippingMethod} Shipping`}</UText>
              </Grid>
            )}
          </Grid>
        )
      case "orderStatus": {
        const date =
          row[key] === "Shipped" || row[key] === "Delivered"
            ? row.shippingDate
            : row.updateDate
        let status
        if (row[key]?.toUpperCase()?.replace(" ", "_") === "IN_PRODUCTION") {
          status = "In Production"
        } else {
          status = row[key]
        }
        return renderStatus(status, date, row.trackingNo)
      }
      case "orderType": {
        if (!row.orderType) {
          return renderText("A La Carte", true)
        } else if (
          row.orderType.toUpperCase().replaceAll(" ", "") === "ALACARTE"
        ) {
          return renderText(ORDER_TYPE.ALACARTE, true)
        } else {
          let orderType
          if (row.orderType.toUpperCase().replaceAll(" ", "") === "MILD") {
            orderType = ORDER_TYPE.MILD
          } else if (
            row.orderType.toUpperCase().replaceAll(" ", "") === "MODERATE"
          ) {
            orderType = ORDER_TYPE.MODERATE
          } else if (
            row.orderType.toUpperCase().replaceAll(" ", "") === "COMPREHENSIVE"
          ) {
            orderType = ORDER_TYPE.COMPREHENSIVE
          } else if (
            row.orderType.toUpperCase().replaceAll(" ", "") === "FINISHING"
          ) {
            orderType = ORDER_TYPE.FINISHING
          } else {
            orderType = row?.orderType + " Bundle"
          }
          return renderText(orderType, true)
        }
      }
      default:
        return renderText("", true)
    }
  }

  const renderBundleTableData = (key: string) => {
    switch (key) {
      case "bundleType":
        return (
          <Grid container direction={"column"}>
            <UText variant="body2">{row.name}</UText>
            <UText variant="caption">Includes up to:</UText>
            {!!row.aligner.included && (
              <UText variant="caption">{`${row.aligner.included} Aligners`}</UText>
            )}
            {!!row.template.included && (
              <UText variant="caption">{`${row.template.included} Templates`}</UText>
            )}
            {!!row.retainer.included && (
              <UText variant="caption">{`${row.retainer.included} Retainers`}</UText>
            )}
          </Grid>
        )
      case "remainingBundleAllotment": {
        const bundleAllotment = []
        if (row.aligner.included)
          bundleAllotment.push({
            type: "Aligners",
            total: row.aligner.included,
            completed: row.aligner.included - row.aligner.consumed,
          })
        if (row.template.included)
          bundleAllotment.push({
            type: "Templates",
            total: row.template.included,
            completed: row.template.included - row.template.consumed,
          })
        if (row.retainer.included)
          bundleAllotment.push({
            type: "Retainers",
            total: row.retainer.included,
            completed: row.retainer.included - row.retainer.consumed,
          })
        return (
          <>
            {bundleAllotment.map((bundle, index: number) => (
              <Grid container direction={"column"} mb={1} key={index}>
                <UText variant="body2">{`${bundle.type}: ${bundle.completed} of ${bundle.total}`}</UText>
                {renderProgressBar("secondary.main", bundle)}
              </Grid>
            ))}
          </>
        )
      }
      case "purchaseDate":
      case "expiryDate":
        return renderDate()
    }
  }

  const renderPackagingStatusData = (status: string) => {
    const item = PACKAGING_STATUS.find((obj) => obj.status === status)

    if (!item) return null

    const { style } = item
    const { variant, backgroundColor, color, border, borderColor } = style || {}

    return (
      <Chip
        label={item.status}
        sx={{
          fontSize: "13px",
          lineHeight: "138.462%",
          letterSpacing: "0.16px",
          backgroundColor: variant === "filled" ? backgroundColor : "inherit",
          color: color,
          border: variant === "outlined" ? border : "none",
          borderColor: variant === "outlined" ? borderColor : "none",
        }}
        variant={variant}
      />
    )
  }

  const renderPackagingTable = (key: string) => {
    switch (key) {
      case "imageView":
        return <CustomImagePreview customPackagingInfo={row} />
      case "createdDate":
        return renderDateWithTime()
      case "packageName":
        return renderText(row["custom_packaging_name"])
      case "packagingStatus":
        return renderPackagingStatusData(row.packagingStatus)
      default:
        return renderText()
    }
  }

  if (ORDER_TABLE_COLUMNS.includes(dataKey))
    return renderOrderTableData(dataKey)
  if (BUNDLE_TABLE_COLUMNS.includes(dataKey))
    return renderBundleTableData(dataKey)
  if (CASE_TABLE_COLUMNS.includes(dataKey)) return renderCaseTableData(dataKey)
  if (CUSTOMPACAKGE_TABLE_COLUMNS.includes(dataKey))
    return renderPackagingTable(dataKey)

  switch (dataKey) {
    case "treatmentName":
      return (
        <>
          {hasAny(UI_PERMISSION.UI_TXPLANS_CLINICALVIEW) && (
            <Link
              sx={{
                display: "block",
                width: width === 285 ? width - 32 : "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={(event) => {
                event.stopPropagation()
                navigate(
                  `/clinical/patient/${row.patientId}/case/${row.caseId}/treat`,
                )
              }}
            >
              {row.treatmentName}
            </Link>
          )}
          {doesNotHaveAny(UI_PERMISSION.UI_TXPLANS_CLINICALVIEW) && (
            <UText
              variant={"body2"}
              sxProp={{
                display: "block",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row.treatmentName}
            </UText>
          )}
        </>
      )
    case "status":
      return (
        <>
          {row.caseDisposition && (
            <StatusButton
              {...row.order}
              caseStatus={row.caseDisposition}
              width={width}
            />
          )}
        </>
      )
    case "assignedTo":
      return row.user ? <AssignedUser user={row.user} width={width} /> : <></>
    case "updatedDate":
      return renderDate()
    case "createdDate":
      return renderDateWithTime()
    case "printed":
      return renderPrintedandOrdered()
    case "patient_list":
      return (
        <Link
          sx={{
            display: "block",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={(event) => {
            event.stopPropagation()
            dispatch(updateCurrentOrgId(row.org_id))
            navigate(`/patients`, {}, row.org_id)
          }}
        >
          {row.patient_list}
        </Link>
      )
    case "invoice_list":
      return (
        <Link
          sx={{
            display: "block",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={(event) => {
            event.stopPropagation()
            dispatch(updateCurrentOrgId(row.org_id))
            navigate(`/billing`, {}, row.org_id)
          }}
        >
          {row.invoice_list}
        </Link>
      )
    default:
      return renderText()
  }
}

export default UTableCellRenderer