import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

export const manuStagingPercentSlice = createSlice({
  name: "manustagingpercent",
  initialState: {
    isShow: false,
  },
  reducers: {
    setIsShow(state, action: PayloadAction<boolean>) {
      state.isShow = action.payload
    },
  },
})

export const { setIsShow } = manuStagingPercentSlice.actions
