import { FC } from "react"
import { useTranslation } from "react-i18next"

import { Grid } from "../../components/mui.components"
import { Volume } from "../../core/app/slices/pricing/pricingSlice"

import { VolumeCard } from "./volumeCard"

import "./pricing.scss"

export const Volumes: FC<{ volume: { aligner: Volume; stl: Volume } }> = ({
  volume,
}) => {
  const { t } = useTranslation("common")

  return (
    <Grid
      container
      direction={"row"}
      className="volume-cards-container"
      sx={{ my: 5, mx: 0 }}
    >
      <Grid item xs={11} sm={5} md={5} lg={5}>
        <VolumeCard
          title={t("Pricing.volume.alignerTitle")}
          volume={volume?.aligner}
        />
      </Grid>
      <Grid item xs={11} sm={5} md={5} lg={5}>
        <VolumeCard
          isStl
          title={t("Pricing.volume.stlTitle")}
          volume={volume?.stl}
        />
      </Grid>
    </Grid>
  )
}
