import { FC } from "react"
import { MenuItem, MenuList, Paper, Popper } from "@mui/material"

import { useAppDispatch } from "../../../../core/app/hooks"
import { setIsRouteChangeBlocked } from "../../../../core/app/slices/navigationPopup/navigationPopupSlice"

import { dentalChartMenuList } from "./toothObject"

interface propsType {
  menuProps: { open: boolean; position: number[] }
  setMenuProps: (object: { open: boolean; position: number[] }) => void
  setappliedTeeth: (item: []) => void
  appliedTeeth: { teeth: string; checked: string }[]
  selectedTeethId: string
}
const Menu: FC = ({
  menuProps,
  setMenuProps,
  setappliedTeeth,
  appliedTeeth,
  selectedTeethId,
}: propsType) => {
  const dispatch = useAppDispatch()
  const getStyle = (item: string) => {
    let res = {}
    appliedTeeth.forEach(({ teeth, checked }) => {
      if (teeth === selectedTeethId && item.split("(")[1].includes(checked)) {
        res = {
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 600,
          color: (theme) => theme.palette.text.primary,
          backgroundColor: "rgba(33, 150, 243, 0.04)",
        }
      }
    })
    return res
  }

  const addAplliedTeeth = (selectedItem: string) => {
    let value = selectedItem.substring(
      selectedItem.lastIndexOf("(") + 1,
      selectedItem.length - 1,
    )
    if (selectedItem.includes("Primary")) {
      // toothID like "ul1 - ul8"
      const toothNum = selectedTeethId.charAt(2)
      switch (toothNum) {
        case "1":
          value = "a"
          break
        case "2":
          value = "b"
          break
        case "3":
          value = "c"
          break
        case "4":
          value = "d"
          break
        case "5":
          value = "e"
          break
        default:
          value = "P"
      }
    }
    const res = [{ teeth: selectedTeethId, checked: value }]
    appliedTeeth.forEach((element) => {
      if (selectedTeethId === element.teeth) {
        document.getElementById(
          selectedTeethId + element.checked,
        ).style.display = "none"
        if (element.checked === value) {
          res.shift()
        }
        return
      }
      res.push(element)
    })
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
    setappliedTeeth(res)
  }

  return (
    <Popper
      open={menuProps.open}
      sx={{
        top: `${menuProps.position[1]}px  !important`,
        left: `${menuProps.position[0]}px  !important`,
      }}
    >
      <Paper>
        <MenuList>
          {dentalChartMenuList.map((item) => {
            const toothNum = selectedTeethId.charAt(2)
            if (Number.parseInt(toothNum) > 5 && item.includes("Primary")) {
              return <></>
            }
            return (
              <MenuItem
                key={item}
                onClick={() => {
                  setMenuProps({ open: false, position: [0, 0] })
                  addAplliedTeeth(item)
                }}
                sx={getStyle(item)}
              >
                {item}
              </MenuItem>
            )
          })}
        </MenuList>
      </Paper>
    </Popper>
  )
}
export default Menu
