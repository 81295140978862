import { useState, useRef, useEffect, Children } from "react"
import {
  Card,
  IconButton,
  CardContent,
  CardHeader,
  Slide,
  CardActions,
  CardProps,
  Box,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/CloseOutlined"

import { UDuAssist } from "../UDMessage/UDuAssit"
import { UDMessage } from "../UDMessage/UDMessage"
//import { UDRecord } from "./UDRecord"
import { UDPhotos } from "./UDPhotos"
import { UDReport } from "../UDReport/UDReport"
import { UDTMTable } from "../TMTable/UDTMTable"
import { setCurLeftPage, setReportData } from "../udTreatSlice"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { UDDentalChart } from "./UDDentalChart"
import { UDCreateCase } from "./UDCreateCase"
import { UploadSTL } from "../components/UploadSTL"
import { UploadSTLV2 } from "../components/UploadSTLV2"
import { UDBolton } from "./UDBolton"
import { caseManagement } from "@/gluelayer"
import { LRPhotos } from "../LocalRecord/LRPhotos"
import { LRStls } from "../LocalRecord/LRStls"

export function LeftPages(props: any) {
  const { curLeftPage, reportData } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const dispatch = useAppDispatch()
  const curPage = useRef("")
  useEffect(() => {}, [curLeftPage])

  const onClosePage = () => {
    dispatch(setCurLeftPage(""))
  }
  // Report show or hidden
  const [reportShow, setReportShow] = useState<boolean>(false)

  const reportPdfRef = useRef(null)
  const openOrUploadReportPdf = (status: "open" | "upload") => {
    reportPdfRef?.current.uploadReportPDF(status)
  }
  useEffect(() => {
    setReportShow(curLeftPage == "report")
    curPage.current = curLeftPage
    if (curLeftPage !== "report") return
    const { attachment, toothlist, ipr, firstStage } =
      caseManagement.getAttachmentIPRReport()
    const { lowlist, uplist } = toothlist
    const list = lowlist.concat(uplist)
    dispatch(
      setReportData({
        attachment: attachment,
        toothlist: list,
        iprData: ipr,
      }),
    )
  }, [curLeftPage])
  function getPage(pageName: string) {
    switch (pageName) {
      case "createcase":
        // dispatch(setCaseOpenStatus("emptycase"))
        return <UploadSTL onClose={onClosePage} />
        break
      case "createcasev2":
        // dispatch(setCaseOpenStatus("emptycase"))
        return <UploadSTLV2 onClose={onClosePage} />
        break
      case "records":
        return <UDPhotos onClose={onClosePage} />
        break
      case "dentalchart":
        return <UDDentalChart onClose={onClosePage} />
        break
      case "uassist":
        return <UDMessage onClose={onClosePage} />
        break
      case "report":
        return (
          <UDReport
            onClose={onClosePage}
            reportShow={reportShow}
            toothlist={reportData.toothlist}
            attachment={reportData.attachment}
            setReportShow={setReportShow}
            iprData={reportData.iprData}
            openReportFunc={openOrUploadReportPdf}
          />
        )
        break
      case "tmtable":
        return <UDTMTable onClose={onClosePage} />
        break
      case "bolton":
        return <UDBolton onClose={onClosePage} />
        break
      case "lrphotos":
        return <LRPhotos onClose={onClosePage} />
        break
      case "lrstls":
        return <LRStls onClose={onClosePage} />
        break
    }
    return <></>
  }

  return curLeftPage && getPage(curLeftPage)
}
