import { useState, useEffect } from "react"
import { Button, Typography } from "@mui/material"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { fetchBannerData } from "@/core/app/slices/banners/bannerThunkApi"
import { useParams } from "react-router-dom"
import { useAppDispatch } from "@/core/app/hooks"
export const UDBanner = ({ open, onOK }) => {
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [warningSubject, setWarningSubject] = useState("")
  const [warningContent, setWarningContent] = useState("")
  const [openBanner, setOpenBanner] = useState(false)
  useEffect(() => {
    dispatch(fetchBannerData({ patientId, caseId })).then((data) => {
      if (data.payload && data.payload.warning_subject) {
        setWarningSubject(data.payload.warning_subject)
        setWarningContent(data.payload.content)
        setOpenBanner(true)
      }
    })
  }, [])

  return (
    <Dialog open={openBanner}>
      <DialogTitle>Warning .... </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{warningSubject}</Typography>
        <Typography variant="body2">{warningContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpenBanner(false)
          }}
        >
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  )
}
