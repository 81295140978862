import http from "../../../global/http"
import { CompanyProfile } from "../../../model/User"

import {
  AccountActivateParams,
  IForgotPassword,
  IResetPassword,
  IUserPreferences,
} from "./user.type"

export const updateUserPreferences = async (
  orgId: string,
  usrId: string,
  payload: IUserPreferences,
) => {
  try {
    const { data } = await http.put(
      `/user-mgmt/v1/org/${orgId}/users/${usrId}/preferences/`,
      { user_preference: { ...payload } },
    )
    return data
  } catch (err) {
    return err
  }
}

export const getUserPreferences = async (
  orgId: string,
  usrId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${usrId}/?fields=preferences,id,country_cd`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getUserProfile = async (
  orgId: string,
  userId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/?fields=preferences,id,org_name,first_name,last_name,update_date,last_login_date,org_id,user_type,status,lang_cd,country_cd,username,email,create_date,update_date`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const updateUserProfile = async (
  orgId: string,
  userId: string,
  payload: IUpdateUser,
): Promise<any> => {
  try {
    const { data } = await http.patch(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const updatePasswordService = async (
  payload: any,
  userId: string,
  orgId: string,
) => {
  try {
    const { data } = await http.put(
      `user-mgmt/v2/org/${orgId}/users/${userId}/password/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const resetPasswordService = async (payload: IResetPassword) => {
  try {
    const { data } = await http.put(
      `user-mgmt/v2/authn/reset-password/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const forgotPasswordService = async (payload: IForgotPassword) => {
  try {
    const { data } = await http.put(
      `user-mgmt/v2/authn/forgot-password/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getClinicalSettting = async (orgId: string): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/account/clinical-settings/`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const saveClinicalSettting = async (
  orgId: string,
  requestBody: unknown,
): Promise<any> => {
  try {
    const { data } = await http.patch(
      `/user-mgmt/v1/org/${orgId}/account/clinical-settings/`,
      requestBody,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getCountryList = async (orgId: string): Promise<any> => {
  try {
    const { data } = await http.get(`/user-mgmt/v1/org/${orgId}/countries/`)
    return data
  } catch (err) {
    return err
  }
}

export const getStatesList = async (
  orgId: string,
  countryid: number,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/countries/${countryid}/regions/`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const addCardDetails = async (
  orgId: string,
  inputData: unknown,
): Promise<any> => {
  try {
    const { data } = await http.post(
      `/shared/v1/org/${orgId}/payments/`,
      inputData,
    )
    return data
  } catch (err) {
    return err
  }
}

export const updateCardDetails = async (
  orgId: string,
  inputData: unknown,
): Promise<any> => {
  try {
    const { data } = await http.put(
      `/shared/v1/org/${orgId}/payments/`,
      inputData,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getCardDetails = async (orgId: string): Promise<any> => {
  try {
    const { data } = await http.get(`/shared/v1/org/${orgId}/payments/`)
    return data
  } catch (err) {
    return err
  }
}

export const activateUser = async (
  payload: AccountActivateParams,
): Promise<any> => {
  try {
    payload.user_password = btoa(payload.user_password)
    const { data } = await http.put(`/user-mgmt/v2/authn/activate/`, payload)
    return data
  } catch (err) {
    return err
  }
}

export const getIfuDocument = async (
  orgId: string,
  userId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/ifu/ifu.pdf/`,
      {
        method: "GET",
        responseType: "blob",
      },
    )
    return data
  } catch (err) {
    return err
  }
}

export const getReleaseNotes = async (
  orgId: string,
  userId: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/release-notes/release_notes.pdf/`,
      {
        method: "GET",
        responseType: "blob",
      },
    )
    return data
  } catch (err) {
    return err
  }
}

export const getHelpDocuments = async (
  orgId: string,
  userId: string,
  fileName: string,
): Promise<any> => {
  try {
    const { data } = await http.get(
      `/user-mgmt/v1/org/${orgId}/users/${userId}/help/${fileName}/`,
      {
        method: "GET",
        responseType: "blob",
      },
    )
    return data
  } catch (err) {
    return err
  }
}

export const getAccountDetails = async (orgId: string) => {
  const url = `/user-mgmt/v1/org/${orgId}/account/details/`
  try {
    const { data } = await http.get(url)
    return new CompanyProfile().Deserialize(data?.result)
  } catch (err) {
    return err
  }
}

export const getUserPermissions = async (orgId: string) => {
  const url = `user-mgmt/v1/org/${orgId}/users/permissions`
  try {
    const { data } = await http.get(url)
    return data
  } catch (err) {
    return err
  }
}

export const getUDApp = async (
  orgId: string,
  version: string,
): Promise<any> => {
  try {
    const data = await http.get(
      `/user-mgmt/v1/org/${orgId}/account/udesign?version=${version}`,
      {
        responseType: "blob",
      },
    )
    return data
  } catch (err) {
    return err
  }
}

export const getSystemMessages = async (orgId: string) => {
  const url = `user-mgmt/v1/org/${orgId}/account/banners`
  let message = []
  try {
    const { data } = await http.get(url)
    const { result } = data
    if (result && result.length > 0) {
      message = result
    }
    return message
  } catch (err) {
    return err
  }
}

export const getAddressSuggestions = async (
  orgId: string,
  userId: string,
  payload: any,
) => {
  const url = `user-mgmt/v2/org/${orgId}/users/${userId}/address/suggestions/`
  try {
    const { data } = await http.post(url, { search: payload })
    return data
  } catch (err) {
    return err
  }
}
