import { createAsyncThunk } from "@reduxjs/toolkit"

import store from "../../store"

import { getBundleByCase } from "./bundle.service"

export const getBundleByCaseId = createAsyncThunk(
  "bundleService/getBundleByCaseId",
  async (caseId: number, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return getBundleByCase(orgId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
