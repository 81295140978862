import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Backdrop, Grid, IconButton } from "@mui/material"
import { Box } from "@mui/system"

import CloseIcon from "../../../../assets/svgs/close.svg"
import { useAppDispatch } from "../../../../core/app/hooks"
import { fetchFileList } from "../../../../core/app/slices/clinical/clinicalThunkApi"
import PhotoList from "../../../Uassist/PhotoList"
import OrderForms from "../../../Uassist/USmartForms/OrderForms"
import Retainer from "../../../Uassist/USmartForms/Retainer"

const AddRefinement: FC = () => {
  const preview = useRef<HTMLInputElement>(null)
  const [selectedPreview, setSelectedPreview] = useState("")
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handleScroll = () => {
      if (preview && preview.current) {
        const { top, height } = preview.current.getBoundingClientRect()
        //To determine if the columns have wrapped
        const parent = preview.current.parentElement?.offsetWidth
        const form = preview.current.nextSibling?.offsetWidth
        if (parent && form) {
          const isSticky =
            top <= 0 &&
            top + height > 0 &&
            parent >= form + preview.current.offsetWidth + 45
          preview.current.classList.toggle("preview-sticky", isSticky)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [preview])

  useEffect(() => {
    dispatch(fetchFileList({ patientId, caseId }))
  }, [dispatch])

  return (
    <>
      <Grid
        container
        gap={"45px"}
        marginBottom={"48px"}
        paddingTop={2}
        justifyContent={"center"}
      >
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          minWidth={"466px"}
          ref={preview}
          height={"720px"}
        >
          <PhotoList setSelectedPreview={setSelectedPreview} isRefinement />
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"} minWidth={"640px"}>
          <OrderForms />
        </Grid>
      </Grid>
      {selectedPreview && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Box
              display="flex"
              justifyContent="center"
              position="fixed"
              top="120px"
              bottom="120px"
              left="120px"
              right="120px"
              overflow="hidden"
            >
              <Box
                id="backdrop"
                position="relative"
                width="inherit"
                height="inherit"
              >
                <img
                  src={selectedPreview}
                  alt={"fullscreenimg"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
                <Box
                  component={"div"}
                  id="closeicon"
                  position="absolute"
                  top="0px"
                  right="0px"
                  sx={{ padding: "8px" }}
                >
                  <IconButton
                    size="medium"
                    sx={{
                      fontSize: "large",
                      backgroundColor: "divider",
                      padding: "0px",
                      ":hover": { backgroundColor: "divider" },
                    }}
                    onClick={() => setSelectedPreview("")}
                  >
                    <Box
                      component={"img"}
                      src={CloseIcon}
                      alt={"Logo"}
                      loading="lazy"
                      sx={{ cursor: "pointer", padding: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      )}
    </>
  )
}
export default AddRefinement
