import oktaAuthClient from "@/core/config/okta.config"
import { getAccessToken } from "@/core/config/okta.config"

export function dataURItoFile(dataURI, fileName) {
    const [dataDescription, base64Data] = dataURI.split(',')
    // 文件类型
    const mimetype = dataDescription.match(/:(.*?);/)[1]

    // 解码 base64 数据
    const decodedData = atob(base64Data)
    let n = decodedData.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = decodedData.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mimetype });
}

export async function compressImageByCanvas(file, options:{quality?:number,width?:number,height?:number,filename?:string} = {}) {
    const { quality } = options;
    let { width, height } = options

    const filename = options.filename ?? file.name;

    let _resolve, _reject
    const promise = new Promise<File>((resolve, reject) => {
        _resolve = resolve
        _reject = reject
    })

    const img = new Image();
    img.onload = function () {

        // 如果只指定了宽度或高度，则另一个按比例缩放
        if (width && !height) {
            height = Math.round(img.height * (width / img.width))
        } else if (!width && height) {
            width = Math.round(img.width * (height / img.height))
        }
        
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // 设置 canvas 的宽高与图片一致
        canvas.width = width || img.width;
        canvas.height = height || img.height;

        // 在 canvas 上绘制图片
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
        // 获取压缩后的图片数据
        const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
        _resolve(dataURItoFile(compressedDataUrl, filename))
    };

    img.src = URL.createObjectURL(file);
    return promise
}


export async function postMessageToWebsocket({ imageNameList,orgId,patientId,caseId}):Promise<Object|null>{
    const { VITE_WEBSOCKET_URL } = import.meta.env
    return new Promise(async (resolve,reject)=>{
        const renewToken = await oktaAuthClient.token.renewTokens();
        await oktaAuthClient.tokenManager.setTokens(renewToken)
        const token = getAccessToken();
        // setIsAIloading(true)
        let connect = false
        let ws = new WebSocket(
          `${VITE_WEBSOCKET_URL}/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/ai-clinical-photo`,
          "4a793be7-51f0-4d51-892f-11022e413e94",
        )
        ws.onopen = function () {
          const sendContext = JSON.stringify({
            type: "inference",
            body: {
              case_id: Number(caseId),
              token: token,
              algorithm: "photo2",
              input: {org_id:orgId,patient_id:patientId,photo_name:imageNameList},
            },
          })
          ws.send(sendContext)
          connect = true
        }
        ws.onmessage = function ({ data }) {
          console.log("AIProcessOnMessage::",data)
          try{
            const {
              body: { result, output },
            } = JSON.parse(data)
            if (result !== 1) return
            // filter
            const aiResult = Object.keys(output).reduce((prev,key)=>{
              if (typeof output[key] ===  "string") {
                prev[key]=output[key]
              }
              return prev
            },{})
            const removeDuplicate = Object.keys(aiResult).reduce((prev,key)=>{
              const value = aiResult[key]
              if (!prev.values.has(value)) {
                prev.values.add(value);
                prev.result[key]=value;
              }else{
                prev.result[key]=""
              }
              return prev
            },{values:new Set(),result:{}})
    
            const jsonData = {...removeDuplicate.result};
            console.log('jsonData:',jsonData);
            ws.close()
            resolve(jsonData)
          }catch(err){
            console.error('handle AI message error:',err,data)
            reject(null)
          }
        }
    })

}