import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { UFileBox, UImagePreview, UText } from "../../../components"
import { Container, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { getCaseById } from "../../../core/app/slices/case"
import {
  FileItem,
  PhotoObject,
} from "../../../core/app/slices/records/photograph/photograph.type"
import { setDownloadStatus } from "../../../core/app/slices/records/photograph/photographSlice"
import {
  deleteFiles,
  downloadXray,
} from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"
import { getFiles } from "../../../core/utils/commonMethod"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

import UploadXrayProcessing from "./uploadXrayProcessing"

const FileUploadSection: FC<{
  selectedFiles: File[]
  setSelectedFiles: (files: File[]) => void
  onRemoveFileFromList: (file: File) => void
  deleteFile: () => void
  uploadFiles: (file: FileList) => void
  id: string
  downloadedFile: PhotoObject[]
  isRefinement: boolean
}> = ({
  id,
  selectedFiles,
  setSelectedFiles,
  onRemoveFileFromList,
  deleteFile,
  uploadFiles,
  downloadedFile,
  isRefinement,
}) => {
  const { t } = useTranslation("common")
  const FILE_ALLOWED_TYPES = ["png", "jpg", "jpeg", "bmp"]
  const FILE_ALLOWED_SIZE = 50 //in MB
  const FILE_ALLOWED_LIMIT = 1

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{ pt: "10px !important", maxWidth: "396px" }}
    >
      <Container
        maxWidth={"md"}
        sx={{
          padding: "0 !important",
        }}
      >
        {selectedFiles.length > 0 && (
          <Container
            maxWidth={"md"}
            id="processing"
            sx={{
              paddingLeft: "0 !important",
              paddingRight: "0px !important",
              width: "396px",
            }}
          >
            <UploadXrayProcessing
              key={id}
              radioGraphType={id}
              file={selectedFiles[0]}
              onRemove={onRemoveFileFromList}
              onUploadSuccess={setSelectedFiles}
              isRefinement={isRefinement}
            />
          </Container>
        )}
        {!!downloadedFile.length && (
          <Container
            maxWidth={"md"}
            id="preview"
            sx={{
              paddingLeft: "0 !important",
              paddingRight: "0px !important",
              width: "400px",
            }}
          >
            <UImagePreview
              showFileName={true}
              files={
                downloadedFile.length
                  ? [
                      {
                        src: downloadedFile[0].src,
                        name: downloadedFile[0].name,
                      },
                    ]
                  : selectedFiles.map((file) => {
                      return {
                        src: URL.createObjectURL(file),
                        name: file.name,
                      }
                    })
              }
              sxImageProp={{ mb: "0px", mt: "0px" }}
              imageHeight={400}
              imageWidth={400}
              deleteImage={deleteFile}
            />
          </Container>
        )}
        {selectedFiles.length === 0 && !downloadedFile.length && (
          <UFileBox
            boxSize={{ height: "216px", width: "396px", mt: "0 !important" }}
            allowedFileExtensions={FILE_ALLOWED_TYPES}
            allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
            fileAcceptType={"image/*"}
            id={id}
            selectedFile={uploadFiles}
            fileLimit={FILE_ALLOWED_LIMIT}
            isRequired={false}
            messages={{
              fileNote: t("records.file.filenote", {
                fileTypes: "JPG, PNG BMP",
                fileSize: FILE_ALLOWED_SIZE,
              }),
              uploadButton: t("records.file.btnclicktoupload"),
              uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
              invalidfileFormat: (
                <Trans components={{ newLine: <br /> }}>
                  {"records.photograph.invalidfileformat"}
                </Trans>
              ),
              invalidFileSize: t("records.file.invalidfilesize", {
                fileSize: FILE_ALLOWED_SIZE,
              }),
              invalidFileLimit: t("records.file.invalidfilelimit", {
                noOfFiles: FILE_ALLOWED_LIMIT,
              }),
            }}
          />
        )}
      </Container>
    </Grid>
  )
}

const UploadPano: FC<{
  setXrayErrors: (val: boolean) => void
  isRefinement?: boolean
  isShowRecords?: boolean
}> = ({ setXrayErrors, isRefinement, isShowRecords }) => {
  const [selectedPanoFiles, setSelectedPanoFiles] = useState<File[]>([])
  const [selectedCephFiles, setSelectedCephFiles] = useState<File[]>([])
  const [noPrevRefinementFiles, setNoPrevRefinementFiles] =
    useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const { t } = useTranslation("common")
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()

  const getXrayType = (file_name) => {
    if (file_name.includes("pano")) {
      return "pano"
    } else if (file_name.includes("ceph")) {
      return "ceph"
    } else {
      return "others"
    }
  }

  useEffect(() => {
    // if (((isRefinement && isShowRecords) || !isRefinement))
    let downloadTotal = 0
    const downloadFiles = (item: FileItem, totalFilesToDownload: number) => {
      const { file_name, original_file_name } = item
      dispatch(setDownloadStatus("pending"))
      dispatch(
        downloadXray({
          patientId: patientId,
          caseId: caseId,
          fileName: file_name,
          XrayType: getXrayType(file_name),
          original_file_name: original_file_name,
        }),
      ).then((res) => {
        downloadTotal++
        if (downloadTotal === totalFilesToDownload) {
          dispatch(setDownloadStatus("succeeded"))
        }
      })
    }

    if (fileList.length) {
      const radioGraphType = []
      let files = []
      let refinementFiles = []
      if (!pano.length) radioGraphType.push("pano")
      if (!ceph.length) radioGraphType.push("ceph")
      if (!otherXrays.length) radioGraphType.push("others")
      radioGraphType.map((type) => {
        files = [
          ...files,
          ...getFiles(
            fileList,
            "radiograph",
            caseDetail.refinementRound > 0,
            type,
            caseDetail.refinementRound,
          ),
        ]
        refinementFiles = [
          ...refinementFiles,
          ...getFiles(
            fileList,
            "radiograph",
            true,
            type,
            caseDetail.refinementRound + 1,
          ),
        ]
      })
      if (!files.length) {
        setNoPrevRefinementFiles(true)
        radioGraphType.map((type) => {
          files = [
            ...files,
            ...getFiles(
              fileList,
              "radiograph",
              false,
              type,
              caseDetail.refinementRound,
            ),
          ]
        })
      }
      if (!isRefinement || isShowRecords) {
        files.forEach((file) => {
          if (
            caseDetail.refinementRound === 0 ||
            noPrevRefinementFiles ||
            file.file_name.includes(`refinement_${caseDetail.refinementRound}`)
          ) {
            downloadFiles(file, files.length)
          }
        })
      } else if (refinementFiles.length) {
        refinementFiles.forEach((file) => {
          downloadFiles(file, refinementFiles.length)
        })
      }
    }
  }, [caseDetail, fileList, patientId, caseId, isShowRecords])

  useEffect(() => {
    if (isRefinement && caseDetail.id + "" !== caseId) {
      dispatch(getCaseById({ patientId, caseId }))
    }
  }, [])

  const removeFileFromList = (
    file: File,
    setState: React.Dispatch<React.SetStateAction<File[]>>,
  ): void => {
    setState((prevFiles) => prevFiles.filter((f) => f !== file))
  }

  const onRemoveFileFromList = (file: File): void => {
    removeFileFromList(file, setSelectedPanoFiles)
  }

  const onRemoveCephFileFromList = (file: File): void => {
    removeFileFromList(file, setSelectedCephFiles)
  }

  const deletePanoFile = (): void => {
    dispatch(
      deleteFiles({
        patientId: patientId,
        caseId: caseId,
        fileName: pano[0].fileName,
        fileType: "radiograph",
        version: "v1",
      }),
    )
  }

  const deleteCephFile = (): void => {
    dispatch(
      deleteFiles({
        patientId: patientId,
        caseId: caseId,
        fileName: ceph[0].fileName,
        fileType: "radiograph",
        version: "v1",
      }),
    )
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={"space-between"}
        gap={2}
        mt={0}
        pt={1}
        ml={0}
        width={"872px"}
        sx={{ minHeight: "278px" }}
      >
        <Grid
          item
          justifyContent={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ p: "10px !important", ml: "0px !important" }}
        >
          <UText
            color={"text.primary"}
            variant={"h6"}
            sxProp={{ display: "flex", justifyContent: "center" }}
          >
            {t("records.xray.pano")}
          </UText>
          <FileUploadSection
            id="pano"
            selectedFiles={selectedPanoFiles}
            setSelectedFiles={setSelectedPanoFiles}
            onRemoveFileFromList={onRemoveFileFromList}
            deleteFile={deletePanoFile}
            uploadFiles={(files: FileList) => setSelectedPanoFiles([...files])}
            downloadedFile={pano}
            isRefinement={isRefinement}
          />
        </Grid>
        <Grid
          item
          justifyContent={"flex-start"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ p: "10px !important", ml: "0px !important" }}
        >
          <UText
            component={"div"}
            color={"text.primary"}
            variant={"h6"}
            sxProp={{ textAlign: "center" }}
          >
            {t("records.xray.ceph")}
          </UText>
          <FileUploadSection
            id="ceph"
            selectedFiles={selectedCephFiles}
            setSelectedFiles={setSelectedCephFiles}
            onRemoveFileFromList={onRemoveCephFileFromList}
            deleteFile={deleteCephFile}
            uploadFiles={(files: FileList) => setSelectedCephFiles([...files])}
            downloadedFile={ceph}
            isRefinement={isRefinement}
          />
          {!ceph.length && (
            <UText
              component={"div"}
              color={"text.secondary"}
              variant={"caption"}
              sxProp={{
                lineHeight: "166%",
                letterSpacing: "0.4px",
                pt: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {t("records.xray.optional")}
            </UText>
          )}
        </Grid>
      </Grid>
      <AdditionalPhotograph
        isXray={true}
        title={t("records.xray.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        linkText={t("records.xray.addXray") || ""}
        showAdditionalFilesSection={
          (!!pano.length && !!ceph.length) || !!otherXrays.length
        }
        photographType={"xrays"}
        downloadedFiles={otherXrays}
        version="v1"
        fileType="radiograph"
        isRefinement={isRefinement}
      />
    </>
  )
}

export default UploadPano
