import { FC } from "react"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"

import { Box, SxProps } from "../../../../components/mui.components"

export interface IBannerProps {
  severity: "warning" | "info"
  buttonText?: string
  buttonIcon?: any
  bannerTitle?: string
  bannerContent?: string
  handleBannerRouter?: () => void
  buttonWidth?: string
  isBundle?: boolean
  sxProp?: SxProps
  contentTitle?: string
}

const Banner: FC<IBannerProps> = ({
  severity,
  buttonText,
  buttonIcon,
  bannerTitle,
  bannerContent,
  handleBannerRouter,
  buttonWidth,
  isBundle,
  sxProp,
  contentTitle,
}) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert
        severity={severity}
        sx={{
          ...sxProp,
          alignItems: !isBundle && "center",
          padding: "10px 16px",
          "> .MuiAlert-icon": { alignItems: !isBundle && "center" },
          "> .MuiAlert-action": { mr: 0, pt: 0 },
          "> .MuiAlert-message": { display: bannerContent ? "block" : "flex" },
        }}
        action={
          buttonText && (
            <Button
              onClick={handleBannerRouter}
              component="label"
              sx={{
                width: buttonWidth,
                backgroundColor:
                  severity === "warning" ? "warning.main" : "info.main",
                color: "white",
                fontSize: 13,
                "&:hover": {
                  backgroundColor:
                    severity === "warning" ? "warning.main" : "info.main",
                },
              }}
              startIcon={buttonIcon ? buttonIcon : ""}
            >
              {buttonText}
            </Button>
          )
        }
      >
        <AlertTitle sx={{ mb: 0 }}>{bannerTitle}</AlertTitle>
        <Box
          sx={{ marginTop: "4px", color: isBundle && "rgba(0, 0, 0, 0.85)" }}
        >
          <span>{contentTitle}</span>
          {bannerContent || ""}
        </Box>
      </Alert>
    </Stack>
  )
}

export default Banner
