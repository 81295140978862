import { Font, Path, StyleSheet, Svg } from "@react-pdf/renderer"

import Poppins500 from "../../../../assets/fonts/poppins/poppins-v20-latin-500.woff"
import Poppins700 from "../../../../assets/fonts/poppins/poppins-v20-latin-700.woff"
import PoppinsRegular from "../../../../assets/fonts/poppins/poppins-v20-latin-regular.woff"

Font.register({ family: "Poppins", src: PoppinsRegular, fontWeight: 400 })
Font.register({ family: "Poppins", src: Poppins500, fontWeight: 500 })
Font.register({ family: "Poppins", src: Poppins700, fontWeight: 700 })

export const pdfstyles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    display: "flex",
    // width: "612px",
    // height: "792px",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  footer: {
    position: "absolute",
    bottom: 24,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "1 0 0",
    gap: 8,
  },

  header: {
    // display: "flex",
    flexDirection: "row", // 默认flex方向是水平的，所以设为row以实现水平布局
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    width: 568,
  },

  logocontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  logo: {
    width: 108,
    height: 48,
    flexShrink: 0,
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: 48,
  },

  text: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFeatureSettings: "clig off, liga off",

    // /* typography/h6 */
    fontFamily: "Poppins",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%", // React Native uses lineHeight as a number representing the multiple of font size
    letterSpacing: 0.15,
  },
})

export const attachmentstyles = StyleSheet.create({
  attachmentlables: {
    width: 582,
    height: 24,
    display: "flex",
    alignItems: "flex-start",
    flex: "1 0 0",
  },
  lableitem: {
    width: 127,
    height: 24,
    display: "flex",
    // padding: "4px 8px",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row", // React-PDFv3 默认是 "column"，如果需要水平布局，请添加此行
    gap: 4,
  },
  labletext: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "166%",
    letterSpacing: 0.4,
  },
})

export const PresurepointSvg = () => (
  <Svg width="16" height="16" viewBox="0 0 16 16">
    <Path
      d="M1.35498 8.88594C1.35498 8.88594 2.79498 7.08594 7.99498 7.08594C13.195 7.08594 14.645 8.91594 14.645 8.91594"
      stroke="#455A64"
      strokeWidth="2"
      strokeLineCap="round"
    />
  </Svg>
)
export default pdfstyles
