import { useEffect, useRef, useState } from "react"
import { Stack, TextField, Typography, Tooltip, CardMedia } from "@mui/material"
import { Box, Button, Card, Toolbar, IconButton } from "@mui/material"
import { LeftPage } from "../left/LeftPage"
import lowerArch from "../assets/lowerArch.jpg"
import { caseManagement } from "@/gluelayer"
import { string } from "prop-types"

const PhotoBox = (props) => {
  const [photoUrl, setPhotoUrl] = useState("")
  useEffect(() => {
    caseManagement
      .getPhotoFromZip(props.fileName)
      .then((file) => {
        const url = file ? URL.createObjectURL(file) : ""
        setPhotoUrl(url)
      })
      .catch((e) => {
        setPhotoUrl("")
      })
  }, [props.fileName])
  return (
    <Box
      component="img"
      sx={{
        width: "100%",
      }}
      alt="The house from the offer."
      src={photoUrl}
    />
  )
}

export function LRPhotos(props: any) {
  const [frontPhoto, setFrontPhoto] = useState("")
  const [photoList, setPhotoList] = useState([])

  useEffect(() => {
    setPhotoList(caseManagement.getPhotoListFromZip())
  }, [])

  const Content = () => {
    return (
      <Box sx={{ width: 400 }} position="relative">
        {photoList.map((fileName, index) => {
          return <PhotoBox fileName={fileName} />
        })}
      </Box>
    )
  }
  const SavePhoto = (file: File) => {
    const fileName = file.name
    caseManagement.savePhotoToZip(file, fileName).then((bool) => {
      if (bool) {
        console.log("Photo is saved to local file system.")
        const list = caseManagement.getPhotoListFromZip()
        setPhotoList(list)
      } else {
        console.log("Failed to save photo!")
      }
    })
  }
  const Actions = () => {
    return (
      <Box sx={{ height: 48 }}>
        <Button component="label">
          <input
            type="file"
            hidden
            onChange={(e) => {
              if (e.target.files) {
                console.log(e.target.files[0])
                SavePhoto(e.target.files[0])
              }
            }}
            accept="jpg,jpeg,png"
          />
          Upload photo ...
        </Button>
      </Box>
    )
  }

  return (
    <LeftPage
      title="Local Photos"
      onClose={props.onClose}
      content={<Content />}
      actions={<Actions />}
    />
  )
}
