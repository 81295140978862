import * as React from "react"
import { useEffect, useState } from "react"
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material"
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { IPR_DATA_1, viewControlInTreatment, wasmModule } from "@/gluelayer"
import { stagingManager } from "@/gluelayer"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup } from "@/UDTreat/udSetupSlice"
import {
  FlowTabs2,
  FlowBtnGroup,
  FlowToggleButton,
} from "../../custom/FlowCustom"
import {
  setCurArch,
  setShowKFEditor,
  setUpdateSetup,
  setUpdateStaging,
} from "../../udTreatSlice"

import { RightPage } from "@/UDTreat/right/RightPage"

import { WeStagingDlg } from "./WeStagingDlg"

export const WeStaging = (props) => {
  const {
    curArch,
    showKFEditor,
    showTMChart,
    currentStep,
    updateSetup,
    updateStaging,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const dispatch = useAppDispatch()
  const [curTab, setCurTab] = useState(0)

  const onClickStagingBtn = (cmd: string) => {
    console.log("Auto staging button " + cmd + " is clicked.")
    if (!wasmModule) {
      console.log("wasmModule null ptr")
      return
    }
    // Sequential move type
    /*
    enum class MoveType
    {
      AUTO = 0,
      DISTALIZATION,
      POSTEXPANSION,
      PREMOLAREXTRACTION,
      BLOCKDISTALIZATION,
      ADAPTIVEEXPANSION, 
      NORMAL
    }; */

    if (curTab < 2) {
      const i = curTab
      if (cmd === "AutoAll") wasmModule.stagingcontoler.AutoAllStage(i)
      else if (cmd === "RemoveAll")
        wasmModule.stagingcontoler.RemoveAutoAllStage(i)
      else if (cmd === "RemoveSeq")
        wasmModule.stagingcontoler.RemoveSeqMoveNode(i)
      else if (cmd === "OldAutoSeq")
        wasmModule.stagingcontoler.StagingSeqMove(i, 0)
      else if (cmd === "NewAutoSeq")
        wasmModule.stagingcontoler.StagingSeqMove(i, 7)
      else if (cmd === "DistBySpc")
        wasmModule.stagingcontoler.StagingSeqMove(i, 1)
      else if (cmd === "PostExp")
        wasmModule.stagingcontoler.StagingSeqMove(i, 2)
      else if (cmd === "PremExtra")
        wasmModule.stagingcontoler.StagingSeqMove(i, 3)
      else if (cmd === "DistByBlock")
        wasmModule.stagingcontoler.StagingSeqMove(i, 4)
      else if (cmd === "AdptExp")
        wasmModule.stagingcontoler.StagingSeqMove(i, 5)
      else if (cmd === "SeqNormal") wasmModule.stagingcontoler.StagingSeqMove(i, 6)
      else if (cmd === "DistByStep")
        wasmModule.stagingcontoler.StagingSeqMove(i, 8)
      else if (cmd === "RemoveAutoStage")
        wasmModule.stagingcontoler.RemoveAutoAllStage(i)
      else if (cmd === "AutoIPRStage")
        wasmModule.stagingcontoler.AutoIPRStage(-1, i)
      else if (cmd === "AutoIPRStageCur")
        wasmModule.stagingcontoler.AutoIPRStage(currentStep, i)
      else if (cmd === "AutoIPRStageStart")
        wasmModule.stagingcontoler.AutoIPRStage(1, i)
      else if (cmd === "RemoveC_Chain")
        wasmModule.stagingcontoler.RemoveC_chainNode(i)
      else if (cmd === "cchain3x3")
        wasmModule.stagingcontoler.AddC_chainNode(i, false)
      else if (cmd === "cchain4x4")
        wasmModule.stagingcontoler.AddC_chainNode(i, true)
      else if (cmd === "AutoCorrBefore")
        wasmModule.stagingcontoler.AddOverCorr(i, false)
      else if (cmd === "AutoCorrFinal")
        wasmModule.stagingcontoler.AddOverCorr(i, true)
      else if (cmd === "RemoveCorr")
        wasmModule.stagingcontoler.RemoveOverCorr(i)
      wasmModule.stagingcontoler.FinalStage(i)
    } // tab =2
    else {
      for (let i = 0; i < 2; i++) {
        if (cmd === "VelWeekly") wasmModule.stagingcontoler.VelocityWeekly(i)
        else if (cmd === "VelBiWeekly")
          wasmModule.stagingcontoler.VelocityBiWeekly(i)
        else if (cmd === "SyncVelRestore")
          wasmModule.stagingcontoler.VelocityRestore()
        else if (cmd === "SyncVelSync")
          wasmModule.stagingcontoler.VelocitySync()
        else if (cmd === "SyncVelPassive")
          wasmModule.stagingcontoler.VelocityPassiveAligner()
      }
    }

    stagingManager.updateStagesFromWasm()
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup(!updateSetup))
    dispatch(setUpdateStaging())
  }

  const label = { inputProps: { "aria-label": "Size switch demo" } }

  const autoStagingBtns = [
    { label: "Auto", name: "AutoAll" },
    { label: "Remove", name: "RemoveAll" },
  ]
  const overCorrBtns = [
    { label: "Auto Corr Before", name: "AutoCorrBefore" },
    { label: "Auto Corr Final", name: "AutoCorrFinal" },
    { label: "Remove", name: "RemoveCorr" },
  ]

  const options = [
    "New Auto Seq", // 7
    "Dist by Space", //1
    "Post. Expanstion", //2
    "Premolar Extraction", // 3
    "Dist. by Block", //4
    "Adaptive ExpansiON", //5
    "Seq. Normal", // 6
    "Old Auto Seq", // 0
    "Dist by Step", // 8
    "Step 5", // 9
    "Step 4", // 10
  ]

  const seqMoveBtns = [
    { label: "New Auto", name: "NewAutoSeq" }, // 7
    { label: "Dist. by Space", name: "DistBySpc" }, // 1
    { label: "Post. Exp.", name: "PostExp" }, // 2
    { label: "Prem. Extr.", name: "PremExtra" }, // 3
    { label: "Dist. by Block", name: "DistByBlock" }, // 4
    { label: "Adpt. Exp", name: "AdptExp" }, //5
    { label: "Old Auto", name: "OldAutoSeq" }, //0
    { label: "Dist. by Step", name: "DistByStep" }, //8
  ]
  const stagingIPRBtns = [
    { label: "Auto", name: "AutoIPRStage" },
    { label: "Current Stage", name: "AutoIPRStageCur" },
    { label: "Start Stage", name: "AutoIPRStageStart" },
  ]

  const cchainBtns = [
    { label: "Ant. 3x3", name: "cchain3x3" },
    { label: "Ant. 4x4", name: "cchain4x4" },
  ]

  const wearTimeBtns = [
    { label: "Weekly", name: "VelWeekly" },
    { label: "Bi-weekly", name: "VelBiWeekly" },
  ]

  const syncULBtns = [
    { label: "Sync Vel", name: "SyncVelSync" },
    { label: "Passive", name: "SyncVelPassive" },
  ]

  const OnEditKF = () => {
    dispatch(setShowKFEditor(!showKFEditor))
  }

  const OnShowTMChart = () => {
    //  dispatch(setShowTMChart(!showTMChart))
  }

  const [openStagingDlg, setOpenStagingDlg] = useState(false)
  const handleStagingDlgClose = () => {
    console.log("Staing dlg close!")
    setOpenStagingDlg(false)
  }
  const onStagingDlgSubmit = ({
    ocType,
    seqType,
    iprStageType,
    cchainType,
  }) => {
    console.log("Staing dlg submit!")
    //setStagingDlg(false)
    const curJaw = curTab
    wasmModule.stagingcontoler.RemoveAutoAllStage(curJaw) // remove all staging
    if (ocType) wasmModule.stagingcontoler.AddOverCorr(curJaw, ocType == 2) //1: before final, 2: after final
    wasmModule.stagingcontoler.StagingSeqMove(curJaw, seqType)
    if (cchainType)
      wasmModule.stagingcontoler.AddC_chainNode(curJaw, cchainType == 2) //1: 3x3 , 2: 4x4
    wasmModule.stagingcontoler.AutoIPRStage(
      iprStageType === 0 ? -1 : iprStageType === 1 ? currentStep : 1,
      curJaw,
    ) //0 - auto, 1- curstage
    stagingManager.updateStagesFromWasm()
    dispatch(setUpdateSetup(!updateSetup))
    dispatch(setUpdateStaging())
    setOpenStagingDlg(false)
  }
  const JawStagingTab = (prop, jaw: string) => {
    return (
      <>
        <FlowBtnGroup
          label="Auto All"
          btnGroup={autoStagingBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        >
          <Grid item xs={3}>
            <FlowToggleButton
              value="EditKF"
              selected={showKFEditor}
              onClick={OnEditKF}
            >
              KF Edit
            </FlowToggleButton>
          </Grid>
        </FlowBtnGroup>
        {/* 
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              setOpenStagingDlg(true)
            }}
          >
            Custom...
          </Button>
        </Grid>*/}
        <FlowBtnGroup
          label="Over Corr"
          btnGroup={overCorrBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        ></FlowBtnGroup>
        <FlowBtnGroup
          label="Sequential Movement"
          btnGroup={seqMoveBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        />
        <FlowBtnGroup
          label="Staging IPR"
          btnGroup={stagingIPRBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        />
        <FlowBtnGroup
          label="C-chain"
          btnGroup={cchainBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        />
        <WeStagingDlg
          open={openStagingDlg}
          onSubmit={onStagingDlgSubmit}
          handleClose={handleStagingDlgClose}
        />
      </>
    )
  }
  const VelocityTab = (props) => {
    return (
      <>
        <FlowBtnGroup
          label="Wearing Time"
          btnGroup={wearTimeBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        />
        <FlowBtnGroup
          label="Sync. U/L"
          btnGroup={syncULBtns}
          onButtonClick={onClickStagingBtn}
          btnW={4}
        />
      </>
    )
  }
  const onTabChange = (tab) => {
    setCurTab(tab)
    const showArch = tab === 0 ? "up" : tab === 1 ? "low" : "all"
    viewControlInTreatment.showArchMode(showArch)
    dispatch(setCurArch(showArch))
  }

  useEffect(() => {
    if (!wasmModule.isInit) return
    const newTab = curArch === "up" ? 0 : curArch === "low" ? 1 : 2
    setCurTab(newTab)
  }, [curArch])

  return (
    <RightPage
      sx={{ width: "450px" }}
      title="WeDesign Staging"
      onClose={props.onClose}
      content={
        <FlowTabs2 value={curTab} onChange={onTabChange}>
          <JawStagingTab jaw="upper" label="Upper" />
          <JawStagingTab jaw="lower" label="Lower" />
          <VelocityTab label="Velocity" />
        </FlowTabs2>
      }
    />
  )
}
