import { FC } from "react"
import { Route, Routes } from "react-router"

import HelpContent from "./help/HelpContent"
import ReleaseNotes from "./help/ReleaseNotes"
import Shortcuts from "./help/Shortcuts"

const HelpModule: FC = () => {
  return (
    <Routes>
      <Route path={"help"} element={<HelpContent />} />
      <Route path={"shortcuts"} element={<Shortcuts />} />
      <Route path={"releaseNotes"} element={<ReleaseNotes />} />
    </Routes>
  )
}

export default HelpModule
