import { FC, ReactNode } from "react"
import React from "react"
import { createTheme, ThemeProvider } from "@mui/material"

import uSmileLightTheme from "./theme/theme.light"

const UsmileLightThemeProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={createTheme(uSmileLightTheme)}>
      {children}
    </ThemeProvider>
  )
}

export default UsmileLightThemeProvider
