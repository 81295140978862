export const MY_CUSTOM_PACKAGING_TABLE_CONFIG = [
  {
    name: "Image",
    id: "imageView",
    dataKey: "imageView",
    visible: true,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Account #",
    id: "accNo",
    dataKey: "accNo",
    visible: false,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Name",
    id: "packageName",
    dataKey: "customPackagingName",
    visible: true,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Company name",
    id: "companyName",
    dataKey: "orgName",
    visible: false,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Created on",
    id: "createdDate",
    dataKey: "createdDate",
    visible: true,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Status",
    id: "status",
    dataKey: "packagingStatus",
    visible: true,
  },
]

export const PACKAGING_STATUS = [
  {
    status: "Pending",
    style: {
      color: "info.main",
      variant: "outlined",
      border: "1px solid",
      borderColor: "primary.dark",
    },
  },
  {
    status: "Disabled",
    style: {
      color: "secondary.contrastText",
      variant: "filled",
      backgroundColor: "text.disabled",
    },
  },
  {
    status: "Purged",
    style: {
      color: "secondary.contrastText",
      variant: "filled",
      backgroundColor: "text.disabled",
    },
  },
  {
    status: "Uploaded",
    style: {
      color: "primary.dark",
      variant: "outlined",
      border: "1px solid",
      borderColor: "primary.dark",
    },
  },
  {
    status: "Approved",
    style: {
      color: "secondary.contrastText",
      variant: "filled",
      backgroundColor: "success.main",
    },
  },
  {
    status: "Unapproved",
    style: {
      color: "secondary.contrastText",
      variant: "filled",
      backgroundColor: "error.main",
    },
  },
  {
    status: "Sent For Approval",
    style: {
      color: "primary.dark",
      variant: "outlined",
      border: "1px solid",
      borderColor: "primary.dark",
    },
  },
]
