import { ThemeOptions } from "@mui/material/styles"

import uSmileLightTheme from "./theme.light"

const uSmileDarkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
    },
    primary: {
      main: "#90CAF9",
      dark: "#42A5F5",
      light: "#E3F2FD",
      contrastText: "rgba(0, 0, 0, 0.87)",
      hover: "rgba(144, 202, 249, 0.08)",
      selected: "rgba(144, 202, 249, 0.16)",
      focus: "rgba(144, 202, 249, 0.12)",
      focusVisible: "rgba(144, 202, 249, 0.3)",
      outlineBorder: "rgba(144, 202, 249, 0.5)",
    },
    secondary: {
      main: "#CE93D8",
      dark: "#AB47BC",
      light: "#F3E5F5",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: "#F44336",
      dark: "#D32F2F",
      light: "#E57373",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    warning: {
      main: "#FFA726",
      dark: "#F57C00",
      light: "#FFB74D",
      contrastText: "#rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#29B6F6",
      dark: "#0288D1",
      light: "#4FC3F7",
      contrastText: "#rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#66BB6A",
      dark: "#388E3C",
      light: "#81C784",
      contrastText: "#rgba(0, 0, 0, 0.87)",
    },
    divider: "rgba(255, 255, 255, 0.1)",
    text: {
      primary: "rgba(255, 255, 255, 1)",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.3)",
    },
  },
  ...uSmileLightTheme.typography,
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined", // 'outlined' | 'standard' | 'filled'
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          //Todo: we can enable in the fur
          // boxShadow:
          //   "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
          height: 36,
          padding: "6px, 16px, 6px, 16px",
        },
      },
      //TODO: if need we can enabl in the future
      //   variants: [
      //     {
      //       props: { variant: "shade" },
      //       style: {
      //         color: "rgba(0, 0, 0, 0.87)",
      //         background: "#E0E0E0",
      //         "&:hover": {
      //           color: "rgba(0, 0, 0, 0.87)",
      //           background: "#E0E0E0",
      //         },
      //       },
      //     },
      //   ],
    },
    //TODO: if need we can enabl in the future
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& fieldset": {
            border: `1px solid rgba(255, 255, 255, 0.23)`,
          },
          "&:hover:not(.Mui-focused ) fieldset": {
            border: `1px solid rgba(255, 255, 255, 0.12) !important`,
          },
          "&.Mui-error": {
            "&:hover fieldset": {
              border: `1px solid #F44336 !important`,
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
          },
        },
      },
    },
  },
}

export default uSmileDarkTheme
