import { FC, useEffect, useRef, useState } from "react"
import AdyenCheckout from "@adyen/adyen-web"

import { ICardDetails } from "./adyen.type"
import { ADEN_CONFIG } from "./adyen,config"

import "./adyen.scss"

export const PaymentContainer: FC<{
  updateCardInfo: (card: ICardDetails) => void
}> = ({ updateCardInfo }) => {
  return (
    <div id="payment-page">
      <div className="container">
        <AdyenForm updateCardInfo={updateCardInfo} />
      </div>
    </div>
  )
}

const AdyenForm: FC<{ updateCardInfo: (card: ICardDetails) => void }> = ({
  updateCardInfo,
}) => {
  const [cardInfo, setCardInfo] = useState<ICardDetails>({} as ICardDetails)
  const payment = ADEN_CONFIG
  const paymentContainer = useRef(null)

  useEffect(() => {
    if (cardInfo) {
      updateCardInfo(cardInfo)
    }
  }, [cardInfo])

  useEffect(() => {
    const { config } = payment
    let ignore = false

    if (!paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return
    }

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...config,
        onError: (_error: unknown, _component: unknown) =>
          console.log("onError", _error, _component),
        onChange: (state: any) => {
          if (state.isValid) {
            setCardInfo({ ...state.data.paymentMethod })
          }
        },
        onValid: (event: any) => {
          if (event.isValid) {
            setCardInfo({ ...event.data.paymentMethod })
          }
        },
      })
      window.checkout = checkout
      if (paymentContainer.current && !ignore) {
        checkout.create("card").mount(paymentContainer.current)
      }
    }
    createCheckout()
    return () => {
      ignore = true
    }
  }, [payment])

  return (
    <div className="payment-container">
      <div ref={paymentContainer} className="payment"></div>
    </div>
  )
}

export default AdyenForm
