import { FC } from "react"
import { useTranslation } from "react-i18next"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { UText } from "../../../../components"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Grid,
} from "../../../../components/mui.components"
import { IBundleInfo } from "../../../../core/model/interface/IQuotation"
import { formatCurrency } from "../../../../core/utils/formatters"

const ExtraAlaCarte: FC<{
  bundle: IBundleInfo
  currency: string
  countryCode: string
}> = ({ bundle, currency, countryCode }) => {
  const { t } = useTranslation()

  return (
    <>
      {bundle?.alacarte && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", backgroundColor: "#FFF4E5" }}>
            <Alert
              severity="warning"
              sx={{
                borderRadius: "4px",
                py: 0,
              }}
            >
              <AlertTitle sx={{ display: "flex", height: "20px" }}>
                <UText variant={"caption"}>
                  {t("bundle.alacarte.extraneeded")}
                </UText>
              </AlertTitle>
              <UText variant={"h6"}>
                <strong>
                  {formatCurrency(bundle.total_price, currency, countryCode)}/
                </strong>
              </UText>
              <UText variant={"body2"}>{t("bundle.alacarte.fullcase")}</UText>
            </Alert>
            <Box>
              <Accordion
                elevation={0}
                sx={{
                  root: {
                    "&:before": {
                      border: "none !important",
                    },
                  },
                  backgroundColor: "#FFF4E5",
                  borderRadius: "4px",
                  border: "none",
                }}
              >
                <AccordionSummary
                  sx={{
                    border: "none",
                    color: (theme) => theme.palette.primary.main,
                    "&.Mui-expanded": {
                      minHeight: "30px",
                      maxHeight: "40px",
                    },
                  }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "#215ECD" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <UText
                    variant={"body2"}
                    sxProp={{
                      fontWeight: 500,
                      textTransform: "uppercase",
                    }}
                  >
                    {t("bundle.alacarte.viewcost")}
                  </UText>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <Grid
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{ my: 1 }}
                    >
                      <Grid item sx={{ display: "flex" }}>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00" }}
                        >
                          {t("bundle.alacarte.bundleprice")}
                        </UText>
                      </Grid>
                      <Grid item sx={{ display: "flex" }}>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00" }}
                        >
                          {formatCurrency(
                            bundle.description.price,
                            currency,
                            countryCode,
                          )}
                        </UText>
                      </Grid>
                    </Grid>

                    <Grid
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "warning.main", display: "flex" }}
                        >
                          {t("bundle.alacarte.extra")}
                        </UText>
                      </Grid>
                      <Grid item>
                        {bundle.alacarte.aligner && (
                          <UText
                            variant={"caption"}
                            sxProp={{ color: "warning.main", display: "flex" }}
                          >
                            {`${
                              bundle.alacarte.aligner.item_count
                            } x ${formatCurrency(
                              bundle.alacarte.aligner.item_per_cost,
                              currency,
                              countryCode,
                            )}`}
                          </UText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{ my: 1 }}
                    >
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00", display: "flex" }}
                        >
                          {t("bundle.alacarte.uassistService")}
                        </UText>
                      </Grid>
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00", display: "flex" }}
                        >
                          {t("bundle.alacarte.included")}
                        </UText>
                      </Grid>
                    </Grid>

                    <Grid
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00", display: "flex" }}
                        >
                          {t("bundle.alacarte.standard")}
                        </UText>
                      </Grid>
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00", display: "flex" }}
                        >
                          {t("bundle.alacarte.included")}
                        </UText>
                      </Grid>
                    </Grid>
                    <Grid
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{ my: 1 }}
                    >
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00" }}
                        >
                          {t("bundle.alacarte.packaging")}
                        </UText>
                      </Grid>
                      <Grid item>
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00" }}
                        >
                          {t("bundle.alacarte.included")}
                        </UText>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      my={1}
                    >
                      <Grid
                        item
                        sx={{
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <UText
                          variant={"caption"}
                          sxProp={{
                            color: "#663C00",
                            textTransform: "uppercase",
                          }}
                        >
                          {t("bundle.alacarte.total")}
                        </UText>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <UText
                          variant={"caption"}
                          sxProp={{ color: "#663C00" }}
                        >
                          {formatCurrency(
                            bundle.total_price,
                            currency,
                            countryCode,
                          )}
                        </UText>
                      </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", mb: 1 }}>
                      <UText variant={"caption"} color={"text.secondary"}>
                        {t("bundle.alacarte.alacartenote")}
                      </UText>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ExtraAlaCarte
