import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import ActiveOrders from "./ActiveOrdersList"

const ActiveOrdersModule: FC = () => {
  return (
    <Routes>
      <Route path={""} element={<ActiveOrders />} />
      <Route path={"*"} element={<Navigate to={""} replace />} />
    </Routes>
  )
}

export default ActiveOrdersModule
