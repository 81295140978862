import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import PatientInfoHeader from "../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { useAppDispatch } from "../../core/app/hooks"
import { setIsRouteChangeBlocked } from "../../core/app/slices/navigationPopup/navigationPopupSlice"
import { UText } from "../../ui-component"
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
} from "../../ui-component/mui.components"

import ExitOrderAlertPopup from "./ExitOrderAlertPopup"

export interface OrderProps {
  activeStep: number
  completed: { [k: number]: boolean }
  steps: { id: string; label: string; to: string }[]
}
const OrderSteps: FC<OrderProps> = ({ activeStep, completed, steps }) => {
  const { t } = useTranslation("common")
  const [showAlert, setShowAlert] = useState(false)
  const dispatch = useAppDispatch()

  return (
    <>
      <Container sx={{ pt: 4 }}>
        <PatientInfoHeader
          chipLabel={""}
          cancelBtnText={t("button.close")}
          onBtnCancelClick={() => {
            setShowAlert(true)
            dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
          }}
          chipSx={{
            background: "#0288D1",
            fontSize: "13px",
            fontWeight: "400px",
            color: "primary.contrastText",
          }}
        />
        <Box maxWidth={"lg"} sx={{ mb: 4 }}>
          <UText
            sxProp={{ display: "flex", justifyContent: "center", mb: "10px" }}
            variant={"h4"}
          >
            {t("overview.order.title")}
          </UText>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {steps.map((item, index) => (
              <Step key={item.id} completed={completed[index]}>
                <StepLabel>{item.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
      <ExitOrderAlertPopup
        showAlert={showAlert}
        closeAlert={() => {
          dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
          setShowAlert(false)
        }}
        buttonTxt={t("button.close")}
      />
    </>
  )
}

export default OrderSteps
