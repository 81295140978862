import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { UTextField } from "../../../../../components"
import { Box, SxProps } from "../../../../../components/mui.components"

const USearch: FC<{
  search: string
  updateSearch: (search: string) => void
  sxProp: SxProps
}> = ({ search, updateSearch, sxProp }) => {
  const [value, setValue] = useState<string>("")
  const { t } = useTranslation("common")

  useEffect(() => {
    setValue(search)
  }, [search])
  return (
    <Box component={"div"} sx={{ width: "auto", height: "40px" }}>
      <UTextField
        sxProp={sxProp}
        fieldType={"text"}
        fieldName={"search"}
        inputLabel={t("patientList.searchLabel")}
        value={value}
        onInputchange={(value) => {
          setValue(value)
          updateSearch(value)
        }}
      />
    </Box>
  )
}

export default USearch
