import attach0 from "@/assets/images/clinical/report/attachment/attach0.svg"
import attach1 from "@/assets/images/clinical/report/attachment/attach1.svg"
import attach2 from "@/assets/images/clinical/report/attachment/attach2.svg"
import attach3 from "@/assets/images/clinical/report/attachment/attach3.svg"
import attach8 from "@/assets/images/clinical/report/attachment/attach8.svg"
import attach9 from "@/assets/images/clinical/report/attachment/attach9.svg"
import attach10 from "@/assets/images/clinical/report/attachment/attach10.svg"
import attach11 from "@/assets/images/clinical/report/attachment/attach11.svg"
import attach12 from "@/assets/images/clinical/report/attachment/attach12.svg"
import attach13 from "@/assets/images/clinical/report/attachment/attach13.svg"
import attach14 from "@/assets/images/clinical/report/attachment/attach14.svg"
import attach15 from "@/assets/images/clinical/report/attachment/attach15.svg"
import attach16 from "@/assets/images/clinical/report/attachment/attach16.svg"
import attach17 from "@/assets/images/clinical/report/attachment/attach17.svg"
import attach18 from "@/assets/images/clinical/report/attachment/attach18.svg"
import attach21 from "@/assets/images/clinical/report/attachment/attach21.svg"
import attach22 from "@/assets/images/clinical/report/attachment/attach22.svg"
import attach29 from "@/assets/images/clinical/report/attachment/attach29.svg"
import attach30 from "@/assets/images/clinical/report/attachment/attach30.svg"
import attach31 from "@/assets/images/clinical/report/attachment/attach31.svg"
import cutoutSvg from "@/assets/images/clinical/report/auxilliaries/cutout.svg"
import cutoutDownSvg from "@/assets/images/clinical/report/auxilliaries/cutoutBox.svg"
import cutoutUpSvg from "@/assets/images/clinical/report/auxilliaries/cutoutUp.svg"
import missSvg from "@/assets/images/clinical/report/auxilliaries/miss.svg"
import pressureSvg from "@/assets/images/clinical/report/auxilliaries/pressure.svg"
import pressureBoxSvg from "@/assets/images/clinical/report/auxilliaries/pressureBox.svg"
import slitSvg from "@/assets/images/clinical/report/auxilliaries/slit.svg"
import slitBox24 from "@/assets/images/clinical/report/auxilliaries/slitBox.svg"
import slitBox13 from "@/assets/images/clinical/report/auxilliaries/slitBox13.svg"
import biteSvg from "@/assets/images/clinical/report/auxilliaries/bite.svg"
import biteBoxSvg from "@/assets/images/clinical/report/auxilliaries/biteBox.svg"
export const attachmentIDs = [
  0, 1, 2, 3, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 29, 30, 31,
]

export const attachmentSvg = {
  0: {
    src: attach0,
    name: "Generic 1",
  },
  1: {
    src: attach1,
    name: "Generic 2",
  },
  2: {
    src: attach2,
    name: "Generic 3",
  },
  3: {
    src: attach3,
    name: "Generic 4",
  },
  8: {
    src: attach8,
    name: "Posterior retention",
  },
  9: {
    src: attach9,
    name: "Posterior torque retention",
  },
  10: {
    src: attach10,
    name: "Anterior tipping twin 1",
  },
  11: {
    src: attach11,
    name: "Bi-planar 1",
  },
  12: {
    src: attach12,
    name: "Bi-planar 2",
  },
  13: {
    src: attach13,
    name: "Multiplanar",
  },
  14: {
    src: attach14,
    name: "Rotation 1",
  },
  15: {
    src: attach15,
    name: "Rotation 2",
  },
  16: {
    src: attach16,
    name: "Anchorage",
  },
  17: {
    src: attach17,
    name: "Posterior anchorage",
  },
  18: {
    src: attach18,
    name: "Posterior tipping 1",
  },
  21: {
    src: attach21,
    name: "Anterior tipping twin 2",
  },
  22: {
    src: attach22,
    name: "Posterior tipping 2",
  },
  29: {
    src: attach29,
    name: "Reva 1",
  },
  30: {
    src: attach30,
    name: "Reva 2 small",
  },
  31: {
    src: attach31,
    name: "Reva 2 large",
  },
}

export const legendsTmp = [
  {
    key: "Pressure point",
    name: "Pressure point",
    icon: pressureSvg,
  },
  {
    key: "Slit",
    name: "Slit",
    icon: slitSvg,
  },
  {
    key: "Button cutout",
    name: "Button cutout",
    icon: cutoutSvg,
  },
  {
    key: "Missing tooth",
    name: "Missing tooth",
    icon: missSvg,
  },
  {
    key: "Bite Ramp",
    name: "Bite Ramp",
    icon: biteSvg,
  },
]
export const qua1Ids = [1, 2, 3, 4, 5, 6, 7, 8]
export const qua2Ids = [9, 10, 11, 12, 13, 14, 15, 16]
export const qua3Ids = [24, 23, 22, 21, 20, 19, 18, 17]
export const qua4Ids = [32, 31, 30, 29, 28, 27, 26, 25]

export const qua1AllIds = [
  [1, 501, 701],
  [2, 502, 702],
  [3, 503, 703],
  [4, 504, 704],
  [5, 505, 705],
  [6, 506, 706],
  [7, 507, 707],
  [8, 508, 708],
]
export const qua2AllIds = [
  [9, 509, 709],
  [10, 510, 710],
  [11, 511, 711],
  [12, 512, 712],
  [13, 513, 713],
  [14, 514, 714],
  [15, 515, 715],
  [16, 516, 716],
]
export const qua3AllIds = [
  [24, 524, 724],
  [23, 523, 723],
  [22, 522, 722],
  [21, 521, 721],
  [20, 520, 720],
  [19, 519, 719],
  [18, 518, 718],
  [17, 517, 717],
]
export const qua4AllIds = [
  [32, 532, 732],
  [31, 531, 731],
  [30, 530, 730],
  [29, 529, 729],
  [28, 528, 728],
  [27, 527, 727],
  [26, 526, 726],
  [25, 525, 725],
]
export const leftNums = [8, 7, 6, 5, 4, 3, 2, 1]
export const rightNums = [1, 2, 3, 4, 5, 6, 7, 8]

export const svgs = {
  pressure: pressureBoxSvg,
  slit24: slitBox24,
  slit13: slitBox13,
  cutoutUp: cutoutUpSvg,
  cutoutDown: cutoutDownSvg,
  miss: missSvg,
  bite: biteBoxSvg,
}

export const pressIDs = [7, 19, 20]
export const cutoutIDs = [4, 5, 6]
export const slitIDs = [23]
export const biteIDs = [27, 28]

// number setting label
export const labels = {
  Pedro: {
    // qua1: ["A8", "A7", "A6", "A5", "A4", "A3", "A2", "A1"],
    // qua2: ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8"],
    // qua3: ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8"],
    // qua4: ["D8", "D7", "D6", "D5", "D4", "D3", "D2", "D1"],
    qua1: [8, 7, 6, 5, 4, 3, 2, 1],
    qua2: [1, 2, 3, 4, 5, 6, 7, 8],
    qua3: [1, 2, 3, 4, 5, 6, 7, 8],
    qua4: [8, 7, 6, 5, 4, 3, 2, 1],
  },
  FDI: {
    qua1: [18, 17, 16, 15, 14, 13, 12, 11],
    qua2: [21, 22, 23, 24, 25, 26, 27, 28],
    qua3: [31, 32, 33, 34, 35, 36, 37, 38],
    qua4: [48, 47, 46, 45, 44, 43, 42, 41],
  },
  Palmer: {
    qua1: [8, 7, 6, 5, 4, 3, 2, 1],
    qua2: [1, 2, 3, 4, 5, 6, 7, 8],
    qua3: [1, 2, 3, 4, 5, 6, 7, 8],
    qua4: [8, 7, 6, 5, 4, 3, 2, 1],
  },
  General: {
    qua1: [1, 2, 3, 4, 5, 6, 7, 8],
    qua2: [9, 10, 11, 12, 13, 14, 15, 16],
    qua3: [24, 23, 22, 21, 20, 19, 18, 17],
    qua4: [32, 31, 30, 29, 28, 27, 26, 25],
  },
}
