import { FC, useEffect, useMemo, useState } from "react"
import { Trans } from "react-i18next"
import { matchPath, Outlet, useLocation } from "react-router-dom"

import { Alert, Grid } from "../components/mui.components"
import { useAppSelector } from "../core/app/hooks"
import { getContactInfo } from "../modules/contact.config"
import { UText } from "../ui-component"

const Help: FC = () => {
  const location = useLocation()
  const [showAlert, setShowAlert] = useState(false)

  const { countryCode } = useAppSelector((state) => state.userService)
  const contactInfo = useMemo(() => getContactInfo(countryCode), [countryCode])

  useEffect(() => {
    if (location?.pathname) {
      if (matchPath("/help/help", location.pathname)) {
        setShowAlert(true)
      } else {
        setShowAlert(false)
      }
    }
  }, [location])
  return (
    <>
      {showAlert && (
        <Alert
          severity={"info"}
          icon={false}
          sx={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#2196F31F",
            justifyContent: "center",
            padding: 2,
            ".MuiAlert-message": {
              p: 0,
              color: "#000000",
            },
          }}
        >
          <UText variant={"body2"}>
            <Trans
              components={{
                bold: (
                  <b
                    style={{
                      fontWeight: "600",
                    }}
                  />
                ),
              }}
              values={{
                phone: contactInfo.phone,
              }}
            >
              {"help.alertMsg"}
            </Trans>
          </UText>
        </Alert>
      )}
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        minWidth={"lg"}
        sx={{ minHeight: "85vh" }}
      >
        <Grid container maxWidth={"lg"} flexDirection={"column"}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  )
}

export default Help
