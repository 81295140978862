import http from "../../../global/http"
import { Banner } from "../../../model/Banner"
import { IBanner } from "../../../model/interface/IBanner"

export const getBannerMessage = async (orgId, patientId, caseId) => {
  try {
    const res = await http.get(
      `/shared/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/messages/type/warning_banner`,
    )
    let obj: IBanner = {} as IBanner
    if (res && res.status === 200) {
      if (res?.data?.results?.warning_subject) {
        obj = new Banner().Deserialize(res.data.results)
      }
    }
    return obj
  } catch (err) {
    return err
  }
}

export const postMessage = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
) => {
  try {
    const { data } = await http.post(
      `/shared/v1/org/${orgId}/orchestration/patients/${patientId}/txplans/${caseId}/messages/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}
