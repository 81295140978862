import { createSlice } from "@reduxjs/toolkit"

import { IBundle } from "../../../model/interface/IBundle"

import { getBundleByCaseId } from "./bundleThunkApi"

interface IData {
  [key: string]: IBundle[]
}
interface BundleState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  bundleList: IData
}

const initialState: BundleState = {
  loading: "idle",
  bundleList: {},
}

export const BundleServiceSlice = createSlice({
  name: "bundleService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBundleByCaseId.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getBundleByCaseId.fulfilled, (state, action) => {
      if (action.payload.length) {
        state.bundleList = {
          ...state.bundleList,
          [action.meta.arg]: action.payload,
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(getBundleByCaseId.rejected, (state) => {
      state.loading = "failed"
    })
  },
})
