import { useCallback, useEffect, useState } from "react"

import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"
import {
  canAll,
  canAny,
  canNotHaveAll,
  canNotHaveAny,
} from "../core/utils/access/access"

const usePermissions = () => {
  const [permissions, setPermissions] = useState<string[]>([])
  const { user } = useAppSelector((state: RootState) => state.userService)

  useEffect(() => {
    if (user?.app_permissions) {
      setPermissions(user.app_permissions)
    }
  }, [user?.app_permissions])

  const hasAll = useCallback(
    (permission: string | string[]) => {
      return canAll(permissions as string[])(permission)
    },
    [permissions],
  )

  const hasAny = useCallback(
    (permission: string | string[]) => {
      return canAny(permissions as string[])(permission)
    },
    [permissions],
  )

  const doesNotHaveAll = useCallback(
    (permission: string | string[]) => {
      return canNotHaveAll(permissions as string[])(permission)
    },
    [permissions],
  )

  const doesNotHaveAny = useCallback(
    (permission: string | string[]) => {
      return canNotHaveAny(permissions as string[])(permission)
    },
    [permissions],
  )
  return { hasAny, hasAll, doesNotHaveAny, doesNotHaveAll }
}

export default usePermissions
