import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { UDTabs } from "../custom/UDTabs"
import { caseManagement, wasmModule } from "@/gluelayer"
import { LeftPage } from "../left/LeftPage"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

export function TMTable({ arch }) {
  const { updateStaging, updateSetup } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const getToothDiffLevel = (tid, idx, value) => {
    const toothType = [0, 0, 0, 1, 1, 2, 3, 3, 3, 3, 2, 1, 1, 0, 0, 0] //0: molar, 1: premolar, 2, canine, 3, incisor.
    const ttype = toothType[(tid - 1) % 16]
    const levelLimit0 = [
      [1, 1, 1, 10, 10, 10], // dist <1, rot <10
      [1, 1, 1, 10, 10, 10], // dist <1, rot <10
      [1, 1, 1, 10, 10, 10], // dist <1, rot <10
      [1, 1, 1, 10, 10, 10], // dist <1, rot <10
    ]
    const levelLimit1 = [
      [2, 2, 2, 20, 20, 20], // dist <2, rot <20
      [2, 2, 2, 20, 20, 20], // dist <2, rot <20
      [2, 2, 2, 20, 20, 20], // dist <2, rot <20
      [2, 2, 2, 20, 20, 20], // dist <2, rot <20
    ]
    if (Math.abs(value) < levelLimit0[ttype][idx]) return 0
    else if (Math.abs(value) < levelLimit1[ttype][idx]) return 1
    return 2
  }
  const getToothDiffColor = (tid, idx, value) => {
    const levelColor = ["white", "lightskyblue", "darksalmon"]
    const level = getToothDiffLevel(tid, idx, value)
    return levelColor[level]
  }
  const headNames = ["Tooth", "BL", "MD", "IE", "Trq", "Tip", "Rot"]
  const [rows, setRows] = useState([])
  const updateData = () => {
    const isUpper = arch === "upper"
    const report = caseManagement.getToothReports(isUpper)
    const currentPageReport = report.get(report.size - 1) || []
    let allRows = []
    currentPageReport.map(({ id, distanceRotate }) => {
      let row = []
      row.push(["T" + id.toString(), "white"])
      for (let i = 0; i < 6; i++) {
        const colorStr = getToothDiffColor(id, i, distanceRotate[i])
        row.push([distanceRotate[i].toFixed(1).toString(), colorStr])
      }
      allRows.push(row)
    })
    setRows(allRows)
  }
  useEffect(() => {
    updateData()
  }, [, updateSetup, updateStaging])

  return (
    <TableContainer sx={{ padding: 1 }} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headNames.map((name, key) => (
              <TableCell align="right" key={key}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            return (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {row.map((valueColor, idx) => (
                  <TableCell
                    sx={{ backgroundColor: valueColor[1] as string }}
                    key={idx}
                    component="th"
                    align="right"
                    scope="row"
                  >
                    {valueColor[0]}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function UDTMTable(props: any) {
  return (
    <LeftPage
      title="Tooth Movement Table"
      content={
        <UDTabs labels={["Upper", "Lower"]}>
          <TMTable arch="upper" />
          <TMTable arch="lower" />
        </UDTabs>
      }
      onClose={props.onClose}
    />
  )
}
