import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization";
import {
  wasmModule,
  WASMLifeCircleEventType,
} from "../../modules/wasm/wasmModule";

import { EShowArchType } from "../../common/types";
import { IDrawRefinementSTLOps, caseManagement } from "../../interfaces";
import { resourceManager } from "../resourcemanager";

/**
 *  WASM interface of preview
 *
 * enable preview
 * this will invoke a loop can't do something after invoke this function
 * wasmModule.module._scanPreview();
 *
 * set zoom callback
 * wasmModule.module.getScanPreviewWin().SetUpdateSliderCB
 *
 * set zoom
 * wasmModule.module.getScanPreviewWin().ZoomBySlider(scale)
 *
 * set range of zoom scale
 * wasmModule.module.getScanPreviewWin().SetMinScaleValue(double minScaleValue)
 * wasmModule.module.getScanPreviewWin().SetMaxScaleValue(double maxScaleValue)
 *
 * change arch
 * wasmModule.module.getScanPreviewWin().OnArchChanged(type)
 *
 * drwa arch
 * wasmModule.module.getScanPreviewWin().UpdateArch('upper arch path', 'lower arch path')
 * wasmModule.module.getScanPreviewWin().RemoveArch(isupper:boolean,islower:boolean);
 *
 * fill hole
 * wasmModule.module._autoFillHoles(wasmModule.module.allocateUTF8('test/pre/arch_u.stl'), wasmModule.module.allocateUTF8('test/pre/fillready_arch_u.stl'))
 *
 *
 */

interface IDrawSTLOps {
  upperArch: File | null;
  lowerArch: File | null;
  canvas: HTMLCanvasElement;
  zoomRange: [number, number];
  fillholeCallback?: (isSuccess: boolean, isUpperFlag: "up" | "low") => void;
}

export enum EArchTreatmentType {
  Aligner = 0,
  Retainer = 1,
  /**
   * not used in current version
   */
  RetainerMove = 2,
}
/**
 * draw STL when upload file
 */
let currentShowSTLUpper: Blob | null = null;
let currentShowSTLLower: Blob | null = null;
let stlFilePath: Record<string, any> = {};
let isEnabled = false;

export function saveArchType(
  upperType: EArchTreatmentType | null,
  lowerType: EArchTreatmentType | null
) {
  const archTypeData = resourcesSynchronization.getFileDirectly(
    "ArchType.json",
    "Setting Data2",
    "utf8"
  );

  if (!archTypeData) return;

  const dataObject = JSON.parse(archTypeData);
  // eslint-disable-next-line no-prototype-builtins
  if (dataObject.hasOwnProperty("upArch") && upperType != null) {
    dataObject.upArch = upperType;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (dataObject.hasOwnProperty("downArch") && lowerType != null) {
    dataObject.downArch = lowerType;
  }
  const newArchTypeData = JSON.stringify(dataObject);
  console.log("ashuaizzz🚀 ~ newArchTypeData:", newArchTypeData);
  resourcesSynchronization.saveJsonDataToZip(
    newArchTypeData,
    "ArchType.json",
    "Setting Data2"
  );
}

export async function saveDoRefinementStage(upperStage: any, lowerState: any) {
  if (!isEnabled) return false;

  const parasData = {
    upperTPStep: Number.parseInt(upperStage),
    lowerTPStep: Number.parseInt(lowerState),
  };

  const jsonData = JSON.stringify(parasData, (key, value) => {
    // 如果值是数字，不添加引号
    return typeof value === "number" ? value : value;
  });
  console.log("ashuaizzz🚀 ~save refinement stage jsonData:", jsonData);

  const blob = new Blob([jsonData], { type: "text/plain" });
  const file = new File([blob], `case/Setting Data10/refinement/paras.json`);
  const savePath: Record<string, any> = {};
  savePath[`test/case/Setting Data10/refinement/paras.json`] = file;

  const saveRes = await resourcesSynchronization.moduleFS(savePath);
  return saveRes;
}

export function isInitPreview() {
  return isEnabled;
}

export function drawScanMtc(zoomRange: [number, number] = [0.25, 2.0]) {
  if (!isEnabled) return
  console.log("DrawMtcFromZips :", zoomRange)
  const previewWin = wasmModule.module.getScanPreviewWin()
  if (!previewWin) return
  const mtcNames = [
    "/test/case/Raw/arch_o_u.mtc",
    "/test/case/Raw/arch_o_l.mtc",
  ]
  wasmModule.module.getScanPreviewWin().UpdateArch(mtcNames[0], mtcNames[1])
  wasmModule.module.getScanPreviewWin().SetMinScaleValue(zoomRange[0])
  wasmModule.module.getScanPreviewWin().SetMaxScaleValue(zoomRange[1])
  const mtcFiles = resourcesSynchronization.getMtcsFromURL([
    mtcNames[0],
    mtcNames[1],
  ])
  return mtcFiles
}

export async function drawMtcFromZips(
  zips: Record<string, File>,
  zoomRange: [number, number] = [0.25, 2.0],
) {
  if (!isEnabled) return

  console.log("DrawMtcFromZips :", zips, zoomRange)
  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)
  const previewWin = wasmModule.module.getScanPreviewWin()
  if (!previewWin) return

  const mtcNames = [
    "/test/case/Raw/arch_o_u.mtc",
    "/test/case/Raw/arch_o_l.mtc",
  ]
  wasmModule.module.getScanPreviewWin().UpdateArch(mtcNames[0], mtcNames[1])

  wasmModule.module.getScanPreviewWin().SetMinScaleValue(zoomRange[0])
  wasmModule.module.getScanPreviewWin().SetMaxScaleValue(zoomRange[1])

  const mtcFiles = resourcesSynchronization.getMtcsFromURL([
    mtcNames[0],
    mtcNames[1],
  ])
  return mtcFiles
}

export async function drawRefinementMtcFromZips(
  zips: Record<string, File>,
  zoomRange: [number, number] = [0.25, 2.0],
) {
  if (!isEnabled) return

  console.log("drawRefinementMtcFromZips :", zips, zoomRange)
  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)
  wasmModule.module
    .getScanPreviewWin()
    .UpdateArch("test/case/Setting Data10/refinement/arch_u.mtc", "")

  wasmModule.module
    .getScanPreviewWin()
    .UpdateArch("", "test/case/Setting Data10/refinement/arch_l.mtc")

  const mtcFiles = resourcesSynchronization.getMtcsFromURL([
    `/test/case/Setting Data10/refinement/arch_u.mtc`,
    `/test/case/Setting Data10/refinement/arch_l.mtc`,
  ])
  // console.log("mtcFiles ",mtcFiles);

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module.getScanPreviewWin().SetMinScaleValue(zoomRange[0])
    wasmModule.module.getScanPreviewWin().SetMaxScaleValue(zoomRange[1])
  }

  return mtcFiles
}

function getFileExtension(file: File) {
  // 使用split()方法将文件名拆分成名称和后缀
  const parts = file.name.split(".")
  const extension = parts[parts.length - 1].toLowerCase()
  return extension
}

// 让drawSTL可重入
let isInitingPreview = false

/**
 * 初始化 scan / preview
 * @param canvas
 * @param zips
 */
export async function initScanView(
  canvas: HTMLCanvasElement,
  zips: Record<string, File>,
) {
  const windowTitle = document.title
  await wasmModule.initWASM(canvas, async () => {
    resourcesSynchronization.fsUnlink()
    wasmModule.cancelemloop()
    wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945)
    await resourcesSynchronization.writeCaseFilesToVirtualFS(zips)
    isEnabled = true
    wasmModule.module._scanPreview(wasmModule.module.allocateUTF8(windowTitle))
    wasmModule.module
      .getScanPreviewWin()
      .UpdateArch("test/pre/arch_u.stl", "test/pre/arch_l.stl")
  })
}

/**
 * 初始化refinement view
 * @param canvas
 * @param zips
 */
export async function initRefinementView(
  canvas: HTMLCanvasElement,
  zips: Record<string, File>
) {
  const windowTitle = document.title;
  await wasmModule.initWASM(canvas, async () => {
    resourcesSynchronization.fsUnlink();
    wasmModule.cancelemloop();
    wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945);
    // this will invoke a loop can't do something after invoke this function
    setTimeout(() => {
      wasmModule.module._scanPreview(
        wasmModule.module.allocateUTF8(windowTitle)
      );
    }, 100);
    isEnabled = true;
  });

  console.log("initRefinementView zips :", zips);
  await resourcesSynchronization.writeCaseFilesToVirtualFS(zips);
}

/**
 * 在胶水层UI测试初始化refinement view
 * @param canvas
 * @param zips
 */
export async function testInitRefinementView(
  canvas: HTMLCanvasElement,
  zip: File
) {
  const windowTitle = document.title;
  await wasmModule.initWASM(canvas, async () => {
    wasmModule.cancelemloop();
    wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945);
    // this will invoke a loop can't do something after invoke this function
    setTimeout(() => {
      wasmModule.module._scanPreview(
        wasmModule.module.allocateUTF8(windowTitle)
      );
    }, 100);
    isEnabled = true;
  });

  const resFileList = await resourceManager.parseZipFilesFromAZipPacket(zip);
  const writeFileList = await resourcesSynchronization.makeVirtualDirectory(
    resFileList
  );
  await resourcesSynchronization.moduleFS(writeFileList);

  isInitingPreview = true;
}

export async function drawRefinementSTL(drawSTLOps: IDrawRefinementSTLOps) {
  if (!wasmModule.module.getScanPreviewWin()) {
    return;
  }

  wasmModule.module
    .getScanPreviewWin()
    .SetMinScaleValue(drawSTLOps.zoomRange[0]);
  wasmModule.module
    .getScanPreviewWin()
    .SetMaxScaleValue(drawSTLOps.zoomRange[1]);

  const { upperArch, lowerArch } = drawSTLOps;

  if (upperArch !== currentShowSTLUpper) {
    if (upperArch) {
      const extension = getFileExtension(upperArch);

      if (extension == "stl") {
        stlFilePath["test/pre/arch_u.stl"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);

        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/pre/arch_u.stl", "");

        const isSaveU = wasmModule.module.SaveMtcFileToFolder(
          "test/pre/arch_u.stl",
          "Setting Data10/refinement/arch_u.mtc"
        );

        console.log("isSaveLUpperMTC :", isSaveU);
      }
      currentShowSTLUpper = upperArch;
    } else if (currentShowSTLUpper) {
      wasmModule.module.getScanPreviewWin().RemoveArch(true, false);
      currentShowSTLUpper = null;
      resourcesSynchronization.deleteFile(
        "test/case/Setting Data10/refinement/arch_u.mtc"
      );
    }
  }

  if (lowerArch !== currentShowSTLLower) {
    if (lowerArch) {
      const extension = getFileExtension(lowerArch);
      if (extension == "stl") {
        stlFilePath["test/pre/arch_l.stl"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/pre/arch_l.stl");

        const isSaveL = wasmModule.module.SaveMtcFileToFolder(
          "test/pre/arch_l.stl",
          "Setting Data10/refinement/arch_l.mtc"
        );

        console.log("isSaveLlowerMTC :", isSaveL);
      }
      currentShowSTLLower = lowerArch;
    } else if (currentShowSTLLower) {
      wasmModule.module.getScanPreviewWin().RemoveArch(false, true);
      currentShowSTLLower = null;
      resourcesSynchronization.deleteFile(
        "test/case/Setting Data10/refinement/arch_l.mtc"
      );
    }
  }
}

export async function fillHole(fillholeCallback?: (isSuccess: boolean, isUpperFlag: "up" | "low") => void){
    const result={upper:null,lower:null}
    // // fill hole
    if (currentShowSTLUpper) {
      let archPath = "/test/case/Raw/arch_o_u.mtc"
      const mtcfile = resourcesSynchronization.getFile(archPath)
      if (!mtcfile) {
        archPath = "test/pre/arch_u.stl"
      }
      const ret = wasmModule.module._autoFillHoles(
        wasmModule.module.allocateUTF8(archPath),
        wasmModule.module.allocateUTF8("/test/case/Setting Data0/arch_u.mtc"),
      )
      // if (!ret) {
      //   if (fillholeCallback) {
      //     fillholeCallback(false, "up");
      //   }

      // }
      result.upper = ret ? true : false
    }

    if (currentShowSTLLower) {
      let archPath = "/test/case/Raw/arch_o_l.mtc"
      const mtcfile = resourcesSynchronization.getFile(archPath)
      if (!mtcfile) {
        archPath = "test/pre/arch_l.stl"
      }
      const ret = wasmModule.module._autoFillHoles(
        wasmModule.module.allocateUTF8(archPath),
        wasmModule.module.allocateUTF8("/test/case/Setting Data0/arch_l.mtc"),
      )
      // if (!ret) {
      //   if (fillholeCallback) {
      //     fillholeCallback(false, "low");
      //   }
      // }
      result.lower = ret ? true : false
    }

    return result;

}





export async function drawSTL(drawSTLOps: IDrawSTLOps) {
  if (!isEnabled) {
    if (isInitingPreview) {
      return;
    }
    if (!isInitingPreview) {
      isInitingPreview = true;
    }

    const windowTitle = document.title;
    await wasmModule.initWASM(drawSTLOps.canvas, async () => {
      resourcesSynchronization.fsUnlink();
      wasmModule.cancelemloop();
      wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945);
      // this will invoke a loop can't do something after invoke this function
      setTimeout(() => {
        wasmModule.module._scanPreview(
          wasmModule.module.allocateUTF8(windowTitle)
        );
      }, 100);
      isEnabled = true;
      isInitingPreview = false;
    });
    return;
  }

  if (!wasmModule.module.getScanPreviewWin()) {
    return;
  }

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module
      .getScanPreviewWin()
      .SetMinScaleValue(drawSTLOps.zoomRange[0]);
    wasmModule.module
      .getScanPreviewWin()
      .SetMaxScaleValue(drawSTLOps.zoomRange[1]);
  }

  const { upperArch, lowerArch, fillholeCallback } = drawSTLOps;

  if (upperArch !== currentShowSTLUpper) {
    if (upperArch) {
      const extension = getFileExtension(upperArch);

      if (extension == "stl") {
        stlFilePath["test/pre/arch_u.stl"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        // remove filling hole by zasidle @2023/11/7
        // // fill hole
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_u.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_u.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "up");
        //   }
        // }
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/pre/arch_u.stl", "");
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_u.mtc"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/case/Raw/arch_o_u.mtc", "");
      }
      currentShowSTLUpper = upperArch;
    } else if (currentShowSTLUpper) {
      wasmModule.module.getScanPreviewWin().RemoveArch(true, false);
      currentShowSTLUpper = null;
      // const deleteMtcUpper = wasmModule.module.DeleteMtcFiles(0);
      // console.log("deleteMtcUpper :", deleteMtcUpper);
      resourcesSynchronization.deleteFile("test/case/Raw/arch_o_u.mtc");
    }
  }

  if (lowerArch !== currentShowSTLLower) {
    if (lowerArch) {
      const extension = getFileExtension(lowerArch);
      if (extension == "stl") {
        stlFilePath["test/pre/arch_l.stl"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);

        // remove filling hole by zasidle @2023/11/7
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_l.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_l.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "low");
        //   }
        // }

        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/pre/arch_l.stl");
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_l.mtc"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/case/Raw/arch_o_l.mtc");
      }

      currentShowSTLLower = lowerArch;
    } else if (currentShowSTLLower) {
      wasmModule.module.getScanPreviewWin().RemoveArch(false, true);
      currentShowSTLLower = null;
      resourcesSynchronization.deleteFile("test/case/Raw/arch_o_l.mtc");

      // const isDeleteMtcLower = wasmModule.module.DeleteMtcFiles(1);
      // console.log("isDeleteMtcLower :", isDeleteMtcLower);
    }
  }

  // create ArchType.json

  let archType;
  if (upperArch && lowerArch) {
    archType = {
      KeepPalate: false,
      downArch: 0,
      upArch: 0,
    };
  } else if (upperArch == null) {
    archType = {
      downArch: 0,
    };
  } else if (lowerArch == null) {
    archType = {
      KeepPalate: false,
      upArch: 0,
    };
  }

  const archTypeJson = JSON.stringify(archType);
  console.log("archTypeJson:", archTypeJson);

  const isSaveData = await caseManagement.saveJsonDataToZip(
    archTypeJson,
    "ArchType.json",
    "Setting Data2"
  );

  console.log("isSaveData :", isSaveData);

  // const blob = new Blob([archTypeJson], { type: "text/plain" });
  // const file = new File([blob], "case/Setting Data2/ArchType.json");
  // const archTypeJsonPath: Record<string, any> = {};
  // archTypeJsonPath["test/case/Setting Data2/ArchType.json"] = file;

  // await resourcesSynchronization.moduleFS(archTypeJsonPath);
}

export function saveStlToMtc(upperStl: File | null, lowerStl: File | null) {
  if (!upperStl && !lowerStl) {
    console.log("At least one jaw needs to be preserved ");
    resourcesSynchronization.deleteFile("test/case/Raw/arch_o_u.mtc");
    resourcesSynchronization.deleteFile("test/case/Raw/arch_o_l.mtc");

    return 0;
  }
  if (upperStl) {
    const ext = getFileExtension(upperStl);
    if (ext == "stl") {
      const isSaveRaw = wasmModule.module.SaveMtcFileToFolder(
        "test/pre/arch_u.stl",
        "Raw/arch_o_u.mtc"
      );
      console.log("ashuaizzz🚀 ~ isSaveRawUpper:", isSaveRaw);
    }
  } else {
    resourcesSynchronization.deleteFile("test/case/Raw/arch_o_u.mtc");
  }

  if (lowerStl) {
    const ext = getFileExtension(lowerStl);
    if (ext == "stl") {
      const isSaveRaw = wasmModule.module.SaveMtcFileToFolder(
        "test/pre/arch_l.stl",
        "Raw/arch_o_l.mtc"
      );
      console.log("ashuaizzz🚀 ~ isSaveRawLower:", isSaveRaw);
    }
  } else {
    resourcesSynchronization.deleteFile("test/case/Raw/arch_o_l.mtc");
  }

  const rawList = resourcesSynchronization.getDirInVituralFS("Raw");

  return 1;
}

/**
 *changeArchMode
 *param name  (1、up.2、low.3、both)
 * */
export function changeArchMode(viewState: "up" | "low" | "both") {
  if (!wasmModule.module.getScanPreviewWin()) {
    return;
  }
  const type =
    viewState === "up"
      ? EShowArchType.UpArch
      : viewState === "low"
      ? EShowArchType.LowArch
      : EShowArchType.BothArch;
  wasmModule.module.getScanPreviewWin().OnArchChanged(type);
}

/**
 * setup zoom callback
 * @param zoomRange  the [min,max] tuple value of the scale
 * @param callback  (val: number) => void)
 */
export async function setZoomCallback(callback: (val: number) => void) {
  if (!isEnabled) {
    return;
  }
  if (wasmModule.module.getScanPreviewWin && callback) {
    (window as any).preview = {};
    (window as any).preview.preViewCallback = callback;
    if (!wasmModule.module.getScanPreviewWin()) {
      console.warn(
        "module getScanPreviewWin false,cant set update-slider callback."
      );
      return;
    }
    wasmModule.module
      .getScanPreviewWin()
      .SetUpdateSliderCB("preview.preViewCallback");
  }
}

/**
 * zoom with scale
 * @param scale
 */
export function zoomWithValue(scale: number) {
  if (!wasmModule.module.getScanPreviewWin()) {
    return;
  }
  wasmModule.module.getScanPreviewWin().ZoomBySlider(scale);
  // console.log("zoomWithValue:", scale);
}

/**
 * clear preview state after out of preview
 */
export function clearPreview() {
  isEnabled = false;
  currentShowSTLUpper = null;
  currentShowSTLLower = null;
  stlFilePath = {};
}



export async function drawSTL2(drawSTLOps: IDrawSTLOps) {
  if (!isEnabled) {
    if (isInitingPreview) {
      return;
    }
    if (!isInitingPreview) {
      isInitingPreview = true;
    }

    const windowTitle = document.title;

    resourcesSynchronization.fsUnlink();
    wasmModule.cancelemloop();
    wasmModule.module.setPreviewColor(0.69, 0.86, 0.945, 0.69, 0.86, 0.945);
    // this will invoke a loop can't do something after invoke this function
    setTimeout(() => {
      wasmModule.module._scanPreview(
        wasmModule.module.allocateUTF8(windowTitle)
      );
    }, 100);
    isEnabled = true;
    isInitingPreview = false;

    return;
  }

  if (!wasmModule.module.getScanPreviewWin()) {
    return;
  }

  if (wasmModule.module.getScanPreviewWin) {
    wasmModule.module
      .getScanPreviewWin()
      .SetMinScaleValue(drawSTLOps.zoomRange[0]);
    wasmModule.module
      .getScanPreviewWin()
      .SetMaxScaleValue(drawSTLOps.zoomRange[1]);
  }

  const { upperArch, lowerArch, fillholeCallback } = drawSTLOps;

  if (upperArch !== currentShowSTLUpper) {
    if (upperArch) {
      const extension = getFileExtension(upperArch);

      if (extension == "stl") {
        stlFilePath["test/pre/arch_u.stl"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        // remove filling hole by zasidle @2023/11/7
        // // fill hole
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_u.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_u.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "up");
        //   }
        // }
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/pre/arch_u.stl", "");
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_u.mtc"] = upperArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("test/case/Raw/arch_o_u.mtc", "");
      }
      currentShowSTLUpper = upperArch;
    } else if (currentShowSTLUpper) {
      wasmModule.module.getScanPreviewWin().RemoveArch(true, false);
      currentShowSTLUpper = null;
      // const deleteMtcUpper = wasmModule.module.DeleteMtcFiles(0);
      // console.log("deleteMtcUpper :", deleteMtcUpper);
      resourcesSynchronization.deleteFile("test/case/Raw/arch_o_u.mtc");
    }
  }

  if (lowerArch !== currentShowSTLLower) {
    if (lowerArch) {
      const extension = getFileExtension(lowerArch);
      if (extension == "stl") {
        stlFilePath["test/pre/arch_l.stl"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);

        // remove filling hole by zasidle @2023/11/7
        // const ret = wasmModule.module._autoFillHoles(
        //   wasmModule.module.allocateUTF8("test/pre/arch_l.stl"),
        //   wasmModule.module.allocateUTF8("test/pre/fillready_arch_l.stl")
        // );
        // if (!ret) {
        //   if (fillholeCallback) {
        //     fillholeCallback(false, "low");
        //   }
        // }

        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/pre/arch_l.stl");
      } else if (extension == "mtc") {
        stlFilePath["test/case/Raw/arch_o_l.mtc"] = lowerArch;
        await resourcesSynchronization.moduleFS(stlFilePath);
        wasmModule.module
          .getScanPreviewWin()
          .UpdateArch("", "test/case/Raw/arch_o_l.mtc");
      }

      currentShowSTLLower = lowerArch;
    } else if (currentShowSTLLower) {
      wasmModule.module.getScanPreviewWin().RemoveArch(false, true);
      currentShowSTLLower = null;
      resourcesSynchronization.deleteFile("test/case/Raw/arch_o_l.mtc");

      // const isDeleteMtcLower = wasmModule.module.DeleteMtcFiles(1);
      // console.log("isDeleteMtcLower :", isDeleteMtcLower);
    }
  }

  // create ArchType.json

  let archType;
  if (upperArch && lowerArch) {
    archType = {
      KeepPalate: false,
      downArch: 0,
      upArch: 0,
    };
  } else if (upperArch == null) {
    archType = {
      downArch: 0,
    };
  } else if (lowerArch == null) {
    archType = {
      KeepPalate: false,
      upArch: 0,
    };
  }

  const archTypeJson = JSON.stringify(archType);
  console.log("archTypeJson:", archTypeJson);

  const isSaveData = await caseManagement.saveJsonDataToZip(
    archTypeJson,
    "ArchType.json",
    "Setting Data2"
  );

  console.log("isSaveData :", isSaveData);

  // const blob = new Blob([archTypeJson], { type: "text/plain" });
  // const file = new File([blob], "case/Setting Data2/ArchType.json");
  // const archTypeJsonPath: Record<string, any> = {};
  // archTypeJsonPath["test/case/Setting Data2/ArchType.json"] = file;

  // await resourcesSynchronization.moduleFS(archTypeJsonPath);
}