import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import WeCaseList from "@/UDTreat/WeDesign/WeTest/WeCaseList"

import OrganizationList from "./OrganizationList"

const OrganizationModule: FC = () => {
  return (
    <Routes>
      <Route path={""} element={<OrganizationList />} />
      <Route path={"/wecaselist"} element={<WeCaseList />} />{" "}
      <Route path={"*"} element={<Navigate to={""} replace />} />
    </Routes>
  )
}

export default OrganizationModule
