import { createMachine, assign, sendUpdate } from "xstate";
import { wasmModule } from "../../modules/wasm/wasmModule";

class FACCAndEdgeEditor {
  state;

  async showEditBtn(isShow: boolean, type: number) {
    console.log("isShow :", isShow, "  type:", type);
    // await getDvaApp()._store.dispatch({
    //   type: 'patients/setStageData',
    //   payload: {
    //     vtkmodel: { faccBtnState: { isShow, type } },
    //   },
    // });
  }

  enableSwitchFeatureEditModule(isEnabled: boolean) {
    wasmModule.moduleManager.SwitchFeatureEditModule(isEnabled);
  }

  enableFacc(isEnabled: boolean) {
    console.log("enablefacc::", isEnabled);
    console.time("enablefacc");
    // get the arch type to setting
    // const archType: 'up' | 'down' | '' = getDvaApp()._store.getState().mainModel.arch;
    // if (!isEnabled) {
    //   const featureModule = this.moduleManager.GetFeatureEditModule();
    //   featureModule.ShowOrHideFaccActor(0, isEnabled);
    //   featureModule.ShowOrHideFaccActor(1, isEnabled);
    //   this.enableSwitchFeatureEditModule(false);
    // } else {
    //   this.enableSwitchFeatureEditModule(true);
    //   const featureModule = this.moduleManager.GetFeatureEditModule();
    //   featureModule.SetEditType(0);
    //   if (archType === 'up') {
    //     featureModule.ShowOrHideFaccActor(0, isEnabled);
    //   } else if (archType === 'down') {
    //     featureModule.ShowOrHideFaccActor(1, isEnabled);
    //   } else {
    //     featureModule.ShowOrHideFaccActor(0, isEnabled);
    //     featureModule.ShowOrHideFaccActor(1, isEnabled);
    //   }
    // }
    // console.timeEnd('enablefacc');
  }

  enableEdge(isEnabled: boolean) {
    console.log("enableEdge::", isEnabled);
    console.time("enableEdge");
    // get the arch type to setting
    // const archType: 'up' | 'down' | '' = getDvaApp()._store.getState().mainModel.arch;

    // if (!isEnabled) {
    //   const featureModule = this.moduleManager.GetFeatureEditModule();
    //   featureModule.ShowOrHideEdgeActor(0, isEnabled);
    //   featureModule.ShowOrHideEdgeActor(1, isEnabled);
    //   this.enableSwitchFeatureEditModule(false);
    // } else {
    //   this.enableSwitchFeatureEditModule(true);
    //   const featureModule = this.moduleManager.GetFeatureEditModule();
    //   featureModule.SetEditType(0);
    //   if (archType === 'up') {
    //     featureModule.ShowOrHideEdgeActor(0, isEnabled);
    //   } else if (archType === 'down') {
    //     featureModule.ShowOrHideEdgeActor(1, isEnabled);
    //   } else {
    //     featureModule.ShowOrHideEdgeActor(0, isEnabled);
    //     featureModule.ShowOrHideEdgeActor(1, isEnabled);
    //   }
    // }
    // console.timeEnd('enableEdge');
  }

  delete() {
    const featureModule = this.moduleManager.GetFeatureEditModule();
    featureModule.RemoveCurrentFaccActor();
  }

  Redo() {
    this.delete();
  }

  save() {
    const featureModule = this.moduleManager.GetFeatureEditModule();
    featureModule.SaveFacc();
  }

  get moduleManager() {
    const ulabwinIns = wasmModule.getWasmObj()._ulabwinIns;
    const moduleManager = ulabwinIns.getModuleManager();
    return moduleManager;
  }

  /**
   * off facc faccedit edge edgeedit
   */
  constructor() {
    this.state = createMachine({
      id: "faccedgemodule",
      initial: "off",
      states: {
        facc: {
          entry: [
            () => {
              console.log("entry ..facc11");
              this.enableFacc(true);
            },
          ],
          exit: [
            () => {
              console.log("out ..facc11");
              this.enableFacc(false);
            },
          ],
        },
        faccedit: {
          entry: [],
        },
        edge: {
          entry: [
            () => {
              console.log("entry ..edge22");
              this.enableEdge(true);
            },
          ],
          exit: [
            () => {
              console.log("entry ..edge22");
              this.enableEdge(false);
            },
          ],
        },
        edgeedit: {},
        off: {
          on: {
            TOGGLE_FACC: "facc",
            TOGGLE_EDGE: "edge",
          },
        },
      },
      entry: [
        () => {
          console.log("entry ..00");
          // this.enableSwitchFeatureEditModule(true);
        },
      ],
      exit: [
        () => {
          console.log("exit ..00");
          // this.enableSwitchFeatureEditModule(false);
        },
      ],
    });
  }
}

export const faccAndEdgeEditor = new FACCAndEdgeEditor();
