import { FC, useEffect, useMemo, useState } from "react"
import { JsonSchema, UISchemaElement } from "@jsonforms/core"
import { materialCells, materialRenderers } from "@jsonforms/material-renderers"
import { JsonForms } from "@jsonforms/react"

import { useAppDispatch } from "../../core/app/hooks"
import { setIsRouteChangeBlocked } from "../../core/app/slices/navigationPopup/navigationPopupSlice"
import { isDeepEqual } from "../../core/utils/commonMethod"
import { checkIfObjectHasKeys } from "../../core/utils/formatters"
import {
  UAccordionContainer,
  UAccordionContainerTester,
  UArchesTreat,
  UArchesTreatTester,
  UCheckboxAccordian,
  UCheckboxAccordianTester,
  UCheckboxGroup,
  UCheckboxGroupTester,
  UGroupContainer,
  UGroupContainerTester,
  UInput,
  UInputTester,
  UMultipleCheckbox,
  UMultipleCheckboxTester,
  UMultipleInput,
  UMultipleInputTester,
  URadioGroup,
  URadioGroupTester,
  URadioTextGroup,
  URadioTextGroupTester,
  USummary,
  USummaryTester,
  UTemplateSelect,
  UTemplateSelecTester,
  UTextarea,
  UTextareaTester,
} from "../components"

const JsonFormBuilder: FC<{
  schema: JsonSchema
  uischema: UISchemaElement
  defaultData: any
  isFormSubmitted: boolean
  setIsFormSubmitted?: any
  setData?: any
  handleChange?: any
  isSettings?: boolean
}> = ({
  schema,
  uischema,
  defaultData,
  setData,
  isFormSubmitted,
  setIsFormSubmitted,
  handleChange,
  isSettings,
}) => {
  const createTranslator =
    (locale: string) => (key: string, defaultMessage: string) => {
      if (key === "error.required") {
        return "This field is required"
      }
      return defaultMessage
    }
  //TODO: In future we can make dynamic for now default as "en"
  const [locale, setLocale] = useState<"en">("en")
  const translation = useMemo(() => createTranslator(locale), [locale])
  const dispatch = useAppDispatch()

  const changeHandler = (data) => {
    if (data.arch_form_type !== undefined) {
      setData(data)
    }
  }

  const renderers = [
    ...materialRenderers,
    //register custom renderers
    { tester: UArchesTreatTester, renderer: UArchesTreat },
    { tester: UGroupContainerTester, renderer: UGroupContainer },
    { tester: UTemplateSelecTester, renderer: UTemplateSelect },
    { tester: URadioGroupTester, renderer: URadioGroup },
    { tester: UInputTester, renderer: UInput },
    { tester: URadioTextGroupTester, renderer: URadioTextGroup },
    { tester: UCheckboxGroupTester, renderer: UCheckboxGroup },
    { tester: UCheckboxAccordianTester, renderer: UCheckboxAccordian },
    { tester: UTextareaTester, renderer: UTextarea },
    { tester: USummaryTester, renderer: USummary },
    { tester: UAccordionContainerTester, renderer: UAccordionContainer },
    { tester: UMultipleInputTester, renderer: UMultipleInput },
    { tester: UMultipleCheckboxTester, renderer: UMultipleCheckbox },
  ]

  return (
    <>
      {
        <JsonForms
          i18n={{ locale: locale, translate: translation }}
          schema={schema}
          uischema={uischema}
          data={defaultData}
          renderers={renderers}
          cells={materialCells}
          validationMode={isFormSubmitted ? "ValidateAndShow" : "NoValidation"}
          onChange={({ data, errors }) => {
            if (isDeepEqual(data, defaultData)) {
              return
            }
            if (!isSettings) {
              dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
            }
            setIsFormSubmitted(false)
            handleChange(data, errors)
          }}
          sx={{ width: "100%" }}
        />
      }
    </>
  )
}

export default JsonFormBuilder
