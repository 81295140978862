import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react"

interface Error {
  code: number
  message: string
}

interface ErrorContextType {
  error: Error | null
  setError: React.Dispatch<React.SetStateAction<Error | null>>
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider")
  }
  return context
}

interface ErrorProviderProps {
  children: ReactNode
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null)
  const value = useMemo(() => ({ error, setError }), [error, setError])

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}
